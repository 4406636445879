import styled from 'styled-components'
import { Row, Col } from '../../../index'

export const ProfileItemHeader = styled.span`
  font-size: 12.8px;
  color: var(--secondary);
  display: inline-block;
`

export const ProfileItemContainer = styled(Col)`
  margin-top: 15px;
`

export const DependentsRow = styled(Row)`
  @media (max-width: 576px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
`

export const ProfileRow = styled(Row)`
  padding: 30px 0px 10px;
  margin: 0px !important;
  border-bottom: 1px solid #e7eaf3;
  border-top: 1px solid #e7eaf3;

  @media (max-width: 576px) {
    padding: 15px 10px;
  }
`

export const HealthProfileSectionTitle = styled.strong`
  display: block;
  margin-bottom: 1.25rem;
`

export const PersonalInformation = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid #e7eaf3;
  display: flex;
  justify-content: space-between;

  p:first-child {
    color: #6c757d;
  }
`

export const ReviewAlert = styled.div`
  background: #f3f2ff;
  border-radius: 6px;
  padding: 15px;
  color: var(--primary);
  font-weight: bold;
  margin-bottom: 2.5rem;
  margin-top: 0.5rem;
`

export const SectionContainer = styled.div`
  display: flex;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const Sidebar = styled.div`
  width: 352px;
  height: 100%;
  flex-shrink: 0;
  border-right: 1px solid #e7eaf3;

  @media (max-width: 576px) {
    flex-shrink: inherit;
    width: 100% !important;
  }
`
