import React from 'react'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import { Logo } from '../../Logo'

export const FrontHeader: React.FC<{ data: any; planCoverage: any }> = ({
  data,
  planCoverage
}) => {
  const memberId = data?.member_id.length === 9 || data?.member_id.length === 12 ? data?.member_id.slice(0, -2) : data?.member_id
  return (
    <div
      className="bg-primary pt-3 px-4 rounded-top border-top border-right border-left"
      style={{
        backgroundImage: 'url(/subtract.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '70%',
        backgroundPosition: 'right',
        flex: 0
      }}
    >
      <div>
        <div className="text-center">
          <Logo
            size="xs"
            variant="default"
            color="white"
            subTitleColor="white"
            width={120}
            height={70}
          />
        </div>

        <h6 className="text-white" style={{ fontFamily: 'Sentinel' }}>
          {data?.name.first_name} {data?.name.middle_name}{' '}
          {data?.name.last_name}
        </h6>
        <Row>
          <Col className="text-left">
            <p className="mb-0 text-white fs-12 font-weight-bold">Member ID:</p>
            <p className="mb-0 text-white fs-12 font-weight-bold">Group #:</p>
            <p className="mb-0 text-white fs-12 font-weight-bold">
              Effective Date:{' '}
            </p>
          </Col>
          <Col className="text-right">
            <p className="mb-0 text-white fs-12">{memberId}</p>
            <p className="mb-0 text-white fs-12">{data?.group_id}</p>
            <p className="mb-0 text-white fs-12">
              {moment(planCoverage?.effective_date).format('MM/DD/YYYY')}
            </p>
          </Col>
        </Row>

        <div className="text-center mb-1">
          <b className="text-white fs-12">{planCoverage?.plan.plan_name}</b>
        </div>
      </div>
    </div>
  )
}
