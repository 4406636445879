import styled from 'styled-components'

export const FlexText = styled.span`
  flex: 1;
`

export const MenuItemsContainer = styled.div`
  flex-direction: column;
  margin-top: 1.5em;
  overflow: scroll;
  background: white;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding-top: 9vh;
  padding-left: 1em;
  padding-right: 1em;
  height: calc(100% - 20px);
`

export const Main = styled.main`
  margin-top: 68px;
  display: flex;
  flex-direction: column;
`

export const DropdownItem = styled.div`
  display: flex;
  flex-direction: row;
`

export const DropdownItemIcon = styled.div`
  flex-grow: 0;
`

export const DropdownContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const DropdownTitle = styled.p`
  margin-bottom: 0;
`

export const DropdownSubtitle = styled.small`
  max-width: 334px;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
`
