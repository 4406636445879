import React, { useState } from 'react'
import { Row, Col, Form } from 'react-angle-dashboard-components'
import { Report } from './Report'
import { reports } from '../exploConfig'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': unknown
    }
  }
}

export const Reports = () => {
  const [currentReport, setCurrentReport] = useState(reports[0].dashboardToken)

  return (
    <div>
      <Row className="mb-4">
        <Col>
          <Form.Control
            as="select"
            className="bg-white"
            style={{ maxWidth: 250 }}
            onChange={(e: any) => {
              setCurrentReport(e.target.value)
            }}
          >
            {reports.map((o) => (
              <option value={o.dashboardToken} key={o.dashboardToken}>
                {o.label}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>

      <Report dashboardToken={currentReport}></Report>
    </div>
  )
}

export default Reports
