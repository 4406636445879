import React, { ReactNode } from 'react'
import { Row, Col, Accordion, Card, Button } from 'react-bootstrap'
import { SectionTitle } from '../SectionTitle'
import { FloatingContainer, InfoRow } from './styles'

export interface IInfoCardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string
  withoutContainer?: boolean
  topTranslation?: number
}

export const InfoCard: React.FC<IInfoCardProps> = ({
  title,
  withoutContainer = false,
  topTranslation = -190,
  children,
  ...others
}) => {
  const content = (
    <FloatingContainer {...others} withoutContainer={withoutContainer}>
      <Accordion className="d-block d-sm-none">
        <Card className="shadow-sm p-3 mb-4">
          <Accordion.Toggle as="div" role="button" eventKey="0">
            <InfoRow style={{ margin: 0, padding: 0 }}>
              <SectionTitle className="mb-0">{title}</SectionTitle>
            </InfoRow>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <div style={{ marginTop: '27px' }}>{children}</div>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Card
        style={{ top: topTranslation, zIndex: 1 }}
        className="shadow-sm d-none d-sm-flex"
      >
        <Card.Body>
          <InfoRow className="mb-2">
            <SectionTitle className="mb-1">{title}</SectionTitle>
          </InfoRow>
          {children}
        </Card.Body>
      </Card>
    </FloatingContainer>
  )

  if (withoutContainer) return content

  return (
    <Row>
      <Col className="d-none d-sm-flex"></Col>
      <Col md="4">{content}</Col>
    </Row>
  )
}

export default InfoCard
