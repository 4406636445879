import React, { useContext, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { states } from '@angle/utils'
import { useMutation } from 'react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import ReactInputMask from 'react-input-mask'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import styled from 'styled-components'

const ErrorSpan = styled.span`
display: inline-block;
margin-top: 5px;
color: var(--red);
`

import {
  Button,
  Col,
  Row,
  Modal,
  Form,
  EmployeeProfile,
  EightThreeFourService,
  useToaster,
  AuthContext
} from '../../../index'
import { EightThreeFourRequest } from '../../../interfaces/employer-broker-shared/eightthreefour'

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
)

export interface EditEmployeeFormV2 {
  firstName: string
  middleName?: string
  suffix?: string
  lastName: string
  dateOfBirth: Date
  sex: string
  ssn: string
  email?: string
  phoneNumber: string
  street_1: string
  street_2: string
  city: string
  state: string
  zip: string
}

interface EditEmployeeForm extends EditEmployeeFormV2 {
  confirmSsn: string
}

const EditEmployeeFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().optional(),
  suffix: z.string().optional(),
  email: z.string().email().or(z.literal('')),
  sex: z.string().min(1, 'Birth Gender is required'),
  dateOfBirth: z.date(),
  ssn: z
    .string()
    .nonempty({ message: 'SSN is required' })
    .refine((value) => value.length === 11 && !value.includes('_'), {
      message: 'SSN needs to be in a valid format'
    }),
  confirmSsn: z
    .string()
    .nonempty({ message: 'Confirm SSN is required' })
    .refine((value) => value.length === 11 && !value.includes('_'), {
      message: 'SSN needs to be in a valid format'
    }),
  phoneNumber: z
    .string()
    .regex(phoneRegex, 'Invalid Number!')
    .or(z.literal('')),
  street_1: z.string(),
  street_2: z.string().optional(),
  city: z.string(),
  state: z
    .string()
    .length(2, 'State needs to be in a valid format'),
  zip: z
    .string()
    .length(5, 'ZIP needs to be in a valid format')
}).refine(
  (data) => data.confirmSsn === data.ssn, {
    message: 'Confirm SSN must match SSN',
    path: ['confirmSsn']
})

const genderTranslatorFor843 = (gender: string): string => {
  if (gender.toLowerCase() === 'm' || gender.toLowerCase() === 'f') {
    return gender.toUpperCase()
  }

  if (gender.toLowerCase() === 'male') {
    return 'M'
  }

  if (gender.toLowerCase() === 'female') {
    return 'F'
  }

  return 'U'
}

const addDashesToSSN = (ssn) => {
  if (ssn.length === 9) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
  }
  return ssn;
}

export const EmployerPortalEditEmployeeModalV2: React.FC<{
  isEditModalVisible: boolean
  setEditModalVisible: (boolean) => void
  employee: EmployeeProfile | undefined
  eightThreeFourService: EightThreeFourService
  parentMemberId?: string
  refetchEmployee: () => void,
}> = ({
  isEditModalVisible,
  setEditModalVisible,
  employee,
  eightThreeFourService,
  parentMemberId,
  refetchEmployee
}) => {
  const { showSuccess, showError } = useToaster()

  const {state: userState} = useContext(AuthContext)
  const isAdmin = userState.user && ["employer", "internal", "broker"].includes(userState.user.user_type)
  let defaultSSN = employee?.ssn ? addDashesToSSN(employee.ssn) : ''

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: '',
      dateOfBirth: new Date(),
      sex: '',
      ssn: '',
      confirmSsn: '',
      email: '',
      phoneNumber: '',
      street_1: '',
      street_2: '',
      city: '',
      state: '',
      zip: ''
    },
    resolver: zodResolver(EditEmployeeFormSchema)
  })
  const ssnValue = watch('ssn')

  useEffect(() => {
    if (ssnValue !== defaultSSN) {
      setValue('confirmSsn', '')
    } else {
      setValue('confirmSsn', defaultSSN)
    }
  }, [ssnValue])

  useEffect(() => {
    const defaultValues: EditEmployeeForm = {
      firstName: employee?.name.split(' ')[0] || '',
      lastName: employee?.name.split(' ')[1] || '',
      middleName: '',
      suffix: '',
      dateOfBirth: employee?.dob ? moment(employee.dob).toDate() : new Date(),
      sex: employee?.gender ? genderTranslatorFor843(employee.gender) : '',
      ssn: employee?.ssn ? addDashesToSSN(employee.ssn) : '',
      confirmSsn: employee?.ssn ? addDashesToSSN(employee.ssn) : '',
      email: '',
      phoneNumber: '',
      street_1: employee?.address_object.street_1 || '',
      street_2: employee?.address_object.street_2 || '',
      city: employee?.address_object.city || '',
      state: employee?.address_object.state || '',
      zip: employee?.address_object.zip || ''
    }

    defaultSSN = employee?.ssn ? addDashesToSSN(employee.ssn) : ''

    reset(defaultValues)
  }, [employee, reset])

  const editMember = useMutation({
    mutationFn: (data: any) =>
      eightThreeFourService.postMemberInformation(data),
    onSuccess: (response) => {
      if (response.success && response.data.total_members === response.data.total_success) {
        showSuccess(`Information for this member has been successfully edited!`)
        setEditModalVisible(false)
        reset()
        refetchEmployee()
      } else if (response.link && response.success === false) {
        showError(`Information was not successfully edited - ${response.message}`)
        setTimeout(() => window.open(response.link, '_blank'), 500)
      }
      else {
        showError('Information was not successfully edited because of an error.')
      }
    },
    onError: (error) => {
      showError(`Information was not successfully edited because of an error - ${error}`)
    }
  })

  const sendInformationChangeRequest = async (data) => {
    if (!employee) {
      return
    }
    const dateOfBirth = moment(data.dateOfBirth).format('YYYY-MM-DD')
    const params: EightThreeFourRequest = {
      group_id: employee?.group_id,
      members: [
        {
          first_name: data.firstName,
          last_name: data.lastName,
          middle_name: data.middleName,
          suffix: data.suffix,
          email: data.email,
          phone_number: data.phoneNumber,
          group_id: employee?.group_id,
          angle_member_id: employee?.id,
          parent_member_id: parentMemberId ? parentMemberId : employee?.id,
          ssn: data.ssn,
          action_requested: 'info',
          gender: data.sex,
          relationship: employee?.relationship,
          date_of_birth: dateOfBirth,
          subgroup: employee?.subgroup,
          street_1: data.street_1,
          street_2: data.street_2,
          state: data.state,
          city: data.city,
          zip: data.zip
        }
      ]
    }
    await editMember.mutateAsync(params)
  }

  const onSubmit: SubmitHandler<EditEmployeeFormV2> = (data) => {
    try {
      sendInformationChangeRequest(data)
    } catch (err) {
      showError(
        `Information was not successfully edited because of an error.`
      )
    }
  }

  return (
    <Modal
      show={isEditModalVisible}
      centered
      size="lg"
      onHide={() => {
        setEditModalVisible(false)
        reset()
      }}
      dialogClassName="groupFormModalDialog"
      className="groupFormModal"
      style={{
        zIndex: 2000
      }}
    >
      <Modal.Header className="border-bottom pb-2 pt-4" closeButton>
        <Modal.Title>Edit Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="py-1">
          <Col sm={12} md={6}>
            <h6>Personal Information</h6>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder="First Name"
                className={isAdmin ? 'bg-white' : 'bg-gray'}
                {...register('firstName')}
                readOnly={!isAdmin}
                isInvalid={!!errors?.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.firstName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Middle Name (Optional)</Form.Label>
              <Form.Control
                placeholder="Middle Name"
                className={isAdmin ? 'bg-white' : 'bg-gray'}
                {...register('middleName')}
                readOnly={!isAdmin}
                isInvalid={!!errors?.middleName}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.middleName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder="Last Name"
                className={isAdmin ? 'bg-white' : 'bg-gray'}
                isInvalid={!!errors?.lastName}
                readOnly={!isAdmin}
                {...register('lastName')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.lastName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Suffix (Optional)</Form.Label>
              <Form.Control
                placeholder="Suffix"
                className={isAdmin ? 'bg-white' : 'bg-gray'}
                isInvalid={!!errors?.suffix}
                readOnly={!isAdmin}
                {...register('suffix')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.suffix?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Date of Birth</Form.Label>
              <Controller
                key="dateOfBirth"
                name="dateOfBirth"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Enter your birth date"
                    onChange={onChange}
                    selected={value}
                    maxDate={new Date()}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.dateOfBirth?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Sex (Gender at Birth)</Form.Label>
              <Form.Control
                as="select"
                className="bg-white"
                isInvalid={!!errors?.sex}
                {...register('sex')}
              >
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="U">Other</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors?.sex?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Social Security Number</Form.Label>
              <Controller
                key="ssn"
                name="ssn"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    placeholder="Enter SSN"
                    className="bg-white"
                    isInvalid={!!errors?.ssn}
                    as={ReactInputMask}
                    mask="999-99-9999"
                    {...register('ssn', {
                      required: 'This field is required',
                      validate: {
                        invalidSsn: (val) => {
                          if (
                            val === '000-00-0000' ||
                            val === '111-11-1111'
                          ) {
                            return 'Please enter a valid social security number'
                          } else return true
                        }
                      }
                    })}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.ssn?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Label>Confirm Social Security Number</Form.Label>
            <Controller
              key="confirmSsn"
              name="confirmSsn"
              control={control}
              render={({ field }) => (
                <Form.Control
                  placeholder="Confirm SSN"
                  className="bg-white"
                  isInvalid={!!errors?.confirmSsn}
                  as={ReactInputMask}
                  mask="999-99-9999"
                  {...register('confirmSsn', {
                    required: 'This field is required',
                    validate: {
                      invalidSsn: (val) => {
                        if (
                          val === '000-00-0000' ||
                          val === '111-11-1111'
                        ) {
                          return 'Please enter a valid social security number'
                        } else return true
                      }
                    }
                  })}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.confirmSsn?.message}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="border-bottom pb-1">
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Personal Email (Optional)</Form.Label>
              <Form.Control
                placeholder="Email"
                className="bg-white"
                isInvalid={!!errors?.email}
                {...register('email')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder="Phone Number"
                className="bg-white"
                isInvalid={!!errors?.phoneNumber}
                as={ReactInputMask}
                mask="(999)-999-9999"
                {...register('phoneNumber')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.phoneNumber?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col sm={12} md={6}>
            <h6>Mailing Address</h6>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                placeholder="Street Address"
                className="bg-white"
                isInvalid={!!errors.street_1}
                {...register('street_1')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.street_1?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Street Address Line 2</Form.Label>
              <Form.Control
                placeholder="Street Address line 2"
                className="bg-white"
                isInvalid={!!errors.street_2}
                {...register('street_2')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.street_2?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                placeholder="City"
                className="bg-white"
                isInvalid={!!errors.city}
                {...register('city')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.city?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Control
                placeholder="Select a state"
                as="select"
                className="bg-white"
                isInvalid={Boolean(errors?.state)}
                {...register('state')}
              >
                <option value="" disabled>
                  Select a state
                </option>
                {Object.keys(states).map((s) => (
                  <option key={s} value={s}>
                    {(states as any)[s]}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors?.state?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                placeholder="Zip Code"
                className="bg-white"
                isInvalid={!!errors.zip}
                {...register('zip')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.zip?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-top pb-3 pt-3">
        <Button
          disabled={isSubmitting || editMember.isLoading}
          onClick={handleSubmit(onSubmit)}
          className="w-25"
        >
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
