import React from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { IFaq, IPlanBenefitTableDTO } from '../../interfaces'
import { AnglePlanBadge } from '../AnglePlanBadge'
import { BenefitsTable } from '../BenefitsTable'
import { Faq } from '../Faq'

export interface ISBCProps {
  coverageInfo: {
    plan: {
      name: string
      metalTier: string
      type: string
    }
    coveragePeriod: {
      from: string
      to: string
    }
    coverageFor: string
  }
  faqs: IFaq[]
  benefits: IPlanBenefitTableDTO[]
}

const InfoAlert: React.FC<
  Partial<{ contentContainerClass: string; iconStyle: React.CSSProperties }>
> = ({ children, contentContainerClass, iconStyle = {} }) => (
  <Alert
    variant="info"
    style={{
      background: 'rgba(23, 162, 184, 0.02)'
    }}
  >
    <Row>
      <Col style={{ maxWidth: 80 }} xs="1">
        <p
          className="text-center text-info"
          style={{
            fontSize: '2.7em',
            ...iconStyle
          }}
        >
          <i className="icon-error"></i>
        </p>
      </Col>
      <Col className={contentContainerClass}>{children}</Col>
    </Row>
  </Alert>
)
export const SummaryOfBenefitsCoverage: React.FC<ISBCProps> = ({
  coverageInfo,
  faqs,
  benefits
}) => {
  const sbc = coverageInfo

  return (
    <div>
      <div className="text-center">
        <h2>Summary of Benefits and Coverage</h2>
        <p>What this Plan Covers & What You Pay for Covered Services</p>

        <p className="text-uppercase text-gray">
          DOMICILED IN THE STATE OF UTAH
        </p>
      </div>

      <div
        style={{
          borderTop: '0.25rem solid var(--card-border)',
          borderBottom: '0.25rem solid var(--card-border)'
        }}
        className="mt-3"
      >
        <Container className="my-3">
          <Row>
            <Col className="text-center">
              Angle Insurance Company of Utah:{' '}
              <AnglePlanBadge
                planName={sbc.plan.name}
                metalTier={sbc.plan.metalTier}
              />{' '}
            </Col>
            <Col xs="1" className="px-0 text-center" style={{ maxWidth: 15 }}>
              <span className="mx-2 text-gray">|</span>
            </Col>
            <Col className="text-center">
              Coverage Period: {sbc.coveragePeriod.from} -{' '}
              {sbc.coveragePeriod.to}
            </Col>
            <Col xs="1" className="px-0 text-center" style={{ maxWidth: 15 }}>
              <span className="mx-2 text-gray">|</span>
            </Col>
            <Col className="text-center">
              Coverage for: {sbc.coverageFor} | Plan Type: {sbc.plan.type}
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-4 px-3">
        <InfoAlert>
          <p>
            The Summary of Benefits and Coverage (SBC) document will help you
            choose a health plan. The SBC shows you how you and the plan would
            share the cost for covered health care services. NOTE: Information
            about the cost of this plan (called the premium) will be provided
            separately.
          </p>
          <p>
            This is only a summary. For more information about your coverage, or
            to get a copy of the complete terms of coverage, visit
            www.anglehealth.com or call 855-937-1855. For general definitions of
            common terms, such as allowed amount, balance billing, coinsurance,
            copayment, deductible, provider, or other underlined terms, see the
            Glossary. You can view the Glossary at www.anglehealth.com or call
            855-937-1855 to request a copy
          </p>
        </InfoAlert>
      </div>

      <Container className="my-5 mx-auto">
        <h4 className="text-uppercase text-center">
          Frequently asked questions
        </h4>

        <Faq faqs={faqs} />
      </Container>

      <div className="mt-4 px-3">
        <InfoAlert
          contentContainerClass="align-self-center"
          iconStyle={{ fontSize: '2em', marginBottom: 0 }}
        >
          <p className="mb-0 ">
            All copayment and coinsurance costs shown in this chart are after
            your deductible has been met, if a deductible applies.
          </p>
        </InfoAlert>
      </div>

      <section>
        <BenefitsTable benefits={benefits} />
      </section>
    </div>
  )
}

export default SummaryOfBenefitsCoverage
