import { ApiService } from './base'

export interface IDocument {
  name: string
  link: string
  document_id: number
  document_type: string
  content_type: string
  description: string
  document_name: string
  upload_time: string
  url: string
}

export interface IDocumentResponse {
  category: string
  documents: IDocument[]
}

export class DocumentService extends ApiService {
  async findDocuments(groupId?: string): Promise<IDocumentResponse[]> {
    return this.request({
      method: 'get',
      url: `${groupId ? `/groups/${groupId}` : ''}/documents`,
      credentials: true
    })
  }

  async upload(data: FormData): Promise<any> {
    return this.request({
      method: 'post',
      url: '/documents/upload',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }

  getMemberDocuments(memberId: string, isMemberUpload: boolean = true) {
    return this.request({
      method: 'get',
      url: `/members/${memberId}/documents${
        isMemberUpload ? '/member-uploaded' : ''
      }`,
      credentials: true
    })
  }

  getGroupDocuments(groupId: string) {
    return this.request({
      method: 'get',
      url: `/groups/${groupId}/documents`,
      credentials: true
    }).then((r) => r.data)
  }

  getProviderDocuments(providerId: string) {
    return this.request({
      method: 'get',
      url: `/providers/${providerId}/documents`,
      credentials: true
    })
  }

  deleteProviderDocument(providerId: string, documentId: string) {
    return this.request({
      method: 'delete',
      url: `/providers/${providerId}/documents/${documentId}`,
      credentials: true
    })
  }

  async previewDocument(route: string, contentType: string) {
    const headers = new Headers()

    headers.append('Authorization', `Bearer ${this.token || ''}`)

    const response = await fetch(this.baseApiUrl + route, {
      headers
    })

    const blob = await response.blob()
    const file = new Blob([blob], { type: contentType })
    var data = URL.createObjectURL(file)
    window.open(data)
  }

  async downloadDocument(
    route: string,
    doc: { contentType: string; filename: string }
  ) {
    const headers = new Headers()

    headers.append('Authorization', `Bearer ${this.token || ''}`)

    const response = await fetch(this.baseApiUrl + route, {
      headers
    })

    if (response.status > 400)
      throw new Error(
        `Error downloading document. [${response.statusText || 'None'}:${
          response.status
        }]`
      )

    const blob = await response.blob()
    const file = new Blob([blob], { type: doc.contentType })
    var data = URL.createObjectURL(file)
    var a = document.createElement('a')
    ;(a as any).style = 'display: none'
    a.href = data
    a.download = doc.filename
    document.body.appendChild(a)
    a.click()
    setTimeout(function () {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(data)
    }, 100)
  }

  downloadGroupDocument(groupId: string, documentId: string) {
    return this.request({
      method: 'get',
      url: `/groups/${groupId}/documents/${documentId}`,
      credentials: true
    })
  }

  downloadMemberDocument(memberId: string, documentId: string) {
    return this.request({
      method: 'get',
      url: `/members/${memberId}/documents/member-uploaded/${documentId}`,
      credentials: true
    })
  }

  uploadDocument(route: string, data: FormData): Promise<{ success: boolean }> {
    return this.request({
      method: 'post',
      url: route,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }
}
