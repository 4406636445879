import { Button } from 'react-angle-dashboard-components'
import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2.375rem;
  border-top: 1px solid var(--card-border);
`

export const GhostBtn = styled(Button)`
  border-color: var(--card-border);
`

export const RightMarginBtn = styled(GhostBtn)`
  margin-right: 1rem;
`
