import React from 'react'

export const NewTabLink: React.FC<{ href: string; text: string }> = ({
  href,
  text
}) => {
  return (
    <a
      href={href}
      target="_blank noopener noreferrer"
      style={{ textDecoration: 'underline' }}
    >
      {text}
    </a>
  )
}
