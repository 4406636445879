import { Col, Form, FormCheckProps, Row } from 'react-bootstrap'
import styled from 'styled-components'

export const Cont = styled.div`
  max-width: 45.625rem;
  margin: 0 auto;
`

export const FullScreenModal = styled.div`
  position: fixed;
  top: 78px;
  left: 0;
  z-index: 9999;
  background-color: white;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  padding-bottom: 2em;
`

export const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1.875rem;
  border-top: 1px solid var(--card-border);
`

export const CheckBoxControl: React.FC<
  { field: any; value: string } & FormCheckProps
> = ({ field, value, children, ...others }) => (
  <Col>
    <Form.Group
      className={'rounded m-0 p-0'}
      style={
        field.value === value
          ? {
              background: '#EEEAF7',
              border: '0.0625rem solid var(--primary)'
            }
          : {
              border: '0.0625rem solid #e7eaf3'
            }
      }
    >
      <Form.Label className={'pl-3 pb-3 pt-4 mb-0 w-100'}>
        <Row>
          <Col xs="10">
            <h6 className="mb-0">{children}</h6>
          </Col>
          <Col className="text-center px-0 d-flex justify-content-center align-items-center">
            <Form.Check {...field} {...others} type="radio" value={value} />
          </Col>
        </Row>
      </Form.Label>
    </Form.Group>
  </Col>
)
