import React, { useEffect, useState } from 'react'
import {
  APIError,
  CenteredSpinner,
  Col,
  Container,
  Form,
  Row,
  SortDirection,
  Tab,
  Table,
  Tabs,
  useServerPagination
} from '../../index'
import { useQuery } from 'react-query'
import { ClaimTableBody } from './ClaimTableBody'
import { AccumsCards } from './AccumsCards'
import { ClaimFilters } from './ClaimsFilters'

export const ClaimsTable = ({
  getAccums,
  getMedicalClaims,
  getRXClaims,
  getDependents,
  memberId: memberIdProp,
  isMemberLoading,
  memberData,
  PaperlessCard,
  isHomebase
}: {
  getAccums: any
  getMedicalClaims: any
  getRXClaims: any
  getDependents: any
  memberId: any
  isMemberLoading: any
  memberData: any
  PaperlessCard?: any
  isHomebase?: boolean
}) => {
  const [memberId, setMemberId] = useState(memberIdProp)
  const [showcard, setShowCard] = useState('family')
  const [claimStatusFilter, setClaimStatusFilter] = useState('')
  const [claimTypeKey, setClaimTypeKey] = useState('medical')
  const [inNetworkFilter, setInNetworkFilter] = useState(true)

  //sorting
  const [sortBy, setSortBy] = useState('')
  const [sortDirection, setSortDirection] = useState<SortDirection>('none')

  const onTableSort = (field: string) => {
    if (field === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortDirection('asc')
    }

    setSortBy(field)
  }

  const headers = [
    {
      title: 'Claim ID',
      sortable: true,
      sortDirection: sortBy === 'claim_id' ? sortDirection : 'none',
      onClick: () => onTableSort('claim_id')
    },
    {
      title: 'Processing Date',
      sortable: true,
      sortDirection: sortBy === 'paid_date' ? sortDirection : 'none',
      onClick: () => onTableSort('paid_date')
    },
    {
      title: 'Service Date',
      sortable: true,
      sortDirection: sortBy === 'service_date' ? sortDirection : 'none',
      onClick: () => onTableSort('service_date')
    },
    { title: 'Provider' },
    { title: 'Member' },
    { title: 'Patient Responsibility' },
    { title: 'Paid Amount' },
    { title: 'Billed Amount' },
    // { title: 'Processing Status' },
    { title: 'Payment Status' },
    { title: 'Payment Type'}
  ]
  const rxHeaders = [
    {
      title: 'Claim ID',
      sortable: true,
      sortDirection: sortBy === 'claim_id' ? sortDirection : 'none',
      onClick: () => onTableSort('claim_id')
    },
    {
      title: 'Processing Date',
      sortable: true,
      sortDirection: sortBy === 'paid_date' ? sortDirection : 'none',
      onClick: () => onTableSort('paid_date')
    },
    // { title: 'Service Date' },
    { title: 'Provider' },
    { title: 'Member' },
    { title: 'Patient Responsibility' },
    { title: 'Processing Status' },
    { title: 'Payment Type'}
  ]

  useEffect(() => {
    setMemberId(memberIdProp)
  }, [memberIdProp])

  const {
    paginationConfig,
    queryResult: { data, isLoading: isLoadingClaims, isError, error }
  } = useServerPagination(
    [
      'medicalClaims',
      showcard,
      claimStatusFilter,
      claimTypeKey,
      inNetworkFilter,
      sortBy,
      sortDirection
    ],
    ({ pageLimit, pageNumber }) => {
      const params: any = {
        in_network: inNetworkFilter,
        member_id: showcard !== 'family' ? showcard : undefined,
        page_limit: pageLimit,
        page_number: pageNumber,
        sort_by: sortBy,
        sort_direction: sortDirection
      }
      return getMedicalClaims(params)
    }
  )
  if (error) {
    console.log('requesterr: ', JSON.stringify(error))
  }

  const {
    paginationConfig: paginationConfigRx,
    queryResult: {
      data: rxData,
      isLoading: isLoadingRx,
      isError: isErrorRx,
      error: errorRx
    }
  } = useServerPagination(
    [
      'rxClaims',
      showcard,
      claimStatusFilter,
      claimTypeKey,
      inNetworkFilter,
      sortBy,
      sortDirection
    ],
    ({ pageLimit, pageNumber }) => {
      const params: any = {
        claim_status: claimStatusFilter,
        in_network: inNetworkFilter,
        member_id: showcard !== 'family' ? showcard : undefined,
        page_limit: pageLimit,
        page_number: pageNumber,
        sort_by: sortBy,
        sort_direction: sortDirection
      }
      return getRXClaims(params)
    }
  )

  const { data: dependentsData, isLoading: isDependentsLoading } = useQuery(
    ['getDependents'],
    () => getDependents()
  )

  useEffect(() => {
    if (dependentsData?.dependents.length === 0) {
      setShowCard(memberId)
    }
  }, [memberId, dependentsData, showcard])

  const {
    data: accumsData,
    isLoading: isAccumsLoading,
    isError: isAccumsError
  } = useQuery(['getAccums'], () => getAccums(memberId))

  const onChange = (e: any) => {
    const memberId = e.target.value
    if (!memberId) {
      setShowCard('family')
    } else {
      setShowCard(memberId)
    }
  }

  const onNetworkChange = (e: any) => {
    const network = e.target.value
    setInNetworkFilter(JSON.parse(network))
  }

  const isLoading =
    isAccumsLoading ||
    isMemberLoading ||
    isDependentsLoading ||
    isLoadingClaims ||
    isLoadingRx

  return (
    <div>
      <div className="bg-light mb-3">
        <Container className="d-flex justify-content-between pt-5">
          <div
            className="align-self-end"
            style={{ fontSize: 31, fontWeight: 500 }}
          >
            <Tabs
              activeKey={claimTypeKey}
              defaultActiveKey="medical"
              onSelect={(key) => setClaimTypeKey(key || 'medical')}
              justify
              fill
            >
              <Tab eventKey="medical" title="Medical"></Tab>
              <Tab eventKey="rx" title="Pharmacy"></Tab>
            </Tabs>
          </div>
          {PaperlessCard}
        </Container>
      </div>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <Container style={{ paddingBottom: 200 }}>
          <div
            style={{
              border: '1px solid #C9C9C9',
              borderRadius: 9,
              padding: '25px 30px',
              marginBottom: 30,
              width: 'max-content',
              marginTop: 30
            }}
          >
            <div className="d-flex align-items-center mb-2">
              {dependentsData?.dependents?.length !== 0 && (
                <>
                  <Form.Label className="text-nowrap mr-3 mt-2 fs-20">
                    Select member
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={onChange}
                    value={showcard}
                    style={{ width: 'auto' }}
                  >
                    <option value="">All Members</option>
                    <option value={memberData?.member_id}>
                      {memberData?.name?.first_name +
                        ' ' +
                        memberData?.name?.last_name}
                    </option>
                    {dependentsData?.dependents?.map((dep: any) => (
                      <option key={dep.member_id} value={dep.member_id}>{`${
                        dep?.name?.first_name || 'Unknown Member'
                      } ${dep?.name?.last_name || ''}`}</option>
                    ))}
                  </Form.Control>
                </>
              )}
              <Form.Label className="text-nowrap mx-3 mt-2 fs-20">
                Select Network
              </Form.Label>
              <Form.Control
                as="select"
                onChange={onNetworkChange}
                style={{ width: 'auto' }}
                value={JSON.stringify(inNetworkFilter)}
              >
                <option value={'true'}>In Network</option>
                <option value={'false'}>Out of Network</option>
              </Form.Control>
            </div>
            {isAccumsError !== true && (
              <Row noGutters>
                <Col>
                  <Row noGutters>
                    <AccumsCards
                      showcard={showcard}
                      accumsData={accumsData}
                      memberId={memberId}
                      inNetwork={inNetworkFilter}
                    />
                  </Row>
                </Col>
              </Row>
            )}
          </div>
          {claimTypeKey === 'rx' && (
            <div className="d-flex align-items-center justify-content-end pb-4">
              <ClaimFilters
                setClaimStatusFilter={setClaimStatusFilter}
                isRx={claimTypeKey === 'rx'}
              />
            </div>
          )}

          {claimTypeKey === 'medical' ? (
            <Table
              paginationConfig={
                data?.claims?.length ? paginationConfig : undefined
              }
              headers={headers}
              headerClassName="thead-light"
              loading={isLoadingClaims}
              error={isError}
              errorElement={
                error && (error as APIError).status === 403 ? (
                  <p style={{ textAlign: 'center' }}>
                    To protect the confidentiality of insured dependents who are
                    18 years of age or older, you cannot view this information.
                  </p>
                ) : undefined
              }
            >
              <ClaimTableBody
                items={data?.claims ? data?.claims : []}
                isHomebase={isHomebase}
              />
            </Table>
          ) : (
            <Table
              paginationConfig={
                rxData?.claims?.length ? paginationConfigRx : undefined
              }
              headers={rxHeaders}
              headerClassName="thead-light"
              loading={isLoadingRx}
              error={isErrorRx}
              errorElement={
                errorRx && (errorRx as APIError).status === 403 ? (
                  <p style={{ textAlign: 'center' }}>
                    To protect the confidentiality of insured dependents who are
                    18 years of age or older, you cannot view this information.
                  </p>
                ) : undefined
              }
            >
              <ClaimTableBody
                items={rxData?.claims ? rxData?.claims : []}
                isRx={true}
                isHomebase={isHomebase}
              />
            </Table>
          )}
        </Container>
      )}
    </div>
  )
}
export default ClaimsTable
