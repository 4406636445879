import { Page, Text, View } from '@react-pdf/renderer'
import React from 'react'

import { ReportTableInfo } from './ReportTable'
import PDFTable from './PDFTable/PDFTable'
import tableStyles from './PDFTable/tableStyles'
import TopBanner from './TopBanner/TopBanner'

const ReportPage = ({
  title,
  groupName,
  description,
  table,
  extra,
  footer
}: ReportTableInfo) => (
  <Page
    style={{
      paddingVertical: 10,
      paddingHorizontal: 10
    }}
    orientation="landscape"
  >
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
    >
      <TopBanner title="" />
      <View
        style={{
          marginBottom: 10
        }}
      >
        <Text
          style={{
            marginBottom: 3
          }}
        >
          {groupName} - {title}
        </Text>
        <Text style={[tableStyles.descriptionText, { marginBottom: 3 }]}>
          Created on: {new Date().toLocaleDateString()}
        </Text>
        <Text style={[tableStyles.descriptionText]}>{description}</Text>
      </View>
      <View>
        <PDFTable
          title={title}
          groupName={groupName}
          description={description}
          table={table}
          extra={extra}
          footer={footer}
        />
      </View>
    </View>
  </Page>
)

export default ReportPage
