import React from 'react'
import { BackMainSection } from './BackMainSection'
import { BackHeader } from './BackHeader'

export const Back: React.FC<{ data: any; showFrontBackHeaders?: boolean }> = ({
  data,
  showFrontBackHeaders
}) => {
  return (
    <div
      style={{
        maxWidth: 350,
        minWidth: 350,
        width: '100%',
        margin: '20px auto 0',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {showFrontBackHeaders && (
        <div className="font-weight-bold mb-2 pt-5">Back</div>
      )}
      <BackHeader data={data} />
      <BackMainSection data={data} />
    </div>
  )
}
