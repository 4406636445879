import { DateHandler } from '@angle/utils'
import { ApiService } from '../../base'
import {
  mapDtoToEmployeeList,
  mapDtoToProfile,
  mapDTOtoEditMemberForm,
  mapFormToDTO
} from './mappers'
import {
  Employee,
  MembersResponse,
  AddEmployee,
  EmployeeDTO,
  EmployeeProfileForm,
  ModifyEmployee
} from '../../../../interfaces/employer-broker-shared/employees'

// When displaying employer portal pages on the broker portal, pass groupId to these routes
export class EmployeeService extends ApiService {
  async getEmployees(
    pageNumber: number,
    pageLimit: number,
    searchTerm: string,
    groupId?: string
  ): Promise<{ employees: Employee[]; count: number }> {
    const url = '/members/search'
    return this.request({
      method: 'post',
      url: groupId ? `/groups/${groupId}${url}` : url,
      credentials: true,
      data: {
        page_number: pageNumber,
        page_limit: pageLimit,
        string: searchTerm,
        member_type: 'employee'
      }
    }).then((r: MembersResponse) => ({
      employees: r.members.map(mapDtoToEmployeeList),
      count: r.count
    }))
  }

  async requestIdCard(memberId: string, groupId?: string):Promise<any>{
    const baseUrl = `/members/${memberId}/request-new-id-card`
    const url = groupId ? `/groups/${groupId}${baseUrl}` : baseUrl
    return this.request({
      method: 'post',
      url: url,
      credentials: true
    }).then((r) => r)
  }

  async getAllEmployees(groupId?: string) {
    // if groupId is given, it's broker portal that's calling this, otherwise, it's employer portal
    const url = '/members'
    return this.request({
      method: 'get',
      url: groupId ? `/groups/${groupId}${url}` : url,
      credentials: true
    }).then((r) => r.members.filter((r: any) => r.member_type === 'employee'))
  }

  async getQlesForEmployee(memberId?: string | undefined) {
    const url = `/members/${memberId}/get-qle`
    const response = await this.request({
      method: 'get',
      url: url,
      credentials: true
    });
    return response;
  }

  async getEmployeeProfile(memberId: string, groupId?: string) {
    const url = `/members/${memberId}`
    const response: EmployeeDTO = await this.request({
      method: 'get',
      url: groupId ? `/groups/${groupId}${url}` : url,
      credentials: true
    })
    return mapDtoToProfile(response)
  }

  async getEmployeeIdCard(memberId: string, groupId?: string) {
    const url = `/members/${memberId}`
    const response = await this.request({
      method: 'get',
      url: groupId ? `/groups/${groupId}${url}` : url,
      credentials: true
    })
    return response
  }

  async createQLE(
    memberId: string,
    props: { date: string; reason: string },
    groupId?: string
  ) {
    const url = `/members/${memberId}/qle`
    return this.request({
      method: 'post',
      url: groupId ? `/groups/${groupId}${url}` : url,
      credentials: true,
      data: {
        qle_date: DateHandler.formatForServer(props.date),
        qle_reason: props.reason
      }
    })
  }

  async addEmployee(data: AddEmployee, groupId?: string): Promise<AddEmployee> {
    const url = '/members'
    return this.request({
      method: 'post',
      url: groupId ? `/groups/${groupId}${url}` : url,
      data: {
        ...data,
        member_type: 'employee'
      },
      credentials: true
    })
  }

  async modifyEmployee(
    memberId: string,
    data: EmployeeProfileForm,
    groupId?: string
  ): Promise<ModifyEmployee> {
    const baseUrl = `/members/${memberId}`
    const url = groupId ? `/groups/${groupId}${baseUrl}` : baseUrl

    return this.request({
      method: 'patch',
      url,
      credentials: true,
      data: mapFormToDTO(data)
    })
  }

  async getEmployeeById(id: string, groupId?: string) {
    const baseUrl = `/members/${id}`
    const url = groupId ? `/groups/${groupId}${baseUrl}` : baseUrl
    return this.request({
      method: 'get',
      url,
      credentials: true
    })
  }

  async getMemberForForm(memberId: string, groupId?: string) {
    const baseUrl = `/members/${memberId}`
    const url = groupId ? `/groups/${groupId}${baseUrl}` : baseUrl
    const response: EmployeeDTO = await this.request({
      method: 'get',
      url,
      credentials: true
    })
    return mapDTOtoEditMemberForm(response)
  }

  async disenroll(
    memberId: string,
    props: { date: string; reason: string },
    groupId?: string
  ) {
    const baseUrl = `/members/${memberId}/termination`
    const url = groupId ? `/groups/${groupId}${baseUrl}` : baseUrl
    return this.request({
      method: 'post',
      url,
      credentials: true,
      data: {
        termination_date: props.date,
        termination_reason: props.reason
      }
    })
  }
}
