import { ApiService } from 'react-angle-dashboard-components'

export class TempAuthService extends ApiService {
  async tempAuthNoPW(code: string) {
    return this.request({
      method: 'post',
      url: '/temp-auth/no-pw',
      data: {
        code
      }
    })
  }
}
