import React, { Dispatch, SetStateAction } from 'react'
import { Col, Form, Row, Button } from 'react-angle-dashboard-components'
import { Controller, Control } from 'react-hook-form'
import { OtherCarrierRow } from './OtherCarrierRow'

interface OtherInsuranceProps {
  control: Control
  otherInsurance: 'true' | 'false'
  otherCarriers: string[]
  setOtherInsurance: Dispatch<SetStateAction<'true' | 'false'>>
  setOtherCarriers: Dispatch<SetStateAction<string[]>>
}

export const OtherInsurance: React.FC<OtherInsuranceProps> = ({
  control,
  otherInsurance,
  otherCarriers,
  setOtherInsurance,
  setOtherCarriers
}) => {
  return (
    <>
      <Row>
        <Col xs={12} md={7}>
          <Form.Label className="required-field">
            Will this employer/organization also offer coverage through another
            group health plan?
          </Form.Label>
        </Col>
      </Row>
      <Form.Group>
        <Row>
          <Col xs={12} md={7}>
            <Controller
              name="otherInsurance"
              control={control}
              render={({ field }) => (
                <Form.Check
                  {...field}
                  className="custom-radio"
                  type="radio"
                  value="false"
                  label="No"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setOtherInsurance('false')
                    setOtherCarriers([])
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={7}>
            <Controller
              name="otherInsurance"
              control={control}
              render={({ field }) => (
                <Form.Check
                  {...field}
                  className="custom-radio"
                  type="radio"
                  value="true"
                  label="Yes"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setOtherInsurance('true')
                  }}
                />
              )}
            />
          </Col>
        </Row>
        {otherInsurance === 'true' && (
          <div>
            {otherCarriers?.map((carrier: string, i: number) => {
              return (
                <OtherCarrierRow
                  key={i}
                  idx={i}
                  setOtherCarriers={setOtherCarriers}
                  otherCarriers={otherCarriers}
                />
              )
            })}
            <Button
              variant="link"
              className="fs-18 px-0 py-0 mx-0"
              onClick={() => {
                setOtherCarriers((prevState: string[]) => {
                  const newState = [...prevState, '']
                  return newState
                })
              }}
            >
              <i className="icon icon-plus" style={{ marginLeft: -15 }} />
              <span>Add another carrier</span>
            </Button>
          </div>
        )}
      </Form.Group>
    </>
  )
}
