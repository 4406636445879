import React, { useRef } from 'react'
import { FormCheckProps } from 'react-bootstrap'
import './index.css'

interface CheckBoxProps extends FormCheckProps {
  containerProps?: Partial<React.HTMLAttributes<HTMLDivElement>>
  labelProps?: Partial<React.LabelHTMLAttributes<HTMLLabelElement>>
}

export const Checkbox: React.FC<CheckBoxProps> = (props) => {
  const { containerProps = {}, labelProps = {}, ...inputProps } = props

  const ref = useRef<HTMLInputElement>()

  return (
    <>
      <div
        {...containerProps}
        onClick={() => {
          if (ref.current) {
            inputProps.onChange &&
              inputProps.onChange({
                target: {
                  value: !ref.current.checked ? 'on' : '',
                  checked: !ref.current.checked,
                  name: inputProps.name
                }
              } as any)

            ref.current.checked = !ref.current.checked
            ref.current.value = !ref.current.checked as any
          }
        }}
        className={'checkbox-custom ' + containerProps?.className || ''}
      >
        <input ref={ref as any} type="checkbox" {...inputProps} />
        <label htmlFor={inputProps.id}></label>
      </div>
      <label
        {...labelProps}
        className={'label-text ' + labelProps?.className || ''}
      >
        {inputProps.label}
      </label>
    </>
  )
}
