import { useQueryParams } from '@angle/hooks'
import React from 'react'
import {
  CenteredSpinner,
  ErrorPageLayout
} from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { ApiService, tempAuthService } from '../../services'
import { PageLayout } from '../layouts/PageLayout'

// this temp auth page is currently being used by Homebase users to log in to an employer's account as a superuser

export const TempAuth = () => {
  const history = useHistory()
  const params = useQueryParams()
  const token = params.get('token') || ''

  const { isLoading, isError } = useQuery(
    ['tempAuthNoPw', token],
    () => tempAuthService.tempAuthNoPW(token),
    {
      enabled: !!token,
      onSuccess: (data) => {
        if (data.access_token) {
          ApiService._token = data.access_token
          localStorage.setItem('token', data.access_token)
        }
        history.push(`/`)
      }
    }
  )

  if (isLoading) {
    return (
      <PageLayout noMenu>
        <CenteredSpinner />
      </PageLayout>
    )
  }
  if (!token || isError) {
    return (
      <PageLayout noMenu>
        {' '}
        <ErrorPageLayout
          errorTitle="Invalid Link"
          errorSubtitle={
            'This link is invalid. Please try again or reach out to support to obtain a new link.'
          }
        />
      </PageLayout>
    )
  }

  return <div />
}

export default TempAuth
