export const paginate = (
  items: any[],
  amountPerPage: number,
  currentPage: number
) => {
  const startingIndex = (currentPage - 1) * amountPerPage
  const paginated: any[] = items.slice(
    startingIndex,
    startingIndex + amountPerPage
  )
  const totalPages = Math.ceil(items.length / amountPerPage)

  return { paginated, totalPages }
}
