import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { DateHandler } from '@angle/utils'
import { useToaster } from '../Toast'
import { Button } from '../Button'
import { CenteredSpinner } from '../CenteredSpinner'
import { Row, Modal } from '../../'
import { useMutation } from 'react-query'
import { EmployeeService, MemberService } from '../../services'

export const CardRequester: React.FC<{
  memberId: string
  isAdmin: boolean
  cardRequests: Array<any>
  employeeService: EmployeeService | MemberService
  refetchMemberData: () => {}
  isRefetching: boolean
}> = ({
  memberId,
  isAdmin,
  cardRequests,
  employeeService,
  refetchMemberData,
  isRefetching
}) => {
  const { showSuccess, showError } = useToaster()
  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState(false)
  const requestIdCard = useMutation({
    mutationFn: () => employeeService.requestIdCard(memberId),
    onSuccess: (response) => {
      if (response.success === true) {
        showSuccess('Successfully made request to re-issue a New ID Card.')
        refetchMemberData()
      }
    },
    onError: (error) => {
      showError(`Error re-issuing ID Card - ${error}`)
    }
  })

  const requestCard = async () => {
    await requestIdCard.mutateAsync()
  }
  const currDay = new Date()
  const tenDaysAgo = currDay.setDate(currDay.getDate() - 10)
  const isThereRecentRequestNotCancelled =
    cardRequests.filter(
      (cardRequest) =>
        new Date(cardRequest.requested_at).getTime() >= tenDaysAgo &&
        cardRequest.status !== 'cancelled'
    ).length > 0

  return (
    <>
      <Modal
        show={isConfirmationModalVisible}
        centered
        onHide={() => setConfirmationModalVisible(false)}
        dialogClassName="customPlanFormModalDialog"
        className="customPlanFormModal"
        style={{
          zIndex: 2000
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          There already is a request to generate an ID card that has not been
          cancelled within the last ten days. Do you still wish to proceed?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-primary"
            onClick={() => setConfirmationModalVisible(false)}
          >
            Cancel
          </Button>
          <Button
            variant='danger'
            disabled={requestIdCard.isLoading}
            onClick={() => {
              requestCard()
              setConfirmationModalVisible(false)
            }}
          >
            Reissue Card
          </Button>
        </Modal.Footer>
      </Modal>
      <Table striped hover size="sm">
        <thead
          className="text-center"
          style={{ backgroundColor: '#6d39d9', color: '#FFFFFF' }}
        >
          <tr
            className="text-white"
            style={{ backgroundColor: '#6d39d9', color: '#FFFFFF' }}
          >
            <td>Requested</td>
            <td>Status</td>
            <td>Effective Date</td>
            <td>Plan Name</td>
          </tr>
        </thead>
        <tbody className="text-center">
          {isRefetching ? (
            <tr>
              <td colSpan={4}>
                <CenteredSpinner style={{ height: '5vh' }} />
              </td>
            </tr>
          ) : (
            cardRequests.map((cardRequest, i) => (
              <tr key={i}>
                <td>
                  {DateHandler.formatFullDatetime(cardRequest.requested_at)}
                </td>
                <td className="text-capitalize">{cardRequest.status}</td>
                <td>
                  {DateHandler.formatFullDate(
                    cardRequest.allcoverage.effective_date
                  )}
                </td>
                <td>{cardRequest.allcoverage.plan_name}</td>
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <Row
                className="d-flex justify-content-center align-items-center"
                style={{ backgroundColor: '#FFF' }}
              >
                <Button
                  variant="outline-primary"
                  disabled={
                    requestIdCard.isLoading ||
                    (!isAdmin && isThereRecentRequestNotCancelled)
                  }
                  className="w-100"
                  onClick={() => {
                    if (isThereRecentRequestNotCancelled) {
                      setConfirmationModalVisible(true)
                    } else {
                      requestCard()
                    }
                  }}
                >
                  Re-Issue ID Card
                </Button>
              </Row>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  )
}
