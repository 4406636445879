import React, { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import ReactInputMask from 'react-input-mask'

import {
  Button,
  Card,
  Col,
  Form,
  Row
} from  '../../../index'
import { limitLength, states } from '@angle/utils'
import styled from 'styled-components'

export const ErrorSpan = styled.span`
  display: inline-block;
  margin-top: 5px;
  color: var(--danger);
`

export type EmployeeBasicInfoFormFields = {
  anotherPlan: {
    insurer: string
    policyNumber: string
    coverageStartDate: string
    coverageEndDate: string
    coverageTypes: string[]
  }
  dob: string
  firstName: string
  genderIdentity?: string
  isCobra: boolean // TODO 2473 may have to change key
  lastName: string
  medicareEligible: boolean // TODO 2473 may have to change key
  midName?: string
  sex: string
  ssn: string
  suffix?: string
  homeAddress: {
    street_1: string
    street_2: string
    city: string
    state: string
    zip: string
  }
}

export const EmployeeBasicInfoForm: React.FC<{
  handleCancelCallback?: () => void
  returnDataToParent?: (data: EmployeeBasicInfoFormFields) => void
  defaultData?: EmployeeBasicInfoFormFields
}> = ({ handleCancelCallback, returnDataToParent, defaultData }) => {
  const [hasAnotherPlan, setHasAnotherPlan] = useState<boolean>(false)
  const [coverageStartDateErrorMsg, setCoverageStartDateErrorMsg] = useState<string>('')
  const [coverageEndDateErrorMsg, setCoverageEndDateErrorMsg] = useState<string>('')
  const [hasOtherCoverageType, setHasOtherCoverageType] = useState<boolean>(false)
  const [otherCoverageType, setOtherCoverageType] = useState<string>('')

  const { register, control, watch, handleSubmit, formState, trigger } =
    useForm({
      defaultValues: defaultData ? {
        ...defaultData
      } : {
        anotherPlan: {
          insurer: '',
          policyNumber: '',
          coverageStartDate: '',
          coverageEndDate: '',
          coverageTypes: []
        }, 
        dob: '',
        firstName: '',
        genderIdentity: '',
        isCobra : false,
        lastName: '',
        medicareEligible: false,
        midName: '',
        sex: '',
        ssn: '',
        suffix: '',
        homeAddress: {
          street_1: '',
          street_2: '',
          city: '',
          state: '',
          zip: ''
        }
      }
    })

  const errors = formState.errors

  const isCobra = watch('isCobra')
  const medicareEligible = watch('medicareEligible')

  const handleCancel = () => {
    if (handleCancelCallback) {
      handleCancelCallback()
    }
  }

  const handleContinue: SubmitHandler<EmployeeBasicInfoFormFields> = async (
    data
  ) => {
    await trigger()
    const hasErrors = Object.keys(errors).length > 0

    if (hasAnotherPlan && (data.anotherPlan.coverageStartDate === '' || hasAnotherPlan && data.anotherPlan.coverageEndDate === '')) {
      if (data.anotherPlan.coverageStartDate === '') {
        setCoverageStartDateErrorMsg('Coverage Start Date is required')
      } else {
        setCoverageStartDateErrorMsg('')
      }
  
      if (data.anotherPlan.coverageEndDate === '') {
        setCoverageEndDateErrorMsg('Coverage End Date is required')
      } else {
        setCoverageEndDateErrorMsg('')
      }

      return
    }

    if (!hasErrors) {
      if (
        hasOtherCoverageType &&
        otherCoverageType &&
        data.anotherPlan?.coverageTypes
      ) {
        data.anotherPlan.coverageTypes.push(otherCoverageType)
      }

      if (returnDataToParent) {
        /*
        this means the parent is the Add Employee Flow.
        Rather than make an API call, invoke returnDataToParent()
        */
        returnDataToParent(data)
      }
    }
  }

  return (
    <>
      <>
        <b className="pb-3">Basic Information</b>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                className="bg-white"
                isInvalid={Boolean(errors?.firstName)}
                {...register('firstName', {
                  required: 'This field is required'
                })}
              />
              <ErrorSpan>{errors?.firstName?.message}</ErrorSpan>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Middle Name (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Middle Name"
                className="bg-white"
                isInvalid={Boolean(errors?.midName)}
                {...register('midName')}
              />
              <ErrorSpan>{errors?.midName?.message}</ErrorSpan>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                className="bg-white"
                isInvalid={Boolean(errors?.lastName)}
                {...register('lastName', {
                  required: 'This field is required'
                })}
              />
              <ErrorSpan>{errors?.lastName?.message}</ErrorSpan>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Suffix (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Suffix"
                className="bg-white"
                isInvalid={Boolean(errors?.suffix)}
                {...register('suffix')}
              />
              <ErrorSpan>{errors?.suffix?.message}</ErrorSpan>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>What gender do you identify as?</Form.Label>
              <Form.Control
                as="select"
                className="bg-white"
                {...register('genderIdentity')}
                isInvalid={!!errors.genderIdentity}
              >
                <option value="" disabled>
                  Select gender identity
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="U">Female to Male</option>
                <option value="U">Male to Female</option>
                <option value="U">Non-Binary</option>
              </Form.Control>
              <ErrorSpan>{errors?.genderIdentity?.message}</ErrorSpan>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Sex (Gender at Birth)</Form.Label>
              <Form.Control
                as="select"
                className="bg-white"
                {...register('sex', {
                  required: 'This field is required'
                })}
                isInvalid={!!errors.sex}
              >
                <option value="" disabled>
                  Select birth gender
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </Form.Control>
              <ErrorSpan>{errors?.sex?.message}</ErrorSpan>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Date of Birth</Form.Label>
              <Controller
                key="dob"
                name="dob"
                control={control}
                rules={{
                  required: 'This field is required'
                }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select a date"
                    onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
                    selected={value ? moment(value, 'YYYY-MM-DD').toDate() : null}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                  />
                )}
              />
              <ErrorSpan>{errors?.dob?.message}</ErrorSpan>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Social Security Number</Form.Label>
              <Controller
                key="ssn"
                name="ssn"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    placeholder="Enter SSN"
                    className="bg-white"
                    isInvalid={!!errors?.ssn}
                    as={ReactInputMask}
                    mask="999-99-9999"
                    {...register('ssn', {
                      required: 'This field is required',
                      validate: {
                        invalidSsn: (val) => {
                          if (val === '000-00-0000' || val === '111-11-1111') {
                            return 'Please enter a valid social security number'
                          } else return true
                        }
                      }
                    })}
                    {...field}
                  />
                )}
              />
              <ErrorSpan>{errors?.ssn?.message}</ErrorSpan>
            </Form.Group>
          </Col>
        </Row>
        <Card
          className="mb-3"
          style={{
            borderWidth: '0.0625rem 0 0.0625rem 0',
            marginLeft: '-15px',
            marginRight: '-15px'
          }}
        >
          <Card.Header className="border-0" style={{ padding: '15px' }}>
            <Card.Title>Mailing Address</Card.Title>
          </Card.Header>
          <Card.Body style={{ padding: '15px' }}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    type="text"
                    className="bg-white"
                    isInvalid={Boolean(errors?.homeAddress?.street_1)}
                    placeholder="Street Address"
                    {...register('homeAddress.street_1', {
                      required: 'This field is required'
                    })}
                  />
                  <ErrorSpan>
                    {errors?.homeAddress?.street_1?.message}
                  </ErrorSpan>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Street Address Line 2</Form.Label>
                  <Form.Control
                    type="text"
                    className="bg-white"
                    isInvalid={Boolean(errors?.homeAddress?.street_2)}
                    placeholder="Street Address Line 2"
                    {...register('homeAddress.street_2')}
                  />
                  <ErrorSpan>
                    {errors?.homeAddress?.street_2?.message}
                  </ErrorSpan>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    className="bg-white"
                    isInvalid={Boolean(errors?.homeAddress?.city)}
                    placeholder="City"
                    {...register('homeAddress.city', {
                      required: 'This field is required'
                    })}
                  />
                  <ErrorSpan>{errors?.homeAddress?.city?.message}</ErrorSpan>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    placeholder="Select a state"
                    as="select"
                    className="bg-white"
                    isInvalid={Boolean(errors?.homeAddress?.state)}
                    {...register('homeAddress.state', {
                      required: 'This field is required'
                    })}
                  >
                    <option value="" disabled>
                      Select a state
                    </option>
                    {Object.keys(states).map((s) => (
                      <option key={s} value={s}>
                        {(states as any)[s]}
                      </option>
                    ))}
                  </Form.Control>
                  <ErrorSpan>{errors?.homeAddress?.state?.message}</ErrorSpan>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Zip Code</Form.Label>
                  <Controller
                    name="homeAddress.zip"
                    control={control}
                    rules={{
                      required: 'This field is required',
                      minLength: {
                        value: 5,
                        message: 'Zip Code should be 5 digits'
                      }
                    }}
                    render={({ field }) => (
                      <Form.Control
                        className="bg-white"
                        isInvalid={Boolean(errors?.homeAddress?.zip)}
                        placeholder="Zip Code"
                        type="number"
                        {...register('homeAddress.zip')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          field.onChange(e)
                          limitLength(e, 5, field.onChange)
                        }}
                      />
                    )}
                  />
                  <ErrorSpan>{errors?.homeAddress?.zip?.message}</ErrorSpan>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card
          className="mb-3"
          style={{
            borderWidth: '0 0 0.0625rem 0',
            borderBottom: 'none',
            marginLeft: '-15px',
            marginRight: '-15px'
          }}
        >
          <Card.Header className="border-0" style={{ padding: '15px' }}>
            <Card.Title>Other</Card.Title>
          </Card.Header>
          <Card.Body style={{ padding: '5px 15px 5px 15px' }}>
            <div
              style={{
                paddingLeft: '-15px',
                paddingRight: '-15px'
              }}
            >
              <Card
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  minHeight: '7vh'
                }}
              >
                <Col style={{ flexGrow: 9 }}>
                  Is the member on COBRA or state continuation?
                </Col>
                <Col className="text-right" style={{ display: 'flex' }}>
                  <Form.Check
                    type="switch"
                    id="isCobra"
                    {...register('isCobra')}
                  />
                  {isCobra ? 'Yes' : 'No'}
                </Col>
              </Card>
            </div>
          </Card.Body>
          <Card.Body style={{ padding: '5px 15px 5px 15px' }}>
            <div
              style={{
                paddingLeft: '-15px',
                paddingRight: '-15px'
              }}
            >
              <Card
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  minHeight: '7vh'
                }}
              >
                <Col style={{ flexGrow: 9 }}>
                  Is the member eligible for Medicare?
                </Col>
                <Col className="text-right" style={{ display: 'flex' }}>
                  <Form.Check
                    type="switch"
                    id="medicareEligible"
                    {...register('medicareEligible')}
                  />
                  {medicareEligible ? 'Yes' : 'No'}
                </Col>
              </Card>
            </div>
          </Card.Body>
          <Card.Body style={{ padding: '5px 15px 5px 15px' }}>
            <div
              style={{
                paddingLeft: '-15px',
                paddingRight: '-15px'
              }}
            >
              <Card
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  minHeight: '7vh'
                }}
              >
                <Col style={{ flexGrow: 9 }}>
                  Does the member expect to keep another health insurance
                  plan/policy?
                </Col>
                <Col className="text-right" style={{ display: 'flex' }}>
                  <Form.Check
                    type="switch"
                    id="hasAnotherPlan"
                    checked={hasAnotherPlan}
                    onChange={() => setHasAnotherPlan(!hasAnotherPlan)}
                  />
                  {hasAnotherPlan ? 'Yes' : 'No'}
                </Col>
              </Card>
            </div>
          </Card.Body>
        </Card>
        {hasAnotherPlan && (<>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Insurer</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Insurer Name"
                  className="bg-white"
                  isInvalid={Boolean(errors?.anotherPlan?.insurer)}
                  {...register('anotherPlan.insurer', {
                    required: { value: hasAnotherPlan, message: 'This field is required' }
                  })}
                />
                <ErrorSpan>{errors?.anotherPlan?.insurer?.message}</ErrorSpan>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Policy Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Policy number"
                  className="bg-white"
                  isInvalid={Boolean(errors?.anotherPlan?.policyNumber)}
                  {...register('anotherPlan.policyNumber', {
                    required: { value: hasAnotherPlan, message: 'This field is required' }
                  })}
                />
                <ErrorSpan>{errors?.anotherPlan?.policyNumber?.message}</ErrorSpan>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Coverage Start Date</Form.Label>
                <Controller
                  key="anotherPlan.coverageStartDate"
                  name="anotherPlan.coverageStartDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select a date"
                      onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
                      selected={value ? moment(value, 'YYYY-MM-DD').toDate() : null}
                      dateFormat="MM/dd/yyyy"
                    />
                  )}
                />
                <ErrorSpan>{coverageStartDateErrorMsg}</ErrorSpan>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Coverage End Date</Form.Label>
                <Controller
                  key="anotherPlan.coverageEndDate"
                  name="anotherPlan.coverageEndDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select a date"
                      onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
                      selected={value ? moment(value, 'YYYY-MM-DD').toDate() : null}
                      dateFormat="MM/dd/yyyy"
                    />
                  )}
                />
                <ErrorSpan>{coverageEndDateErrorMsg}</ErrorSpan>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Type of coverage (Check all that apply)</Form.Label>
                <Controller
                  name="anotherPlan.coverageTypes"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Form.Check
                        type="checkbox"
                        label="Employer Group"
                        value="employer"
                        checked={value.includes("employer")}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          onChange(
                            isChecked
                              ? [...value, e.target.value]
                              : value.filter((item) => item !== e.target.value)
                          )
                        }}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Individual"
                        value="individual"
                        checked={value.includes("individual")}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          onChange(
                            isChecked
                              ? [...value, e.target.value]
                              : value.filter((item) => item !== e.target.value)
                          )
                        }}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Medicare"
                        value="medicare"
                        checked={value.includes("medicare")}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          onChange(
                            isChecked
                              ? [...value, e.target.value]
                              : value.filter((item) => item !== e.target.value)
                          )
                        }}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Governmental"
                        value="government"
                        checked={value.includes("government")}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          onChange(
                            isChecked
                              ? [...value, e.target.value]
                              : value.filter((item) => item !== e.target.value)
                          )
                        }}
                      />
                      <Form.Check
                        type="checkbox"
                        value="other"
                        checked={hasOtherCoverageType}
                        onChange={() => { setHasOtherCoverageType(!hasOtherCoverageType) }}
                      />
                      <Form.Control
                        type="text"
                        placeholder="Other"
                        className="bg-white "
                        value={otherCoverageType}
                        onChange={(e) => { 
                          setOtherCoverageType(e.target.value)
                          if (e.target.value && !hasOtherCoverageType) {
                            setHasOtherCoverageType(true)
                          } else if (!e.target.value && hasOtherCoverageType) {
                            setHasOtherCoverageType(false)
                          }
                        }}
                        style={{ width: '50%' }}
                      />
                    </>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </>)}
      </>
      <hr />
      <Row>
        <Col xs={12} md={6} className="pb-5">
          <Button
            className="float-left text-primary"
            variant="outline-primary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12} md={6}>
          <Button
            className="float-right"
            onClick={handleSubmit(handleContinue)}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </>
  )
}
