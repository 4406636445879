import React from 'react'
import { Col, Row } from 'react-bootstrap'

export const MPCNFooter = () => {
  return (
    <Row style={{ paddingBottom: 0, marginBottom: 0 }}>
      <Col
        className="text-left d-flex align-items-end pr-0"
        xs={7}
        style={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <p className="fs-10 pt-2">
          Find your Angle Health MPCN provider at{' '}
          <span className="font-weight-bold">www.mpcn-ms.com</span>
        </p>
      </Col>
      <Col style={{ textAlign: 'right' }}>
        <div>
          <img
            src="/mpcn.png"
            style={{ width: 100, marginTop: 5 }}
            alt="mpcn-logo"
          />
        </div>
      </Col>
    </Row>
  )
}
