import React from 'react'
import GoogleMapReact from 'google-map-react'

import { Form, Row, Col } from 'react-bootstrap'
import { OfficeDetail, OfficeDetailTitle } from './style'

const LocationDropdown = ({
  items,
  onLocationSelect,
  selectedLocationIndex
}) => (
  <Row>
    <Col>
      <Form.Group style={{ width: '100%' }}>
        <Form.Control
          as="select"
          style={{ width: '100%' }}
          onChange={(e) => onLocationSelect(+e.target.value)}
          value={selectedLocationIndex}
        >
          {items.map((location, index) => (
            <option key={location.id} value={index}>
              {location.name.replace(/\b\w/g, (l: any) => l.toUpperCase())}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </Col>
  </Row>
)

export interface IDetail {
  value: string
  name: string
}

export interface ILocationDetail {
  coordinates: { lat: number; lng: number }
  details: IDetail[]
  name: string
  id: string | number
}

export interface IProfileLocationProps {
  locations: ILocationDetail[]
  zoom: number
  apiKey: string
  [x: string]: any
}

export const ProfileLocation: React.FC<IProfileLocationProps> = ({
  locations = [],
  apiKey,
  selectedLocationIndex,
  setSelectedLocationIndex,
  zoom
}) => {
  if (!locations.length) {
    return null
  }

  return (
    <React.Fragment>
      <LocationDropdown
        items={locations}
        onLocationSelect={setSelectedLocationIndex}
        selectedLocationIndex={selectedLocationIndex}
      />
      <Row>
        <Col sm={8}>
          <div style={{ width: '100%', height: '250px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: apiKey }}
              zoom={zoom}
              center={locations[selectedLocationIndex].coordinates}
              yesIWantToUseGoogleMapApiInternals
            />
          </div>
        </Col>
        <Col sm={4}>
          <OfficeDetailTitle className="fs-16 text-capitalize">
            <strong>{'Office Details'}</strong>
          </OfficeDetailTitle>

          {locations[selectedLocationIndex].details.map((detail, key) => (
            <OfficeDetail key={key}>
              <p className="text-dark">{detail.value}</p>
              <small className="text-muted">{detail.name}</small>
            </OfficeDetail>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  )
}
