import React from 'react'
import { EmployeeService, PageContainer, PageHeaderLight } from '../../../index'
import { DisenrollEmployeeContent } from './DisenrollEmployeeContent'

export type DisenrollLocationState = {
  reason: string
  date: string
  name: string
}

export const EmployerPortalDisenrollEmployee: React.FC<{
  isBrokerPortal?: boolean
  employeeService: EmployeeService
}> = ({ isBrokerPortal, employeeService }) => {
  return (
    <>
      <PageHeaderLight>
        <PageContainer size="md">
          <h1>Disenroll Employee</h1>
        </PageContainer>
      </PageHeaderLight>
      <DisenrollEmployeeContent
        isBrokerPortal={isBrokerPortal}
        employeeService={employeeService}
      />
    </>
  )
}
