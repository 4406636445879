import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  PageHeaderLight,
  PageContainer,
  SectionTitle,
  ManuallyAddBankAccountForm,
  CenteredSpinner,
  useAuth
} from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { PageLayout } from '../../layouts/PageLayout'
import { paymentsService } from '../../../services/index'
import { Paths } from '../../../routes/paths'

export const ManuallyAddBankAccount = () => {
  const history = useHistory()
  const [isAdding, setIsAdding] = useState(false)
  const auth = useAuth()

  const { register, handleSubmit, setValue, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      lastName: '',
      accountType: '',
      routingNumber: '',
      accountNumber: '',
      acceptedTermsAndConditions: false
    }
  })

  useEffect(() => {
    setValue(
      'name',
      auth?.state?.user?.first_name + ' ' + auth?.state?.user?.last_name
    )
  }, [auth?.state?.user?.first_name, auth?.state?.user?.last_name, setValue])

  const registerFunc = register as any

  const handleAddAccount = handleSubmit((data) => {
    setIsAdding(true)

    const account = {
      account_number: data.accountNumber,
      routing_number: data.routingNumber,
      account_type: data.accountType,
      name: data.name
    }
    paymentsService.addAchAccount(account).then(() => {
      setIsAdding(false)
      history.push(Paths.settingsBilling)
    })
  })

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Settings', link: '/settings' },
        { title: 'Add a new bank account' }
      ]}
    >
      {isAdding ? (
        <CenteredSpinner />
      ) : (
        <>
          <PageHeaderLight>
            <PageContainer size="md">
              <h1>Add a new bank account</h1>
            </PageContainer>
          </PageHeaderLight>
          <PageContainer size="md" className="mb-5">
            <SectionTitle>Account Information</SectionTitle>
            <ManuallyAddBankAccountForm
              register={registerFunc}
              onAddBankAccount={handleAddAccount}
              onCancelButtonClick={() => history.push(Paths.settingsBilling)}
              formState={formState}
            />
          </PageContainer>
        </>
      )}
    </PageLayout>
  )
}
