import React from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Avatar } from '../Avatar'
import { Beneficiary } from './Beneficiary'
import { useQuery } from 'react-query'
import { GroupService } from '../../services'

export interface SetDefaultHandlerProps {
  itemId: string
  accountId: string
}

export interface IAccountProps {
  isDefault: boolean
  markAccountAsDefault?: boolean
  bankName: string
  lastNumbers?: string
  itemId: string
  accountId: string
  routing_number?: string
  name?: string
  isAutoPay?: boolean
  has_issues: boolean
  onUpdateButtonClick?: () => void
  onRemoveButtonClick?: () => void
  onSetDefaultHandler?: (arg: any) => void
  type?: string
  groupService?: GroupService
  groupId?: string
}

export const Account: React.FC<IAccountProps> = ({
  isDefault,
  bankName,
  lastNumbers,
  itemId,
  accountId,
  has_issues,
  markAccountAsDefault = true,
  type,
  groupService,
  groupId,
  routing_number,
  name,
  isAutoPay,

  onUpdateButtonClick = () => { },
  onRemoveButtonClick = () => { },
  onSetDefaultHandler = () => { }
}) => {
  var content
  if (!has_issues) {
    content = (
      <div
        style={{ height: '100%' }}
        className="d-inline-block d-flex align-items-center ml-3"
      >
        {bankName}
      </div>
    )
  } else {
    content = (
      <div className="d-inline-block ml-3" style={{ width: 300 }}>
        <p className="mb-0">{bankName}</p>

        <span className="text-danger">
          {'There is something wrong with this account'}
        </span>
      </div>
    )
  }

  const handleDefaultClick = () => {
    const data = {
      account_id: accountId,
      account_type: type
    }

    onSetDefaultHandler(data)
  }

  let beneficiary;
  if (type === 'ach' && groupId) {
    beneficiary = useQuery(['groupBeneficiaryAccount', groupId], () =>
      groupService?.getBeneficiary(groupId)
    )
  }

  return (
    <Row
      className="py-3 border-bottom"
      style={{ marginLeft: 0, marginRight: 0 }}
    >
      <Col xs={12} md={8}>
        <Row>
          <Col className="pl-0 d-flex align-items-center">
            <Form.Check
              className="d-inline mr-2 custom-radio"
              defaultChecked={isDefault}
              name={'account-check'}
              type="radio"
              id={'account-check-' + itemId}
              onChange={handleDefaultClick}
              disabled={has_issues}
            />

            <Avatar
              circle
              fallbackContent={<i className="text-white icon icon-bank"></i>}
            />

            {isDefault && markAccountAsDefault && !has_issues ? (
              <div className="d-inline-block ml-3" style={{ width: 200 }}>
                <p className="mb-0">{bankName}</p>

                <span className="text-muted">{'Default payment method'}</span>
              </div>
            ) : (
              content
            )}
          </Col>
        </Row>
      </Col>

      {has_issues && type !== 'ach' ? (
        <Col xs={12} md={2}>
          <Button variant="link" onClick={onUpdateButtonClick}>
            Update
          </Button>
        </Col>
      ) : (
        <Col
          xs={12}
          md={2}
          className="text-muted d-flex align-items-center justify-content-end"
        >
          {lastNumbers && <>**** {lastNumbers}</>}
        </Col>
      )}

      <Col xs={12} md={2}>
        <Button variant="link" onClick={onRemoveButtonClick}>
          Remove
        </Button>
      </Col>

      {['ach', 'plaid'].includes(type || '') && <Beneficiary
        beneficiaryData={beneficiary?.data}
        isAutopay={isAutoPay}
        bankName={bankName}
        lastNumbers={lastNumbers}
        routing_number={routing_number}
        name={name} />}

      {type == 'check' && <div className="bg-light p-4 mt-3" style={{ borderRadius: "8px" }}>
        <strong>Please remit payments to:</strong>
        <br />
        <span className="text-muted">
          Adrem Administrators<br />
          2261 Market Street #4265<br />
          San Francisco, CA 94114
        </span>
        <br />
        <br />
        <span >
          * Additional fees to process paper checks will apply EACH billing period. Check processing fees may exceed $100 each month.
        </span>
      </div>}
    </Row>
  )
}
