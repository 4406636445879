import React from 'react'
import moment from 'moment'
import WarningIcon from './WarningIcon'

export const SummaryLabelValue: React.FC<{ summary: any; label: string }> = ({
  summary,
  label
}) => {
  if (['coverageStart', 'coverageEnd'].includes(label) && summary[label]) {
    const date = moment(summary[label])
    if (label === 'coverageEnd') {
      const isUpcomingRenewal = date.diff(moment(), 'days') <= 30
      return (
        <>
          {isUpcomingRenewal && (
            <span className="mr-2">
              <WarningIcon />
            </span>
          )}
          {date.format('MM/DD/YYYY')}
        </>
      )
    }
    return date.format('MM/DD/YYYY')
  }
  return summary[label] || '-'
}
