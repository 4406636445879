import * as React from 'react'
import { ReactNode } from 'react'
import { Button } from './Button'

type ISaveForLaterButton = {
  isSaved?: boolean
  children?: ReactNode
  onClick?: Function
}

export const SaveForLaterButton: React.FC<ISaveForLaterButton> = (
  props: ISaveForLaterButton
) => {
  const { isSaved = false, children, onClick } = props
  const defaultText = !isSaved ? 'Save for later' : 'Saved for later'

  return (
    <Button
      variant={isSaved ? 'primary' : 'light'}
      ghost={!isSaved}
      onClick={onClick}
      border
    >
      <span className={isSaved ? 'text-light' : 'text-primary'}>
        <i
          style={{
            display: 'contents'
          }}
          className={`icon fs-12 ${
            isSaved ? 'icon-heart-filled' : 'icon-heart'
          }`}
        />{' '}
        <span className="ml-1">{children || defaultText}</span>
      </span>
    </Button>
  )
}

export default SaveForLaterButton
