import React, { useState } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import { Location } from '../Directory'

import {
  ClearButton,
  EllipsisSpan,
  LocationIcon,
  LocationSearchContainer,
  ResultsDropdown,
  SearchInput,
  SearchResult
} from './styles'

export interface ILocationSearchField {
  onLocationSelected: (l: Location) => void
  apiKey?: string
  initialValue?: string
  [x: string]: any
}

export const LocationSearchField: React.FC<ILocationSearchField> = (props) => {
  const { onLocationSelected, initialValue = '', ...p } = props

  const [searchTerm, setSearchTerm] = useState(initialValue)

  const clearSearch = () => {
    setSearchTerm('')
  }

  const buildHighlightedResult = ({ description: result }) => {
    const matchIndex = result.toLowerCase().indexOf(searchTerm.toLowerCase())
    const firstPortion = result.slice(0, matchIndex)
    const secondPortion = result.slice(
      matchIndex,
      matchIndex + searchTerm.length
    )
    const thirdPortion = result.slice(matchIndex + searchTerm.length)

    return (
      <EllipsisSpan>
        <span>{firstPortion}</span>
        <span className="text-primary">{secondPortion}</span>
        <span>{thirdPortion}</span>
      </EllipsisSpan>
    )
  }

  const handleSelect = (address) => {
    setSearchTerm(address)
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => onLocationSelected(latLng))
  }

  return (
    <LocationSearchContainer {...p}>
      <PlacesAutocomplete
        value={searchTerm}
        onChange={setSearchTerm}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <SearchInput
              {...getInputProps({
                className: 'fs-18 location-search-input',
                ariaLabel: 'location-input'
              })}
              placeholder="Enter an address"
            />
            <LocationIcon className="icon icon-marker" />
            {searchTerm && (
              <ClearButton
                onClick={clearSearch}
                className="icon icon-cancel fs-14"
              />
            )}
            {suggestions.length > 0 && (
              <ResultsDropdown className="shadow autocomplete-dropdown-container">
                {suggestions.map((sr) => (
                  <SearchResult
                    {...getSuggestionItemProps(sr)}
                    key={sr.description}
                  >
                    <LocationIcon
                      style={{ top: '11.7px' }}
                      className="icon icon-marker"
                    />
                    {buildHighlightedResult(sr)}
                  </SearchResult>
                ))}
              </ResultsDropdown>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </LocationSearchContainer>
  )
}

export default LocationSearchField
