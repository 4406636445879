import React from 'react'
import { Form } from 'react-bootstrap'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'
import { ITableHeaderProps } from '../../interfaces'

export const TableSortHeader: React.FC<ITableHeaderProps> = ({
  title,
  sortDirection = 'none',
  onClick = () => {},
  sortable = false,
  checkableProps,
  ...props
}) => {
  let caretUpColor = 'var(--secondary)'
  let caretDownColor = 'var(--secondary)'
  if (sortDirection === 'asc') {
    caretUpColor = 'var(--primary)'
  }
  if (sortDirection === 'desc') {
    caretDownColor = 'var(--primary)'
  }

  return (
    <th onClick={onClick} {...props}>
      <div
        className="d-flex align-items-center"
        style={{ textTransform: 'capitalize' }}
      >
        {checkableProps ? (
          <Form.Check>
            <Form.Check.Input
              className={checkableProps.checkClassName}
              onChange={checkableProps.onCheck}
              checked={checkableProps.checked}
            />
            <Form.Check.Label>{title}</Form.Check.Label>
          </Form.Check>
        ) : (
          title
        )}
        {sortable && (
          <div className="d-inline-block mx-2">
            {(sortDirection === 'desc' || sortDirection === 'none') && (
              <BsCaretUpFill
                className="d-block"
                size={10}
                style={{ color: caretUpColor, marginBottom: -3 }}
                data-testid="caret-up"
              />
            )}
            {(sortDirection === 'asc' || sortDirection === 'none') && (
              <BsCaretDownFill
                className="d-block"
                size={10}
                style={{
                  color: caretDownColor,
                  marginBottom: 2,
                  marginTop: -2
                }}
                data-testid="caret-down"
              />
            )}
          </div>
        )}
      </div>
    </th>
  )
}

export default TableSortHeader
