import React, { Dispatch, SetStateAction } from 'react'
import { Col, Form, Row } from 'react-angle-dashboard-components'
import { ReactComponent as TrashIcon } from './trash-icon.svg'

export const OtherCarrierRow: React.FC<{
  idx: number
  otherCarriers: string[]
  setOtherCarriers: Dispatch<SetStateAction<string[]>>
}> = ({ idx, otherCarriers, setOtherCarriers }) => {
  return (
    <Row>
      <Form.Group className="w-100">
        <Col>
          <Form.Label>Carrier Name</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              className="mr-3"
              placeholder="Carrier name"
              value={otherCarriers[idx]}
              onChange={(e: any) => {
                setOtherCarriers((prevState: string[]) => {
                  const carriers = [...prevState]
                  carriers[idx] = e.target.value
                  return carriers
                })
              }}
            />
            {idx !== 0 && (
              <TrashIcon
                onClick={() => {
                  setOtherCarriers((prevState: string[]) => {
                    const newState = [...prevState]
                    newState.splice(idx, 1)
                    return newState
                  })
                }}
              />
            )}
          </div>
        </Col>
      </Form.Group>
    </Row>
  )
}
