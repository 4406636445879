import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IStep } from './Steps'
import { StepBadge, StepWrapper } from './style'

export interface IStepProps {
  step: IStep
  active: boolean
  stepNumber: number
  completed?: boolean
  link: string
  showEditOnFinalStep?: boolean
  onRoute?: Function
}
export const Step: React.FC<IStepProps> = ({
  step,
  active,
  stepNumber,
  completed,
  link,
  showEditOnFinalStep,
  onRoute
}) => {
  const { title, subtitle } = step

  let badgeContent = <span className="fs-12">{stepNumber}</span>

  if (completed && !active) {
    badgeContent = (
      <i
        className="icon icon-correct"
        style={{ fontSize: '0.7rem' }}
        data-testid="correct-icon"
      />
    )
  }

  let showEdit = false
  if (completed && !active) {
    showEdit = true
  }

  if (showEditOnFinalStep) {
    showEdit = true
  }

  return (
    <StepWrapper className="w-100">
      {/* <i className="icon icon-correct" /> */}
      <Row>
        <Col xs={1}>
          <StepBadge className="fs-12" active={active || Boolean(completed)}>
            {badgeContent}
          </StepBadge>
        </Col>
        <Col>
          <div className="d-flex flex-column">
            <h6 className="text-nowrap" style={{ maxWidth: 165 }}>
              {title}
            </h6>
          </div>
        </Col>
        <Col xs={3} className="pr-4">
          <div className="d-flex align-items-base">
            {showEdit && (
              <div className="d-flex">
                <i
                  className="icon icon-pencil text-primary fs-12"
                  style={{ height: '100%', width: '0.8rem', marginRight: 7 }}
                />
                {onRoute ? (
                  <span
                    role="button"
                    onClick={() => onRoute(link)}
                    className="fs-14 text-primary"
                  >
                    Edit
                  </span>
                ) : (
                  <Link to={link} className="fs-14 text-primary">
                    Edit
                  </Link>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={1}></Col>
        <Col>
          <p
            style={{ textOverflow: 'ellipsis', width: '100%' }}
            className="text-muted m-0"
          >
            {subtitle}
          </p>
        </Col>
      </Row>
    </StepWrapper>
  )
}

export default Step
