import React from 'react'
import { SectionTitle } from '../SectionTitle'
import { Wrapper } from './style'
import Step from './Step'

export interface IStep {
  title: string
  subtitle?: string
  link: string
  completed?: boolean
}

export interface IStepsProps {
  title: string
  currentStep: number
  steps: IStep[]
  onRoute?: Function
}

export const Steps: React.FC<IStepsProps> = ({
  title,
  steps,
  currentStep,
  onRoute
}) => {
  return (
    <Wrapper className="mb-3 mb-lg-0">
      <SectionTitle>{title}</SectionTitle>
      {steps.map((step: IStep, i: number) => (
        <Step
          key={step.title}
          step={step}
          active={currentStep === i + 1}
          stepNumber={i + 1}
          link={step.link}
          completed={step.completed}
          showEditOnFinalStep={
            i === steps.length - 1 && !!steps[i - 1]?.completed
          }
          onRoute={onRoute}
        />
      ))}
    </Wrapper>
  )
}

export default Steps
