import {
  ApiService,
  AuthService,
  PlaidService,
  DocumentService,
  FormService,
  SettingService,
  DocusignService,
  GroupService,
  PaymentsService,
  EmployeeService,
  SummaryService,
  ReportingService,
  EightThreeFourService,
  PlanService
} from 'react-angle-dashboard-components'
import { BillingService } from './billing'
import { TempAuthService } from './tempauth'
import { DisclosuresService } from './disclosures'

const BASE_URL = (window as any).runtimeConfig?.apiURL
const appName = 'employerportal'

export const tempAuthService = new TempAuthService(`${BASE_URL}/${appName}`)
export const authService = new AuthService(`${BASE_URL}/${appName}`)
export const billingService = new BillingService(`${BASE_URL}/${appName}`)
export const documentService = new DocumentService(`${BASE_URL}/${appName}`)
export const formService = new FormService(`${BASE_URL}/${appName}`)
export const plaidService = new PlaidService(BASE_URL)
export const docusignService = new DocusignService(`${BASE_URL}/${appName}`)
export const settingService = new SettingService(`${BASE_URL}/${appName}`)
export const groupService = new GroupService(`${BASE_URL}/${appName}`)
export const summaryService = new SummaryService(`${BASE_URL}/${appName}`)
export const employeeService = new EmployeeService(`${BASE_URL}/${appName}`)
export const eightThreeFourService = new EightThreeFourService(`${BASE_URL}/${appName}`)
export const plansService = new PlanService(`${BASE_URL}/${appName}`)
export const paymentsService = new PaymentsService(`${BASE_URL}/${appName}`)
export const disclosuresService = new DisclosuresService(
  `${BASE_URL}/${appName}`
)
export const reportingService = new ReportingService(`${BASE_URL}/${appName}`)

export { ApiService }
