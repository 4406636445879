import React, { useState } from 'react'
import styled from 'styled-components'
import { Form } from 'react-bootstrap'

import { FilterContainer } from '../FilterContainer/FilterContainer'

const SwitchContainer = styled.div`
  max-width: 200px;
  display: flex;
  align-items: center;
`

export interface ISwitchFiler {
  name: string
  value: boolean
  label: string
  onChange: Function
  [x: string]: any
}

export const SwitchFilter: React.FC<ISwitchFiler> = ({
  name,
  value,
  label,
  onChange,
  ...p
}) => {
  const [internalValue, setInternalValue] = useState(value)
  const [showDropdown, setShowDropdown] = useState(false)

  const apply = () => {
    onChange(internalValue)
    setShowDropdown(false)
  }

  const onToggle = () => {
    setInternalValue(value)
    setShowDropdown(!showDropdown)
  }

  return (
    <FilterContainer
      name={name}
      apply={apply}
      onToggle={onToggle}
      showDropdown={showDropdown}
      applyDisabled={value === internalValue}
      appliedFilters={value}
      {...p}
    >
      <SwitchContainer>
        <Form.Check
          type="switch"
          id={name + ' filter'}
          checked={internalValue}
          onChange={(e) => setInternalValue(e.target.checked)}
        />
        <label htmlFor={name + ' filter'}>{label}</label>
      </SwitchContainer>
    </FilterContainer>
  )
}

export default SwitchFilter
