import React, { useState } from 'react'
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai'
import { Link } from 'react-router-dom'

import { Badge, OverlayTrigger, Row, Tooltip } from '../../index'
import { PaymentSource, currencyFormatter, paymentSourceObject } from '@angle/utils'
import { IClaimListItem } from './interfaces'
import styled from 'styled-components'


export const StatusCircle = styled.div<{ bgColor: string }>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 0.8rem;
  background: ${(props) => props.bgColor};
`

const HelpIcon = styled.i`
  color: var(--gray-400);
  height: 10px;
  width: 10px;
  position: relative;
  top: 2px;
  left: 4px;
`

export const TableRow = styled.tr<{
  isExpanded: boolean
  isExpandable: boolean
}>`
  background: ${(props) => (props.isExpanded ? 'var(--light)' : 'white')};
  :hover {
    cursor: ${(props) => (props.isExpandable ? 'pointer' : '')};
    background: ${(props) => (props.isExpandable ? 'var(--light)' : 'white')};
  }
`

const status: Record<string, { text: string; variant: string }> = {
  P: { text: 'Payable', variant: 'success' },
  A: { text: 'Reversed', variant: 'warning' },
  payable: { text: 'Payable', variant: 'success' },
  denied_partial: { text: 'Denied Partial', variant: 'danger' },
  denied: { text: 'Denied', variant: 'danger' },
  suspended: { text: 'Suspended', variant: 'warning' },
  pended: { text: 'Pended', variant: 'info' },
  error: { text: 'Error', variant: 'danger' }
}

export const ClaimTableBody: React.FC<{
  items: IClaimListItem[]
  isRx?: boolean
  isHomebase?: boolean
}> = ({ items, isRx, isHomebase }) => {
  if (items.length === 0) {
    return <EmptyTable colSpan={isRx ? 6 : 7} />
  }

  return (
    <tbody>
      {items?.map((claim: IClaimListItem) => {
        return (
          <ClaimTableRow claim={claim} isRx={isRx} isHomebase={isHomebase} />
        )
      })}
    </tbody>
  )
}

export const ClaimTableRow: React.FC<{
  claim: IClaimListItem
  isRx?: boolean
  isHomebase?: boolean
}> = ({ claim, isRx, isHomebase = false }) => {
  const isExpandable = !!claim?.associatedClaims?.length
  const onClick = () => {
    if (isExpandable) {
      setIsExpanded((prevState) => !prevState)
    }
  }
  const showServiceDateRange = claim.serviceEndDate
    ? claim?.serviceDate !== claim?.serviceEndDate
    : false

  const isPaid = claim?.anglePaid === true
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <React.Fragment key={claim.claimId}>
      <TableRow
        onClick={onClick}
        isExpandable={isExpandable}
        isExpanded={isExpanded}
      >
        <td style={{ paddingBottom: 20, paddingTop: 20 }}>
          {!isRx && (
            <>
              {' '}
              {isExpanded ? (
                <AiFillCaretUp className="mr-3" />
              ) : (
                <AiFillCaretDown
                  className="mr-3"
                  style={{ visibility: isExpandable ? 'visible' : 'hidden' }}
                />
              )}
            </>
          )}
          <div>
            {isHomebase && isRx ? (
              <>{claim.claimNumber || 'No Claim Number'}</>
            ) : (
              <Link
                to={`/claims${isHomebase ? '' : isRx ? '/rx' : '/medical'}/${
                  claim.claimId
                }`}
              >
                {claim.claimNumber || 'No Claim Number'}
              </Link>
            )}
            {!isRx && !claim.inNetwork && (
              <>
                <br />
                <Badge
                  variant="warning"
                  style={{ color: 'white', paddingTop: 4, paddingBottom: 4 }}
                >
                  Out of Network
                </Badge>
              </>
            )}
          </div>
        </td>
        <td>{claim.paidDate}</td>
        {!isRx && (
          <td>
            <div>
              <span className="d-block">
                {claim.serviceDate}
                {showServiceDateRange && ' -'}
              </span>
              {showServiceDateRange && <span> {claim.serviceEndDate}</span>}
            </div>
          </td>
        )}
        <td className="text-capitalize">{claim.providerName}</td>
        <td className="text-capitalize">{claim.memberName}</td>
        <td>
          {claim.copayAssistance ? (
            <Row>
              Copay Assistance
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip data-testid="about-tooltip" id="copay assistance">
                    This is a copay assistance claim. Your patient
                    responsibility will be determined by the RX provider.
                  </Tooltip>
                }
              >
                <HelpIcon
                  data-testid="about-tooltip-trigger"
                  className="icon icon-question-mark fs-12"
                />
              </OverlayTrigger>
            </Row>
          ) : (
            claim.patientResponsibility
          )}
        </td>
        {!isRx && (
          <td>{currencyFormatter.format(claim.paidAmount)}</td>
        )}
        {!isRx && (
          <td>{claim.billedAmount}</td>
        )}
        {isRx && (
          <td>
            <Badge
              style={{
                color: 'white',
                fontWeight: 400,
                textTransform: 'capitalize'
              }}
              variant={status[claim.status]?.variant || 'error'}
              className="fs-16"
            >
              {status[claim.status]?.text}
            </Badge>
          </td>
        )}
        {!isRx && (
          <td>
            <div className="d-flex align-items-center">
              {' '}
              <StatusCircle
                bgColor={`var(--${claim?.anglePaid ? 'success' : 'secondary'})`}
              />
              <span> {claim.anglePaid ? 'Paid' : 'Unpaid'}</span>
            </div>
          </td>
        )}
        <td>{(paymentSourceObject)[claim.paymentSource as PaymentSource]}</td>
      </TableRow>
      {isExpandable &&
        isExpanded &&
        claim?.associatedClaims?.map((claim) => {
          return (
            <InnerRow showStatus={isPaid} claim={claim} key={claim.claimId} />
          )
        })}
    </React.Fragment>
  )
}

// this is kinda redundant, refactor when I have time
const InnerRow: React.FC<{ claim: IClaimListItem; showStatus: boolean }> = ({
  claim,
  showStatus
}) => {
  const showServiceDateRange = claim?.serviceDate !== claim?.serviceEndDate

  return (
    <tr key={claim.claimId} className="bg-light">
      <td className="text-primary">
        <AiFillCaretDown className="mr-3" style={{ visibility: 'hidden' }} />
        <Link to={`/claims/medical/${claim.claimId}`}>{claim.claimNumber}</Link>
      </td>
      <td>{claim.paidDate}</td>
      <td>
        <div>
          <span className="d-block">
            {claim.serviceDate}
            {showServiceDateRange && ' -'}
          </span>
          {showServiceDateRange && <span> {claim.serviceEndDate}</span>}
        </div>
      </td>

      <td className="text-capitalize">{claim.providerName}</td>
      <td className="text-capitalize">{claim.memberName}</td>
      <td>{claim.patientResponsibility}</td>
      <td>
        {showStatus && (
          <div className="d-flex align-items-center">
            {' '}
            <StatusCircle
              bgColor={`var(--${claim?.anglePaid ? 'success' : 'secondary'})`}
            />
            <span> {claim.anglePaid ? 'Paid' : 'Unpaid'}</span>
          </div>
        )}
      </td>
    </tr>
  )
}

// todo this could be a reusable component
const EmptyTable: React.FC<{ colSpan: number }> = ({ colSpan }) => {
  return (
    <tbody>
      <tr>
        <td colSpan={colSpan}>
          <h6 className="text-muted text-center p-5">No claims</h6>
        </td>
      </tr>
    </tbody>
  )
}

export default ClaimTableBody
