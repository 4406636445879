import { Name } from './shared'

export interface GroupDTO {
  admin_contact_info?: ContactInfo
  admin_contact_name?: Name
  agency_appointment?: any
  agency?: Agency
  annual_groups: any[]
  autopay?: boolean
  avg_age?: string
  bankruptcy?: any
  billing_contact_info: ContactInfo
  billing_contact_name?: Name
  business_address?: BusinessAddress
  business_contact_info?: ContactInfo
  business_licence?: any
  business_type?: string
  calculated_group_size?: any
  classlist?: ClassDTO[]
  cobra_required?: any
  cobra_scb_seperate_billing?: any
  coverage_effective_date?: string
  created_on_date?: string
  current_balance_due_date?: any
  current_balance_due?: any
  current_carrier?: CurrentCarrier
  current_changing_reason?: string
  dba_name?: string
  default_class?: DefaultClassDTO
  dependent_count?: number
  ein?: string
  eligibility_num_employees?: any
  eligibility_num_hours?: any
  employee_count?: number
  employee_num_location?: any
  employer_contribution_amount_type?: string
  employer_contribution_dependent_amount?: string
  employer_contribution_employee_amount?: string
  exist_4_weeks?: any
  group_anniversary_date?: string
  group_effective_date?: string
  group_id?: string
  group_name?: string
  group_status?: any
  group_termination_date?: string
  has_current_carrier?: boolean
  ihc_approved?: boolean
  in_progress_state?: number
  industry?: string
  mailing_address?: MailingAddress
  min_signup_requirements?: any
  nature_of_business?: string
  new_hire_waiting_period_update?: any
  new_insurance_reason?: string
  onboarding_status?: string
  open_enrollment_effective_date?: string
  open_enrollment_end_date?: string
  open_enrollment_start_date?: string
  outside_utah?: any
  paperless_billing?: any
  planned_purchase_timeframe?: string
  plans?: Plan[]
  previous_carrier?: PreviousCarrier
  previous_changing_reason?: string
  rating_type?: any
  received_policy_agreement?: any
  rehire_policy?: any
  scb_required?: any
  self_serve_progress?: string
  self_serve_status?: string
  sic?: string
  signed_final_quote?: any
  statement_of_understanding?: any
  total_individuals?: any
  total_members?: any
  tpa_true_up?: boolean
  workers_compensation_explanation?: string
  workers_compensation?: any
  years_in_operation?: any
  [key: string]: any
  funding_type: 'fully_funded' | 'level_funded'
}

export interface DefaultClassDTO {
  class_id: string
  description: string
}

export interface ClassDTO {
  entry_id: number
  description: string
  class_id: string
}

export interface BusinessContactInfo {
  home_email: string
  work_email: string
  fax: string
  home_phone: string
  work_phone: string
}

export interface AdminContactInfo {
  home_email?: string
  work_email: string
  fax?: string
  home_phone?: string
  work_phone?: string
}

export interface AdminContactPerson {
  admin_name: Name
  admin_contact_info: AdminContactInfo
}

export interface BillingContactInfo {
  home_email: string
  work_email: string
  fax: string
  home_phone: string
  work_phone: string
}

export interface BillingContactPerson {
  billing_name: Name
  billing_contact_info: BillingContactInfo
}

export interface BusinessAddress {
  address_type?: any
  primary?: any
  street_1: string
  street_2: string
  city: string
  county: string
  state: string
  zip: string
  country: string
  alternate_start?: any
  alternate_end?: any
}

export interface MailingAddress {
  address_type?: any
  primary?: any
  street_1: string
  street_2?: string
  city: string
  county?: string
  state: string
  zip: string
  country?: string
  alternate_start?: any
  alternate_end?: any
}

export interface CurrentCarrier {
  status: string
  coverage_type: string
  medical_carrier: string
  medical_policy_number: string
  medical_start_date?: any
  medical_term_date?: any
  dental_carrier: string
  dental_policy_number: string
  dental_start_date?: any
  dental_term_date?: any
  vision_carrier: string
  vision_policy_number: string
  vision_start_date?: any
  vision_term_date?: any
}

export interface PreviousCarrier {
  status: string
  coverage_type: string
  medical_carrier: string
  medical_policy_number: string
  medical_start_date?: any
  medical_term_date?: any
  dental_carrier: string
  dental_policy_number: string
  dental_start_date?: any
  dental_term_date?: any
  vision_carrier: string
  vision_policy_number: string
  vision_start_date?: any
  vision_term_date?: any
}

export interface ContactInfo {
  home_email?: string
  work_email?: string
  fax?: string
  home_phone?: string
  work_phone?: string
}

export interface Address {
  address_type?: any
  primary?: any
  street_1: string
  street_2: string
  city: string
  county: string
  state: string
  zip: string
  country: string
  alternate_start?: any
  alternate_end?: any
}

export interface Agency {
  producer_name: Name
  agency_name: string
  payee_type?: any
  ssn: string
  producer_code: string
  utah_license_number: string
  utah_license_expiration_date?: any
  appointed?: any
  producer_contact_info: ContactInfo
  agency_contact_info: ContactInfo
  address: Address
}

export interface Plan {
  plan_id: string
  plan_type: string
  eligible_member_types: string[]
  dependent_child_age_limit: number
  dependent_child_term_rule: string
  plan_name: string
  line_of_coverage: string
  start_date: string
  end_date: string
  metal_tier: string
  plan_year: number
  sbc_url: string
  deductible_individual_inn: number
  deductible_family_inn: number
  oop_max_individual_inn: number
  oop_max_family_inn: number
  doctor_visit_inn_copay: number
  doctor_visit_copay_family: number
}

export enum NewHireWaitingPeriodChoices {
  doh_0 = '0 Days',
  fotm_0 = '1st of the month following 0 Days',
  fotm_30 = '1st of the month following 30 Days',
  fotm_60 = '1st of the month following 60 Days',
  doh_90 = '90 Days from Date of Hire',
}
