import React from 'react'
import { Col, DatePicker, Form, Row } from 'react-angle-dashboard-components'
import { Controller, Control, FormState } from 'react-hook-form'
import { getRequiredObject } from '../../../../../utils/formValidations'
import { CarrierFormData } from '../../../interfaces'
import { ValidationError } from '../../style'

const labels = {
  visionCarrier: 'Current Vision Insurance Carrier',
  visionStartDate: 'Start Date',
  visionEndDate: 'Anticipated Termination Date'
}

interface VisionProps {
  control: Control
  formState: FormState<CarrierFormData>
  isRequired: boolean
}
export const Vision: React.FC<VisionProps> = ({
  control,
  formState,
  isRequired
}) => {
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="w-100">
            <Form.Label className="required-field">
              {labels.visionCarrier}
            </Form.Label>
            <Controller
              name="visionCarrier"
              rules={{
                required: isRequired
                  ? getRequiredObject(labels.visionCarrier)
                  : false
              }}
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  placeholder="Vision Carrier"
                />
              )}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.visionCarrier?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.visionStartDate}
            </Form.Label>
            <Controller
              name="visionStartDate"
              control={control}
              rules={{
                required: isRequired
                  ? getRequiredObject(labels.visionStartDate)
                  : false
              }}
              render={({ field }) => <DatePicker {...field} />}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.visionStartDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.visionEndDate}
            </Form.Label>
            <Controller
              name="visionEndDate"
              control={control}
              rules={{
                required: isRequired
                  ? getRequiredObject(labels.visionEndDate)
                  : false
              }}
              render={({ field }) => <DatePicker {...field} />}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.visionEndDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
