import styled from 'styled-components'

export const PageHeaderLight = styled.div`
  padding: 2rem 0;
  background-color: var(--light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`

export default PageHeaderLight
