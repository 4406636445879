import React, { useContext } from 'react'
import {
  CenteredSpinner,
  Col,
  Form,
  PlaceAutocomplete,
  Row,
  useToaster,
  useMultiCustomCheck, OverlayTrigger, Tooltip
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'
import {
  formValidations,
  getRequiredObject
} from '../../../../utils/formValidations'
import { BusinessOnboardingContext } from '../../context/BusinessOnboardingContext'
import { useStep } from '../../hooks/use-step'
import { businessOnboardingPaths } from '../../router/paths'
import { FooterActions } from '../FooterActions'
import { FormSectionRow, TitleRow, ValidationError } from '../style'
import { useQuery } from 'react-query'
import { groupService } from '../../../../services'
import {
  mapDTOtoBusinessInformation,
  mapBusinessInformationToDTO
} from './mappers'
import {
  onCheckPayroll,
  typeOfPayrollList,
  typeOfHRList
} from './utils/payroll'
import { nonNegativeValues, states } from '@angle/utils'
import { useRuntimeConfig } from '@angle/hooks'
import styled from "styled-components"

const labels = {
  groupName: 'Business Legal Name',
  phone: 'Phone',
  businessType: 'Business Type',
  DBA: 'DBA',
  taxId: 'Tax ID',
  industry: 'Industry',
  hrPlatforms: 'What HR system do you use?',
  payrollPlatforms: 'What Payroll system do you use?',
  address1: 'Address',
  address2: 'Address Line 2',
  county: 'County',
  state: 'State',
  zip: 'ZIP',
  city: 'City',
  sic: 'SIC Code'
}

const HelpIcon = styled.i`
  color: var(--gray-400);
  height: 10px;
  width: 10px;
  position: relative;
  top: 0px;
  left: 4px;
`

export const BusinessInformation: React.FC = () => {
  const history = useHistory()
  const step = 'businessInformation'

  const toaster = useToaster()

  const { state, setState, modifyGroup } = useContext(BusinessOnboardingContext)

  const {
    handleSubmit,
    control,
    reset,
    formState,
    watch,
    getValues,
    setValue
  } = useForm({
    mode: 'onChange'
  })

  const errors = formState.errors

  const { data, isLoading } = useQuery(
    'getBusinessInformation',
    () => groupService.getInfo(mapDTOtoBusinessInformation, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (data: any) => {
        reset({ ...data, mailingEqualsBusiness: false })
        setState(data.stepNumber)
      }
    }
  )

  const config = useRuntimeConfig()

  const googleApiKey = config?.googleApiKey || '****'

  const watchMailingEqualBusiness = watch('mailingEqualBusiness')

  useStep(state, 1, step, data?.stepNumber || 1)

  const { defaultChecked, defaultValue, init, inputRef, isCustomChecked } =
    useMultiCustomCheck(typeOfPayrollList, getValues('payrollPlatforms') || [])

  const {
    defaultChecked: defaultCheckedHR,
    defaultValue: defaultValueHR,
    init: initHR,
    inputRef: inputRefHR,
    isCustomChecked: isCustomCheckedHR
  } = useMultiCustomCheck(typeOfHRList, getValues('hrPlatforms') || [])

  const handleContinue = handleSubmit(async (formData) => {
    const DTO = mapBusinessInformationToDTO(
      formData,
      watchMailingEqualBusiness,
      data.stepNumber
    )

    try {
      await modifyGroup.mutateAsync(DTO)
      history.push(businessOnboardingPaths.adminInformation)
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  const { maxPhoneLength, maxZip, maxTaxIdLength, onlyNumbers } =
    formValidations

  return (
    <>
      <Form>
        {isLoading ? (
          <CenteredSpinner style={{ height: '40vh' }}></CenteredSpinner>
        ) : (
          <>
            <TitleRow noGutters>
              <b>Business Information</b>
            </TitleRow>
            <FormSectionRow>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.groupName}
                  </Form.Label>
                  <Controller
                    name="groupName"
                    control={control}
                    rules={{
                      required: getRequiredObject(labels.groupName)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Business Legal Name"
                        isInvalid={Boolean(errors?.groupName)}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.groupName?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">{labels.sic}</Form.Label>
                  <OverlayTrigger delay={{show: 0, hide: 2000}}
              placement="right"
              overlay={
                <Tooltip className='bg-white'
                  data-testid="about-tooltip"
                  id="reconsideration tooltip"
                >
                 The SIC code (Standard Industrial Classification) is a standardized numerical system used to classify and categorize industries based on their primary economic activities. To find your SIC code, you can refer to <a style={{textDecoration: "underline", color: "white"}} href="https://www.sec.gov/corpfin/division-of-corporation-finance-standard-industrial-classification-sic-code-list">official government resources</a> or use online databases that provide comprehensive lists of codes for various industries.
                </Tooltip>
              }
            >
              <HelpIcon
                data-testid="about-tooltip-trigger"
                className="icon icon-question-mark fs-12"
              />
            </OverlayTrigger>
                  <Controller
                    name="sic"
                    control={control}
                    rules={{
                      required: getRequiredObject(labels.sic)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="string"
                        placeholder="SIC Code"
                        as={ReactInputMask}
                        mask="9999"
                        isInvalid={Boolean(errors?.sic)}
                        {...{ maskChar: null }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.sic?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.phone}
                  </Form.Label>
                  <Controller
                    rules={{
                      validate: {
                        maxPhoneLength
                      },
                      required: getRequiredObject(labels.phone)
                    }}
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        as={ReactInputMask}
                        mask="999-999-9999"
                        isInvalid={Boolean(errors?.phone)}
                        type="tel"
                        {...field}
                        placeholder="Phone"
                        {...{ maskChar: null }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.phone?.message}
                  </ValidationError>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.industry}
                  </Form.Label>
                  <Controller
                    name="industry"
                    control={control}
                    rules={{
                      required: getRequiredObject(labels.industry)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        value={field.value || ''}
                        as="select"
                        isInvalid={Boolean(errors?.businessType)}
                      >
                        <option value="" disabled hidden>
                          Select your industry
                        </option>
                        <option>Agriculture/Minerals/Mining</option>
                        <option>Real Estate</option>
                        <option>Construction</option>
                        <option>Energy/Utilities</option>
                        <option>Restaurant/Food Services</option>
                        <option>Transportation/Logistics</option>
                        <option>Media/Creative Industry</option>
                        <option>Education</option>
                        <option>Tourism and Travel/Hospitality</option>
                        <option>Defense/Aerospace</option>
                        <option>Healthcare</option>
                        <option>Life Science</option>
                        <option>Tech</option>
                        <option>Non-profit</option>
                        <option>Finance</option>
                        <option>Retail/e-commerce</option>
                        <option>Automotive</option>
                        <option>
                          Professional Services (Law/Consulting/etc)
                        </option>
                        <option>Maufacturing</option>
                      </Form.Control>
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.industry?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>{labels.DBA}</Form.Label>
                  <Controller
                    name="dba"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Name of the company"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.taxId}
                  </Form.Label>
                  <Controller
                    name="taxId"
                    rules={{
                      required: getRequiredObject(labels.taxId),
                      validate: { maxTaxIdLength }
                    }}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...nonNegativeValues}
                        {...field}
                        type="string"
                        placeholder="Tax ID"
                        as={ReactInputMask}
                        isInvalid={Boolean(errors?.taxId)}
                        mask="99-9999999"
                        {...{ maskChar: null }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.taxId?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.businessType}
                  </Form.Label>
                  <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          data-testid="business-type-tooltip"
                          id="business-type-tooltip"
                        >
                        <div>Corporate is an organization—usually a group of people or a company—authorized by the state to act as a single entity and recognized as such in law for certain purposes.</div>
                        <div>PEO is a Professional Employer Organization that provides small businesses with support for growth and sustainability.</div>
                        </Tooltip>
                      }>
                  <HelpIcon
                    data-testid="about-tooltip-trigger"
                    className="icon icon-question-mark fs-12"
                  />
                  </OverlayTrigger>
                  <Controller
                    name="businessType"
                    control={control}
                    rules={{
                      required: getRequiredObject(labels.businessType)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        value={field.value || ''}
                        as="select"
                        isInvalid={Boolean(errors?.industry)}
                      >
                        <option value="" disabled hidden>
                          Select your business type
                        </option>
                        <option value="corporate">Corporate</option>
                        <option value="peo">PEO</option>
                        <option value="government_public">Government / Public</option>
                      </Form.Control>
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.businessType?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group>
                  <Form.Label>{labels.hrPlatforms}</Form.Label>
                  <Controller
                    name="hrPlatforms"
                    control={control}
                    render={({ field }) => {
                      const { onCheckChanges, onInputBlur } = initHR(
                        field.value,
                        field.onChange
                      )

                      return (
                        <div>
                          {typeOfHRList.map((type, i) => (
                            <Form.Check
                              key={i}
                              {...field}
                              onChange={onCheckPayroll(field)}
                              className="my-2 custom-checkbox"
                              id={`hr-${type.value}`}
                              value={type.value}
                              label={type.text}
                            />
                          ))}

                          <Form.Check
                            className="my-2 d-inline custom-checkbox"
                            id={'hr-custom'}
                            onChange={onCheckChanges}
                            defaultChecked={defaultCheckedHR}
                            checked={isCustomCheckedHR}
                          />
                          <Form.Control
                            style={{ minWidth: 150, width: '27%' }}
                            size="sm"
                            className="ml-2 d-inline"
                            disabled={!isCustomCheckedHR}
                            placeholder="Other"
                            ref={inputRefHR}
                            onBlur={onInputBlur}
                            defaultValue={defaultValueHR}
                          />
                        </div>
                      )
                    }}
                  />
                </Form.Group>
                <ValidationError>
                  {formState.isSubmitted && errors?.hrPlatforms?.message}
                </ValidationError>
              </Col>
              <Col xs="6">
                <Form.Group>
                  <Form.Label>{labels.payrollPlatforms}</Form.Label>
                  <Controller
                    name="payrollPlatforms"
                    control={control}
                    render={({ field }) => {
                      const { onCheckChanges, onInputBlur } = init(
                        field.value,
                        field.onChange
                      )

                      return (
                        <div>
                          {typeOfPayrollList.map((type, i) => (
                            <Form.Check
                              key={i}
                              {...field}
                              onChange={onCheckPayroll(field)}
                              className="my-2 custom-checkbox"
                              id={`payroll-${type.value}`}
                              value={type.value}
                              label={type.text}
                            />
                          ))}

                          <Form.Check
                            className="my-2 d-inline custom-checkbox"
                            id={'payroll-custom'}
                            onChange={onCheckChanges}
                            defaultChecked={defaultChecked}
                            checked={isCustomChecked}
                          />
                          <Form.Control
                            style={{ minWidth: 150, width: '27%' }}
                            size="sm"
                            className="ml-2 d-inline"
                            disabled={!isCustomChecked}
                            placeholder="Other"
                            ref={inputRef}
                            onBlur={onInputBlur}
                            defaultValue={defaultValue}
                            id="payroll"
                          />
                        </div>
                      )
                    }}
                  />
                </Form.Group>
                <ValidationError>
                  {formState.isSubmitted && errors?.payrollPlatforms?.message}
                </ValidationError>
              </Col>
            </FormSectionRow>
            <TitleRow noGutters>
              <b>Business Address</b>
            </TitleRow>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.address1}
                  </Form.Label>
                  <Controller
                    name="businessAddress.street1"
                    rules={{ required: getRequiredObject(labels.address1) }}
                    control={control}
                    render={({ field }) => (
                      <PlaceAutocomplete
                        ref={field.ref}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        className={`form-control ${
                          errors?.businessAddress?.street1 ? 'is-invalid' : ''
                        }`}
                        placeholder="Address"
                        defaultValue={field.value}
                        apiKey={googleApiKey}
                        onPlaceSelection={({
                          address,
                          city,
                          county,
                          zip,
                          state
                        }) => {
                          setValue('businessAddress.street1', address)
                          setValue('businessAddress.state', state)
                          setValue('businessAddress.city', city)
                          setValue('businessAddress.zip', zip)
                          setValue('businessAddress.county', county)
                        }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.businessAddress?.street1?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.county}
                  </Form.Label>
                  <Controller
                    name="businessAddress.county"
                    control={control}
                    rules={{ required: getRequiredObject(labels.county) }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={Boolean(errors?.businessAddress?.county)}
                        placeholder="County"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.businessAddress?.county?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.zip}
                  </Form.Label>
                  <Controller
                    name="businessAddress.zip"
                    rules={{
                      validate: { maxZip, onlyNumbers },
                      required: getRequiredObject(labels.zip)
                    }}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        {...nonNegativeValues}
                        isInvalid={Boolean(errors?.businessAddress?.zip)}
                        as={ReactInputMask}
                        type="string"
                        mask="99999"
                        placeholder="Zip code"
                        {...{ maskChar: null }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.businessAddress?.zip?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>{labels.address2}</Form.Label>
                  <Controller
                    name="businessAddress.street2"
                    control={control}
                    render={({ field }) => (
                      <Form.Control {...field} placeholder="Address Line 2" />
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.state}
                  </Form.Label>
                  <Controller
                    name="businessAddress.state"
                    control={control}
                    rules={{ required: getRequiredObject(labels.state) }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        value={field.value || ''}
                        as="select"
                        isInvalid={Boolean(errors?.businessAddress?.state)}
                      >
                        <option value="" disabled hidden>
                          Select your state
                        </option>
                        {Object.keys(states).map((k) => (
                          <option key={k} value={states[k]}>
                            {states[k]}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.businessAddress?.state?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.city}
                  </Form.Label>
                  <Controller
                    name="businessAddress.city"
                    control={control}
                    rules={{ required: getRequiredObject(labels.city) }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={Boolean(errors?.businessAddress?.city)}
                        placeholder="City"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.businessAddress?.city?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
            </Row>

            <TitleRow
              noGutters
              className={watchMailingEqualBusiness ? 'disabled' : ''}
            >
              <b>Mailing Address</b>
            </TitleRow>
            <Row noGutters>
              <Col>
                <Form.Group>
                  <Controller
                    name="mailingEqualBusiness"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        className="custom-checkbox"
                        id="mailingEqualBusiness"
                        type="checkbox"
                        label="Mailing address is same as business address"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <FormSectionRow
              style={{ border: 'none' }}
              className={watchMailingEqualBusiness ? 'disabled' : ''}
            >
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      watchMailingEqualBusiness ? '' : 'required-field'
                    }
                  >
                    {labels.address1}
                  </Form.Label>
                  <Controller
                    name="mailingAddress.street1"
                    control={control}
                    rules={{
                      required: watchMailingEqualBusiness
                        ? false
                        : getRequiredObject(labels.address1)
                    }}
                    render={({ field }) =>
                      watchMailingEqualBusiness ? (
                        <input
                          defaultValue={field.value}
                          className="form-control"
                          placeholder="Address"
                          disabled={true}
                        />
                      ) : (
                        <PlaceAutocomplete
                          ref={field.ref}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          className={`form-control ${
                            errors?.mailingAddress?.street1 ? 'is-invalid' : ''
                          }`}
                          placeholder="Address"
                          defaultValue={field.value}
                          apiKey={googleApiKey}
                          onPlaceSelection={({
                            address,
                            city,
                            county,
                            zip,
                            state
                          }) => {
                            setValue('mailingAddress.street1', address)
                            setValue('mailingAddress.state', state)
                            setValue('mailingAddress.city', city)
                            setValue('mailingAddress.zip', zip)
                            setValue('mailingAddress.county', county)
                          }}
                          options={{
                            types: ['address'],
                            componentRestrictions: {
                              country: ['us']
                            }
                          }}
                        />
                      )
                    }
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      !watchMailingEqualBusiness &&
                      errors?.mailingAddress?.street1?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    className={
                      watchMailingEqualBusiness ? '' : 'required-field'
                    }
                  >
                    {labels.county}
                  </Form.Label>
                  <Controller
                    name="mailingAddress.county"
                    control={control}
                    rules={{
                      required: watchMailingEqualBusiness
                        ? false
                        : getRequiredObject(labels.county)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        value={field.value || ''}
                        placeholder="County"
                        isInvalid={Boolean(errors?.mailingAddress?.county)}
                        disabled={watchMailingEqualBusiness}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      !watchMailingEqualBusiness &&
                      errors?.mailingAddress?.county?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    className={
                      watchMailingEqualBusiness ? '' : 'required-field'
                    }
                  >
                    {labels.zip}
                  </Form.Label>
                  <Controller
                    name="mailingAddress.zip"
                    rules={{
                      validate: !watchMailingEqualBusiness
                        ? { maxZip }
                        : () => true,
                      required: watchMailingEqualBusiness
                        ? false
                        : getRequiredObject(labels.zip)
                    }}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        {...nonNegativeValues}
                        placeholder="Zip code"
                        isInvalid={Boolean(errors?.mailingAddress?.zip)}
                        as={ReactInputMask}
                        type="string"
                        {...{ maskChar: null }}
                        mask="99999"
                        disabled={watchMailingEqualBusiness}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      !watchMailingEqualBusiness &&
                      errors?.mailingAddress?.zip?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>{labels.address2}</Form.Label>
                  <Controller
                    name="mailingAddress.street2"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        placeholder="Address Line 2"
                        disabled={watchMailingEqualBusiness}
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    className={
                      watchMailingEqualBusiness ? '' : 'required-field'
                    }
                  >
                    {labels.state}
                  </Form.Label>
                  <Controller
                    name="mailingAddress.state"
                    control={control}
                    rules={{
                      required: watchMailingEqualBusiness
                        ? false
                        : getRequiredObject(labels.state)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        value={field.value || ''}
                        isInvalid={Boolean(errors?.mailingAddress?.state)}
                        as="select"
                        disabled={watchMailingEqualBusiness}
                      >
                        <option value="" disabled hidden>
                          Select your state
                        </option>
                        {Object.keys(states).map((k) => (
                          <option key={k} value={states[k]}>
                            {states[k]}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      !watchMailingEqualBusiness &&
                      errors?.mailingAddress?.state?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    className={
                      watchMailingEqualBusiness ? '' : 'required-field'
                    }
                  >
                    {labels.city}
                  </Form.Label>
                  <Controller
                    name="mailingAddress.city"
                    control={control}
                    rules={{
                      required: watchMailingEqualBusiness
                        ? false
                        : getRequiredObject(labels.city)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={Boolean(errors?.mailingAddress?.city)}
                        value={field.value || ''}
                        placeholder="City"
                        disabled={watchMailingEqualBusiness}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      !watchMailingEqualBusiness &&
                      errors?.mailingAddress?.city?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
            </FormSectionRow>
          </>
        )}
      </Form>
      <FooterActions continueHandler={handleContinue} showBack={false} />
    </>
  )
}
