import React from 'react'
import { Modal, Button } from 'react-bootstrap'

interface PayByCheckModalProps {
    show: boolean
    toggleModal: Function
    callback?: Function
}

export const PayByCheckModal: React.FC<PayByCheckModalProps> = ({
    show,
    toggleModal,
    callback = () => {},
  }) => {
    const handleCallback = () => {
        callback();
        toggleModal();
    }
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
            show={show}
            onHide={() => toggleModal()}
            >
            <Modal.Body>
                <h3 className="font-weight-normal">Approval is required to pay by check!</h3>
                <p>Additional fees to process checks apply each billing period.</p>

                <div className="d-flex justify-content-between align-items-center">
                    <strong>Request to Pay by Check (approval required)</strong>
                    <div>
                        <Button variant="secondary" onClick={() => handleCallback()}>Select</Button>
                        <Button variant="primary" className="ml-md-3" onClick={() => toggleModal()}>Cancel</Button>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    )
}