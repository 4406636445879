import React from 'react'
import {
  BadgeStatusVariants,
  IMedication,
  ITableHeaderProps
} from '../../interfaces'
import { StatusBadge } from '../CareAuthorizationsTable/style'
import { IPaginationProps } from '../Pagination'
import { Table } from '../Table'

export interface IMedicationHeaders {
  drug: string
  provider: string
  lastFilledDate: string
  dosage?: string
  status?: string
  refillLeft: string
}

export interface IMedicationsTableProps
  extends React.HTMLAttributes<HTMLTableElement> {
  medications: IMedication[]
  headers: IMedicationHeaders
  paginationConfig?: IPaginationProps
  LinkEl: React.FC<any>
  loading?: boolean
  [x: string]: any
}

export const MedicationsTable: React.FC<IMedicationsTableProps> = ({
  paginationConfig,
  medications,
  headers,
  LinkEl,
  loading = false,
  ...p
}) => {
  const tableHeaders: ITableHeaderProps[] = [
    { title: headers.drug, width: '25%' },
    { title: headers.provider, width: '25%' },
    { title: headers.lastFilledDate, width: '20%' },
    { title: headers.dosage || headers.status || '', width: '20%' },
    { title: headers.refillLeft, width: '10%' }
  ]

  return (
    <Table
      className="w-100"
      paginationConfig={paginationConfig}
      headers={tableHeaders}
      headerClassName="thead-light"
      {...p}
    >
      <tbody>
        {medications.map((m: IMedication) => (
          <tr key={m.id}>
            <td className="py-4">{m.drug}</td>
            <td className="py-4">
              <LinkEl to={m.provider.profileLink}>{m.provider.name}</LinkEl>
            </td>
            <td className="py-4 text-muted">{m.lastFilledDate}</td>
            <td className="py-4">
              {m.status ? (
                <StatusBadge
                  variant={BadgeStatusVariants[m.status]}
                  className="fs-16"
                >
                  {m.status}
                </StatusBadge>
              ) : (
                m.dosage
              )}
            </td>
            <td className="py-4 text-muted">{m.refillLeft}</td>
          </tr>
        ))}
        {medications.length === 0 && !loading && (
          <tr>
            <td colSpan={tableHeaders.length}>
              <h6 className="text-muted text-center p-5">No medications</h6>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default MedicationsTable
