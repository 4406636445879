import { validateEmail } from '@angle/utils'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import {
  Button,
  CenteredSpinner,
  Col,
  Form,
  PageContainer,
  Row,
  SectionTitle,
  useToaster
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { groupService } from '../../../services'

interface IAccountSetting {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

export const AccountSettings = () => {
  const toast = useToaster()
  const [isSaving, setIsSaving] = useState(false)

  const { control, formState, handleSubmit, setValue } =
    useForm<IAccountSetting>({
      mode: 'onChange'
    })

  const { data, isLoading } = useQuery(['accountSetting'], () => {
    return groupService.getInfo()
  })

  useEffect(() => {
    if (!isLoading && data) {
      setValue('firstName', data.admin_contact_name.first_name)
      setValue('lastName', data.admin_contact_name.last_name)
      setValue('email', data.admin_contact_info.work_email)
      setValue('phoneNumber', data.admin_contact_info.work_phone)
    }
  }, [setValue, data, isLoading])

  const onChangesSuccess = () => {
    setIsSaving(false)
    toast.show({
      message: 'Your account has been updated',
      icon: 'icon-correct',
      type: 'success'
    })
  }

  const onChangesFail = (error: any) => {
    setIsSaving(false)
    toast.show({
      message: `Something went wrong: ${error.message}`,
      icon: 'icon-cancel',
      type: 'danger'
    })
  }

  const handleUpdate = handleSubmit((data) => {
    const requestBody = {
      admin_contact_name: {
        first_name: data.firstName,
        last_name: data.lastName
      },
      admin_contact_info: {
        work_email: data.email,
        work_phone: data.phoneNumber
      }
    }
    setIsSaving(true)
    groupService
      .modifyGroup(requestBody)
      .then(onChangesSuccess)
      .catch(onChangesFail)
  })

  const hasError = !!Object.keys(formState.errors || {}).length

  const getError = (field: keyof IAccountSetting) => formState.errors[field]

  const errorMessage = hasError ? (
    <span className="text-danger">Please fill the required fields</span>
  ) : null

  if (isLoading) return <CenteredSpinner></CenteredSpinner>

  return (
    <PageContainer size="md">
      <SectionTitle>Account Setting</SectionTitle>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': getError('firstName')
                })}
              >
                First Name
              </Form.Label>
              <Controller
                name="firstName"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    className="bg-white"
                    placeholder="First Name"
                    isInvalid={!!getError('firstName')}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': getError('lastName')
                })}
              >
                Last Name
              </Form.Label>
              <Controller
                name="lastName"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    className="bg-white"
                    placeholder="Last Name"
                    isInvalid={!!getError('lastName')}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <hr />

        <SectionTitle>Account information</SectionTitle>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': getError('email')
                })}
              >
                Email address
              </Form.Label>
              <Controller
                name="email"
                defaultValue=""
                rules={{ required: true, validate: validateEmail }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="email"
                    className="bg-white"
                    placeholder="Email address"
                    isInvalid={!!getError('email')}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': getError('phoneNumber')
                })}
              >
                Phone number
              </Form.Label>
              <Controller
                name="phoneNumber"
                defaultValue=""
                rules={{ required: true, maxLength: 10 }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="tel"
                    className="bg-white"
                    placeholder="e.g. 202-555-0161"
                    isInvalid={!!getError('phoneNumber')}
                  />
                )}
              />
              {getError('phoneNumber')?.type === 'maxLength' && (
                <Form.Control.Feedback type="invalid">
                  Phone number must contain 10 characters
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        <hr />
        {errorMessage}

        <div className="pt-2 text-right">
          <Button disabled={isSaving} onClick={handleUpdate}>
            Save changes
          </Button>
        </div>
      </Form>
    </PageContainer>
  )
}

export default AccountSettings
