import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import {
  Modal,
  EightThreeFourService,
  EmployeeProfile,
  Row,
  Col,
  Card,
  computeFamilyIndicator,
  Form,
  SummaryService,
  PlanService,
  Spinner,
  useToaster,
  Button
} from '../../../index'
import styled from 'styled-components'
import {
  EightThreeFourRequest,
  EightThreeFourRequestMemberObject
} from '../../../interfaces/employer-broker-shared/eightthreefour'
import { GroupData } from '../../../interfaces/employer-broker-shared/group'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from 'react-query'
import { z } from 'zod'

const DependentsRow = styled(Row)`
{
 padding: 0.5em;
 max-width: 700px;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
`

const getFamilyIndicator = (employee: EmployeeProfile): string => {
  const returnVal = computeFamilyIndicator(employee)
  switch (returnVal) {
    case 'employee':
      return 'Employee'
    case 'family':
      return 'Employee + Family'
    case 'employee_child':
      return 'Employee + Child'
    case 'employee_children':
      return 'Employee + Children'
    default:
      return 'Employee + Spouse'
  }
}

export type EditCoverageFormFields = {
  planSelected: string
  subgroupSelected: string
}

const EditCoverageSchema = z.object({
  planSelected: z.string().nonempty({ message: 'You must select a plan' }),
  subgroupSelected: z.string()
})

const genderTranslatorFor843 = (gender: string): string => {
  if (gender.toLowerCase() === 'm' || gender.toLowerCase() === 'f') {
    return gender.toUpperCase()
  }

  if (gender.toLowerCase() === 'male') {
    return 'M'
  }

  if (gender.toLowerCase() === 'female') {
    return 'F'
  }

  return 'U'
}

export const EmployerPortalEditCoverageModal: React.FC<{
  isEditCoverageModalVisible: boolean
  setEditCoverageModalVisible: (boolean) => void
  employee: EmployeeProfile | undefined
  eightThreeFourService: EightThreeFourService
  summaryService: SummaryService
  plansService: PlanService
  refetchEmployee: () => void
  isBrokerPortal: boolean
  groupId?: string
}> = ({
  isEditCoverageModalVisible,
  setEditCoverageModalVisible,
  employee,
  eightThreeFourService,
  summaryService,
  plansService,
  refetchEmployee,
  isBrokerPortal,
  groupId
}) => {
  const [groupDataState, setGroupDataState] = useState<GroupData>()
  const [chosenSubgroup, setChosenSubgroup] = useState<string>(
    employee?.subgroup || ''
  )
  const { showSuccess, showError } = useToaster()
  const editMemberCoverage = useMutation({
    mutationFn: (data: any) =>
      eightThreeFourService.postMemberInformation(data),
    onSuccess: (response, data) => {
      if (
        response.success &&
        response.data.total_members === response.data.total_success
      ) {
        showSuccess('Coverage for Member was successfully modified.')
        reset()
        refetchEmployee()
        setEditCoverageModalVisible(false)
      } else if (response.link && response.success === false) {
        showError(`Error modifiying coverage for member - ${response.message}`)
        setTimeout(() => window.open(response.link, '_blank'), 500)
      } else {
        showError(`Error modifiying coverage for member.`)
      }
    },
    onError: () => {
      showError(`Error modifiying coverage for member.`)
    }
  })

  const { data: groupData, isLoading: groupLoading } = useQuery(
    'getGroupForAddOrRenewal',
    () =>
      isBrokerPortal
        ? summaryService.getGroup(groupId)
        : summaryService.getGroup()
  )

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
    setValue,
    reset
  } = useForm<EditCoverageFormFields>({
    defaultValues: {
      planSelected: employee?.plan,
      subgroupSelected: employee?.subgroup
    },
    resolver: zodResolver(EditCoverageSchema)
  })

  useEffect(() => {
    setGroupDataState(groupData)
  }, [groupLoading, groupData])

  const {
    data: planData,
    isLoading: planDataLoading,
    refetch,
    isRefetching
  } = useQuery(['getPlansForGivenGroupInAddEmployee'], () => {
    const getListParams: { groupId?: string; subgroupId?: number } = {}

    if (isBrokerPortal) {
      getListParams.groupId = groupId
    }
    // Class Selection only matters if we can find subgroup associated with that class
    if (
      groupDataState?.classes &&
      groupDataState?.classes.length > 1 &&
      chosenSubgroup !== ''
    ) {
      const matchingClassesForSubgroup = groupDataState.classes.filter(
        (klass) => klass.description === chosenSubgroup
      )
      if (matchingClassesForSubgroup.length >= 1) {
        getListParams.subgroupId = matchingClassesForSubgroup[0].entryIdPK
      }
    }
    return plansService.getList(getListParams)
  })

  useEffect(() => {
    setValue('subgroupSelected', chosenSubgroup)

    if (chosenSubgroup !== '') {
      refetch()
    }
  }, [chosenSubgroup, setValue, refetch])

  if (
    planDataLoading ||
    groupLoading ||
    !groupDataState ||
    isRefetching ||
    !employee
  ) {
    return (
      <Modal
        show={isEditCoverageModalVisible}
        centered
        size="lg"
        onHide={() => setEditCoverageModalVisible(false)}
        dialogClassName="groupFormModalDialog"
        className="groupFormModal"
        style={{
          zIndex: 2000
        }}
      >
        <Modal.Header className="border-bottom pb-2 pt-2" closeButton>
          <Modal.Title>Edit Coverage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Spinner size="sm" className="ml-2" animation="border" />
        </Modal.Body>
      </Modal>
    )
  }

  const sendEditCoverageRequest = async (data) => {
    if (!employee) {
      return
    }
    const params: EightThreeFourRequest = {
      group_id: employee?.group_id,
      members: [
        {
          first_name: employee?.name.split(' ')[0],
          last_name: employee?.name.split(' ')[1],
          relationship: 'employee',
          family_indicator: computeFamilyIndicator(employee),
          group_id: employee?.group_id,
          action_requested: 'enroll',
          angle_member_id: employee?.id,
          insured_ssn: employee?.ssn,
          ssn: employee?.ssn,
          gender: genderTranslatorFor843(employee?.gender),
          date_of_birth: employee?.dob,
          coverage_start_date: employee?.coverageStart,
          coverage_end_date: employee?.coverageEnd,
          plan_name: data.planSelected,
          street_1: employee.address_object.street_1,
          street_2: employee.address_object.street_2,
          state: employee.address_object.state,
          city: employee.address_object.city,
          zip: employee.address_object.zip
        }
      ]
    }

    for (let x = 0; x < employee.dependents.length; x++) {
      let dependentMemberObject: EightThreeFourRequestMemberObject = {
        group_id: employee?.group_id,
        first_name: employee?.dependents[x].name.first_name,
        last_name: employee?.dependents[x].name.last_name,
        relationship: employee?.dependents[x].relationship,
        family_indicator: computeFamilyIndicator(employee),
        action_requested: 'enroll',
        parent_member_id: employee?.id,
        angle_member_id: employee?.dependents[x].member_id,
        gender: genderTranslatorFor843(employee?.dependents[x]?.gender),
        ssn: employee?.dependents[x].ssn,
        insured_ssn: employee?.ssn,
        coverage_start_date: employee?.coverageStart,
        coverage_end_date: employee?.coverageEnd,
        plan_name: data.planSelected,
        subgroup: data.subgroupSelected,
        street_1: employee.address_object.street_1,
        street_2: employee.address_object.street_2,
        state: employee.address_object.state,
        city: employee.address_object.city,
        zip: employee.address_object.zip
      }
      params.members.push(dependentMemberObject)
    }

    if (data.subgroupSelected && data.subgroupSelected !== 'Standard') {
      for (let x = 0; x < params.members.length; x++) {
        params.members[x].subgroup = data.subgroupSelected
      }
    }

    await editMemberCoverage.mutateAsync(params)
  }

  const onSubmit: SubmitHandler<EditCoverageFormFields> = (data) => {
    try {
      sendEditCoverageRequest(data)
    } catch (err) {
      console.error(err)
      showError(`Information was not successfully edited because of an error.`)
    }
  }

  return (
    <Modal
      show={isEditCoverageModalVisible}
      centered
      size="lg"
      onHide={() => setEditCoverageModalVisible(false)}
      dialogClassName="groupFormModalDialog"
      className="groupFormModal"
      style={{
        zIndex: 2000
      }}
    >
      <Modal.Header className="border-bottom p-4" closeButton>
        <Modal.Title>Edit Coverage</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-2">
        <h6
          className="mb-3 d-flex justify-content-between"
          style={{ fontWeight: 700 }}
        >
          Employee Family
        </h6>
        <DependentsRow>
          <Card className="mx-1" style={{ minWidth: 60, marginBottom: 15 }}>
            <Card.Body className="d-flex justify-content-between">
              <div>
                <p className="mb-0">{employee?.name}</p>
              </div>
            </Card.Body>
          </Card>
          {employee?.dependents.map((d: any) => {
            return (
              <Card className="mx-1" style={{ minWidth: 60, marginBottom: 15 }}>
                <Card.Body className="d-flex justify-content-between">
                  <div>
                    <p className="mb-0">
                      {d.name?.first_name} {d.name?.last_name}
                    </p>
                  </div>
                </Card.Body>
              </Card>
            )
          })}
        </DependentsRow>
        <Row className="my-1 pb-1">
          <Col>
            <h6>
              <span style={{ fontWeight: 700 }}>Coverage Type:</span>{' '}
              {getFamilyIndicator(employee)}
            </h6>
          </Col>
        </Row>
        {groupDataState.subgroups && groupDataState.subgroups?.length >= 1 && (
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label style={{ fontWeight: 700 }}>
                  Available Billing Subgroups
                </Form.Label>
                <Form.Control
                  as="select"
                  className="bg-white"
                  isInvalid={!!errors?.subgroupSelected}
                  disabled={
                    groupDataState?.subgroups &&
                    groupDataState.subgroups.length === 1
                  }
                  value={chosenSubgroup}
                  onChange={(e) => setChosenSubgroup(e.target.value)}
                >
                  <option value="" disabled={true}>
                    {'Please select a subgroup'}
                  </option>
                  {groupDataState?.subgroups &&
                    groupDataState.subgroups.length > 0 &&
                    groupDataState.subgroups.map((subgroup) => (
                      <option value={subgroup}>{subgroup}</option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors?.subgroupSelected?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label style={{ fontWeight: 700 }}>
                Available Plans
              </Form.Label>
              <Form.Control
                as="select"
                className="bg-white"
                isInvalid={!!errors?.planSelected}
                {...register('planSelected')}
              >
                <option disabled={true} value={''}>
                  {'Please select a plan'}
                </option>
                {planData &&
                  planData.plans.map((plan) => (
                    <option value={plan.plan_name}>{plan.plan_name}</option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors?.planSelected?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-top pb-3 pt-3">
        <Button
          disabled={isSubmitting || editMemberCoverage.isLoading}
          className="w-25"
          onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
