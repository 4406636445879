import React, { ReactNode, useState } from 'react'
import { Table as BSTable, Card, Spinner } from 'react-bootstrap'

import { Pagination, IPaginationProps } from '../Pagination'
import { TableSortHeader } from '../TableSortHeader'
import { ITableHeaderProps } from '../../interfaces'
import { CollapseIcon } from './style'
import { WarningIcon } from './WarningIcon'

export interface ITableProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  paginationConfig?: IPaginationProps
  title?: ReactNode
  loading?: boolean
  error?: boolean
  errorElement?: ReactNode
  headers?: ITableHeaderProps[]
  headerClassName?: string
  isCollapsible?: boolean
  tableContainerProps?: React.HTMLAttributes<HTMLDivElement>
  headerRowClassName?: string
  defaultItemsPerPage?: number[]
}

export const Error = () => (
  <div className="m-4 mx-auto text-center">
    <WarningIcon />
    <p className="text-muted mt-2">
      We were unable to load this table. If your issue continues please contact
      support.
    </p>
  </div>
)

export const Table: React.FC<ITableProps> = ({
  children,
  paginationConfig,
  title,
  loading = false,
  error = false,
  errorElement = null,
  headers = [],
  headerClassName = null,
  isCollapsible = false,
  tableContainerProps = {},
  headerRowClassName,
  defaultItemsPerPage = [10, 20, 50],
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  let content = children
  if (loading) {
    content = (
      <tbody data-testid="loading-tbody">
        <tr>
          <td colSpan={headers.length || 1}>
            <div className="text-center p-4">
              <Spinner animation="border" />
            </div>
          </td>
        </tr>
      </tbody>
    )
  }

  if (error) {
    content = (
      <tbody data-testid="error-tbody">
        <tr>
          <td colSpan={headers.length || 1}>{errorElement || <Error />}</td>
        </tr>
      </tbody>
    )
  }

  return (
    <Card {...props}>
      {title}
      <div
        className="table-responsive position-relative"
        {...tableContainerProps}
      >
        {isCollapsible && (
          <CollapseIcon
            className={`icon fs-14 ${
              isCollapsed ? 'icon-dropdown' : 'icon-dropdown-up'
            }`}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
        )}
        <BSTable className="table table-thead-bordered table-nowrap table-align-middle card-table">
          {headers.length ? (
            <thead className={headerClassName as string}>
              <tr className={headerRowClassName}>
                {headers.map((header: ITableHeaderProps, idx) => (
                  <TableSortHeader {...header} key={idx} />
                ))}
              </tr>
            </thead>
          ) : null}

          {!isCollapsed && content}
        </BSTable>
      </div>
      {paginationConfig && !loading && !error && !isCollapsed && (
        <Card.Footer className="py-0 px-3">
          <Pagination
            {...paginationConfig}
            pageNumberList={defaultItemsPerPage}
          />
        </Card.Footer>
      )}
    </Card>
  )
}

export default Table
