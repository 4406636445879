import React from 'react'
import { Dropdown, DropdownProps } from 'react-bootstrap'

const MenuToggle = React.forwardRef<any, any>((props, ref) => (
  <i
    role="button"
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
    className={`icon icon-more text-secondary fs-14 ${props.className || ''}`}
  />
))

const CustomMenu = React.forwardRef<any, any>(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={{ ...style, top: -25 }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled mb-0">{children}</ul>
      </div>
    )
  }
)

export interface IDotsMenuProps extends DropdownProps {
  buttonClassName: string
  menuClassName: string
  menuStyle?: CSSStyleDeclaration
  dropdownId: string
}

export const DotsMenu: React.FC<Partial<IDotsMenuProps>> = ({
  children,
  dropdownId,
  menuClassName = '',
  menuStyle,
  buttonClassName,
  ...others
}) => {
  return (
    <Dropdown {...others}>
      <Dropdown.Toggle
        as={MenuToggle}
        id={dropdownId}
        className={buttonClassName}
      />

      <Dropdown.Menu
        as={CustomMenu}
        style={menuStyle}
        className={`border py-0 ${menuClassName}`}
      >
        {children}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DotsMenu
