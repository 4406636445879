import React, { ChangeEvent } from 'react'
import {
  Button,
  CenteredSpinner,
  Col,
  DatePicker,
  Form,
  PageContainer,
  PageHeaderLight,
  Row,
  SectionTitle,
  useToaster,
  EmployeeProfileForm,
  EmployeeService
} from '../../../index'
import { states, nonNegativeValues, maxZip } from '@angle/utils'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

const defaultValues: EmployeeProfileForm = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  streetAddressLine1: '',
  streetAddressLine2: '',
  city: '',
  state: '',
  zip: '',
  medicareEligible: false,
  otherInsurance: false
}

const useEditProfile = (
  memberId: string,
  profilePath: string,
  employeeService: EmployeeService
) => {
  const history = useHistory()
  const toaster = useToaster()
  const { groupId } = useParams<{ groupId: string }>()

  const { register, control, reset, handleSubmit } = useForm({
    defaultValues
  })

  const { data: employee, isLoading } = useQuery(
    ['editEmployee', memberId],
    () => employeeService.getMemberForForm(memberId, groupId || ''),
    {
      onSuccess: (data: EmployeeProfileForm) => {
        const defaultFormData = { ...data }
        delete defaultFormData.name
        reset(defaultFormData)
      }
    }
  )

  const modifyEmployee = useMutation((formData: EmployeeProfileForm) =>
    employeeService.modifyEmployee(memberId, formData, groupId || '')
  )

  const onSubmit = handleSubmit(async (formData: any) => {
    try {
      await modifyEmployee.mutateAsync(formData)
      history.push(profilePath)
      toaster.show({
        message: 'The profile was updated',
        icon: 'icon-check',
        type: 'success'
      })
    } catch (error) {
      toaster.show({
        message: 'Something went wrong',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  return { onSubmit, register, control, employee, isLoading }
}

export const EmployerPortalEditEmployee: React.FC<{
  isBrokerPortal?: boolean
  employeeService: EmployeeService
}> = ({ isBrokerPortal, employeeService }) => {
  const { id, groupId } = useParams<{ id: string; groupId: string }>()
  const history = useHistory()

  const employeesPagePath = isBrokerPortal
    ? `/groups/${groupId}/employees`
    : '/employees'

  const profilePath = `${employeesPagePath}/${id}`

  const { onSubmit, register, control, isLoading } = useEditProfile(
    id,
    profilePath,
    employeeService
  )

  return (
    <>
      <PageHeaderLight>
        {' '}
        <PageContainer className="mt-4 mb-2 pb-2" size="md">
          <Row>
            <Col md={8}>
              <h1>Edit Information</h1>
            </Col>
            <Col></Col>
          </Row>
        </PageContainer>
      </PageHeaderLight>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <PageContainer className="mt-4 mb-2 pb-2" size="md">
          <SectionTitle>Personal Information</SectionTitle>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    placeholder="First Name"
                    className="bg-white"
                    {...register('firstName', { required: true })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    placeholder="Last Name"
                    className="bg-white"
                    {...register('lastName', { required: true })}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    placeholder="Email Address"
                    className="bg-light"
                    {...register('email', {
                      required: true
                    })}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Date of Birth</Form.Label>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => <DatePicker {...field} />}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <SectionTitle>Mailing Address</SectionTitle>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    placeholder="Address"
                    className="bg-white"
                    {...register('streetAddressLine1', { required: true })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Street Address Line 2</Form.Label>
                  <Form.Control
                    placeholder="Address Line 2"
                    className="bg-white"
                    {...register('streetAddressLine2')}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    placeholder="City"
                    className="bg-white"
                    {...register('city', { required: true })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    className="bg-white custom-select"
                    {...register('state', { required: true })}
                  >
                    {Object.keys(states).map((key) => (
                      <option key={key} value={key}>
                        {(states as any)[key]}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    placeholder="Zip Code"
                    className="bg-white"
                    {...register('zip', {
                      required: true,
                      validate: maxZip
                    })}
                    {...nonNegativeValues}
                    type="number"
                  />
                </Form.Group>
              </Col>
            </Row>
            <SectionTitle>Other</SectionTitle>
            <Row className="mb-2">
              <Col className="d-flex justify-content-between">
                <h6>Medicare Eligible</h6>
                <Controller
                  name="medicareEligible"
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Form.Switch
                      {...field}
                      id="medicareEligible"
                      checked={value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange(e.target.checked)
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-between">
                <h6>Other Health Coverage</h6>
                <Controller
                  name="otherInsurance"
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Form.Switch
                      {...field}
                      id="otherInsurance"
                      checked={value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange(e.target.checked)
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
            <hr />
            <div className="d-flex justify-content-between pt-4 pb-3">
              <Button
                variant="outline-primary"
                onClick={() => history.push(profilePath)}
              >
                Cancel
              </Button>
              <Button type="submit">Save Changes</Button>
            </div>
          </Form>
        </PageContainer>
      )}
    </>
  )
}
