import styled from 'styled-components'

import { Card as BSCard, Button } from 'react-bootstrap'

export const Card = styled(BSCard)`
  width: 100%;
  padding: 20px;
`

export const TimeButton = styled(Button)`
  background: #fff;
  border: 1px solid #e7eaf3;
  border-radius: 4px;
  color: #343a40;
  width: 49%;
  margin-bottom: 0.35rem;

  :hover,
  :focus,
  :active {
    color: #343a40;
    background: #f3f2ff;
    border: 1px solid var(--primary);
  }

  ${(props) => {
    if (props.selected) {
      return `
        color: #343a40;
        background: #f3f2ff;
        border: 1px solid var(--primary);
      `
    }
  }}
`

export const TimeButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
