const base = '/business/onboarding/'

export const businessOnboardingPaths = {
  base: base,
  businessInformation: base + 'business-information',
  adminInformation: base + 'admin-information',
  employerPreferences: base + 'employer-preferences',
  disclosure: base + 'disclosure',
  contributionStrategy: base + 'contribution',
  plan: base + 'plans',
  carrierInformation: base + 'carrier',
  billing: base + 'billing',
  producer: base + 'producer',
  docusign: base + 'docusign',
  complete: base + 'complete'
}
