import React from 'react'
import { EmployerPortalEditDependent } from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { employeeService } from '../../services'
import { PageLayout } from '../layouts/PageLayout'

const EditDependent: React.FC<{ isDependent: boolean }> = ({ isDependent }) => {
  const { dependentId, id: employeeId } = useParams<{
    dependentId: string
    id: string
  }>()

  const { data: employee } = useQuery(['employee', employeeId], () =>
    employeeService.getEmployeeProfile(employeeId)
  )

  const { data: dependent } = useQuery(['editDependent', dependentId], () =>
    employeeService.getMemberForForm(dependentId)
  )
  const profilePath = `/employees/${employeeId}/dependents/${dependentId}`

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Employees', link: '/employees' },
        {
          title: employee?.name || 'Loading...',
          link: `/employees/${employeeId}`
        },
        { title: dependent?.name || 'Loading...', link: profilePath },
        { title: 'Edit Information', link: `${profilePath}/edit` }
      ]}
    >
      <EmployerPortalEditDependent
        employeeService={employeeService}
        isBrokerPortal={false}
      />
    </PageLayout>
  )
}

export default EditDependent
