import React from 'react'
import { IPaginationProps } from '../Pagination'
import { Table } from '../Table'
import { StatusBadge } from './style'
import {
  ITableHeaderProps,
  BadgeStatusVariants,
  IPatientAuthorization
} from '../../interfaces'

export interface ICareAuthorizationHeaders {
  requestType: string
  status: string
  decisionLetter: string
  authorization: string
  facility: string
}

export interface ICareAuthorizationsTableProps
  extends React.HTMLAttributes<HTMLTableElement> {
  authorizations: IPatientAuthorization[]
  headers: ICareAuthorizationHeaders
  viewPdfText: string
  paginationConfig?: IPaginationProps
  LinkEl: React.FC<any>
  loading?: boolean
  [x: string]: any
}

export const CareAuthorizationsTable: React.FC<ICareAuthorizationsTableProps> =
  ({
    authorizations,
    headers,
    viewPdfText,
    LinkEl,
    paginationConfig,
    loading = false,
    ...p
  }) => {
    const tableHeaders: ITableHeaderProps[] = [
      { title: headers.authorization, width: '28%' },
      { title: headers.facility, width: '20%' },
      { title: headers.requestType, width: '17%' },
      { title: headers.status, width: '15%' },
      { title: headers.decisionLetter, width: '20%' }
    ]

    return (
      <Table
        className="w-100"
        paginationConfig={paginationConfig}
        headers={tableHeaders}
        headerClassName="thead-light"
        {...p}
      >
        <tbody>
          {authorizations.map((a: IPatientAuthorization) => (
            <tr key={a.id}>
              <td className="d-flex flex-column">
                <React.Fragment>
                  <span>{a.date}</span>
                  <LinkEl to={a.provider.profileLink}>{a.provider.name}</LinkEl>
                </React.Fragment>
              </td>
              <td>
                {a.facility ? (
                  <LinkEl to={a.facility.profileLink}>{a.facility.name}</LinkEl>
                ) : (
                  <span className="text-muted">{a.date}</span>
                )}
              </td>
              <td className="text-muted">{a.requestType}</td>
              <td>
                <StatusBadge
                  variant={BadgeStatusVariants[a.status]}
                  className="fs-16"
                >
                  {a.status}
                </StatusBadge>
              </td>
              <td>
                <a target="_blank" href={a.decisionLetterLink} rel="noreferrer">
                  {viewPdfText}
                </a>
              </td>
            </tr>
          ))}
          {authorizations.length === 0 && !loading && (
            <tr>
              <td colSpan={tableHeaders.length}>
                <h6 className="text-muted text-center p-5">
                  No authorizations
                </h6>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )
  }

export default CareAuthorizationsTable
