import React from 'react'
import styled from 'styled-components'
import SmallLogo from '../../assets/badge-logo.svg'

export enum MetalTierColor {
  gold = 'warning', // #FFC122
  silver = 'silver', // #828282
  bronze = 'orange', // #EB8C57
  platinum = 'secondary' // #6C757D
}

export interface IAnglePlanBadgeProps
  extends React.HTMLAttributes<HTMLDivElement> {
  textColor?: string
  planName?: string
  metalTier?: string
  logoSize?: 'sm' | 'lg' | 'md'
  textBold?: boolean
}

const BadgeContainer = styled.div`
  margin-right: 7px;
  height: 27px;
  width: 27px;
  position: relative;
`

export const AnglePlanBadge: React.FC<IAnglePlanBadgeProps> = ({
  planName,
  metalTier,
  textColor,
  className,
  logoSize = 'sm',
  textBold,
  ...others
}) => {
  const color = metalTier
    ? MetalTierColor[metalTier as keyof typeof MetalTierColor]
    : 'dark'

  return (
    <React.Fragment>
      <BadgeContainer
        className={`bg-${color} d-inline-flex align-items-center justify-content-center rounded-circle ${
          className || ''
        }`}
        {...others}
      >
        <img src={SmallLogo} alt="Angle Logo" width="15" height="15" />
      </BadgeContainer>
      {planName && (
        <span
          style={{
            color: textColor ? textColor : `var(--${color})`,
            fontWeight: textBold ? 'bold' : 'normal'
          }}
        >
          {planName}
        </span>
      )}
    </React.Fragment>
  )
}

export default AnglePlanBadge
