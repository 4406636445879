import React, { useState } from 'react'
import moment from 'moment'

import {
  Button,
  Col,
  EightThreeFourService,
  EmployerPortalAddDependent,
  Row,
  Table
} from '../../../index'

import { Address, DependentFormType } from './EmployerPortalAddDependent'
import { EditDeleteButtons } from './EditDeleteButtons'


export const AddDependentTable: React.FC<{
  dependentArr: DependentFormType[]
  eightThreeFourService: EightThreeFourService
  employeeAddress: Address
  handleCancelCallback?: () => void
  handleContinueCallback?: () => void
  removalDependentArr: DependentFormType[]
  setDependentArr: (depList: DependentFormType[] ) => void
  updateRemovalDependentArr: (depList: DependentFormType[] ) => void
}> = ({
  dependentArr,
  eightThreeFourService,
  employeeAddress,
  handleCancelCallback,
  handleContinueCallback,
  removalDependentArr, // this is only used for removing pre-existing dependents
  setDependentArr,
  updateRemovalDependentArr // this is only used for removing pre-existing dependents
}) => {

  const [addDependentModalVisible, setAddDependentModalVisible] = useState<boolean>(false)
  const [editModeData, setEditModeData] = useState<DependentFormType | undefined>()
  const [editDependentArrIdx, setEditDependentArrIdx] = useState<number | undefined>()
  

  const handleAddDependent = (dep: DependentFormType) => {
    setDependentArr([...dependentArr, dep])
    setAddDependentModalVisible(false)
  }

  const handleAddDependentModalVisibleSetting = (bool: boolean) => {
    setEditModeData(undefined)
    setAddDependentModalVisible(bool)
  }

  // this is for dependentArr, the dependents that haven't been sent to BE
  const onEdit = (idx: number) => {
    const fetchedDep = dependentArr[idx]
    setEditModeData(fetchedDep)
    setEditDependentArrIdx(idx)
    setAddDependentModalVisible(true)
  }

  const editCallbackForUnsavedDependents = (dep: DependentFormType) => {
    
    if (editDependentArrIdx || editDependentArrIdx === 0) {
      dependentArr[editDependentArrIdx] = { ...dep, memberId: dependentArr[editDependentArrIdx].memberId }
      setDependentArr(dependentArr)
    }
    setEditModeData(undefined)
    setEditDependentArrIdx(undefined)
    setAddDependentModalVisible(false)
  }

  const onDelete = (idx: number) => {
    
    let depArr = dependentArr
    const dep = depArr[idx]

    if (dep.editingState === 'existing-edits' || dep.editingState === 'existing-no-edits') {
      updateRemovalDependentArr([...removalDependentArr, dep])
    }
    depArr = dependentArr.filter((_, index) => index !== idx)
    setDependentArr(depArr)
  }
  return (
    <>
      <Row style={{
        marginLeft: 0,
        marginRight: 0,
        display: 'flex',
        justifyContent: 'space-between' }}>
        <b className="pb-3">Dependents</b>
        <Button
          className="float-right"
          size="sm"
          onClick={() => setAddDependentModalVisible(!addDependentModalVisible)}
        >
          + Add Dependent
        </Button>
      </Row>
      <Row style={{ paddingTop: '15px' }}>
        <Table
          className="force-show-scrollbars"
          headerClassName="bg-light"
          headers={[
            { title: 'Dependent' },
            { title: 'SSN' },
            { title: 'Date of birth' },
            { title: 'Actions' }
          ]}
          style={{ borderBottom: 0, width: '100%' }}
          tableContainerProps={{
            style: { overflow: 'visible' }
          }}
        >
          <tbody>
            { dependentArr.map((dep, idx) => {
              return (
                <tr key={`new-${idx}`}>
                  <td>
                    <Row style={{ marginLeft: 0 }}>
                      {dep.firstName} {dep.lastName}
                    </Row>
                    <Row style={{ marginLeft: 0 }}>
                      <p className="mb-0 text-muted text-capitalize">
                        {dep.dependentType}
                      </p>
                    </Row>
                  </td>
                  <td>
                    {dep.ssn}
                  </td>
                  <td>
                    {moment(dep.dateOfBirth).format('MM/DD/YYYY')}
                  </td>
                  <td>
                    <EditDeleteButtons
                      onEdit={() => onEdit(idx)}
                      onDelete={() => onDelete(idx)}
                    />
                  </td>
                </tr>
              )
            }) }
          </tbody>
        </Table>
        { dependentArr.length === 0 && (
          <Row style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 0,
            width: '100%' }}>
            <span className="text-muted" style={{
              fontSize: '16px',
              fontWeight: 500,
              marginBottom: '1vh',
              marginTop: '3vh' }}>There are no dependents added</span>
            <Button
              className="float-right"
              variant="primary"
              ghost
              size="sm"
              onClick={() => setAddDependentModalVisible(!addDependentModalVisible)}
              style={{ marginBottom: '1vh' }}
            >
              + Add Dependent
            </Button>
          </Row>
        )}
      </Row>
      <hr />
      {handleCancelCallback && handleContinueCallback && (
        <Row>
          <Col xs={12} md={6} className="pb-5">
            <Button
              className="float-left text-primary"
              variant="outline-primary"
              onClick={handleCancelCallback}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <Button
              className="float-right"
              onClick={handleContinueCallback}
            >
              Continue
            </Button>
          </Col>
        </Row>)}
      <EmployerPortalAddDependent
        editCallback={editModeData ? editCallbackForUnsavedDependents : undefined}
        editModeData={editModeData}
        eightThreeFourService={eightThreeFourService}
        employeeAddress={employeeAddress}
        isAddDependentModalVisible={addDependentModalVisible}
        returnDataToParent={handleAddDependent}
        setAddDependentModalVisible={handleAddDependentModalVisibleSetting}
        />
    </>
  )
}
