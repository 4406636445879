import React, { useEffect, useState } from 'react'
import { Card, ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'
import { currencyFormatter } from '@angle/utils'
import { formatCurrencyForNonIntegers } from './getAccumsCardProps'

export const WrapperSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const icon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: '-2px' }}
  >
    <path
      d="M6.50039 0.899902C3.40779 0.899902 0.900391 3.4073 0.900391 6.4999C0.900391 9.5925 3.40779 12.0999 6.50039 12.0999C9.59299 12.0999 12.1004 9.5925 12.1004 6.4999C12.1004 3.4073 9.59299 0.899902 6.50039 0.899902ZM6.50039 9.9999C6.11399 9.9999 5.80039 9.6863 5.80039 9.2999C5.80039 8.9135 6.11399 8.5999 6.50039 8.5999C6.88679 8.5999 7.20039 8.9135 7.20039 9.2999C7.20039 9.6863 6.88679 9.9999 6.50039 9.9999ZM7.46546 6.73324C7.15652 7.01837 6.96706 7.2055 6.96706 7.43324H6.03372C6.03372 6.78504 6.47659 6.37624 6.83219 6.04724C7.20506 5.70284 7.43372 5.47277 7.43372 5.0999C7.43372 4.58517 7.01512 4.16657 6.50039 4.16657C5.98566 4.16657 5.56706 4.58517 5.56706 5.0999H4.63372C4.63372 4.07044 5.47092 3.23324 6.50039 3.23324C7.52986 3.23324 8.36706 4.07044 8.36706 5.0999C8.36706 5.90024 7.86726 6.36177 7.46546 6.73324Z"
      fill="#BDBDBD"
    />
  </svg>
)

export interface IOutOfPocketCardPlusProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  currentValue: number
  maxValue: number
  remainingText: string
  medical: number
  rx: number
  id_member: string
}

export const OutOfPocketCardPlus: React.FC<IOutOfPocketCardPlusProps> = ({
  title,
  currentValue,
  maxValue,
  remainingText,
  medical,
  rx,
  id_member,
  ...p
}) => {
  const percentage = Math.trunc((currentValue * 100) / maxValue)
  const [currentkey, setCurrentKey] = useState(false)

  useEffect(() => {
    setCurrentKey(false)
  }, [id_member])

  return (
    <Card {...p}>
      <Card.Body className="py-3">
        <h6>{title}</h6>
        <div className="fs-28 mt-2 text-nowrap">
          <span>{formatCurrencyForNonIntegers(currentValue)} / </span>
          <span className="text-secondary">
            {formatCurrencyForNonIntegers(maxValue)}
          </span>
        </div>
        <ProgressBar now={percentage} className="mb-1 mt-2" />
        <small className="text-secondary">{remainingText}</small>
        <hr className="mt-1 mb-0" />
        <div
          onClick={() => setCurrentKey(!currentkey)}
          style={{
            cursor: 'pointer',
            marginTop: '-5px',
            marginBottom: '-18px'
          }}
        >
          <small className="text-secondary">Cost Breakdown</small>
          <i
            className="icon fs-10 icon-dropdown text-secondary"
            style={currentkey ? { transform: 'rotate(180deg)' } : {}}
          />
        </div>
        {currentkey && (
          <>
            <WrapperSection className="pb-2 pt-3 fs-12">
              <div className=" text-muted">Medical Claims</div>
              <div className="font-weight-bold">
                {currencyFormatter.format(medical)}
              </div>
            </WrapperSection>
            <WrapperSection className="fs-12">
              <div className=" text-muted">RX Claims</div>
              <div className="font-weight-bold">
                {currencyFormatter.format(rx)}
              </div>
            </WrapperSection>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default OutOfPocketCardPlus
