import styled from 'styled-components'

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 1197px) {
    justify-content: flex-start;
  }
`
