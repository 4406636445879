import React from 'react'
import { Col, Row } from 'react-bootstrap'

const OutOfAreaTravelNetwork = () => {
  return (
    <Col className="mt-3">
      <Row>
        <p className="fs-10 font-weight-bold mb-0">
          Out of area travel network
        </p>
      </Row>
      <Row>
        <Col className="d-flex align-items-center">
          <img className="w-100" src="/fhn.png" alt="First Health Network" />
        </Col>
        <Col>
          <p className="fs-10 mb-0" style={{ whiteSpace: 'nowrap' }}>
            www.myfirsthealth.com
          </p>
          <p className="fs-10 mb-0" style={{ whiteSpace: 'nowrap' }}>
            Provider locator assistance
          </p>
          <p className="fs-10 mb-0" style={{ whiteSpace: 'nowrap' }}>
            1(800)225-5116
          </p>
        </Col>
      </Row>
    </Col>
  )
}

export default OutOfAreaTravelNetwork
