import React from 'react'
import { Wrapper } from './style'

export interface IInfoCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle: React.ReactNode
  footer: React.ReactNode
  linkEl?: React.ReactNode
}

export const InfoCard: React.FC<IInfoCardProps> = ({
  title,
  subtitle,
  footer,
  linkEl,
  ...p
}) => {
  return (
    <Wrapper {...p}>
      <div className="d-flex align-items-baseline justify-content-between w-100 flex-wrap">
        <h6 style={{ marginBottom: '1.25rem' }}>{title}</h6>
        {linkEl}
      </div>
      {subtitle}
      {footer}
    </Wrapper>
  )
}

export default InfoCard
