import { IBusinessOnboardingDTO } from '../../interfaces'
import { getNewCurrentStep } from '../../utils/utils'
// import { StrategyType } from './context'

export const mapEmployerContributionToDTO = (
  formData: any,
  backendStepNumber: number
) => {
  return {
    employer_contribution_employee_amount:
      formData.employerContributionEmployee,
    employer_contribution_dependent_amount:
      formData.employerContributionDependent,
    employer_contribution_amount_type: formData.type,
    in_progress_state: getNewCurrentStep(backendStepNumber, 5)
  }
}

export const mapDTOtoEmployerContribution = (data: IBusinessOnboardingDTO) => {
  return {
    employerContributionEmployee: data.employer_contribution_employee_amount,
    employerContributionDependent: data.employer_contribution_dependent_amount,
    type: data.employer_contribution_amount_type,
    stepNumber: data.in_progress_state,
    isFronting: data.insurer_type === "fronting_arrangement"
  }
}

// export const calculateContribution = (
//   type: StrategyType,
//   value: number,
//   total: number
// ) => {
//   if (type === 'exact')
//     return {
//       employee: total - Number(value),
//       employer: Number(value),
//       total
//     }

//   if (type === 'percentage')
//     return {
//       employee: total * (1 - Number(value) / 100),
//       employer: total * (Number(value) / 100),
//       total
//     }

//   if (type === 'flat')
//     return {
//       employee: total - Number(value),
//       employer: Number(value),
//       total
//     }

//   if (type === 'flat_employee')
//     return {
//       employer: total - Number(value),
//       employee: Number(value),
//       total
//     }
// }
