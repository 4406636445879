import React from 'react'
import { DependentConfirmationForm } from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { useDependatForm } from './context'

export const DependentConfirmation = () => {
  const { control, onSubmit } = useDependatForm()
  const history = useHistory()

  const goBack = () => {
    history.push(`/employees/1/dependents/registration`)
  }

  return (
    <DependentConfirmationForm
      control={control as any}
      onAddDependent={onSubmit}
      onBack={goBack}
      backText="Back"
    />
  )
}

export default DependentConfirmation
