import React from 'react'
import { AnglePlanBadge } from '../AnglePlanBadge'
import { Button } from '../Button'
import { BorderContainer, Wrapper } from './style'

export interface IPlanCardProps {
  planName?: string
  price: string
  description: string
  deductible: string
  copay: string
  type?: string
  metalTier?: string
  selectPlan: (planType: any) => void
  onMoreDetails?: () => void
}

export const PlanCard: React.FC<IPlanCardProps> = ({
  planName,
  metalTier,
  price,
  description,
  deductible,
  copay,
  selectPlan,
  onMoreDetails
}) => {
  return (
    <Wrapper>
      <div>
        <AnglePlanBadge
          metalTier={metalTier}
          planName={planName}
          style={{ display: 'block' }}
          textColor="black"
        />
      </div>
      <div className="mt-4">
        <h3 className="d-inline">{price}/</h3>
        <h4 className="text-muted d-inline">month</h4>
      </div>
      <div className="justify-content-center mt-3">
        <Button onClick={selectPlan} className="w-100 text-nowrap px-2">
          Select {planName}
        </Button>
      </div>
      <div className="mt-4">
        <p style={{ height: '3.56rem' }} className="fs-12 text-muted mb-3">
          {description}
        </p>
      </div>
      <div>
        <BorderContainer>
          <h5>{deductible}</h5>
          <p className="text-muted m-0">Deductible</p>
        </BorderContainer>
        <BorderContainer>
          <h5>{copay}</h5>
          <p className="text-muted m-0">Doctor Visit Copay</p>
        </BorderContainer>
        <div style={{ flex: 1 }}>
          <Button
            variant="primary"
            ghost
            className="w-100"
            style={{ borderColor: 'var(--card-border)', width: '100%' }}
            onClick={onMoreDetails}
          >
            More details
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

export default PlanCard
