import {
  StatementDTO,
  TransactionDTO,
  GroupDTO,
  PaginateResponse,
  PaymentsService,
  GroupService,
  AchPayment,
  PlaidPayment
} from 'react-angle-dashboard-components'
import {
  BillingGeneral,
  Statement,
  Transaction
} from '../interfaces/views/billing'
import moment from 'moment'

const mapTransaction = (transaction: TransactionDTO): Transaction => ({
  id: transaction.vendor_transaction_id,
  date: transaction.date,
  detail:
    (transaction.vendor === 'svb' ? 'ACH Transfer' : transaction.vendor) +
    ' - Bank account: ' +
    transaction.mask,
  status: transaction.status,
  amount: transaction.amount
})

const mapStatement = (statement: StatementDTO): Statement => ({
  id: statement.statement_id,
  period:
    moment(statement.start_date).format('MMM Do YYYY') +
    ' - ' +
    moment(statement.end_date).format('MMM Do YYYY')
})

const mapDefault = (data: any): { mask: string } => {
  return data.accounts?.find((a: any) => a.is_default)
}

export class BillingService {
  payments: PaymentsService
  group: GroupService

  constructor(base: string) {
    this.payments = new PaymentsService(base)
    this.group = new GroupService(base)
  }

  async getBillingGeneralData(): Promise<BillingGeneral> {
    return this.group.getInfo().then((r: GroupDTO) => ({
      paperlessBilling: r.paperless_billing,
      autopay: r.autopay,
      currentBalanceDue: r.current_balance_due
    }))
  }

  async getDefaultAccountName(): Promise<{ mask: string }> {
    return this.payments.getAccounts().then(mapDefault)
  }

  async getTransactionsList(
    pageNumber: number,
    pageLimit: number = 5
  ): Promise<PaginateResponse<Transaction[], 'transactions'>> {
    return this.payments.getTransactions(pageNumber, pageLimit).then((r) => ({
      num_pages: +r.num_pages,
      page_limit: +r.page_limit,
      page_number: +r.page_number,
      total_count: +r.total_count,
      transactions: r.transactions.map(mapTransaction)
    }))
  }

  async getStatementUrl(statementId: string) {
    return this.payments.getStatementUrl(statementId)
  }

  async getStatement(statementId: string): Promise<any> {
    return this.payments.getStatement(statementId)
  }
  async makePlaidPayment(paymentData: PlaidPayment) {
    return this.payments.makePlaidPayment(paymentData)
  }

  async makeAchPayment(paymentData: AchPayment) {
    return this.payments.makeAchPayment(paymentData)
  }

  async getStatementsList(
    pageNumber: number,
    pageLimit: number = 5
  ): Promise<PaginateResponse<Statement[], 'statements'>> {
    return this.payments.getStatements(pageNumber, pageLimit).then((r) => ({
      num_pages: +r.num_pages,
      page_limit: +r.page_limit,
      page_number: +r.page_number,
      total_count: +r.total_count,
      statements: r.statements.map(mapStatement)
    }))
  }
}
