import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { usePlaidLink } from 'react-plaid-link'
import { IGetAccounts, IPaymentAccount } from '../../interfaces'
import {
  AuthService,
  GroupService,
  PaymentsService,
  PlaidService
} from '../../services'
import { CenteredSpinner } from '../CenteredSpinner'
import { useToaster } from '../Toast'
import { Account } from './Account'
import { AddBankAccountModal } from './AddBankAccountModal'
import { IDisplayAccount, IPlaidAccount } from './interfaces'
import {
  IAchAccount,
  ManuallyAddBankAccountModal
} from './ManuallyAddBankAccountModal'
import { PlaidButton } from './PlaidButton'
import { SelectAccountsModal } from './SelectAccountsModal'
import { GhostBtn, TitleRow } from './styles'
import { useQuery } from 'react-query'

import { Col, Form, Row } from '../../index'

const mapUserAccounts = (response: IPaymentAccount[]): IDisplayAccount[] => {
  return response.map((r) => ({
    item_id: r.account_id.toString(),
    institution_name: r.institution_name || 'ACH Account',
    is_default: r.is_default,
    account_id: r.account_id.toString(),
    mask: r.mask,
    account_type: r.account_type,
    has_issues: r.has_issues,
    name: r.name,
    routing_number: r.routing
  }))
}

interface IBankAccountsProps {
  userAccounts: IDisplayAccount[]
  setUserAccounts: Function
  setInitialDefaultAccount?: Function
  plaidService: PlaidService
  authService: AuthService
  paymentsService: PaymentsService
  groupService: GroupService
  notUpdateOnSelection?: boolean
  groupId?: string
  autopay?: boolean
  initialAutopayState?: boolean
  onAddAch?: () => void
  onAccountSelection?: () => void
}

export const BankAccounts: React.FC<IBankAccountsProps> = ({
  setUserAccounts,
  setInitialDefaultAccount,
  userAccounts,
  paymentsService,
  plaidService,
  authService,
  groupService,
  autopay,
  initialAutopayState,
  onAddAch,
  onAccountSelection,
  notUpdateOnSelection,
  groupId
}) => {
  // Modals
  const [showOptionsModal, setShowOptionsModal] = useState(false)
  const [showSelectPlaidAccountsModal, setShowSelectPlaidAccountsModal] =
    useState(false)
  const [showAddAccountManuallyModal, setShowAccountManuallyModal] =
    useState(false)

  const [isDeletingModal, setIsDeletingModal] = useState(false)
  const [showDefaultAccountDeletion, setShowDefaultAccountDeletion] =
    useState(false)
  const [accountToDelete, setAccountToDelete] =
    useState<IDisplayAccount | null>(null)

  // Plaid
  const [publicToken, setPublicToken] = useState('')
  const [updateToken, setUpdateToken] = useState('')
  const [updatedAccount, setUpdatedAccount] = useState<IDisplayAccount | null>(
    null
  )
  const [plaidSelectedAccounts, setplaidSelectedAccounts] = useState<
    IPlaidAccount[]
  >([])

  const [isLoadingList, setIsLoadingList] = useState(false)
  const { show: setToastr } = useToaster()

  const getAccounts = useCallback(() => {
    setIsLoadingList(true)
    paymentsService
      .getAccounts()
      .then((response: IGetAccounts) => {
        const { accounts } = response
        const mappedAccounts = mapUserAccounts(accounts)
        setUserAccounts(mappedAccounts)
        setIsLoadingList(false)
        if (setInitialDefaultAccount) {
          setInitialDefaultAccount(mappedAccounts.find(
            (a: IDisplayAccount) => a.is_default
          ))
         }
      })
      .catch(() => {
        setIsLoadingList(false)
        setToastr({
          message: 'An error has occurred trying to get the bank accounts',
          icon: 'icon-danger',
          type: 'danger'
        })
      })
  }, [setToastr, setUserAccounts, paymentsService, initialAutopayState])

  const createLinkToken = useCallback(() => {
    plaidService
      .createLinkToken()
      .then((response) => {
        setPublicToken(response?.link_token)
      })
      .catch((e: any) => {
        setToastr({
          message:
            e.message ||
            'We are unable to connect your account at this time, please contact us at onboarding@anglehealth.com or 855-937-1855 for assistance',
          icon: 'icon-danger',
          type: 'danger'
        })
      })
  }, [setToastr, plaidService])

  useEffect(() => {
    getAccounts()
  }, [getAccounts])

  useEffect(() => {
    createLinkToken()
  }, [createLinkToken])

  const handleFetchedAccounts = (metadata: any) => {
    if (!metadata.accounts.length) {
      setToastr({
        message:
          "You don't have any checkings/savings accounts. Please try again with another financial institution.",
        icon: 'icon-close',
        type: 'warning'
      })
    }

    const mappedAccounts: IPlaidAccount[] = metadata.accounts.map((a: any) => ({
      ...a,
      institutionName: metadata.institution.name,
      accountName: a.name
    }))

    setplaidSelectedAccounts(mappedAccounts)
    setShowOptionsModal(false)
    setShowSelectPlaidAccountsModal(true)
  }

  const handlePlaidButtonSuccess = (token: string, metadata: any) => {
    setPublicToken(token)
    handleFetchedAccounts(metadata)
  }

  const handleSelectedPlaidAccounts = () => {
    setIsLoadingList(true)
    setShowSelectPlaidAccountsModal(false)

    paymentsService
      .addPlaidAccounts(publicToken, plaidSelectedAccounts)
      .then(() => paymentsService.getAccounts())
      .then((response) => {
        const { accounts } = response
        setUserAccounts(mapUserAccounts(accounts))
        setIsLoadingList(false)
        setToastr({
          message: 'Accounts have been added successfully',
          icon: 'icon-check',
          type: 'success'
        })
      })
      .catch((e) => {
        setIsLoadingList(false)
        setToastr({
          message:
            e.message ||
            'We are unable to connect your account at this time, please contact us at onboarding@anglehealth.com or 855-937-1855 for assistance',
          icon: 'icon-danger',
          type: 'danger'
        })
      })
  }

  const handleManuallyAdd = (acc: IAchAccount) => {
    setIsLoadingList(true)

    const data = {
      account_number: acc.account_number,
      routing_number: acc.routing_number,
      account_type: acc.account_type,
      name: (acc as any).name,
      time: (acc as any).acceptedTime,
      text: (acc as any).acceptedText
    }

    paymentsService
      .addAchAccount(data)
      .then(() => paymentsService.getAccounts())
      .then((response) => {
        const { accounts } = response
        setUserAccounts(mapUserAccounts(accounts))
        setIsLoadingList(false)
        setShowAccountManuallyModal(false)
        setShowOptionsModal(false)
      })
      .catch((e) => {
        setIsLoadingList(false)
        setToastr({
          message:
            e?.message ||
            'We are unable to connect your account at this time, please contact us at onboarding@anglehealth.com or 855-937-1855 for assistance',
          icon: 'icon-danger',
          type: 'danger'
        })
      })
  }

  const handleCheckApproval = () => {
    setIsLoadingList(true)

    paymentsService
      .addCheckAccount()
      .then(() => paymentsService.getAccounts())
      .then((response) => {
        const { accounts } = response
        setUserAccounts(mapUserAccounts(accounts))
        setIsLoadingList(false)
        setShowOptionsModal(false)
        setToastr({
          message: 'Pay by Check request successful',
          icon: 'icon-check',
          type: 'success'
        })
      })
      .catch((e) => {
        setIsLoadingList(false)
        setToastr({
          message:
            e?.message ||
            'We are unable to connect your account at this time, please contact us at onboarding@anglehealth.com or 855-937-1855 for assistance',
          icon: 'icon-danger',
          type: 'danger'
        })
      })
  }

  const handleDeleteAccount = async (account: IDisplayAccount) => {
    if (account.is_default) {
      setAccountToDelete(null)
      setIsDeletingModal(false)
      setShowDefaultAccountDeletion(true)
      return
    }

    setIsLoadingList(true)
    try {
      switch (account.account_type) {
        case 'plaid':
          await paymentsService.removePlaidAccount(account.account_id)
          break
        case 'ach':
          await paymentsService.removeAchAccount(account.account_id)
          break
        case 'check':
          await paymentsService.removeCheckAccount(account.account_id)
          break
      }
      setToastr({
        message: 'Account has been removed successfully',
        icon: 'icon-check',
        type: 'success'
      })

      const response = await paymentsService.getAccounts()

      setUserAccounts(mapUserAccounts(response.accounts))
      setIsLoadingList(false)
    } catch (e) {
      setIsLoadingList(false)
      setToastr({
        message: 'An error has occurred deleting the account',
        icon: 'icon-danger',
        type: 'danger'
      })
    }
  }

  const handleDefaultAccount = (data: any) => {
    const newAccounts = userAccounts.map((a: IDisplayAccount) => ({
      ...a,
      is_default: false
    }))

    let defaultAccIndex = userAccounts.findIndex(
      (a: IDisplayAccount) => a.account_id === data.account_id
    )

    newAccounts[defaultAccIndex].is_default = true
    setUserAccounts(newAccounts)
    if (!notUpdateOnSelection) paymentsService.setAccountAsDefault(data)
  }

  const { open } = usePlaidLink({
    token: updateToken,
    onSuccess: async (token: string, metadata: any) => {
      try {
        if (!updatedAccount) throw new Error('Not account selected')

        await plaidService.accountUpdated(updatedAccount.account_id)
        setUpdateToken('')
        setUpdatedAccount(null)
        getAccounts()
      } catch (e) {
        setToastr({
          message: 'An error has occurred updating the account',
          icon: 'icon-danger',
          type: 'danger'
        })
      }
    }
  })

  const handleAccountUpdate = async (account: IDisplayAccount) => {
    try {
      setUpdatedAccount(account)
      const { link_token } = await plaidService.createLinkTokenById(
        account.account_id
      )
      setUpdateToken(link_token)
    } catch (e) {
      setToastr({
        message: 'An error has occurred updating the account',
        icon: 'icon-danger',
        type: 'danger'
      })
    }
  }

  useEffect(() => {
    if (updateToken) {
      open()
    }
  }, [open, updateToken])

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isDeletingModal}
        onHide={() => {
          setAccountToDelete(null)
          setIsDeletingModal(false)
        }}
      >
        <Modal.Header className="pt-4 pb-2" closeButton>
          <Modal.Title>Delete Bank Account</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-2">
          <p>
            Are you sure you want to delete this account? This account cannot be
            undone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => {
              setAccountToDelete(null)
              setIsDeletingModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              if (accountToDelete) {
                handleDeleteAccount(accountToDelete)
                setAccountToDelete(null)
                setIsDeletingModal(false)
              }
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDefaultAccountDeletion}
        onHide={() => setShowDefaultAccountDeletion(false)}
      >
        <Modal.Header className="pt-4 pb-2" closeButton>
          <Modal.Title>Unable to delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-2">
          <p>You cannot delete the default account</p>
        </Modal.Body>
      </Modal>

      {plaidSelectedAccounts.length > 0 && (
        <SelectAccountsModal
          show={showSelectPlaidAccountsModal}
          toggleModal={() => setShowSelectPlaidAccountsModal(false)}
          accounts={plaidSelectedAccounts}
          setAccounts={setplaidSelectedAccounts}
          continueCallback={handleSelectedPlaidAccounts}
        />
      )}
      {publicToken && (
        <AddBankAccountModal
          show={showOptionsModal}
          toggleModal={setShowOptionsModal}
          checkCallback={handleCheckApproval}
          publicToken={publicToken}
          successCallback={handlePlaidButtonSuccess}
          sessionExpiredCallback={() => {}}
          manuallyAddCallback={
            onAddAch ? onAddAch : () => setShowAccountManuallyModal(true)
          }
          plaidButton={(props) => (
            <PlaidButton {...props} authService={authService}>
              {props.children}
            </PlaidButton>
          )}
        />
      )}
      <ManuallyAddBankAccountModal
        show={showAddAccountManuallyModal}
        toggleModal={() => setShowAccountManuallyModal(false)}
        addCallback={handleManuallyAdd}
      />
      <TitleRow
        className="d-flex justify-content-between align-items-center"
        noGutters
      >
        <b>Payment methods</b>
        <GhostBtn
          variant="primary"
          ghost
          onClick={() => setShowOptionsModal(true)}
        >
          Add a bank account
        </GhostBtn>
      </TitleRow>
      {isLoadingList ? (
        <CenteredSpinner style={{ height: 'auto' }} />
      ) : (
        <>
          {userAccounts.map((account: IDisplayAccount) => (
            <Account
              isDefault={account.is_default}
              bankName={account.institution_name}
              has_issues={account.has_issues}
              lastNumbers={account.mask}
              onRemoveButtonClick={() => {
                setAccountToDelete(account)
                setIsDeletingModal(true)
              }}
              accountId={account.account_id || ''}
              itemId={account.item_id || account.account_id || ''}
              onSetDefaultHandler={
                onAccountSelection ? onAccountSelection : handleDefaultAccount
              }
              key={account.account_id}
              type={account.account_type}
              onUpdateButtonClick={() => handleAccountUpdate(account)}
              groupService={groupService}
              groupId={groupId}
              isAutoPay={autopay}
              routing_number={account.routing_number}
              name={account.name}
            />
          ))}
          {!userAccounts.length && (
            <span className="text-muted">No bank accounts added</span>
          )}
          <div className="my-4">
            <p>
              For further help with your billing options or to change payment
              methods, please reach out to{' '}
              <a href="mailto:billing@anglehealth.com">
                billing@anglehealth.com
              </a>
            </p>
          </div>
        </>
      )}
    </>
  )
}
