import { ApiService } from './base'
import { DocusignAuthRequest } from '../../interfaces/docusign'

export class DocusignService extends ApiService {
  async getDocusignLoginUrl(): Promise<any> {
    return this.request({
      method: 'get',
      url: '/docusign_login_url',
      credentials: true
    }).then((r) => r)
  }

  async loginDocusign(code: string, state: string): Promise<any> {
    return this.request({
      method: 'post',
      url: '/docusign_login',
      credentials: true,
      data: { code }
    }).then((r) => r)
  }

  async getDocusignSigningUrl(redirect_url: string): Promise<any> {
    return this.request({
      method: 'post',
      url: '/docusign/sign-url',
      credentials: true,
      data: {
        redirect_url
      }
    }).then((r) => r)
  }
  async updateSignedStatus(): Promise<any> {
    return this.request({
      method: 'post',
      url: '/docusign/signed',
      credentials: true
    }).then((r) => r)
  }

  async docusignAuth(data: DocusignAuthRequest): Promise<any> {
    return this.request({
      method: 'post',
      url: '/docusign/auth',
      credentials: true,
      data
    })
  }
}
