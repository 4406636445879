import { IDirectoryQueryData } from '../../../interfaces/directory'
import { ICheckboxFilter, IFilter } from '../../../interfaces/directory-filters'

export interface IFacilityListReqData {
  lat: number
  lon: number
  page_limit: number
  page_number: number
  [filterName: string]: any
}

export const mapDirectoryListQuery = (
  queryData: IDirectoryQueryData
): IFacilityListReqData => {
  const { lat, lng, filters, bounds, page, nameFilter, maxElementsPerPage } =
    queryData

  let requestData: IFacilityListReqData = {
    lat: lat,
    lon: lng,
    page_limit: maxElementsPerPage,
    page_number: page
  }

  if (filters) {
    requestData = {
      ...requestData,
      ...parseDirectoryFilters(filters)
    }
  }

  if (bounds) {
    requestData = {
      ...requestData,
      distance: parseBoundsFilter(lng, bounds.nw.lng)
    }
  }

  if (nameFilter) {
    requestData = {
      ...requestData,
      query: nameFilter
    }
  }

  return requestData
}

export const parseCheckboxFilters = (
  filter: ICheckboxFilter
): Record<string, any> => {
  const parsedFilter: string[] = []

  for (const key in filter.value) {
    if (filter.value[key].value) {
      parsedFilter.push(key)
    }
  }

  return parsedFilter
}

export const parseDirectoryFilters = (
  filters: IFilter[]
): Record<string, any> => {
  let parsedFilters: Record<string, any> = {}
  filters.forEach((f: IFilter) => {
    if (f.type === 'checkbox') {
      parsedFilters = {
        ...parsedFilters,
        [f.filterKey]: parseCheckboxFilters(f)
      }
    } else {
      parsedFilters[f.filterKey] = f.value || undefined
    }
  })

  if (parsedFilters.distance === 0 || !parsedFilters.distance) {
    parsedFilters.distance = 250
  }

  return parsedFilters
}

export const parseBoundsFilter = (
  boundsLng: number,
  currentLng: number
): number => {
  return Math.abs(currentLng - boundsLng) * 69
}

function capitalizeWords(words) {
  var separateWord = words.toLowerCase().split(' ')
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
  }
  return separateWord.join(' ')
}

export const mapTypesToCheckboxFilter = (types: string[]) => {
  return types.reduce((acc, type) => {
    const filters = { ...acc }
    filters[type] = {
      label: capitalizeWords(type),
      value: type === 'URGENT CARE CENTER' ? true : false
    }
    return filters
  }, {})
}
