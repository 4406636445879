import React, { useMemo } from 'react'
import { SelectText, PageSelect } from './styles'

export interface IPaginationDropdownProps {
  onSelectChange: Function
  selectFontSize?: number
  maxItemsShown: number
  totalItems: number
  pageNumberList?: number[]
}

export const PaginationDropdown: React.FC<IPaginationDropdownProps> = ({
  onSelectChange,
  selectFontSize,
  totalItems,
  maxItemsShown,
  pageNumberList = [10, 20, 50]
}) => {
  const values = useMemo(() => {
    const newArr = pageNumberList.map((v) => (v > totalItems ? totalItems : v))
    return [...new Set(newArr)]
  }, [totalItems, pageNumberList])

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <SelectText selectFontSize={selectFontSize}>Showing</SelectText>
      <PageSelect
        className="mx-2"
        onChange={onSelectChange}
        value={maxItemsShown}
        selectFontSize={selectFontSize}
        data-testid="select"
      >
        {values.map((value, idx) => {
          return (
            <option key={idx} value={value} data-testid="select-option">
              {value}
            </option>
          )
        })}
      </PageSelect>
      <SelectText selectFontSize={selectFontSize}>
        of {totalItems.toLocaleString('en-US')}
      </SelectText>
    </div>
  )
}
