import { IDirectoryQueryData } from '../../interfaces/directory'
import {
  IProviderProfile,
  IProviderProfileDTO,
  IProviderV2DTO
} from '../../interfaces/providers'
import { formatPhoneNumber } from '@angle/utils'
import { ApiService } from './base'
import { mapDirectoryListQuery } from './mappers/directory-mapper'

const ENDPOINTS = {
  providers: '/providers',
  specialtiesV1: '/provider-specialties',
  specialtiesV2: '/providers/specialties'
}

type GetClaimsType = {
  query?: string
  page_number?: number
  page_limit?: number
  ordering?: string
}

export class ProviderService extends ApiService {
  async search(
    provider_type: 'all' | 'provider' | 'facility',
    lat: number,
    lon: number,
    query: string
  ): Promise<any> {
    return this.request({
      method: 'get',
      url: `/search?provider_type=${provider_type}&lat=${lat}&lon=${lon}&query=${query}`
    }).then((r) => r.data)
  }

  async find(query: IDirectoryQueryData | null) {
    if (!query) return

    const params = mapDirectoryListQuery(query)

    return this.request({
      method: 'get',
      url: ENDPOINTS.providers,
      params
    }).then((response: { data: IProviderV2DTO[]; total_count: number }) => {
      const items = response.data.map((provider: IProviderV2DTO) => ({
        ...provider,
        name: [provider.first_name, provider.middle_name, provider.last_name]
          .map((p) => p?.trim())
          .filter((p) => !!p)
          .join(' '),
        address: provider.locations[0].address_line_1,
        coordinates: {
          lat: provider.locations[0].lat,
          lng: provider.locations[0].lon
        },
        locations: provider.locations,
        type: provider.first_health_physician_facility_type
      }))

      return {
        items,
        totalCount: response.total_count
      }
    })
  }

  /**
   * Use getById instead
   *
   * @deprecated
   */
  async findById(id: string): Promise<IProviderProfile> {
    const result: IProviderProfileDTO = await this.request({
      method: 'post',
      url: `${ENDPOINTS.providers}/${id}`,
      credentials: true
    })

    const aboutList = Object.keys(result.about).map((k) => result.about[k])

    const offices = result.offices.map((e) => ({
      name: e.address,
      id: e.office_id,
      times: e.appointments.map((a) => ({ value: a })),
      coordinates: e.coordinates,
      details: e.details
    }))

    return {
      ...result,
      id: result.profile_id,
      about: aboutList,
      offices
    } as any
  }

  async getByProId(id: string): Promise<any> {
    const result: any = await this.request({
      method: 'get',
      url: `/providers/${id}`,
      credentials: true
    }).then((r) => r)

    return result
  }

  async getClaimsBySearch(id: string, params?: GetClaimsType): Promise<any> {
    return this.request({
      method: 'get',
      url: `/providers/${id}/claims`,
      params
    })
  }

  async getById(id: string): Promise<IProviderProfile> {
    const result: IProviderProfileDTO = await this.request({
      method: 'get',
      url: `${ENDPOINTS.providers}/${id}`,
      credentials: true
    })

    result.locations = result.locations.map((l) => ({
      ...l,
      address:
        `${l.address_line_1}`.toLocaleLowerCase() +
        `${l.address_line_2 ? ', ' + l.address_line_2 : ''}, ${
          l.city
        }, `.toLocaleLowerCase() +
        `${l.state}`.toUpperCase() +
        `, ${l.zip_code}`,
      specialties: result.specialties[0].toLocaleLowerCase()
    }))

    return {
      ...result,
      current: result.locations[0],
      full_name: (
        result.first_name +
        ' ' +
        (result.middle_name ? result.middle_name + ' ' : '') +
        result.last_name
      ).toLocaleLowerCase(),
      mapLocations: result.locations.map((l) => ({
        coordinates: {
          lat: Number(l.lat),
          lng: Number(l.lon)
        },
        details: [
          {
            name: 'Phone',
            value: formatPhoneNumber(l.phone) || ' - '
          },
          {
            name: 'Fax',
            value: formatPhoneNumber(l.fax) || ' - '
          }
        ],
        id: l.provider_id,
        name: l.address || ' - '
      }))
    }
  }

  async getSpecialties(isV1?: boolean) {
    const req: any = {
      method: 'get',
      url: isV1 ? ENDPOINTS.specialtiesV1 : ENDPOINTS.specialtiesV2,
      credentials: true
    }

    return this.request(req).then((r) =>
      isV1 ? r['provider-specialties'] : r.data
    )
  }
}
