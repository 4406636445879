import styled from 'styled-components'
import { Col } from 'react-bootstrap'

export const PageSelect = styled.select<{selectFontSize?: number; onChange: any}>`
  background-color: #fff;
  border: none;
  color: var(--primary);
  font-size: ${(props) => props.selectFontSize + 'px' || '16px'};
  padding: 4px;
`

export const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
  @media (max-width: 610px) {
    justify-content: center;
  }
`

export const LeftCol = styled(Col)`
  display: flex;
  @media (max-width: 610px) {
    justify-content: center;
  }
`

export const SelectText = styled.span<{selectFontSize?: number}>`
  font-size: ${(props) => props.selectFontSize + 'px' || '16px'};
  white-space: nowrap;
`
