import { useQuery } from 'react-query'

interface IAuthService {
  getUserInfo(): any
}

export const useAuthUserInfo = (
  service: IAuthService,
  queryKey = 'logged-in-user',
  cacheTime = 0,
  retry = 2
) =>
  useQuery(queryKey, async () => service.getUserInfo(), {
    refetchOnWindowFocus: false,
    cacheTime,
    retry
  })
