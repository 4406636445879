import React, { useState } from 'react'
import { useHistory, Route, Switch, Redirect } from 'react-router-dom'
import { useQuery } from 'react-query'
import ReportTable from './ReportTable'
import ReportingForm from './ReportingForm'
import { ReportTypes, ReportingService } from '../../services'
import { Spinner } from 'react-bootstrap'

import type { ReportData } from './types'

interface ReportingProps {
  reportingService: ReportingService
  companyName: string
  basePagePath: string
  groupId?: string
  hideHeaders?: boolean
}

const Reporting = ({
  reportingService,
  companyName,
  basePagePath,
  groupId,
  hideHeaders = false
}: ReportingProps) => {
  const history = useHistory()

  const [selectedPlanYearId, setSelectedPlanYearId] = useState<number>()
  const [selectedReportId, setSelectedReportId] = useState<ReportTypes>()
  const [selectedSubgroup, setSelectedSubgroup] = useState<string>()
  const [reportData, setReportData] = useState<ReportData>()


  let basePath = basePagePath
  if (groupId) {
    basePath = basePath.replace(':groupId', groupId)
  }

  const onViewReport = () => {
    if (selectedPlanYearId !== undefined && selectedReportId !== undefined) {
      reportingService.getReports(
        {
          planYearId: selectedPlanYearId,
          reportTypeId: selectedReportId,
          subgroup: selectedSubgroup,
        },
        groupId
      ).then((r) => {
        setReportData(r)
        history.push(`${basePath}/table`)
      })
    }
  }

  return (
    <Switch>
      <Route exact path={basePagePath}>
        <ReportingForm
          reportingService={reportingService}
          selectedPlanYearId={selectedPlanYearId}
          selectedReportId={selectedReportId}
          selectedSubgroup={selectedSubgroup}
          reportData={reportData}
          setSelectedPlanYearId={setSelectedPlanYearId}
          setSelectedReportId={setSelectedReportId}
          setSelectedSubgroup={setSelectedSubgroup}
          setReportData={setReportData}
          onViewReport={onViewReport}
          groupId={groupId}
          hideHeader={hideHeaders}
          groupName={companyName}
        />
      </Route>
      <Route path={`${basePagePath}/table`}>
        {!reportData && <Redirect to={basePath} />}
        {reportData ? (
          <ReportTable
            title={reportData.title}
            groupName={companyName}
            description={reportData.description}
            table={reportData.table}
            footer={reportData.footer}
            extra={reportData.extra}
            hideHeader={hideHeaders}
          />
        ) : (
          <Spinner animation="border" />
        )}
      </Route>
    </Switch>
  )
}

export default Reporting
