import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToasterProvider } from 'react-angle-dashboard-components'
import { BrowserRouter as ReactRouter } from 'react-router-dom'
import { Router } from './routes/Router'
import { AuthContextProvider } from './context/Auth/Auth'
import { GroupContextProvider } from './context/Group'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactRouter>
        <ToasterProvider styles={{ zIndex: 999999 }}>
          <AuthContextProvider>
            <GroupContextProvider>
              <Router />
            </GroupContextProvider>
          </AuthContextProvider>
        </ToasterProvider>
      </ReactRouter>
    </QueryClientProvider>
  )
}

export default App
