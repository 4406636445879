import { EmployerPortalEditEmployee } from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { employeeService } from '../../services'
import { PageLayout } from '../layouts/PageLayout'

const EditEmployee = () => {
  const { id } = useParams<{ id: string }>()

  const profilePath = `/employees/${id}`

  const { data: employee } = useQuery(['editEmployee', id], () =>
    employeeService.getMemberForForm(id)
  )

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Employees', link: '/employees' },
        { title: employee?.name || 'Loading...', link: profilePath },
        { title: 'Edit Information', link: `${profilePath}/edit` }
      ]}
    >
      <EmployerPortalEditEmployee employeeService={employeeService} />
    </PageLayout>
  )
}

export default EditEmployee
