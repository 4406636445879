import React from 'react'
import { PageLayout } from '../../../../pages/layouts/PageLayout'

export const Complete = () => {

  return (
    <PageLayout>
      <section className="d-flex flex-column justify-content-center align-items-center my-8">
        <div className="mb-4">
          <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="21" cy="21" r="21" fill="#7DD591" fill-opacity="0.3" />
            <path
              d="M28.9869 14.9935C28.8137 14.9986 28.6493 15.071 28.5285 15.1953L18.3332 25.3906L14.1379 21.1953C14.0765 21.1313 14.0029 21.0802 13.9215 21.045C13.8401 21.0098 13.7524 20.9912 13.6638 20.9903C13.5751 20.9894 13.4871 21.0062 13.405 21.0398C13.3228 21.0733 13.2482 21.1229 13.1855 21.1856C13.1228 21.2483 13.0732 21.3229 13.0397 21.405C13.0062 21.4871 12.9894 21.5751 12.9903 21.6638C12.9912 21.7525 13.0098 21.8401 13.045 21.9216C13.0801 22.003 13.1312 22.0766 13.1952 22.138L17.8619 26.8047C17.9869 26.9296 18.1564 26.9998 18.3332 26.9998C18.51 26.9998 18.6796 26.9296 18.8046 26.8047L29.4712 16.138C29.5676 16.0443 29.6334 15.9238 29.66 15.7921C29.6867 15.6604 29.6729 15.5238 29.6205 15.4C29.5682 15.2763 29.4797 15.1713 29.3666 15.0987C29.2535 15.0262 29.1212 14.9895 28.9869 14.9935Z"
              fill="#219653"
            />
          </svg>
        </div>
        <h1 className="mb-5">Onboarding Completed!</h1>
        <span
          style={{ maxWidth: 720, textAlign: 'center' }}
          className="text-secondary"
        >
          Thank you for completing your group application. Your implementation
          team is reviewing your application and will reach out to you shortly.
          Over the next 30 days, keep an eye out on your inbox for the contract
          and final policy sent via Docusign requiring your signature. If you
          have any questions please reach out to{' '}
        </span>
        <span className="d-inline-block text-primary">
          onboarding@anglehealth.com
        </span>
      </section>
    </PageLayout>
  )
}

export default Complete
