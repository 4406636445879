import React from 'react'

import { View, Text, Image } from '@react-pdf/renderer'
import styles from './style'

import angleA from '../../../assets/angle_A.png'

interface ITopBanner {
  title: string
}

const TopBanner = ({ title }: ITopBanner) => {
  return (
    <View style={styles.pageHeaderContainer}>
      <Image style={styles.pageHeaderImage} src={angleA} />
      <Text style={styles.pageHeaderText}>{title}</Text>
    </View>
  )
}

export default TopBanner
