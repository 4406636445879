import React from 'react'
import { Col } from '../../index'
import { OutOfPocketCardPlus } from './OutOfPocketCardPlus'
import { ZeroDeductibleCard } from './ZeroDeductibleCard'
import { getAccumsCardProps } from './getAccumsCardProps'

interface AccumsCardsProps {
  showcard: string
  accumsData: any
  memberId: string
  inNetwork?: boolean
}

const LeftCardStyle = { borderRadius: '5px 0px 0px 5px', minWidth: 330 }
const RightCardStyle = { borderRadius: '0px 5px 5px 0px', minWidth: 330 }

export const AccumsCards: React.FC<AccumsCardsProps> = ({
  showcard,
  accumsData,
  memberId,
  inNetwork = true
}) => {
  let oopFamily = getAccumsCardProps(
    inNetwork ? accumsData?.familyOOP : accumsData?.familyOOPOON,
    inNetwork ? 'Out-of-Pocket Family' : 'Out-of-Network Out-of-Pocket Family'
  )
  let deductibleFamily = getAccumsCardProps(
    inNetwork ? accumsData?.familyDeductible : accumsData?.familyDeductibleOON,
    inNetwork ? 'Deductible Family' : 'Out-of-Network Deductible Family'
  )

  const indData = accumsData?.members?.find(
    (member: any) => member.memberId === showcard
  )

  let oopInd = getAccumsCardProps(
    inNetwork ? indData?.oop : indData?.oopoon,
    inNetwork
      ? 'Out-of-Pocket Individual'
      : 'Out-of-Network Out-of-Pocket Individual'
  )
  let deductibleInd = getAccumsCardProps(
    inNetwork ? indData?.deductible : indData?.deductibleoon,
    inNetwork ? 'Deductible Individual' : 'Out-of-Network Deductible Individual'
  )
  const isIndDeductibleZero = deductibleInd?.maxValue === 0
  const isFamilyDeductibleZero = deductibleFamily?.maxValue === 0

  if (showcard !== 'family') {
    return (
      <>
        <Col xl={6} className="pb-xl-3 py-3">
          {isIndDeductibleZero ? (
            <ZeroDeductibleCard style={LeftCardStyle} />
          ) : (
            <OutOfPocketCardPlus
              {...deductibleInd}
              // className="w-100"
              id_member={showcard}
              style={LeftCardStyle}
            />
          )}
        </Col>
        <Col xl={6} className="pb-xl-3 py-3">
          <OutOfPocketCardPlus
            {...oopInd}
            // className="w-100"
            id_member={showcard}
            style={RightCardStyle}
          />
        </Col>
      </>
    )
  } else {
    return (
      <>
        <Col xl={6} className="pb-xl-3 py-3">
          {isFamilyDeductibleZero ? (
            <ZeroDeductibleCard style={LeftCardStyle} />
          ) : (
            <OutOfPocketCardPlus
              {...deductibleFamily}
              // className="w-100"
              id_member={showcard}
              style={LeftCardStyle}
            />
          )}
        </Col>
        <Col xl={6} className="pb-xl-3 py-3">
          <OutOfPocketCardPlus
            {...oopFamily}
            // className="w-100"
            id_member={showcard}
            style={RightCardStyle}
          />
        </Col>
      </>
    )
  }
}
