import React from 'react'
import { LocationInner, LocationOuter } from './styles'

export interface ILocationMarkerProps {
  lat: number
  lng: number
}

export const LocationMarker: React.FC<ILocationMarkerProps> = () => {
  return (
    <LocationOuter>
      <LocationInner />
    </LocationOuter>
  )
}

export default LocationMarker
