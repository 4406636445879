import { Row } from 'react-bootstrap'
import {
  IPlanBenefitTableDTO,
  IPlanBenefitTableServiceDTO
} from '../../interfaces'
import { removeLinks, sanitizerWithLinks } from '@angle/utils'
import { BOX_HEIGHT, CustomCol } from './styles'

export const BenefitsTable: React.FC<{ benefits: IPlanBenefitTableDTO[] }> = ({
  benefits
}) => {
  return (
    <div>
      <Row className="w-100">
        <CustomCol xs="3" className="bg-light">
          <b>Common Medical Event</b>
        </CustomCol>

        <CustomCol xs="3">
          <b>Services You May Need</b>
        </CustomCol>

        <CustomCol
          container
          style={{
            flexDirection: 'column',
            height: BOX_HEIGHT,
            overflow: 'hidden'
          }}
          xs="3"
        >
          <Row className="text-center mx-0">
            <CustomCol heightAuto>
              <p
                className="fs-14 text-uppercase m-0 py-2"
                style={{
                  fontWeight: 500,
                  flex: 1
                }}
              >
                What You Will Pay
              </p>
            </CustomCol>
          </Row>

          <Row className="mx-0">
            <CustomCol
              heightAuto
              className="pt-2 pb-1"
              style={{ flexDirection: 'column' }}
            >
              <b className="align-self-auto">Network Provider </b>
              <p className="align-self-auto">(You will pay the least)</p>
            </CustomCol>
            <CustomCol
              heightAuto
              className="pt-2 pb-1"
              style={{ flexDirection: 'column' }}
            >
              <b className="align-self-auto">Out-of-Network Provider </b>
              <p className="align-self-auto">(You will pay the most)</p>
            </CustomCol>
          </Row>
        </CustomCol>

        <CustomCol xs="3">
          <b>Limitations, Exceptions, & Other Important Information</b>
        </CustomCol>
      </Row>

      {benefits.map((b, k) => {
        const { Services, InnCost, OonCost, OtherInformation } =
          mapToComponents(b.services)

        const otherInfoLength = OtherInformation.length

        return (
          <Row className="w-100">
            <CustomCol className="bg-light" heightAuto xs="3">
              <b
                dangerouslySetInnerHTML={{
                  __html: sanitizerWithLinks(b.event)
                }}
              ></b>
            </CustomCol>

            <CustomCol xs="3" container>
              <Row className="px-0 mx-0 w-100">{Services}</Row>
            </CustomCol>

            <CustomCol container>
              <Row style={{ height: '100%' }} className="mx-0">
                <CustomCol container>
                  <Row className="px-0 mx-0">{InnCost}</Row>
                </CustomCol>

                <CustomCol container>
                  <Row className="px-0 mx-0">{OonCost}</Row>
                </CustomCol>
              </Row>
            </CustomCol>

            <CustomCol
              xs="3"
              container={otherInfoLength > 1}
              heightAuto={otherInfoLength == 1}
            >
              {otherInfoLength > 1 ? (
                <Row className="px-0 mx-0 w-100">{OtherInformation}</Row>
              ) : (
                OtherInformation
              )}
            </CustomCol>
          </Row>
        )
      })}
    </div>
  )
}

function mapToComponents(services: IPlanBenefitTableServiceDTO[]) {
  const loader: {
    Services: React.ReactNode[]
    InnCost: React.ReactNode[]
    OonCost: React.ReactNode[]
    OtherInformation: React.ReactNode[]
  } = {
    Services: [],
    InnCost: [],
    OonCost: [],
    OtherInformation: []
  }

  for (const service of services) {
    loader.Services.push(
      <CustomCol xs="12">
        <p
          dangerouslySetInnerHTML={{
            __html: sanitizerWithLinks(service.service)
          }}
        ></p>
      </CustomCol>
    )

    loader.InnCost.push(
      <CustomCol xs="12">
        <p
          dangerouslySetInnerHTML={{
            __html: sanitizerWithLinks(service.inn_cost)
          }}
        ></p>
      </CustomCol>
    )

    loader.OonCost.push(
      <CustomCol xs="12">
        <p
          dangerouslySetInnerHTML={{
            __html: sanitizerWithLinks(service.oon_cost)
          }}
        ></p>
      </CustomCol>
    )

    const { other_information } = service

    if (other_information?.data) {
      const otherInfo =
        services.length === other_information?.rowspan ? (
          <p
            dangerouslySetInnerHTML={{
              __html: sanitizerWithLinks(other_information.data)
            }}
          ></p>
        ) : (
          <CustomCol
            style={{
              height: other_information.rowspan * 115,
              overflowY:
                removeLinks(other_information.data).length /
                  other_information.rowspan >
                200
                  ? 'scroll'
                  : 'auto'
            }}
            xs="12"
          >
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizerWithLinks(other_information.data)
              }}
            ></p>
          </CustomCol>
        )

      loader.OtherInformation.push(otherInfo)
    }
  }

  return loader
}

export default BenefitsTable
