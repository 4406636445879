import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  padding: 1rem 2rem;
  flex-direction: row;
  min-height: 162px;
  :hover {
    background-color: var(--input-bg);
  }
`

export const Main = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 70%;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  > h3 {
    line-height: 24px;
    margin-bottom: 0;
  }

  > span.badge-success,
  > span.silver {
    font-size: 16px;
    font-weight: normal;
  }

  > span.badge-success {
    color: #fff;
  }

  > span.silver {
    background-color: var(--gray-600);
    color: var(--silver);
    font-weight: 500;
  }
`

export const Content = styled.div`
  padding-top: 0.5rem;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    line-height: 24px;
  }
`
