import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { ProvidersSection } from './ProvidersSection'
import { LegalText } from './LegalText'
import OutOfAreaTravelNetwork from './OutOfAreaTravelNetwork'

export const BackMainSection: React.FC<{
  data: any
}> = ({ data }) => {
  const idCardType = data.id_card_type
  const isCigna = idCardType === 'sns' || idCardType === 'centivo'
  const isMPCN = idCardType === 'mpcn'

  return (
    <div
      style={{
        height: '100%'
      }}
      className="px-4 pt-2 pb-3 rounded-bottom border-bottom border-right border-left d-flex flex-column justify-content-between"
    >
      <div>
        <ProvidersSection
          idCardType={idCardType}
          formularyIndicator={data?.formulary_indicator}
        />
        <LegalText isCigna={isCigna} isMPCN={isMPCN} />
      </div>
      {isMPCN && <OutOfAreaTravelNetwork />}
      <div>
        {isCigna && (
          <div className="d-flex justify-content-between align-items-center">
            <Row>
              <Col>
                <div
                  className="text-nowrap fs-10 text-white bg-dark px-1 mt-1"
                  style={{ fontFamily: 'Sentinel' }}
                >
                  {' '}
                  AWAY FROM HOME CARE
                </div>
              </Col>
            </Row>
            {idCardType === 'centivo' && (
              <Row className="mt-1">
                <Col>
                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <p className="mb-0 fs-10 mr-1">Administered by</p>
                    <img
                      src="/centivo.png"
                      style={{ height: 10 }}
                      alt="Centivo"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
