import React, { useState, CSSProperties } from 'react'
import { useCSVReader, formatFileSize } from 'react-papaparse'

const styles = {
  zone: {
    border: '2px dashed var(--primary)',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: 10,
    cursor: 'pointer'
  } as CSSProperties,
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'fit-content',
    minWidth: 100,
    minHeight: 100,
    wordBreak: 'break-word',
    position: 'relative'
  } as CSSProperties,
  info: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 10px'
  } as CSSProperties,
  size: {
    marginBottom: '0.4em'
  } as CSSProperties,
  name: {
    fontSize: 12,
    marginBottom: '0.4em'
  } as CSSProperties,
  progressBar: {
    width: '100%',
    padding: '0 10px'
  } as CSSProperties,
  remove: {
    position: 'absolute',
    right: 4,
    top: 1
  } as CSSProperties
}

type ValidationRule = {
  rule: (x: any) => boolean
  message: string
}

interface CSVReaderV4Props {
  label: string
  onChange: (data: any[]) => void
  onError: (errors: string[]) => void
  validationRules?: ValidationRule[]
}

export const CSVReaderV4: React.FC<CSVReaderV4Props> = ({
  label,
  onChange,
  onError,
  validationRules
}) => {
  const { CSVReader } = useCSVReader()

  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const onUploadAccepted = (results: {
    data: any[]
    errors: any[]
    meta: any[]
  }) => {
    if (validationRules?.length) {
      const errors: string[] = []

      results.data.forEach((row) => {
        validationRules.forEach((validationRule) => {
          if (
            !validationRule.rule(row) &&
            !errors.includes(validationRule.message)
          ) {
            errors.push(validationRule.message)
          }
        })
      })

      if (errors.length) {
        setValidationErrors(errors)
        return
      }
    }

    setValidationErrors([])
    onChange(results.data)
  }

  const onUploadRejected = (
    results: { file: File; errors: { code: string; message: string }[] }[]
  ) => {
    const errorMessages: string[] = []
    results.forEach((x) =>
      x.errors.forEach((error) => {
        errorMessages.push(error.message)
      })
    )

    onError(errorMessages)
  }

  const onClearFile = () => {
    setValidationErrors([])
    onChange([])
  }

  return (
    <CSVReader
      config={{ header: true, skipEmptyLines: 'greedy' }}
      onUploadAccepted={onUploadAccepted}
      onUploadRejected={onUploadRejected}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove
      }: any) => (
        <>
          <div {...getRootProps()} style={styles.zone}>
            {acceptedFile ? (
              <>
                <div style={styles.file}>
                  <div style={styles.info}>
                    <span style={styles.size}>
                      {formatFileSize(acceptedFile.size)}
                    </span>
                    <span style={styles.name}>{acceptedFile.name}</span>
                  </div>
                  <div style={styles.progressBar}>
                    <ProgressBar
                      style={{ backgroundColor: 'var(--primary)' }}
                    />
                  </div>
                  <div {...getRemoveFileProps()} style={styles.remove}>
                    {/* This div allows us to perform extra onClick logic on the "Remove" component without affecting the parent's onClick logic that the "getRemoveFileProps" function gave it */}
                    <div onClick={onClearFile}>
                      <Remove width={20} height={20} color="var(--primary)" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <span
                className="text-primary noselect"
                style={{ fontSize: '1.2rem' }}
              >
                {label}
              </span>
            )}
          </div>
          {validationErrors.map((error) => (
            <p key={error} className="mt-2 mb-0 text-danger">
              {error}
            </p>
          ))}
        </>
      )}
    </CSVReader>
  )
}
