import { ILoginFormData } from '@angle/types'
import { useEffect } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

const defaultKeys = {
  storage: 'saved_email'
}

export const useRememberAccount = (
  setValue: UseFormSetValue<ILoginFormData>,
  watch: UseFormWatch<ILoginFormData>,
  { storage } = defaultKeys
) => {
  const rememberValue = watch('remember')
  const currentEmail = watch('email')

  useEffect(() => {
    const savedEmail = localStorage.getItem(storage)
    if (savedEmail) {
      setValue('email' as any, savedEmail)
      setValue('remember' as any, true)
    }
  }, [setValue, storage])

  useEffect(() => {
    if (rememberValue) localStorage.setItem(storage, currentEmail)
    if (!rememberValue) localStorage.removeItem(storage)
  }, [rememberValue, currentEmail, storage])
}
