import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Col,
  DocumentService,
  PageContainer,
  PageHeaderLight,
  Row,
  SectionTitle,
  Tab,
  Tabs,
} from '../../../index'
import Statements from './Statements'
import { BillingSettings } from './BillingSettings'
import { Faq } from './Faq'

export const EmployerPortalBilling: React.FC<{
  billingSettingsPath?: string
  isBrokerPortal: boolean
  groupService: any
  billingService: any
  documentService: DocumentService
}> = ({ billingSettingsPath, isBrokerPortal, groupService, billingService, documentService }) => {
  const history = useHistory()
  
  return (
    <>
      <PageHeaderLight>
        <PageContainer className="mt-4 mb-2 pb-2" size="lg">
          <Row>
            <Col md={8}>
              <h1>Billing</h1>
            </Col>
            <Col>
              { billingSettingsPath && (
                <Button
                  className={'billing-settings-btn'}
                  variant="primary"
                  ghost={true}
                  onClick={() =>
                    history.push(billingSettingsPath)}
                >
                  <i
                    className={`icon icon-settings text-primary`}
                    style={{ height: '30px' }}
                  />
                  Billing Settings
                </Button>
              ) }
            </Col>
          </Row>
        </PageContainer>
      </PageHeaderLight>
      <PageContainer size="lg">
        <BillingSettings
          isBrokerPortal={isBrokerPortal}
          groupService={groupService}
          billingService={billingService}
          />
        <Row>
          <Col xs={12} md={8}>
            <SectionTitle>Billing History</SectionTitle>
            <Tabs defaultActiveKey="statements" id="table-section-tabs">
              <Tab eventKey="statements" title="Statements">
                <Statements
                  isBrokerPortal={isBrokerPortal}
                  documentService={documentService}
                  billingService={billingService}
                />
              </Tab>
            </Tabs>
          </Col>
          <Col>
            <Faq/>
          </Col>
        </Row>
      </PageContainer>
    </>
  )
}
