import { businessOnboardingPaths } from '../router/paths'

// onboarding step configs

export const getSharedSteps = (currentStep: number) => {
  const sharedSteps = [
    {
      title: 'Business Information',
      link: businessOnboardingPaths.businessInformation,
      completed: currentStep > 1
    },
    {
      title: 'Admin Information',
      link: businessOnboardingPaths.adminInformation,
      completed: currentStep > 2
    },
    {
      title: 'Employer Preferences',
      link: businessOnboardingPaths.employerPreferences,
      completed: currentStep > 3
    }
  ]
  return sharedSteps
}

export const getNonFrontingSteps = (
  currentStep: number,
  inRenewal: boolean
) => {
  const nonFrontingSteps = [
    {
      title: 'Employer Contribution Strategy',
      link: businessOnboardingPaths.contributionStrategy,
      completed: currentStep > 4
    },
    {
      title: 'Plan Selection',
      link: businessOnboardingPaths.plan,
      completed: currentStep > 5
    },
    {
      title: 'Carrier Information',
      link: businessOnboardingPaths.carrierInformation,
      completed: currentStep > 6
    },
    {
      title: 'Billing/Payment',
      link: businessOnboardingPaths.billing,
      completed: currentStep > (inRenewal ? 6 : 7)
    },
    {
      title: 'Producer/Agency Information',
      link: businessOnboardingPaths.producer,
      completed: currentStep > (inRenewal ? 7 : 8)
    }
  ]

  return nonFrontingSteps
}

export const getFrontingSteps = (
  currentStep: number,
  inRenewal: boolean
) => {
  // some step numbers differ based on insurer type and renewal status

  const frontingSteps = [
    {
      title: 'Disclosure Form',
      link: businessOnboardingPaths.disclosure,
      completed: currentStep > 4
    },
    {
      title: 'Employer Contribution Strategy',
      link: businessOnboardingPaths.contributionStrategy,
      completed: currentStep > 5
    },
    {
      title: 'Plan Selection',
      link: businessOnboardingPaths.plan,
      completed: currentStep > 6
    },
    {
      title: 'Carrier Information',
      link: businessOnboardingPaths.carrierInformation,
      completed: currentStep > 7
    },
    {
      title: 'Billing/Payment',
      link: businessOnboardingPaths.billing,
      completed: currentStep > (inRenewal ? 7 : 8)
    },
    {
      title: 'Producer/Agency Information',
      link: businessOnboardingPaths.producer,
      completed: currentStep > (inRenewal ? 8 : 9)
    }
  ]

  return frontingSteps
}
