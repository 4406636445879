import React from 'react'
import { Avatar } from '../Avatar'

import { Wrapper, Main, Title, Content, Footer } from './style'

export interface IDirectoryItemCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle?: string
  image?: string
  children?: React.ReactNode
  badge?: React.ReactNode
  distance?: string
  circledImage?: boolean
  [x: string]: any
}

export const ItemCard: React.FC<IDirectoryItemCardProps> = ({
  title,
  subtitle,
  image,
  children,
  badge,
  distance,
  circledImage = false,
  ...p
}) => {
  const wrapperStyles = p.onClick
    ? { ...p.style, cursor: 'pointer' }
    : { ...p.style }
  return (
    <Wrapper {...p} style={wrapperStyles}>
      {circledImage ? (
        <Avatar
          avatarImagePath={image}
          fallbackContent={<i className="icon icon-user text-primary" />}
          circle
          style={{ marginTop: '1rem' }}
          size="lg"
          variant="primary-light"
        />
      ) : (
        <Avatar
          data-testid="directory-item-image"
          avatarImagePath={image}
          fallbackContent={
            <i
              className="icon icon-facility fs-48 text-primary"
              style={{ height: 48, width: 48 }}
            />
          }
          style={{
            width: '30%',
            height: '184px'
          }}
          variant="primary-light"
        />
      )}
      <Main>
        <Title>
          <h3 className="fs-20">{title}</h3>
          {badge}
        </Title>
        {subtitle && (
          <small className="text-muted fs-16 d-block">{subtitle}</small>
        )}
        <Content>{children}</Content>
        <Footer>
          {distance && <span className="text-muted fs-16">{distance}</span>}
        </Footer>
      </Main>
    </Wrapper>
  )
}

export const DirectoryItemCard = React.memo(ItemCard)
export default DirectoryItemCard
