import styled from 'styled-components'

export const OfficeDetail = styled.div`
  margin-bottom: 0.85rem;
  > p {
    margin-bottom: 0;
  }
`

export const OfficeDetailTitle = styled.h4`
  margin-bottom: 1rem;

  @media screen and (max-width: 577px) {
    margin-top: 15px;
  }
`
