export interface Facility {
  facility_name: string
  facility_id: number
}

export interface Provider {
  provider_name: string
  provider_id: number
}

// Allergies
export interface IAllergyDTO {
  allergy_id: number
  allergy_name: string
  updateDate?: string
}

export interface IAllergy {
  name: string
  updateDate?: string
  id: number
}

// Labs
export interface ILabDTO {
  date: string
  provider: Provider
  labName: string
  labAddress: string
  labResultId: number
}

export interface ILabResult {
  labName: string
  labAddress: string
  provider: {
    name: string
    profileLink: string
  }
  date: string
  id: string | number
}

// Medications
export interface IMedicationDTO {
  medication_id: number
  drug: string
  provider: Provider
  lastFilledDate: string
  refillLeft: number
  dosage?: number
  status?: string
}

export interface IMedication {
  id: string | number
  drug: string
  provider: {
    name: string
    profileLink: string
  }
  lastFilledDate: string
  refillLeft: number
  dosage?: number
  status?: string
}

// Patient authorizations
export interface IPatientAuthorizationDTO {
  authorization_id: number
  date: string
  provider: Provider
  facility: Facility
  requestType: string
  status: keyof typeof BadgeStatusVariants
  decisionLetterURL: string
}

export interface IPatientAuthorization {
  date: string
  provider: { name: string; profileLink: string }
  facility: { name: string; profileLink: string }
  requestType: string
  status: string
  decisionLetterLink: string
  id: number | string
}

// Patient claims
export interface IPatientClaimDTO {
  provider_name: string
  facility_name: string
  claim_id: string
  date: string
  status: keyof typeof ClaimStatusVariant
  cost: number
}

export interface IPatientClaim {
  provider: string
  facility: string
  id: number | string
  date: string
  status: keyof typeof ClaimStatusVariant
  cost: number
}

// Plan
export interface IPlanInfo {
  memberOOPMax: number
  memberOOPUsed: number
  familyOOPMax: number
  familyOOPUsed: number
}

export interface Link {
  id: number
  name: string
}

export interface SubCategory {
  id: number
  name: string
  links: Link[]
}

export interface MedicalBenefit {
  id: number
  name: string
  subCategories: SubCategory[]
}

export interface IPatientPlanDTO {
  plan_info: IPlanInfo
  medical_benefits: MedicalBenefit[]
}

// Shared
export enum BadgeStatusVariants {
  Approved = 'success',
  Denied = 'danger',
  Pending = 'warning'
}

export const ClaimStatusVariant = {
  paid: 'success',
  pending: 'warning',
  denied: 'danger'
}
