import React, { ReactNode } from 'react'

export interface InfoWithIconProps {
  iconEl?: ReactNode
  title?: string
  [x: string]: any
}
export const InfoWithIcon: React.FC<InfoWithIconProps> = ({
  children,
  iconEl = null,
  title = '',
  ...p
}) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100"
      {...p}
    >
      {iconEl}
      <h5 className="text-center">{title}</h5>
      <p className="text-muted text-center">{children}</p>
    </div>
  )
}
