export const Paths = Object.freeze({
  main: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  settingsBilling: '/settings/billing',
  summary: '/summary',
  newEmployeeRegistration: '/employees/registration',
  newEmployeeOnboarding: '/employees/onboarding',
  newBusinessRegistration: '/business/registration',
  temporaryLogin: '/temporary-login',
  newBusinessOnboarding: '/business/onboarding',
  newBusinessOnboardingComplete: '/onboarding-complete',
  employeeProfileIdCard: '/employees/:id/id-card',
  employeeProfile: '/employees/:id',
  editEmployeeProfile: '/employees/:id/edit',
  disenrollEmployee: '/employees/:id/disenroll',
  newDependentRegistration: '/employees/:id/dependents/registration',
  employees: '/employees',
  dependentProfile: '/employees/:id/dependents/:dependentId',
  dependentProfileIdCard: '/employees/:id/dependents/:dependentId/id-card',
  editDependentProfile: '/employees/:id/dependents/:dependentId/edit',
  billing: '/billing',
  settings: '/settings',
  accountSelection: '/settings/billing/accounts',
  makePayment: '/settings/billing/pay',
  addManualAccount: '/settings/billing/add-account',
  documentsAndForms: '/documents',
  brokerForm: '/producer-form',
  superuser: '/superuser',
  claims: '/claims',
  notFound: '/not-found',
  reporting: '/reporting'
})
