export const WarningIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.271973C3.58962 0.271973 0 3.86159 0 8.27197C0 12.6824 3.58962 16.272 8 16.272C12.4104 16.272 16 12.6824 16 8.27197C16 3.86159 12.4104 0.271973 8 0.271973ZM8 1.60531C11.6898 1.60531 14.6667 4.58218 14.6667 8.27197C14.6667 11.9618 11.6898 14.9386 8 14.9386C4.31021 14.9386 1.33333 11.9618 1.33333 8.27197C1.33333 4.58218 4.31021 1.60531 8 1.60531ZM7.19141 4.27197L7.32552 9.60531H8.67448L8.80859 4.27197H7.19141ZM8.0026 10.8123C7.44994 10.8123 7.11979 11.1072 7.11979 11.6105C7.11979 12.1045 7.4506 12.3983 8.0026 12.3983C8.5506 12.3983 8.87891 12.1045 8.87891 11.6105C8.87891 11.1072 8.5506 10.8123 8.0026 10.8123Z"
      fill="#EDCA60"
    />
  </svg>
)

export default WarningIcon
