import React from 'react'
import { ListGroup as ListGroupRB, Row, Col } from 'react-bootstrap'
import { ListItem, IListItem } from './ListItem'

export interface IListGroup {
  items?: IListItem[]
  [x: string]: any
}

export const ListGroup: React.FC<IListGroup> = ({
  items,
  children,
  ...others
}) => {
  return (
    <ListGroupRB as="ul" {...others}>
      {items ? items.map((e, i) => <ListItem key={i} {...e} />) : children}
    </ListGroupRB>
  )
}

export default ListGroup
