import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import {
  Button,
  Col,
  Row,
  Modal,
  Form,
  EmployeeProfile,
  EightThreeFourService,
  useToaster
} from '../../../index'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import {
  EightThreeFourRequest,
  EightThreeFourRequestMemberObject,
  FamilyIndicator
} from '../../../interfaces/employer-broker-shared/eightthreefour'
import styled from 'styled-components'
import moment from 'moment'
import DatePicker from 'react-datepicker'

const InvoiceText = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #6c757d;
`
const genderTranslatorFor843 = (gender: string): string => {
  if (gender.toLowerCase() === 'm' || gender.toLowerCase() === 'f') {
    return gender.toUpperCase()
  }

  if (gender.toLowerCase() === 'male') {
    return 'M'
  }

  if (gender.toLowerCase() === 'female') {
    return 'F'
  }

  return 'U'
}

export interface TerminationForm {
  firstName: string
  lastName: string
  terminationDate: Date
}

const TerminationFormSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  terminationDate: z.date()
})

export const doesEmployeeHaveChildDependents = (
  employee: EmployeeProfile
): boolean =>
  employee.dependents.some((dependent) => dependent.relationship === 'child')

export const doesEmployeeHaveSpouseDependent = (
  employee: EmployeeProfile
): boolean =>
  employee.dependents.some((dependent) => dependent.relationship === 'spouse')

export const computeNumChildDependents = (
  employee: EmployeeProfile
): number => {
  let numChildren = 0
  for (let x = 0; x < employee.dependents.length; x++) {
    if (
      employee.dependents[x].relationship in
      ['child', 'grandchild', 'adopted_child', 'foster_child', 'stepchild']
    ) {
      numChildren += 1
    }
  }
  return numChildren
}

export const computeFamilyIndicator = (
  employee: EmployeeProfile
): FamilyIndicator => {
  if (employee.dependents.length === 0) {
    return 'employee'
  }
  if (
    doesEmployeeHaveChildDependents(employee) &&
    doesEmployeeHaveSpouseDependent(employee)
  ) {
    return 'family'
  } else if (
    doesEmployeeHaveChildDependents(employee) &&
    !doesEmployeeHaveSpouseDependent(employee)
  ) {
    if (computeNumChildDependents(employee) > 1) {
      return 'employee_children'
    }
    return 'employee_child'
  }
  return 'employee_spouse'
}

export const EmployerPortalTerminateEmployee: React.FC<{
  isTerminationModalVisible: boolean
  setTerminationModalVisible: (boolean) => void
  employee: EmployeeProfile | undefined
  eightThreeFourService: EightThreeFourService
  refetchEmployee: () => void
}> = ({
  isTerminationModalVisible,
  setTerminationModalVisible,
  employee,
  eightThreeFourService,
  refetchEmployee
}) => {
  const [hasBeenTerminated, setHasBeenTerminated] = useState(false)
  const [newTerminationDate, setNewTerminationDate] = useState<Date>()
  const { showSuccess, showError } = useToaster()

  const terminateMember = useMutation({
    mutationFn: (data: any) =>  eightThreeFourService.postMemberInformation(data),
    onSuccess: (response, data) => {
      if (response.success && response.data.total_members === response.data.total_success) {
        showSuccess('Member was successfully terminated')
        setHasBeenTerminated(true)
        setNewTerminationDate(data.terminationDate)
        reset()
        refetchEmployee()
      } else if (response.link && response.success === false) {
        showError(`Error terminating Member - ${response.message}`)
        setTimeout(() => window.open(response.link, '_blank'), 500)
      } else {
        showError(`Error Terminating Member`)
      }
    },
    onError: (error) => {
      showError(`Error Terminating Member - ${error}`)
    }
  })

  const defaultValues: TerminationForm = {
    firstName: employee?.name.split(' ')[0] || '',
    lastName: employee?.name.split(' ')[1] || '',
    terminationDate: new Date()
  }

  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues,
    resolver: zodResolver(TerminationFormSchema)
  })

  const sendTerminationRequest = async (data) => {
    if (!employee) {
      return
    }
    if (data.terminationDate < new Date(employee.coverageStart)) {
      showError('Termination Date needs to be after the coverage start date')
      return
    }
    const terminationDate = moment(data.terminationDate).format('YYYY-MM-DD')
    const params: EightThreeFourRequest = {
      group_id: employee?.group_id,
      members: [
        {
          first_name: data.firstName,
          last_name: data.lastName,
          relationship: 'employee',
          family_indicator: computeFamilyIndicator(employee),
          group_id: employee?.group_id,
          action_requested: 'terminate',
          angle_member_id: employee?.id,
          insured_ssn: employee?.ssn,
          ssn: employee?.ssn,
          gender: genderTranslatorFor843(employee?.gender),
          date_of_birth: employee?.dob,
          coverage_start_date: employee?.coverageStart,
          coverage_end_date: terminationDate,
          plan_name: employee?.plan,
          subgroup: employee?.subgroup,
          street_1: employee.address_object.street_1,
          street_2: employee.address_object.street_2,
          state: employee.address_object.state,
          city: employee.address_object.city,
          zip: employee.address_object.zip
        }
      ]
    }
    for (let x = 0; x < employee.dependents.length; x++) {
      let dependentMemberObject: EightThreeFourRequestMemberObject = {
        group_id: employee?.group_id,
        first_name: employee?.dependents[x].name.first_name,
        last_name: employee?.dependents[x].name.last_name,
        relationship: employee?.dependents[x].relationship,
        family_indicator: computeFamilyIndicator(employee),
        action_requested: 'terminate',
        parent_member_id: employee?.id,
        angle_member_id: employee?.dependents[x].member_id,
        ssn: employee?.dependents[x].ssn,
        gender: genderTranslatorFor843(employee?.dependents[x]?.gender),
        insured_ssn: employee?.ssn,
        coverage_start_date: employee?.coverageStart,
        coverage_end_date: terminationDate,
        plan_name: employee?.plan,
        subgroup: employee?.subgroup,
        street_1: employee.address_object.street_1,
        street_2: employee.address_object.street_2,
        state: employee.address_object.state,
        city: employee.address_object.city,
        zip: employee.address_object.zip
      }
      params.members.push(dependentMemberObject)
    }
    await terminateMember.mutateAsync(params)
  }

  const onSubmit: SubmitHandler<TerminationForm> = (data) => {
    sendTerminationRequest(data)
  }

  const isLastDayOfMonth = (date) => {
    const day = date.getDate()
    const lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate()
    return day === lastDay
  }

  return (
    <Modal
      show={isTerminationModalVisible}
      centered
      size="lg"
      onHide={() => setTerminationModalVisible(false)}
      dialogClassName="groupFormModalDialog"
      className="groupFormModal"
      style={{
        zIndex: 2000
      }}
    >
      <Modal.Header className="border-bottom pb-3 pt-4" closeButton>
        <Modal.Title>Terminate Employee</Modal.Title>
      </Modal.Header>
      {hasBeenTerminated ? (
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center pb-2 flex-column">
            <h5 className="pb-1">
              The health coverage for {employee?.name.split(' ')[0]}{' '}
              {employee?.name.split(' ')[1]} and their dependents will end on{' '}
              {moment(newTerminationDate).format('YYYY-MM-DD')}
            </h5>
            <InvoiceText>
              This change will be reflected in your next invoice.
            </InvoiceText>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body className="pb-2">
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder="First Name"
                  {...register('firstName')}
                  className="bg-gray"
                  readOnly
                  isInvalid={!!errors?.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.firstName?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  placeholder="Last Name"
                  className="bg-gray"
                  readOnly
                  isInvalid={!!errors?.firstName}
                  {...register('lastName')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.lastName?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Termination Date</Form.Label>
                <Controller
                  key="terminationDate"
                  name="terminationDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      filterDate={isLastDayOfMonth}
                      dropdownMode="select"
                      placeholderText="Enter a termination date"
                      onChange={onChange}
                      selected={value}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.terminationDate?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
      )}
      {!hasBeenTerminated && (
        <Modal.Footer className="border-top pb-3 pt-3">
          <Button
            disabled={isSubmitting || terminateMember.isLoading}
            className="w-25"
            style={{ backgroundColor: '#DC3545', borderColor: '#DC3545' }}
            onClick={handleSubmit(onSubmit)}
          >
            Terminate Employee
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}
