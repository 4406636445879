import { Context, useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form'

export interface ILoginFormData {
  email: string
  password: string
  remember: boolean
}

interface ILoginHandlers {
  onSuccess: (props: { context: any; result: any }) => any
  onError: (error: Error) => any
}

export function useLoginFormHandler(
  onHandlers: ILoginHandlers,
  authContext: Context<any>,
  defaultValues: ILoginFormData = {
    email: '',
    password: '',
    remember: false
  }
) {
  const { onError, onSuccess } = useMemo(() => onHandlers, [onHandlers])
  const context = useContext(authContext)
  const {
    handleSubmit,
    control,
    formState,
    getValues,
    register,
    watch,
    setValue,
    ...others
  } = useForm<ILoginFormData>({
    mode: 'onChange',
    defaultValues
  })

  const onSubmit = handleSubmit(async (data: ILoginFormData) => {
    // Call login endpoint
    const { email, password } = data
    try {
      const result = await context.login(email, password)
      onSuccess({ result, context })
    } catch (e) {

      if (e instanceof Error) {
        onError(e)
      }
    }
  })

  const isValid = Boolean(!formState.isDirty || !formState.isValid)

  return {
    register,
    onSubmit,
    isValid,
    control,
    formState,
    getValues,
    watch,
    setValue,
    ...others
  }
}

export default useLoginFormHandler
