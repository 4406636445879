import React from 'react'

export const Icon = () => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66667 0C1.196 0 0 1.196 0 2.66667V29.3333C0 30.804 1.196 32 2.66667 32H21.3333C22.804 32 24 30.804 24 29.3333V17.3333H13.3333C12.5973 17.3333 12 16.736 12 16C12 15.264 12.5973 14.6667 13.3333 14.6667H24V2.66667C24 1.196 22.804 0 21.3333 0H2.66667ZM24 14.6667V17.3333H31.4479L28.3906 20.3906C28.2627 20.5135 28.1605 20.6606 28.0901 20.8235C28.0197 20.9863 27.9825 21.1616 27.9807 21.339C27.9789 21.5163 28.0125 21.6923 28.0796 21.8565C28.1467 22.0208 28.2458 22.17 28.3712 22.2954C28.4967 22.4209 28.6459 22.52 28.8101 22.5871C28.9744 22.6541 29.1503 22.6877 29.3277 22.6859C29.5051 22.6841 29.6803 22.6469 29.8432 22.5766C30.006 22.5062 30.1532 22.404 30.276 22.276L35.6094 16.9427C35.8593 16.6927 35.9997 16.3536 35.9997 16C35.9997 15.6464 35.8593 15.3074 35.6094 15.0573L30.276 9.72396C30.1518 9.59623 30.0032 9.49471 29.839 9.42538C29.6749 9.35605 29.4985 9.32032 29.3203 9.32031C29.0551 9.32038 28.7959 9.39955 28.5759 9.54771C28.3558 9.69586 28.185 9.90626 28.0852 10.152C27.9854 10.3978 27.9611 10.6677 28.0155 10.9273C28.07 11.1869 28.2005 11.4244 28.3906 11.6094L31.4479 14.6667H24Z"
      fill="#EDCA60"
    />
  </svg>
)
