import React, { Dispatch, SetStateAction } from 'react'
import { Col, DatePicker, Form, Row } from 'react-angle-dashboard-components'
import { Controller, Control, FormState } from 'react-hook-form'
import { ValidationError } from '../../style'
import { getRequiredObject } from '../../../../../utils/formValidations'
import { changingReasons } from '../changingReasons'
import { CarrierFormData } from '../../../interfaces'

const labels = {
  hasPreviousCarrier:
    'Does the company have a previous medical insurance carrier?',
  previousCarrier: 'Prior Medical Insurance Carrier',
  previousCarrierStartDate: 'Start Date',
  previousCarrierEndDate: 'Termination Date',
  changingReason: 'Reason for changing'
}

interface PriorCarriersProps {
  control: Control
  formState: FormState<CarrierFormData>
  hasPreviousCarrier: 'true' | 'false'
  setHasPreviousCarrier: Dispatch<SetStateAction<'true' | 'false'>>
  lessThanThree: string
  watch: any
}

export const PriorCarriers: React.FC<PriorCarriersProps> = ({
  control,
  formState,
  hasPreviousCarrier,
  setHasPreviousCarrier,
  lessThanThree,
  watch
}) => {
  const isOtherChangingReason = watch('previousChangingReason') === 'Other'

  const hasPrevious = lessThanThree === 'true' && hasPreviousCarrier === 'true'

  return (
    <>
      <Row>
        <Col xs={12} md={7}>
          <Form.Label className="required-field">
            {labels.hasPreviousCarrier}
          </Form.Label>
        </Col>
      </Row>
      <Form.Group>
        <Row>
          <Col xs={12} md={7}>
            <Controller
              name="hasPreviousCarrier"
              control={control}
              render={({ field }) => (
                <Form.Check
                  {...field}
                  className="custom-radio"
                  type="radio"
                  value="false"
                  label="No"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setHasPreviousCarrier('false')
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={7}>
            <Controller
              name="hasPreviousCarrier"
              control={control}
              render={({ field }) => (
                <Form.Check
                  {...field}
                  className="custom-radio"
                  type="radio"
                  value="true"
                  label="Yes"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setHasPreviousCarrier('true')
                  }}
                />
              )}
            />
          </Col>
          <ValidationError>
            {formState.isSubmitted &&
              formState.errors?.hasPreviousCarrier?.message}
          </ValidationError>
        </Row>
      </Form.Group>
      {hasPreviousCarrier === 'true' && (
        <>
          <Row className="pt-3">
            <Form.Group className="w-100">
              <Col>
                <Form.Label className="required-field">
                  {labels.previousCarrier}
                </Form.Label>
                <Controller
                  name="previousCarrier"
                  control={control}
                  rules={{
                    required: hasPrevious
                      ? getRequiredObject(labels.previousCarrier)
                      : false
                  }}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Prior Carrier"
                    />
                  )}
                />
                <ValidationError>
                  {formState.isSubmitted &&
                    formState.errors?.previousCarrier?.message}
                </ValidationError>
              </Col>
            </Form.Group>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label className="required-field">
                  {labels.previousCarrierStartDate}
                </Form.Label>
                <Controller
                  name="previousCarrierStartDate"
                  rules={{
                    required: hasPrevious
                      ? getRequiredObject(labels.previousCarrierStartDate)
                      : false
                  }}
                  control={control}
                  render={({ field }) => <DatePicker {...field} />}
                />
                <ValidationError>
                  {formState.isSubmitted &&
                    formState.errors?.previousCarrierStartDate?.message}
                </ValidationError>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label className="required-field">
                  {labels.previousCarrierEndDate}
                </Form.Label>
                <Controller
                  name="previousCarrierEndDate"
                  control={control}
                  rules={{
                    required: hasPrevious
                      ? getRequiredObject(labels.previousCarrierEndDate)
                      : false
                  }}
                  render={({ field }) => <DatePicker {...field} />}
                />
                <ValidationError>
                  {formState.isSubmitted &&
                    formState.errors?.previousCarrierEndDate?.message}
                </ValidationError>
              </Form.Group>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label className="required-field">
                  {labels.changingReason}
                </Form.Label>
                <Controller
                  name="previousChangingReason"
                  control={control}
                  rules={{
                    required: hasPrevious
                      ? getRequiredObject(labels.changingReason)
                      : false
                  }}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      as="select"
                      type="text"
                      placeholder="Reason for Changing"
                    >
                      <option value="" disabled hidden>
                        Select an option
                      </option>
                      {Object.keys(changingReasons).map((reason: string) => (
                        <option key={reason} value={reason}>
                          {reason}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                />
                <ValidationError>
                  {formState.isSubmitted &&
                    formState.errors?.previousChangingReason?.message}
                </ValidationError>
              </Form.Group>
            </Col>
            {isOtherChangingReason && (
              <Col xs={12} md={6} className="pl-0 pl-md-3">
                <Form.Group>
                  <Form.Label>Reason</Form.Label>
                  <Controller
                    name="otherPreviousChangingReason"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Optional"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      formState.errors?.otherPreviousChangingReason?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  )
}
