import { IDrug, IDrugsResponse, IDrugProfile } from '../../interfaces/drugs'
import { ApiService } from './base'

interface DrugSearchParams {
  searchTerm: string
  limit?: number
  pageNumber?: number
}

export class DrugService extends ApiService {
  async find(
    opts?: DrugSearchParams
  ): Promise<Omit<IDrugsResponse, 'drugs'> & { drugs: IDrug[] }> {
    const response: IDrugsResponse = await this.request({
      method: 'get',
      url: `/v2/drugs?name_searchterm=${opts?.searchTerm}&page_limit=${
        opts?.limit || 10
      }&page_number=${opts?.pageNumber || 1}`,
      credentials: true
    })
    return {
      ...response,
      drugs: response.drugs.map((d) => ({
        description: d.generic_name,
        name: d.label_name,
        id: d.searchable_id,
        tier: d.tier,
        covered: d.is_covered
      }))
    }
  }

  async getById(id: string): Promise<IDrugProfile> {
    return this.request({
      method: 'get',
      url: `/v2/drugs/${id}`,
      credentials: true
    }).then((response) => {
      return {
        id: response.searchable_id,
        name: response.label_name?.toLocaleLowerCase(),
        tier: response.tier,
        specialty_drug: response.dosages[0].specialty_drug,
        pa_applies: response.dosages[0].pa_applies,
        is_covered: response.dosages[0].is_covered,
        quantity_limit: response.dosages[0].quantity_limit,
        age_limit: response.dosages[0].age_limit,
        gender: response.dosages[0].gender,
        dosages: response.dosages
      }
    })
  }
}
