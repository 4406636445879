import React from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorPageLayout, Button } from 'react-angle-dashboard-components'
import { PageLayout } from '../layouts/PageLayout'

const NavButton: React.FC = ({ children }) => {
  const history = useHistory()
  return <Button onClick={() => history.push('/')}>{children}</Button>
}

export const NotFound = () => {
  const messages = {
    notFoundPage: {
      title: '404 - Page not found',
      text: 'The page you were looking for could not be found.',
      button: 'Go to home page'
    }
  }

  return (
    <PageLayout>
      <div style={{ marginTop: -75 }}>
        <ErrorPageLayout
          errorTitle={messages.notFoundPage.title}
          errorSubtitle={messages.notFoundPage.text}
          button={<NavButton>{messages.notFoundPage.button}</NavButton>}
        />
      </div>
    </PageLayout>
  )
}

export default NotFound
