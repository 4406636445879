import React, { ChangeEvent } from 'react'
import {
  Button,
  CenteredSpinner,
  Col,
  DatePicker,
  EmployeeService,
  Form,
  PageContainer,
  PageHeaderLight,
  PlaceAutocomplete,
  Row,
  SectionTitle,
  useToaster
} from '../../../index'

import { states, nonNegativeValues, maxZip } from '@angle/utils'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

type DependentProfileForm = any

const defaultValues: DependentProfileForm = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  streetAddressLine1: '',
  streetAddressLine2: '',
  city: '',
  state: '',
  zip: '',
  medicareEligible: false,
  otherInsurance: false
}

const useEditProfile = (
  id: string,
  profilePath: string,
  employeeService: EmployeeService
) => {
  const history = useHistory()
  const toaster = useToaster()
  const { groupId } = useParams<{
    groupId: string
  }>()

  const { register, control, formState, reset, handleSubmit, setValue } =
    useForm({
      defaultValues
    })

  const { data: dependent, isLoading } = useQuery(
    ['editDependent', id],
    () => employeeService.getMemberForForm(id, groupId || ''),
    {
      onSuccess: (data: DependentProfileForm) => {
        const defaultFormData = { ...data }
        delete defaultFormData.name
        reset(defaultFormData)
      }
    }
  )

  const modifyDependent = useMutation((formData: DependentProfileForm) =>
    employeeService.modifyEmployee(id, formData, groupId || '')
  )

  const onSubmit = handleSubmit(async (formData: any) => {
    try {
      await modifyDependent.mutateAsync(formData)
      history.push(profilePath)
      toaster.show({
        message: 'The profile was updated',
        icon: 'icon-check',
        type: 'success'
      })
    } catch (error) {
      toaster.show({
        message: 'Something went wrong',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  return {
    onSubmit,
    register,
    control,
    formState,
    dependent,
    isLoading,
    setValue
  }
}

export const EmployerPortalEditDependent: React.FC<{
  isBrokerPortal?: boolean
  employeeService: EmployeeService
}> = ({ employeeService, isBrokerPortal }) => {
  const {
    dependentId,
    id: employeeId,
    groupId
  } = useParams<{
    dependentId: string
    id: string
    groupId: string
  }>()
  const history = useHistory()
  const config = (window as any).runtimeConfig
  const googleApiKey = config?.googleApiKey || '****'

  const basePath = `/employees/${employeeId}/dependents/${dependentId}`
  const profilePath = isBrokerPortal
    ? `/groups/${groupId}${basePath}`
    : basePath

  const { onSubmit, register, control, formState, isLoading, setValue } =
    useEditProfile(dependentId, profilePath, employeeService)

  const errors = formState.errors

  return (
    <>
      <PageHeaderLight>
        <PageContainer className="mt-4 mb-2 pb-2" size="md">
          <Row>
            <Col md={8}>
              <h1>Edit Information</h1>
            </Col>
            <Col></Col>
          </Row>
        </PageContainer>
      </PageHeaderLight>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <PageContainer className="mt-4 mb-2 pb-2" size="md">
          <SectionTitle>Personal Information</SectionTitle>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    placeholder="First Name"
                    className="bg-white"
                    isInvalid={errors?.firstName?.type === 'required'}
                    {...register('firstName', { required: true })}
                  />
                  {errors?.firstName?.type === 'required' && (
                    <span className="mt-3 text-danger">
                      First Name is required
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    placeholder="Last Name"
                    className="bg-white"
                    isInvalid={errors?.lastName?.type === 'required'}
                    {...register('lastName', { required: true })}
                  ></Form.Control>
                  {errors?.lastName?.type === 'required' && (
                    <span className="mt-3 text-danger">
                      Last Name is required
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    placeholder="Email Address"
                    className="bg-light"
                    {...register('email', {
                      required: false
                    })}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Date of Birth</Form.Label>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => <DatePicker {...field} />}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <SectionTitle>Mailing Address</SectionTitle>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Street Address</Form.Label>

                  <PlaceAutocomplete
                    {...register('streetAddressLine1', { required: true })}
                    className={`form-control bg-white ${
                      errors?.streetAddressLine1?.type === 'required'
                        ? 'border-danger'
                        : ''
                    }`}
                    placeholder="Address"
                    apiKey={googleApiKey}
                    onPlaceSelection={({
                      address,
                      city,
                      county,
                      zip,
                      state
                    }) => {
                      setValue('streetAddressLine1', address)
                      setValue('city', city)
                      setValue('zip', zip)
                      setValue('state', state)
                    }}
                  />
                  {errors?.streetAddressLine1?.type === 'required' && (
                    <span className="mt-3 text-danger">
                      Street Address is required
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Street Address Line 2</Form.Label>
                  <Form.Control
                    placeholder="Address Line 2"
                    className="bg-white"
                    {...register('streetAddressLine2')}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    placeholder="City"
                    className="bg-white"
                    isInvalid={errors?.city?.type === 'required'}
                    {...register('city', { required: true })}
                  />
                  {errors?.city?.type === 'required' && (
                    <span className="mt-3 text-danger">City is required</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    className="bg-white custom-select"
                    isInvalid={errors?.state?.type === 'required'}
                    {...register('state', { required: true })}
                  >
                    {Object.keys(states).map((key) => (
                      <option key={key} value={key}>
                        {(states as any)[key]}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.state?.type === 'required' && (
                    <span className="mt-3 text-danger">State is required</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    placeholder="Zip Code"
                    className="bg-white"
                    isInvalid={errors?.zip?.type === 'required'}
                    {...register('zip', {
                      required: true,
                      validate: maxZip
                    })}
                    {...nonNegativeValues}
                    type="number"
                  />
                  {errors?.zip?.type === 'required' && (
                    <span className="mt-3 text-danger">
                      Zip Code is required
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <SectionTitle>Other</SectionTitle>
            <Row className="mb-2">
              <Col className="d-flex justify-content-between">
                <h6>Medicare Eligible</h6>
                <Controller
                  name="medicareEligible"
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Form.Switch
                      {...field}
                      id="medicareEligible"
                      checked={value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange(e.target.checked)
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-between">
                <h6>Other Health Coverage</h6>
                <Controller
                  name="otherInsurance"
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Form.Switch
                      {...field}
                      id="otherInsurance"
                      checked={value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange(e.target.checked)
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
            <hr />
            <div className="d-flex justify-content-between pt-4 pb-3">
              <Button
                variant="outline-primary"
                onClick={() => history.push(profilePath)}
              >
                Cancel
              </Button>
              <Button type="submit">Save Changes</Button>
            </div>
          </Form>
        </PageContainer>
      )}
    </>
  )
}
