import { useEffect, useState } from 'react'
// import { IBusinessOnboardingState } from '../interfaces'
import { useHistory } from 'react-router-dom'

// import { businessOnboardingPaths } from '../router/paths'

export const useStep = (
  state: number,
  step: number,
  requiredStepKey: any,
  currentStep: number
) => {
  const history = useHistory()
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    if (checked) return

    // if (step !== 1 && currentStep < step) {
    //   return history.push(businessOnboardingPaths[requiredStepKey])
    // }
    setChecked(true)
  }, [step, requiredStepKey, history, state, checked, currentStep])
}
