import React, { useState } from 'react'
import styled from 'styled-components'
import { IMedicalBenefit, IMedicalBenefitSubCategory } from '../../interfaces'

const DropdownIcon = styled.i`
  font-size: 10px;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

export interface IBenefitListItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  benefit: IMedicalBenefit
  LinkEl: React.FC<any>
  onClickSubCategory?: (category: IMedicalBenefitSubCategory) => void
}

export const BenefitListItem: React.FC<IBenefitListItemProps> = ({
  benefit,
  LinkEl,
  onClickSubCategory,
  ...p
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div {...p}>
      <div
        onClick={() => {
          if (benefit.subCategories.length) {
            setIsOpen(!isOpen)
          }
        }}
        style={{
          borderBottom: '1px solid #E7EAF3',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 16px',
          cursor: 'pointer'
        }}
      >
        <h6 className="mb-0">{benefit.name}</h6>
        <DropdownIcon
          className={`icon ${isOpen ? 'icon-dropdown-up' : 'icon-dropdown'}`}
        />
      </div>
      {isOpen && (
        <div
          className="bg-light"
          style={{
            borderBottom: '1px solid #E7EAF3',
            padding: 16,
            paddingBottom: 0,
            paddingTop: 0
          }}
        >
          {benefit.subCategories.map((subCategory) => {
            return (
              <div
                role="button"
                onClick={() =>
                  onClickSubCategory && onClickSubCategory(subCategory)
                }
                className="py-3"
                key={subCategory.id}
              >
                <h6 className="mb-2 text-primary">{subCategory.name}</h6>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default BenefitListItem
