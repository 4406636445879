import React, { useEffect, useState } from 'react'

import { pdf, Document, Page, Image } from '@react-pdf/renderer'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { IDCard } from './IDCard'
import * as htmlToImage from 'html-to-image'

interface IBulkIDCardDownloadButtonProps {
  members: any[]
}

export const BulkIDCardDownloadButton = ({
  members
}: IBulkIDCardDownloadButtonProps) => {
  const [generate, setGenerate] = useState(false)
  const [lockedMembers, setLockedMembers] = useState(members ?? [])

  useEffect(() => {
    if (
      !generate &&
      (members?.length ?? 0) > 0 &&
      lockedMembers.length !== (members?.length ?? 0)
    ) {
      setLockedMembers(members)
    }
  }, [generate, lockedMembers.length, members])

  const [showGenerationModal, setShowGenerationModal] = useState(false)
  const [idCardGenerationLoading, setIdCardGenerationLoading] = useState(false)

  useEffect(() => {
    if (generate) {
      setGenerate(false)

      const zip = new JSZip()

      const CardDoc = ({ card }: { card: any }) => (
        <Document>
          <Page
            style={{
              padding: 30
            }}
          >
            <Image src={card} />
          </Page>
        </Document>
      )

      let counter = document.getElementById('numberParsed')
      Promise.all(
        lockedMembers
          .filter(
            (employee: any) =>
              !!employee.current_medical_coverage ||
              employee.future_medical_coverages?.length > 0
          )
          .map(async (employee: any, index) => {
            const { name } = employee
            const member_id_no_person_code = employee.member_id.length === 9 || employee.member_id.length === 12 ? employee.member_id?.slice(0, -2) : employee.member_id
            const cardName = `${name.first_name}_${name.last_name}`
            const fileName = `${cardName}_${member_id_no_person_code}.pdf`
            const cardID = `${cardName}_${employee.member_id}`
            const card = document.getElementById(cardID)

            if (card) {
              const img = await htmlToImage.toPng(card)
              zip.file(fileName, pdf(<CardDoc card={img} />).toBlob())
              if (counter && counter.textContent) {
                counter.innerHTML = `${parseInt(counter.textContent) + 1}`
              }
            }
          })
      ).then(async () => {
        const blob = await zip.generateAsync({ type: 'blob' })
        saveAs(blob, 'employee_id_cards.zip')
        setIdCardGenerationLoading(false)

        if (counter) {
          counter.innerHTML = '0'
        }
      })
    }
  }, [generate, lockedMembers])

  const generateBulkIdCards = () => {
    setShowGenerationModal(false)
    setIdCardGenerationLoading(true)
    setTimeout(() => {
      // calling this without a timeout results in a UI freeze while documents are loaded.
      // This create poor UX since a spinner is not visible.
      setGenerate(true)
    }, 100)
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        {idCardGenerationLoading && (
          <div className="d-flex align-items-center mr-2">
            <Spinner className="text-primary mr-1" animation="border" />
            <span id="numberParsed">0</span>/{lockedMembers.length}
          </div>
        )}
        <Button
          variant="outline-primary"
          onClick={() => setShowGenerationModal(true)}
          disabled={idCardGenerationLoading || lockedMembers.length < 1}
        >
          Generate ID Cards
        </Button>
      </div>
      <Modal show={showGenerationModal} centered>
        <Modal.Header>Generate ID Cards</Modal.Header>
        <Modal.Body className="text-center">
          You are about to generate a PDF file containing all active employee
          and dependent ID Cards. This action may take a while. Please do not
          navigate away or refresh the page.
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-primary"
            onClick={() => setShowGenerationModal(false)}
          >
            Cancel
          </Button>
          <Button onClick={generateBulkIdCards}>Generate</Button>
        </Modal.Footer>
      </Modal>
      <div
        style={{
          position: 'absolute',
          zIndex: -10000,
          right: 20000,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        {lockedMembers?.map((e: any) => {
          const { name, member_id } = e
          return (
            <div
              key={`${name.first_name}_${name.last_name}_${member_id}`}
              id={`${name.first_name}_${name.last_name}_${member_id}`}
              style={{
                width: 700
              }}
            >
              <IDCard data={e} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
