import { DateHandler } from '@angle/utils'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Container, Form, Row } from 'react-bootstrap'

export interface IDatePickerProps {
  onChange: (date: string) => void
  value?: string | Date | null
  required?: boolean
  isOptional?: boolean
  backgroundColor?: string
}

const isInputValid = (value, maxDigits): Boolean => {
  const re = /^[0-9\b]+$/

  return (value === '' || re.test(value)) && value.length <= maxDigits
}

export const DatePicker: React.FC<IDatePickerProps> = ({
  value,
  onChange,
  required,
  isOptional,
  backgroundColor = 'var(--light)'
}) => {
  const [prevValue, setPrevValue] = useState(value)
  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [dayIsInvalid, setDayIsInvalid] = useState(false)
  const [monthIsInvalid, setMonthIsInvalid] = useState(false)
  const [yearIsInvalid, setYearIsInvalid] = useState(false)

  useEffect(() => {
    if (prevValue && !value) {
      // if we previously had a value and don't now, clear the input fields
      setDay('')
      setMonth('')
      setYear('')
    }
    setPrevValue(value)
    if (!value) {
      return
    }
    const newDate = moment(value)
    const dateString = `${day}-${month}-${year}`
    const currentDate = moment(dateString, 'DD-MM-YYYY')
    if (newDate.isValid() && !newDate.isSame(currentDate, 'day')) {
      setDay(newDate.format('DD'))
      setMonth(newDate.format('MM'))
      setYear(newDate.format('YYYY'))
    }
  }, [value])

  useEffect(() => {
    const noInputErrors = !dayIsInvalid && !monthIsInvalid && !yearIsInvalid
    const inputLengthInRange =
      day.length > 0 &&
      day.length <= 2 &&
      month.length > 0 &&
      month.length <= 2 &&
      year.length > 0 &&
      year.length === 4
    if (noInputErrors && inputLengthInRange) {
      const dateString = `${day}-${month}-${year}`
      const momentDate = moment(dateString, 'DD-MM-YYYY')
      if (momentDate.isValid()) {
        onChange(DateHandler.formatForServer(momentDate))
      }
    }
  }, [day, month, year])

  const onChangeField = (e, maxDigits, minDigits, setValue, setError) => {
    e.preventDefault()
    const value = e.target.value

    if (isInputValid(value, maxDigits)) {
      setError(false)
      setValue(value)
    } else if (value.length <= maxDigits) {
      setError(!isOptional)
    }

    if (value.length < minDigits) {
      setError(!isOptional)
    }
  }

  return (
    <Container>
      <Row className="flex-nowrap">
        <div
          className="w-50 pr-2"
          style={{
            minWidth: 50
          }}
        >
          <Form.Control
            style={{
              padding: 0,
              textAlign: 'center',
              backgroundColor
            }}
            onChange={(e) =>
              onChangeField(e, 2, 1, setMonth, setMonthIsInvalid)
            }
            required={required}
            value={month}
            isInvalid={monthIsInvalid}
            placeholder="MM"
            onSubmit={(e) => e.preventDefault()}
          />
        </div>
        <div
          className="w-50 pr-2"
          style={{
            minWidth: 50
          }}
        >
          <Form.Control
            style={{
              padding: 0,
              textAlign: 'center',
              backgroundColor
            }}
            onChange={(e) => onChangeField(e, 2, 1, setDay, setDayIsInvalid)}
            required={required}
            value={day}
            isInvalid={dayIsInvalid}
            placeholder="DD"
            onSubmit={(e) => e.preventDefault()}
          />
        </div>
        <div
          className="pr-2"
          style={{
            minWidth: 80
          }}
        >
          <Form.Control
            style={{
              padding: 0,
              textAlign: 'center',
              backgroundColor
            }}
            onChange={(e) => onChangeField(e, 4, 4, setYear, setYearIsInvalid)}
            required={required}
            value={year}
            isInvalid={yearIsInvalid}
            placeholder="YYYY"
            onSubmit={(e) => e.preventDefault()}
          />
        </div>
      </Row>
    </Container>
  )
}
