import { ICreateTokenLink, ISendPublicToken } from '../../interfaces/plaid'
import { system } from '@angle/utils'
import { ApiService } from './base'

export class PlaidService extends ApiService {
  async createLinkToken(): Promise<ICreateTokenLink> {
    return this.request({
      method: 'get',
      url: '/employerportal/payments/accounts/plaid/link-token',
      credentials: true
    })
  }

  async sendPublicToken(
    token: string,
    text?: string
  ): Promise<ISendPublicToken> {
    const systemInfo = system.getInfo()
    return this.request({
      method: 'post',
      url: '/employerportal/payments/public-token',
      data: {
        public_token: token,
        text:
          text ||
          'I have read the terms and conditions for payment by ACH, and hereby authorize Angle to deduct payment from my bank account',
        device_info: `${systemInfo.os.name} ${systemInfo.os.version}`,
        client_info: `${systemInfo.browser.name}/${systemInfo.browser.version}`,
        time: new Date().toISOString()
      },
      credentials: true
    })
  }

  async createLinkTokenById(accountId: string) {
    return this.request({
      method: 'get',
      url: `/employerportal/payments/accounts/plaid/${accountId}/link-token`,
      credentials: true
    })
  }

  async accountUpdated(accountId: string) {
    return this.request({
      method: 'post',
      url: `/employerportal/payments/accounts/plaid/${accountId}/updated`,
      credentials: true
    })
  }
}
