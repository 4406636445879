import styled from 'styled-components'

export const LocationSearchContainer = styled.div`
  position: relative;
  width: 506px;
`

export const SearchInput = styled.input`
  width: 100%;
  background-color: var(--input-bg);
  border: 1px solid var(--primary);
  border-radius: 6px;
  height: 46px;
  padding: 8px 52px;
  color: var(--input-text);
  &:focus {
    box-shadow: 0px 0px 0px 3.2px rgba(96, 30, 126, 0.25);
    border: 1px solid var(--primary);
  }
`

export const LocationIcon = styled.i`
  color: var(--input-text);
  position: absolute;
  height: 17px;
  width: 17px;
  top: 14.33px;
  left: 18.67px;
`

export const ClearButton = styled.i`
  color: var(--gray-400);
  height: 14px;
  width: 14px;
  position: absolute;
  top: 0;
  right: 16.33px;
  top: 16.33px;
  cursor: pointer;
`
export const ResultsDropdown = styled.div`
  background: #ffffff;
  border-radius: 6px;
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
`

export const SearchResult = styled.p`
  width: 100%;
  height: 46px;
  padding: 8px 52px;
  position: relative;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.2s linear;
  &:hover {
    background-color: var(--input-bg);
  }
`

export const EllipsisSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
`
