interface IBeneficiaryProps {
  beneficiaryData?: {
    name: string;
    account_number: string;
    account_type: string;
    address: string;
    aba_routing: string;
    bank_name: string;
    bank_address: string;
  }
  isAutopay?: boolean
  bankName?: string;
  lastNumbers?: string;
  routing_number?: string;
  name?: string;
}


export const Beneficiary: React.FC<IBeneficiaryProps> = ({
  beneficiaryData,
  isAutopay,
  bankName,
  lastNumbers,
  routing_number,
  name,
}) => {

  return (
    <div className="bg-light p-4 mt-3" style={{ borderRadius: "8px" }}>
      {isAutopay ?

        // If Autopay = true, display group's ACH account info
        <span className="text-muted">
          {name && <>Name on account: {name}<br /></>}
          {bankName && <>Bank name: {bankName}<br /></>}
          {routing_number && <>Routing Number: {routing_number}<br /></>}
          Account Number: **** {lastNumbers}<br />
        </span>
        :
        // Else, display Angle's beneficiary info
        <>
          <strong>Please use the following information to submit domestic wires and ACH payments to Angle Insurance Company of Utah's account at Silicon Valley Bank.</strong>
          <br />
          <br />
          <strong>Beneficiary</strong>
          <br />
          <span className="text-muted">
            Name: {beneficiaryData?.name}<br />
            Account Number: {beneficiaryData?.account_number}<br />
            Type of Account: {beneficiaryData?.account_type}<br />
            Address: {beneficiaryData?.address}
          </span>
          <br />
          <br />
          <strong>Receiving Bank</strong>
          <br />
          <span className="text-muted">
            ABA Routing: {beneficiaryData?.aba_routing}<br />
            Bank Name: {beneficiaryData?.bank_name}<br />
            Bank Address: {beneficiaryData?.bank_address}
          </span>
        </>
      }

    </div>
  )
}