import React, { MouseEvent, useState } from 'react'
import { Badge, Col, Row } from 'react-bootstrap'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const DragBox = styled.div<{ isActive: any }>`
  border-style: dashed !important;
  border-color: ${(props: any) =>
    props.isActive ? 'var(--primary)' : '#e7eaf3'};
  border-width: 0.0625rem;
`

export interface IFileDropzone extends Partial<DropzoneOptions> {
  onFileLoaded: (files: File[]) => void
  descriptionText?: string
  placeholderText?: string
  initialFiles?: File[]
}

export const FileDropzone: React.FC<IFileDropzone> = ({
  onFileLoaded,
  placeholderText = 'Drag a file or click to browse',
  descriptionText,
  initialFiles,
  ...others
}) => {
  const [files, setFiles] = useState<File[]>(initialFiles ?? [])

  const onDrop = (acceptedFiles: File[]) => {
    const newFiles = [...files, ...acceptedFiles]
    onFileLoaded(newFiles)
    setFiles(newFiles)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...others
  })

  return (
    <div {...getRootProps()} role="button">
      <input {...getInputProps()} />
      <DragBox className="text-center pt-3" isActive={isDragActive}>
        <p className="text-primary fs-18">{placeholderText}</p>
        {files.length > 0 ? (
          <Row className="mb-5">
            <Col>
              <p>Click to remove</p>
              {files.map((f) => (
                <Badge
                  className="bg-info mb-2 mr-2"
                  onClick={(e: MouseEvent<HTMLSpanElement>) => {
                    e.stopPropagation()
                    const newFiles = files.filter(
                      (cf) => `${cf.name}${cf.size}` !== `${f.name}${f.size}`
                    )
                    onFileLoaded(newFiles)
                    setFiles(newFiles)
                  }}
                  key={f.name + f.size}
                >
                  {f.name}
                </Badge>
              ))}
            </Col>
          </Row>
        ) : (
          <>
            {descriptionText && (
              <p className="text-secondary fs-16">{descriptionText}</p>
            )}
          </>
        )}
      </DragBox>
    </div>
  )
}
export default FileDropzone
