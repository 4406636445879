import { useMemo } from 'react'
import { DateHandler } from '@angle/utils'

type MontlyDateOption = { label: string; value: string }

// for dates on 1st of the month (effective dates)
export const useMonthlyDates = (
  from: Date | string = new Date(),
  qty: number = 12
): MontlyDateOption[] => {
  return useMemo(() => {
    const options: MontlyDateOption[] = []
    const date = DateHandler.use(from).startOf('month')
    for (let i = 0; i < qty; i++) {
      date.add(1, 'month')

      options.push({
        label: date.format('MMMM D, YYYY'),
        value: date.format('YYYY-MM-DD')
      })
    }

    return options
  }, [from, qty])
}
