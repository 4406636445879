import styled from 'styled-components'
import { Button } from '../Button'

export const Detail = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    padding-top: 1rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--card-border);
    padding-bottom: 1rem;
  }

  &:last-child {
    margin-bottom: 1.7rem;
  }
`

export const Title = styled.strong`
  display: block;
  margin-bottom: 1.25rem;
`

export const BadgeContainer = styled.div`
  margin-bottom: 1.7rem;
`

export const DetailLabel = styled.span`
  font-weight: 500;
`

export const ClaimButtonContainer = styled.div`
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--card-border);
  margin-bottom: 1.25rem;
  width: 100%;
`
export const GhostButton = styled(Button)`
  border: 1px solid var(--card-border);
  width: 47%;
`
export const GhostButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
