import { IDirectoryQueryData } from '../../interfaces/directory'
import {
  IFacilityDTO,
  IFacilityProfile,
  IFacilityProfileDTO
} from '../../interfaces/facilities'
import { formatPhoneNumber } from '@angle/utils'
import { ApiService } from './base'
import {
  mapDirectoryListQuery,
  mapTypesToCheckboxFilter
} from './mappers/directory-mapper'

const ENDPOINTS = {
  facilities: '/facilities',
  services: '/facilities/services',
  specialties: '/facility-specialties'
}

export const mapFacilityProfile = (
  result: IFacilityProfileDTO
): IFacilityProfile => {
  const { location, id, facility_physician_group_name, facility_services } =
    result
  const address =
    `${location.address_line_1}`.toLocaleLowerCase() +
    `${location.address_line_2 ? ', ' + location.address_line_2 : ''}, ${
      location.city
    }, `.toLocaleLowerCase() +
    `${location.state}`.toUpperCase() +
    `, ${location.zip_code}`

  return {
    ...result,
    current: {
      city: location.city,
      county: location.county,
      phone: location.phone,
      facility_name: facility_physician_group_name,
      lat: location.lat,
      lng: location.lon,
      country: '',
      address: address,

      street_1: location.address_line_1,
      street_2: location.address_line_2,
      state: location.state,
      fax: '',
      zip_code: location.zip_code,
      dir_print: '',
      country_code: '',
      provider_id: String(id),
      specialties: facility_services.join(' • ')
    },
    mapLocations: [
      {
        coordinates: {
          lat: Number(location.lat),
          lng: Number(location.lon)
        },
        details: [
          {
            name: 'Phone',
            value: formatPhoneNumber(location.phone) || ' - '
          },
          {
            name: 'Fax',
            value: ' - '
          }
        ],
        id: String(id),
        name: location.name || address || ' - '
      }
    ]
  }
}

export const mapFacilityList = (response: {
  data: IFacilityDTO[]
  total_count: number
}) => {
  const items = response.data.map((f) => {
    const mappedFacility: any = {
      ...f,
      distance: Number(f.location.distance).toFixed(2) + 'mil',
      address:
        f.location.address_line_1 +
        (f.location.address_line_2 ? ', ' + f.location.address_line_2 : ''),
      name: f.facility_physician_group_name,
      coverPicture: null, // it's missing
      id: f.id,
      coordinates: {
        lat: f.location.lat,
        lng: f.location.lon
      },
      type: f.facility_services[0]
    }
    return mappedFacility
  })

  return {
    items,
    totalCount: response.total_count
  }
}

export class FacilityService extends ApiService {
  async getById(
    id: string,
    mapper: (e: IFacilityProfileDTO) => IFacilityProfile = mapFacilityProfile
  ): Promise<IFacilityProfile> {
    return this.request({
      method: 'get',
      url: `${ENDPOINTS.facilities}/${id}`,
      credentials: true
    }).then(mapper)
  }

  async find(
    queryData: IDirectoryQueryData | null,
    mapper: (e: any) => {
      items: any[]
      totalCount: number
    } = mapFacilityList
  ) {
    if (!queryData) return

    const params = mapDirectoryListQuery(queryData)

    const urlParams = Object.keys(params).map((k) => {
      if (k === 'service') return params[k].map((v) => `service=${v}`).join('&')
      return `${k}=${params[k]}`
    })

    const req: any = {
      method: 'get',
      url:
        ENDPOINTS.facilities + `?${urlParams.join('&').replaceAll('&&', '&')}`,
      credentials: true
    }

    return this.request(req).then(mapper)
  }

  async getFacilityTypesCheckboxFilter(
    mapper: (a: any) => any = (r) => mapTypesToCheckboxFilter(r['data'])
  ) {
    const req: any = {
      method: 'get',
      url: ENDPOINTS.services,
      credentials: true
    }

    return this.request(req).then(mapper)
  }

  async getFacilityTypes(mapper: (e: any) => any = (r) => r['data']) {
    const req: any = {
      method: 'get',
      url: ENDPOINTS.services,
      credentials: true
    }

    return this.request(req).then(mapper)
  }
}
