import { ApiService } from '../base'
import { EightThreeFourRequest } from "../../../interfaces/employer-broker-shared/eightthreefour";

export class EightThreeFourService extends ApiService {
  async postMemberInformation(data: EightThreeFourRequest){
    return this.request({
        method: 'post',
        url: '/834',
        data,
        credentials: true,
      })
  }
}
