import React, { useMemo } from 'react'
import { View, Text } from '@react-pdf/renderer'

import tableStyles from './tableStyles'
import Row from './Row'
import type { ReportTableInfo } from '../ReportTable'
import {
  getCellStyleBasedOnDataType,
  getColumnFlexBasedOnContent,
  getFormattedDataBasedOnType
} from '../funcs'

const PDFTable = ({ table, extra, footer }: ReportTableInfo) => {
  const { columns, rowData, summaryData } = table
  
  const hiddenColumnKey: string[] = []

  if (columns.length && columns[0].displayLocations) {
    let i = 0;
    while(i < columns.length) {
      if (!columns[i].displayLocations?.includes('print')) {
        hiddenColumnKey.push(columns[i].columnKey)
        columns.splice(i, 1)
      } else {
        i++
      }
    }
  }

  if (hiddenColumnKey.length) {
    for (let row of rowData) {
      for (let key of hiddenColumnKey) {
        delete row[key]
      }
    }
  }

  const columnFlex = useMemo(() => {
    return getColumnFlexBasedOnContent(columns, rowData)
  }, [columns, rowData])

  return (
    <View
      style={{
        display: 'flex',
        width: '100%'
      }}
    >
      {/* table */}
      <View
        style={{
          border: '1px solid #E7EAF3'
        }}
      >
        <View
          style={[
            tableStyles.row,
            {
              marginTop: 0,
              paddingVertical: 4,
              color: 'white',
              backgroundColor: '#4c41b9'
            }
          ]}
        >
          {columns.map((col) => (
            <View style={[tableStyles.cell, columnFlex[col.columnKey]]}>
              <Text
                style={[
                  getCellStyleBasedOnDataType(col.dataType) as any,
                  tableStyles.cellText
                ]}
              >
                {col.columnHeader.toUpperCase()}
              </Text>
            </View>
          ))}
        </View>
        {rowData.map((rd) => (
          <Row rowData={rd} columns={columns} columnFlex={columnFlex} />
        ))}
      </View>

      {/* summary data */}
      <View style={[tableStyles.tableRow, { borderTop: 'none' }]}>
        {columns.map(({ columnKey, dataType }, index) => (
          <View style={[tableStyles.cell, columnFlex[columnKey]]}>
            <Text
              style={[
                getCellStyleBasedOnDataType(dataType) as any,
                tableStyles.cellText
              ]}
            >
              {index === 0
                ? 'Totals'
                : getFormattedDataBasedOnType(summaryData[columnKey], dataType)}
            </Text>
          </View>
        ))}
      </View>

      {/* extras */}
      {extra.fields.length > 0 && (
        <View
          style={{
            marginTop: 50,
            marginVertical: 5,
            width: '50%',
            border: '1px solid #E7EAF3'
          }}
        >
          {extra.fields.map((field, index) => (
            <View
              style={[
                tableStyles.tableRow,
                index === 0 ? { borderTop: 'none' } : {}
              ]}
            >
              <View style={[tableStyles.cell]}>
                <Text style={[tableStyles.cellText]}>
                  {field.fieldDescription}:
                </Text>
              </View>
              <View style={[tableStyles.cell]}>
                <Text style={[tableStyles.cellText]}>
                  {getFormattedDataBasedOnType(
                    extra.data[field.fieldKey],
                    field.dataType
                  )}
                </Text>
              </View>
            </View>
          ))}
        </View>
      )}

      {/* disclaimer */}
      <View style={{ marginTop: 30 }}>
        <Text style={[tableStyles.descriptionText]}>{footer}</Text>
      </View>
    </View>
  )
}

export default PDFTable
