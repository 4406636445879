import styled from 'styled-components'

export const InfoContainer = styled.div`
  width: 100%;
  padding: 20px 55px 20px 20px;
  margin-top: 20px;
  border-radius: 6px;
`

export const SearchContainer = styled.div`
  padding: 30px 1rem 0px 1rem;
  border-bottom: 1px solid #e7eaf3;
`

export const InfoTitle = styled.strong`
  margin-bottom: 5px;
  color: var(--dark);
`
export const InputPlaceholder = styled.div`
  width: 100%;
  background-color: var(--input-bg);
  border: none;
  border-radius: 6px;
  height: 46px;
  margin-top: 15px;
  margin-bottom: 20px;
`
