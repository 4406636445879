import React, { ChangeEvent, useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useDebounce,
  IDrug,
  DrugService,
  CenteredSpinner,
  EmptySearch,
  IconInput,
  PageContainer,
  PageHeaderLight,
  Pagination,
  useServerPagination
} from '../../'
import { SearchInputContainer, SearchTitle } from './style'
import { DrugItem } from './DrugItem'

type DrugsSearchLocationState =
  | {
      searchTerm: string
      currentPage: number
    }
  | undefined

export interface IDrugSearchProps {
  drugService: DrugService
}

export const DrugSearch: React.FC<IDrugSearchProps> = ({ drugService }) => {
  const history = useHistory<DrugsSearchLocationState>()
  const locationState = history.location.state
  const inputReference = useRef<any>(null)

  // Clear the location state once it is used (example when reloading) and without causing re-render
  useEffect(() => {
    if (locationState) {
      window.history.replaceState({}, document.title)
    }
  }, [locationState])

  useEffect(() => {
    if (inputReference.current) inputReference.current.focus()
  }, [inputReference.current])

  const [searchTerm, setSearchTerm] = useState(locationState?.searchTerm || '')

  const minCharacters = 3

  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const { queryResult, paginationConfig } = useServerPagination(
    ['drugServiceFind', debouncedSearchTerm],
    ({ pageLimit, pageNumber }) => {
      return drugService.find({ searchTerm, limit: pageLimit, pageNumber })
    },
    {
      queryOpts: {
        enabled:
          !!debouncedSearchTerm && debouncedSearchTerm.length >= minCharacters
      },
      initialPage: locationState?.currentPage
    }
  )

  const { isLoading, isIdle, data: response, isFetched } = queryResult

  paginationConfig.totalItems = response?.total_count || 0
  paginationConfig.totalPages = Math.ceil(
    paginationConfig.totalItems / paginationConfig.pageLimit
  )

  // Send the needed variables for later getting the same drugs state here if we come back from Drug Profile page
  const navigateToProfile = (id: number) => {
    history.push(`/drugs/${id}`, {
      searchTerm,
      currentPage: paginationConfig.currentPage
    })
  }

  const searchedResult = !isIdle

  let subtitle

  if (searchedResult) {
    subtitle = (response?.drugs?.length ?? 0) > 0 ? 'Search results' : ''
  } else {
    subtitle = null
  }

  return (
    <React.Fragment>
      <PageHeaderLight>
        <PageContainer size="md" className="text-center">
          <SearchTitle className="mt-3 mb-5">Drug search</SearchTitle>
          <SearchInputContainer>
            <IconInput
              icon="icon-search"
              id="search-input"
              data-testid="search-input-for-test"
              placeholder="Amoxicillin, Raloxifene hcl, etc."
              label="seach-input"
              value={searchTerm}
              ref={inputReference}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(e.target.value)
              }
            />
          </SearchInputContainer>
        </PageContainer>
      </PageHeaderLight>
      <PageContainer className="bg-white" size="md">
        {isLoading && <CenteredSpinner animation="border" />}

        {!isLoading && isFetched && (
          <React.Fragment>
            <h6 className="mb-3">
              <b>{subtitle}</b>
            </h6>

            {(response?.drugs?.length ?? 0) > 0 ? (
              <React.Fragment>
                <ul className="p-0">
                  {response?.drugs.map((d: IDrug) => (
                    <DrugItem
                      key={d.id}
                      name={d.name}
                      covered={d.covered}
                      onClick={() => navigateToProfile(d.id)}
                    />
                  ))}
                </ul>
                <section className="mt-5">
                  <Pagination {...paginationConfig} nextText="Next" />
                </section>
              </React.Fragment>
            ) : searchedResult ? (
              <EmptySearch title={`No search results for "${searchTerm}"`}>
                Please make sure that the drug name you entered is spelled
                correctly.
              </EmptySearch>
            ) : null}
          </React.Fragment>
        )}
      </PageContainer>
    </React.Fragment>
  )
}

export default DrugSearch
