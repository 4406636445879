import { AuthState } from './state'
import { IAuthState } from './interface'

export const AuthReducer = (state: IAuthState, action: any) => {
  switch (action.type) {
    case 'LOGOUT':
      return {
        ...AuthState,
        fetching: false
      }
    default:
      return {
        ...state,
        ...action
      }
  }
}
