import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import styled from 'styled-components'
import { IconInput, IconInputProps } from '../Input'
import { ListGroup } from '../List'

const SearchResultContainer = styled.section`
  display: block;
  position: absolute;
  padding-top: 0.1em;
  top: 10vh;
  left: 0;
  width: 100%;
  background-color: white;
`

const SearchResults = styled.section`
  padding: 1rem;
  width: 95%;
  margin: auto;
`

function SearchTabs() {
  const [key, setKey] = useState('all')

  return (
    <Tabs
      id="result-search-tab"
      activeKey={key}
      onSelect={(k: any) => setKey(k)}
    >
      <Tab tabClassName="text-dark" eventKey="all" title="All">
        {/* <div>hi</div> */}
      </Tab>
      <Tab tabClassName="text-dark" eventKey="facilities" title="Facilities">
        <div>hi</div>
      </Tab>
      <Tab tabClassName="text-dark" eventKey="rx" title="Rx">
        <div>hi</div>
      </Tab>
      <Tab tabClassName="text-dark" eventKey="care" title="Care">
        <div>hi</div>
      </Tab>
      <Tab tabClassName="text-dark" eventKey="plan" title="Plan">
        <div>hi</div>
      </Tab>
    </Tabs>
  )
}

export interface ISearchBarProps extends Omit<IconInputProps, 'icon'> {
  toggleBar: Function
  barIsOpen: boolean
  showingMenu: boolean
  isSearching: boolean
  smallClassName?: string
  largeClassName?: string
  iconClassName?: string
  inlineStyle?: React.CSSProperties
  smallStyle?: React.CSSProperties
  largeStyle?: React.CSSProperties
}

export const SearchBar: React.FC<ISearchBarProps> = ({
  barIsOpen,
  toggleBar,
  showingMenu,
  isSearching,
  smallClassName,
  largeClassName,
  inlineClassName,
  inlineStyle,
  smallStyle = {},
  largeStyle = {},
  iconClassName,
  ...otherProps
}) => {
  return (
    <React.Fragment>
      <IconInput
        style={{ ...largeStyle }}
        barStyle={largeStyle}
        barClassName={largeClassName}
        containerClassName="my-1"
        inlineClassName={`d-none d-sm-inline ${inlineClassName}`}
        inlineStyle={inlineStyle}
        iconClassName={iconClassName}
        className="border-bottom-0"
        placeholder="Find Care"
        icon="icon-search"
        id="search-id"
        {...otherProps}
      />

      {barIsOpen ? (
        <IconInput
          style={{ maxWidth: '400px' }}
          barStyle={smallStyle}
          barClassName={smallClassName}
          containerClassName="my-1"
          inlineClassName={'w-100 d-inline d-sm-none'}
          className="border-bottom-0"
          appendIcon={'icon-cancel'}
          onAppendIconClick={() => toggleBar()}
          placeholder="Find Care"
          icon="icon-search"
          id="search-id-mobile"
          {...otherProps}
        />
      ) : null}

      {isSearching && barIsOpen && !showingMenu ? (
        <SearchResultContainer>
          <SearchTabs />
          <SearchResults>
            <ListGroup
              items={[
                {
                  title: 'Ra Back & Body Pain Relief',
                  avatarFallback: (
                    <i className="icon icon-drugs bg-white text-dark" />
                  ),
                  style: { borderBottom: 'none' }
                },
                {
                  title: 'Back Home Pharmacy',
                  subtitle: '328 W Main St, Waverly, VA 23890',
                  avatarFallback: (
                    <i className="icon icon-facility bg-white text-dark" />
                  ),
                  style: { borderBottom: 'none' }
                },
                {
                  title: 'Alabama Artificial Limb & Orthopedic',
                  subtitle: '3201 Dauphin St Ste D, Mobile, AL 36606',
                  avatarFallback: (
                    <i className="icon icon-facility bg-white text-dark" />
                  ),
                  style: { borderBottom: 'none' }
                },
                {
                  title: 'Physiatrist',
                  avatarFallback: (
                    <i className="icon icon-search bg-white text-dark" />
                  ),
                  style: { borderBottom: 'none' }
                },
                {
                  title: 'Mackenzie Cohen',
                  subtitle: 'Orthopedic Surgeon · San Francisco, CA',
                  avatarFallback: <span className="text-dark">MC</span>,
                  avatarImagePath:
                    'https://photographybyangelamcconnell.com/wp-content/uploads/2019/06/Photography-by-Angela-McConnell-20180906-_DSC0270.jpg',
                  style: { borderBottom: 'none' }
                }
              ]}
            />
          </SearchResults>
        </SearchResultContainer>
      ) : null}
    </React.Fragment>
  )
}

export default SearchBar
