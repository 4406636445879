import classNames from 'classnames'
import React, { useState } from 'react'
import {
  Button,
  Col,
  Form,
  PageContainer,
  Row,
  SectionTitle,
  useToaster
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import { authService } from '../../../services'

interface ISecurity {
  currentPassword: string
  newPassword: string
  verifyNewPassword: string
}

export const Security = () => {
  const toast = useToaster()
  const [isSaving, setIsSaving] = useState(false)

  const { control, formState, getValues, handleSubmit, reset } =
    useForm<ISecurity>({
      mode: 'onBlur'
    })

  const hasError = !!Object.keys(formState.errors || {}).length

  const getError = (field: keyof ISecurity) => formState.errors[field]

  const onChangesSuccess = () => {
    reset()

    toast.show({
      message: 'Your password has been updated',
      icon: 'icon-correct',
      type: 'success'
    })
  }

  const onChangesFail = (error: any) => {
    toast.show({
      message: `Something went wrong: ${error.message}`,
      icon: 'icon-cancel',
      type: 'danger'
    })
  }

  const submitForm = handleSubmit((data) => {
    setIsSaving(true)
    authService
      .changePassword({
        old_password: data.currentPassword,
        new_password: data.newPassword
      })
      .then(onChangesSuccess)
      .catch(onChangesFail)
      .finally(() => setIsSaving(false))
  })

  const errorMessage = hasError ? (
    <span className="text-danger">Please fill the required fields</span>
  ) : null

  return (
    <PageContainer size="md">
      <SectionTitle>Password change</SectionTitle>
      <Form>
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': getError('currentPassword')
                })}
              >
                Current Password
              </Form.Label>
              <Controller
                name="currentPassword"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="password"
                    className="bg-white"
                    placeholder="Current Password"
                    isInvalid={!!getError('currentPassword')}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': getError('newPassword')
                })}
              >
                New Password
              </Form.Label>
              <Controller
                name="newPassword"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="password"
                    className="bg-white"
                    placeholder="New Password"
                    isInvalid={!!getError('newPassword')}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': getError('verifyNewPassword')
                })}
              >
                Confirm New Password
              </Form.Label>
              <Controller
                name="verifyNewPassword"
                defaultValue=""
                rules={{
                  required: true,
                  validate: {
                    passwordAreNotTheSame: (value) => {
                      if (value !== getValues('newPassword')) return false
                      return true
                    }
                  }
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="password"
                    className="bg-white"
                    placeholder="Confirm New Password"
                    isInvalid={!!getError('verifyNewPassword')}
                  />
                )}
              />

              {getError('verifyNewPassword') &&
                getError('verifyNewPassword')?.type ===
                  'passwordAreNotTheSame' && (
                  <small className="text-danger mt-2">
                    {' '}
                    Passwords are not equal{' '}
                  </small>
                )}
            </Form.Group>
          </Col>
        </Row>

        <hr />

        {errorMessage}

        <div className="pt-2 text-right">
          <Button disabled={isSaving} onClick={submitForm}>
            Save changes
          </Button>
        </div>
      </Form>
    </PageContainer>
  )
}

export default Security
