import React, { useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { CircularBadge } from '../CircularBadge/CircularBadge'

const maxNotificationCount = 99

const NotificationContainer = styled(CircularBadge)`
  position: absolute;
  top: 0;
  right: -9%;
  width: 37%;
  height: 37%;
  font-size: ${(props) => props.fontSize + 'px'};
`

export interface IAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  fallbackContent: React.ReactNode
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  avatarImagePath?: string
  status?: React.ReactNode
  variant?: string
  circle?: boolean
  centered?: boolean
  border?: string
  notificationCount?: number
  notificationFontSize?: number
  notificationVariant?: string
  customBg?: string
  [x: string]: any
}

export const Avatar: React.FC<IAvatarProps> = (props) => {
  const {
    fallbackContent,
    avatarImagePath,
    status = null,
    border = null,
    size = 'md',
    circle = false,
    centered = false,
    variant = 'primary',
    notificationVariant = 'primary',
    notificationCount = 0,
    notificationFontSize = 10,
    avatarClass = '',
    customBg,
    ...p
  } = props

  const [imageError, setImageError] = useState(false)

  const classNames = classnames(
    'avatar',
    {
      'avatar-circle': circle,
      [`avatar-border-${border}`]: border,
      'avatar-centered': centered,
      [`avatar-${variant}`]: !customBg
    },
    `avatar-${size}`
  )

  let avatar

  if (avatarImagePath && !imageError) {
    avatar = (
      <img
        src={avatarImagePath}
        className="avatar-img"
        onError={() => setImageError(true)}
        alt="Avatar"
        width="100%"
        height="100%"
      />
    )
  } else {
    avatar = (
      <span
        className={'avatar-initials text-white ' + avatarClass}
        style={customBg ? { backgroundColor: customBg } : {}}
      >
        {fallbackContent}
      </span>
    )
  }

  return (
    <div className={classNames} {...p}>
      {avatar}
      {status}
      {!!notificationCount && (
        <NotificationContainer
          variant={notificationVariant}
          fontSize={notificationFontSize}
        >
          {notificationCount <= maxNotificationCount
            ? notificationCount
            : maxNotificationCount}
        </NotificationContainer>
      )}
    </div>
  )
}

export default Avatar
