import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import EmployerAuthentication from '../components/EmployerAuthentication/EmployerAuthentication'
import { authService as auth } from '../services'

export interface PrivateRouteProps extends RouteProps {
  onboardingStep?: string
  notFound?: boolean
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  onboardingStep,
  notFound,
  ...p
}) => (
  <EmployerAuthentication
    auth={auth}
    onboardingStep={onboardingStep}
    notFound={!!notFound}
  >
    <Route {...p} />
  </EmployerAuthentication>
)
