import React from 'react'
import {
  TooltipPaginatorContainer,
  PaginatorText,
  PaginatorButtonContainer,
  PaginatorButton
} from './style'

const ItemPaginator = ({
  currentIndex,
  maxIndex,
  setCurrentIndex,
  showPageControls
}) => {
  const previousDisabled = currentIndex <= 0
  const nextDisabled = currentIndex >= maxIndex
  const disabledStyles = { color: 'var(--gray-400)', cursor: 'auto' }
  const onlyCloseStyles = { width: '30px' }

  return (
    <TooltipPaginatorContainer
      style={!showPageControls ? onlyCloseStyles : null}
    >
      {showPageControls && (
        <React.Fragment>
          <PaginatorText>
            <small>
              {currentIndex + 1}/{maxIndex + 1}
            </small>
          </PaginatorText>
          <PaginatorButtonContainer>
            <PaginatorButton
              onClick={() => {
                if (!previousDisabled) setCurrentIndex(currentIndex - 1)
              }}
              className="icon icon-left-arrow fs-12"
              style={previousDisabled ? disabledStyles : null}
            />
            <PaginatorButton
              onClick={() => {
                if (!nextDisabled) setCurrentIndex(currentIndex + 1)
              }}
              className="icon icon-right-arrow fs-12"
              style={nextDisabled ? disabledStyles : null}
            />
          </PaginatorButtonContainer>
        </React.Fragment>
      )}
    </TooltipPaginatorContainer>
  )
}

export default ItemPaginator
