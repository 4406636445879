import React, { ReactNode } from 'react'
import { ListGroup as ListGroupRB, Row, Col } from 'react-bootstrap'
import { Avatar } from '../Avatar'

export interface IListItem {
  title: string
  subtitle?: string | ReactNode
  avatarImagePath?: string
  avatarFallback?: ReactNode
  circularAvatar?: boolean
  rightContent?: React.ReactNode
  [x: string]: any
}

export const ListItem: React.FC<IListItem> = ({
  title,
  subtitle,
  avatarImagePath,
  avatarFallback,
  circularAvatar = true,
  className = '',
  rightContent = null,
  ...others
}) => {
  return (
    <ListGroupRB.Item
      className={`px-2 border-top-0 border-right-0 border-left-0 ${className}`}
      as="li"
      {...others}
    >
      <Row>
        <Col
          xs="1"
          style={{ minWidth: '3rem', maxWidth: '4rem' }}
          className="text-center px-0"
        >
          {(avatarImagePath || avatarFallback) && (
            <Avatar
              circle={circularAvatar}
              avatarImagePath={avatarImagePath}
              fallbackContent={avatarFallback}
              avatarClass="bg-primary-light"
            />
          )}
        </Col>
        <Col
          className={`text-left ${
            !subtitle ? 'd-flex align-items-center' : ''
          }`}
        >
          <b>{title}</b>
          {subtitle && <p className="mb-0 text-muted fs-14">{subtitle}</p>}
        </Col>
        {rightContent && (
          <Col className="d-flex justify-content-end">{rightContent}</Col>
        )}
      </Row>
    </ListGroupRB.Item>
  )
}

export default ListItem
