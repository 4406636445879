import React from 'react'
import { Container } from '../../index'

export const SearchViewLayout: React.FC = ({ children }) => {
  return (
    <Container
      fluid
      className="h-100 w-100 d-flex flex-column align-center p-0"
    >
      {children}
    </Container>
  )
}

export default SearchViewLayout
