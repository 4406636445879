import { unformatPhoneNumber, validateEmail } from '@angle/utils'

export const getRequiredObject = (label: string) => ({
  value: true,
  message: `${label} is required`
})

export const formValidations = {
  required: { value: true, message: 'Input required' },
  isValidEmail: (value: string) => {
    // eslint-disable-next-line
    if (validateEmail(value)) return true
    return 'Email is not valid'
  },
  onlyNumbers: (value: string) => {
    if (typeof value === 'number') return true
    return (
      !value?.match(/[^0-9]+/g)?.length ||
      'This field must be filled with whole numbers only'
    )
  },
  minNumOfEmployees: (val: number) =>
    val >= 50 || 'Employee count must be 50 or more',
  maxNumOfEmployees: (val: number) =>
    val <= 49 || 'Employee count must be 49 or less',
  hoursWorkedRange: (val: number) =>
    (val >= 30 && val <= 40) || 'Hours must be between 30 and 40',
  minMedicare: (val: number) => val >= 2 || 'Minimum two employees required',
  noNegative: (val: number) => val >= 0 || 'Negative numbers not allowed',
  maxZip: (val: any) =>
    val.toString().length === 5 || 'Zip code must be 5 digits',
  maxPhoneLength: (val: any) =>
    unformatPhoneNumber(val)?.length === 10 || 'Phone number must be 10 digits',
  maxTaxIdLength: (val: any) =>
    unformatPhoneNumber(val)?.length === 9 || 'Please enter a valid Tax ID'
}
