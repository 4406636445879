import React, { useCallback } from 'react'
import {
  Button,
  Container,
  Col,
  Row,
  Form,
  Card,
  Logo,
  useToaster
} from 'react-angle-dashboard-components'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { authService as auth } from '../../services'
import { Paths } from '../../routes/paths'

export const ForgotPassword = () => {
  const { showError, showSuccess } = useToaster()
  const history = useHistory()
  const { register, handleSubmit } = useForm<{ email: string }>({
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  })

  const onChangesSuccess = async (response: any) => {
    showSuccess(
      'An email has been sent to your address with the instructions for resetting your password.'
    )
  }

  const onChangesFail = (error: any) => {
    showError(error.message || '')
  }

  const onBackToSignInHandler = useCallback(() => {
    history.push(Paths.login)
  }, [history])

  const forgotPassword = useMutation(
    (data: { email: string }) => auth.forgotPassword(data),
    {
      onSuccess: onChangesSuccess,
      onError: onChangesFail
    }
  )

  const onSubmit = handleSubmit(async (data: { email: string }) => {
    forgotPassword.mutate(data)
  })

  return (
    <Container
      fluid
      className="vh-100 d-flex flex-column justify-content-center"
    >
      <Row className="mb-4 d-flex justify-content-center">
        <Logo variant="default" color="primary" />
      </Row>
      <Row>
        <Col
          lg={5}
          md={8}
          sm={10}
          className="m-auto d-flex flex-column justify-content-center"
        >
          <Card className="p-6 pl-lg-10 pr-lg-10">
            <Card.Title>
              <h1 className="text-center">Forgot Password?</h1>
            </Card.Title>
            <Card.Body>
              <p className="text-center">
                Enter the email address you used when you joined and we&apos;ll
                send you instructions to reset your password.
              </p>
              <Form onSubmit={onSubmit}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    {...register('email', {
                      required: true
                    })}
                    type="email"
                    placeholder="Enter your email address"
                  />
                </Form.Group>
                <Button type="submit" block>
                  Send Recovery Link
                </Button>
              </Form>
              <Button block variant="link" onClick={onBackToSignInHandler}>
                Back to Sign In
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default ForgotPassword
