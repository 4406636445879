import { ApiService } from 'react-angle-dashboard-components'
import { IDisclosureData } from '../interfaces/disclosures'



export class DisclosuresService extends ApiService {
  async getDisclosures() {
    return this.request({
      method: 'get',
      url: '/disclosures'
    }).then((r) => r.disclosure)
  }

  async createDisclosure(data: IDisclosureData) {
    return this.request({
      method: 'post',
      url: '/disclosures',
      data
    })
  }

  async editDisclosure(disclosure_id: string | number, data: Partial<IDisclosureData>) {
    return this.request({
      method: 'patch',
      url: `/disclosures/${disclosure_id}`,
      data
    })
  }

  async deleteDisclosure(disclosure_id: string | number) {
    return this.request({
      method: 'delete',
      url: `/disclosures/${disclosure_id}`
    })
  }
}
