import React from 'react'
import { EmployerPortalDocuments } from 'react-angle-dashboard-components'
import { documentService } from '../../services'
import { PageLayout } from '../layouts/PageLayout'

export const DocumentsAndForms: React.FC = () => {
  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Documents and Forms', link: '/documents' }
      ]}
    >
      <EmployerPortalDocuments
        isBrokerPortal={false}
        documentService={documentService}
      />
    </PageLayout>
  )
}

export default DocumentsAndForms
