import styled from 'styled-components'

export const UserDropdownHeader = styled.div`
  display: flex;
  border-bottom: 1px solid var(--card-border);
  padding: 0.5rem 1rem;
`

export const UserDropdownTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.7rem 0.2rem;
  &:hover {
    background-color: var(--dropdown-active);
    cursor: pointer;
  }
`

export const DropdownItemIcon = styled.div`
  flex-grow: 0;
`

export const DropdownContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const DropdownTitle = styled.p`
  margin-bottom: 0;
`

export const DropdownContainer = styled.div`
  max-width: 400px;
  border-radius: 6px;
`
