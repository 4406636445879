import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'

const Wrapper = styled(Row)<{ selected: boolean }>`
  margin-bottom: 1.3rem;
  padding: 0.3rem;
  border-radius: 6px;
  ${(props) => (props.selected ? 'background-color: #EEEAF7;' : '')}
  border: 1px solid ${(props) =>
    props.selected ? 'var(--primary);' : '#e7eaf3;'}
`
export interface ISwitchRowProps extends React.HTMLAttributes<HTMLDivElement> {
  control: any
  label: string
  name: string
  saveChange?: (key: string, value: any) => void
  sideEffect?: (value: boolean) => void
  selected?: boolean
}
export const SwitchRow: React.FC<ISwitchRowProps> = ({
  control,
  label,
  name,
  saveChange,
  sideEffect,
  selected: outterSelected,
  ...p
}) => {
  const initialValue = control.defaultValuesRef.current[name]

  const [selected, setSelected] = useState(outterSelected || initialValue)

  useEffect(() => {
    if (outterSelected) setSelected(outterSelected)
  }, [initialValue, setSelected, selected, outterSelected])

  return (
    <Wrapper selected={selected} {...p}>
      <Col>
        <h6 className="my-1">{label}</h6>
      </Col>
      <Col xs={1} className="d-flex justify-content-end">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <React.Fragment>
              <Form.Switch
                {...field}
                style={{ marginRight: '0.25rem' }}
                checked={value}
                onChange={(e) => {
                  if (saveChange) {
                    saveChange(e.target.name, e.target.checked)
                  }
                  if (sideEffect) {
                    sideEffect(e.target.checked)
                  }
                  onChange(e.target.checked)
                  setSelected(e.target.checked)
                }}
                id={name}
              />
              {value ? 'Yes' : 'No'}
            </React.Fragment>
          )}
        />
      </Col>
    </Wrapper>
  )
}

export default SwitchRow
