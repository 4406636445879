import React, { MouseEventHandler, useState } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import styles from './styles'
import { Location } from '../Directory/Directory'
import { LocationMarker } from '../LocationMarker'

const minZoomToShowMarkers = 7

const MapContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const MapControl = styled.div`
  position: absolute;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  z-index: 9;
`

const LocationControl = styled(MapControl)<{
  onClick: MouseEventHandler<HTMLDivElement>
}>`
  height: 40px;
  width: 40px;
  right: 10px;
  top: 100px;
`

const SearchAsIMoveControl = styled(MapControl)`
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 8px;
  display: flex;
  align-items: baseline;
  height: 36px;
  width: 230px;
`

const LocationIcon = styled.i`
  height: 40px;
  width: 40px;
`

export interface IDirectoryMapProps {
  apiKey?: string
  zoom: number
  searchAsIMove: boolean
  setLocation: (l: Location) => void
  location: any
  setSearchAsIMove: Function
  otherKeys?: any
  onGoogleApiLoaded?: Function
  refreshUserLocation: MouseEventHandler<HTMLDivElement>
  userMarkerLocation?: Location
  [x: string]: any
}

export const DirectoryMap: React.FC<IDirectoryMapProps> = ({
  apiKey,
  zoom,
  children,
  setLocation,
  location,
  otherKeys,
  onGoogleApiLoaded,
  setSearchAsIMove,
  searchAsIMove,
  refreshUserLocation,
  userMarkerLocation,
  ...p
}) => {
  const [showMarkers, setShowMarkers] = useState(true)

  const onBoundsChange = ({ center, zoom, bounds }) => {
    setShowMarkers(zoom >= minZoomToShowMarkers)

    setLocation({ ...center, bounds })
  }

  const onMapLoad = (map, maps) => {
    map.setOptions({
      styles
    })
  }

  const createMapOptions = (maps) => {
    const mapConfig = {
      disableDoubleClickZoom: true
    }

    return mapConfig
  }

  return apiKey ? (
    <MapContainer {...p}>
      <LocationControl className="shadow-sm" onClick={refreshUserLocation}>
        <LocationIcon className="icon icon-location" />
      </LocationControl>
      <SearchAsIMoveControl className="shadow-sm">
        <input
          checked={searchAsIMove}
          onChange={(e) => setSearchAsIMove(e.target.checked)}
          type="checkbox"
          style={{ marginRight: '7px' }}
        />
        <p>Search as I move the map</p>
      </SearchAsIMoveControl>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey, ...otherKeys }}
        options={createMapOptions}
        center={location as any}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          onMapLoad(map, maps)
          if (onGoogleApiLoaded) {
            onGoogleApiLoaded()
          }
        }}
        onChange={onBoundsChange}
      >
        {showMarkers && children}
        {userMarkerLocation && (
          <LocationMarker
            lat={userMarkerLocation?.lat}
            lng={userMarkerLocation?.lng}
          />
        )}
      </GoogleMapReact>
    </MapContainer>
  ) : null
}

export default DirectoryMap
