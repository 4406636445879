import { Row } from 'react-bootstrap'
import { Button } from '../Button'
import styled from 'styled-components'

export const GhostBtn = styled(Button)`
  border-color: var(--card-border);
`
export const TitleRow = styled(Row)`
  margin-bottom: 1.25rem;
`
export const BankAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid var(--card-border);
`
