import React from 'react'
import { currencyFormatter, DateHandler } from '@angle/utils'
import { RowButtons } from './RowButtons'
import { TableDataLight } from '../style'

export const DisclosureTableBody: React.FC<{
  disclosures: any[]
  isLoading: boolean
  onEdit: Function
  onDelete: Function
}> = ({ disclosures, onEdit, onDelete, isLoading }) => {
  if (disclosures?.length) {
    return (
      <tbody>
        {disclosures?.map((row: any) => {
          return (
            <tr key={row.id}>
              <td className="font-weight-bold">{row.risk_identifier_name}</td>
              <TableDataLight>
                {DateHandler.formatForClient(row.date_of_birth)}
              </TableDataLight>
              <TableDataLight className="text-capitalize">
                {row.sex}
              </TableDataLight>
              <TableDataLight className="text-capitalize">
                {row.member_type}
              </TableDataLight>
              <TableDataLight className="text-capitalize">
                <div className="text-left" style={{ width: 175 }}>
                  {row.status}
                </div>
              </TableDataLight>
              <TableDataLight>
                {DateHandler.formatForClient(row.term_date) || '-'}
              </TableDataLight>
              <TableDataLight>{row.diagnosis}</TableDataLight>
              <TableDataLight>
                <div className="text-left" style={{ width: 140 }}>
                  {DateHandler.formatForClient(row.latest_service_date)}
                </div>
              </TableDataLight>
              <TableDataLight>
                <div className="text-left" style={{ width: 160 }}>
                  {currencyFormatter.format(row.plan_year_expenses)}
                </div>
              </TableDataLight>
              <td>
                <RowButtons
                  onEdit={() => onEdit(row)}
                  onDelete={() => onDelete(row.disclosure_id)}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  } else return <EmptyTableBody />
}

const EmptyTableBody = () => {
  return (
    <tbody>
      <tr>
        <td className="p-5" colSpan={4}></td>
        <td>
          <div style={{ width: 175 }}></div>
        </td>
        <td colSpan={2}></td>
        <td>
          <div style={{ width: 140 }}></div>
        </td>
        <td>
          <div style={{ width: 160 }}></div>
        </td>
        <td></td>
      </tr>
    </tbody>
  )
}
