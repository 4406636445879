import { useState, useMemo } from 'react'

import { useQuery, UseQueryOptions } from 'react-query'

export interface IUsePaginationOptions {
  initialPage: number
  showMax: number
  buttonFontSize: number
}

export interface IUsePaginationServerOption
  extends Omit<IUsePaginationOptions, 'buttonFontSize'> {
  queryOpts?: UseQueryOptions<any, any, any, any>
}

type IServerPaginationProps = {
  count: number

  total_count?: number
  page_limit?: number
  page_number?: number
  num_pages?: number

  totalCount?: number
  pageLimit?: number
  pageNumber?: number
  numPages?: number
}

export function useServerPagination<T extends Promise<unknown>>(
  key: any[],
  queryFun: (props: {
    paginationQuery: string
    pageNumber: number
    pageLimit: number
  }) => T,
  opts?: Partial<IUsePaginationServerOption>
) {
  const [pageNumber, setPageNumber] = useState(opts?.initialPage || 1)
  const [pageLimit, setPageLimit] = useState(opts?.showMax || 10)

  const nextPage = () => {
    setPageNumber((s) => s + 1)
  }

  const previousPage = () => {
    setPageNumber((s) => (s > 1 ? s - 1 : s))
  }

  const paginationQuery = `?page_number=${pageNumber}&page_limit=${pageLimit}`

  const queryKey = key.concat(paginationQuery)

  const queryResult = useQuery<Awaited<T> & IServerPaginationProps>(
    queryKey,
    () =>
      queryFun({ paginationQuery, pageNumber, pageLimit }) as Awaited<T> &
        IServerPaginationProps,
    opts?.queryOpts
  )
  const totalItems = useMemo(
    () => queryResult.data?.total_count || queryResult.data?.totalCount || 0,
    [queryResult.data]
  )

  const totalPages = useMemo(
    () => Math.ceil(totalItems / pageLimit),
    [totalItems, pageLimit]
  )

  // add pageNumberList here ?
  const paginationConfig = useMemo(
    () => ({
      totalPages: totalPages,
      currentPage: pageNumber,
      setPage: setPageNumber,
      totalItems: totalItems,
      pageLimit: pageLimit,
      setPageLimit
    }),
    [pageLimit, pageNumber, totalItems, totalPages]
  )

  return {
    queryResult,
    setPageNumber,
    setPageLimit,
    nextPage,
    previousPage,
    paginationConfig
  }
}

export function usePagination<T = any>(
  elements: T[],
  elementsPerPage = 10,
  opts: Partial<IUsePaginationOptions> = {}
) {
  const { initialPage = 1, showMax, buttonFontSize } = opts
  const [pageLimit, setPageLimit] = useState(elementsPerPage)

  const numberOfPages = Math.ceil(elements.length / pageLimit)

  const [currentPage, setCurrentPage] = useState(initialPage)

  const setPage = (pageNum: number) => setCurrentPage(pageNum)

  const from = (currentPage - 1) * pageLimit

  const paginationConfig = {
    currentPage,
    showMax: showMax || 5,
    buttonFontSize: buttonFontSize || 16,
    totalPages: numberOfPages,
    setPage,
    setPageLimit,
    totalItems: elements.length,
    pageLimit
  }

  const state = {
    showPagination: elements.length > pageLimit,
    currentElements: elements.slice(from, from + pageLimit),
    paginationConfig
  }

  return state
}
