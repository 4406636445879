import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import {
  Billing,
  BusinessOnboarding,
  DependentProfile,
  DependentRegistration,
  DisenrollEmployee,
  DocumentsAndForms,
  EditEmployee,
  EmployeeProfile,
  EmployeeRegistration,
  Employees,
  ForgotPassword,
  Login,
  MakePayment,
  ManuallyAddBankAccount,
  NotFound,
  OnboardingComplete,
  ResetPassword,
  Settings,
  SignUp,
  Summary,
  TemporaryLogin,
  TempAuth,
  ReportingPage
} from '../pages'
import { BrokerForm, TempAuthRedirect } from '../pages/Broker'
import Claims from '../pages/Claims'
import EditDependent from '../pages/Dependents/EditDependent'
import { EmployeeProfileIdCard } from '../pages/EmployeeProfile/EmployeeProfileIdCard'
import { OnboardingStatus } from './OnboardingStatus'
import { Paths } from './paths'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'

export const Router = () => (
  <Switch>
    <PublicRoute path={Paths.brokerForm + '/auth'} component={BrokerForm} />
    <PublicRoute path={Paths.brokerForm} component={TempAuthRedirect} />
    <PublicRoute path={Paths.superuser} component={TempAuth} />
    <PublicRoute path={Paths.forgotPassword} component={ForgotPassword} />
    <PublicRoute path={Paths.resetPassword} component={ResetPassword} />
    <PublicRoute path={Paths.login} component={Login} />
    <PublicRoute path={Paths.temporaryLogin} component={TemporaryLogin} />
    <PrivateRoute path={Paths.summary} component={Summary} />
    <PrivateRoute path={Paths.claims} component={Claims} />
    <PrivateRoute path={Paths.billing} component={Billing} />
    <PrivateRoute path={Paths.reporting} component={ReportingPage} />
    <PrivateRoute path={Paths.makePayment} component={MakePayment} />
    <PrivateRoute
      path={Paths.newBusinessOnboardingComplete}
      component={OnboardingComplete}
      onboardingStep={OnboardingStatus.underReview}
    />
    <PrivateRoute
      path={Paths.addManualAccount}
      component={ManuallyAddBankAccount}
    />
    <PrivateRoute path={Paths.settings} component={Settings} />
    <PrivateRoute
      path={Paths.newDependentRegistration}
      component={DependentRegistration}
    />
    <PrivateRoute
      path={Paths.newEmployeeRegistration}
      component={EmployeeRegistration}
    />
    <PrivateRoute
      path={Paths.newBusinessOnboarding}
      component={BusinessOnboarding}
      onboardingStep={OnboardingStatus.inProgress}
    />

    <PrivateRoute
      path={Paths.newBusinessRegistration}
      component={SignUp}
      onboardingStep={OnboardingStatus.passwordNotChanged}
    />
    <PrivateRoute
      path={Paths.disenrollEmployee}
      component={DisenrollEmployee}
    />
    <PrivateRoute
      exact
      path={Paths.dependentProfileIdCard}
      component={EmployeeProfileIdCard}
    />
    <PrivateRoute
      exact
      path={Paths.dependentProfile}
      component={DependentProfile}
    />
    <PrivateRoute
      exact
      path={Paths.editDependentProfile}
      component={EditDependent}
    />
    <PrivateRoute
      path={Paths.employeeProfileIdCard}
      component={EmployeeProfileIdCard}
    />
    <PrivateRoute path={Paths.editEmployeeProfile} component={EditEmployee} />
    <PrivateRoute path={Paths.employeeProfile} component={EmployeeProfile} />
    <PrivateRoute path={Paths.employees} component={Employees} />
    <PrivateRoute exact path={Paths.main} component={Summary} />
    <PrivateRoute
      path={Paths.documentsAndForms}
      component={DocumentsAndForms}
    />
    <PublicRoute exact path={Paths.main} component={Login} />
    <PublicRoute exact path="/broker" component={BrokerForm} />
    <PrivateRoute path={Paths.notFound} component={NotFound} />

    <PrivateRoute
      exact
      path="/onboarding"
      render={() => <Redirect to={Paths.newBusinessOnboarding} />}
    />
    <PrivateRoute component={NotFound} notFound={true} />
    <PublicRoute component={NotFound} />
  </Switch>
)
