import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button, Row, Form, Col, Modal } from '../../index'
import { Controller, useFieldArray } from 'react-hook-form'
import { limitLength } from '@angle/utils'
import ReactInputMask from 'react-input-mask'

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.375rem;
  border-top: 1px solid var(--card-border);
`

const GhostBtn = styled(Button)`
  border-color: var(--card-border);
`

const ValidationError = styled.p`
  color: var(--primary);
  margin-top: 5px;
`
const TermsAndConditionsContainer = styled.div`
  overflow-y: auto;
  height: 300px;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--gray-600);
  padding: 8px;
`

export type ManuallyAddBankAccountFormData = {
  firstName: string
  lastName: string
  name: string
  accountNumber: string
  routingNumber: string
  accountType: string
  acceptedTermsAndConditions: boolean
}
export interface ManuallyAddBankAccountFormProps {
  onAddBankAccount?: () => void
  onCancelButtonClick?: () => void
  register: any
  isUpdating?: boolean
  watch?: any
  formState?: any
  control?: any
}

const getRequiredObject = (label: string) => ({
  value: true,
  message: `${label} is required`
})

const labels = {
  name: 'Account Holder Name',
  accountType: 'Account Type',
  routingNumber: 'Routing Number',
  accountNumber: 'Account Number'
}

export const ManuallyAddBankAccountForm: React.FC<
  ManuallyAddBankAccountFormProps
> = ({
  register,
  onCancelButtonClick = () => {},
  onAddBankAccount = () => {},
  isUpdating,
  formState,
  control
}) => {
  const [show, setShow] = useState(false)

  return (
    <Form>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={show}
        onHide={() => setShow(false)}
        style={{ zIndex: 99999 }}
      >
        <Modal.Header>
          <Modal.Title>Terms and Conditions for ACH Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsAndConditionsContainer>
            <p>
              Welcome to the terms and conditions (the “Terms and Conditions”)
              for use of a bank account (“Authorized Bank Account”) registered
              with Angle Health , Inc. (hereinafter referred to as “We”, “Us” or
              “Our”) as a payment method on the Angle Health website. Please
              note that your use of the Angle Health website (anglehealth.com)
              is also governed by Our Terms of Service, Privacy Policy and
              Acceptable Use Policy, which may be amended from time to time and
              are incorporated into these Terms and Conditions. If the Group
              Health Plan contract holder (hereinafter referred to as “You” or
              “Your”) chooses to use an Authorized Bank Account as Your payment
              method, You accept and agree to these Terms and Conditions.
            </p>

            <h3>1. Bank Account Payments</h3>
            <p>
              By choosing to use an Authorized Bank Account as Your payment
              method, You will be able to pay the fees due for Your use of Our
              Services by using any valid automated clearing house (“ACH”)
              enabled bank account at a United States-based financial
              institution. Whenever You choose to pay by ACH, You are
              authorizing Angle Health (or its agent) to debit your Authorized
              Bank Account for the total amount of the fees due. If You choose
              to provide Your Authorized Bank Account credentials to Us, You
              authorize Us to use this information to facilitate debiting Your
              Authorized Bank Account. Your transaction must be payable in U.S.
              dollars. Angle Health, in its sole discretion, may refuse this
              payment option service to anyone or any user without notice for
              any reason at any time. Transactions that We process using Your
              Authorized Bank Account will be identified as “Angle Health” (or
              similar identifier) on the statement issued by Your bank or other
              financial institution holding Your account. All questions relating
              to any transactions made using Your Authorized Bank Account by Us
              should be initially directed to Us, but may also require
              involvement of Your bank.
            </p>

            <h3>2. ACH Authorization</h3>
            <p>
              By choosing Your Authorized Bank Account as Your payment method,
              You agree that: (a) You have read, understand and agree to these
              Terms and Conditions, and that this agreement constitutes a
              “writing signed by You” under any applicable law or regulation,
              (b) You consent to the electronic delivery of the disclosures
              contained in these Terms and Conditions, (c) You authorize Angle
              Health (or its agent) to make any inquiries We consider necessary
              to validate any dispute involving Your payment, which may include
              ordering a credit report and performing other credit checks or
              verifying the information You provide against third party
              databases, and (d) You authorize Angle Health (or its agent) to
              initiate one or more ACH debit entries (withdrawals), and You
              authorize the financial institution that holds Your Authorized
              Bank Account to deduct such payments, in the amounts and frequency
              designated in Your then-current payment plan.
            </p>

            <h3>3. Transaction Errors</h3>
            <p>
              If You believe that any payment transaction initiated by Angle
              Health (or its agent) with respect to Your Authorized Bank Account
              is erroneous, or if You need more information about any such
              transaction, You should contact Us as soon as possible as at
              support@anglehealth.com. Notify Us at once if You believe the
              password associated with Your account has been lost or stolen, or
              if someone has attempted (or may attempt) to make a transfer from
              Your Authorized Bank Account without Your permission. We reserve
              the right to cancel the ability to pay by Authorized Bank Account
              for any reason at any time.
            </p>

            <h3>4. Your Liability for Unauthorized Transactions</h3>
            <p>
              Federal law limits Your liability for any fraudulent, erroneous
              unauthorized transaction from Your Authorized Bank Account based
              on how quickly You report it to Your financial institution. As a
              general rule, You should report any fraudulent, erroneous or
              unauthorized transactions to Your bank within 60 days after the
              questionable transaction FIRST appeared on Your Authorized Bank
              Account statement. You should contact Your bank for more
              information about the policies and procedures that apply to Your
              account and any unauthorized transactions, including any limits on
              Your liability.
            </p>

            <h3>5. Electronic Delivery of Future Disclosures</h3>
            <p>
              You agree to accept all disclosures and other communications
              between You and Us on this website or at the primary email address
              associated with Your Angle Health account. You should print and
              retain a copy of all such disclosures and communications.
            </p>

            <h3>6. Agreement Changes</h3>
            <p>
              We may, at Our discretion, change these Terms and Conditions at
              any time without notice to You. If any change is found to be
              invalid, void, or for any reason unenforceable, that change is
              severable and does not affect the validity and enforceability of
              any other changes or the remainder of these Terms and Conditions.
              We reserve the right to subcontract any of Our rights or
              obligations under these Terms and Conditions. YOUR CONTINUED USE
              OF YOUR AUTHORIZED BANK ACCOUNT AS A PAYMENT METHOD ON
              ANGLEHEALTH.COM AFTER WE CHANGE THESE TERMS AND CONDITIONS
              CONSTITUTES YOUR ACCEPTANCE OF THESE CHANGES.
            </p>
          </TermsAndConditionsContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)}>OK</Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col xs={12} md={12}>
          <Form.Group>
            <Form.Label className="required-field">{labels.name}</Form.Label>
            <Form.Control
              type="text"
              {...register('name', {
                required: getRequiredObject(labels.name),
                validate: (value: string) =>
                String(value)?.length < 23 ||
                'Name must be less than 23 digits'  
              })}
              maxLength={22}
              minLength={1}
              placeholder="Account Holder Name"
              style={{ backgroundColor: '#f8fafd' }}
              isInvalid={Boolean(formState.errors?.name)}
            />
            <ValidationError>
              {formState.isSubmitted && formState.errors?.name?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.accountType}
            </Form.Label>
            <Form.Control
              {...register('accountType', {
                required: getRequiredObject(labels.accountType)
              })}
              as="select"
              isInvalid={Boolean(formState.errors?.accountType)}
            >
              <option value="" disabled hidden>
                Select the account type
              </option>
              <option value="checking">Checking</option>
              <option value="savings">Savings</option>
              <option value="business_checking">Business Checking</option>
              <option value="business_savings">Business Savings</option>
            </Form.Control>
            <ValidationError>
              {formState.isSubmitted && formState.errors?.accountType?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}></Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.routingNumber}
            </Form.Label>
            <Form.Control
              type="text"
              {...register('routingNumber', {
                required: getRequiredObject(labels.routingNumber),
                validate: (value: number) =>
                  String(value)?.length === 9 ||
                  'Routing number must be 9 digits'
              })}
              maxLength={9}
              minLength={9}
              placeholder="Routing Number"
              isInvalid={Boolean(formState.errors?.routingNumber)}
              as={ReactInputMask}
              mask="999999999"
              {...{ maskChar: null }}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.routingNumber?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.accountNumber}
            </Form.Label>
            <Form.Control
              type="number"
              {...register('accountNumber', {
                required: getRequiredObject(labels.accountNumber)
              })}
              placeholder="Account Number"
              isInvalid={Boolean(formState.errors?.accountNumber)}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.accountNumber?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
      {!isUpdating && (
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                {...register('acceptedTermsAndConditions', {
                  required: {
                    value: true,
                    message: 'Must accept terms and conditions'
                  }
                })}
                label={
                  <>
                    I have read the{' '}
                    <Link to="#" onClick={() => setShow(true)}>
                      terms and conditions
                    </Link>{' '}
                    for payment by ACH, and hereby authorize Angle to deduct
                    payment from my bank account by ACH.
                  </>
                }
              />
              <ValidationError style={{ paddingLeft: 19 }}>
                {formState.isSubmitted &&
                  formState.errors?.acceptedTermsAndConditions?.message}
              </ValidationError>
            </Form.Group>
          </Col>
        </Row>
      )}
      <ActionContainer className="d-flex justify-content-between align-items-center mt-5">
        <GhostBtn ghost variant="primary" onClick={onCancelButtonClick}>
          Cancel
        </GhostBtn>
        <Button variant="primary" onClick={onAddBankAccount}>
          {isUpdating ? 'Update' : 'Add'} bank account
        </Button>
      </ActionContainer>
    </Form>
  )
}
