import { IGetSettings, ISetSettings } from '../../interfaces'
import {
  AchAccountPayload,
  AchPayment,
  PaginateResponse,
  PlaidAccountPayload,
  PlaidPayment,
  StatementDTO,
  TransactionDTO
} from '../../interfaces/billing'
import { system } from '@angle/utils'
import { ApiService } from './base'

export class PaymentsService extends ApiService {
  async getAccounts(groupId?: string): Promise<{ accounts: any[] }> {
    return this.request({
      method: 'get',
      url: `${groupId ? `/groups/${groupId}` : ''}/payments/accounts`,
      credentials: true
    })
  }

  async getTransactions(
    pageNumber: number,
    pageLimit: number = 5,
    groupId?: string
  ): Promise<PaginateResponse<TransactionDTO[], 'transactions'>> {
    return this.request({
      method: 'get',
      url: `${
        groupId ? `/groups/${groupId}` : ''
      }/payments/transactions?page_limit=${pageLimit}&page_number=${pageNumber}`,
      credentials: true
    })
  }

  async getStatements(
    pageNumber: number,
    pageLimit: number = 5,
    groupId?: string
  ): Promise<PaginateResponse<StatementDTO[], 'statements'>> {
    return this.request({
      method: 'get',
      url: `${
        groupId ? `/groups/${groupId}` : ''
      }/payments/statements?page_limit=${pageLimit}&page_number=${pageNumber}`,
      credentials: true
    })
  }

  async getStatementUrl(statementId: string): Promise<{ url: string }> {
    return this.request({
      method: 'get',
      url: `/payments/statements/${statementId}/url`,
      credentials: true
    })
  }

  async getStatement(statementId: string): Promise<{ url: string }> {
    return this.request({
      method: 'get',
      url: `/payments/statements/${statementId}/url`,
      credentials: true
    })
  }

  async makePlaidPayment(paymentData: PlaidPayment) {
    return this.request({
      method: 'post',
      url: '/payments/plaid',
      credentials: true,
      data: paymentData
    })
  }

  async makeAchPayment(paymentData: AchPayment) {
    return this.request({
      method: 'post',
      url: '/payments/ach',
      credentials: true,
      data: paymentData
    })
  }

  async setAccountAsDefault({
    account_id,
    account_type
  }: {
    account_id: number | string
    account_type: string
  }) {
    return this.request({
      method: 'put',
      url: '/payments/accounts/default',
      data: { account_id, account_type },
      credentials: true
    })
  }

  addAchAccount(data: AchAccountPayload) {
    const systemInfo = system.getInfo()

    const body = {
      ...data,
      text:
        (data as any).text ||
        'I have read the terms and conditions for payment by ACH, and hereby authorize Angle to deduct payment from my bank account',
      device_info: `${systemInfo.os.name} ${systemInfo.os.version}`,
      client_info: `${systemInfo.browser.name}/${systemInfo.browser.version}`,
      time: (data as any).time || new Date().toISOString()
    }

    return this.request({
      method: 'post',
      url: '/payments/accounts/ach ',
      credentials: true,
      data: body
    })
  }

  addCheckAccount() {
    const systemInfo = system.getInfo()

    const body = {
      text: 'I have read the terms and conditions for payment by ACH, and hereby authorize Angle to deduct payment from my bank account',
      device_info: `${systemInfo.os.name} ${systemInfo.os.version}`,
      client_info: `${systemInfo.browser.name}/${systemInfo.browser.version}`,
      time: new Date().toISOString()
    }

    return this.request({
      method: 'post',
      url: '/payments/accounts/check',
      credentials: true,
      data: body
    })
  }

  async addPlaidAccounts(publicToken: string, data: PlaidAccountPayload[]) {
    const systemInfo = system.getInfo()

    const body = {
      metadata: {
        public_token: publicToken,
        institution: {
          name: data[0].institutionName
        },
        accounts: data.map((acc) => ({
          plaid_account_id: acc.id,
          account_name: acc.name,
          mask: acc.mask,
          name: acc.owner
        }))
      },
      text: 'I have read the terms and conditions for payment by ACH, and hereby authorize Angle to deduct payment from my bank account',
      device_info: `${systemInfo.os.name} ${systemInfo.os.version}`,
      client_info: `${systemInfo.browser.name}/${systemInfo.browser.version}`,
      time: new Date().toISOString()
    }

    return this.request({
      method: 'post',
      url: '/payments/accounts/plaid',
      credentials: true,
      data: body
    })
  }

  removePlaidAccount(accoundId: string) {
    return this.request({
      method: 'delete',
      url: `/payments/accounts/plaid/${accoundId}`,
      credentials: true
    })
  }

  removeAchAccount(accoundId: string) {
    return this.request({
      method: 'delete',
      url: `/payments/accounts/ach/${accoundId}`,
      credentials: true
    })
  }

  removeCheckAccount(accoundId: string) {
    return this.request({
      method: 'delete',
      url: `/payments/accounts/check/${accoundId}`,
      credentials: true
    })
  }

  async getSettings(): Promise<IGetSettings> {
    return this.request({
      method: 'get',
      url: '/payments/settings',
      credentials: true
    })
  }

  async setSettings(data: ISetSettings): Promise<any> {
    return this.request({
      method: 'patch',
      url: '/payments/settings',
      credentials: true,
      data
    })
  }

  async maskPlaidAsUpdated(accountId: string) {
    return this.request({
      method: 'post',
      url: `/payments/accounts/plaid/${accountId}/updated`,
      credentials: true
    })
  }
}
