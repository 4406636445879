import moment from 'moment'

type DateInput = moment.MomentInput | DateManager

export class DateHandler {
  static use(date: string | Date) {
    return new DateManager(date)
  }

  static formatForServer(date: DateInput): string {
    if (date instanceof DateManager) {
      return date.format('YYYY-MM-DD')
    }

    const formattedDate = moment(date).format('YYYY-MM-DD')
    if (formattedDate === 'Invalid date') return null

    return formattedDate
  }

  static formatForClient(date: DateInput): string {
    if (!date) return ''

    if (date instanceof DateManager) {
      return date.format('MM/DD/YYYY')
    }

    const formattedDate = moment(date).format('MM/DD/YYYY')

    if (formattedDate === 'Invalid date') return ''
    return formattedDate
  }

  static formatFullDate(date: DateInput) {
    if (date instanceof DateManager) {
      return date.format('MMMM Do, YYYY')
    }

    const formattedDate = moment(date).format('MMMM Do, YYYY')
    if (formattedDate === 'Invalid date') return ''
    return formattedDate
  }

  static formatFullDatetime(date: DateInput) {
    if (date instanceof DateManager) {
      return date.format('h:mm A • MMM Do, YYYY')
    }

    const formattedDate = moment(date).format('h:mm A • MMM Do, YYYY')
    if (formattedDate === 'Invalid date') return ''
    return formattedDate
  }

  static formatTime(date: DateInput) {
    if (date instanceof DateManager) {
      return date.format('h:mm A')
    }

    const formattedDate = moment(date).format('h:mm A')
    if (formattedDate === 'Invalid date') return ''
    return formattedDate
  }

  static startOf(unitOfTime: moment.unitOfTime.StartOf) {
    return moment().startOf(unitOfTime)
  }

  static get(unit: moment.unitOfTime.All) {
    return moment().get(unit)
  }
}

class DateManager {
  private _momentDate: moment.Moment

  constructor(date?: Date | string) {
    this._momentDate = moment(date)
  }

  startOf(unitOfTime: moment.unitOfTime.StartOf) {
    return this._momentDate.startOf(unitOfTime)
  }

  endOf(unitOfTime: moment.unitOfTime.StartOf) {
    return this._momentDate.endOf(unitOfTime)
  }

  get(unit: moment.unitOfTime.All) {
    return this._momentDate.get(unit)
  }

  isBefore(date: moment.MomentInput) {
    return this._momentDate.isBefore(date)
  }

  isSameOrAfter(date: moment.MomentInput) {
    return this._momentDate.isSameOrAfter(date)
  }

  format(format: string) {
    return this._momentDate.format(format)
  }

  isSame(inp?: moment.MomentInput, granularity?: moment.unitOfTime.StartOf) {
    return this._momentDate.isSame(inp, granularity)
  }

  isSameOrBefore(
    inp?: moment.MomentInput,
    granularity?: moment.unitOfTime.StartOf
  ) {
    return this._momentDate.isSameOrBefore(inp, granularity)
  }

  add(
    amount?: moment.DurationInputArg1,
    unit?: moment.unitOfTime.DurationConstructor
  ) {
    return this._momentDate.add(amount, unit)
  }

  subtract(
    amount?: moment.DurationInputArg1,
    unit?: moment.unitOfTime.DurationConstructor
  ) {
    return this._momentDate.subtract(amount, unit)
  }
}
