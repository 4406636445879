(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-hook-form"), require("react-query"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define("AngleHooks", ["react", "react-hook-form", "react-query", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["AngleHooks"] = factory(require("react"), require("react-hook-form"), require("react-query"), require("react-router-dom"));
	else
		root["AngleHooks"] = factory(root["react"], root["react-hook-form"], root["react-query"], root["react-router-dom"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__369__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__128__) => {
return 