import { Card } from 'react-bootstrap'
import styled from 'styled-components'

export const SelectionCard = styled(Card)`
  width: 300px;
  padding: 16px;
  margin-right: 20px;
`

export const ReportCardTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* or 120% */
  display: flex;
  align-items: center;

  /* Text/01. Body */
  color: #212529;
`

export const ReportCardDescription = styled.p`
  color: var(--text-02-muted, #6c757d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`

export const TableDescription = styled.p`
  color: var(--text-02-muted, #6c757d);
  /* Body/Paragraph */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`

export const DisclaimerText = styled.p`
  color: var(--global-02-secondary, #6c757d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
