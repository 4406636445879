import { useContext, useEffect } from 'react'
import { AuthContext } from '../authentication'

export const useAuth = () => useContext(AuthContext)

export const useRememberAccount = (
  setValue: Function,
  watch: Function,
  { email, storage, remember } = {
    email: 'email',
    storage: 'saved_email',
    remember: 'remember'
  }
) => {
  const rememberValue = watch(remember)
  const currentEmail = watch(email)

  useEffect(() => {
    const savedEmail = localStorage.getItem(storage)
    if (savedEmail) {
      setValue(email as any, savedEmail)
      setValue(remember as any, true)
    }
  }, [setValue])

  useEffect(() => {
    if (rememberValue) localStorage.setItem(storage, currentEmail)
    if (!rememberValue) localStorage.removeItem(storage)
  }, [rememberValue, currentEmail])
}
