import React from 'react'
import { Col, Row } from 'react-bootstrap'

export const CignaFooter = () => {
  return (
    <Row style={{ paddingBottom: 0, marginBottom: 0 }}>
      <Col
        className="text-right d-flex align-items-end pr-0"
        xs={7}
        style={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <span className="font-weight-bold fs-12 pb-2">S</span>
        <p className="fs-10 pt-2">
          Find your Angle Health Cigna PPO provider at{' '}
          <span className="font-weight-bold">www.myCigna.com</span>
        </p>
      </Col>
      <Col style={{ textAlign: 'right' }}>
        <div>
          <img
            src="/cigna.png"
            style={{ width: 100, marginTop: 5 }}
            alt="cigna-logo"
          />
        </div>
        <p
          className="font-weight-bold fs-10"
          style={{ marginTop: -2, marginBottom: 0 }}
        >
          Cigna PPO
        </p>
      </Col>
    </Row>
  )
}
