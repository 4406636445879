import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import {
  Button,
  CenteredSpinner,
  Col,
  Form,
  NewHireWaitingPeriodChoices,
  PageContainer,
  Row,
  SectionTitle,
  useToaster
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import { CompanySettingsForm } from './interfaces'
import { useQuery } from 'react-query'
import { groupService } from '../../../services'
import { states } from '@angle/utils'

export const CompanySettings = () => {
  const toast = useToaster()
  const [isSaving, setIsSaving] = useState(false)

  const { control, formState, handleSubmit, setValue } =
    useForm<CompanySettingsForm>({
      mode: 'onChange'
    })

  const { data, isLoading } = useQuery(['companySettings'], () => {
    return groupService.getInfo()
  })

  useEffect(() => {
    if (!isLoading && data) {
      setValue('adminContact', {
        firstName: data.admin_contact_name.first_name,
        lastName: data.admin_contact_name.last_name,
        title: data.admin_contact_name.title,
        phone: data.admin_contact_info.work_phone,
        email: data.admin_contact_info.work_email
      })

      setValue('mailingAddress', {
        address1: data.mailing_address.street_1,
        address2: data.mailing_address.street_2,
        state: data.mailing_address.state,
        city: data.mailing_address.city,
        zip: data.mailing_address.zip
      })

      setValue('billingContact', {
        firstName: data.billing_contact_name.first_name,
        lastName: data.billing_contact_name.last_name,
        title: data.billing_contact_name.title,
        phone: data.billing_contact_info.work_phone,
        email: data.billing_contact_info.work_email
      })

      setValue('businessAddress', {
        address1: data.business_address.street_1,
        address2: data.business_address.street_2,

        state: data.business_address.state,
        city: data.business_address.city,
        zip: data.business_address.zip
      })

      setValue('newHireWaitingPeriod', data.new_hire_waiting_period_update)
    }
  }, [setValue, data, isLoading])

  const onChangesSuccess = () => {
    setIsSaving(false)
    toast.show({
      message: 'Updated sucessfully',
      icon: 'icon-correct',
      type: 'success'
    })
  }

  const onChangesFail = (error: any) => {
    setIsSaving(true)
    toast.show({
      message: `Something went wrong: ${error.message}`,
      icon: 'icon-cancel',
      type: 'danger'
    })
  }

  const hasError = Object.keys(formState.errors || {}).length

  const getError = (field: keyof CompanySettingsForm): any | undefined =>
    formState.errors[field]

  const submitForm = handleSubmit((data) => {
    setIsSaving(true)

    const requestBody = {
      business_address: {
        city: data.businessAddress.city,
        state: data.businessAddress.state,
        street_1: data.businessAddress.address1,
        street_2: data.businessAddress.address2 || '',
        zip: data.businessAddress.zip
      },
      mailing_address: {
        city: data.mailingAddress.city,
        state: data.mailingAddress.state,
        street_1: data.mailingAddress.address1,
        street_2: data.mailingAddress.address2 || '',
        zip: data.mailingAddress.zip
      },
      billing_contact_name: {
        first_name: data.billingContact.firstName,
        last_name: data.billingContact.lastName,
        title: data.billingContact.title
      },
      billing_contact_info: {
        work_phone: data.billingContact.phone,
        work_email: data.billingContact.email
      },
      admin_contact_name: {
        first_name: data.adminContact.firstName,
        last_name: data.adminContact.lastName,
        title: data.adminContact.title
      },
      admin_contact_info: {
        work_phone: data.adminContact.phone,
        work_email: data.adminContact.email
      }
    }

    groupService
      .modifyGroup(requestBody)
      .then(onChangesSuccess)
      .catch(onChangesFail)
  })

  const errorMessage = hasError ? (
    <span className="text-danger">Please fill the required fields</span>
  ) : null

  const bizAddressErrors = getError('businessAddress')
  const mailAddressErrors = getError('mailingAddress')
  const adminErrors = getError('adminContact')
  const billingErrors = getError('billingContact')

  if (isLoading) return <CenteredSpinner></CenteredSpinner>

  return (
    <PageContainer size="md">
      <SectionTitle>Business Address</SectionTitle>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': bizAddressErrors?.address1
                })}
              >
                Address
              </Form.Label>
              <Controller
                name="businessAddress.address1"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    className="bg-white"
                    placeholder="Address"
                    isInvalid={bizAddressErrors?.address1}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            {' '}
            <Form.Label
              className={classNames({
                'text-danger': bizAddressErrors?.address2
              })}
            >
              Address Line 2
            </Form.Label>
            <Controller
              name="businessAddress.address2"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  className="bg-white"
                  placeholder="Address Line 2"
                  isInvalid={bizAddressErrors?.address2}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': bizAddressErrors?.state
                })}
              >
                State
              </Form.Label>
              <Controller
                name="businessAddress.state"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    value={field.value || ''}
                    as="select"
                    placeholder="Select your state"
                    className={'bg-white'}
                    isInvalid={bizAddressErrors?.state}
                  >
                    {Object.keys(states).map((k) => (
                      <option key={k} value={k}>
                        {states[k]}
                      </option>
                    ))}
                  </Form.Control>
                )}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': bizAddressErrors?.city
                })}
              >
                City
              </Form.Label>
              <Controller
                name="businessAddress.city"
                rules={{ required: true }}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    placeholder="City"
                    className={'bg-white'}
                    isInvalid={bizAddressErrors?.city}
                  ></Form.Control>
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': bizAddressErrors?.zip
                })}
              >
                ZIP
              </Form.Label>
              <Controller
                name="businessAddress.zip"
                defaultValue=""
                rules={{ required: true, maxLength: 5 }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    className="bg-white"
                    placeholder="Enter the ZIP code"
                    isInvalid={bizAddressErrors?.zip}
                  />
                )}
              />
              {bizAddressErrors?.zip?.type === 'maxLength' && (
                <Form.Control.Feedback type="invalid">
                  Zip must contain 5 characters
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={6}></Col>
        </Row>
        <hr className="my-5" />
        <>
          <SectionTitle>Mailing Address</SectionTitle>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger': mailAddressErrors?.address1
                  })}
                >
                  Address
                </Form.Label>
                <Controller
                  name="mailingAddress.address1"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      className="bg-white"
                      placeholder="Address"
                      isInvalid={mailAddressErrors?.address1}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger': mailAddressErrors?.address2
                  })}
                >
                  Address Line 2
                </Form.Label>
                <Controller
                  name="mailingAddress.address2"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      className="bg-white"
                      placeholder="Address Line 2"
                      isInvalid={mailAddressErrors?.address2}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger': mailAddressErrors?.state
                  })}
                >
                  State
                </Form.Label>
                <Controller
                  name="mailingAddress.state"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      value={field.value || ''}
                      as="select"
                      placeholder="Select your state"
                      className={'bg-white'}
                      isInvalid={mailAddressErrors?.state}
                    >
                      {Object.keys(states).map((k) => (
                        <option key={k} value={k}>
                          {states[k]}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger': mailAddressErrors?.city
                  })}
                >
                  City
                </Form.Label>
                <Controller
                  name="mailingAddress.city"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      placeholder="City"
                      className={'bg-white'}
                      isInvalid={mailAddressErrors?.city}
                    ></Form.Control>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger': mailAddressErrors?.zip
                  })}
                >
                  ZIP
                </Form.Label>
                <Controller
                  name="mailingAddress.zip"
                  defaultValue=""
                  rules={{ maxLength: 5 }}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      className="bg-white"
                      placeholder="Enter the ZIP code"
                      isInvalid={mailAddressErrors?.zip}
                    />
                  )}
                />
                {mailAddressErrors?.zip?.type === 'maxLength' && (
                  <Form.Control.Feedback type="invalid">
                    Zip must contain 5 characters
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <hr className="my-5" />
        </>

        <SectionTitle>New Hire Waiting Period</SectionTitle>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Wait until covered</Form.Label>
              <Controller
                name="newHireWaitingPeriod"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    as="select"
                    className="bg-white"
                    disabled
                  >
                    {Object.keys(NewHireWaitingPeriodChoices).map((k) => (
                      <option key={k} value={k}>
                        {NewHireWaitingPeriodChoices[k]}
                      </option>
                    ))}
                  </Form.Control>
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <hr className="my-5" />

        <SectionTitle>Membership/Admin Contact</SectionTitle>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': adminErrors?.firstName
                })}
              >
                First name
              </Form.Label>
              <Controller
                name="adminContact.firstName"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    className="bg-white"
                    placeholder="First Name"
                    isInvalid={adminErrors?.firstName}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': adminErrors?.title
                })}
              >
                Title
              </Form.Label>
              <Controller
                name="adminContact.title"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    className="bg-white"
                    placeholder="Enter title"
                    isInvalid={adminErrors?.title}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': adminErrors?.phone
                })}
              >
                Phone
              </Form.Label>
              <Controller
                name="adminContact.phone"
                defaultValue=""
                rules={{ required: true, maxLength: 10 }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="tel"
                    className="bg-white"
                    placeholder="Enter phone number"
                    isInvalid={adminErrors?.phone}
                  />
                )}
              />
              {adminErrors?.phone?.type === 'maxLength' && (
                <Form.Control.Feedback type="invalid">
                  Phone number must contain 10 characters
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': adminErrors?.email
                })}
              >
                Email
              </Form.Label>
              <Controller
                name="adminContact.email"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="email"
                    className="bg-white"
                    placeholder="Enter email"
                    isInvalid={adminErrors?.email}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr className="my-5" />
        <SectionTitle>Billing Contact</SectionTitle>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': billingErrors?.firstName
                })}
              >
                First name
              </Form.Label>
              <Controller
                name="billingContact.firstName"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    className="bg-white"
                    placeholder="First Name"
                    isInvalid={billingErrors?.firstName}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': billingErrors?.title
                })}
              >
                Title
              </Form.Label>
              <Controller
                name="billingContact.title"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    className="bg-white"
                    placeholder="Enter title"
                    isInvalid={billingErrors?.title}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': billingErrors?.phone
                })}
              >
                Phone
              </Form.Label>
              <Controller
                name="billingContact.phone"
                defaultValue=""
                rules={{ maxLength: 10 }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="tel"
                    className="bg-white"
                    placeholder="Enter phone number"
                    isInvalid={billingErrors?.phone}
                  />
                )}
              />
              {billingErrors?.phone?.type === 'maxLength' && (
                <Form.Control.Feedback type="invalid">
                  Phone number must contain 10 characters
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': billingErrors?.email
                })}
              >
                Email
              </Form.Label>
              <Controller
                name="billingContact.email"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="email"
                    className="bg-white"
                    placeholder="Enter email"
                    isInvalid={billingErrors?.email}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr className="my-5" />
        {errorMessage}
        <div className="pt-2 text-right">
          <Button disabled={isSaving} onClick={submitForm}>
            Save changes
          </Button>
        </div>
      </Form>
    </PageContainer>
  )
}

export default CompanySettings
