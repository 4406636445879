import React from 'react'
import { ILabResult, ITableHeaderProps } from '../../interfaces'
import { IPaginationProps } from '../Pagination'
import { Table } from '../Table'

export interface ILabResultsHeader {
  labName: string
  provider: string
  date: string
}

export interface ILabResultsTableProps
  extends React.HTMLAttributes<HTMLTableElement> {
  labResults: ILabResult[]
  headers: ILabResultsHeader
  paginationConfig?: IPaginationProps
  LinkEl: React.FC<any>
  loading?: boolean
  [x: string]: any
}

export const LabResultsTable: React.FC<ILabResultsTableProps> = ({
  labResults,
  headers,
  paginationConfig,
  loading = false,
  LinkEl,
  ...p
}) => {
  const tableHeaders: ITableHeaderProps[] = [
    { title: headers.labName, width: '33%' },
    { title: headers.provider, width: '34%' },
    { title: headers.date, width: '33%' }
  ]
  return (
    <Table
      className="w-100"
      paginationConfig={paginationConfig}
      headers={tableHeaders}
      headerClassName="thead-light"
      {...p}
    >
      <tbody>
        {labResults.map((l: ILabResult) => (
          <tr key={l.id}>
            <td className="d-flex flex-column">
              <span>{l.labName}</span>
              <span className="text-muted">{l.labAddress}</span>
            </td>
            <td>
              <LinkEl to={l.provider.profileLink}>{l.provider.name}</LinkEl>
            </td>
            <td className="text-muted">{l.date}</td>
          </tr>
        ))}
        {labResults.length === 0 && !loading && (
          <tr>
            <td colSpan={tableHeaders.length}>
              <h6 className="text-muted text-center p-5">No lab results</h6>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default LabResultsTable
