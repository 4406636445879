import React from 'react'
import classnames from 'classnames'

export const AvatarStatus = ({ size, variant='primary' }) => {
  const classNames = classnames(
    'avatar-status',
    `avatar-status-${variant}`,
    {
      [`avatar-${size}-status`]: size,
    }
  )

  return <span className={classNames}></span>
}

export default AvatarStatus