import React, { createContext, useContext } from 'react'
import {
  Control,
  UseFormSetValue,
  FormState,
  UseFormTrigger,
  UseFormWatch,
  useForm,
  UseFormGetValues,
  UseFormUnregister
} from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { IDependentForm } from './interfaces'

interface IDependatFormContext {
  control: Control<IDependentForm>
  onSubmit: () => Promise<void>
  setValue: UseFormSetValue<IDependentForm>
  formState: FormState<IDependentForm>
  getErrors: (stage: keyof IDependentForm) => {
    errors: any
    message: JSX.Element | null
  }
  trigger: UseFormTrigger<IDependentForm>
  watch: UseFormWatch<IDependentForm>
  isSpouse: string
  healthInsurance: boolean
  getValues: UseFormGetValues<IDependentForm>
  unregister: UseFormUnregister<IDependentForm>
}

export const DependatFormContext = createContext({} as IDependatFormContext)

export const DependatFormProvider: React.FC<any> = ({ children }) => {
  const history = useHistory()
  const {
    control,
    handleSubmit,
    setValue,
    formState,
    trigger,
    watch,
    getValues,
    unregister
  } = useForm<IDependentForm>({
    defaultValues: {
      personalInfo: {},
      carrierInfo: {}
    },
    mode: 'onChange'
  })

  const onSubmit = handleSubmit(async (data) => {
    const fullName =
      data.personalInfo?.firstName + ' ' + data.personalInfo?.lastName
    history.push(`/employees/1?condition=new-dependent&name=${fullName}`)
  })

  const getErrors = (stage: keyof IDependentForm) => {
    const hasError = Object.keys(formState.errors[stage] || {}).length

    const message = (
      <span className="text-danger">Please fill the required fields</span>
    )

    return {
      message: hasError ? message : null,
      errors: formState.errors[stage]
    }
  }

  const isSpouse = watch('personalInfo.isSpouse')
  const healthInsurance = watch('healthInsurance')

  return (
    <DependatFormContext.Provider
      value={{
        healthInsurance,
        control,
        onSubmit,
        setValue,
        formState,
        getErrors,
        trigger,
        watch,
        isSpouse,
        getValues,
        unregister
      }}
    >
      {children}
    </DependatFormContext.Provider>
  )
}

export const useDependatForm = () => useContext(DependatFormContext)
