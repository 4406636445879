import React from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { Control, Controller } from 'react-hook-form'
import { SectionTitle } from '../SectionTitle'
import { FooterButtons } from './styles'
import { IDependentForm } from './../../interfaces/dependent'

interface DependentConfirmationProps {
  control: Control<IDependentForm>
  onAddDependent: Function
  onBack: Function
  backText?: string
  hasValues?: boolean
}
export const DependentConfirmationForm = ({
  control,
  onAddDependent,
  onBack,
  hasValues,
  backText = 'Back'
}: DependentConfirmationProps) => {
  return (
    <div>
      <SectionTitle>Personal information</SectionTitle>
      <Form>
        <Row className="my-3">
          <Col xs="8">
            <h6>Is this dependent eligible for dental coverage?</h6>
          </Col>
          <Col className="d-flex justify-content-end">
            <Controller
              name={'confirmation.dentalCoverage'}
              control={control}
              defaultValue={false}
              render={({ field }) => {
                return (
                  <React.Fragment>
                    <Form.Switch
                      {...field}
                      style={{ marginRight: '0.25rem' }}
                      id={'confirmation.dentalCoverage'}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      value={undefined}
                    />
                    {field.value ? 'Yes' : 'No'}
                  </React.Fragment>
                )
              }}
            />
          </Col>
        </Row>

        <Row className="my-3">
          <Col xs="8">
            <h6>Is this dependent medicare eligible?</h6>
          </Col>
          <Col className="d-flex justify-content-end">
            <Controller
              name={'confirmation.medicareEligible'}
              control={control}
              defaultValue={false}
              render={({ field }) => {
                return (
                  <React.Fragment>
                    <Form.Switch
                      {...field}
                      style={{ marginRight: '0.25rem' }}
                      id={'confirmation.medicareEligible'}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      value={undefined}
                    />
                    {field.value ? 'Yes' : 'No'}
                  </React.Fragment>
                )
              }}
            />
          </Col>
        </Row>

        <Row className="my-3">
          <Col xs="8">
            <h6>Is this dependent eligible for vision coverage?</h6>
          </Col>
          <Col className="d-flex justify-content-end">
            <Controller
              name={'confirmation.visionCoverage'}
              control={control}
              defaultValue={false}
              render={({ field }) => {
                return (
                  <React.Fragment>
                    <Form.Switch
                      {...field}
                      style={{ marginRight: '0.25rem' }}
                      id={'confirmation.visionCoverage'}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      value={undefined}
                    />
                    {field.value ? 'Yes' : 'No'}
                  </React.Fragment>
                )
              }}
            />
          </Col>
        </Row>

        <Row className="my-3">
          <Col xs="8">
            <h6>Is this dependent eligible for life coverage?</h6>
          </Col>
          <Col className="d-flex justify-content-end">
            <Controller
              name={'confirmation.lifeCoverage'}
              control={control}
              defaultValue={false}
              render={({ field }) => {
                return (
                  <React.Fragment>
                    <Form.Switch
                      {...field}
                      style={{ marginRight: '0.25rem' }}
                      id={'confirmation.lifeCoverage'}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      value={undefined}
                    />
                    {field.value ? 'Yes' : 'No'}
                  </React.Fragment>
                )
              }}
            />
          </Col>
        </Row>

        <Row className="my-3">
          <Col xs="8">
            <h6>Is this dependent eligible for other health coverage?</h6>
          </Col>
          <Col className="d-flex justify-content-end">
            <Controller
              name={'confirmation.otherHealthCoverage'}
              control={control}
              defaultValue={false}
              render={({ field }) => {
                return (
                  <React.Fragment>
                    <Form.Switch
                      {...field}
                      style={{ marginRight: '0.25rem' }}
                      id={'confirmation.otherHealthCoverage'}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      value={undefined}
                    />
                    {field.value ? 'Yes' : 'No'}
                  </React.Fragment>
                )
              }}
            />
          </Col>
        </Row>

        <FooterButtons className="mb-5 d-flex justify-content-end">
          {/* <Button variant="link" onClick={() => onBack()}>
            {backText}
          </Button> */}
          <div>
            <Button
              onClick={() => {
                onAddDependent()
              }}
            >
              {hasValues ? 'Edit' : 'Add'} dependent
            </Button>
          </div>
        </FooterButtons>
      </Form>
    </div>
  )
}

export default DependentConfirmationForm
