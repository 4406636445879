import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  DependentFormType,
  AddDependentTable,
  Card,
  Col,
  PageContainer,
  Row,
  useToaster,
  EmployeeProfile,
  DependentEditingState,
  EightThreeFourService,
  SummaryService,
  PlanService
} from '../../../index'
import {
  AddPlanSelectionForm,
  AddPlanSelectionReturnData
} from './AddPlanSelectionForm'
import {
  EmployeeBasicInfoForm,
  EmployeeBasicInfoFormFields
} from './EmployeeBasicInfoForm'
import {
  EightThreeFourRequest,
  EightThreeFourRequestMemberObject,
  FamilyIndicator
} from '../../../interfaces/employer-broker-shared/eightthreefour'

import greenCheck from '../../../assets/check_green_filled.svg'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'

const CircleActive = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%; /* Make it a circle */
  background-color: #6d39d9; /* Background color */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Text color */
  font-size: 12px; /* Font size of the number */
  font-weight: bold; /* Bold font weight */
`

const CirclePassive = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%; /* Make it a circle */
  background-color: #f8f9fa; /* Background color */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c757d; /* Text color */
  font-size: 12px; /* Font size of the number */
  font-weight: bold; /* Bold font weight */
`

const PageHeader = styled.div`
  align-items: center;
  display: flex;
  height: 10vh;
  font-size: 32px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  max-width: 81.25rem;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
`

const doesEmployeeHaveChildDependents = (
  depArr: DependentFormType[]
): boolean => depArr.some((dependent) => dependent.dependentType === 'child')

const doesEmployeeHaveSpouseDependent = (
  depArr: DependentFormType[]
): boolean => depArr.some((dependent) => dependent.dependentType === 'spouse')

const computeNumChildDependents = (depArr: DependentFormType[]): number => {
  let numChildren = 0
  for (let x = 0; x < depArr.length; x++) {
    if (
      depArr[x].dependentType in
      ['child', 'grandchild', 'adopted_child', 'foster_child', 'stepchild']
    ) {
      numChildren += 1
    }
  }
  return numChildren
}

const genderTranslatorFor843 = (gender: string): string => {
  if (gender.toLowerCase() === 'm' || gender.toLowerCase() === 'f') {
    return gender.toUpperCase()
  }

  if (gender.toLowerCase() === 'male') {
    return 'M'
  }

  if (gender.toLowerCase() === 'female') {
    return 'F'
  }

  return 'U'
}

export const computeFamilyIndicator = (
  depArr: DependentFormType[]
): FamilyIndicator => {
  if (depArr.length === 0) {
    return 'employee'
  }
  if (
    doesEmployeeHaveChildDependents(depArr) &&
    doesEmployeeHaveSpouseDependent(depArr)
  ) {
    return 'family'
  } else if (
    doesEmployeeHaveChildDependents(depArr) &&
    !doesEmployeeHaveSpouseDependent(depArr)
  ) {
    if (computeNumChildDependents(depArr) > 1) {
      return 'employee_children'
    }
    return 'employee_child'
  }
  return 'employee_spouse'
}

export const EmployerPortalAddReinstateEmployee: React.FC<{
  employeeData?: EmployeeProfile | undefined // this is for edit employee or reinstate
  eightThreeFourService: EightThreeFourService
  summaryService: SummaryService
  planService: PlanService
  isBrokerPortal: boolean
  groupId?: string
}> = ({
  employeeData,
  eightThreeFourService,
  summaryService,
  planService,
  isBrokerPortal,
  groupId
}) => {
  const { showSuccess, showError } = useToaster()
  const history = useHistory()

  const [dependentArr, setDependentArr] = useState<DependentFormType[]>([])
  const [employeeBasicInfo, setEmployeeBasicInfo] =
    useState<EmployeeBasicInfoFormFields>()
  const [removalDependentArr, setRemovalDependentArr] = useState<
    DependentFormType[]
  >([])
  const [stepNum, setStepNum] = useState(1)
  const [maxStep, setMaxStep] = useState(1)

  useEffect(() => {
    if (employeeData?.dependents) {
      const tempDependentArr = employeeData.dependents.map((dep) => ({
        city: employeeData.address_object.city,
        dateOfBirth: dep.date_of_birth,
        dependentType: dep.relationship,
        editingState: 'existing-no-edits' as DependentEditingState,
        firstName: dep.name.first_name,
        lastName: dep.name.last_name,
        memberId: dep.member_id,
        middleName: dep.name.middle_name,
        sex: genderTranslatorFor843(dep.gender),
        ssn: dep.ssn,
        state: employeeData.address_object.state,
        street_1: employeeData.address_object.street_1,
        street_2: employeeData.address_object.street_2,
        suffix: dep.name.suffix,
        zip: employeeData.address_object.zip
      }))

      setDependentArr(tempDependentArr)
    }
  }, [employeeData])

  const handleCancel = () => {
    if (stepNum === 1) {
      history.push(
        isBrokerPortal ? `/groups/${groupId}/employees` : '/employees'
      )
    }

    setStepNum(stepNum - 1)
  }

  const handleAddEmployeeBasicInfo = (emp: EmployeeBasicInfoFormFields) => {
    setEmployeeBasicInfo(emp)
    setStepNum(2)

    if (maxStep < 2) {
      setMaxStep(2)
    }
  }

  const handleAddDependent = () => {
    setStepNum(3)

    if (maxStep < 3) {
      setMaxStep(3)
    }
  }

  const makeAddEmployeeRequest = async (data: AddPlanSelectionReturnData) => {
    if (!employeeBasicInfo) {
      return
    }
    const membersArr: EightThreeFourRequestMemberObject[] = []

    const coverageStartDate = moment(data.coverageStartDate).format(
      'YYYY-MM-DD'
    )

    membersArr.push({
      action_requested: 'enroll',
      city: employeeBasicInfo.homeAddress.city,
      coverage_end_date: data.coverageTerminationDate,
      coverage_start_date: coverageStartDate,
      date_of_birth: employeeBasicInfo.dob,
      family_indicator: computeFamilyIndicator(dependentArr),
      first_name: employeeBasicInfo.firstName,
      gender: employeeBasicInfo.sex,
      group_id: data.groupId,
      identified_gender: employeeBasicInfo.genderIdentity,
      insured_ssn: employeeBasicInfo.ssn,
      last_name: employeeBasicInfo.lastName,
      plan_name: data.planName,
      relationship: 'employee',
      ssn: employeeBasicInfo.ssn,
      state: employeeBasicInfo.homeAddress.state,
      street_1: employeeBasicInfo.homeAddress.street_1,
      street_2: employeeBasicInfo.homeAddress.street_2,
      zip: employeeBasicInfo.homeAddress.zip
    })


    dependentArr.forEach((dep) => {
      membersArr.push({
        action_requested: 'enroll',
        city: dep.city,
        coverage_end_date: data.coverageTerminationDate,
        coverage_start_date: coverageStartDate,
        date_of_birth: dep.dateOfBirth,
        family_indicator: computeFamilyIndicator(dependentArr),
        first_name: dep.firstName,
        gender: dep.sex,
        group_id: data.groupId,
        identified_gender: dep.genderIdentity,
        insured_ssn: employeeBasicInfo.ssn,
        last_name: dep.lastName,
        plan_name: data.planName,
        relationship: dep.dependentType,
        ssn: dep.ssn,
        state: dep.state,
        street_1: dep.street_1,
        street_2: dep.street_2,
        zip: dep.zip
      })
    })

    if (data.subgroup && data.subgroup !== "Standard") {
      for (let x = 0; x < membersArr.length; x++) {
        membersArr[x].subgroup = data.subgroup
      }
    }

    const combinedData: EightThreeFourRequest = {
      group_id: data.groupId,
      members: membersArr
    }
    await enrollMember.mutateAsync(combinedData)
  }

  const makeReinstateEmployeeRequest = async (
    data: AddPlanSelectionReturnData
  ) => {
    if (!employeeBasicInfo || !employeeData) {
      return
    }

    const membersArr: EightThreeFourRequestMemberObject[] = []

    const coverageStart = moment(data.coverageStartDate).format('YYYY-MM-DD')

    membersArr.push({
      action_requested: 'enroll',
      angle_member_id: employeeData.id,
      city: employeeBasicInfo.homeAddress.city,
      coverage_end_date: data.coverageTerminationDate,
      coverage_start_date: coverageStart,
      date_of_birth: employeeBasicInfo.dob,
      family_id: employeeData.familyId,
      family_indicator: computeFamilyIndicator(dependentArr),
      first_name: employeeBasicInfo.firstName,
      gender: employeeBasicInfo.sex,
      group_id: data.groupId,
      identified_gender: employeeBasicInfo.genderIdentity,
      insured_ssn: employeeBasicInfo.ssn,
      last_name: employeeBasicInfo.lastName,
      parent_member_id: employeeData.id,
      plan_name: data.planName,
      relationship: 'employee',
      ssn: employeeBasicInfo.ssn,
      state: employeeBasicInfo.homeAddress.state,
      street_1: employeeBasicInfo.homeAddress.street_1,
      street_2: employeeBasicInfo.homeAddress.street_2,
      zip: employeeBasicInfo.homeAddress.zip
    })

    // process new dependents to be removed
    for (let x = 0; x < removalDependentArr.length; x++) {
      let dependentMemberObject: EightThreeFourRequestMemberObject = {
        action_requested: 'terminate',
        angle_member_id: removalDependentArr[x].memberId,
        city: removalDependentArr[x].city,
        coverage_end_date: new Date().toISOString(),
        coverage_start_date: coverageStart,
        family_indicator: computeFamilyIndicator(dependentArr),
        first_name: removalDependentArr[x].firstName,
        gender: removalDependentArr[x].sex,
        group_id: employeeData?.group_id,
        insured_ssn: employeeBasicInfo?.ssn,
        last_name: removalDependentArr[x].lastName,
        parent_member_id: employeeData.id,
        plan_name: data.planName,
        relationship: removalDependentArr[x].dependentType,
        ssn: removalDependentArr[x].ssn,
        state: removalDependentArr[x].state,
        street_1: removalDependentArr[x].street_1,
        street_2: removalDependentArr[x].street_2,
        zip: removalDependentArr[x].zip
      }
      membersArr.push(dependentMemberObject)
    }

    // Process newly-Created & edited & existing
    for (let x = 0; x < dependentArr.length; x++) {
      let dependentMemberObject: EightThreeFourRequestMemberObject = {
        action_requested: 'enroll',
        city: dependentArr[x].city,
        coverage_end_date: data.coverageTerminationDate,
        coverage_start_date: coverageStart,
        family_indicator: computeFamilyIndicator(dependentArr),
        first_name: dependentArr[x].firstName,
        gender: dependentArr[x].sex,
        group_id: data.groupId,
        insured_ssn: employeeBasicInfo?.ssn,
        last_name: dependentArr[x].lastName,
        parent_member_id: employeeData.id,
        plan_name: data.planName,
        relationship: dependentArr[x].dependentType,
        ssn: dependentArr[x].ssn,
        state: dependentArr[x].state,
        street_1: dependentArr[x].street_1,
        street_2: dependentArr[x].street_2,
        zip: employeeBasicInfo.homeAddress.zip
      }

      if (dependentArr[x].editingState === 'new') {
        dependentMemberObject.gender = dependentArr[x].sex
        dependentMemberObject.parent_member_id = employeeData?.id
        dependentMemberObject.date_of_birth = dependentArr[x].dateOfBirth
      }

      if (dependentArr[x].memberId) {
        dependentMemberObject.angle_member_id = dependentArr[x].memberId
      }

      if (dependentArr[x].genderIdentity) {
        dependentMemberObject.identified_gender = dependentArr[x].genderIdentity
      }

      membersArr.push(dependentMemberObject)
    }

    if (data.subgroup && data.subgroup !== "Standard") {
      for (let x = 0; x < membersArr.length; x++) {
        membersArr[x].subgroup = data.subgroup
      }
    }

    const combinedData: EightThreeFourRequest = {
      group_id: data.groupId,
      members: membersArr
    }
    await enrollMember.mutateAsync(combinedData)
  }

  const handleSubmitFromPlanSelection = (data: AddPlanSelectionReturnData) => {
    if (employeeData) {
      // Reinstate flow
      makeReinstateEmployeeRequest(data)
    } else {
      makeAddEmployeeRequest(data)
    }
  }

  const enrollMember = useMutation({
    mutationFn: (data: any) => eightThreeFourService.postMemberInformation(data),
    onSuccess: (response) => {
      if (response.success && response.data.total_members === response.data.total_success) {
        showSuccess('Member was successfully added')
        history.push(
          isBrokerPortal ? `/groups/${groupId}/employees` : '/employees'
        )
      } else if (response.link && response.success === false) {
        showError(`Error Adding Member - ${response.message}`)
        setTimeout(() => window.open(response.link, '_blank'), 500)
      }
      else {
        showError(`Error Adding Member`)
      }
    },
    onError: () => {
      showError(`Error Adding Member`)
    }
  })

  const transformDataToBasicInfo = (): EmployeeBasicInfoFormFields => {
    if (!employeeData) {
      return {
        anotherPlan: {
          insurer: '',
          policyNumber: '',
          coverageStartDate: '',
          coverageEndDate: '',
          coverageTypes: []
        },
        dob: '',
        firstName: '',
        genderIdentity: '',
        isCobra: false,
        lastName: '',
        medicareEligible: false,
        sex: '',
        ssn: '',
        suffix: '',
        homeAddress: {
          street_1: '',
          street_2: '',
          city: '',
          state: '',
          zip: ''
        }
      }
    }
    let gender = 'U'
    if (employeeData.gender === 'male') {
      gender = 'M'
    } else if (employeeData.gender === 'female') {
      gender = 'F'
    }

    return {
      anotherPlan: {
        insurer: '',
        policyNumber: '',
        coverageStartDate: '',
        coverageEndDate: '',
        coverageTypes: []
      },
      dob: employeeData?.dob,
      firstName: employeeData?.name.split(' ')[0] || '',
      genderIdentity: gender,
      isCobra: false,
      lastName: employeeData?.name.split(' ')[1] || '',
      medicareEligible: false,
      sex: gender,
      ssn: employeeData.ssn,
      suffix: '',
      homeAddress: {
        street_1: employeeData.address_object.street_1,
        street_2: employeeData.address_object.street_2,
        city: employeeData.address_object.city,
        state: employeeData.address_object.state,
        zip: employeeData.address_object.zip
      }
    }
  }

  const getStepOne = (): JSX.Element => {
    const transformedData = employeeData
      ? transformDataToBasicInfo()
      : employeeBasicInfo ? employeeBasicInfo : {
          anotherPlan: {
            insurer: '',
            policyNumber: '',
            coverageStartDate: '',
            coverageEndDate: '',
            coverageTypes: []
          },
          dob: '',
          firstName: '',
          genderIdentity: '',
          isCobra: false,
          lastName: '',
          medicareEligible: false,
          midName: '',
          sex: '',
          ssn: '',
          suffix: '',
          homeAddress: {
            street_1: '',
            street_2: '',
            city: '',
            state: '',
            zip: ''
          }
        }
    return (
      <EmployeeBasicInfoForm
        handleCancelCallback={handleCancel}
        returnDataToParent={handleAddEmployeeBasicInfo}
        defaultData={transformedData}
      />
    )
  }

  const getStepTwo = (): JSX.Element => {
    if (!employeeBasicInfo) {
      return <></>
    }
    return (
      <AddDependentTable
        dependentArr={dependentArr}
        eightThreeFourService={eightThreeFourService}
        employeeAddress={{
          street_1: employeeBasicInfo.homeAddress.street_1,
          street_2: employeeBasicInfo.homeAddress.street_2,
          city: employeeBasicInfo.homeAddress.city,
          state: employeeBasicInfo.homeAddress.state,
          zip: employeeBasicInfo.homeAddress.zip
        }}
        handleCancelCallback={handleCancel}
        handleContinueCallback={handleAddDependent}
        removalDependentArr={removalDependentArr}
        setDependentArr={setDependentArr}
        updateRemovalDependentArr={setRemovalDependentArr}
      />
    )
  }

  const getExistingPlan = (): string | null => {

    return null
  }

  const getStepThree = (): JSX.Element => {
    const existingPlan = employeeData ? getExistingPlan() : null
    if (!employeeBasicInfo) {
      return <></>
    }
    const employeeFullName = `${employeeBasicInfo.firstName} ${employeeBasicInfo.lastName}`

    return (
      <AddPlanSelectionForm
        existingPlan={existingPlan}
        dependentArr={dependentArr}
        employeeFullName={employeeFullName}
        employee={employeeData}
        handleCancelCallback={handleCancel}
        returnDataToParent={handleSubmitFromPlanSelection}
        isMutationLoading={enrollMember.isLoading}
        planService={planService}
        summaryService={summaryService}
        isBrokerPortal={isBrokerPortal}
        groupId={groupId}
      />
    )
  }

  const getMainCardContent = (): JSX.Element => {
    if (stepNum === 2) {
      return getStepTwo()
    }

    if (stepNum === 3) {
      return getStepThree()
    }

    return getStepOne()
  }

  const getStepRow = (i: number, title: string): JSX.Element => {
    if (stepNum === i) {
      return (
        <Row style={{ marginLeft: 0, background: '#F8F9FA', paddingBottom: '5px', paddingTop: '5px' }}>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <CircleActive>{i}</CircleActive>
          </Col>
          <Col style={{ flexGrow: 9 }}>{title}</Col>
        </Row>
      )
    } else if (maxStep > i) {
      return (
        <Row style={{ marginLeft: 0, paddingBottom: '5px', paddingTop: '5px' }} onClick={ () => setStepNum(i)}>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{
                width: 17,
                height: 17
              }}
              src={greenCheck}
            />
          </Col>
          <Col style={{ flexGrow: 9 }}>{title}</Col>
        </Row>
      )
    } else {
      return (
        <Row style={{ marginLeft: 0, paddingBottom: '5px', paddingTop: '5px' }}  onClick={ () => { if (maxStep >= i) setStepNum(i)}}>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <CirclePassive>{i}</CirclePassive>
          </Col>
          <Col style={{ flexGrow: 9 }}>{title}</Col>
        </Row>
      )
    }
  }

  return (
    <>
      <Row
        style={{
          borderBottom: '1px solid #E7EAF3',
          borderTop: '1px solid #E7EAF3'
        }}
      >
        <PageHeader>
          <div style={{ fontWeight: 700, fontSize: '32px' }}>
            {employeeData ? 'Reinstate Employee' : 'Add Employee'}
          </div>
        </PageHeader>
      </Row>
      <div style={{ background: '#F9F9FC' }}>
        <PageContainer size="xl">
          <Row style={{ display: 'flex' }}>
            <Col style={{ marginTop: '3vh', maxWidth: '400px' }}>
              <Card>
                <Card.Body className="gy-2">
                  <Row style={{ marginLeft: 0 }}>
                    <h6 style={{ fontWeight: 700 }}>Summary</h6>
                  </Row>
                  <div>
                    {getStepRow(1, 'Basic Information')}
                    {getStepRow(2, 'Dependents')}
                    {getStepRow(3, 'Plan')}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col style={{ flexGrow: 2, marginTop: '3vh' }}>
              <Card style={{ padding: '15px' }}>{getMainCardContent()}</Card>
            </Col>
          </Row>
        </PageContainer>
      </div>
    </>
  )
}
