import { View, Text } from '@react-pdf/renderer'

import tableStyles from './tableStyles'
import { ReportTableProps } from '../ReportTable'
import {
  getCellStyleBasedOnDataType,
  getFormattedDataBasedOnType
} from '../funcs'

export interface Info {
  costShare: string
  deductibleApplies: boolean
}

type RowProps = {
  rowData: ReportTableProps['table']['rowData'][0]
  columns: ReportTableProps['table']['columns']
  columnFlex: any
}

const Row = ({ rowData, columns, columnFlex }: RowProps) => {
  return (
    <View style={[tableStyles.tableRow]}>
      {columns.map(({ columnKey, dataType }) => (
        <View style={[tableStyles.cell, columnFlex[columnKey]]}>
          <Text
            style={[
              getCellStyleBasedOnDataType(dataType) as any,
              tableStyles.cellText
            ]}
          >
            {getFormattedDataBasedOnType(rowData[columnKey], dataType)}
          </Text>
        </View>
      ))}
    </View>
  )
}

export default Row
