import {
  PageHeaderLight,
  PageContainer,
  Row,
  Col
} from 'react-angle-dashboard-components'
import { PageLayout } from '../layouts/PageLayout'
import { BrokerInformation } from './BrokerInformation'
// import { useLocation } from 'react-router-dom'
// import { useQuery } from 'react-query'
// import { brokerService } from '../../services'

export const BrokerForm = () => {
  // const groupId =
  //   new URLSearchParams(useLocation().search).get('group_id') || ''
  // const token = localStorage.getItem('token')
  // const { data, isLoading } = useQuery(['getBrokerForm', groupId], () =>
  //   brokerService.getBrokerInfo(groupId, token)
  // )

  return (
    <PageLayout noMenu>
      <div className="business-onboarding">
        <PageHeaderLight className="align-items-start">
          <PageContainer size="xl">
            <h1>Broker/Producer Information</h1>
          </PageContainer>
        </PageHeaderLight>
        <PageContainer size="xl">
          <Row className="pb-5">
            <Col>
              <BrokerInformation />
            </Col>
          </Row>
        </PageContainer>
      </div>
    </PageLayout>
  )
}

export { TempAuthRedirect } from './TempAuthRedirect'
