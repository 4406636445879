import React from 'react'
import { Row, Col } from 'react-bootstrap'

export const BackHeader = ({ data }: { data: any }) => {
  let phoneNumber = '1(855)937-1855'

  //TODO: remove this, hacky solution
  const groupId = data.group_id
  switch (groupId) {
    case 'UT860793':
    case 'UT991771':
      phoneNumber = '1(877)257-3826'
      break
    default:
  }

  return (
    <div
      style={{
        backgroundImage: 'url(/bg-card.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'right',
        flex: 0
      }}
      className="bg-primary pt-3 px-4 rounded-top border-top border-right border-left"
    >
      <div className="pb-2">
        <h6 className="text-white" style={{ fontFamily: 'Sentinel' }}>
          Care team: For eligibility and benefits
        </h6>
        <Row>
          <Col className="text-left">
            <p className="mb-0 text-white fs-12 font-weight-bold">Live Chat:</p>
            <p className="mb-0 text-white fs-12 font-weight-bold">Call:</p>
            <p className="mb-0 text-white fs-12 font-weight-bold">Website:</p>
          </Col>
          <Col className="text-right">
            <p className="mb-0 text-white fs-12">Your mobile app</p>
            <p className="mb-0 text-white fs-12">{phoneNumber}</p>
            <p className="mb-0 text-white fs-12">anglehealth.com</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}
