import React, { useContext, useState } from 'react'
import {
  Col,
  Form,
  Row,
  CenteredSpinner,
  useToaster
} from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { BusinessOnboardingContext } from '../../context/BusinessOnboardingContext'
import { businessOnboardingPaths } from '../../router/paths'
import { FooterActions } from '../FooterActions'
import { getNewCurrentStep } from '../../utils/utils'
import { groupService } from '../../../../services'
import { mapDTOtoProducer, mapProducerToDTO } from './mappers'
import {
  formValidations,
  getRequiredObject
} from '../../../../utils/formValidations'
import { useStep } from '../../hooks/use-step'

import { TitleRow, ValidationError } from '../style'



const { isValidEmail } = formValidations

const labels = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email'
}

export const Producer: React.FC = () => {

  const history = useHistory()
  const { state, setState, modifyGroup } = useContext(BusinessOnboardingContext)
  const toaster = useToaster()

  const { handleSubmit, formState, register, reset, unregister } = useForm({
    mode: 'onChange'
  })
  const errors = formState.errors

  const [isThroughBroker, setIsThroughBroker] = useState('false')

  const { data, isLoading } = useQuery(
    'getProducer',
    () => groupService.getInfo(mapDTOtoProducer, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (data: any) => {
        reset({ ...data })
        setIsThroughBroker(data.agencyAppointment)
        setState(data.stepNumber)
      }
    }
  )

  let showForm = isThroughBroker === 'true'

  const handleContinue = handleSubmit(async (formData) => {
    try {
      const DTO = mapProducerToDTO(formData, data.stepNumber)
      await modifyGroup.mutateAsync(DTO)

      if (data?.isLevelFunded) {
        await modifyGroup.mutateAsync({
          onboarding_status: 'under_review'
        })
        history.push(businessOnboardingPaths.complete)
      } else {
        await modifyGroup.mutateAsync({
          in_progress_state: getNewCurrentStep(data.stepNumber, 9 + (data.isFronting))
        })
        history.push(businessOnboardingPaths.complete)
      }
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  const handleBack = () => {
    history.push(businessOnboardingPaths.billing)
  }

  useStep(state, 8, 'producer', data?.stepNumber)

  return (
    <>
      {isLoading ? (
        <CenteredSpinner style={{ height: '40vh' }} />
      ) : (
        <>
          <Form>
            <TitleRow noGutters>
              <b>Producer/Agency Appointment</b>
            </TitleRow>
            <Form.Group>
              <Form.Label>
                Are you signing up with Angle through a broker?
              </Form.Label>
              <Row className="mb-3">
                <Col xs={12} md={7}>
                  <Form.Check
                    {...register('agencyAppointment')}
                    className="custom-radio"
                    type="radio"
                    value="false"
                    label="No"
                    id="rno"
                    onChange={(e) => {
                      setIsThroughBroker('false')
                      unregister('agency')
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={7}>
                  <Form.Check
                    {...register('agencyAppointment')}
                    className="custom-radio"
                    type="radio"
                    value="true"
                    label="Yes"
                    id="ryes"
                    onChange={(e) => setIsThroughBroker('true')}
                  />
                </Col>
              </Row>
            </Form.Group>
            {showForm && (
              <>
                <TitleRow noGutters>
                  <b>Producer/Broker Information</b>
                </TitleRow>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="required-field">
                        {labels.firstName}
                      </Form.Label>
                      <Form.Control
                        placeholder="First name"
                        {...register('firstName', {
                          required: getRequiredObject(labels.firstName)
                        })}
                        isInvalid={Boolean(errors?.firstName)}
                      />
                      <ValidationError>
                        {formState.isSubmitted && errors?.firstName?.message}
                      </ValidationError>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="required-field">
                        {labels.lastName}
                      </Form.Label>
                      <Form.Control
                        placeholder="Last name"
                        {...register('lastName', {
                          required: getRequiredObject(labels.lastName)
                        })}
                        isInvalid={Boolean(errors?.lastName)}
                      />
                      <ValidationError>
                        {formState.isSubmitted && errors?.lastName?.message}
                      </ValidationError>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="required-field">
                        {labels.email}
                      </Form.Label>
                      <Form.Control
                        placeholder="Email address"
                        {...register('email', {
                          required: getRequiredObject(labels.email),
                          validate: isValidEmail
                        })}
                        isInvalid={Boolean(errors?.email)}
                      />
                      <ValidationError>
                        {formState.isSubmitted && errors?.email?.message}
                      </ValidationError>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </Form>
          <FooterActions
            backHandler={handleBack}
            continueHandler={handleContinue}
          />
        </>
      )}
    </>
  )
}
