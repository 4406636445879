import { ApiService } from './base'

export interface IFormLink {
  name: string
  link: {
    text: string
    route?: string
    href?: string
  }
}

export interface IForm {
  sectionName: string
  links: IFormLink[]
}

export class FormService extends ApiService {
  async findForms(): Promise<IForm[]> {
    return this.request({
      method: 'post',
      url: '/forms',
      credentials: true
    }).then((r: any) => r.forms)
  }
}
