export const capitalize = (str: string) => {
  if (!str) return

  return str.replace(/\b\w/g, function (l) {
    return l.toUpperCase()
  })
}

export const formatName = (name: string) => {
  if (!name) return
  return name[0].toUpperCase() + name.slice(1).toLowerCase();
}

// changes any string to Title Case. IE -> 'aRandomStr' -> 'A Random Str'
export const convertStringToTitleCase = (str: string): string => {
    
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
