import React from 'react'
import { AnglePlanBadge } from 'react-angle-dashboard-components'
import { Form } from 'react-bootstrap'
import { GhostBtn } from '../../pages/BusinessOnboarding/steps/FooterActions/style'
import { MedicalCoverageCardWrapper } from './style'
import styled from 'styled-components'

interface MedicalCoveragePlanCardProps {
  plan: any
  selected: boolean
  onSelect: Function
  [x: string]: any
}

const PlanPrice = styled.span`
  font-size: 26px;
  color: var(--primary);
  font-weight: 700;
`

const PlanSuffix = styled.span`
  font-size: 19px;
  color: var(--secondary);
`

const Subtitle = styled.p`
  margin: 0 0 8px 0;
  padding: 0;
  font-size: 12px;
  color: var(--secondary);
`

const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

const MonthlyCost = styled.div`
  border-bottom: 1px solid #dee2e6;
`

const AvgPrice = styled.span`
  font-weight: 600;
  font-size: 17px;
`

const AvgSuffix = styled.span`
  font-size: 14px;
  color: var(--secondary);
`

const DataLabel = styled.span`
  font-size: 14px;
`

export const MedicalCoveragePlanCard: React.FC<MedicalCoveragePlanCardProps> =
  ({
    selected,
    onSelect,
    moreDetailsHref,
    plan,
    showPrices,
    onMoreDetailsClick = () => {},
    isLargeGroup,
    ...p
  }) => {
    return (
      <MedicalCoverageCardWrapper {...p}>
        <div className="d-flex justify-content-between align-items-baseline mb-3">
          <div className="d-flex align-items-center">
            <AnglePlanBadge
              metalTier={plan.metal_tier}
              planName={plan.plan_name}
            />
          </div>
          <Form.Check
            className="custom-checkbox"
            checked={selected}
            onChange={() => onSelect()}
          />
        </div>
        {showPrices && (
          <>
            <div className="d-flex flex-column align-items-center pb-3">
              <div>
                <PlanPrice>${plan.avg_premium_employer_contrib}</PlanPrice>
                <PlanSuffix>/mo</PlanSuffix>
              </div>
              <p className="fs-14" style={{ marginBottom: 5 }}>
                Employer cost
              </p>
            </div>
            <MonthlyCost>
              <Subtitle>Avg. monthly cost per employee</Subtitle>
              <DataWrapper>
                <div>
                  <AvgPrice>${plan.avg_premium_employee_contrib}</AvgPrice>
                  <AvgSuffix>/mo</AvgSuffix>
                </div>
                <DataLabel>Employee cost</DataLabel>
              </DataWrapper>
              <DataWrapper>
                <div>
                  <AvgPrice>${plan.avg_premium}</AvgPrice>
                  <AvgSuffix>/mo</AvgSuffix>
                </div>
                <DataLabel>Total cost</DataLabel>
              </DataWrapper>
            </MonthlyCost>
          </>
        )}

        <Subtitle className="mt-2">In-Network Costs</Subtitle>
        <div className="d-flex justify-content-between">
          <p className="mt-2 mb-1 text-muted fs-12">Deductible</p>
          <p className="mt-2 mb-1 fs-12">${plan.deductible_individual_inn}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="my-1 text-muted fs-12">Out Of Pocket Max</p>
          <p className="my-1 fs-12">${plan.oop_max_individual_inn}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="my-1 text-muted fs-12">
            Primary Doctor{' '}
            {plan.doctor_visit_inn_copay_type === 'coinsurance'
              ? 'Coinsurance'
              : 'Copay'}
          </p>
          <p className="my-1 fs-12">
            $
            {plan.doctor_visit_inn_copay_type === 'coinsurance'
              ? plan.doctor_visit_inn_coinsurance
              : plan.doctor_visit_inn_copay}
          </p>
        </div>
        <GhostBtn
          variant="primary"
          ghost
          className="w-100 mt-2"
          onClick={onMoreDetailsClick}
          href={moreDetailsHref}
          target="_blank"
        >
          More details
        </GhostBtn>
      </MedicalCoverageCardWrapper>
    )
  }
