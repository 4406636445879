const getStyles = (styles, className: string) => {
  switch (className) {
    case 'disabled':
      return {
        color: styles.disabledColor && styles.disabledColor,
        fontSize: styles.buttonFontSize,
        backgroundColor: styles.disabledBgColor
          ? styles.disabledBgColor
          : styles.bgColor,
        borderColor: styles.disabledBorderColor
          ? styles.disabledBorderColor
          : styles.borderColor
      }
    case 'active':
      return {
        color: styles.activeColor && styles.activeColor,
        backgroundColor: styles.activeBgColor,
        border: `1px solid ${styles.activeBorderColor}`,
        fontSize: styles.buttonFontSize
      }
    default:
      return {
        color: styles.color && styles.color,
        backgroundColor: styles.bgColor,
        borderColor: styles.borderColor,
        fontSize: styles.buttonFontSize
      }
  }
}

export default getStyles
