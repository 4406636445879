import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  min-height: 8.8rem;
  border: 1px solid var(--card-border);
  display: flex;
  flex-direction: column;
  justfiy-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 6px;
`
