export const EyeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 11.5C2.72998 7.10999 7 4 12 4C17 4 21.27 7.10999 23 11.5C21.27 15.89 17 19 12 19C7 19 2.72998 15.89 1 11.5ZM3.18005 11.5C4.83008 14.87 8.21008 17 12 17C15.79 17 19.17 14.87 20.8201 11.5C19.17 8.13 15.79 6 12 6C8.21008 6 4.83008 8.13 3.18005 11.5Z"
      fill="black"
      fill-opacity="0.54"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 11.5C7.5 9.02002 9.52002 7 12 7C14.48 7 16.5 9.02002 16.5 11.5C16.5 13.98 14.48 16 12 16C9.52002 16 7.5 13.98 7.5 11.5ZM9.5 11.5C9.5 12.88 10.62 14 12 14C13.38 14 14.5 12.88 14.5 11.5C14.5 10.12 13.38 9 12 9C10.62 9 9.5 10.12 9.5 11.5Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
)

export const EyeClosedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M12 14C12.04 14 12.08 13.99 12.12 13.99L9.51 11.38C9.51 11.42 9.5 11.46 9.5 11.5C9.5 12.88 10.62 14 12 14Z"
      fill="black"
      fill-opacity="0.54"
    />
    <path
      opacity="0.5"
      d="M13.01 9.21002L14.29 10.49C14.03 9.92002 13.58 9.46002 13.01 9.21002Z"
      fill="black"
      fill-opacity="0.54"
    />
    <path
      d="M12 6C15.79 6 19.17 8.13 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.11 17 4 12 4C10.73 4 9.50999 4.2 8.35999 4.57L10.01 6.22C10.66 6.09 11.32 6 12 6Z"
      fill="black"
      fill-opacity="0.54"
    />
    <path
      d="M14.2801 10.49L16.3501 12.56C16.4301 12.22 16.4901 11.86 16.4901 11.49C16.5001 9.01 14.4801 7 12.0001 7C11.6301 7 11.2801 7.06 10.9301 7.14L13.0001 9.21C13.5801 9.46 14.0301 9.92 14.2801 10.49Z"
      fill="black"
      fill-opacity="0.54"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.01001 3.87L4.68994 6.54999C3.06006 7.83002 1.77002 9.53003 1 11.5C2.72998 15.89 7 19 12 19C13.52 19 14.98 18.71 16.3199 18.18L19.74 21.6L21.15 20.19L3.42004 2.45001L2.01001 3.87ZM9.51001 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.475 9.50244 11.455 9.505 11.435C9.50757 11.415 9.51001 11.395 9.51001 11.37ZM7.86011 9.72003L6.11011 7.97003C4.90002 8.89001 3.88 10.07 3.18005 11.5001C4.83008 14.8701 8.21008 17.0001 12 17.0001C12.9501 17.0001 13.87 16.86 14.75 16.6201L13.77 15.64C13.2301 15.8701 12.63 16.0001 12 16.0001C9.52002 16.0001 7.5 13.98 7.5 11.5001C7.5 10.8701 7.63 10.27 7.86011 9.72003Z"
      fill="black"
      fill-opacity="0.54"
    />
  </svg>
)
