import {
  IAccountSetting,
  ICompanySetting,
  IDocumentRequested,
  ISecurity
} from '../../interfaces/setting'
import { ApiService } from './base'

export class SettingService extends ApiService {
  // TODO take out these placeholder methods
  getAccount(): Promise<IAccountSetting> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          email: 'joe@gmail.com',
          firstName: 'Joe',
          lastName: 'Smith',
          language: 'english',
          phoneNumber: '+1 (303) 556-1523'
        })
      }, 800)
    })
  }

  updateAccount(account: IAccountSetting): Promise<{ success: boolean }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          success: true
        })
      }, 800)
    })
  }

  getCompany(): Promise<ICompanySetting> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          adminContact: {
            email: 'joe@gmail.com',
            fullName: 'Joe Smith',
            phone: '+1 (303) 556-1523',
            title: 'Manager'
          },
          billingContact: {
            email: 'joe@gmail.com',
            fullName: 'Joe Smith',
            phone: '+1 (303) 556-1523',
            title: 'Manager'
          },
          businessAddress: {
            address: '678  Lodgeville Road',
            city: 'minneapolis',
            country: 'usa',
            email: 'admin@company.com',
            state: 'minnesota',
            zip: '55402'
          },
          mailingAddress: {
            address: '678  Lodgeville Road',
            city: 'minneapolis',
            country: 'usa',
            state: 'minnesota',
            zip: '55402'
          }
        })
      }, 800)
    })
  }

  updateCompany(
    companySetting: ICompanySetting
  ): Promise<{ success: boolean }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          success: true
        })
      }, 800)
    })
  }

  getPassword(): Promise<Pick<ISecurity, 'currentPassword'>> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          currentPassword: '5s4d8'
        })
      }, 800)
    })
  }

  updatePassword(passwords: ISecurity) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          success: true
        })
      }, 800)
    })
  }

  getRequestedDocuments(): Promise<IDocumentRequested> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          name: 'Official Document',
          submitBy: '12 july, 2021'
        })
      }, 800)
    })
  }

  uploadDocument(data: FormData): Promise<{ success: boolean }> {
    return this.request({
      method: 'post',
      url: '/documents/upload',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }
}
