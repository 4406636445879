import React, { useState, useEffect } from 'react'
import { Form, useToaster } from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { TitleRow } from '../../style'
import { FormState, Control } from 'react-hook-form'
import { businessOnboardingPaths } from '../../../router/paths'
import { FooterActions } from '../../FooterActions'
import { CarrierData, CarrierFormData } from '../../../interfaces'
import { mapLargeGroupCarrierInformationToDTO } from '../mappers'
import { PriorCarriers, CurrentCarrier, OtherInsurance, LessThanThree } from '.'

interface LargeGroupCarrierInfoProps {
  data: CarrierData
  formState: FormState<CarrierFormData>
  control: Control
  handleSubmit: any
  modifyGroup: any
  watch: any
  unregister: any
  setValue: any
}

export const LargeGroupCarrierInformation: React.FC<
  LargeGroupCarrierInfoProps
> = ({
  formState,
  control,
  data,
  handleSubmit,
  modifyGroup,
  watch,
  unregister,
  setValue
}) => {
  const history = useHistory()
  const toaster = useToaster()

  const [hasPreviousCarrier, setHasPreviousCarrier] = useState(
    data?.hasPreviousCarrier
  )
  const [otherCarriers, setOtherCarriers] = useState<string[]>([''])
  const [otherInsurance, setOtherInsurance] = useState(data?.otherInsurance)
  const [lessThanThree, setLessThanThree] = useState<'true' | 'false'>(
    data?.lessThanThree
  )

  useEffect(() => {
    setOtherCarriers(data?.otherCarrierNames)
  }, [data])

  useEffect(() => {
    setHasPreviousCarrier(data?.hasPreviousCarrier)
    setLessThanThree(data?.lessThanThree)
    setOtherInsurance(data?.otherInsurance)
  }, [data, setHasPreviousCarrier])

  useEffect(() => {
    if (lessThanThree === 'false') {
      unregister('previousCarrier')
      unregister('previousCarrierStartDate')
      unregister('previousCarrierEndDate')
      unregister('previousChangingReason')
      unregister('otherPreviousChangingReason')
    } else {
      setValue('hasPreviousCarrier', data?.hasPreviousCarrier || 'false')
      setHasPreviousCarrier(data?.hasPreviousCarrier || 'false')
    }
  }, [lessThanThree, unregister, setValue, data?.hasPreviousCarrier])

  const handleContinue = handleSubmit(async (formData: CarrierFormData) => {
    const DTO = mapLargeGroupCarrierInformationToDTO(
      formData,
      otherCarriers,
      data.stepNumber,
      data.isFronting,
    )
    try {
      await modifyGroup.mutateAsync(DTO)
      history.push(businessOnboardingPaths.billing)
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  const handleBack = () => {
    history.push(businessOnboardingPaths.plan)
  }

  return (
    <>
      <Form>
        <TitleRow noGutters>
          <b>Carrier Information</b>
        </TitleRow>
        <CurrentCarrier control={control} formState={formState} watch={watch} />
        <LessThanThree
          control={control}
          formState={formState}
          setLessThanThree={setLessThanThree}
        />
        {lessThanThree === 'true' && (
          <PriorCarriers
            control={control}
            formState={formState}
            hasPreviousCarrier={hasPreviousCarrier}
            setHasPreviousCarrier={setHasPreviousCarrier}
            lessThanThree={lessThanThree}
            watch={watch}
          />
        )}
        <OtherInsurance
          control={control}
          otherInsurance={otherInsurance}
          otherCarriers={otherCarriers}
          setOtherInsurance={setOtherInsurance}
          setOtherCarriers={setOtherCarriers}
        />
      </Form>
      <FooterActions
        continueHandler={handleContinue}
        backHandler={handleBack}
      />
    </>
  )
}
