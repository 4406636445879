import React, { useContext } from 'react'
import {
  Col,
  Form,
  Row,
  CenteredSpinner,
  useToaster
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { getRequiredObject } from '../../../../utils/formValidations'
import { BusinessOnboardingContext } from '../../context/BusinessOnboardingContext'
import { useStep } from '../../hooks/use-step'
import { businessOnboardingPaths } from '../../router/paths'
import { FooterActions } from '../FooterActions'
import { TitleRow, ValidationError } from '../style'
import { useQuery } from 'react-query'
import { groupService } from '../../../../services'
import { getNewCurrentStep } from '../../utils/utils'
import {
  mapDTOtoEmployerContribution,
  mapEmployerContributionToDTO
} from './mappers'
import { nonNegativeValues } from '@angle/utils'

const labels = {
  type: 'Amount Type',
  employerContributionEmployee: 'Employee',
  employerContributionDependent: 'Dependent'
}

export const EmployerContributionStrategy: React.FC = () => {
  const history = useHistory()
  const toaster = useToaster()
  const { state, setState, modifyGroup } = useContext(BusinessOnboardingContext)

  const { handleSubmit, control, formState, reset, watch, trigger } = useForm({
    mode: 'onChange'
  })

  const { data, isLoading } = useQuery(
    'getContribution',
    () => groupService.getInfo(mapDTOtoEmployerContribution, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (data: any) => {
        reset({ ...data })
        setState(data.stepNumber)
      }
    }
  )

  const handleContinue = handleSubmit(async (formData) => {
    const DTO = mapEmployerContributionToDTO(formData, data.stepNumber)

    try {
      await modifyGroup.mutateAsync(DTO)

      await modifyGroup.mutateAsync({
        in_progress_state: getNewCurrentStep(data.stepNumber, 5 + (data.isFronting))
      })
      history.push(businessOnboardingPaths.plan)
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  const errors = formState.errors

  const handleBack = () => {
    const path = data?.isFronting
      ? businessOnboardingPaths.disclosure
      : businessOnboardingPaths.employerPreferences
    history.push(path)
  }

  const employerContributionType = watch('type')

  useStep(state, 4, 'contributionStrategy', data?.stepNumber || 1)

  return (
    <>
      <Form>
        {isLoading ? (
          <CenteredSpinner style={{ height: '44vh' }}></CenteredSpinner>
        ) : (
          <>
            <TitleRow noGutters>
              <b>Employer contribution towards premium</b>
            </TitleRow>
            <Row noGutters>
              <Form.Label className="required-field">{labels.type}</Form.Label>
            </Row>
            <Form.Group>
              <Controller
                name="type"
                control={control}
                rules={{ required: getRequiredObject(labels.type) }}
                render={({ field }) => (
                  <Row>
                    <Col xs="12">
                      <Form.Check
                        {...field}
                        className="custom-radio"
                        type="radio"
                        id="percentage"
                        value="percentage"
                        label="Percentage"
                      />
                    </Col>

                    <Col xs="12">
                      <Form.Check
                        {...field}
                        className="custom-radio"
                        type="radio"
                        id="exact"
                        value="exact"
                        label="Exact Amount"
                      />
                    </Col>
                  </Row>
                )}
              />
              <ValidationError className="mt-0">
                {formState.isSubmitted && errors?.type?.message}
              </ValidationError>
            </Form.Group>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      employerContributionType === 'percentage'
                        ? 'percentage-sign-after'
                        : employerContributionType === 'exact'
                        ? 'dollar-sign-after'
                        : ''
                    }
                  >
                    {labels.employerContributionEmployee}
                  </Form.Label>

                  <Controller
                    name="employerContributionEmployee"
                    rules={{
                      required: {
                        value: true,
                        message: `${
                          employerContributionType === 'percentage'
                            ? 'Contribution percentage per employee is required'
                            : 'Contribution amount per employee is required'
                        }`
                      },
                      validate: (value) => {
                        if (employerContributionType === 'percentage') {
                          if (value > 100)
                            return 'Percentage can not be greater than 100'
                          if (value < 0) return 'Negative numbers not allowed'
                        } else return true
                      }
                    }}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        {...nonNegativeValues}
                        type="number"
                        placeholder="Enter a number"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.employerContributionEmployee?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className={
                      employerContributionType === 'percentage'
                        ? 'percentage-sign-after'
                        : employerContributionType === 'exact'
                        ? 'dollar-sign-after'
                        : ''
                    }
                  >
                    {labels.employerContributionDependent}
                  </Form.Label>

                  <Controller
                    name="employerContributionDependent"
                    rules={{
                      required: {
                        value: true,
                        message: `${
                          employerContributionType === 'percentage'
                            ? 'Contribution percentage per dependent is required'
                            : 'Contribution amount per dependent is required'
                        }`
                      },
                      validate: (value) => {
                        if (employerContributionType === 'percentage') {
                          if (value > 100)
                            return 'Percentage can not be greater than 100'
                          if (value < 0) return 'Negative numbers not allowed'
                        } else return true
                      }
                    }}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        {...nonNegativeValues}
                        onChange={(e) => {
                          field.onChange(e)
                          trigger('employerContributionDependent')
                        }}
                        type="number"
                        placeholder="Enter a number"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.employerContributionDependent?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </Form>

      <FooterActions
        continueHandler={handleContinue}
        backHandler={handleBack}
      />
    </>
  )
}
