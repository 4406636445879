import styled from 'styled-components'

export const LocationOuter = styled.div`
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  background-color: rgba(108, 105, 248, 0.2);
  z-index: 3;
  position: relative;
`

export const LocationInner = styled.div`
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: var(--primary);
  width: 0.875rem;
  height: 0.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
