import { useState, useLayoutEffect } from 'react'

enum Breakpoints {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1400
}

const getBreakpointValue = (width: number) => {
  if (width >= Breakpoints.xxl) {
    return 'xxl'
  } else if (width >= Breakpoints.xl) {
    return 'xl'
  } else if (width >= Breakpoints.lg) {
    return 'lg'
  } else if (width >= Breakpoints.md) {
    return 'md'
  } else if (width >= Breakpoints.sm) {
    return 'sm'
  } else {
    return 'xs'
  }
}

type windowSize = {
  width: number
  height: number
  breakpoint: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'
}

export const debounceFunc = (func, delay = 400) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => func(...args), delay);
  }
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<windowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
    breakpoint: getBreakpointValue(window.innerWidth)
  })

  useLayoutEffect(() => {
    const onResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint: getBreakpointValue(window.innerWidth)
      })
    }
    const debouncedReize = debounceFunc(onResize, 500)
    window.addEventListener('resize', debouncedReize)
    return () => {
      window.removeEventListener('resize', debouncedReize)
    }
  }, [])

  return windowSize
}
