import React from 'react'
import { Button } from 'react-angle-dashboard-components'
import { ActionContainer, RightMarginBtn } from './style'

interface FooterActionsProps {
  backHandler?: React.MouseEventHandler<HTMLButtonElement>
  continueHandler?: React.MouseEventHandler<HTMLButtonElement>
  cancelHandler?: React.MouseEventHandler<HTMLButtonElement>
  continueDisabled?: boolean
  showBack?: boolean
  showContinue?: boolean
  continueSubmits?: boolean
}

export const FooterActions: React.FC<FooterActionsProps> = ({
  backHandler,
  continueHandler,
  continueDisabled = false,
  showBack = true,
  showContinue = true,
  continueSubmits = false
}) => {
  const buttonProps = continueSubmits
    ? { type: 'submit' }
    : { onClick: continueHandler }

  return (
    <ActionContainer>
      <div>
        {backHandler && showBack && (
          <RightMarginBtn ghost variant="primary" onClick={backHandler}>
            Back
          </RightMarginBtn>
        )}
        {(continueHandler || continueSubmits) && showContinue && (
          <Button
            variant="primary"
            {...buttonProps}
            disabled={continueDisabled}
          >
            Continue
          </Button>
        )}
      </div>
    </ActionContainer>
  )
}
