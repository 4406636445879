import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--card-border);
  padding: 1rem;
  margin: 1rem 0;
  height: 34rem;
  display: flex;
  flex-direction: column;
  align-items: space-between;
`

export const BorderContainer = styled.div`
  padding: 1.25rem 0;
  border-top: 1px solid var(--card-border);
  width: 100%;
`
