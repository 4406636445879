export type Benefit = {
    displayName: string
    key?: string
    selectedPlanName?: string | null
    selectedPlanId?: string | null
    apiKey?: string
    // mapperFn?: Function
  }
  
export const benefitsMap: { [key: string]: Benefit } = {
  dental: {
    displayName: 'Dental',
    key: 'dental'
  },
  vision: {
    displayName: 'Vision',
    key: 'vision'
  },
  medical: {
    displayName: 'Medical',
    key: 'medical'
  },
  critical_illness: {
    displayName: 'Critical Illness',
    key: 'critical_illness',
    apiKey: 'critical-illness'
  },
  hospital_indemnity: {
    displayName: 'Hospital Indemnity',
    key: 'hospital_indemnity',
    apiKey: 'hospital-indemnity'
  },
  identity: {
    displayName: 'Identity Theft',
    key: 'identity'
  },
  accidental: {
    displayName: 'Accidental',
    key: 'accidental'
  },
  basic_life_add: {
    displayName: 'Basic Life',
    key: 'basic_life_add',
    apiKey: 'basic-life-add'
  }
}
