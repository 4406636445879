import React from 'react'
import { FrontHeader } from './FrontHeader'
import { Copays } from './Copays'
import { PharmacyInformation } from './PharmacyInformation'
import { DeductibleOOP } from './DeductibleOOP'
import { FirstHealthFooter } from './FirstHealthFooter'
import { CignaFooter } from './CignaFooter'
import { getCoverage } from '../utils/getCoverage'
import { MPCNFooter } from './MPCNFooter'

export const Front: React.FC<{ data: any; showFrontBackHeaders?: boolean }> = ({
  data,
  showFrontBackHeaders
}) => {
  const planCoverage = getCoverage(data)

  const idCardType = data.id_card_type
  const isHdhp = !!planCoverage?.plan?.is_hdhp

  let Footer
  switch (idCardType) {
    case 'angle':
      Footer = FirstHealthFooter
      break
    case 'mpcn':
      Footer = MPCNFooter
      break
    default:
      Footer = CignaFooter
  }

  return (
    <div
      style={{
        maxWidth: 350,
        minWidth: 350,
        width: '100%',
        margin: '20px auto 0',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {showFrontBackHeaders && (
        <div className="font-weight-bold mb-2 pt-5">Front</div>
      )}
      <FrontHeader data={data} planCoverage={planCoverage} />
      {/* front main section */}
      <div
        className="pt-2 px-4 py-2 rounded-bottom border-bottom border-right border-left"
        style={{ height: '100%' }}
      >
        <Copays planCoverage={planCoverage} idCardType={idCardType} />
        <PharmacyInformation planCoverage={planCoverage} isHdhp={isHdhp} />
        <p className="mt-3 fs-12" style={{ fontWeight: 500 }}>
          {isHdhp ? '*After Deductible' : 'Deductible may apply.'}
        </p>
        <DeductibleOOP planCoverage={planCoverage} />
        {<Footer />}
      </div>
    </div>
  )
}
