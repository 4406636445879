import React from 'react'
import { Button } from '../Button'
import { Document, Page } from 'react-pdf'
import { PDFDocument } from 'pdf-lib'

export type ClarityCardData = {
  method: string
  data: {
    cardAsPDF?: string
    cardBackAsPDF?: string
    cardFrontAsPDF?: string
    cardBackAsPNG?: string
    cardFrontAsPNG?: string
  }
} | null

export const ClarityCard: React.FC<{
  clarityCardData: ClarityCardData
}> = ({ clarityCardData }) => {
  if (!clarityCardData) {
    return null
  }

  const isTwoSided = clarityCardData?.method.includes('Sides')
  const fileType = 'application/pdf'
  let fileContent
  if (isTwoSided) {
    fileContent = [
      {
        name: 'Angle_ID_Card_Front.pdf',
        data: clarityCardData?.data.cardFrontAsPDF
      },
      {
        name: 'Angle_ID_Card_Back.pdf',
        data: clarityCardData?.data.cardBackAsPDF
      }
    ]
  } else {
    fileContent = [
      {
        name: 'Angle_ID_Card_Full.pdf',
        data: clarityCardData?.data.cardAsPDF
      }
    ]
  }

  const mergeFiles = async () => {
    const combinedDoc = await PDFDocument.create()
    for (let file of fileContent) {
      file = await PDFDocument.load(file.data)
      const copiedPages = await combinedDoc.copyPages(
        file,
        file.getPageIndices()
      )
      copiedPages.forEach((page) => combinedDoc.addPage(page))
    }
    return await combinedDoc.saveAsBase64({ dataUri: true })
  }

  const downloadIdCard = async () => {
    const mergedData = await mergeFiles()
    var a = document.createElement('a')
    ;(a as any).style = 'display: none'
    a.href = `${mergedData}`
    a.download = 'Angle_Insurance_ID_Card.pdf'
    document.body.appendChild(a)
    a.click()
    setTimeout(function () {
      document.body.removeChild(a)
    }, 100)
  }

  return (
    <div>
      <div className="d-flex p-4 flex-row justify-content-center align-items-center mt-4">
        {fileContent.map((file,  i) => (
          <div className="p-4" key={i}>
            <Document file={`data:${fileType};base64,${file.data}`}>
              <Page pageNumber={1} scale={3.0} />
            </Document>
          </div>
        ))}
      </div>
      <div className="text-center mt-2">
        <Button onClick={() => downloadIdCard()}>
          Download {fileContent.length > 1 ? 'Cards' : 'Card'}
        </Button>
      </div>
    </div>
  )
}
