import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../hooks'

import { ManuallyAddBankAccountForm } from '../ManuallyAddBankAccountForm'

import { TitleRow } from './styles'

export interface IAchAccount {
  routing_number: string
  account_type: string
  first_name: string
  last_name: string
  account_number: string
}

export interface IManuallyAddBankAccountModalProps {
  show: boolean
  toggleModal: () => void
  addCallback: (account: IAchAccount) => void
}

export const ManuallyAddBankAccountModal: React.FC<IManuallyAddBankAccountModalProps> =
  ({ show, toggleModal, addCallback }) => {
    const auth = useAuth()
    const firstName = auth?.state?.user?.first_name
    const lastName = auth?.state?.user?.last_name

    const { handleSubmit, reset, register, setValue, watch, formState } =
      useForm({
        mode: 'onChange',
        defaultValues: {
          name: '',
          firstName: '',
          lastName: '',
          accountType: '',
          routingNumber: '',
          accountNumber: '',
          acceptedTermsAndConditions: false,
          acceptedTime: new Date()
        }
      })

    useEffect(() => {
      setValue('name', firstName + ' ' + lastName)
    }, [firstName, lastName, setValue, show])

    const acceptedTermsAndConditions = watch('acceptedTermsAndConditions')

    useEffect(() => {
      setValue('acceptedTime', new Date())
    }, [acceptedTermsAndConditions, setValue])

    const registerFunc = register as any

    useEffect(() => {
      if (!show) reset()
    }, [show, reset])

    const handleAdd = handleSubmit((data) => {
      addCallback({
        routing_number: data.routingNumber,
        account_type: data.accountType,
        account_number: data.accountNumber,
        name: data.name,
        acceptedTime: data.acceptedTime,
        acceptedText:
          'I have read the terms and conditions for payment by ACH, and hereby authorize Angle to deduct payment from my bank account by ACH.'
      } as any)
    })

    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={show}
        onHide={toggleModal}
        style={{ zIndex: 99999 }}
        className="add-acc-modal"
      >
        <Modal.Header>
          <Modal.Title>Add a new bank account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TitleRow noGutters>Account information</TitleRow>
          <ManuallyAddBankAccountForm
            register={registerFunc}
            onCancelButtonClick={toggleModal}
            onAddBankAccount={handleAdd}
            watch={watch}
            formState={formState}
          />
        </Modal.Body>
      </Modal>
    )
  }
