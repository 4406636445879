import React, { ReactNode } from 'react'
import {
  DirectoryContent,
  DirectoryWrapper,
  LeftColumn,
  ListContainer,
  ResponsiveMapContainer,
  RightColumn
} from './styles'

export interface IDirectoryLayout {
  filters: ReactNode
  search: ReactNode
  list: ReactNode
  map: ReactNode
  navbarHeight?: number
  [x: string]: any
}

export const DirectoryLayout: React.FC<IDirectoryLayout> = ({
  filters,
  search,
  list,
  map,
  navbarHeight = 189,
  ...p
}) => (
  <DirectoryWrapper {...p}>
    {filters}
    <DirectoryContent>
      <LeftColumn navbarHeight={navbarHeight}>
        {search}
        <ListContainer>{list}</ListContainer>
      </LeftColumn>
      <RightColumn navbarHeight={navbarHeight}>
        <ResponsiveMapContainer navbarHeight={navbarHeight}>
          {map}
        </ResponsiveMapContainer>
      </RightColumn>
    </DirectoryContent>
  </DirectoryWrapper>
)

export default DirectoryLayout
