import styled from 'styled-components'

export const SearchInputContainer = styled.div`
  width: 50%;
  margin-top: 1.25rem;
  width: 100%;
  max-width: 45.5rem;
`

export const SearchTitle = styled.h1`
  margin-bottom: 3rem;
`

export const SearchList = styled.div`
  padding-top: 2.25rem;
`
