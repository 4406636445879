import React from 'react'
import styled from 'styled-components'

import { Logo } from '../components/Logo'
import { Breadcrumb, Container, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Main = styled.main`
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  overflow-y: auto;
  margin-top: 75px;
`

const DefaultNav = () => (
  <Navbar bg="primary" className="p-2 px-3" fixed="top">
    <Navbar.Brand>
      <Logo variant="full" />
    </Navbar.Brand>
  </Navbar>
)

export interface IBreadcrumb {
  link: string
  title: string
}
export interface IPortalLayoutProps {
  children: React.ReactNode
  breadcrumbs: IBreadcrumb[]
  nav?: React.ReactNode
  [x: string]: any
}

export const PortalLayout: React.FC<IPortalLayoutProps> = ({
  children,
  breadcrumbs = [],
  noBreadcrumbs = false,
  nav
}) => (
  <Container fluid className="px-0 vh-100 d-flex">
    {nav || <DefaultNav />}
    <Main>
      {!noBreadcrumbs && (
        <Breadcrumb>
          {breadcrumbs.length ? (
            breadcrumbs.map((crumb: any, key) => (
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{ to: crumb.link }}
                key={key}
                active={breadcrumbs.length === key + 1}
                className="fs-16"
              >
                {crumb.title}
              </Breadcrumb.Item>
            ))
          ) : (
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          )}
        </Breadcrumb>
      )}
      {children}
    </Main>
  </Container>
)
