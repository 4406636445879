import React, { useContext, useState } from 'react'
import { IDrugProfile, IDrugDosage } from '../interfaces'

type DrugProfileContextValue = IDrugProfile & {
  selectByForm: (formName: string) => void
}

export const DrugProfileContext = React.createContext<DrugProfileContextValue>(
  {} as DrugProfileContextValue
)

export const useDrugProfile = () => useContext(DrugProfileContext)

const mapToCurrentProfile = (
  drugProfile: IDrugProfile,
  formSelected: IDrugDosage
) => {
  const profile: IDrugProfile = {
    ...drugProfile,
    tier: formSelected.tier,
    specialty_drug: formSelected.specialty_drug,
    pa_applies: formSelected.pa_applies,
    is_covered: formSelected.is_covered,
    quantity_limit: formSelected.quantity_limit,
    age_limit: formSelected.age_limit,
    gender: formSelected.gender
  }

  return profile
}

export const DrugProfileProvider: React.FC<{ drugProfile: IDrugProfile }> = ({
  children,
  drugProfile
}) => {
  const initialState: any = drugProfile

  const [current, setCurrent] = useState(initialState)

  const selectByForm = (index: string) => {
    const currentSelected = drugProfile.dosages[index]

    if (!currentSelected) return

    const state = mapToCurrentProfile(drugProfile, currentSelected)

    setCurrent(state)
  }

  return (
    <DrugProfileContext.Provider value={{ ...current, selectByForm }}>
      {children}
    </DrugProfileContext.Provider>
  )
}
