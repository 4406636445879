import { unformatPhoneNumber, states } from '@angle/utils'
import { IBusinessOnboardingDTO } from '../../interfaces'
import { getNewCurrentStep, stateAbrvs } from '../../utils/utils'

export const mapDTOtoBusinessInformation = (data: IBusinessOnboardingDTO) => {
  return {
    groupName: data.group_name || '',
    dba: data.dba_name || '',
    phone: data.business_contact_info?.work_phone || '',
    businessType: data.business_type || '',
    taxId: data.ein || '',
    sic: data.sic,
    industry: data.industry || '',
    payrollPlatforms: data.payroll_platforms || [],
    hrPlatforms: data.hr_platforms || [],
    businessAddress: {
      street1: data.business_address?.street_1 || '',
      street2: data.business_address?.street_2 || '',
      state: states[data.business_address?.state] || '',
      county: data.business_address?.county || '',
      city: data.business_address?.city || '',
      zip: data.business_address?.zip || ''
    },
    mailingAddress: {
      street1: data.mailing_address?.street_1 || '',
      street2: data.mailing_address?.street_2 || '',
      state: states[data.mailing_address?.state] || '',
      county: data.mailing_address?.county || '',
      city: data.mailing_address?.city || '',
      zip: data.mailing_address?.zip || ''
    },
    stepNumber: data.in_progress_state
  }
}

export const mapBusinessInformationToDTO = (
  formData: any,
  mailingEqualsBusiness: boolean,
  backendStepNumber: number
) => {
  const business_address = {
    street_1: formData.businessAddress.street1,
    street_2: formData.businessAddress.street2,
    state: stateAbrvs[formData.businessAddress.state] || '',
    county: formData.businessAddress.county,
    city: formData.businessAddress.city,
    zip: formData.businessAddress.zip
  }

  const mailing_address = mailingEqualsBusiness
    ? business_address
    : {
        street_1: formData.mailingAddress.street1,
        street_2: formData.mailingAddress.street2,
        state: stateAbrvs[formData.mailingAddress.state] || '',
        county: formData.mailingAddress.county,
        city: formData.mailingAddress.city,
        zip: formData.mailingAddress.zip
      }

  return {
    onboarding_status: 'in_progress',
    group_name: formData.groupName,
    dba_name: formData.dba,
    business_contact_info: {
      work_phone: unformatPhoneNumber(formData.phone)
    },
    business_type: formData.businessType,
    ein: unformatPhoneNumber(formData.taxId),
    industry: formData.industry,
    payroll_platforms: formData.payrollPlatforms,
    hr_platforms: formData.hrPlatforms,
    business_address,
    mailing_address,
    sic: formData.sic,
    in_progress_state: getNewCurrentStep(backendStepNumber, 2)
  }
}
