import React from 'react'
import {
  Button,
  Col,
  Form,
  Row,
  Modal,
  DatePicker,
  FormControlPrefixWrapper
} from 'react-angle-dashboard-components'
import {
  Controller,
  UseFormRegister,
  Control,
  FormState,
  UseFormWatch
} from 'react-hook-form'
import { IDisclosureData } from '../../../../../interfaces/disclosures'

interface AddOrEditModalProps {
  showModal: boolean
  currentRow: IDisclosureData | null
  register: UseFormRegister<IDisclosureData>
  formState: FormState<IDisclosureData>
  onCloseModal: () => void
  control: Control<IDisclosureData>
  onSave: () => void
  watch: UseFormWatch<IDisclosureData>
}

export const AddOrEditModal: React.FC<AddOrEditModalProps> = ({
  showModal,
  currentRow,
  register,
  formState,
  onCloseModal,
  control,
  onSave,
  watch
}) => {
  const isTermed = watch('status') === 'termed'

  return (
    <Modal
      size="lg"
      style={{ zIndex: 9999 }}
      show={showModal}
      centered
      onHide={() => onCloseModal()}
    >
      <Modal.Header className="border-bottom pb-3" closeButton>
        <Modal.Title>
          {currentRow ? 'Edit participant' : 'Add participant'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Risk Identifier</Form.Label>
                <Form.Control
                  {...register('risk_identifier_name', { required: true })}
                  className="bg-white"
                  placeholder="Risk Identifier"
                  isInvalid={
                    formState?.errors?.risk_identifier_name &&
                    formState?.isSubmitted
                  }
                />
                {formState?.errors?.risk_identifier_name &&
                  formState?.isSubmitted && (
                    <span className="text-danger">Input required</span>
                  )}
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Controller
                  name="date_of_birth"
                  rules={{
                    required: true
                  }}
                  control={control}
                  render={({ field }) => (
                    <DatePicker {...field} backgroundColor="white" />
                  )}
                />
                {formState?.errors?.date_of_birth && formState?.isSubmitted && (
                  <span className="text-danger">Input required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Sex</Form.Label>
                <Form.Control
                  {...register('sex', { required: true })}
                  className="bg-white"
                  as="select"
                  isInvalid={formState?.errors?.sex && formState?.isSubmitted}
                >
                  <option disabled hidden value="">
                    Select a sex
                  </option>
                  <option value="female">Male</option>
                  <option value="male">Female</option>
                  {formState?.errors?.sex && formState?.isSubmitted && (
                    <span className="text-danger">Input required</span>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>EE, Sp, or Ch</Form.Label>
                <Form.Control
                  {...register('member_type', { required: true })}
                  className="bg-white"
                  as="select"
                  isInvalid={
                    formState?.errors?.member_type && formState?.isSubmitted
                  }
                >
                  <option disabled hidden value="">
                    Select an option
                  </option>
                  <option value="employee">Employee</option>
                  <option value="spouse">Spouse</option>
                  <option value="child">Child</option>
                  {formState?.errors?.member_type && formState?.isSubmitted && (
                    <span className="text-danger">Input required</span>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  {...register('status', { required: true })}
                  className="bg-white"
                  as="select"
                  isInvalid={
                    formState?.errors?.status && formState?.isSubmitted
                  }
                >
                  <option hidden value="">
                    Select an option
                  </option>
                  <option value="active">Active</option>
                  <option value="termed">Termed</option>
                  <option value="cobra">Cobra</option>
                  <option value="retiree">Retiree</option>
                  {formState?.errors?.status && formState?.isSubmitted && (
                    <span className="text-danger">Input required</span>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            {isTermed && (
              <Col sm={12} md={6}>
                <Form.Group>
                  <Form.Label>Term Date</Form.Label>
                  <Controller
                    name="term_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker {...field} backgroundColor="white" />
                    )}
                  />
                  {formState?.errors?.term_date &&
                    formState?.isSubmitted &&
                    isTermed && (
                      <span className="text-danger">Input required</span>
                    )}
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Diagnosis</Form.Label>
                <Form.Control
                  {...register('diagnosis', { required: true })}
                  className="bg-white"
                  placeholder="Diagnosis"
                  isInvalid={
                    formState?.errors?.diagnosis && formState?.isSubmitted
                  }
                />
                {formState?.errors?.diagnosis && formState?.isSubmitted && (
                  <span className="text-danger">Input required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Most Recent Date of Service</Form.Label>
                <Controller
                  name="latest_service_date"
                  rules={{
                    required: true
                  }}
                  control={control}
                  render={({ field }) => (
                    <DatePicker {...field} backgroundColor="white" />
                  )}
                />
                {formState?.errors?.latest_service_date &&
                  formState?.isSubmitted && (
                    <span className="text-danger">Input required</span>
                  )}
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Expenses Incurred This Plan Year</Form.Label>
                <FormControlPrefixWrapper
                  prefix={<div className="text-muted">$</div>}
                >
                  <Form.Control
                    type="number"
                    {...register('plan_year_expenses', {
                      required: true
                    })}
                    className="bg-white"
                    isInvalid={
                      formState?.errors?.plan_year_expenses &&
                      formState?.isSubmitted
                    }
                    placeholder="Enter the amount"
                  />
                </FormControlPrefixWrapper>

                {formState?.errors?.plan_year_expenses &&
                  formState?.isSubmitted && (
                    <span className="text-danger">
                      Valid numerical input Required
                    </span>
                  )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="outline-primary" onClick={() => onCloseModal()}>
          Cancel
        </Button>
        <Button onClick={() => onSave()}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}
