import React from 'react'
import { Nav, Tab } from 'react-angle-dashboard-components'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom'
import styled from 'styled-components'
import { PageLayout } from '../layouts/PageLayout'
import { AccountSettings } from './AccountSetting'
import BillingDetails from './BillingDetails'
import { CompanySettings } from './CompanySetting'
import { Security } from './Security/Security'

const InnerContainer = styled.div`
  max-width: 730px;
  width: 100%;
`

export const Settings: React.FC = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const key = pathname.slice(`${path}/`.length)
  /*   const { settings: messages } = useLang().messages */

  const onSelect = (k: any) => {
    history.push(`${path}/${k}`)
  }

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Settings', link: '/settings' }
      ]}
    >
      <Tab.Container id="left-tabs-example" activeKey={key} onSelect={onSelect}>
        <section className="bg-light">
          <InnerContainer className="mt-5 pt-3 mx-auto">
            <h1>Settings</h1>

            <Nav variant="tabs" className="mt-5">
              <Nav.Item className="mr-3">
                <Nav.Link eventKey="account">Account Settings</Nav.Link>
              </Nav.Item>

              <Nav.Item className="mr-3">
                <Nav.Link eventKey="company">Company Settings</Nav.Link>
              </Nav.Item>

              <Nav.Item className="mr-3">
                <Nav.Link eventKey="security">Security</Nav.Link>
              </Nav.Item>

              <Nav.Item className="mr-3">
                <Nav.Link eventKey="billing">Billing</Nav.Link>
              </Nav.Item>
            </Nav>
          </InnerContainer>
        </section>
        <InnerContainer className="mt-5 mx-auto pb-5">
          <Tab.Content>
            <Switch>
              <Route
                exact
                path={`${path}/account`}
                component={AccountSettings}
              />
              <Route
                exact
                path={`${path}/company`}
                component={CompanySettings}
              />
              <Route exact path={`${path}/security`} component={Security} />
              <Route
                exact
                path={`${path}/billing`}
                component={BillingDetails}
              />
              <Redirect to={`${path}/account`}></Redirect>
            </Switch>
          </Tab.Content>
        </InnerContainer>
      </Tab.Container>
    </PageLayout>
  )
}

export default Settings
