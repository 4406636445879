import React, { SetStateAction, Dispatch } from 'react'
import { Col, Form, Row } from 'react-angle-dashboard-components'
import { Controller, Control, FormState } from 'react-hook-form'
import { getRequiredObject } from '../../../../../utils/formValidations'
import { CarrierFormData } from '../../../interfaces'
import { ValidationError } from '../../style'

const labels = {
  lessThanThree:
    'Has the company been with the current medical insurance carrier less than three (3) years?'
}
export const LessThanThree: React.FC<{
  control: Control
  formState: FormState<CarrierFormData>
  setLessThanThree: Dispatch<SetStateAction<'true' | 'false'>>
}> = ({ setLessThanThree, control, formState }) => {
  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <Form.Label className="required-field">
            {labels.lessThanThree}
          </Form.Label>
        </Col>
      </Row>
      <Form.Group>
        <Row>
          <Col xs={12} md={7}>
            <Controller
              name="lessThanThree"
              control={control}
              rules={{
                required: getRequiredObject(labels.lessThanThree)
              }}
              render={({ field }) => (
                <Form.Check
                  {...field}
                  className="custom-radio"
                  type="radio"
                  value="false"
                  label="No"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setLessThanThree('false')
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={7}>
            <Controller
              name="lessThanThree"
              control={control}
              render={({ field }) => (
                <Form.Check
                  {...field}
                  className="custom-radio"
                  type="radio"
                  value="true"
                  label="Yes"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setLessThanThree('true')
                  }}
                />
              )}
            />
          </Col>
          <ValidationError>
            {formState.isSubmitted && formState.errors?.lessThanThree?.message}
          </ValidationError>
        </Row>
      </Form.Group>
    </>
  )
}
