import React, { MouseEventHandler } from 'react'
import { Button } from 'react-bootstrap'
import { IFilter } from '../../../interfaces/directory-filters'
import { CheckboxFilter } from '../../CheckboxFilter'
import { RangeWithBarsFilter } from '../../RangeWithBarsFilter'
import { SelectFilter } from '../../SelectFilter'
import { SwitchFilter } from '../../SwitchFilter'

import { ClearNameFilterIcon, FiltersContainer, NameFilter } from './style'

const filterRightGutter = {
  margin: '10px 10px 0 0'
}
interface IDirectoryFiltersProps {
  filters: IFilter[]
  searchAsIMove: boolean
  onFilter: (appliedFilter: any, index: any) => void
  nameFilter?: string
  clearNameFilter?: MouseEventHandler<HTMLDivElement>
  backBtnCallback?: MouseEventHandler<HTMLElement>
}

export const DirectoryFilters: React.FC<IDirectoryFiltersProps> = ({
  filters,
  onFilter,
  searchAsIMove = false,
  nameFilter,
  clearNameFilter,
  backBtnCallback
}) => {
  return (
    <FiltersContainer>
      <div className="d-flex align-items-center justify-content-start">
        {filters.map((f, i) => {
          switch (f.type) {
            case 'checkbox':
              return (
                <CheckboxFilter
                  data-testid="checkbox"
                  style={filterRightGutter}
                  name={f.name}
                  value={f.value}
                  onChange={(appliedFilter: any) => onFilter(appliedFilter, i)}
                  key={f.filterKey}
                />
              )
            case 'slider':
              return (
                <RangeWithBarsFilter
                  data-testid="slider"
                  style={filterRightGutter}
                  name={f.name}
                  componentWidth={f.componentWidth}
                  maxValue={f.maxValue}
                  unitSuffix={f.unitSuffix}
                  value={f.value}
                  onChange={(appliedFilter: any) => onFilter(appliedFilter, i)}
                  dropdownDisabled={searchAsIMove}
                  key={f.filterKey}
                />
              )
            case 'switch':
              return (
                <SwitchFilter
                  data-testid="switch"
                  style={filterRightGutter}
                  name={f.name}
                  label={f.label}
                  onChange={(appliedFilter: any) => onFilter(appliedFilter, i)}
                  value={f.value}
                  key={f.filterKey}
                />
              )

            case 'select':
              return (
                <SelectFilter
                  style={filterRightGutter}
                  name={f.name}
                  value={f.value}
                  onChange={(appliedFilter: any) => onFilter(appliedFilter, i)}
                  options={f.options}
                  key={f.filterKey}
                />
              )

            default:
              return null
          }
        })}
        {nameFilter && (
          <NameFilter>
            <span>{nameFilter}</span>{' '}
            <ClearNameFilterIcon
              className="icon icon-cancel fs-16"
              onClick={clearNameFilter}
            />
          </NameFilter>
        )}
      </div>
      {backBtnCallback && (
        <Button
          variant="primary"
          onClick={backBtnCallback}
          size="sm"
          style={{ margin: '10px 10px 0px 0px' }}
        >
          Back to list
        </Button>
      )}
    </FiltersContainer>
  )
}

export default DirectoryFilters
