import React, { ReactNode } from 'react'
import { IFacility, IProvider } from '../../../interfaces'
import { PaginatorContainer } from '../../../layouts/styles'
import { DirectoryItemCard } from '../../DirectoryItemCard'

import { ListContainer } from './style'

interface IDirectoryListProps {
  items: IProvider[] | IFacility[]
  circledItems?: boolean
  cardClickHandler?: Function
  hoverCardHandler?: Function
  paginator: ReactNode
  [x: string]: any
}

const List: React.FC<IDirectoryListProps> = ({
  items,
  circledItems = false,
  cardClickHandler,
  hoverCardHandler,
  paginator,
  ...p
}) => (
  <ListContainer {...p}>
    {items.map((item: IFacility | IProvider) => (
      <DirectoryItemCard
        image={item.coverPicture}
        circledImage={circledItems}
        title={item.name}
        subtitle={item.type}
        distance={item.distance}
        key={item.id}
        onClick={() => (cardClickHandler ? cardClickHandler(item.id) : null)}
        onMouseEnter={() =>
          hoverCardHandler ? hoverCardHandler(item.coordinates, item.id) : null
        }
        style={{ borderBottom: '1px solid var(--card-border)' }}
      >
        <span className="d-block">{item.address.split(':')[0]}</span>
        <span className="d-block">{item.address.split(':')[1]}</span>
      </DirectoryItemCard>
    ))}
    <PaginatorContainer>{paginator}</PaginatorContainer>
  </ListContainer>
)

export const DirectoryList = React.memo(List)
export default DirectoryList
