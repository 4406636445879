import React from 'react'
import {
  Table,
  TableHeader,
  CenteredSpinner,
  Button,
  IDocument,
  IDocumentResponse,
  DocumentService,
  InfoWithIcon
} from '../../../../index'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

export const DocumentsTab: React.FC<{
  documentService: DocumentService
  isBrokerPortal: boolean
}> = ({ documentService, isBrokerPortal }) => {
  const { groupId } = useParams<{ groupId: string }>()

  const onDownload = async (doc: IDocument) => {
    if (doc.document_id) {
      let apiPath = `/documents/${doc.document_id}`
      if (isBrokerPortal && groupId) {
        apiPath = `/groups/${groupId}` + apiPath
      }
      await documentService.downloadDocument(apiPath, {
        contentType: doc.document_type,
        filename: doc.document_name
      })
    } else {
      window.open(doc.url, '_blank')
    }
  }
  const queryKey = isBrokerPortal ? ['documents', groupId] : ['documents']

  const { data, isLoading, isError } = useQuery(
    queryKey,
    () =>
      documentService.findDocuments(isBrokerPortal && groupId ? groupId : ''),
    {
      retry: 0,
      refetchOnWindowFocus: false
    }
  )
  const docs: IDocumentResponse[] = (data as any)?.data || []
  return (
    <section>
      {isLoading && <CenteredSpinner style={{ height: 'auto' }} />}
      {isError && (
        <InfoWithIcon
          iconEl={
            <i
              className="icon icon-error text-danger"
              style={{ fontSize: '35px', marginBottom: '0.625rem' }}
            />
          }
        >
          An unknown error ocurred. Please contact Angle Health for support.
        </InfoWithIcon>
      )}
      {docs &&
        docs.map((e, i) => (
          <div key={i} className="mb-5">
            <h6 className="mb-3 fs-18 font-weight-bold">{e.category}</h6>
            <Table className="mb-3">
              <thead className="thead-light">
                <tr>
                  <TableHeader className="py-2" wide>
                    Document Name
                  </TableHeader>
                  <TableHeader className="py-2" />
                </tr>
              </thead>
              {e.documents.length ? (
                <tbody>
                  {e.documents.map((document, i) => (
                    <tr key={i}>
                      <td>{document.description || document.document_name}</td>
                      <td>
                        <Button
                          variant="link"
                          className="py-1"
                          onClick={() => onDownload(document)}
                        >
                          Download
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={2} className="text-center">
                      There are no documents available.
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        ))}
    </section>
  )
}

export default DocumentsTab
