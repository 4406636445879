import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ReportCardDescription, ReportCardTitle, SelectionCard } from './style'
import { useMutation, useQuery } from 'react-query'
import moment from 'moment'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { ReportTypes, ReportingService } from '../../services'
import { PageHeaderLight } from '../PageHeaderLight'
import { PageContainer } from '../PageContainer'
import { Document, usePDF } from '@react-pdf/renderer'
import ReportPage from './ReportPage'
import { noSubGroup } from './consts'

import type { BulkReportData, ReportData } from './types'

const ReportCard = ({
  title,
  description,
  value,
  checked,
  onSelect,
  disabled = false
}: {
  title: string
  description: string
  value: string
  checked: boolean
  onSelect: (value: string) => void
  disabled?: boolean
}) => {
  return (
    <SelectionCard
      onClick={() => {
        if (!disabled) {
          onSelect(value)
        }
      }}
      style={{
        opacity: disabled ? 0.5 : 1
      }}
    >
      <Row>
        <Col>
          <ReportCardTitle>{title}</ReportCardTitle>
        </Col>
        <Col className="col-auto">
          <Form.Check readOnly type="radio" name="report" checked={checked} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ReportCardDescription>{description}</ReportCardDescription>
        </Col>
      </Row>
    </SelectionCard>
  )
}

interface ReportingFormProps {
  reportingService: ReportingService
  selectedPlanYearId: number | undefined
  setSelectedPlanYearId: (id: number | undefined) => void
  selectedReportId: ReportTypes | undefined
  setSelectedReportId: (id: ReportTypes | undefined) => void
  selectedSubgroup: string | undefined
  setSelectedSubgroup: (subgroup: string | undefined) => void
  reportData: ReportData | undefined
  setReportData: (reportData: ReportData | undefined) => void
  onViewReport: () => void
  groupName: string
  hideHeader: boolean
  groupId?: string
}

const ReportingForm = ({
  reportingService,
  selectedPlanYearId,
  setSelectedPlanYearId,
  selectedReportId,
  setSelectedReportId,
  selectedSubgroup,
  setSelectedSubgroup,
  reportData,
  setReportData,
  onViewReport,
  groupId,
  hideHeader,
  groupName
}: ReportingFormProps) => {
  const { data: choices, isLoading } = useQuery(
    ['reports'],
    () => reportingService.getChoices(groupId),
    {
      initialData: {
        planYears: [],
        subgroups: [],
      }
    }
  )

  const { mutateAsync: generateBulkReports } = useMutation(() => {
    if (selectedPlanYearId) {
      return reportingService.generateBulkReports(
        {
          planYearId: selectedPlanYearId,
          subgroup: selectedSubgroup,
        },
        groupId)
    }
    return new Promise(() => undefined)
  })

  const planYearChoices = choices?.planYears ?? choices?.data  // `choices.data` is for backwards compatibility
  const subgroupChoices = choices?.subgroups

  const reports = useMemo(() => {
    return (
      planYearChoices?.find((c) => c.planYearId === selectedPlanYearId)?.reportTypes ??
      []
    )
  }, [planYearChoices, selectedPlanYearId])

  useEffect(() => {
    if ((planYearChoices?.length ?? 0) > 0) {
      setSelectedPlanYearId(planYearChoices?.[0].planYearId)
    }
  }, [planYearChoices, setSelectedPlanYearId])

  useEffect(() => {
    if (reports.length > 0) {
      setSelectedReportId(reports[0].reportTypeId)
    }
  }, [reports, setSelectedReportId])

  useEffect(() => {
    setSelectedSubgroup(noSubGroup)
  }, [subgroupChoices, setSelectedSubgroup])

  const downloadLinkRef = useRef<HTMLAnchorElement>(null)
  const [isLoadingBulk, setIsLoadingBulk] = useState(false)
  const [isDocumentReady, setIsDocumentReady] = useState(false)
  const [instance, update] = usePDF({ document: <Document /> })

  useEffect(() => {
    if (
      isLoadingBulk &&
      isDocumentReady &&
      !instance.error &&
      !instance.loading
    ) {
      downloadLinkRef.current?.click()
      setIsLoadingBulk(false)
      setIsDocumentReady(false)
    }
  }, [instance.error, instance.loading, isDocumentReady, isLoadingBulk])

  const onDownloadClick = async () => {
    try {
      setIsLoadingBulk(true)
      setIsDocumentReady(false)
      const res: BulkReportData | undefined = await generateBulkReports()
      setReportData(res)
      update(
        <Document>
          {res?.map(({ reportType: type, reportData: r }) => (
            <ReportPage
              key={type}
              title={r.title}
              groupName={groupName}
              description={r.description}
              table={r.table}
              footer={r.footer}
              extra={r.extra}
            />
          ))}
        </Document>
      )
      setTimeout(() => {
        setIsDocumentReady(true)
      }, 2000)
    } catch {
      setIsLoadingBulk(false)
    }
  }

  return (
    <>
      {!hideHeader && (
        <PageHeaderLight>
          <PageContainer className="mt-4 mb-2 pb-2" size="lg">
            <Row>
              <Col md={8}>
                <h1>View a Report</h1>
              </Col>
              <Col></Col>
            </Row>
          </PageContainer>
        </PageHeaderLight>
      )}
      <PageContainer size="lg">
        {isLoading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Period Starting</Form.Label>
                  <Form.Control
                    as="select"
                    className="bg-white"
                    onChange={(e: any) =>
                      setSelectedPlanYearId(Number(e.target.value))
                    }
                    value={selectedPlanYearId}
                  >
                    {planYearChoices?.map((choice) => (
                      <option value={choice.planYearId}>
                        {moment(choice.planYearStartDate).format('YYYY-MM-DD')}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              {subgroupChoices?.length && (
                <Col>
                  <Form.Group>
                    <Form.Label>Subgroups</Form.Label>
                    <Form.Control
                      as="select"
                      className="bg-white"
                      onChange={(e: any) =>
                        setSelectedSubgroup(e.target.value)
                      }
                      value={selectedSubgroup ?? noSubGroup}
                    >
                      <option value={noSubGroup}>All</option>
                      {subgroupChoices?.map((choice) => (
                        <option value={choice}>{choice}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}
              <Col className="d-flex justify-content-end ">
                <div>
                  <Button
                    variant="primary"
                    onClick={onDownloadClick}
                    disabled={isLoadingBulk}
                  >
                    Download All For This Period{' '}
                    {isLoadingBulk && <Spinner animation="border" />}
                  </Button>
                </div>
                <a
                  hidden
                  ref={downloadLinkRef}
                  href={instance.url ?? undefined}
                  download={`${groupName} ${new Date().toLocaleDateString()} Reports.pdf`}
                >
                  download
                </a>
              </Col>
            </Row>
            {reports.length > 0 ? (
              <>
                <Form.Group>
                  <Form.Label>Select Report</Form.Label>
                  <div
                    className="d-flex flex-wrap"
                    style={{
                      rowGap: 15
                    }}
                  >
                    {reports.map((r) => (
                      <ReportCard
                        title={r.title}
                        description={r.description}
                        value={r.reportTypeId}
                        onSelect={(val) => {
                          return setSelectedReportId(val as ReportTypes)
                        }}
                        checked={r.reportTypeId === selectedReportId}
                        disabled={!r.enabled}
                      />
                    ))}
                  </div>
                </Form.Group>
                <Button
                  disabled={!selectedPlanYearId || !selectedReportId}
                  onClick={onViewReport}
                >
                  View Report
                </Button>
              </>
            ) : (
              <>
                <h2>No reports available for this period</h2>
                <p>
                  Report availability is based on group size and coverage type.
                  This group does not currently meet the requirements for
                  reports in this period.
                </p>
              </>
            )}
          </>
        )}
      </PageContainer>
    </>
  )
}

export default ReportingForm
