import React from 'react'
import { EmployerPortalBilling } from 'react-angle-dashboard-components'
import { billingService, groupService, documentService } from '../../services'
import { PageLayout } from '../layouts/PageLayout'
import { Paths } from '../../routes/paths'

import './Billing.css'

export const Billing: React.FC<any> = () => {
  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Billing', link: '/billing' }
      ]}
    >
      <EmployerPortalBilling
        isBrokerPortal={false}
        groupService={groupService}
        billingService={billingService}
        documentService={documentService}
        billingSettingsPath={Paths.settingsBilling}
      />
    </PageLayout>
  )
}

export default Billing
