import React from 'react'
import { Card, ProgressBar } from '../../index'

export interface ZeroDeductibleCardProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const ZeroDeductibleCard: React.FC<ZeroDeductibleCardProps> = ({
  ...p
}) => {
  return (
    <Card {...p}>
      <Card.Body className="py-3">
        <h6>Deductible</h6>
        <div className="fs-28 mt-2">
          <span>$0 Deductible</span>
        </div>
        <ProgressBar now={100} className="mb-1 mt-2" />
        <small className="text-secondary">$0 remaining</small>
        <hr className="mt-1 mb-0" />
        <div style={{ paddingTop: 5, marginBottom: -6 }}>
          <span className="text-secondary fs-12">
            You have a zero deductible plan
          </span>
        </div>
      </Card.Body>
    </Card>
  )
}
