export type Coordinates = {
  lat: number
  lng: number
}

export type Details = {
  name: string
  value: string
}

export type Gallery = {
  src: string
  id: string
}

export type Hours = {
  label: string
  hours: string
}

export type AboutDescription = {
  content: string[]
  helpButton?: string
  icon: string
  title: string
}

export interface Name {
  title?: string
  first_name?: string
  middle_name?: string
  last_name?: string
  suffix?: string
}

export const badgeVariants = {
  'good value': 'success',
  'no cost data': 'gray'
}
