import { StyleSheet } from '@react-pdf/renderer'

const tableStyles = StyleSheet.create({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 17,
    borderTop: '1px solid #E7EAF3'
  },
  cell: {
    flex: 1,
    padding: 2
  },
  cellText: {
    fontSize: 7
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  descriptionText: {
    color: '#6c757d',
    fontSize: 8
  }
})

export default tableStyles
