import React, { useCallback, useEffect, useState } from 'react'
import {
  BankAccounts,
  Button,
  Col,
  Container,
  Form,
  Row,
  useToaster,
  Alert
} from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { Paths } from '../../../routes/paths'
import { useQuery } from 'react-query'
import {
  authService,
  paymentsService,
  plaidService as PlaidService,
  groupService
} from '../../../services'
import { IBusinessOnboardingDTO } from '../../BusinessOnboarding/interfaces'

export interface PlaidAccount {
  id: string
  name: string
  mask: string
  type: string
  subtype: string
}

export interface PlaidResponse {
  institution: {
    institution_id: string
    name: string
  }
  accounts: Array<PlaidAccount>
  link_session_id: string
  public_token: string
}

export interface DisplayAccount {
  item_id?: string
  institution_name: string
  is_default: boolean
  account_id: string
  mask: string
  account_type: string
  has_issues: boolean
}

const mapDTOtoBilling = (data: IBusinessOnboardingDTO) => {
  return {
    groupId: data.group_id
  }
}

export const BillingDetails = () => {
  const [initialAutopayState, setInitialAutopayState] = useState(false)
  const [autopay, setAutopay] = useState(false)
  const [initialDefaultAccount, setInitialDefaultAccount] =
    useState<DisplayAccount>(null)
  const [defaultAccount, setDefaultAccount] = useState<DisplayAccount>(null)
  const [paymentMethods, setPaymentMethods] = useState<any[]>([])
  const [isLoadingList, setIsLoadingList] = useState(false)
  const [dirty, setDirty] = useState(false)
  const { show: setToastr } = useToaster()
  const history = useHistory()

  const { data: paymentSettingsData, refetch: refetchBillingSettings } =
    useQuery(['payment-settings'], () => paymentsService.getSettings())

  const { data: group} = useQuery(
    'getBilling',
    () => groupService.getInfo(mapDTOtoBilling, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (data: any) => {}
    }
  )

  useEffect(() => {
    if (paymentSettingsData) {
      setInitialAutopayState(paymentSettingsData.autopay)
      setAutopay(paymentSettingsData.autopay)
    }
  }, [paymentSettingsData])

  useEffect(() => {
    const newDefaultAccount = paymentMethods.find(
      (a: DisplayAccount) => a.is_default
    )
    const newIsDirty = initialDefaultAccount?.item_id !== newDefaultAccount?.item_id
    setDefaultAccount(newDefaultAccount)
    setDirty(newIsDirty)
  }, [paymentMethods, initialDefaultAccount])

  const onAutoPayHandler = useCallback(async () => {
    try {
      await paymentsService.setSettings({
        autopay: autopay
      })
      
      refetchBillingSettings()
      setInitialDefaultAccount(null)
      setDefaultAccount(null)
      setDirty(false)
      setToastr({
        message: 'Autopay Settings saved.',
        icon: 'icon-correct',
        type: 'success'
      })
    } catch(e) {
      setToastr({
        message: `Unable to save autopay settings. ${e}`,
        icon: 'icon-danger',
        type: 'danger'
      })
    }
  }, [setToastr, autopay, refetchBillingSettings])

  const handleSaveChanges = async () => {
    if (!defaultAccount) return

    setIsLoadingList(true)
    try {
      await paymentsService.setAccountAsDefault(defaultAccount)

      setToastr({
        message: 'Changes saved.',
        icon: 'icon-correct',
        type: 'success'
      })
      setIsLoadingList(false)
      setDirty(false)
      setInitialDefaultAccount(defaultAccount)
      refetchBillingSettings()
    } catch {
      setToastr({
        message: 'An error has occurred trying to save the information.',
        icon: 'icon-danger',
        type: 'danger'
      })
      setIsLoadingList(false)
    }
  }

  return (
    <Container className="p-3">
      <section>
        <div>
          <Row>
            <Col>
              <b>Autopay</b>
            </Col>
            <Col className="text-right">
              <Form.Check
                type="switch"
                id="autopay"
                checked={autopay}
                onChange={(e) => {
                  setAutopay(e.target.checked)
                }}
                disabled={!defaultAccount || dirty}
              />
            </Col>
          </Row>
          <Row>
            {defaultAccount && !dirty ? (
              <Col className="text-right">
                <Button
                  className="mt-2"
                  size={'sm'}
                  soft={true}
                  ghost={true}
                  disabled={
                    autopay === initialAutopayState ||
                    (autopay && !defaultAccount)
                  }
                  onClick={() => onAutoPayHandler()}
                >
                  Save Autopay settings
                </Button>
              </Col>
            ) : (
              <Col className="mt-4">
                <Alert
                  variant="warning"
                  style={{
                    backgroundColor: '#DC354520'
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center p-1">
                    <span>
                      Please finish making changes and saving the default bank account first, found below.
                    </span>
                  </div>
                </Alert>
              </Col>
            )}
          </Row>
        </div>
      </section>
      <section className="border-top mt-4 pt-4">
        <BankAccounts
          initialAutopayState={initialAutopayState}
          autopay={autopay}
          userAccounts={paymentMethods}
          setUserAccounts={setPaymentMethods}
          setInitialDefaultAccount={setInitialDefaultAccount}
          paymentsService={paymentsService}
          plaidService={PlaidService}
          authService={authService}
          groupService={groupService}
          groupId={group?.data?.groupId}
          onAddAch={() => history.push(Paths.addManualAccount)}
          notUpdateOnSelection
        />
      </section>
      {dirty && (
        <Row>
          <Col className="my-2">
            <Alert
              variant="warning"
              style={{
                backgroundColor: '#DC354520'
              }}
            >
              <div className="d-flex align-items-center justify-content-center p-1">
                <span>
                  There are unsaved changes, please click on Save Changes to
                  finalize your edits.
                </span>
              </div>
            </Alert>
          </Col>
        </Row>
      )}

      {paymentMethods.length !== 0 && !isLoadingList ? (
        <section className="text-right my-2">
          <Button onClick={handleSaveChanges} disabled={autopay !== initialAutopayState}>Save Changes</Button>
        </section>
      ) : null}
    </Container>
  )
}

export default BillingDetails
