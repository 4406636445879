import styled from 'styled-components'

export const FloatingContainer = styled.div<{withoutContainer: boolean;}>`
  position: ${(props) => (props.withoutContainer ? 'inherit' : 'absolute')};
  width: ${(props) => (props.withoutContainer ? '100%' : '94%')};

  @media (max-width: 767px) {
    position: relative;
    width: 100%;
  }
`
export const InfoRow = styled.div<{ borderBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.825rem;
  border-bottom: 1px solid var(--gray);

  &:last-child {
    border-bottom: ${(props) =>
      props.borderBottom ? '1px solid var(--gray);' : 'none'};
  }

  &:first-child {
    border-bottom: ${(props) =>
      props.borderBottom ? '1px solid var(--gray);' : 'none'};
  }
`
