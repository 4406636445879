import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Avatar,
  AnglePlanBadge,
  Button,
  Badge,
  EmployeeProfile,
  EightThreeFourService,
  EmployerPortalTerminateEmployee,
  EmployerPortalEditCoverageModal,
  SummaryService,
  PlanService
} from '../../../index'
import {
  Sidebar,
  ProfileRow,
  ProfileItemContainer,
  ProfileItemHeader
} from './style'
import { useHistory, useParams } from 'react-router-dom'

interface IEmployeeProfileSidebarProps {
  employee: EmployeeProfile | undefined
  complete?: boolean
  empty?: boolean
  dependent?: boolean
  isBrokerPortal?: boolean
  eightThreeFourService: EightThreeFourService
  summaryService: SummaryService
  plansService: PlanService
  refetchEmployee: () => void
  coverageEffectiveDate?: string | undefined
  coverageTerminationDate?: string | undefined
  planName?: string | undefined
  numCovered?: number
}

const EmployeeProfileSidebar: React.FC<IEmployeeProfileSidebarProps> = ({
  employee,
  dependent = false,
  isBrokerPortal,
  eightThreeFourService,
  refetchEmployee,
  coverageEffectiveDate,
  coverageTerminationDate,
  planName,
  numCovered,
  summaryService,
  plansService
}) => {
  const history = useHistory()
  const { id, dependentId, groupId } = useParams<{
    id: string
    dependentId: string
    groupId: string
  }>()

  const [isTerminationModalVisible, setTerminationModalVisible] =
    useState(false)
  const [isEditCoverageModalVisible, setEditCoverageModalVisible] =
    useState(false)

  if (employee == null) {
    return null
  }

  const currentDate = new Date()
  let idCardPath =
    dependent && dependentId
      ? `/employees/${id}/dependents/${dependentId}/id-card`
      : `/employees/${id}/id-card`

  if (isBrokerPortal) {
    idCardPath = `/groups/${groupId}${idCardPath}`
  }

  const coverageStartAsDatePlus60 = new Date(employee?.coverageStart); 
  coverageStartAsDatePlus60.setDate(coverageStartAsDatePlus60.getDate() + 60)
  return (
    <>
      <Sidebar className="d-flex flex-column justify-content-between">
        <div>
          <ProfileRow className="bg-light">
            <Col xs="2" sm="12" style={{ marginBottom: '10px' }}>
              <Avatar
                circle
                style={{ height: 54, width: 54 }}
                fallbackContent={employee?.name ? employee?.name[0] : ''}
                variant="primary"
              />
            </Col>
            <Col xs="10" sm="12" className="pb-2">
              <h3 className="mb-0">{employee?.name}</h3>
              <span className="text-secondary">
                {employee?.age ? `${employee?.age} years old` : '-'}
              </span>
            </Col>
            {(currentDate > new Date(employee?.coverageEnd) || employee.terminated) && (
              <Col xs="12" className="pt-1 pb-2">
                <Badge variant="danger">Terminated</Badge>
              </Col>
            )}
            <Col xs="12" className="border-top p-1 pt-3">
              <Row className="d-flex align-items-center justify-content-around">
                <div>
                {employee?.metalTier || planName || employee?.plan ? (
                    <AnglePlanBadge
                      metalTier={employee?.metalTier}
                      planName={planName ? planName : employee?.plan}
                    />
                  ) : (
                    <p>The plan has not been added</p>
                  )}
                </div>
                <Button
                  variant="primary"
                  ghost
                  disabled={currentDate >= coverageStartAsDatePlus60 || dependent || employee?.coverageStart != coverageEffectiveDate}
                  size="sm"
                  onClick={() => setEditCoverageModalVisible(true)}
                >
                  Edit Coverage
                </Button>
              </Row>
            </Col>
          </ProfileRow>
          <Row className="p-3">
            <Col>
              <Button
                className="w-100"
                onClick={() => history.push(idCardPath)}
              >
                View Member ID Card
              </Button>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col
                style={{ paddingLeft: 0 }}
                xs={12}
                className="d-flex flex-wrap"
              >
                {!dependent && (
                  <ProfileItemContainer>
                    <ProfileItemHeader>Plan Covers</ProfileItemHeader>
                    <h6>{`${
                      numCovered ? numCovered : employee.dependents.length + 1
                    } ${
                      (numCovered && numCovered > 1) ||
                      employee.dependents.length + 1 > 1
                        ? 'members'
                        : 'member'
                    }`}</h6>
                  </ProfileItemContainer>
                )}
              </Col>
              <Col
                xs={12}
                style={{
                  padding: 0,
                  borderTop: '1px solid #E7EAF3'
                }}
                className="d-flex justify-content-between"
              >
                <ProfileItemContainer>
                  <ProfileItemHeader>Coverage Effective Date</ProfileItemHeader>
                  <h6>
                    {coverageEffectiveDate
                      ? coverageEffectiveDate
                      : employee?.coverageStart || '-'}
                  </h6>
                </ProfileItemContainer>
                <ProfileItemContainer>
                  <ProfileItemHeader>Coverage End Date</ProfileItemHeader>
                  <h6>
                    {coverageTerminationDate
                      ? coverageTerminationDate
                      : employee?.coverageEnd || '-'}
                  </h6>
                </ProfileItemContainer>
              </Col>
            </Row>
          </Container>
        </div>
        {!dependent && (
          <Row className="p-3 py-1">
            <Col
              xs={12}
              style={{
                padding: '15px 15px',
                borderTop: '1px solid #E7EAF3'
              }}
              className="d-flex justify-content-end mt-10"
            >
              {employee.coverageEnd === '-' || 
              currentDate > new Date(employee.coverageEnd) ? (
                <Button
                  className="w-100"
                  style={{ backgroundColor: '#6D39D9', borderColor: '#6D39D9' }}
                  onClick={() => {
                    history.push(
                      isBrokerPortal
                        ? `/groups/${groupId}/employees/registration`
                        : '/employees/registration',
                      {
                        employeeData: employee
                      }
                    )
                  }}
                >
                  Reinstate & Add Coverage
                </Button>
              ) : (
                <Button
                  className="w-100"
                  style={{ backgroundColor: '#DC3545', borderColor: '#DC3545' }}
                  onClick={() => setTerminationModalVisible(true)}
                >
                  Terminate Employee
                </Button>
              )}
            </Col>
          </Row>
        )}
      </Sidebar>
      <EmployerPortalTerminateEmployee
        isTerminationModalVisible={isTerminationModalVisible}
        setTerminationModalVisible={setTerminationModalVisible}
        employee={employee}
        eightThreeFourService={eightThreeFourService}
        refetchEmployee={refetchEmployee}
      />
      <EmployerPortalEditCoverageModal
        isEditCoverageModalVisible={isEditCoverageModalVisible}
        setEditCoverageModalVisible={setEditCoverageModalVisible}
        employee={employee}
        eightThreeFourService={eightThreeFourService}
        refetchEmployee={refetchEmployee}
        summaryService={summaryService}
        plansService={plansService}
        groupId={groupId}
        isBrokerPortal={isBrokerPortal || false}
      />
    </>
  )
}

export default EmployeeProfileSidebar
