import React, { ChangeEvent, useContext, useState, useEffect } from 'react'
import {
  Form,
  BankAccounts,
  useToaster
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { DisplayAccount} from "../../../Settings/BillingDetails/BillingDetails"
import {
  authService,
  paymentsService,
  plaidService,
  groupService
} from '../../../../services'
import { BusinessOnboardingContext } from '../../context/BusinessOnboardingContext'

import { useStep } from '../../hooks/use-step'
import { businessOnboardingPaths } from '../../router/paths'
import { getNewCurrentStep } from '../../utils/utils'
import { FooterActions } from '../FooterActions'
import { FormSectionRow, ValidationError } from '../style'
import { IDisplayAccount } from './interfaces'
import { useQuery } from 'react-query'
import { IBusinessOnboardingDTO } from '../../interfaces'

const mapDTOtoBilling = (data: IBusinessOnboardingDTO) => {
  return {
    autopay: data.autopay === false ? false : true,
    stepNumber: data.in_progress_state,
    paymentOptional: data.payment_info_optional,
    isFronting: data.insurer_type === 'fronting_arrangement'
  }
}

export const Billing: React.FC<{ renewal: boolean }> = ({ renewal }) => {
  const history = useHistory()
  const toaster = useToaster()
  const [defaultAccount, setDefaultAccount] = useState<any>(null)
  const [userAccounts, setUserAccounts] = useState<IDisplayAccount[]>([])

  const { state, setState, modifyGroup } = useContext(BusinessOnboardingContext)

  // non-plaid form

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      autopay: true
    },
    mode: 'onChange'
  })
  // TODO add centered spinner
  const { data } = useQuery(
    'getBilling',
    () => groupService.getInfo(mapDTOtoBilling, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (data: any) => {
        reset({
          autopay: data.autopay
        })
        setState(data.stepNumber)
      }
    }
  )

  useStep(state, 7, 'billing', data?.stepNumber || 1)

  const handleBack = () => {
    renewal
      ? history.push(businessOnboardingPaths.plan)
      : history.push(businessOnboardingPaths.carrierInformation)
  }

  const handleContinue = handleSubmit(async (formData) => {
    try {
      if (!data.paymentOptional) {
        if (!userAccounts.find((account) => account.is_default)) {
          return setDefaultAccount(null)
        }
        if (userAccounts.length <= 0) {
          return
        }
      }

      const DTO = {
        autopay: formData.autopay,
        in_progress_state: getNewCurrentStep(
          data.stepNumber,
          8 + data.isFronting
        )
      }

      await modifyGroup.mutate(DTO)

      history.push(businessOnboardingPaths.producer)
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  useEffect(() => {
    const newDefaultAccount = userAccounts.find(
      (a: DisplayAccount) => a.is_default
    )
    setDefaultAccount(newDefaultAccount)
  }, [userAccounts])

  return (
    <>
      <Form>
        <FormSectionRow
          className="d-flex justify-content-between mt-8"
          noGutters
          style={{ borderBottom: 'none' }}
        >
          <b>Autopay</b>
          <Controller
            name="autopay"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <Form.Switch
                {...field}
                id="autopay"
                checked={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChange(e.target.checked)
                }}
              />
            )}
          />
        </FormSectionRow>
      </Form>
      <BankAccounts
        userAccounts={userAccounts}
        setUserAccounts={setUserAccounts}
        paymentsService={paymentsService}
        plaidService={plaidService}
        authService={authService}
        groupService={groupService}
      />
      {userAccounts.length <= 0 && formState.isSubmitted && (
        <ValidationError>Please add a bank account</ValidationError>
      )}
      {!defaultAccount && formState.isSubmitted && userAccounts.length > 0 && (
        <ValidationError>Please select a default bank account</ValidationError>
      )}

      <FooterActions
        continueHandler={handleContinue}
        backHandler={handleBack}
      />
    </>
  )
}
