import React from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  qleList,
  Row,
  useToaster,
  EmployeeService
} from '../../../../index'
import { Controller, useForm } from 'react-hook-form'

type QleModalProps = {
  show: boolean
  onHide: Function
  memberId: string
  refetch: any
  employeesService: EmployeeService
  groupId?: string
}

export const QleModal: React.FC<QleModalProps> = ({
  show,
  onHide,
  memberId,
  refetch,
  employeesService,
  groupId
}) => {
  const toaster = useToaster()
  const { control, handleSubmit, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      date: '',
      reason: ''
    }
  })

  const errors = formState.errors

  const addQle = handleSubmit(async (values: any) => {
    try {
      const response = await employeesService.createQLE(
        memberId,
        values,
        groupId
      )
      if (response.detail === 'Success.') {
        reset()
        onHide()
        toaster.show({
          message: 'QLE was successfully added',
          icon: 'icon-check',
          type: 'success'
        })
        refetch()
      }
    } catch (e: any) {
      toaster.show({
        message: e.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      show={show}
      centered
      onHide={onHide}
    >
      <Form className="w-100" onSubmit={addQle}>
        <Modal.Header className="pt-4 pb-4 border-bottom mb-4" closeButton>
          <Modal.Title>Adding Qualifying Life Event</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-2">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Date of the QLE</Form.Label>
                <div>
                  <Controller
                    name="date"
                    control={control}
                    rules={{
                      required: 'Date of the QLE is required'
                    }}
                    render={({ field }) => <DatePicker {...field} />}
                  />
                </div>
                <span className="text-danger">{errors?.date?.message}</span>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Reason of the QLE</Form.Label>
                <Controller
                  name="reason"
                  rules={{ required: 'Reason of the QLE is required' }}
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <Form.Control className="bg-white" as="select" {...field}>
                      <option value="" disabled hidden>
                        Reason
                      </option>
                      {qleList.map((qle: any) => (
                        <option value={qle.value} key={qle.value}>
                          {qle.label}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                />
                <span className="text-danger">{errors?.reason?.message}</span>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="pb-4">
          <Row style={{ width: '100%' }}>
            <Col className="px-0">
              <Button
                className="border-light"
                variant="outline-primary"
                style={{ padding: '.4rem' }}
                onClick={() => onHide()}
              >
                Cancel
              </Button>
            </Col>
            <Col className="text-right px-0">
              <Button type="submit" style={{ padding: '.4rem 1rem' }}>
                Add QLE
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
