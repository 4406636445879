import React from 'react'
import { Col, Row } from 'react-bootstrap'

const Legalese = ({ showMPCN = false }: { showMPCN?: boolean }) => {
  const mpcn = (
    <p className="fs-10 mb-0 mt-1">
      Pre-Certification: Prior-Auth is required for all inpatient, surgeries
      (outside the physician's office), or for other services as specified in
      the SPD. Your physician must call for prior auth including but not limited
      to, high-cost radiology, any drug or infusion above $1,500 a dose, and all
      cancer treatment. To avoid reduced benefits, call MedWatch 7 to 10 days
      before the hospitalization/ treatment, or within 48 hours of an emergency
      admit at <span className="font-weight-bold">800-432-8421</span> or online
      at www.urmedwatch.com.{' '}
      <span className="font-weight-bold">
        Pre-certification does not guarantee payment.
      </span>
    </p>
  )

  const common = (
    <p className="fs-10 mb-0 mt-1">
      Pre-certification is required for elective hospitalizations and certain
      services. Emergency admission must be certified on the next business day.
      Failure to obtain pre-admission or admission certification may result in a
      reduction of benefits. Please call{' '}
      <span className="font-weight-bold">(855) 469-8550</span> for
      pre-certification or eligibility verification.
    </p>
  )

  return showMPCN ? mpcn : common
}

export const LegalText: React.FC<{ isCigna: boolean; isMPCN?: boolean }> = ({
  isCigna,
  isMPCN = false
}) => {
  return (
    <Row>
      <Col className="text-left">
        <Legalese showMPCN={isMPCN} />
        {isCigna && (
          <p className="mb-0 mt-1 fs-10">
            Benefits provided by employer not by Cigna or affiliates. Possession
            of this card or obtaining pre-treatment authorization does not
            guarantee coverage or payment.
          </p>
        )}
      </Col>
    </Row>
  )
}
