import React, { useState, useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import styled from 'styled-components'

import {
  Button,
  Col,
  Row,
  Modal,
  Form,
  EmployeeProfile,
  EightThreeFourService,
  useToaster,
  Card,
  IPlanListDTO,
  AddDependentTable,
  DependentFormType,
  DependentEditingState
} from '../../../index'
import {
  EightThreeFourRequest,
  EightThreeFourRequestMemberObject,
  FamilyIndicator
} from '../../../interfaces/employer-broker-shared/eightthreefour'
import { ErrorSpan } from '../EmployerPortalAddReinstateEmployee/EmployeeBasicInfoForm'

const AddQleSchema = z.object({
  dateOfQle: z.string().nonempty({ message: 'Date of QLE is required' }),
  reasonOfQle: z.string().min(1, 'QLE Reason is Required'),
  planSelected: z.string().min(1, 'Plan Selection is required')
})

export type AddQleFormFields = {
  dateOfQle: string
  reasonOfQle: string
  planSelected: string
}

export const DependentsRow = styled(Row)`
   {
    padding: 0.5em;
    max-width: 700px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const genderTranslatorFor843 = (gender: string): string => {
  if (gender.toLowerCase() === 'm' || gender.toLowerCase() === 'f') {
    return gender.toUpperCase()
  }

  if (gender.toLowerCase() === 'male') {
    return 'M'
  }

  if (gender.toLowerCase() === 'female') {
    return 'F'
  }

  return 'U'
}

export const EmployerPortalAddQLE: React.FC<{
  isAddQLEModalVisible: boolean
  setAddQleModalVisible: (boolean) => void
  employee: EmployeeProfile
  eightThreeFourService: EightThreeFourService
  planData?: IPlanListDTO
  refetchQLE: () => void
  refetchEmployee: () => void
}> = ({
  isAddQLEModalVisible,
  setAddQleModalVisible,
  employee,
  eightThreeFourService,
  planData,
  refetchQLE,
  refetchEmployee,
}) => {
  const defaultValues: AddQleFormFields = {
    dateOfQle: '',
    reasonOfQle: '',
    planSelected: ''
  }
  const [dependentArr, setDependentArr] = useState<DependentFormType[]>([]) // for new dependents
  const [removalDependentArr, setRemovalDependentArr] = useState<
    DependentFormType[]
  >([])

  const { showSuccess, showError } = useToaster()
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<AddQleFormFields>({
    defaultValues,
    resolver: zodResolver(AddQleSchema)
  })

  useEffect(() => {
    if (employee.dependents) {
      const tempDependentArr = employee.dependents.map((dep) => ({
        dependentType: dep.relationship,
        firstName: dep.name.first_name,
        middleName: dep.name.middle_name,
        lastName: dep.name.last_name,
        suffix: dep.name.suffix,
        sex: genderTranslatorFor843(dep.gender),
        dateOfBirth: dep.date_of_birth,
        ssn: dep.ssn,
        street_1: employee.address_object.street_1,
        street_2: employee.address_object.street_2,
        city: employee.address_object.city,
        state: employee.address_object.state,
        zip: employee.address_object.zip,
        memberId: dep.member_id,
        editingState: 'existing-no-edits' as DependentEditingState
      }))

      setDependentArr(tempDependentArr)
    }
  }, [employee])

  const addQleData = useMutation({
    mutationFn: (data: any) => eightThreeFourService.postMemberInformation(data),
    onSuccess: (response, data) => {
      if (response.success && response.data.total_members === response.data.total_success) {
        showSuccess('Successfully Submitted QLE Data')
        setAddQleModalVisible(false)
        reset()
        refetchQLE();
        refetchEmployee();
      } else if (response.link && response.success === false) {
        showError(`Error submitting QLE Data - ${response.message}`)
        setTimeout(() => window.open(response.link, '_blank'), 500)
      }
      else {
        showError(`Error submitting QLE Data`)
      }
    },
    onError: () => {
      showError(`Error submitting QLE Data`)
    }
  })

  const sendQleRequest = async (data: AddQleFormFields) => {
    if (!employee) {
      return
    }

    const seenMemberIds = {}

    const params: EightThreeFourRequest = {
      group_id: employee?.group_id,
      members: [
        {
          action_requested: 'enroll',
          angle_member_id: employee?.id,
          city: employee.address_object.city,
          coverage_end_date: employee?.coverageEnd,
          coverage_start_date: data.dateOfQle,
          date_of_birth: employee?.dob,
          family_indicator: getFamilyIndicatorBackendFriendly(),
          first_name: employee?.name ? employee?.name.split(' ')[0] : '',
          gender: genderTranslatorFor843(employee.gender),
          group_id: employee?.group_id,
          insured_ssn: employee?.ssn,
          last_name: employee?.name ? employee?.name.split(' ')[1] : '',
          plan_name: data.planSelected,
          qle_reason: data.reasonOfQle,
          relationship: 'employee',
          subgroup: employee.subgroup,
          ssn: employee?.ssn,
          state: employee.address_object.state,
          street_1: employee.address_object.street_1,
          street_2: employee.address_object.street_2,
          zip: employee.address_object.zip
        }
      ]
    }

    if (employee?.id) {
      seenMemberIds[employee.id] = true
    }

    // process new dependents to be removed
    for (let x = 0; x < removalDependentArr.length; x++) {
      let dependentMemberObject: EightThreeFourRequestMemberObject = {
        action_requested: 'terminate',
        angle_member_id: removalDependentArr[x].memberId,
        city: removalDependentArr[x].city,
        coverage_end_date: new Date().toISOString(),
        coverage_start_date: data.dateOfQle,
        date_of_birth: removalDependentArr[x].dateOfBirth,
        first_name: removalDependentArr[x].firstName,
        gender: removalDependentArr[x].sex,
        group_id: employee?.group_id,
        insured_ssn: employee?.ssn,
        last_name: removalDependentArr[x].lastName,
        parent_member_id: employee?.id,
        plan_name: data.planSelected,
        qle_reason: data.reasonOfQle,
        subgroup: employee.subgroup,
        relationship: removalDependentArr[x].dependentType,
        ssn: removalDependentArr[x].ssn,
        state: removalDependentArr[x].state,
        street_1: removalDependentArr[x].street_1,
        street_2: removalDependentArr[x].street_2,
        zip: removalDependentArr[x].zip
      }
      params.members.push(dependentMemberObject)
    }

    // Process newly-Created & edited & existing
    for (let x = 0; x < dependentArr.length; x++) {
      let dependentMemberObject: EightThreeFourRequestMemberObject = {
        action_requested: 'enroll',
        city: dependentArr[x].city,
        coverage_end_date: employee?.coverageEnd,
        coverage_start_date: data.dateOfQle,
        date_of_birth: dependentArr[x].dateOfBirth,
        family_indicator: getFamilyIndicatorBackendFriendly(),
        first_name: dependentArr[x].firstName,
        gender: dependentArr[x].sex,
        group_id: employee?.group_id,
        insured_ssn: employee?.ssn,
        last_name: dependentArr[x].lastName,
        parent_member_id: employee?.id,
        plan_name: data.planSelected,
        qle_reason: data.reasonOfQle,
        subgroup: employee.subgroup,
        relationship: dependentArr[x].dependentType,
        ssn: dependentArr[x].ssn,
        state: dependentArr[x].state,
        street_1: dependentArr[x].street_1,
        street_2: dependentArr[x].street_2,
        zip: dependentArr[x].zip
      }

      if (dependentArr[x].editingState === 'new') {
        dependentMemberObject.gender = dependentArr[x].sex
        dependentMemberObject.parent_member_id = employee?.id
      }

      const tempMemberId = dependentArr[x]?.memberId
      if (tempMemberId && tempMemberId !== undefined) {
        dependentMemberObject.angle_member_id = tempMemberId

        if (seenMemberIds[tempMemberId]) {
          continue
        } else {
          seenMemberIds[tempMemberId] = true
        }
      }

      if (dependentArr[x].genderIdentity) {
        dependentMemberObject.identified_gender = dependentArr[x].genderIdentity
      }
      params.members.push(dependentMemberObject)
    }
    await addQleData.mutateAsync(params)
  }

  const onSubmit: SubmitHandler<AddQleFormFields> = (data) => {
    try {
      sendQleRequest(data)
    } catch (err) {
      showError(`Error submitting QLE Data`)
    }
  }

  const getFamilyIndicatorForAllFlows = (): string => {
    if (dependentArr.length === 0) {
      return 'Employee'
    }
    const hasSpouse = dependentArr.some(
      (dependent) => dependent.dependentType === 'spouse'
    )
    const hasChildren = dependentArr.some((dependent) => dependent.dependentType === 'child')
    if (hasSpouse && hasChildren) {
      return "Employee + Family"
    }
    else if (hasSpouse) {
      return "Employee + Spouse"
    }
    else if (hasChildren) {
      return "Employee + Child"
    }
    return "Employee + Children";
  }

  const getFamilyIndicatorBackendFriendly = (): FamilyIndicator => {
    const userFriendlyValue = getFamilyIndicatorForAllFlows();
    switch (userFriendlyValue) {
      case "Employee":
        return "employee"
      case "Employee + Spouse":
        return "employee_spouse"
      case "Employee + Family":
        return "employee_family"
      case "Employee + Child":
        return "employee_child"
      default:
        return "employee_children"
    }
  }

  const famIndicator = getFamilyIndicatorForAllFlows();
  const employeeCoverageStartDate = new Date(employee?.coverageStart);
  return (
    <Modal
      show={isAddQLEModalVisible}
      centered
      size="lg"
      onHide={() => {
        setAddQleModalVisible(false)
        reset()
      }}
      dialogClassName="groupFormModalDialog"
      className="groupFormModal"
      style={{
        zIndex: 2000
      }}
    >
      <Modal.Header className="border-bottom pb-3 pt-4" closeButton>
        <Modal.Title>Add a QLE</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-2">
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Date of QLE</Form.Label>
              <Controller
                key="dateOfQle"
                name="dateOfQle"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select a date"
                    onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
                    selected={value ? moment(value, 'YYYY-MM-DD').toDate() : null}
                    dateFormat="MM/dd/yyyy"
                    minDate={new Date(employeeCoverageStartDate.getTime() + Math.abs(employeeCoverageStartDate.getTimezoneOffset()*60000))}
                    maxDate={new Date()}
                  />
                )}
              />
              <ErrorSpan>
                {errors?.dateOfQle?.message}
              </ErrorSpan>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Reason of the QLE</Form.Label>
              <Form.Control
                as="select"
                className="bg-white"
                isInvalid={!!errors?.reasonOfQle}
                placeholder="Select the reason"
                {...register('reasonOfQle')}
              >
                <option value="lossOfHealthCoverage">
                  Loss of Health Coverage
                </option>
                <option value="marriage">Getting Married</option>
                <option value="baby">Having a baby</option>
                <option value="death">Death in the family</option>
                <option value="changeInResidence">Changes in residence</option>
                <option value="U">Other</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors?.reasonOfQle?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <AddDependentTable
          dependentArr={dependentArr}
          eightThreeFourService={eightThreeFourService}
          employeeAddress={{
            street_1: employee.address_object.street_1,
            street_2: employee.address_object.street_2,
            city: employee.address_object.city,
            state: employee.address_object.state,
            zip: employee.address_object.zip
          }}
          removalDependentArr={removalDependentArr}
          setDependentArr={setDependentArr}
          updateRemovalDependentArr={setRemovalDependentArr}
        />
        <h5 className="mb-1 d-flex justify-content-between pt-3">
          Plan Selection
        </h5>
        <h6 className="mb-1 d-flex justify-content-between pt-3">
          Employee Family
        </h6>
        <DependentsRow>
          <Card className="mx-1" style={{ minWidth: 60, marginBottom: 15 }}>
            <Card.Body className="d-flex justify-content-between">
              <div>
                <p className="mb-0">{employee?.name}</p>
              </div>
            </Card.Body>
          </Card>
          {dependentArr?.map((d: any) => {
            return (
              <Card className="mx-1" style={{ minWidth: 60, marginBottom: 15 }}>
                <Card.Body className="d-flex justify-content-between">
                  <div>
                    <p className="mb-0">
                      {d.firstName} {d.lastName}
                    </p>
                  </div>
                </Card.Body>
              </Card>
            )
          })}
        </DependentsRow>
        <Row className="my-1 pb-1">
          <Col>
            <h6>Coverage Type: {famIndicator}</h6>
          </Col>
        </Row>
        <Row className="border-bottom">
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label>Available Plans</Form.Label>
              <Form.Control
                as="select"
                className="bg-white"
                isInvalid={!!errors?.planSelected}
                {...register('planSelected')}
              >
                {planData?.plans.map((plan) => (
                  <option value={plan.plan_name}>{plan.plan_name}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors?.planSelected?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-top pb-3 pt-3">
        <Button
          className="w-10"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || addQleData.isLoading}
        >
          Add QLE
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
