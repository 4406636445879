import React, { useState, useEffect, useCallback } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { AuthProvider, useAuth } from '../..'

import { useAuthUserInfo } from '../../hooks/use-auth-user-info'
import { ApiService, AuthService } from '../../services'
import { CenteredSpinner } from '../CenteredSpinner'
import { SessionExpired } from '../SessionExpired'

export interface IAuthenticationProps {
  auth: AuthService
  redirectTo?: string
  tokenKey?: string
  loadingPlaceholder?: React.ReactNode
}

const Comp: React.FC<IAuthenticationProps> = ({
  children,
  auth,
  redirectTo = '/login',
  tokenKey = 'token',
  loadingPlaceholder = null
}) => {
  const history = useHistory()
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)
  const { dispatch } = useAuth()

  const { data, error, isLoading, isError, isFetched } = useAuthUserInfo(auth)

  useEffect(() => {
    // If we got a 401 when loading this component,
    // we redirect to the login page
    if (!isLoading && isError && (error as any)?.status === 401) {
      history.push(redirectTo)
    }
  }, [data, isLoading, error, history, redirectTo, isError])

  useEffect(() => {
    if (!isLoading && isFetched && !isError) {
      dispatch({ type: 'SET_USER_ACCOUNT', user: data, fetching: false })
    }
  }, [
    data,
    isLoading,
    isError,
    history,
    error,
    redirectTo,
    isFetched,
    dispatch
  ])

  // Error handler
  // If an error occurs while the component was already loaded
  // we show the session expired modal
  ApiService.onError = (error: any) => {
    if (error.response?.status === 401) {
      setShowSessionExpiredModal(true)
    }
    return {}
  }

  const onSessionExpiredButtonClicHandler = useCallback(() => {
    localStorage.removeItem(tokenKey)
    // When the session expired modal button is clicked
    // We redirect to the login page
    history.push(redirectTo)
  }, [history, redirectTo, tokenKey])

  if (!localStorage.getItem(tokenKey)) {
    // No token's present, redirect to the login page
    return <Redirect to={redirectTo} />
  } else {
    ApiService._token = localStorage.getItem(tokenKey)
  }

  return (
    <React.Fragment>
      {isLoading &&
        (loadingPlaceholder || (
          <div className="vh-100">
            <CenteredSpinner />
          </div>
        ))}
      {!isLoading && data?.authuser_id ? (
        <React.Fragment>
          {children}
          {showSessionExpiredModal && (
            <SessionExpired
              verticallyCentered
              onClick={onSessionExpiredButtonClicHandler}
            />
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export const Authentication: React.FC<IAuthenticationProps> = (props) => (
  <AuthProvider>
    <Comp {...props}>{props.children}</Comp>
  </AuthProvider>
)

export default Authentication
