import React, { useEffect, useState } from 'react'
import { Col, Form, Row, useToaster } from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { businessOnboardingPaths } from '../../../router/paths'
import { FooterActions } from '../../FooterActions'
import { TitleRow } from '../../style'
import { FormState, Control } from 'react-hook-form'
import { CarrierFormData, CarrierData } from '../../../interfaces'
import { mapSmallGroupCarrierInformationToDTO } from '../mappers'
import { CurrentCarriers } from './CurrentCarriers'

interface SmallGroupCarrierInfoProps {
  data: CarrierData
  formState: FormState<CarrierFormData>
  control: Control
  handleSubmit: any
  modifyGroup: any
  watch: any
  setValue: any
  unregister: any
}

export const SmallGroupCarrierInformation: React.FC<
  SmallGroupCarrierInfoProps
> = ({
  formState,
  control,
  data,
  setValue,
  handleSubmit,
  modifyGroup,
  watch,
  unregister
}) => {
  const history = useHistory()
  const toaster = useToaster()

  const [hasCurrentCarrier, setHasCurrentCarrier] = useState(
    !!data?.hasCurrentCarrier
  )

  useEffect(() => {
    setHasCurrentCarrier(!!data?.hasCurrentCarrier)
  }, [data, setHasCurrentCarrier])

  useEffect(() => {
    if (hasCurrentCarrier === false) {
      unregister('currentCarrier')
      unregister('currentCarrierStartDate')
      unregister('currentCarrierEndDate')
      unregister('lessThanThree')
      unregister('changingReason')
      unregister('otherChangingReason')
      unregister('hasDental')
      unregister('hasVision')
    }
  }, [hasCurrentCarrier, unregister])

  const handleContinue = handleSubmit(async (formData: CarrierFormData) => {
    const DTO = mapSmallGroupCarrierInformationToDTO(
      formData,
      data.stepNumber,
      hasCurrentCarrier,
      data.isFronting,
    )

    try {
      await modifyGroup.mutateAsync(DTO)
      history.push(businessOnboardingPaths.billing)
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  const handleBack = () => {
    history.push(businessOnboardingPaths.plan)
  }

  return (
    <>
      <Form>
        <TitleRow noGutters>
          <b>Carrier Information</b>
        </TitleRow>
        <Row>
          <Col>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="No Current Carrier"
                checked={!hasCurrentCarrier}
                onChange={(e) => {
                  setHasCurrentCarrier(!hasCurrentCarrier)
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        {hasCurrentCarrier && (
          <CurrentCarriers
            control={control}
            formState={formState}
            hasCurrentCarrier={hasCurrentCarrier}
            setValue={setValue}
            data={data}
            watch={watch}
          />
        )}
      </Form>
      <FooterActions
        continueHandler={handleContinue}
        backHandler={handleBack}
      />
    </>
  )
}
