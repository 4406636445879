import styled from 'styled-components'

export const FiltersContainer = styled.div`
  display: flex;
  padding: 0 1rem 1rem 1rem;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const NameFilter = styled.div`
  height: 39px;
  background: var(--dropdown-active);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.3125rem;
  margin: 10px 10px 0px 0px;
  padding: 0.625rem 1rem;
  color: var(--primary);
  border: 1px solid var(--primary);
`

export const ClearNameFilterIcon = styled.i`
  height: 1rem;
  width: 1rem;
  margin-left: 1rem;
`
