import { Row, Col } from 'react-bootstrap'

export const DeductibleOOP: React.FC<{ planCoverage: any }> = ({
  planCoverage
}) => {
  return (
    <>
      <Row className="flex-nowrap">
        <Col xs="4" className="text-left"></Col>
        <Col className="pl-0 text-right">
          <p className="mb-0 fs-10 font-weight-bold">In-Network</p>
        </Col>
        <Col className="text-right">
          <p className="mb-0 fs-10 font-weight-bold text-nowrap">
            Out-of-Network
          </p>
        </Col>
      </Row>
      <Row className="flex-nowrap">
        <Col
          style={{ whiteSpace: 'nowrap', fontWeight: 500 }}
          xs="4"
          className="text-left fs-10 pr-0"
        >
          Deductible (ind/fam)
        </Col>
        <Col className="pl-0 text-right">
          <p className="mb-0 fs-10">
            ${planCoverage?.plan.deductible_individual_inn}/$
            {planCoverage?.plan.deductible_family_inn}
          </p>
        </Col>
        <Col className="text-right">
          {planCoverage?.plan.deductible_individual_oon ? (
            <p className="mb-0 fs-10">
              ${planCoverage?.plan.deductible_individual_oon}/$
              {planCoverage?.plan.deductible_family_oon}
            </p>
          ) : (
            <p className="mb-0 fs-10">Not Covered</p>
          )}
        </Col>
      </Row>
      <Row className="flex-nowrap">
        <Col
          style={{ whiteSpace: 'nowrap', fontWeight: 500 }}
          xs="4"
          className="text-left fs-10 pr-0"
        >
          Max OOP (ind/fam)
        </Col>
        <Col className="pl-0 text-right">
          <p className="mb-0 fs-10">
            ${planCoverage?.plan.oop_max_individual_inn}/$
            {planCoverage?.plan.oop_max_family_inn}
          </p>
        </Col>
        <Col className="text-right">
          {planCoverage?.plan.deductible_individual_oon ? (
            <p className="mb-0 fs-10">
              {' '}
              ${planCoverage?.plan.oop_max_individual_oon}/$
              {planCoverage?.plan.oop_max_family_oon}
            </p>
          ) : (
            <p className="mb-0 fs-10">Not Covered</p>
          )}
        </Col>
      </Row>
    </>
  )
}
