import styled from 'styled-components'

export const SearchInputContainer = styled.div`
  width: 50%;
  margin-top: 1.25rem;
  width: 100%;
  max-width: 45.5rem;
  margin-left: auto;
  margin-right: auto;
`

export const SearchTitle = styled.h1`
  margin-bottom: 0;
  text-align: center;
`

// export const SearchList = styled.div`
//   padding-top: 2.25rem;
// `

// export const SearchResultsContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   max-width: 48rem;
//   margin: 0 auto;
//   padding: 0 0.5rem 0.5rem 0.5rem;
// `

export const Status = styled.div`
  display: inline-block;
  margin-right: 0.5rem;
  border-radius: 50%;
  height: 8px;
  width: 8px;
`

export const EmployeeRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: var(--input-bg);
  }
`
