import React, { useState } from 'react'
import { Coordinates } from '../..'

import FacilityTooltip from './FacilityTooltip'
import ProviderTooltip from './ProviderTooltip'
import { CounterBadge, MarkerContainer, MarkerIcon } from './style'

export interface IMarkerItem {
  text: string
  title: string
  img: string
  distance: string
  coordinates: Coordinates
  id: string
}
export interface IMapMarker {
  lat: string | number
  lng: string | number
  items: IMarkerItem[]
  markerIcon: string
  isProvider: boolean
  show: boolean
  setShow: Function
  [x: string]: any
}

const Marker: React.FC<IMapMarker> = ({
  lat,
  lng,
  markerIcon,
  isProvider,
  items,
  setShow,
  show = false
}) => {
  const variant = show ? 'light' : 'dark'
  const [currentIndex, setCurrentIndex] = useState(0)

  const tooltip = isProvider ? (
    <ProviderTooltip
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      items={items}
    />
  ) : (
    <FacilityTooltip
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      items={items}
    />
  )

  return (
    <MarkerContainer lat={lat} lng={lng} className="map-marker">
      {items.length > 1 && <CounterBadge>{items.length}</CounterBadge>}
      <MarkerIcon
        data-testid="marker-tooltip-trigger"
        className={`icon ${markerIcon} bg-${variant}`}
        style={{ color: show ? 'black' : 'white' }}
        onClick={() => setShow(!show)}
      />
      {show && tooltip}
    </MarkerContainer>
  )
}

export const MapMarker = React.memo(Marker)
export default MapMarker
