import React from 'react'
import { ExpandibleNavItem } from './ExpandibleNavItem'
import { MenuItemsContainer, FlexText } from './styles'

export const SmallMenu = ({ items }) => {
  const navItemStyle = {
    flex: 1,
    minHeight: '13vh',
    alignItems: 'center',
    maxHeight: '2em'
  }

  const navItemClassName =
    'd-flex border-light border-bottom fs-18 text-secondary'

  return (
    <MenuItemsContainer className="d-flex d-sm-none">
      {items.map(({ className, ...n }, index) => {
        if (n.items) {
          return (
            <ExpandibleNavItem
              key={index}
              className={navItemClassName}
              style={navItemStyle}
              {...n}
            />
          )
        }

        return (
          <div
            onClick={n.onClick}
            key={index}
            className={navItemClassName}
            style={navItemStyle}
          >
            <FlexText>{n.title}</FlexText>
          </div>
        )
      })}
    </MenuItemsContainer>
  )
}

export default SmallMenu
