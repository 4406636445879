import React from 'react'
import {
  Button,
  Row,
  Col,
  Form,
  Avatar
} from 'react-angle-dashboard-components'

export interface SetDefaultHandlerProps {
  itemId: string
  accountId: string
}

export interface IBankAccountProps {
  isDefault: boolean
  markAccountAsDefault?: boolean
  bankName: string
  lastNumbers: string
  itemId: string
  accountId: string
  has_issues: boolean
  onUpdateButtonClick?: () => void
  onRemoveButtonClick?: () => void
  onSetDefaultHandler?: (arg: any) => void
  type?: string
}

export const BankAccount: React.FC<IBankAccountProps> = ({
  isDefault,
  bankName,
  lastNumbers,
  itemId,
  accountId,
  has_issues,
  markAccountAsDefault = true,
  type,

  onUpdateButtonClick = () => {},
  onRemoveButtonClick = () => {},
  onSetDefaultHandler = () => {}
}) => {
  var content
  if (!has_issues) {
    content = (
      <div
        style={{ height: '100%' }}
        className="d-inline-block d-flex align-items-center ml-3"
      >
        {bankName}
      </div>
    )
  } else {
    content = (
      <div className="d-inline-block ml-3" style={{ width: 300 }}>
        <p className="mb-0">{bankName}</p>

        <span className="text-danger">
          {'There is something wrong with this account'}
        </span>
      </div>
    )
  }

  const handleDefaultClick = () => {
    const data = {
      account_id: accountId,
      account_type: type
    }

    onSetDefaultHandler(data)
  }

  return (
    <Row
      className="py-3 border-bottom"
      style={{ marginLeft: 0, marginRight: 0 }}
    >
      <Col xs={12} md={8}>
        <Row>
          <Col className="pl-0 d-flex align-items-center">
            <Form.Check
              readOnly={has_issues}
              defaultChecked={isDefault}
              name="default-bank"
              type="radio"
              id="default-bank"
              className="d-inline mr-2"
              onChange={handleDefaultClick}
              disabled={has_issues}
            />

            <Avatar
              circle
              fallbackContent={<i className="text-white icon icon-bank"></i>}
            />

            {isDefault && markAccountAsDefault && !has_issues ? (
              <div className="d-inline-block ml-3" style={{ width: 200 }}>
                <p className="mb-0">{bankName}</p>

                <span className="text-muted">{'Default payment method'}</span>
              </div>
            ) : (
              content
            )}
          </Col>
        </Row>
      </Col>

      {has_issues && type !== 'ach' ? (
        <Col xs={12} md={2}>
          <Button variant="link" onClick={onUpdateButtonClick}>
            Update
          </Button>
        </Col>
      ) : (
        <Col
          xs={12}
          md={2}
          className="text-muted d-flex align-items-center justify-content-end"
        >
          **** {lastNumbers}
        </Col>
      )}

      <Col xs={12} md={2}>
        <Button variant="link" onClick={onRemoveButtonClick}>
          Remove
        </Button>
      </Col>
    </Row>
  )
}
