import React, { useState } from 'react'
import {
  Avatar,
  Col,
  FileDropzone,
  Form,
  PageContainer,
  Row,
  SectionTitle,
  Button,
  useToaster,
  DocumentService
} from '../../../../index'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

const folder = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66667 6.66716e-05C2.57833 -0.00118269 2.49062 0.0151382 2.40864 0.0480807C2.32666 0.0810232 2.25205 0.12993 2.18913 0.19196C2.12622 0.25399 2.07626 0.327905 2.04216 0.409409C2.00806 0.490914 1.9905 0.578383 1.9905 0.666733C1.9905 0.755083 2.00806 0.842552 2.04216 0.924057C2.07626 1.00556 2.12622 1.07948 2.18913 1.14151C2.25205 1.20354 2.32666 1.25244 2.40864 1.28539C2.49062 1.31833 2.57833 1.33465 2.66667 1.3334H13.3333C13.4217 1.33465 13.5094 1.31833 13.5914 1.28539C13.6733 1.25244 13.748 1.20354 13.8109 1.14151C13.8738 1.07948 13.9237 1.00556 13.9578 0.924057C13.9919 0.842552 14.0095 0.755083 14.0095 0.666733C14.0095 0.578383 13.9919 0.490914 13.9578 0.409409C13.9237 0.327905 13.8738 0.25399 13.8109 0.19196C13.748 0.12993 13.6733 0.0810232 13.5914 0.0480807C13.5094 0.0151382 13.4217 -0.00118269 13.3333 6.66716e-05H2.66667ZM2.66667 2.66673C1.93796 2.66673 1.33333 3.27136 1.33333 4.00007V8.00007H2.66667V4.00007H13.3333V8.00007H14.6667V4.00007C14.6667 3.27136 14.062 2.66673 13.3333 2.66673H2.66667ZM0 9.3334V14.0001C0 15.1047 0.895333 16.0001 2 16.0001H14C15.1047 16.0001 16 15.1047 16 14.0001V9.3334H9.8776C9.60227 10.1087 8.86933 10.6667 8 10.6667C7.13067 10.6667 6.39773 10.1087 6.1224 9.3334H0Z"
      fill="white"
    />
  </svg>
)

export const DocumentsUpload: React.FC<{
  documentService: DocumentService
  isBrokerPortal: boolean
}> = ({ documentService, isBrokerPortal }) => {
  const toast = useToaster()
  const { groupId } = useParams<{ groupId: string }>()
  const [currentFile, setCurrentFile] = useState<null | File>(null)
  const [documentType, setDocumentType] = useState('')
  const [note, setNote] = useState('')
  const DOCUMENT_TYPE_CHOICES = [
    { text: 'W9', value: 'W9' },
    { text: 'Signed Agreement', value: 'Signed Agreement' },
    { text: 'Others', value: 'Others' },
  ]

  const onChangesSuccess = () => {
    setCurrentFile(null)
    setNote('')
    toast.show({
      message: 'Your file has been uploaded',
      icon: 'icon-correct',
      type: 'success'
    })
  }

  const onChangesFail = (error: any) => {
    toast.show({
      message: `Something went wrong: ${error.message}`,
      icon: 'icon-cancel',
      type: 'danger'
    })
  }

  let apiPath = '/documents/upload'
  if (isBrokerPortal && groupId) {
    apiPath = `/groups/${groupId}` + apiPath
  }

  const uploadDocument = useMutation(
    (data: any) => documentService.uploadDocument(apiPath, data),
    {
      onSuccess: onChangesSuccess,
      onError: onChangesFail
    }
  )

  const onSubmit = async () => {
    if ((currentFile?.size || 0) >= 1e7) {
      return toast.show({
        message: 'File size must be under 10 MB',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }

    if (
      currentFile?.type !== 'image/png' &&
      currentFile?.type !== 'application/pdf'
    ) {
      return toast.show({
        message: 'File type must be PDF or PNG',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
    const data = new FormData()

    data.append('description', note)
    data.append('file', currentFile, currentFile.name)
    data.append('document_type', documentType)

    try {
      await uploadDocument.mutateAsync(data)
    } catch (error) {
      onChangesFail(error)
    }
  }

  

  return (
    <PageContainer size="md">
      <SectionTitle>Document upload</SectionTitle>
      <Form>
        <Row className="mb-3">
          <Col xs={12}>
            <Form.Label>Select Document Type:</Form.Label>
            <Form.Control
              as="select"
              className="bg-white"
              onChange={(e: any) => setDocumentType(e.target.value)}
              value={documentType}
            >
              <option value="" disabled>
                Select a document type, if none match use others
              </option>
             {DOCUMENT_TYPE_CHOICES.map(documentChoice => <option key={documentChoice.value} value={documentChoice.value}>{documentChoice.text}</option>)}
            </Form.Control>
          </Col>
        </Row>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Note</Form.Label>
                <Form.Control
                  onChange={(e: any) => setNote(e.target.value)}
                  value={note}
                  placeholder="Add a note"
                  className="bg-white"
                />
              </Form.Group>
            </Col>
          </Row>
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Add a file</Form.Label>
              {!currentFile ? (
                <FileDropzone
                  descriptionText={'File type should be PDF or PNG'}
                  onFileLoaded={([e]: any) => {
                    setCurrentFile(e)
                  }}
                />
              ) : (
                <Row className="pt-3">
                  <Col
                    className="align-self-center"
                    xs="2"
                    style={{ maxWidth: 50 }}
                  >
                    <Avatar
                      circle
                      size="sm"
                      fallbackContent={folder}
                      variant="warning"
                    />
                  </Col>

                  <Col className="align-self-center">
                    <p className="mb-0">{currentFile?.name}</p>
                  </Col>
                  <Col className="align-self-center text-right" xs="2">
                    <i
                      role="button"
                      className="icon icon-cancel d-inline"
                      onClick={() => setCurrentFile(null)}
                    />
                  </Col>
                </Row>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <Button
              variant={currentFile ? 'primary' : 'secondary'}
              disabled={!currentFile || uploadDocument.isLoading}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </PageContainer>
  )
}

export default DocumentsUpload
