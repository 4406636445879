import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { getPagination } from './utils/getPagination'
import { PaginationDropdown } from './PaginationDropdown'
import { PageItem } from './PageItem'
import { RightCol, LeftCol } from './styles'

export interface IPaginationProps {
  totalPages: number
  currentPage: number
  setPage: Function
  setPageLimit: Function
  totalItems: number
  buttonFontSize?: number
  selectFontSize?: number
  showMax?: number
  prevNext?: boolean
  prevText?: string
  nextText?: string
  activeClass?: string
  disabledClass?: string
  defaultClass?: any
  activeBgColor?: string
  activeBorderColor?: string
  disabledBgColor?: string
  disabledBorderColor?: string
  bgColor?: string
  borderColor?: string
  activeColor?: string
  color?: string
  disabledColor?: string
  className?: string
  pageLimit: number
  pageNumberList?: number[]
}

export const Pagination: React.FC<IPaginationProps> = ({
  totalPages,
  currentPage,
  setPage,
  buttonFontSize = 14,
  selectFontSize = 14,
  activeBgColor = 'var(--primary)',
  activeBorderColor = 'var(--primary)',
  activeColor = 'white',
  showMax = 5,
  prevNext = true,
  prevText = 'Previous',
  nextText = 'Next',
  disabledColor = 'var(--secondary)',
  activeClass,
  disabledClass,
  defaultClass,
  disabledBgColor,
  disabledBorderColor,
  bgColor,
  borderColor,
  color,
  className,
  setPageLimit,
  pageLimit,
  totalItems,
  pageNumberList = [10, 20, 50]
}) => {
  const pagination = getPagination({
    totalPages,
    currentPage,
    setPage,
    buttonFontSize,
    selectFontSize,
    activeBgColor,
    activeBorderColor,
    activeColor,
    showMax,
    prevNext,
    prevText,
    nextText,
    disabledColor,
    activeClass,
    disabledClass,
    defaultClass,
    disabledBgColor,
    disabledBorderColor,
    bgColor,
    borderColor,
    color,
    className,
    setPageLimit,
    pageLimit,
    totalItems
  })

  const onItemLimitChange = (data: any) => setPageLimit(+data.target.value)
  const onClick = (newPageNumber: number) => setPage(newPageNumber)

  return (
    <Container fluid className="mx-0 px-0 pt-2">
      <Row className="px-0">
        <LeftCol className="pt-1 mb-3">
          <PaginationDropdown
            onSelectChange={onItemLimitChange}
            selectFontSize={selectFontSize}
            totalItems={totalItems}
            maxItemsShown={pageLimit}
            pageNumberList={pageNumberList}
          />
        </LeftCol>
        <RightCol>
          <nav className={`row ${className && className}`}>
            <ul className={'pagination'}>
              {pagination.map((page, i) => (
                <PageItem
                  key={`${page}-${i}`}
                  page={page.page}
                  text={page.text}
                  className={page.class}
                  onClick={onClick}
                  buttonFontSize={buttonFontSize}
                  activeBgColor={activeBgColor}
                  activeBorderColor={activeBorderColor}
                  disabledBgColor={disabledBgColor}
                  disabledBorderColor={disabledBorderColor}
                  bgColor={bgColor}
                  borderColor={borderColor}
                  activeColor={activeColor}
                  color={color}
                  disabledColor={disabledColor}
                />
              ))}
            </ul>
          </nav>
        </RightCol>
      </Row>
    </Container>
  )
}

Pagination.defaultProps = {
  currentPage: 1,
  activeClass: 'active',
  disabledClass: 'disabled',
  showMax: 5,
  prevNext: true,
  prevText: '⟨',
  nextText: '⟩'
}

export default Pagination
