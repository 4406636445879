import React, { useState, useEffect, useCallback } from 'react'
import {
  AuthService,
  useAuth,
  CenteredSpinner,
  SessionExpired,
  AuthProvider
} from 'react-angle-dashboard-components'
import { Redirect, useHistory } from 'react-router-dom'

import { useAuthUserInfo } from 'react-angle-dashboard-components'
import { ApiService, groupService } from '../../services'
import { useQuery } from 'react-query'
import { OnboardingStatus } from '../../routes/OnboardingStatus'
import { Paths } from '../../routes/paths'

const Redirections = {
  [OnboardingStatus.inProgress]: Paths.newBusinessOnboarding,
  [OnboardingStatus.pending]: Paths.newBusinessOnboarding,
  [OnboardingStatus.passwordNotChanged]: Paths.newBusinessRegistration,
  [OnboardingStatus.completed]: Paths.summary,
  [OnboardingStatus.underReview]: Paths.newBusinessOnboardingComplete
}

export interface IAuthenticationProps {
  auth: AuthService
  redirectTo?: string
  tokenKey?: string
  loadingPlaceholder?: React.ReactNode
  onboardingStep?: string
  notFound?: boolean
}

const Comp: React.FC<IAuthenticationProps> = ({
  children,
  auth,
  redirectTo = '/login',
  tokenKey = 'token',
  loadingPlaceholder = null,
  onboardingStep = 'completed',
  notFound = false
}) => {
  const history = useHistory()

  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)
  const { dispatch } = useAuth()

  const [isLoadingRedirect, setIsLoadingRedirect] = useState(false)
  const dataGroup = useQuery('getGroup', () => groupService.getInfo())

  const { data, error, isLoading, isError, isFetched } = useAuthUserInfo(auth)

  useEffect(() => {
    // If we got a 401 when loading this component,
    // we redirect to the login page

    if (!isLoading && isError && (error as any)?.status === 401) {
      history.push(redirectTo)
    }
  }, [data, isLoading, error, history, redirectTo, isError])

  const renewal = dataGroup?.data?.in_renewal
  const isGroupLoading = dataGroup.isLoading
  const isGroupFetched = dataGroup.isFetched
  const isGroupError = dataGroup.isError

  useEffect(() => {
    if (
      !isLoading &&
      isFetched &&
      !isError &&
      !isGroupLoading &&
      isGroupFetched &&
      !isGroupError
    ) {
      // Check if the required status is the same as the user status

      if (!notFound) {
        setIsLoadingRedirect(true)
        if (onboardingStep === 'in_progress') {
          if (data.temporary) {
            history.push(Paths.newBusinessRegistration)
          }
          if (
            data.onboarding_status !== 'in_progress' &&
            data.onboarding_status !== 'pending'
          ) {
            if (data.temporary) {
              history.push(Paths.newBusinessRegistration)
            } else {
              const redirection =
                Redirections[data.onboarding_status as OnboardingStatus] ||
                Paths.login

              history.push(redirection)
            }
          }
        } else if (onboardingStep !== data.onboarding_status && !renewal) {
          // Redirect based on current status (only if user is not in the required status)
          if (data.temporary) {
            if (history.location.pathname !== Paths.newBusinessRegistration) {
              history.push(Paths.newBusinessRegistration)
            }
          } else {
            if (data.onboarding_status !== 'under_review' && !renewal) {
              const redirection =
                Redirections[data.onboarding_status as OnboardingStatus] ||
                Paths.login

              history.push(redirection)
            }
          }
        }
      }

      dispatch({
        type: 'SET_USER_ACCOUNT',
        user: { ...data },
        fetching: false
      })
      setIsLoadingRedirect(false)
    }
  }, [
    data,
    isLoading,
    isError,
    history,
    error,
    redirectTo,
    isFetched,
    dispatch,
    onboardingStep,
    isGroupLoading,
    isGroupFetched,
    isGroupError,
    renewal,
    notFound
  ])

  // Error handler
  // If an error occurs while the component was already loaded
  // we show the session expired modal
  ApiService.onError = (error: any) => {
    if (error.response?.status === 401) {
      setShowSessionExpiredModal(true)
    }
    return {}
  }

  const onSessionExpiredButtonClicHandler = useCallback(() => {
    localStorage.removeItem(tokenKey)
    // When the session expired modal button is clicked
    // We redirect to the login page
    history.push(redirectTo)
  }, [history, redirectTo, tokenKey])

  if (!localStorage.getItem(tokenKey)) {
    // No token's present, redirect to the login page
    return <Redirect to={redirectTo} />
  } else {
    ApiService._token = localStorage.getItem(tokenKey)
  }

  return (
    <React.Fragment>
      {isLoading &&
        (loadingPlaceholder || (
          <div className="vh-100">
            <CenteredSpinner />
          </div>
        ))}
      {!isLoading && data?.authuser_id && !isLoadingRedirect ? (
        <React.Fragment>
          {children}
          {showSessionExpiredModal && (
            <SessionExpired
              verticallyCentered
              onClick={onSessionExpiredButtonClicHandler}
            />
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export const PartialAuthentication: React.FC<IAuthenticationProps> = (
  props
) => (
  <AuthProvider>
    <Comp {...props}>{props.children}</Comp>
  </AuthProvider>
)

export default PartialAuthentication
