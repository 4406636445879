import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Subheader } from '../style'

export const formularyInfoMap = {
  ['cap_rx' as string]: {
    phone: '1(833)599-0971',
    web: 'cap-rx.com'
  },
  ['magellan' as string]: {
    phone: '1(800)424-4323',
    web: 'magellanrx.com'
  },
  ['smith_rx' as string]: {
    phone: '1(844)454-5201',
    web: 'www.mysmithrx.com'
  },
  ['angle_rx' as string]: {
    phone: '1(945)224-0872',
    web: 'rx.anglehealth.com'
  }
}

export const ProvidersSection: React.FC<{
  idCardType: string
  formularyIndicator?: string
}> = ({ idCardType, formularyIndicator }) => {
  const isCigna = idCardType === 'sns' || idCardType === 'centivo'
  const isMPCN = idCardType === 'mpcn'

  return (
    <>
      <Subheader className="text-primary">For Providers</Subheader>

      <Row>
        <Col xs="6" className="text-left">
          <p className="mb-0 fs-12 font-weight-bold">Payer ID:</p>
          {!isMPCN && (
            <p className="mb-0 fs-12 font-weight-bold">Phone Number:</p>
          )}
        </Col>
        <Col xs="6" className="pl-0 text-right">
          <p className="mb-0 fs-12">{isCigna ? '62308' : '39856'}</p>
          {!isMPCN && (
            <p className="mb-0 fs-12">
              {isCigna ? '1(855)469-8550' : '1(855)937-1811'}
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="5" className="text-left">
          <p className="mb-0 fs-12 font-weight-bold">Claim Address:</p>
        </Col>
        <Col xs="7" className="pl-0 text-right">
          <p className="mb-0 fs-12">
            <span>{isCigna ? 'PO Box 188061' : 'PO BOX 21428'}</span>
            <span className="d-block">
              {isCigna ? 'Chattanooga, TN 37422' : 'Eagan, MN 55121'}
            </span>
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs="6" className="text-left">
          {isMPCN && (
            <p
              className="mb-0 fs-12 font-weight-bold"
              style={{ whiteSpace: 'nowrap' }}
            >
              MPCN Network Phone:
            </p>
          )}
          <p className="mb-0 fs-12 font-weight-bold">Pharmacy Phone:</p>
          <p className="mb-0 fs-12 font-weight-bold">Pharmacy Web:</p>
        </Col>
        <Col className="pl-0 text-right">
          {isMPCN && <p className="mb-0 fs-12">1(800)931-8533</p>}
          <p className="mb-0 fs-12">
            {formularyInfoMap[formularyIndicator as string]?.phone || ''}
          </p>
          <p className="mb-0 fs-12">
            {formularyInfoMap[formularyIndicator as string]?.web || ''}
          </p>
        </Col>
      </Row>
      {!isCigna && !isMPCN ? (
        <Row className="mt-3">
          <Col xs="6" className="text-left">
            <p className="mb-0 fs-12 font-weight-bold text-nowrap">
              First Health Network:
            </p>

            <p className="mb-0 fs-12 font-weight-bold">Provider Locator:</p>
          </Col>

          <Col xs="6" className="pl-0 text-right">
            <p className="mb-0 fs-12">1(800)226-5116</p>
            <p className="mb-0 fs-12">myfirsthealth.com</p>
          </Col>
        </Row>
      ) : null}
    </>
  )
}
