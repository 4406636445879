import { useQueryParams } from '@angle/hooks'
import React, { useState } from 'react'
import {
  Button,
  CenteredSpinner,
  Form,
  Logo
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { ApiService, authService as auth, authService } from '../../services'
import { FormWrapper } from '../Login/style'


export const TemporaryLogin = () => {
  const history = useHistory()
  const [isSigning, setSigningState] = useState(false)
  const userInfoToken =
    useQueryParams().get('code') || ''

  // temp_auth
  const { data, isLoading } = useQuery(
    ['temporaryLogin', userInfoToken],
    () => {
      return authService.signWithCode(userInfoToken)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false
    }
  )

  const { control, getValues, setError, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: ''
    }
  })

  const onSubmit = async (e: any) => {
    e.preventDefault()

    const { password } = getValues()

    if (data?.username && password) {
      // Call login endpoint
      const email = data?.username

      try {
        setSigningState(true)
        const response = await auth.login(email, password)
        if (response.access_token) {
          ApiService._token = response.access_token
          localStorage.setItem('token', response.access_token)

          history.push({
            pathname: '/business/registration',
            state: {
              oldPassword: password
            }
          })
        }
      } catch (e) {
        setSigningState(false)
        setError('password', {
          type: 'badPassword',
          message: 'Invalid credentials'
        })
      }
    }
  }

  if (isLoading)
    return (
      <section style={{ height: '80vh' }}>
        <CenteredSpinner />
      </section>
    )

  const passwordAlreadyChanged = (
    <div className="shadow-sm p-5 mt-10 text-center" style={{ width: '45%' }}>
      <h4>Password already changed?</h4>
      <p role="alert" className="text-muted">
        If you already changed your temporary password, please{' '}
        <Link to="/login">click here</Link> to login using your credentials
      </p>
    </div>
  )
  return (
    <div className="vh-100 vw-100 d-flex flex-column align-items-center pt-10">
      <div className="d-flex align-items-top mb-5">
        <Logo variant="simple" size="lg" color="primary" />
        <p className="fs-32">Business</p>
      </div>
      {data && userInfoToken ? (
        <>
          <div>
            <p className="fs-32">
              <span className="d-block text-center">Welcome</span>
              <span className="d-block text-center mb-10">{`${data?.first_name} ${data?.last_name}`}</span>
            </p>
          </div>
          <FormWrapper>
            <Form onSubmit={onSubmit}>
              <Form.Group>
                <Form.Label>Please input your temporary password</Form.Label>
                <Controller
                  name="password"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="password"
                      placeholder="Enter password"
                      className="bg-white"
                      isInvalid={!!formState.errors?.password}
                    />
                  )}
                />
                {formState.errors?.password && (
                  <span className="text-danger my-1">
                    Your temporary password is not valid
                  </span>
                )}
              </Form.Group>
            </Form>
            <Button
              variant="primary"
              className="w-100"
              type="submit"
              disabled={isSigning}
              onClick={onSubmit}
            >
              Continue
            </Button>
          </FormWrapper>
          {passwordAlreadyChanged}
        </>
      ) : (
        <>
          <div className="shadow-sm p-5 text-center" style={{ width: '45%' }}>
            <h4>Invalid Link</h4>
            <p className="text-muted">
              Please contact Angle Health to get a new link
            </p>
          </div>
          {passwordAlreadyChanged}
        </>
      )}
    </div>
  )
}

export default TemporaryLogin
