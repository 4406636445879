import { DateHandler, capitalize, calculateAge } from '@angle/utils'
import { EmployeeStatusMap, Employee, EmployeeDTO, EmployeeProfile, EmployeeProfileForm } from '../../../../interfaces/employer-broker-shared/employees'


export const mapDtoToEmployeeList = (employeeDTO: EmployeeDTO): Employee => ({
    name: employeeDTO.name.first_name + ' ' + employeeDTO.name.last_name,
    id: employeeDTO.member_id,
    employeeType: capitalize(employeeDTO.family.employment_status) || '-',
    status: EmployeeStatusMap[employeeDTO.onboarding_status],
    startDate:
      DateHandler.formatForClient(employeeDTO.coverages[0]?.effective_date) ||
      '-',
    planVariant: capitalize(employeeDTO.coverages[0]?.plan.metal_tier) as string,
    metalTier: employeeDTO.coverages[0]?.plan.metal_tier,
    planName: capitalize(employeeDTO.coverages[0]?.plan.plan_name) || '',
    profilePicUrl: '',
    endDate:
      DateHandler.formatForClient(employeeDTO.coverages[0]?.term_date) || '-',
    disenrolled:
      !!employeeDTO.coverages?.length &&
      employeeDTO.coverages[0]?.term_date !== null &&
      employeeDTO.coverages[0]?.manually_terminated,
    waivedCoverage: !!employeeDTO.coverage_waived
  })


  export const mapDtoToProfile = (employeeDTO: EmployeeDTO): EmployeeProfile => {
    const homeAddress = employeeDTO.home_address
    const mailingAddress = employeeDTO.mailing_address
    let monthlyPremium = +employeeDTO.coverages[0]?.monthly_premium_employee || 0
    let monthlyPremiumEmployer =
      +employeeDTO.coverages[0]?.monthly_premium_employer || 0
    return {
      address_object: {
          street_1: `${mailingAddress.street_1}`,
          street_2: `${mailingAddress.street_2}`,
          city: `${mailingAddress.city}`,
          state: `${mailingAddress.state}`,
          zip: `${mailingAddress.zip}`,
      },
      age: employeeDTO.date_of_birth
        ? calculateAge(employeeDTO.date_of_birth)
        : null,
        aca_status: employeeDTO.aca_status,
        access_plus_status: employeeDTO.access_plus_status,
        homeAddress: `${homeAddress.street_1} ${homeAddress.street_2}, ${homeAddress.city} ${homeAddress.state}, ${homeAddress.zip}`,
        address: `${mailingAddress.street_1} ${mailingAddress.street_2}, ${mailingAddress.city} ${mailingAddress.state}, ${mailingAddress.zip}`,
      allCoverages: employeeDTO.coverages.concat(employeeDTO.past_medical_coverages.reverse()),
      companyPays: `$${monthlyPremium}`,
      coverageEnd: employeeDTO.coverages[0]?.term_date || '-',
      coverages: employeeDTO.coverages,
      coverageStart: employeeDTO.coverages[0]?.effective_date || '-',
      covers: `${employeeDTO.dependents.length + 1} people`,
      dependents: employeeDTO.dependents || [],
      dob: employeeDTO.date_of_birth,
      email: employeeDTO.contact_info.work_email || '-',
      employeePays: `$${monthlyPremiumEmployer}`,
      empty: ['pending', 'in_progress'].includes(employeeDTO.onboarding_status),
      familyId: employeeDTO.family.family_id,
      gender: employeeDTO.gender,
      group_id: employeeDTO.group_id,
      id: employeeDTO.member_id,
      identified_gender: employeeDTO.identified_gender,
      medicareEligible: employeeDTO.medicare_eligible ? 'Yes' : 'No',
      metalTier: employeeDTO.coverages[0]?.plan.metal_tier || '',
      name: employeeDTO.name.first_name + ' ' + employeeDTO.name.last_name,
      otherInsurance: employeeDTO.other_insurance ? 'Yes' : 'No',
      plan: capitalize(employeeDTO.coverages[0]?.plan.plan_name) || '',
      planType: capitalize(employeeDTO.coverages[0]?.plan.metal_tier) as string,
      premium: `$${monthlyPremium + monthlyPremiumEmployer}`,
      profilePic: '',
      relationship: employeeDTO.relationship,
      ssn: employeeDTO.ssn,
      subgroup: employeeDTO.family.subgroup,
      // warning - Investigate `terminated` usage. It's not trustworthy. `terminationDate` is reliable
      terminated: employeeDTO.coverages[0]?.manually_terminated || false,
      terminationDate: employeeDTO.family.termination_date
    }
  }

  export const mapDTOtoEditMemberForm = (
    employeeDTO: EmployeeDTO
  ): EmployeeProfileForm => {
    const mailingAddress = employeeDTO.mailing_address
    return {
      firstName: employeeDTO.name?.first_name || '',
      lastName: employeeDTO.name?.last_name || '',
      name: `${employeeDTO.name?.first_name || ''} ${
        employeeDTO.name?.last_name || ''
      }`,
      dateOfBirth: employeeDTO.date_of_birth || '',
      email: employeeDTO.contact_info?.work_email || '',
      streetAddressLine1: mailingAddress.street_1 || '',
      streetAddressLine2: mailingAddress.street_2 || '',
      city: mailingAddress.city || '',
      state: mailingAddress.state || '',
      zip: mailingAddress.zip || '',
      medicareEligible: employeeDTO.medicare_eligible || false,
      otherInsurance: employeeDTO.other_insurance || false
    }
  }
 
  export const mapFormToDTO = (formData: EmployeeProfileForm) => {
    const {
      firstName,
      lastName,
      streetAddressLine1,
      streetAddressLine2,
      city,
      state,
      zip,
      medicareEligible,
      otherInsurance
    } = formData
  
    const data: Partial<EmployeeDTO> = {
      name: {
        first_name: firstName,
        last_name: lastName
      },
      mailing_address: {
        street_1: streetAddressLine1,
        street_2: streetAddressLine2,
        city,
        state,
        zip
      },
      other_insurance: otherInsurance,
      medicare_eligible: medicareEligible
    }
    return data
  }
