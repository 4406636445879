import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import moment from 'moment'
import {
  Button,
  Col,
  Row,
  SectionTitle,
  ISendPublicToken,
  useToaster,
  PageContainer,
  PageHeaderLight,
  CenteredSpinner
} from 'react-angle-dashboard-components'

import { PlaidResponse } from '../BillingDetails/BillingDetails'
import { BankAccount } from '../BillingDetails/BankAccount'
import {
  billingService as BillingService,
  paymentsService,
  plaidService as PlaidService,
  summaryService
} from '../../../services'
import { AddBankAccountModal } from '../../../components/AddBankAccountModal'
import { PageLayout } from '../../layouts/PageLayout'

export const MakePayment = () => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [publicToken, setPublicToken] = useState<string | undefined>(undefined)
  const [paymentMethods, setPaymentMethods] = useState<any[]>([])
  const { show: setToastr } = useToaster()
  const history = useHistory()
  const { data, isLoading } = useQuery(
    ['summary'],
    () => summaryService.getSummary(),
    {
      refetchOnWindowFocus: false
    }
  )

  const getAccounts = useCallback(() => {
    setShow(false)
    setLoading(true)
    paymentsService
      .getAccounts()
      .then((response) => {
        const { accounts } = response
        const displayAccounts: Array<{
          bankName: string
          lastNumbers: string
          isDefault: boolean
          itemId?: string
          accountId: string
          type: string
          has_issues: boolean
        }> = []

        for (const record of accounts) {
          const accountId = record.account_id.toString()
          const element = {
            bankName: record.institution_name || 'ACH Account',
            lastNumbers: record.mask,
            isDefault: record.is_default,
            itemId: accountId,
            accountId: accountId,
            type: record.account_type,
            has_issues: record.has_issues
          }

          displayAccounts.push(element)
        }

        setLoading(false)
        setPaymentMethods(displayAccounts)
      })
      .catch(() => {
        setToastr({
          message:
            'An error has occurred trying to get the bank account information.',
          icon: 'icon-danger',
          type: 'danger'
        })
        setLoading(false)
      })
  }, [setToastr])

  useEffect(() => {
    PlaidService.createLinkToken()
      .then((response) => setPublicToken(response?.link_token))
      .catch(() =>
        setToastr({
          message:
            'An error has occurred trying to get the bank account information.',
          icon: 'icon-danger',
          type: 'danger'
        })
      )

    getAccounts()
  }, [getAccounts, setToastr])

  const handleAccountSelection = useCallback(
    (data: any) => {
      if (!data) return

      const newPaymentMethods = [...paymentMethods].map((a) => ({
        ...a,
        isDefault: false
      }))

      const defaultIndex = newPaymentMethods.findIndex(
        (a: any) => a.accountId === data?.account_id
      )

      if (defaultIndex === -1) {
        return
      }

      newPaymentMethods[defaultIndex].isDefault = true

      setPaymentMethods(newPaymentMethods)
    },
    [paymentMethods]
  )

  const onPlaidLinkingSuccess = useCallback(
    (token: string, response: PlaidResponse) => {
      const accounts = response.accounts.filter((a) =>
        ['savings', 'checking'].includes(a.subtype)
      )
      if (!accounts.length) {
        setToastr({
          message:
            "You don't have any checkings/savings accounts. Please try it again with another financial institution.",
          icon: 'icon-close',
          type: 'warning'
        })
      } else {
        PlaidService.sendPublicToken(token).then(
          (publicToken: ISendPublicToken) => {
            history.push({
              pathname: '/settings/billing/accounts',
              state: {
                accounts,
                itemId: publicToken.items[0].item_id
              }
            })
          }
        )
      }
    },
    [history, setToastr]
  )

  const onPaymentHandler = useCallback(() => {
    const account = paymentMethods.find((p) => p.isDefault)

    let response
    if (account.type === 'ach') {
      response = BillingService.makeAchPayment({
        account_id: Number(account.accountId),
        effective_date: moment().format('YYYY-MM-DD'),
        amount: Number(data?.currentBalanceDue || 0),
        description: ''
      })
    } else {
      response = BillingService.makePlaidPayment({
        account_id: Number(account.accountId),
        effective_date: moment().format('YYYY-MM-DD'),
        amount: Number(data?.currentBalanceDue || 0),
        description: ''
      })
    }

    response
      .then(() => history.push('/billing'))
      .catch((e) => {
        setToastr({
          message:
            'An error has occurred. If the issue persists, get in touch with our support team.',
          icon: 'icon-close',
          type: 'danger'
        })
      })
  }, [paymentMethods, data?.currentBalanceDue, history, setToastr])

  const toggleModal = (v?: boolean) =>
    typeof v === 'boolean' ? setShow(v) : setShow((s) => !s)

  if (isLoading) {
    return <CenteredSpinner />
  }

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Billing', link: '/billing' }
      ]}
    >
      <PageHeaderLight>
        <PageContainer className="mt-4 mb-2 pb-2" size="lg">
          <Row>
            <Col md={12}>
              <h1>Make a Payment</h1>
            </Col>
          </Row>
        </PageContainer>
      </PageHeaderLight>

      <PageContainer size="lg">
        <div>
          <AddBankAccountModal
            show={show}
            toggleModal={toggleModal}
            publicToken={publicToken as string}
            successCallback={onPlaidLinkingSuccess}
            sessionExpiredCallback={() => setShow(false)}
            manuallyAddCallback={() =>
              history.push('/settings/billing/add-account')
            }
          />

          <section>
            <SectionTitle>Make a payment</SectionTitle>
            <Row>
              <Col md={3}>
                <h2>${data?.currentBalanceDue || 0}</h2>
              </Col>
            </Row>
          </section>
          <section className="border-top mt-4 pt-4">
            <Row>
              <Col>
                {' '}
                <SectionTitle>Select a payment method</SectionTitle>
              </Col>
              <Col className="text-right">
                <Button onClick={toggleModal} variant="outline-primary">
                  Add new bank account{' '}
                </Button>
              </Col>
            </Row>

            {!paymentMethods.length && !loading && (
              <div className="mt-3">
                <p className="text-muted text-center">
                  No banks accounts available
                </p>
              </div>
            )}

            {loading ? (
              <CenteredSpinner style={{ height: '200px' }} />
            ) : (
              paymentMethods?.map((pm: any, i) => (
                <BankAccount
                  {...pm}
                  markAccountAsDefault={false}
                  onSetDefaultHandler={handleAccountSelection}
                  key={i}
                />
              ))
            )}
          </section>

          {paymentMethods.length !== 0 ? (
            <section className="text-right my-4">
              <Button
                disabled={
                  !data?.currentBalanceDue ||
                  paymentMethods.filter((p: any) => p.isDefault).length === 0
                }
                onClick={onPaymentHandler}
              >
                Pay ${data?.currentBalanceDue || 0}
              </Button>
            </section>
          ) : null}
        </div>
      </PageContainer>
    </PageLayout>
  )
}

export default MakePayment
