import React, { useContext } from 'react'
import {
  CenteredSpinner,
  Col,
  Form,
  Row,
  useToaster
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'
import {
  formValidations,
  getRequiredObject
} from '../../../../utils/formValidations'
import { BusinessOnboardingContext } from '../../context/BusinessOnboardingContext'
import { useStep } from '../../hooks/use-step'
import { businessOnboardingPaths } from '../../router/paths'
import { FooterActions } from '../FooterActions'
import { useQuery } from 'react-query'
import { groupService } from '../../../../services'
import { FormSectionRow, TitleRow, ValidationError } from '../style'
import { mapDTOtoAdminInformation, mapAdminInformationToDTO } from './mappers'

const labels = {
  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone',
  title: 'Title',
  email: 'Email'
}

export const AdminInformation: React.FC = () => {
  const history = useHistory()
  const toaster = useToaster()
  const stepName = 'adminInformation'

  const { state, setState, modifyGroup } = useContext(BusinessOnboardingContext)

  const { handleSubmit, control, reset, formState, watch } = useForm({
    mode: 'onChange'
  })

  const { data, isLoading } = useQuery(
    'getAdminInformation',
    () => groupService.getInfo(mapDTOtoAdminInformation, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (data: any) => {
        reset({ ...data })
        setState(data.stepNumber)
      }
    }
  )

  const errors = formState.errors

  const watchAdminEqualBilling = watch('adminEqualBilling')

  useStep(state, 2, stepName, data?.stepNumber || 1)

  const handleContinue = handleSubmit(async (formData) => {
    const DTO = mapAdminInformationToDTO(formData, watchAdminEqualBilling, data)

    try {
      await modifyGroup.mutateAsync(DTO)
      history.push(businessOnboardingPaths.employerPreferences)
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  const { maxPhoneLength, isValidEmail } = formValidations

  return (
    <>
      <Form>
        {isLoading ? (
          <CenteredSpinner style={{ height: '80vh' }}></CenteredSpinner>
        ) : (
          <>
            <TitleRow noGutters>
              <b>Admin Contact</b>
            </TitleRow>

            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.firstName}
                  </Form.Label>
                  <Controller
                    name="adminName.firstName"
                    control={control}
                    rules={{ required: getRequiredObject(labels.firstName) }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="First name"
                        isInvalid={Boolean(errors?.adminName?.firstName)}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.adminName?.firstName?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.lastName}
                  </Form.Label>
                  <Controller
                    name="adminName.lastName"
                    control={control}
                    rules={{ required: getRequiredObject(labels.lastName) }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Last Name"
                        isInvalid={Boolean(errors?.adminName?.lastName)}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.adminName?.lastName?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.phone}
                  </Form.Label>
                  <Controller
                    name="adminContact.workPhone"
                    rules={{
                      validate: maxPhoneLength,
                      required: getRequiredObject(labels.phone)
                    }}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        as={ReactInputMask}
                        isInvalid={Boolean(errors?.adminContact?.workPhone)}
                        mask="999-999-9999"
                        type="tel"
                        placeholder="Phone number"
                        {...{ maskChar: null }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.adminContact?.workPhone?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>{labels.title}</Form.Label>
                  <Controller
                    name="adminName.title"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Title"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required-field">
                    {labels.email}
                  </Form.Label>
                  <Controller
                    name="adminContact.workEmail"
                    control={control}
                    rules={{
                      required: getRequiredObject(labels.email),
                      validate: {
                        isValidEmail
                      }
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="email"
                        placeholder="Email"
                        isInvalid={Boolean(errors?.adminContact?.workEmail)}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.adminContact?.workEmail?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
            </Row>

            <TitleRow
              noGutters
              className={watchAdminEqualBilling ? 'disabled' : ''}
            >
              <b>Billing Contact</b>
            </TitleRow>
            <Row>
              <Col>
                <Form.Group>
                  <Controller
                    name="adminEqualBilling"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        className="custom-checkbox"
                        id="adminEqualBilling"
                        type="checkbox"
                        label="Admin contact is the same as billing contact"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <FormSectionRow
              style={{ border: 'none' }}
              className={watchAdminEqualBilling ? 'disabled' : ''}
            >
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label
                    className={watchAdminEqualBilling ? '' : 'required-field'}
                  >
                    {labels.firstName}
                  </Form.Label>
                  <Controller
                    name="billingName.firstName"
                    control={control}
                    rules={{
                      required: watchAdminEqualBilling
                        ? false
                        : getRequiredObject(labels.firstName)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        disabled={watchAdminEqualBilling}
                        isInvalid={Boolean(errors?.billingName?.firstName)}
                        placeholder="First name"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      !watchAdminEqualBilling &&
                      errors?.billingName?.firstName?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    className={watchAdminEqualBilling ? '' : 'required-field'}
                  >
                    {labels.lastName}
                  </Form.Label>
                  <Controller
                    name="billingName.lastName"
                    control={control}
                    rules={{
                      required: watchAdminEqualBilling
                        ? false
                        : getRequiredObject(labels.lastName)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        disabled={watchAdminEqualBilling}
                        placeholder="Last name"
                        isInvalid={Boolean(errors?.billingName?.lastName)}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      !watchAdminEqualBilling &&
                      errors?.billingName?.lastName?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    className={watchAdminEqualBilling ? '' : 'required-field'}
                  >
                    {labels.phone}
                  </Form.Label>
                  <Controller
                    name="billingContact.workPhone"
                    control={control}
                    rules={{
                      validate: !watchAdminEqualBilling
                        ? maxPhoneLength
                        : () => true,
                      required: watchAdminEqualBilling
                        ? false
                        : getRequiredObject(labels.phone)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        as={ReactInputMask}
                        mask="999 999-9999"
                        type="tel"
                        disabled={watchAdminEqualBilling}
                        isInvalid={Boolean(errors?.billingContact?.workPhone)}
                        placeholder="Phone number"
                        {...{ maskChar: null }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      !watchAdminEqualBilling &&
                      errors?.billingContact?.workPhone?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>{labels.title}</Form.Label>
                  <Controller
                    name="billingName.title"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        disabled={watchAdminEqualBilling}
                        placeholder="Title"
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    className={watchAdminEqualBilling ? '' : 'required-field'}
                  >
                    {labels.email}
                  </Form.Label>
                  <Controller
                    name="billingContact.workEmail"
                    control={control}
                    rules={{
                      validate: !watchAdminEqualBilling
                        ? isValidEmail
                        : () => true,
                      required: watchAdminEqualBilling
                        ? false
                        : getRequiredObject(labels.email)
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="email"
                        disabled={watchAdminEqualBilling}
                        placeholder="Email"
                        isInvalid={Boolean(errors?.billingContact?.workEmail)}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      !watchAdminEqualBilling &&
                      errors?.billingContact?.workEmail?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
            </FormSectionRow>
          </>
        )}
      </Form>
      <FooterActions
        continueHandler={handleContinue}
        backHandler={() =>
          history.push(businessOnboardingPaths.businessInformation)
        }
      />
    </>
  )
}
