import React from 'react'
import { Container } from 'react-bootstrap'

export interface IPageContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  size: 'sm' | 'md' | 'lg' | 'xl'
}

const sizes = {
  sm: '43.75rem',
  md: '45.625rem',
  lg: '68.75rem',
  xl: '81.25rem'
}

export const PageContainer: React.FC<IPageContainerProps> = ({
  size,
  children,
  ...p
}) => {
  return (
    <Container style={{ maxWidth: sizes[size] }} {...p}>
      {children}
    </Container>
  )
}

export default PageContainer
