import React, { ReactNode, useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Logo } from '../Logo/Logo'
import { AngleNavbarContext, useAngleNavbar } from './context'
import { INavItemProp, NavItem } from './NavItem'
import { SmallMenu } from './SmallMenu'
export { useAngleNavbar } from './context'

const CustomNav = styled.span`
  @media screen and (max-width: 575px) {
    display: none !important;
  }
`
export interface IAngleNavbarProps {
  items: INavItemProp[]
  bg?: string
  variant?: 'light' | 'dark'
  logo?: ReactNode
  avatar?: ReactNode
  barComponent?: React.FC<any>
  barIcon?: string
  className?: string
}

const NavOptions: React.FC<any> = ({ avatar }) => {
  const { barIsOpen } = useAngleNavbar()

  return (
    <React.Fragment>
      <CustomNav className="d-none d-sm-inline d-flex justify-content-end align-items-center">
        {avatar}
      </CustomNav>
      {!barIsOpen && (
        <div
          className="d-flex justify-content-end align-items-center d-sm-none"
          style={{ flex: 1, textAlign: 'right' }}
        >
          {/* <i
            role="button"
            onClick={() => toggleBar()}
            className="icon icon-search mr-2 text-white"
            style={{
              fontSize: '1.8rem',
              top: '2px',
              position: 'relative'
            }}
          ></i> */}

          {avatar}
        </div>
      )}
    </React.Fragment>
  )
}

export const AngleNavbar: React.FC<IAngleNavbarProps> = ({
  items = [],
  bg = 'primary',
  variant = 'dark',
  logo = null,
  avatar = null,
  className = '',
  barComponent: BarComponent,
  barIcon
}) => {
  const [showingMenu, setShowMenu] = useState(false)
  const [barIsOpen, setBarState] = useState(false)

  const toggleBar = () => setBarState((s) => !s)

  return (
    <AngleNavbarContext.Provider value={{ barIsOpen, toggleBar }}>
      <Navbar
        bg={bg}
        className={`py-0 px-3 angle-nav-bar ${className}`}
        fixed="top"
        variant={variant}
        style={{ height: 75 }}
      >
        <i
          role="button"
          data-testid="menu-button"
          onClick={() => {
            setShowMenu((s) => !s)
          }}
          className={`d-inline-flex d-sm-none icon icon-${
            showingMenu ? 'cancel' : 'menu'
          } mr-3 text-white`}
          style={{
            width: '23px',
            fontSize: '1.8rem',
            top: '-3px',
            position: 'relative'
          }}
        ></i>

        <Navbar.Brand className="d-none d-sm-inline mb-2">
          <Link to="/">{logo || <Logo variant="full" color="white" />}</Link>
        </Navbar.Brand>

        {!barIsOpen && (
          <Navbar.Brand className="d-inline d-sm-none">
            <Link to="/">{logo || <Logo variant="full" color="white" />}</Link>
          </Navbar.Brand>
        )}

        {BarComponent && (
          <BarComponent
            barIsOpen={barIsOpen}
            toggleBar={toggleBar}
            showingMenu={showingMenu}
          />
        )}
        <Nav className="ml-auto d-none d-sm-flex" style={{ height: 75 }}>
          {items?.map((n, index) => (
            <NavItem key={index} {...n} />
          ))}
        </Nav>
        <NavOptions avatar={avatar} />
      </Navbar>
      {showingMenu && <SmallMenu items={items} />}
    </AngleNavbarContext.Provider>
  )
}
