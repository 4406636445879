import { Accum } from './interfaces'
import { currencyFormatter } from '@angle/utils'

export const formatCurrencyForNonIntegers = (amount: number) => {
  if (typeof amount !== 'number') {
    return ''
  }
  if (Number.isInteger(amount)) {
    return (
      '$' +
      amount?.toLocaleString('en-US', {
        currency: 'USD'
      })
    )
  } else {
    return currencyFormatter.format(amount)
  }
}

export const getAccumsCardProps = (accumData: Accum, title: string) => {
  const max = accumData?.limit
  const used = accumData?.coverage
  const remaining = max - used
  let remainingText = ''
  if (remaining || remaining === 0) {
    const rest = max - used
    const remaining = formatCurrencyForNonIntegers(rest)
    remainingText = `${remaining} remaining`
  }
  return {
    title,
    currentValue: used,
    maxValue: max,
    remainingText,
    rx: accumData?.rx,
    medical: accumData?.medical
  }
}
