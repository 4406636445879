import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Wrapper } from './style'
import './style.css'
export interface ISelectFilterProps {
  value: string
  onChange: Function
  options: string[]
  name: string
  style?: any
}

export const SelectFilter: React.FC<ISelectFilterProps> = ({
  value,
  onChange,
  name,
  options,
  ...p
}) => {
  const [inputValue, setInputValue] = useState(value)

  const handleChange = (value: string) => {
    setInputValue(value)

    if (options.includes(value) || value === '') {
      onChange(value)
    } else {
      onChange('')
    }
  }

  return (
    <Wrapper {...p} className="select-filter">
      <Form.Control
        name={name}
        placeholder="Select specialty"
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
        id={name}
        list="data"
        className={value ? 'dropdown-active' : ''}
        style={{
          height: 39,
          border: '1px solid var(--primary)'
        }}
      />

      <div style={{ maxHeight: '200px' }}>
        <datalist id="data">
          {options.map((opt) => (
            <option value={opt} key={opt} />
          ))}
        </datalist>
      </div>
    </Wrapper>
  )
}

export default SelectFilter
