import { IPlanDetailDTO } from '../..'
import {
  IFamilyMember,
  IFamilyMembersDTO,
  IMedicalBenefitsDTO,
  IOutOfPocketDTO,
  IPlanDetail,
  IPlanListDTO,
  IPlanSBCDTO
} from '../../interfaces'
import { ApiService } from './base'

export class PlanService extends ApiService {
  // member portal my-plan
  async getPlan(): Promise<any> {
    return this.request({
      method: 'get',
      url: '/plan',
      credentials: true
    }).then((r) => r)
  }

  // we have to get rid of this mock route
  async getDetails(): Promise<IPlanDetail> {
    return this.request({
      method: 'post',
      url: '/plan/details',
      credentials: true
    }).then((r: IPlanDetailDTO) => ({ ...r, memberID: r.member_id }))
  }

  async getFamilyMembers(): Promise<IFamilyMember[]> {
    return this.request({
      method: 'post',
      url: '/plan/family-members',
      credentials: true
    }).then((r: IFamilyMembersDTO) => r.family_members)
  }

  async getSBC(planId: string, params?: string): Promise<IPlanSBCDTO> {
    const paramsString = params ? `?${params}` : ''

    return this.request({
      method: 'get',
      url: `/plans/${planId}/sbc${paramsString}`,
      credentials: true
    })
  }

  async getMedicalBenefits(): Promise<IMedicalBenefitsDTO> {
    return this.request({
      method: 'get',
      url: '/plan/medical/benefits',
      credentials: true
    })
  }

  async getOutOfPocket(): Promise<IOutOfPocketDTO> {
    return this.request({
      method: 'post',
      url: '/plan/out-of-pocket',
      credentials: true
    })
  }

  async getList({ groupId, subgroupId } : { groupId?: string, subgroupId?: number }): Promise<IPlanListDTO> {
    return this.request({
      method: 'get',
      url: groupId ? `/plans/${groupId}` : "/plans",
      params: { klass_id: subgroupId ? subgroupId : null },
      credentials: true
    })
  }
}
