import React, { useState, useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import ReactInputMask from 'react-input-mask'

import {
  Button,
  Col,
  EightThreeFourService,
  EmployeeProfile,
  Form,
  Modal,
  Row,
  useToaster
} from '../../../index'
import { ChildIcon } from './ChildIcon'
import { DomesticPartnerIcon } from './DomesticPartnerIcon'
import { EightThreeFourRequest } from '../../../interfaces/employer-broker-shared/eightthreefour'
import { states } from '@angle/utils'
import styled from 'styled-components'
import { ErrorSpan } from '../EmployerPortalAddReinstateEmployee/EmployeeBasicInfoForm'

type DependentType = 'spouse' | 'child'
export type DependentEditingState =
  | 'existing-no-edits'
  | 'existing-edits'
  | 'new'

interface AddDependentFormfields {
  dependentType: DependentType
  firstName: string
  middleName?: string
  lastName: string
  suffix?: string
  genderIdentity?: string
  sex: string
  dateOfBirth: string
  ssn: string
  email?: string
  street_1: string
  street_2?: string
  city: string
  state: string
  zip: string
}

export interface DependentFormType extends AddDependentFormfields {
  editingState: DependentEditingState
  memberId?: string
}

export type Address = {
  street_1: string
  street_2?: string
  city: string
  state: string
  zip: string
}

/*
this form uses the 11 digit SSN, with 2 dashes
you will need to use this to format any SSN in edit mode,
to get the SSN to load properly
*/
const addDashesToSSN = (ssn: string | undefined) => {
  if (ssn && ssn.length === 9) {
    return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`
  }

  return ssn
}

const AddDependentFormSchema = z.object({
  dependentType: z.string(),
  firstName: z.string().min(1, 'First name is required'),
  middleName: z.string().optional(),
  lastName: z.string().min(1, 'Last name is required'),
  suffix: z.string().optional(),
  email: z.string().email().optional().or(z.literal('')),
  genderIdentity: z.string().optional(),
  sex: z.string().min(1, 'Birth Gender is required'),
  dateOfBirth: z.string().nonempty({ message: 'Date of Birth is required' }),
  ssn: z
    .string()
    .nonempty({ message: 'SSN is required' })
    .min(11, 'SSN needs to be in a valid format')
    .max(11, 'SSN needs to be in a valid format'),
  street_1: z.string().nonempty({ message: 'Street Address is required' }),
  street_2: z.string().optional(),
  city: z.string().nonempty({ message: 'City is required' }),
  state: z
    .string()
    .nonempty({ message: 'State is required' })
    .min(2, 'State needs to be in a valid format')
    .max(2, 'State needs to be in a valid format'),
  zip: z
    .string()
    .nonempty({ message: 'Zip Code is required' })
    .min(5, 'ZIP needs to be in a valid format')
    .max(5, 'ZIP needs to be in a valid format')
})

export const EmployerPortalAddDependent: React.FC<{
  editCallback?: (data: DependentFormType) => void
  editModeData?: DependentFormType
  eightThreeFourService: EightThreeFourService
  employee?: EmployeeProfile | undefined
  employeeAddress: Address
  isAddDependentModalVisible: boolean
  returnDataToParent?: (data: DependentFormType) => void
  setAddDependentModalVisible: (bool: boolean) => void
  refetchEmployee?: () => void
}> = ({
  editCallback,
  editModeData,
  eightThreeFourService,
  employee,
  employeeAddress,
  isAddDependentModalVisible,
  returnDataToParent,
  setAddDependentModalVisible,
  refetchEmployee
}) => {
  const defaultValues: AddDependentFormfields = {
    dependentType: editModeData?.dependentType || 'spouse',
    firstName: editModeData?.firstName || '',
    middleName: editModeData?.middleName || '',
    lastName: editModeData?.lastName || '',
    suffix: editModeData?.suffix || '',
    genderIdentity: editModeData?.genderIdentity || '',
    sex: editModeData?.sex || '',
    dateOfBirth: editModeData?.dateOfBirth || '',
    ssn: addDashesToSSN(editModeData?.ssn) || '',
    email: editModeData?.email || '',
    street_1: editModeData?.street_1 || '',
    street_2: editModeData?.street_2 || '',
    city: editModeData?.city || '',
    state: editModeData?.state || '',
    zip: editModeData?.zip || ''
  }

  const InfoText = styled.span`
    font-size: 16px;
    color: #6c757d;
    padding-top: 1em;
  `
  const DownloadText = styled.div`
    font-size: 16px;
    padding-top: 1em;
    color: #6d39d9;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 7px;
  `
  const [hasSameAddressAsSubscriber, setHasSameAddressAsSubscriber] =
    useState(false)

  const { showSuccess, showError } = useToaster()

  const {
    register,
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<AddDependentFormfields>({
    defaultValues,
    resolver: zodResolver(AddDependentFormSchema)
  })

  useEffect(() => {
    if (hasSameAddressAsSubscriber) {
      handleSameAddress()
    }
  }, [hasSameAddressAsSubscriber, setValue])

  useEffect(() => {
    if (isAddDependentModalVisible && editModeData) {
      setValue('dependentType', editModeData.dependentType || 'spouse'),
        setValue('firstName', editModeData.firstName || ''),
        setValue('middleName', editModeData.middleName || ''),
        setValue('lastName', editModeData.lastName || ''),
        setValue('suffix', editModeData.suffix || ''),
        setValue('genderIdentity', editModeData.genderIdentity || ''),
        setValue('sex', editModeData.sex || ''),
        setValue('dateOfBirth', editModeData.dateOfBirth || ''),
        setValue('ssn', addDashesToSSN(editModeData.ssn) || ''),
        setValue('email', editModeData.email || ''),
        setValue('street_1', editModeData.street_1 || ''),
        setValue('street_2', editModeData.street_2 || ''),
        setValue('city', editModeData.city || ''),
        setValue('state', editModeData.state || ''),
        setValue('zip', editModeData.zip || '')
    }
  }, [isAddDependentModalVisible, editModeData])

  const enrollDependent = useMutation({
    mutationFn: (data: any) =>
      eightThreeFourService.postMemberInformation(data),
    onSuccess: (response, data) => {
      if (
        response.success &&
        response.data.total_members === response.data.total_success
      ) {
        showSuccess(
          `Dependent ${data.firstName} ${data.lastName} enrolled successfully!`
        )
        setHasSameAddressAsSubscriber(false)
        setAddDependentModalVisible(false)
        reset()
        if (refetchEmployee) {
          refetchEmployee()
        }
      } else if (response.link && response.success === false) {
        showError(`Error enrolling dependent - ${response.message}`)
        setTimeout(() => window.open(response.link, '_blank'), 500)
      } else {
        showError(`Error enrolling dependent}`)
      }
    },
    onError: () => {
      showError(`Error enrolling dependent}`)
    }
  })

  const sendEnrollmentRequest = async (data) => {
    if (!employee) {
      return
    }
    const params: EightThreeFourRequest = {
      group_id: employee?.group_id,
      members: [
        {
          first_name: data.firstName,
          last_name: data.lastName,
          middle_name: data.middleName,
          suffix: data.suffix,
          relationship: data.dependentType,
          family_indicator:
            data.dependentType === 'spouse'
              ? 'employee_spouse'
              : 'employee_child',
          group_id: employee?.group_id,
          action_requested: 'enroll',
          parent_member_id: employee?.id,
          gender: data.sex,
          identified_gender: data.genderIdentity,
          insured_ssn: employee?.ssn,
          ssn: data.ssn,
          date_of_birth: data.dateOfBirth,
          coverage_start_date: employee?.coverageStart,
          coverage_end_date: employee?.coverageEnd,
          plan_name: employee?.plan,
          subgroup: employee?.subgroup,
          street_1: data.street_1,
          street_2: data.street_2,
          state: data.state,
          city: data.city,
          zip: data.zip
        }
      ]
    }
    await enrollDependent.mutateAsync(params)
  }

  const handleSameAddress = () => {
    setValue('street_1', employeeAddress.street_1)
    setValue('city', employeeAddress.city)
    setValue('state', employeeAddress.state)
    setValue('zip', employeeAddress.zip)

    if (employeeAddress.street_2) {
      setValue('street_2', employeeAddress.street_2)
    }
  }

  const onSubmit: SubmitHandler<AddDependentFormfields> = (data) => {
    // returnDataToParent is the add flow
    if (returnDataToParent || editModeData) {
      /*
      this means the parent is the Add Employee Flow.
      Rather than make an API call, invoke returnDataToParent()
      */
      if (editCallback) {
        // edit unsaved dep
        editCallback({ ...data, editingState: 'existing-edits' })
      } else if (returnDataToParent) {
        // add unsaved dep
        returnDataToParent({ ...data, editingState: 'new' })
      }

      setAddDependentModalVisible(false)
      setHasSameAddressAsSubscriber(false)
      reset()
    } else {
      sendEnrollmentRequest(data)
    }
  }

  const getModal = () => {
    return (
      <Modal
        show={isAddDependentModalVisible}
        centered
        size="lg"
        onHide={() => {
          setAddDependentModalVisible(false)
          reset()
          setHasSameAddressAsSubscriber(false)
        }}
        dialogClassName="groupFormModalDialog"
        className="groupFormModal"
        style={{
          zIndex: 2000
        }}
      >
        <Modal.Header className="border-bottom pb-4 pt-4" closeButton>
          <Modal.Title>Add a Dependent</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getContent()}</Modal.Body>
        <Modal.Footer className="border-top pb-3 pt-3">
          <Button
            className="w-25"
            disabled={enrollDependent.isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Add a Dependent
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const getContent = () => {
    return (
      <>
        <div className="personalInformation border-bottom">
          <h6 className="pb-2">Personal Information</h6>
          <Form.Group>
            <Form.Label>
              Is this dependant a spouse/domestic partner or child?
            </Form.Label>
            <Row>
              <Col>
                <div className="border 1px rounded solid p-3">
                  <Form.Check type="radio">
                    <Form.Check.Input
                      type="radio"
                      value="spouse"
                      {...register('dependentType')}
                    />
                    <DomesticPartnerIcon />
                    <Form.Check.Label>
                      <span className="ml-2">Spouse/Domestic Partner</span>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </Col>
              <Col>
                <div className="border 1px rounded solid p-3">
                  <Form.Check type="radio">
                    <Form.Check.Input
                      type="radio"
                      value="child"
                      {...register('dependentType')}
                    />
                    <Form.Check.Label>
                      <ChildIcon />
                      <span className="ml-2">Child</span>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </Col>
            </Row>
          </Form.Group>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder="First Name"
                  className="bg-white"
                  {...register('firstName')}
                  isInvalid={!!errors?.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.firstName?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Middle Name (Optional)</Form.Label>
                <Form.Control
                  placeholder="Middle Name"
                  className="bg-white"
                  {...register('middleName')}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  placeholder="Last Name"
                  className="bg-white"
                  isInvalid={!!errors?.lastName}
                  {...register('lastName')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.lastName?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Suffix (Optional)</Form.Label>
                <Form.Control
                  placeholder="Suffix"
                  className="bg-white"
                  {...register('suffix')}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>What gender do you identify as?</Form.Label>
                <Form.Control
                  as="select"
                  className="bg-white"
                  isInvalid={!!errors?.genderIdentity}
                  {...register('genderIdentity')}
                >
                  <option value="" disabled>
                    Select gender identity
                  </option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="U">Female to Male</option>
                  <option value="U">Male to Female</option>
                  <option value="U">Non-Binary</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors?.genderIdentity?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Sex (Gender at Birth)</Form.Label>
                <Form.Control
                  as="select"
                  className="bg-white"
                  isInvalid={!!errors?.sex}
                  {...register('sex')}
                >
                  <option value="" disabled>
                    Select birth gender
                  </option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors?.sex?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Controller
                  control={control}
                  name="dateOfBirth"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select a date"
                      onChange={(date) =>
                        onChange(moment(date).format('YYYY-MM-DD'))
                      }
                      selected={
                        value ? moment(value, 'YYYY-MM-DD').toDate() : null
                      }
                      dateFormat="MM/dd/yyyy"
                      maxDate={new Date()}
                    />
                  )}
                />
                <ErrorSpan>{errors?.dateOfBirth?.message}</ErrorSpan>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Social Security Number</Form.Label>
                <Controller
                  key="ssn"
                  name="ssn"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      placeholder="Enter Dependent's SSN"
                      className="bg-white"
                      isInvalid={!!errors?.ssn}
                      as={ReactInputMask}
                      mask="999-99-9999"
                      {...register('ssn', {
                        required: 'This field is required',
                        validate: {
                          invalidSsn: (val) => {
                            if (
                              val === '000-00-0000' ||
                              val === '111-11-1111'
                            ) {
                              return 'Please enter a valid social security number'
                            } else return true
                          }
                        }
                      })}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.ssn?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Personal Email (Optional)</Form.Label>
                <Form.Control
                  placeholder="Email"
                  className="bg-white"
                  isInvalid={!!errors?.email}
                  {...register('email')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="addressInformation">
          <h6 className="pb-2 pt-4">Mailing Address Information</h6>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Mailing Address is same as subscriber?"
              checked={hasSameAddressAsSubscriber}
              onChange={() =>
                setHasSameAddressAsSubscriber(!hasSameAddressAsSubscriber)
              }
            />
          </Form.Group>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  placeholder="Street Address"
                  className={hasSameAddressAsSubscriber ? '' : 'bg-white'}
                  isInvalid={!!errors.street_1}
                  {...register('street_1')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.street_1?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Street Address Line 2</Form.Label>
                <Form.Control
                  placeholder="Street Address Line 2"
                  className={hasSameAddressAsSubscriber ? '' : 'bg-white'}
                  isInvalid={!!errors.street_2}
                  {...register('street_2')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.street_2?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  placeholder="City"
                  className={hasSameAddressAsSubscriber ? '' : 'bg-white'}
                  isInvalid={!!errors.city}
                  {...register('city')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.city?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  placeholder="Select a state"
                  as="select"
                  className={hasSameAddressAsSubscriber ? '' : 'bg-white'}
                  isInvalid={Boolean(errors?.state)}
                  {...register('state')}
                >
                  <option value="" disabled>
                    Select a state
                  </option>
                  {Object.keys(states).map((s) => (
                    <option key={s} value={s}>
                      {(states as any)[s]}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors?.state?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  placeholder="Zip Code"
                  className={hasSameAddressAsSubscriber ? '' : 'bg-white'}
                  isInvalid={!!errors.zip}
                  {...register('zip')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.zip?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="border-top pt-4 mt-1">
            <Col>
              <h5>What if dependent is disabled?</h5>
              <InfoText>
                Additional information and clinical review is required to add
                disabled dependents. Please contact
                accountmanagement@anglehealth.com to add a disabled dependent to
                this subscriber.
              </InfoText>
              <DownloadText>
                {' '}
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 12 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.375 0.333496V10.6851L2.07422 7.38428L1.17578 8.28271L5.55078 12.6577L6 13.0874L6.44922 12.6577L10.8242 8.28271L9.92578 7.38428L6.625 10.6851V0.333496H5.375ZM0.375 14.0835V15.3335H11.625V14.0835H0.375Z"
                    fill="#6D39D9"
                  />
                </svg>
                <a download target="_blank" href="/disabled-dependent-form.pdf">
                  <span>Download Disabled Dependent Information Form</span>
                </a>
              </DownloadText>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  return <>{getModal()}</>
}
