import React, { useState } from 'react'
import {
  Col,
  Form,
  Row,
  useMonthlyDates,
  NewHireWaitingPeriodChoices
} from 'react-angle-dashboard-components'
import { DateHandler, nonNegativeValues } from '@angle/utils'
import { Controller } from 'react-hook-form'
import { TitleRow, ValidationError } from '../../style'
import {
  formValidations,
  getRequiredObject
} from '../../../../../utils/formValidations'
import { labels } from '../EmployerPreferences'

interface NonLevelFundedProps {
  formState: any
  control: any
  register: any
  data: any
}

export const NonLevelFundedEmployerPreferences: React.FC<
  NonLevelFundedProps
> = ({ formState, control, register, data }) => {
  const [isNumOfEmployeesBlurred, setIsNumOfEmployeesBlurred] = useState(false)

  const options = useMonthlyDates(new Date(), 6)

  // add previous month
  const date = DateHandler.use(new Date()).startOf('month')
  options.unshift({
    label: date.format('MMMM D, YYYY'),
    value: date.format('YYYY-MM-DD')
  })

  const isLargeGroup = data?.isLargeGroup

  const {
    onlyNumbers,
    minMedicare,
    minNumOfEmployees,
    maxNumOfEmployees,
    hoursWorkedRange
  } = formValidations

  return (
    <>
      <TitleRow noGutters>
        <b>Employer Preferences</b>
      </TitleRow>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.effectiveDate}
            </Form.Label>
            <Controller
              name="effectiveDate"
              control={control}
              rules={{
                required: getRequiredObject(labels.effectiveDate)
              }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  isInvalid={Boolean(formState.errors?.effectiveDate)}
                  as="select"
                >
                  <option value="" disabled hidden>
                    Select a Date
                  </option>
                  {options.map((e) => (
                    <option key={e.value} value={e.value}>
                      {e.label}
                    </option>
                  ))}
                </Form.Control>
              )}
            />

            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.effectiveDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.eligibilityHours}
            </Form.Label>
            <Controller
              name="eligibilityHours"
              rules={{
                required: {
                  value: true,
                  message: 'Number of hours (30 or more) is required'
                },
                validate: hoursWorkedRange
              }}
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  {...nonNegativeValues}
                  type="number"
                  placeholder="Number of hours (30 or more)"
                  isInvalid={Boolean(formState.errors?.eligibilityHours)}
                />
              )}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.eligibilityHours?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label
              className="required-field"
              style={{ marginBottom: 32, whiteSpace: 'nowrap' }}
            >
              {labels.numOfEmployees}
            </Form.Label>
            <Controller
              name="numOfEmployees"
              control={control}
              rules={{
                required: getRequiredObject(labels.numOfEmployees),
                validate: isLargeGroup ? minNumOfEmployees : maxNumOfEmployees
              }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  {...nonNegativeValues}
                  isInvalid={Boolean(formState.errors?.numOfEmployees)}
                  type="number"
                  placeholder="Eligible employees"
                  onBlur={() => setIsNumOfEmployeesBlurred(true)}
                />
              )}
            />
            <ValidationError>
              {(formState.isSubmitted || isNumOfEmployeesBlurred) &&
                formState.errors?.numOfEmployees?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
      {isLargeGroup && (
        <>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-0">
                <Form.Label className="required-field">
                  {labels.medicareNum}
                </Form.Label>
                <Form.Control
                  placeholder="Enter number of employees"
                  type="number"
                  isInvalid={Boolean(formState.errors?.medicareNum)}
                  {...register('medicareNum', {
                    required: getRequiredObject(labels.medicareNum),
                    validate: isLargeGroup
                      ? { minMedicare, onlyNumbers }
                      : () => true
                  })}
                />
                <small className="text-secondary">
                  refer to line 1 of your most recent federal tax return form
                  941 or 944
                </small>
              </Form.Group>
            </Col>
          </Row>
          <ValidationError className="mt-0">
            {formState.isSubmitted && formState.errors?.medicareNum?.message}
          </ValidationError>
        </>
      )}
      <TitleRow noGutters>
        <b>New Hire Waiting Period</b>
      </TitleRow>
      <Row noGutters>
        <Col>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.newHireWaitingPeriod}
            </Form.Label>
            <Controller
              control={control}
              rules={{
                required: getRequiredObject('New Hire Waiting Period')
              }}
              name="newHireWaitingPeriod"
              render={({ field }) => (
                <Form.Group className="mb-0">
                  <Row>
                    {Object.keys(NewHireWaitingPeriodChoices).map((choice) => (
                      <Col xs={12} md={7} key={choice}>
                        <Form.Check
                          {...field}
                          className="custom-radio"
                          type="radio"
                          value={choice}
                          label={NewHireWaitingPeriodChoices[choice]}
                          onChange={(e) => {
                            field.onChange(e.target.value)
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              )}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.newHireWaitingPeriod?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
