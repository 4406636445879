import React from 'react'
import { Col, DatePicker, Form, Row } from 'react-angle-dashboard-components'
import { Controller } from 'react-hook-form'
import { ValidationError } from '../../style'
import { getRequiredObject } from '../../../../../utils/formValidations'
import { changingReasons } from '../changingReasons'

const labels = {
  currentCarrier: 'Current Medical Insurance Carrier',
  currentCarrierStartDate: 'Start Date',
  currentCarrierEndDate: 'Anticipated Termination Date',
  changingReason: 'Reason for changing'
}

interface CurrentCarrierProps {
  control: any
  formState: any
  watch: any
}

export const CurrentCarrier: React.FC<CurrentCarrierProps> = ({
  control,
  formState,
  watch
}) => {
  const isOtherChangingReason = watch('changingReason') === 'Other'

  return (
    <>
      <Row>
        <Form.Group className="w-100">
          <Col>
            <Form.Label className="required-field">
              {labels.currentCarrier}
            </Form.Label>
            <Controller
              name="currentCarrier"
              control={control}
              rules={{
                required: getRequiredObject(labels.currentCarrier)
              }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  isInvalid={Boolean(formState.errors?.currentCarrier)}
                  placeholder="Current Carrier"
                />
              )}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.currentCarrier?.message}
            </ValidationError>
          </Col>
        </Form.Group>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.currentCarrierStartDate}
            </Form.Label>
            <Controller
              name="currentCarrierStartDate"
              control={control}
              rules={{
                required: getRequiredObject(labels.currentCarrierStartDate)
              }}
              render={({ field }) => <DatePicker {...field} />}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.currentCarrierStartDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.currentCarrierEndDate}
            </Form.Label>
            <Controller
              name="currentCarrierEndDate"
              control={control}
              rules={{
                required: getRequiredObject(labels.currentCarrierEndDate)
              }}
              render={({ field }) => <DatePicker {...field} />}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.currentCarrierEndDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
      <Row noGutters>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label>{labels.changingReason}</Form.Label>
            <Controller
              name="changingReason"
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  as="select"
                  type="text"
                  placeholder="Reason for Changing"
                  isInvalid={Boolean(formState.errors?.changingReason)}
                >
                  <option value="" disabled hidden>
                    Select an option
                  </option>
                  {Object.keys(changingReasons).map((reason: string) => (
                    <option key={reason} value={reason}>
                      {reason}
                    </option>
                  ))}
                </Form.Control>
              )}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.changingReason?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        {isOtherChangingReason && (
          <Col xs={12} md={6} className="pl-0 pl-md-3">
            <Form.Group>
              <Form.Label>Reason</Form.Label>
              <Controller
                name="otherChangingReason"
                control={control}
                render={({ field }) => (
                  <Form.Control {...field} type="text" placeholder="Optional" />
                )}
              />
              <ValidationError>
                {formState.isSubmitted &&
                  formState.errors?.otherChangingReason?.message}
              </ValidationError>
            </Form.Group>
          </Col>
        )}
      </Row>
    </>
  )
}
