import React from 'react'
import {
  Container,
  Nav,
  Tab,
  ErrorPageLayout,
  Button,
  DocumentService
} from '../../../index'
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
  Switch,
  useParams
} from 'react-router-dom'
import { DocumentsTab } from './components/DocumentsTab'
import { DocumentsUpload } from './components/DocumentsUpload'

export const EmployerPortalDocuments: React.FC<{
  documentService: DocumentService
  isBrokerPortal?: boolean
}> = ({ isBrokerPortal, documentService }) => {
  const history = useHistory()
  const { groupId } = useParams<{ groupId: string }>()
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const key = pathname.slice(`${path}/`.length)

  const onSelect = (k: any) => {
    const path =
      isBrokerPortal && groupId
        ? `/groups/${groupId}/documents/${k}`
        : `/documents/${k}`
    history.push(path)
  }

  if (isBrokerPortal && !groupId) {
    return (
      <ErrorPageLayout
        errorTitle="404 - Not Found"
        errorSubtitle="Something went wrong locating this group. Please reach out to Angle Health if the issue continues."
        button={
          <Button onClick={() => history.push('/')}>Back to Group List</Button>
        }
      />
    )
  }

  return (
    <Tab.Container
      id="document-tabs-container"
      activeKey={key}
      onSelect={onSelect}
    >
      <section className="bg-light">
        <Container className="mt-5 pt-3 px-5 mx-auto">
          <h1>Documents and Forms</h1>

          <Nav variant="tabs" className="mt-5">
            <Nav.Item className="mr-3">
              <Nav.Link eventKey="">Documents</Nav.Link>
            </Nav.Item>

            <Nav.Item className="mr-3">
              <Nav.Link eventKey="upload">Upload</Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </section>
      <Container className="mt-5 mx-auto px-5 pb-5">
        <Switch>
          <Route
            exact
            path={isBrokerPortal ? '/groups/:groupId/documents' : `${path}/`}
            render={() => (
              <DocumentsTab
                documentService={documentService}
                isBrokerPortal={!!isBrokerPortal}
              />
            )}
          />
          <Route
            exact
            path={
              isBrokerPortal
                ? '/groups/:groupId/documents/upload'
                : `${path}/upload`
            }
            render={() => (
              <DocumentsUpload
                documentService={documentService}
                isBrokerPortal={!!isBrokerPortal}
              />
            )}
          />
        </Switch>
      </Container>
    </Tab.Container>
  )
}
