import React, { MouseEventHandler, ReactNode } from 'react'
import { NavDropdown } from 'react-bootstrap'
import {
  DropdownContainer,
  DropdownContent,
  DropdownItem,
  DropdownItemIcon,
  DropdownTitle,
  UserDropdownHeader,
  UserDropdownTitle
} from './styles'

export interface IUserDropdownItem {
  icon: string
  title: string
  callback: MouseEventHandler<HTMLDivElement>
  iconClass?: string
}

interface UserDropdownProps {
  avatar: ReactNode
  email: string
  userName: string
  items: IUserDropdownItem[]
}

export const UserDropdown = ({
  avatar,
  email,
  items,
  userName
}: UserDropdownProps) => {
  return (
    <NavDropdown
      id="user-dropdown"
      title={avatar}
      className="u-dropdown"
      alignRight={true}
    >
      <DropdownContainer className="shadow-sm">
        <UserDropdownHeader>
          <div style={{ minWidth: 40 }}>{avatar}</div>
          <UserDropdownTitle>
            <h5>{userName}</h5>
            <p className="m-0 text-secondary">{email}</p>
          </UserDropdownTitle>
        </UserDropdownHeader>
        {items.map((i: IUserDropdownItem, index) => (
          <DropdownItem onClick={i.callback} key={index}>
            <DropdownItemIcon>
              <i
                className={`icon icon-${i.icon} text-primary ${i.iconClass}`}
              />
            </DropdownItemIcon>
            <DropdownContent>
              <DropdownTitle className="fs-18">{i.title}</DropdownTitle>
            </DropdownContent>
          </DropdownItem>
        ))}
      </DropdownContainer>
    </NavDropdown>
  )
}
