import styled from 'styled-components'

export const DirectoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 992px) {
    height: 100%;
  }
`

export const DirectoryContent = styled.div`
  display: flex;
  width: 100%;
  box-shadow: rgb(0 0 0 / 75%) 0px 5px 10px -11px inset;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    flex-grow: 1;
    overflow: hidden;
    flex-direction: row;
  }
`

export const LeftColumn = styled.div<{ navbarHeight: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 600px;
  order: 2;
  @media screen and (min-width: 992px) {
    order: 1;
    width: 40%;
    height: calc(100vh - ${(props) => props.navbarHeight}px);
  }
`

export const RightColumn = styled.div<{ navbarHeight: number }>`
  width: 100%;
  height: 600px;
  order: 1;
  @media screen and (min-width: 992px) {
    order: 2;
    width: 60%;
    height: calc(100vh - ${(props) => props.navbarHeight}px);
  }
`

export const ResponsiveMapContainer = styled.div<{ navbarHeight: number }>`
  height: 600px;

  @media screen and (min-width: 992px) {
    height: calc(100vh - ${(props) => props.navbarHeight}px);
  }
`

export const PaginatorContainer = styled.div`
  padding: 1rem;
`

export const ListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  @media screen and (min-width: 992px) {
    flex-grow: 1;
  }
`
