import React from 'react'
import { DependentRegistrationForm } from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { useDependatForm } from './context'

export const RegistrationForm = () => {
  const {
    control,
    isSpouse,
    getErrors,
    trigger,
    formState,
    getValues,
    healthInsurance,
    unregister,
    setValue
  } = useDependatForm()
  const history = useHistory()

  const goBack = () => {
    history.push(`/employees/1`)
  }
  const submitForm = async () => {
    await trigger()
    const hasError = Object.keys(formState.errors).length
    if (!hasError)
      history.push('/employees/1/dependents/registration/confirmation')
  }

  return (
    <DependentRegistrationForm
      healthInsurance={healthInsurance}
      control={control as any}
      formState={formState}
      getErrors={getErrors}
      getValues={getValues}
      isSpouse={isSpouse}
      onBack={goBack}
      onContinue={submitForm}
      trigger={trigger}
      unregister={unregister}
      setValue={setValue as any}
    />
  )
}

export default RegistrationForm
