import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Form, Row } from 'react-angle-dashboard-components'
import { Controller, Control, FormState } from 'react-hook-form'
import {
  formValidations,
  getRequiredObject
} from '../../../../../utils/formValidations'
import { CarrierData, CarrierFormData } from '../../../interfaces'
import { FormSectionRow, TitleRow, ValidationError } from '../../style'
import { Vision } from './Vision'
import { Dental } from './Dental'
import { changingReasons } from '../changingReasons'

const labels = {
  currentCarrier: 'Current Medical Insurance Carrier',
  currentCarrierStartDate: 'Start Date',
  currentCarrierEndDate: 'Anticipated Termination Date',
  lessThanThree:
    'Has the company been with the current medical insurance carrier less than three (3) years?',
  changingReason: 'Reason for changing'
}

interface CurrentCarriersProps {
  control: Control
  formState: FormState<CarrierFormData>
  hasCurrentCarrier: boolean
  setValue: any
  data: CarrierData
  watch: any
}

export const CurrentCarriers: React.FC<CurrentCarriersProps> = ({
  control,
  formState,
  hasCurrentCarrier,
  setValue,
  data,
  watch
}) => {
  const { required } = formValidations

  const [hasDental, setHasDental] = useState(false)
  const [hasVision, setHasVision] = useState(false)

  const isOtherChangingReason = watch('changingReason') === 'Other'

  useEffect(() => {
    setHasDental(!!data?.dentalCarrier)
    setHasVision(!!data?.visionCarrier)
  }, [data])
  return (
    <>
      <Row>
        <Form.Group className="w-100">
          <Col>
            <Form.Label className="required-field">
              {labels.currentCarrier}
            </Form.Label>
            <Controller
              name="currentCarrier"
              control={control}
              rules={{
                required: hasCurrentCarrier
                  ? getRequiredObject(labels.currentCarrier)
                  : false
              }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  placeholder="Current Carrier"
                  isInvalid={Boolean(formState.errors?.currentCarrier)}
                />
              )}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.currentCarrier?.message}
            </ValidationError>
          </Col>
        </Form.Group>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.currentCarrierStartDate}
            </Form.Label>
            <Controller
              rules={{
                required: hasCurrentCarrier
                  ? getRequiredObject(labels.currentCarrierStartDate)
                  : false
              }}
              name="currentCarrierStartDate"
              control={control}
              render={({ field }) => <DatePicker {...field} />}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.currentCarrierStartDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.currentCarrierEndDate}
            </Form.Label>
            <Controller
              name="currentCarrierEndDate"
              control={control}
              rules={{
                required: hasCurrentCarrier
                  ? getRequiredObject(labels.currentCarrierEndDate)
                  : false
              }}
              render={({ field }) => <DatePicker {...field} />}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.currentCarrierEndDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Label className="required-field">
            {labels.lessThanThree}
          </Form.Label>
        </Col>
      </Row>
      <Form.Group>
        <Row>
          <Col xs={12} md={7}>
            <Controller
              name="lessThanThree"
              control={control}
              rules={{ required: hasCurrentCarrier ? required : false }}
              render={({ field }) => (
                <Form.Check
                  {...field}
                  type="radio"
                  value="false"
                  label="No"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={7}>
            <Controller
              name="lessThanThree"
              control={control}
              rules={{ required: hasCurrentCarrier ? required : false }}
              render={({ field }) => (
                <Form.Check
                  {...field}
                  type="radio"
                  value="true"
                  label="Yes"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <ValidationError>
          {formState.isSubmitted && formState.errors?.lessThanThree?.message}
        </ValidationError>
      </Form.Group>
      <FormSectionRow noGutters>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.changingReason}
            </Form.Label>
            <Controller
              name="changingReason"
              control={control}
              rules={{
                required: hasCurrentCarrier
                  ? getRequiredObject(labels.changingReason)
                  : false
              }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  as="select"
                  type="text"
                  isInvalid={Boolean(formState.errors?.changingReason)}
                  placeholder="Reason for Changing"
                >
                  <option value="" disabled hidden>
                    Select an option
                  </option>
                  {Object.keys(changingReasons).map((reason: string) => (
                    <option key={reason} value={reason}>
                      {reason}
                    </option>
                  ))}
                </Form.Control>
              )}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.changingReason?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        {isOtherChangingReason && (
          <Col xs={12} md={6} className="pl-0 pl-md-3">
            <Form.Group>
              <Form.Label>Reason</Form.Label>
              <Controller
                name="otherChangingReason"
                control={control}
                render={({ field }) => (
                  <Form.Control {...field} type="text" placeholder="Optional" />
                )}
              />
              <ValidationError>
                {formState.isSubmitted &&
                  formState.errors?.otherChangingReason?.message}
              </ValidationError>
            </Form.Group>
          </Col>
        )}
      </FormSectionRow>
      <TitleRow noGutters>
        <b>Dental Insurance (Optional)</b>
      </TitleRow>
      <Row noGutters>
        <Form.Group>
          <Form.Label className="required-field">
            Do you have a current dental insurance carrier?
          </Form.Label>
          <Row>
            <Col xs={12} md={7}>
              <Controller
                name="hasDental"
                control={control}
                rules={{
                  required: hasCurrentCarrier ? required : false
                }}
                render={({ field }) => (
                  <Form.Check
                    {...field}
                    type="radio"
                    value="false"
                    label="No"
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setHasDental(false)
                      setValue('dentalCarrier', '')
                      setValue('dentalStartDate', '')
                      setValue('dentalEndDate', '')
                    }}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={7}>
              <Controller
                name="hasDental"
                rules={{
                  required: hasCurrentCarrier ? required : false
                }}
                control={control}
                render={({ field }) => (
                  <Form.Check
                    {...field}
                    type="radio"
                    value="true"
                    label="Yes"
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setHasDental(true)
                    }}
                  />
                )}
              />
            </Col>
          </Row>
          <ValidationError>
            {formState.isSubmitted && formState.errors?.hasDental?.message}
          </ValidationError>
        </Form.Group>
      </Row>
      {hasDental && (
        <Dental
          control={control}
          formState={formState}
          isRequired={hasDental && hasCurrentCarrier}
        />
      )}
      <TitleRow noGutters>
        <b>Vision Insurance (Optional)</b>
      </TitleRow>
      <Row noGutters>
        <Form.Group>
          <Form.Label className="required-field">
            Do you have a current vision insurance carrier?
          </Form.Label>
          <Row>
            <Col xs={12} md={7}>
              <Controller
                name="hasVision"
                control={control}
                rules={{
                  required: hasCurrentCarrier ? required : false
                }}
                render={({ field }) => (
                  <Form.Check
                    {...field}
                    type="radio"
                    value="false"
                    label="No"
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setValue('hasVision', 'false')
                      setHasVision(false)
                    }}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={7}>
              <Controller
                name="hasVision"
                rules={{
                  required: hasCurrentCarrier ? required : false
                }}
                control={control}
                render={({ field }) => (
                  <Form.Check
                    {...field}
                    type="radio"
                    value="true"
                    label="Yes"
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setValue('hasVision', 'true')
                      setHasVision(true)
                    }}
                  />
                )}
              />
            </Col>
          </Row>
          <ValidationError>
            {formState.isSubmitted && formState.errors?.hasVision?.message}
          </ValidationError>
        </Form.Group>
      </Row>
      {hasVision && (
        <Vision
          control={control}
          formState={formState}
          isRequired={hasVision && hasCurrentCarrier}
        />
      )}
    </>
  )
}
