import React from 'react'
import { ActionReminderContainer, Icon } from './style'

export interface IActionReminderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  icon: string
  title: string
  lastVisit: string
  bookText: string
  completed?: boolean
}

export const ActionReminder: React.FC<IActionReminderProps> = ({
  title,
  lastVisit,
  icon,
  bookText,
  completed = false,
  ...p
}) => {
  const appliedIcon = completed ? 'icon-correct' : icon
  const lastVisitTextClass = completed ? 'text-muted' : 'text-danger'
  return (
    <ActionReminderContainer
      className="d-flex align-items-baseline justify-content-between"
      {...p}
    >
      <div className="d-flex">
        <Icon
          data-testid="icon"
          className={`icon ${appliedIcon} fs-20`}
          completed={completed}
        />
        <div>
          <strong style={{ lineHeight: '1rem' }}>{title}</strong>
          <p
            style={{ lineHeight: '0.875rem' }}
            className={`${lastVisitTextClass} fs-14`}
          >
            {lastVisit}
          </p>
        </div>
      </div>
      <a href="/">{bookText}</a>
    </ActionReminderContainer>
  )
}

export default ActionReminder
