import { IUserInfoResponseDTO, IChangePasswordDTO } from '../../interfaces/auth'
import { ApiService } from './base'

export class AuthService extends ApiService {
  async login(username: string, password: string) {
    return this.request({
      method: 'post',
      url: '/auth',
      data: { auth_type: 'client_credentials' },
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      },
      credentials: false
    })
  }

  async getUserInfo(): Promise<IUserInfoResponseDTO> {
    return this.request({
      method: 'get',
      url: '/user-info',
      credentials: true
    })
  }

  async signWithCode(code: string) {
    return this.request({
      method: 'post',
      url: '/temp-auth',
      credentials: true,
      data: {
        code
      }
    })
  }

  async changePassword(data: IChangePasswordDTO) {
    const { detail, ...others } = await this.request({
      method: 'post',
      url: '/change-password',
      credentials: true,
      data
    })

    return {
      success: detail === 'Successfully updated new password.',
      detail,
      ...others
    }
  }

  async forgotPassword(data: { email: string }) {
    const { body, detail } = await this.request({
      method: 'post',
      url: '/forgot-password',
      credentials: false,
      data
    })

    return {
      success: body === '',
      detail
    }
  }

  async resetPassword(data: { new_password: string; code: string }) {
    const { detail, ...props } = await this.request({
      method: 'post',
      url: '/reset-password',
      credentials: false,
      data
    })

    return {
      success: detail === 'Successfully updated new password.',
      detail,
      ...props
    }
  }

  async logout() {
    return this.request({
      method: 'post',
      url: '/unauth',
      credentials: true
    })
  }
}
