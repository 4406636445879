import React from 'react'
import { EmployerPortalDependentProfile } from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { PageLayout } from '../../layouts/PageLayout'
import { employeeService, eightThreeFourService, plansService, summaryService } from '../../../services'

export const DependentProfile: React.FC<any> = () => {
  const { id, dependentId } = useParams<{ id: string; dependentId: string }>()

  const { data: dependent } = useQuery(['employee', dependentId], () =>
    employeeService.getEmployeeProfile(dependentId)
  )

  const { data: employee } = useQuery(['employee', id], () =>
    employeeService.getEmployeeProfile(id)
  )

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Employees', link: '/employees' },
        { title: employee?.name || 'Loading...', link: `/employees/${id}` },
        {
          title: dependent?.name || 'Loading...',
          link: `/employees/${id}/dependents/${dependentId}`
        }
      ]}
    >
      <EmployerPortalDependentProfile
        employeeService={employeeService}
        isBrokerPortal={false}
        eightThreeFourService={eightThreeFourService}
        plansService={plansService}
        summaryService={summaryService}
      />
    </PageLayout>
  )
}

export default DependentProfile
