import React from 'react'
import { Row } from 'react-bootstrap'
import { Front } from './Front/Front'
import { Back } from './Back/Back'

// data = member data
export const IDCard: React.FC<{
  data: any
  showFrontBackHeaders?: boolean
}> = ({ data, showFrontBackHeaders }) => {
  return (
    <div className="mb-5">
      <Row
        style={{ maxWidth: 750, margin: 'auto' }}
        className="d-flex align-items-stretch"
      >
        <Front data={data} showFrontBackHeaders={showFrontBackHeaders} />
        <Back data={data} showFrontBackHeaders={showFrontBackHeaders} />
      </Row>
    </div>
  )
}
