import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import { Icon } from './Icon'

export interface ISessionExpiredProps {
  title?: string
  description?: string
  buttonText?: string
  onClick?: () => void
  verticallyCentered?: boolean
}

export const SessionExpired: React.FC<ISessionExpiredProps> = ({
  title = 'Your session has expired',
  description = 'Please sign in again to continue where you left off.',
  buttonText = 'Sign In',
  onClick = () => {},
  verticallyCentered = false
}) => (
  <Modal
    centered={verticallyCentered}
    id="session-expired"
    show
    onHide={() => {}}
  >
    <Modal.Body className="text-center p-5">
      <Icon />
      <h5 className="mt-3">{title}</h5>
      <p className="mb-4">{description}</p>
      <Button variant="primary" onClick={onClick}>
        {buttonText}
      </Button>
    </Modal.Body>
  </Modal>
)
