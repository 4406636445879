export const languages = {
  english: 'English',
  french: 'French',
  spanish: 'Spanish',
  achinese: 'Achinese',
  acoli: 'Acoli',
  adangme: 'Adangme',
  afro_asiatic_other: 'Afro asiatic other',
  afrihili_artificial_language: 'Afrihili artificial language',
  afrikaans: 'Afrikaans',
  aljamia: 'Aljamia',
  akkadian: 'Akkadian',
  albanian: 'Albanian',
  aleut: 'Aleut',
  algonquian_languages: 'Algonquian languages',
  amharic: 'Amharic',
  english_old_ca_450_1100: 'English old ca 450 1100',
  apache_languages: 'Apache languages',
  arabic: 'Arabic',
  aramaic: 'Aramaic',
  armenian: 'Armenian',
  araucanian: 'Araucanian',
  arapaho: 'Arapaho',
  artificial_other: 'Artificial other',
  arawak: 'Arawak',
  assamese: 'Assamese',
  athapascan_languages: 'Athapascan languages',
  avaric: 'Avaric',
  avestan: 'Avestan',
  awadhi: 'Awadhi',
  aymara: 'Aymara',
  azerbaijani: 'Azerbaijani',
  banda: 'Banda',
  bamileke_languages: 'Bamileke languages',
  bashkir: 'Bashkir',
  baluchi: 'Baluchi',
  bambara: 'Bambara',
  balinese: 'Balinese',
  basque: 'Basque',
  basa: 'Basa',
  baltic_other: 'Baltic other',
  beja: 'Beja',
  byelorussian: 'Byelorussian',
  bemba: 'Bemba',
  bengali: 'Bengali',
  berber_languages: 'Berber languages',
  bhojpuri: 'Bhojpuri',
  bikol: 'Bikol',
  bini: 'Bini',
  siksika: 'Siksika',
  braj: 'Braj',
  breton: 'Breton',
  buginese: 'Buginese',
  bulgarian: 'Bulgarian',
  burmese: 'Burmese',
  caddo: 'Caddo',
  central_american_indian_other: 'Central american indian other',
  khmer: 'Khmer',
  carib: 'Carib',
  catalan: 'Catalan',
  caucasian_other: 'Caucasian other',
  cebuano: 'Cebuano',
  celtic_languages: 'Celtic languages',
  chamorro: 'Chamorro',
  chibcha: 'Chibcha',
  chechen: 'Chechen',
  chagatai: 'Chagatai',
  chinese: 'Chinese',
  chinook_jargon: 'Chinook jargon',
  choctaw: 'Choctaw',
  cherokee: 'Cherokee',
  church_slavic: 'Church slavic',
  chuvash: 'Chuvash',
  cheyenne: 'Cheyenne',
  coptic: 'Coptic',
  cornish: 'Cornish',
  creoles_and_pidgins_english_based_other:
    'Creoles and pidgins english based other',
  creoles_and_pidgins_french_based_other:
    'Creoles and pidgins french based other',
  creoles_and_pidgins_portuguese_based_other:
    'Creoles and pidgins portuguese based other',
  cree: 'Cree',
  creoles_and_pidgins_other: 'Creoles and pidgins other',
  cushitic_other: 'Cushitic other',
  czech: 'Czech',
  dakota: 'Dakota',
  danish: 'Danish',
  delaware: 'Delaware',
  dinka: 'Dinka',
  dogri: 'Dogri',
  dravidian_other: 'Dravidian other',
  duala: 'Duala',
  dutch_middle_ca_1050_1350: 'Dutch middle ca 1050 1350',
  dutch: 'Dutch',
  dyula: 'Dyula',
  efik: 'Efik',
  egyptian: 'Egyptian',
  ekajuk: 'Ekajuk',
  elamite: 'Elamite',
  english_middle_1100_1500: 'English middle 1100 1500',
  eskimo: 'Eskimo',
  esperanto: 'Esperanto',
  estonian: 'Estonian',
  ethiopic: 'Ethiopic',
  ewe: 'Ewe',
  ewondo: 'Ewondo',
  fang: 'Fang',
  faroese: 'Faroese',
  fanti: 'Fanti',
  fijian: 'Fijian',
  finnish: 'Finnish',
  finno_ugrian_other: 'Finno ugrian other',
  fon: 'Fon',
  friesian: 'Friesian',
  french_middle_ca_1400_1600: 'French middle ca 1400 1600',
  french_old_ca_842_1400: 'French old ca 842 1400',
  fula: 'Fula',
  g: 'G',
  gaelic_scots: 'Gaelic scots',
  gallegan: 'Gallegan',
  oromo: 'Oromo',
  gayo: 'Gayo',
  germanic_other: 'Germanic other',
  georgian: 'Georgian',
  german: 'German',
  gilbertese: 'Gilbertese',
  german_middle_high_ca_1050_1500: 'German middle high ca 1050 1500',
  german_old_high_ca_750_1050: 'German old high ca 750 1050',
  gondi: 'Gondi',
  gothic: 'Gothic',
  grebo: 'Grebo',
  greek_ancient_to_1453: 'Greek ancient to 1453',
  greek_modern_1453__: 'Greek modern 1453  ',
  guarani: 'Guarani',
  gujarati: 'Gujarati',
  haida: 'Haida',
  hausa: 'Hausa',
  hawaiian: 'Hawaiian',
  hebrew: 'Hebrew',
  herero: 'Herero',
  hiligaynon: 'Hiligaynon',
  himachali: 'Himachali',
  hindi: 'Hindi',
  hiri_motu: 'Hiri motu',
  hungarian: 'Hungarian',
  hupa: 'Hupa',
  iban: 'Iban',
  igbo: 'Igbo',
  icelandic: 'Icelandic',
  ijo: 'Ijo',
  iloko: 'Iloko',
  indic_other: 'Indic other',
  indonesian: 'Indonesian',
  indo_european_other: 'Indo european other',
  interlingua_international_auxiliary_language_association:
    'Interlingua international auxiliary language association',
  iranian_other: 'Iranian other',
  irish: 'Irish',
  iroquoian_languages: 'Iroquoian languages',
  italian: 'Italian',
  javanese: 'Javanese',
  japanese: 'Japanese',
  judeo_persian: 'Judeo persian',
  judeo_arabic: 'Judeo arabic',
  kara_kalpak: 'Kara kalpak',
  kabyle: 'Kabyle',
  kachin: 'Kachin',
  kamba: 'Kamba',
  kannada: 'Kannada',
  karen: 'Karen',
  kashmiri: 'Kashmiri',
  kanuri: 'Kanuri',
  kawi: 'Kawi',
  kazakh: 'Kazakh',
  khasi: 'Khasi',
  khoisan_other: 'Khoisan other',
  khotanese: 'Khotanese',
  kikuyu: 'Kikuyu',
  kinyarwanda: 'Kinyarwanda',
  kirghiz: 'Kirghiz',
  konkani: 'Konkani',
  kongo: 'Kongo',
  korean: 'Korean',
  kpelle: 'Kpelle',
  kru: 'Kru',
  kurukh: 'Kurukh',
  kuanyama: 'Kuanyama',
  kurdish: 'Kurdish',
  kusaie: 'Kusaie',
  kutenai: 'Kutenai',
  ladino: 'Ladino',
  lahnd: 'Lahnd',
  lamba: 'Lamba',
  langue_doc_post_1500: 'Langue doc post 1500',
  lao: 'Lao',
  lapp: 'Lapp',
  latin: 'Latin',
  latvian: 'Latvian',
  lingala: 'Lingala',
  lithuanian: 'Lithuanian',
  mongo: 'Mongo',
  lozi: 'Lozi',
  luba_katanga: 'Luba katanga',
  ganda: 'Ganda',
  luiseno: 'Luiseno',
  lunda: 'Lunda',
  luo_kenya_and_tanzania: 'Luo kenya and tanzania',
  macedonian: 'Macedonian',
  madurese: 'Madurese',
  magahi: 'Magahi',
  marshall: 'Marshall',
  maithili: 'Maithili',
  makasar: 'Makasar',
  malayalam: 'Malayalam',
  mandingo: 'Mandingo',
  maori: 'Maori',
  austronesian_other: 'Austronesian other',
  marathi: 'Marathi',
  masai: 'Masai',
  manx: 'Manx',
  malay: 'Malay',
  mende: 'Mende',
  micmac: 'Micmac',
  minangkabau: 'Minangkabau',
  miscellaneous_other: 'Miscellaneous other',
  mon_khmer_other: 'Mon khmer other',
  malagasy: 'Malagasy',
  maltese: 'Maltese',
  manipuri: 'Manipuri',
  manobo_languages: 'Manobo languages',
  mohawk: 'Mohawk',
  moldavian: 'Moldavian',
  mongolian: 'Mongolian',
  mossi: 'Mossi',
  multiple_languages: 'Multiple languages',
  munda_other: 'Munda other',
  creek: 'Creek',
  marwari: 'Marwari',
  mayan_languages: 'Mayan languages',
  aztec: 'Aztec',
  north_american_indian_other: 'North american indian other',
  navajo: 'Navajo',
  ndebele_zimbabwe: 'Ndebele zimbabwe',
  ndonga: 'Ndonga',
  nepali: 'Nepali',
  newari: 'Newari',
  niger_kordofanian_other: 'Niger kordofanian other',
  niuean: 'Niuean',
  norwegian: 'Norwegian',
  northern_sotho: 'Northern sotho',
  nubian_languages: 'Nubian languages',
  nyanja: 'Nyanja',
  nyamwezi: 'Nyamwezi',
  nyankole: 'Nyankole',
  nyoro: 'Nyoro',
  nzima: 'Nzima',
  ojibwa: 'Ojibwa',
  oriya: 'Oriya',
  osage: 'Osage',
  ossetic: 'Ossetic',
  turkish_ottoman: 'Turkish ottoman',
  otomian_languages: 'Otomian languages',
  papuan_australian_other: 'Papuan australian other',
  pangasinan: 'Pangasinan',
  pahlavi: 'Pahlavi',
  pampanga: 'Pampanga',
  panjabi: 'Panjabi',
  papiamento: 'Papiamento',
  palauan: 'Palauan',
  old_persian_ca_600_400_bc: 'Old persian ca 600 400 bc',
  persian: 'Persian',
  pali: 'Pali',
  polish: 'Polish',
  ponape: 'Ponape',
  portuguese: 'Portuguese',
  prakrit_languages: 'Prakrit languages',
  provencal_old_to_1500: 'Provencal old to 1500',
  pushto: 'Pushto',
  quechua: 'Quechua',
  rajasthani: 'Rajasthani',
  rarotongan: 'Rarotongan',
  romance_other: 'Romance other',
  raeto_romance: 'Raeto romance',
  romany: 'Romany',
  romanian: 'Romanian',
  rundi: 'Rundi',
  russian: 'Russian',
  sandawe: 'Sandawe',
  sango: 'Sango',
  south_american_indian_other: 'South american indian other',
  salishan_languages: 'Salishan languages',
  samaritan_aramaic: 'Samaritan aramaic',
  sanskrit: 'Sanskrit',
  samoan: 'Samoan',
  serbo_croatian_cyrillic: 'Serbo croatian cyrillic',
  scots: 'Scots',
  serbo_croatian_roman: 'Serbo croatian roman',
  selkup: 'Selkup',
  semitic_other: 'Semitic other',
  shan: 'Shan',
  shona: 'Shona',
  sidamo: 'Sidamo',
  siouan_languages: 'Siouan languages',
  sino_tibetan_other: 'Sino tibetan other',
  slavic_other: 'Slavic other',
  slovak: 'Slovak',
  slovenian: 'Slovenian',
  sindhi: 'Sindhi',
  sinhalese: 'Sinhalese',
  somali: 'Somali',
  songhai: 'Songhai',
  serer: 'Serer',
  sotho: 'Sotho',
  sukuma: 'Sukuma',
  sundanese: 'Sundanese',
  susu: 'Susu',
  sumerian: 'Sumerian',
  swahili: 'Swahili',
  swazi: 'Swazi',
  syriac: 'Syriac',
  tagalog: 'Tagalog',
  tahitian: 'Tahitian',
  tajik: 'Tajik',
  tamil: 'Tamil',
  tatar: 'Tatar',
  telugu: 'Telugu',
  timne: 'Timne',
  tereno: 'Tereno',
  thai: 'Thai',
  tibetan: 'Tibetan',
  tigre: 'Tigre',
  tigrinya: 'Tigrinya',
  tivi: 'Tivi',
  tlingit: 'Tlingit',
  tonga_nyasa: 'Tonga nyasa',
  tonga_tonga_islands: 'Tonga tonga islands',
  truk: 'Truk',
  tsimshian: 'Tsimshian',
  tsonga: 'Tsonga',
  tswana: 'Tswana',
  turkmen: 'Turkmen',
  tumbuka: 'Tumbuka',
  turkish: 'Turkish',
  altaic_other: 'Altaic other',
  twi: 'Twi',
  ugaritic: 'Ugaritic',
  uighur: 'Uighur',
  ukrainian: 'Ukrainian',
  umbundu: 'Umbundu',
  undetermined: 'Undetermined',
  urdu: 'Urdu',
  uzbek: 'Uzbek',
  vai: 'Vai',
  venda: 'Venda',
  vietnamese: 'Vietnamese',
  votic: 'Votic',
  wakashan_languages: 'Wakashan languages',
  walamo: 'Walamo',
  waray: 'Waray',
  washo: 'Washo',
  welsh: 'Welsh',
  sorbian_languages: 'Sorbian languages',
  wolof: 'Wolof',
  xhosa: 'Xhosa',
  yao: 'Yao',
  yap: 'Yap',
  yiddish: 'Yiddish',
  yoruba: 'Yoruba',
  zapotec: 'Zapotec',
  zenaga: 'Zenaga',
  zulu: 'Zulu',
  zuni: 'Zuni'
}
