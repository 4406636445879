import React, { SetStateAction, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { groupService } from '../../../services'
import { businessOnboardingPaths } from '../router/paths'

interface IBusinessOnboardingContextValue {
  state: number
  setState: React.Dispatch<SetStateAction<number>>
  modifyGroup: any
}

export const BusinessOnboardingContext =
  React.createContext<IBusinessOnboardingContextValue>({
    state: 1,
    setState: () => {},
    modifyGroup: () => {}
  })

const {
  businessInformation,
  adminInformation,
  employerPreferences,
  contributionStrategy,
  plan,
  carrierInformation,
  billing,
  producer
} = businessOnboardingPaths

const routes = [
  businessInformation,
  adminInformation,
  employerPreferences,
  contributionStrategy,
  plan,
  carrierInformation,
  billing,
  producer
]

export const BusinessOnboardingContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(1)
  const history = useHistory()

  const modifyGroup = useMutation((data: any) => groupService.modifyGroup(data))

  const _setState = (s: SetStateAction<number>) => {
    // This is because we're using setState for each step individually
    // hence, we gotta check which state (step/route) the app should be

    if (state !== s) history.push(routes[Number(s) - 1])
    return setState(s)
  }

  return (
    <BusinessOnboardingContext.Provider
      value={{ state, setState: _setState, modifyGroup }}
    >
      {children}
    </BusinessOnboardingContext.Provider>
  )
}
