import React, { useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'

import { FilterContainer } from '../FilterContainer/FilterContainer'

const FilterOption = styled.div`
  display: flex;
  align-items: baseline;
`
export interface ICheckboxFilterOption {
  value: boolean
  label: string
}
export interface ICheckboxFilterProps {
  name: string
  onChange: Function
  value: {
    [x: string]: ICheckboxFilterOption
  }
  [x: string]: any
}

export const CheckboxFilter: React.FC<ICheckboxFilterProps> = ({
  name,
  value,
  onChange,
  ...props
}) => {
  const [internalFilters, setInternalFilters] = useState({ ...value })
  const [showDropdown, setShowDropdown] = useState(false)

  const onSelectFilter = useCallback((key, value) => {
    const newFilters = {
      ...internalFilters,
      [key]: {
        ...internalFilters[key],
        value
      }
    }

    setInternalFilters(newFilters)
  }, [internalFilters])

  const handleApply = useCallback(() => {
    onChange(internalFilters)
    setShowDropdown(false)
  }, [internalFilters, onChange])

  const handleClear = useCallback(() => {
    const filterKeys = Object.keys(internalFilters)
    const cleared = {}

    filterKeys.forEach((key) => {
      cleared[key] = {
        ...internalFilters[key],
        value: false
      }
    })

    setInternalFilters(cleared)
  }, [internalFilters])

  const handleToggle = () => {
    setInternalFilters({ ...value })
    setShowDropdown(!showDropdown)
    // Sync state with parent (useful when the dropdown is closed without applying the changes)
  }

  const appliedFilters = useMemo(() => Object.keys(value).some(
    (key: string) => value[key].value
  ), [value])

  return (
    <FilterContainer
      name={name}
      apply={handleApply}
      onToggle={handleToggle}
      showDropdown={showDropdown}
      clear={handleClear}
      appliedFilters={appliedFilters}
      {...props}
    >
      {Object.keys(internalFilters).map((o) => (
        <FilterOption key={o}>
          <input
            checked={internalFilters[o].value}
            onChange={(e) => onSelectFilter(o, e.target.checked)}
            id={o}
            type="checkbox"
            style={{ marginRight: '7px' }}
          />
          <label htmlFor={o}>{internalFilters[o].label}</label>
        </FilterOption>
      ))}
    </FilterContainer>
  )
}

export default CheckboxFilter
