import { ILoginHanlder } from '@angle/types'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'

export interface ILoginFormData {
  email: string
  password: string
  remember: boolean
}

interface ILoginHandlers {
  onSuccess: (props: { result: any }) => any
  onError: (error: Error) => any
}

const _defaultValues = {
  email: '',
  password: '',
  remember: false
}

export function useLoginFormHandler(
  onHandlers: ILoginHandlers,
  authContext: ILoginHanlder,
  defaultValues: ILoginFormData = _defaultValues
) {
  const { onError, onSuccess } = useMemo(() => onHandlers, [onHandlers])

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    register,
    watch,
    setValue,
    ...others
  } = useForm<ILoginFormData>({
    mode: 'onChange',
    defaultValues
  })

  const onSubmit = handleSubmit(async (data: ILoginFormData) => {
    // Call login endpoint
    const { email, password } = data
    try {
      const result = await authContext.login(email, password)
      onSuccess({ result })
    } catch (e) {
      onError(e)
    }
  })

  const isValid = Boolean(!formState.isDirty || !formState.isValid)

  return {
    register,
    onSubmit,
    isValid,
    control,
    formState,
    getValues,
    watch,
    setValue,
    ...others
  }
}

export default useLoginFormHandler
