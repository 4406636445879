import { MailingAddress, ContactInfo, CurrentCarrier } from '../group'
import { Name } from '../shared'

export const EmployeeStatusMap = {
    pending: {
      text: 'Not Started',
      variant: 'danger'
    },
    pending_email: {
      text: 'Not Started',
      variant: 'danger'
    },
    in_progress: {
      text: 'In Progress',
      variant: 'warning'
    },
    under_review: {
      text: 'In Progress',
      variant: 'warning'
    },
    completed: {
      text: 'Success',
      variant: 'success'
    }
  }



  export interface EmployeeProfile {
    address_object: {
      street_1: string,
      street_2: string,
      city: string,
      state: string,
      zip: string,
    }
    allCoverages: {
      plan: { plan_name: string; metal_tier: string }
      effective_date: string
      term_date: string
      manually_terminated?: boolean
      monthly_premium_employee: string
      monthly_premium_employer: string
    }[]
    coverages: {
      plan: { plan_name: string; metal_tier: string }
      effective_date: string
      term_date?: string
      manually_terminated?: boolean
      monthly_premium_employee: string
      monthly_premium_employer: string
    }[]
    aca_status: string
    access_plus_status: string
    address: string
    age: number |  null
    companyPays: string
    coverageEnd: string
    coverageStart: string
    covers: string
    dependents: any[]
    dob: string
    email: string
    employeePays: string
    empty: boolean
    familyId: string
    gender: string
    group_id: string
    homeAddress: string
    id: string
    identified_gender: string
    medicareEligible: string
    metalTier?: string
    name: string
    otherInsurance: string | null
    plan: string
    planType: string
    premium: string
    profilePic: string
    relationship: string
    ssn: string
    subgroup: string
    terminated: boolean
    terminationDate: string
  }
  
  export interface EmployeeProfileForm {
    firstName: string
    lastName: string
    name?: string
    dateOfBirth: string
    email: string
    streetAddressLine1: string
    streetAddressLine2: string
    city: string
    state: string
    zip: string
    medicareEligible: boolean
    otherInsurance: boolean
  }

  
  export interface AddEmployee {
    member_type: string
    name: {
      first_name: string
      last_name: string
    }
    contact_info: {
      work_email: string
    }
    addition_type: string
    addition_date: string
    addition_reason?: string
  }
  
  export interface ModifyEmployee {
    detail: string
  }
  

  
  export interface HomeAddress {
    address_type?: any
    primary?: any
    street_1: string
    street_2: string
    city: string
    county: string
    state: string
    zip: string
    country: string
    alternate_start?: any
    alternate_end?: any
  }


  
  export interface Family {
    employment_status?: any
    family_id: string
    hire_date?: any
    termination_date?: any
    hours_worked?: any
    occupation: string
    subgroup: string
  }
  

  

  export interface EmployeeDTO {
    aca_status: string
    access_plus_status: string
    citizenship?: any
    contact_info: ContactInfo
    coverage_waived?: boolean
    coverages: {
      plan: { plan_name: string; metal_tier: string }
      effective_date: string
      term_date: string
      manually_terminated?: boolean
      monthly_premium_employee: string
      monthly_premium_employer: string
    }[]
    past_medical_coverages: {
      plan: { plan_name: string; metal_tier: string }
      effective_date: string
      term_date: string
      manually_terminated?: boolean
      monthly_premium_employee: string
      monthly_premium_employer: string
    }[]
    covered_by_workers_comp: boolean
    created_on_date: string
    current_carrier: CurrentCarrier
    current_medical_coverage?: any
    date_of_birth: string
    death_date?: any
    dental_eligible?: any
    dependents: any[]
    ethnicity?: any
    expiration_date?: any
    family: Family
    gender: string
    group_id: string
    handicap?: any
    home_address: HomeAddress
    identified_gender?: any
    in_progress_state: number
    is_active: boolean
    language?: any
    life_coverage_eligible?: any
    mailing_address: MailingAddress
    marital_status?: any
    medicare_eligible?: any
    medicare?: any
    member_id: string
    member_type: string
    multiple_birth?: any
    name: Name
    on_cobra_scb: boolean
    onboarding_status: string
    other_health_coverage_eligible?: any
    other_insurance?: any
    parent_id?: any
    relationship: string
    sequence_id: string
    ssn: string
    statement_of_understanding?: any
    vision_eligible?: any
  }
  
  export interface MembersResponse {
    count: number
    members: EmployeeDTO[]
  }

  export interface EmployeeStatus {
    text: string
    variant: string
  }
  
  export interface Employee {
    planVariant: string
    name: string
    id: string
    employeeType: string
    startDate: string
    status: EmployeeStatus
    profilePicUrl: string
    planName: string
    metalTier?: string
    endDate?: string
    disenrolled?: boolean
    waivedCoverage?: boolean
  }