import React, { useRef } from 'react'
import {
  Button,
  Form,
  Logo,
  useAuthUserInfo,
  useAuth,
  useToaster
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { Paths } from '../../../../routes/paths'
import { ValidationError } from '../style'
import { FormWrapper } from './style'
import { authService } from '../../../../services/index'

interface SignUpProps {
  location: {
    state?: {
      oldPassword: string
    }
  }
}

export const SignUp: React.FC<SignUpProps> = (props: SignUpProps) => {
  const history = useHistory()
  const toaster = useToaster()
  const { dispatch } = useAuth()
  const { data: user } = useAuthUserInfo(authService)
  const oldPassword = props.location?.state?.oldPassword

  const { handleSubmit, control, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
      rePassword: '',
      oldPassword: ''
    }
  })

  const { errors } = formState

  const password = useRef({})
  password.current = watch('password', '')

  const handleContinue = handleSubmit(async (data) => {
    // Call the change password route
    try {
      await authService.changePassword({
        old_password: oldPassword ?? data.oldPassword,
        new_password: data.password
      })
      dispatch({
        type: 'SET_USER_ACCOUNT',
        user: {
          ...user,
          temporary: false
        },
        fetching: false
      })
      history.push(Paths.newBusinessOnboarding)
    } catch (e: any) {
      toaster.show({
        message: e.message,
        type: 'danger'
      })
    }
  })

  const missingInputData =
    errors.rePassword?.type === 'required' ||
    errors.password?.type === 'required'

  return (
    <div className="vh-100 vw-100 d-flex flex-column align-items-center pt-10">
      <div className="d-flex align-items-top mb-5">
        <Logo variant="simple" size="lg" color="primary" />
        <p className="fs-32">Business</p>
      </div>
      <div>
        <p className="fs-32">
          <span className="d-block text-center">
            Please choose a new password for your
          </span>
          <span className="d-block text-center mb-10">
            Angle Business account
          </span>
        </p>
      </div>
      <FormWrapper>
        <Form>
          {!oldPassword && (
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': formState.errors?.oldPassword
                })}
              >
                Old Password
              </Form.Label>
              <Controller
                name="oldPassword"
                rules={{
                  required: true
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="password"
                    autoFocus
                    placeholder="Old password"
                    className="bg-white"
                    isInvalid={!!formState.errors?.oldPassword}
                  />
                )}
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Controller
              name="password"
              rules={{ required: true, minLength: 8 }}
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="password"
                  placeholder="Create your password"
                  className="bg-white"
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Controller
              name="rePassword"
              rules={{
                required: true,
                validate: (value: string) => value === password.current
              }}
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="password"
                  placeholder="Re-enter your password"
                  className="bg-white"
                />
              )}
            />
          </Form.Group>
          {formState.isSubmitted && missingInputData && (
            <ValidationError>All inputs must be filled</ValidationError>
          )}
          {formState.isSubmitted && errors.password?.type === 'minLength' && (
            <ValidationError>
              Password length must be at least 8 characters
            </ValidationError>
          )}
          {formState.isSubmitted && errors.rePassword?.type === 'validate' && (
            <ValidationError>Passwords do not match</ValidationError>
          )}
        </Form>
        <Button
          variant="primary"
          disabled={formState.isSubmitting}
          className="w-100"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </FormWrapper>
    </div>
  )
}
