import React, { useEffect, useState } from 'react'
import {
  Col,
  DatePicker,
  Form,
  PageContainer,
  PageHeaderLight,
  qleList,
  Row,
  useToaster,
  EmployeeService,
  Button
} from '../../../index'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { DateHandler, unformatPhoneNumber } from '@angle/utils'
import ReactInputMask from 'react-input-mask'
import styled from 'styled-components'

const ErrorSpan = styled.span`
  display: inline-block;
  margin-top: 5px;
  color: var(--danger);
`

export const EmployerPortalEmployeeRegistration: React.FC<{
  isBrokerPortal?: boolean
  employeeService: EmployeeService
}> = ({ isBrokerPortal, employeeService }) => {
  const { groupId } = useParams<{ groupId: string }>()

  const { register, control, watch, unregister, handleSubmit, formState } =
    useForm({
      defaultValues: {
        firstName: '',
        lastName: '',
        dob: '',
        ssn: '',
        email: '',
        reason: 'new_hire',
        doh: '',
        dateofQle: '',
        reasonofQle: ''
      }
    })

  const toast = useToaster()
  const [isSaving, setIsSaving] = useState(false)
  const reason = watch('reason')
  const reasonofQle = watch('reasonofQle')
  const errors = formState.errors
  const history = useHistory()

  useEffect(() => {
    if (reason === 'new_hire') {
      unregister('dateofQle')
      unregister('reasonofQle')
    } else {
      unregister('doh')
    }
  }, [reason, unregister])

  const onChangesSuccess = () => {
    setIsSaving(false)
    toast.show({
      message: 'Your employee has been created',
      icon: 'icon-correct',
      type: 'success'
    })

    const employeesPagePath = isBrokerPortal
      ? `/groups/${groupId}/employees`
      : '/employees'
    history.push(employeesPagePath)
  }

  const onChangesFail = (error: any) => {
    setIsSaving(false)
    toast.show({
      message: `Something went wrong: ${error.message}`,
      icon: 'icon-cancel',
      type: 'danger'
    })
  }

  const handleAddEmployee = handleSubmit((data) => {
    const requestBody = {
      member_type: 'employee',
      name: {
        first_name: data.firstName,
        last_name: data.lastName
      },
      contact_info: {
        work_email: data.email
      },
      date_of_birth: DateHandler.formatForServer(data.dob),
      ssn: unformatPhoneNumber(data.ssn),
      addition_type: data.reason,
      addition_date: DateHandler.formatForServer(
        data.reason === 'qle' ? data.dateofQle : data.doh
      ),
      addition_reason: data.reason === 'qle' ? data.reasonofQle : ''
    }
    setIsSaving(true)
    employeeService
      .addEmployee(requestBody, groupId)
      .then(onChangesSuccess)
      .catch(onChangesFail)
  })

  const dateError = (
    <span>
      Date must be within 30 days of today. Please contact
      <a href="mailto:onboarding@anglehealth.com">
        {' '}
        onboarding@anglehealth.com{' '}
      </a>{' '}
      to add the employee.
    </span>
  )

  const validatePriorDate = (date: string) => {
    const twoWeeksBefore = new Date(Date.now() - 2.592e9)
    const dateObj = new Date(date)
    return !(dateObj < twoWeeksBefore)
  }

  const validateFutureDate = (date: string) => {
    const twoWeeksLater = new Date(Date.now() + 2.592e9)
    const dateObj = new Date(date)
    return !(dateObj > twoWeeksLater)
  }

  return (
    <>
      <PageHeaderLight>
        <h1>Add Employee</h1>
      </PageHeaderLight>
      <PageContainer size="md">
        <b>Personal Information</b>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                className="bg-white"
                isInvalid={Boolean(errors?.firstName)}
                {...register('firstName', {
                  required: 'This field is required'
                })}
              />
              <ErrorSpan>{errors?.firstName?.message}</ErrorSpan>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                className="bg-white"
                isInvalid={Boolean(errors?.lastName)}
                {...register('lastName', {
                  required: 'This field is required'
                })}
              />
              <ErrorSpan>{errors?.lastName?.message}</ErrorSpan>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Date of Birth</Form.Label>
              <Controller
                key="dob"
                name="dob"
                control={control}
                rules={{
                  required: 'This field is required'
                }}
                render={({ field }) => (
                  <DatePicker backgroundColor="white" {...field} />
                )}
              />
              <ErrorSpan>{errors?.dob?.message}</ErrorSpan>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Social Security Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Social Security Number"
                className="bg-white"
                isInvalid={Boolean(errors?.ssn)}
                as={ReactInputMask}
                mask="999-99-9999"
                {...register('ssn', {
                  required: 'This field is required',
                  validate: {
                    invalidSsn: (val) => {
                      if (val === '000-00-0000' || val === '111-11-1111') {
                        return 'Please enter a valid social security number'
                      } else return true
                    }
                  }
                })}
              />
              <ErrorSpan>{errors?.ssn?.message}</ErrorSpan>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                className="bg-white"
                isInvalid={Boolean(errors?.email)}
                {...register('email', { required: 'This field is required' })}
              />
              <ErrorSpan>{errors?.email?.message}</ErrorSpan>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Reason for adding a new Employee</Form.Label>
              <Form.Check
                type="radio"
                value="qle"
                label="Qualifying Life Event"
                {...register('reason')}
              />
              <Form.Check
                type="radio"
                value="new_hire"
                label="New Hire"
                {...register('reason')}
              />
            </Form.Group>
          </Col>
        </Row>
        {reason === 'new_hire' ? (
          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Date of Hire</Form.Label>
                <Controller
                  key="doh"
                  name="doh"
                  control={control}
                  rules={{
                    required: 'This field is required'
                  }}
                  render={({ field }) => (
                    <DatePicker backgroundColor="white" {...field} />
                  )}
                />
                <ErrorSpan>{errors?.doh?.message}</ErrorSpan>
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Date of QLE</Form.Label>
                <Controller
                  key="dateofQle"
                  name="dateofQle"
                  control={control}
                  rules={{
                    required: 'This field is required',
                    validate: {
                      validateFutureDate: (date) => validateFutureDate(date),
                      validatePriorDate: (date) => validatePriorDate(date)
                    }
                  }}
                  render={({ field }) => (
                    <DatePicker backgroundColor="white" {...field} />
                  )}
                />
                <ErrorSpan>{errors?.dateofQle?.message}</ErrorSpan>
                <div className="pt-2 d-inline-block">
                  {errors?.dateofQle?.type === 'validateFutureDate' &&
                    dateError}
                  {errors?.dateofQle?.type === 'validatePriorDate' && dateError}
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Reason of the QLE</Form.Label>
                <Controller
                  name="reasonofQle"
                  control={control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      defaultValue=""
                      onChange={(e) => field.onChange(e.target.value)}
                      as="select"
                      className={`bg-white ${!reasonofQle ? 'text-muted' : ''}`}
                      isInvalid={Boolean(errors?.reasonofQle)}
                    >
                      <option value="" disabled hidden>
                        Reason
                      </option>
                      {qleList.map((qle: any) => (
                        <option value={qle.value} key={qle.value}>
                          {qle.label}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                />
                <ErrorSpan className="text-danger">
                  {errors?.reasonofQle?.message}
                </ErrorSpan>
              </Form.Group>
            </Col>
          </Row>
        )}
        <hr />
        <Row>
          <Col xs={12} md={6} className="pb-5">
            <Button
              className="float-left text-primary"
              variant="outline-primary"
              onClick={() => history.push('/employees')}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <Button
              className="float-right"
              disabled={isSaving}
              onClick={handleAddEmployee}
            >
              Add Employee
            </Button>
          </Col>
        </Row>
      </PageContainer>
    </>
  )
}
