import React from 'react'
import { Col, Row } from 'react-bootstrap'

export const FirstHealthFooter = () => {
  return (
    <Row>
      <Col>
        <b className="fs-10">
          <span className="text-primary">anglehealth</span>
          .com/app
        </b>
      </Col>
      <Col className="text-right">
        <img style={{ width: 100 }} src="/fhn.png" alt="First Health Network" />
      </Col>
    </Row>
  )
}
