import {
    IMemberInfo,
    IDependentDTO
  } from '../../interfaces'
  import { ApiService } from './base'
  
  import { type Benefit, benefitsMap } from '../../utils/benefitsMap'

  export interface MemberContextData {
    memberId: string
    firstName: string
    lastName: string
  }

  export const mapDataToAvailableBenefitTypes = (data: any) => {
    // const availablePlanTypes = []
    const availableBenefitTypes: { [key: string]: Benefit } = {}

    for (const key in data.plan_type_availabilities) {
      if (key === 'medical') {
        continue
      }

      const displayName = benefitsMap[key]?.displayName

      if (
        data.plan_type_availabilities[key] === true &&
        displayName !== undefined
      ) {
        availableBenefitTypes[key] = {
          displayName,
          key,
          apiKey: benefitsMap[key].apiKey || benefitsMap[key].key
        }
      }
    }
    return {
      serverStep: data?.in_progress_state,
      inRenewal: data?.in_renewal,
      availableBenefitTypes
    }
  }

  export class MemberService extends ApiService {
    async getMemberInfo(mapper?: Function): Promise<IMemberInfo> {
      return this.request({
        method: 'get',
        url: '/member',
        credentials: true
      }).then((r: IMemberInfo) => (mapper ? mapper(r) : r))
    }

    async getAvailableBenefitTypes(): Promise<any> {
      return this.request({
        method: 'get',
        url: '/member'
      }).then((r: any) => mapDataToAvailableBenefitTypes(r))
    }

    async requestIdCard(memberId: string, groupId?: string): Promise<any>{
      const url = `/members/${memberId}/request-new-id-card`
      return this.request({
        method: 'post',
        url: url,
        credentials: true
      }).then((r) => r)
    }

    async getMemberInfoForContext(): Promise<MemberContextData> {
      return this.request({
        method: 'get',
        url: '/member'
      }).then((r) => ({
        memberId: r.member_id,
        firstName: r.name?.first_name,
        lastName: r.name?.last_name
      }))
    }

    async updateMemberInfo(data: Partial<IMemberInfo>): Promise<any> {
      const response = await this.request({
        method: 'patch',
        url: '/member',
        credentials: true,
        data
      })
      return { success: response.detail === 'Success.', ...response }
    }

    async waiveCoverage(data: any): Promise<any> {
      return this.request({
        method: 'patch',
        url: '/member',
        credentials: true,
        data: { coverage_waived: true, ...data }
      })
    }

    getDependentById(id: string) {
      return this.request({
        method: 'get',
        url: '/dependents/' + id,
        credentials: true
      })
    }

    async getDependents(mapper?: Function): Promise<any> {
      return this.request({
        method: 'get',
        url: '/dependents',
        credentials: true
      }).then((r: any) => (mapper ? mapper(r) : r))
    }

    addDependent(data: Partial<IDependentDTO>) {
      return this.request({
        method: 'post',
        url: '/dependents',
        credentials: true,
        data
      })
    }

    updateDependent(id: string, data: Partial<IDependentDTO>) {
      return this.request({
        method: 'patch',
        url: `/dependents/${id}`,
        credentials: true,
        data
      })
    }

    deleteDependent(id: string) {
      return this.request({
        method: 'delete',
        url: `/dependents/${id}`,
        credentials: true
      })
    }
  }
