export const ethnicity = {
  not_provided: 'Not provided',
  not_applicable: 'Not applicable',
  asian_pacific_islander: 'Asian pacific islander',
  black: 'Black',
  caucasian: 'Caucasian',
  subcontinent_asian_american: 'Subcontinent asian american',
  other: 'Other',
  asian_pacific_american: 'Asian pacific american',
  native_american: 'Native american',
  hispanic: 'Hispanic',
  american_indian_alaskan_native: 'American indian alaskan native',
  native_hawaiian: 'Native hawaiian',
  black_non_hispanic: 'Black non hispanic',
  white_non_hispanic: 'White non hispanic',
  pacific_islander: 'Pacific islander',
  mutually_defined: 'Mutually defined'
}
