import React from 'react'
import {
  PageHeaderLight,
  PageContainer,
  Row
} from 'react-angle-dashboard-components'
import { PageLayout } from '../layouts/PageLayout'
import { BusinessOnboardingContextProvider } from './context/BusinessOnboardingContext'
import { BusinessOnboardingRouter } from './router/BusinessOnboardingRouter'
import './form-control.scss'

interface BusinessOnboardingProps {}

const Component = (props: BusinessOnboardingProps) => {
  return (
    <PageLayout>
      <div className="business-onboarding">
        <PageHeaderLight
          className="align-items-start"
          style={{ height: '128px', padding: 0 }}
        >
          <PageContainer size="xl">
            <h1>Business Onboarding</h1>
          </PageContainer>
        </PageHeaderLight>
        <PageContainer size="xl">
          <Row className="pb-5">
            <BusinessOnboardingRouter />
          </Row>
        </PageContainer>
      </div>
    </PageLayout>
  )
}

const BusinessOnboarding = () => (
  <BusinessOnboardingContextProvider>
    <Component />
  </BusinessOnboardingContextProvider>
)

export default BusinessOnboarding
