import React, { MouseEventHandler, ReactNode } from 'react'
import {
  Form,
  FormControl,
  InputGroup,
  FormControlProps
} from 'react-bootstrap'
import styled from 'styled-components'

const InputGroupContainer = styled(InputGroup)`
  border-radius: 0.3125rem;
`

type NoEventsInputProps = Omit<
  React.HTMLAttributes<HTMLInputElement>,
  'onChange' | 'onKeyUp'
>

export interface IconInputProps extends FormControlProps, NoEventsInputProps {
  label?: string
  icon?: string
  appendIcon?: string
  appendNode?: ReactNode
  containerClassName?: string
  inlineClassName?: string
  placeholder?: string
  iconClassName?: string
  style?: React.CSSProperties
  barStyle?: React.CSSProperties
  barClassName?: string
  inlineStyle?: React.CSSProperties
  onAppendIconClick?: MouseEventHandler<HTMLSpanElement>
  onAppendNodeClick?: MouseEventHandler<HTMLSpanElement>
  noShadow?: boolean
  onKeyUp?: Function
  ref?: any
}

export const IconInput: React.FC<IconInputProps> = React.forwardRef(
  (
    {
      label = null,
      id,
      icon,
      appendIcon,
      onAppendIconClick,
      onAppendNodeClick,
      placeholder = '',
      className = '',
      containerClassName = '',
      inlineClassName = '',
      barClassName = '',
      iconClassName = 'text-muted',
      barStyle = {},
      inlineStyle = {},
      noShadow = false,
      appendNode,
      ...othersInputProps
    },
    ref
  ) => {
    return (
      <span className={inlineClassName} style={inlineStyle}>
        {label && (
          <Form.Label htmlFor={id} srOnly>
            {label}
          </Form.Label>
        )}
        <InputGroupContainer
          className={`mr-sm-2 ${noShadow ? '' : 'shadow-sm'} ${
            containerClassName || 'mb-2'
          }`}
        >
          {icon && (
            <InputGroup.Prepend className="mr-0">
              <InputGroup.Text
                style={barStyle}
                className={`pr-1 bg-white border border-light border-right-0 ${barClassName}`}
              >
                <i
                  className={`icon ${icon} ${iconClassName}`}
                  style={{ width: 'auto', height: 'auto' }}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <FormControl
            ref={ref}
            className={`bg-white border border-light ${
              appendIcon || appendIcon ? 'border-right-0' : 'rounded-right'
            } border-left-0 fs-18 ${className} ${barClassName}`}
            id={id}
            placeholder={placeholder}
            style={barStyle}
            {...othersInputProps}
          />
          {appendIcon && (
            <InputGroup.Append role="button" onClick={onAppendIconClick}>
              <InputGroup.Text
                className={`pl-1 bg-white border border-light border-left-0 ${barClassName}`}
                style={barStyle}
              >
                <i
                  className={`icon ${appendIcon} text-muted`}
                  style={{ width: 'auto', height: 'auto' }}
                />
              </InputGroup.Text>
            </InputGroup.Append>
          )}

          {appendIcon && (
            <InputGroup.Append role="button" onClick={onAppendNodeClick}>
              <InputGroup.Text
                className={`pl-1 bg-white border border-light border-left-0 ${barClassName}`}
                style={barStyle}
              >
                {appendNode}
              </InputGroup.Text>
            </InputGroup.Append>
          )}
        </InputGroupContainer>
      </span>
    )
  }
)

export default IconInput
