export enum ColumnDataType {
  string = 'string',
  integer = 'integer',
  float = 'float',
  money = 'money',
  oneBasedPercentage = 'oneBasedPercentage',
  hundredBasedPercentage = 'hundredBasedPercentage',
  boolean = 'boolean',
  isoDate = 'isoDate',
  isoDateTime = 'isoDateTime'
}

export interface TableColumn {
  columnKey: string
  columnHeader: string
  dataType: ColumnDataType
  displayLocations?: string[]
}

export interface TableRow {
  [key: string]: string
}

export interface TableSummary {
  [key: string]: string
}

export interface ExtraInfo {
  fields: {
    fieldKey: string
    fieldDescription: string
    dataType: ColumnDataType
  }[]
  data: { [key: string]: string }
}

export type ReportData = any;
export type BulkReportData = any;
