import styled from 'styled-components'

export const SectionTitle = styled.strong`
  margin-bottom: 1.625rem;
  display: block;
`

export const ListItem = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--card-border);
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`
