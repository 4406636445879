// get first coverage that is not null/empty with prority: current, future, past

export const getCoverage = (memberData: any) => {
  const currentCoverage = memberData?.current_medical_coverage
  const futureCoverages = memberData?.future_medical_coverages
  const pastCoverages = memberData?.past_medical_coverages

  if (currentCoverage) return currentCoverage
  else if (futureCoverages && futureCoverages?.length) return futureCoverages[0]
  else if (pastCoverages && pastCoverages?.length) return pastCoverages[0]

  return null
}
