import { useState } from 'react'
import { useToaster } from 'react-angle-dashboard-components'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { disclosuresService } from '../../../../services'
import { IDisclosureData } from '../../../../interfaces/disclosures'

const emptyFormValues: IDisclosureData = {
  risk_identifier_name: '',
  date_of_birth: '',
  sex: '',
  member_type: '',
  status: '',
  term_date: '',
  diagnosis: '',
  latest_service_date: '',
  plan_year_expenses: undefined
}

export const useDisclosureAddOrEdit = (refetch: any) => {
  const { showSuccess, showError } = useToaster()

  const [showModal, setShowModal] = useState(false)

  const { handleSubmit, control, reset, formState, watch, register } =
    useForm<IDisclosureData>({
      mode: 'onChange',
      defaultValues: emptyFormValues
    })

  const [currentRow, setCurrentRow] = useState<IDisclosureData | null>(null)

  const onCloseModal = () => {
    setShowModal(false)
    setCurrentRow(null)
    reset(emptyFormValues)
  }

  // adding rows
  const addRow = useMutation((data: IDisclosureData) =>
    disclosuresService.createDisclosure(data)
  )
  const onCreate = handleSubmit(async (formData) => {
    try {
      await addRow.mutateAsync(formData)
      setShowModal(false)
      showSuccess('Added row')
      refetch()
      reset(emptyFormValues)
    } catch (error: any) {
      showError(error?.message)
    }
  })

  // editing rows
  const editRow = useMutation((data: IDisclosureData) =>
    disclosuresService.editDisclosure(currentRow?.disclosure_id, data)
  )
  const onClickEditButton = (row: IDisclosureData) => {
    setCurrentRow(row)
    reset({ ...row })
    setShowModal(true)
  }

  const onEdit = handleSubmit(async (formData) => {
    const {
      date_of_birth,
      diagnosis,
      disclosure_id,
      latest_service_date,
      member_type,
      plan_year_expenses,
      risk_identifier_name,
      sex,
      status,
      term_date
    } = formData
    try {
      await editRow.mutateAsync({
        date_of_birth,
        diagnosis,
        disclosure_id,
        latest_service_date,
        member_type,
        plan_year_expenses,
        risk_identifier_name,
        sex,
        status,
        term_date
      })

      setShowModal(false)
      showSuccess('Updated row')
      refetch()
      setCurrentRow(null)
      reset(emptyFormValues)
    } catch (error: any) {
      showError(error?.message)
    }
  })

  // deleting rows
  const deleteRow = useMutation((disclosure_id: string) =>
    disclosuresService.deleteDisclosure(disclosure_id)
  )
  const onDelete = async (disclosure_id: string) => {
    await deleteRow.mutateAsync(disclosure_id)
    refetch()

    try {
    } catch (error: any) {
      showError(error?.message)
    }
  }

  return {
    showModal,
    setShowModal,
    currentRow,
    onClickEditButton,
    onEdit,
    onDelete,
    control,
    formState,
    watch,
    register,
    onCloseModal,
    onCreate
  }
}
