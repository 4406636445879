import React from 'react'
import { EmployerPortalSummary } from 'react-angle-dashboard-components'
import { summaryService } from '../../services'
import { PageLayout } from '../layouts/PageLayout'

export const Summary = () => {
  return (
    <PageLayout>
      <EmployerPortalSummary
        summaryService={summaryService}
        isBrokerPortal={false}
      />
    </PageLayout>
  )
}

export default Summary
