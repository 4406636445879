import React from 'react'
import { IPaginationProps } from '../Pagination'
import { Table } from '../Table'
import { TableCell } from './style'
import { ITableHeaderProps } from '../../interfaces'
import { IAllergy } from '../../interfaces'

export interface IAllergiesHeaders {
  name: string
  updateDate?: string
}

export interface IAllergiesTableProps
  extends React.HTMLAttributes<HTMLTableElement> {
  allergies: IAllergy[]
  headers: IAllergiesHeaders
  paginationConfig?: IPaginationProps
  loading?: boolean
}

export const AllergiesTable: React.FC<IAllergiesTableProps> = ({
  paginationConfig,
  allergies,
  headers,
  loading = false,
  ...p
}) => {
  const tableHeaders: ITableHeaderProps[] = [
    { title: headers.name, width: '50%' }
  ]

  if (headers.updateDate) {
    tableHeaders.push({ title: headers.updateDate, width: '50%' })
  }

  return (
    <Table
      className="w-100"
      paginationConfig={paginationConfig}
      headers={tableHeaders}
      headerClassName="thead-light"
      {...p}
    >
      <tbody>
        {allergies.map((a: IAllergy) => (
          <tr key={a.id}>
            <TableCell>{a.name}</TableCell>
            {a.updateDate ? (
              <TableCell className="text-muted">{a.updateDate}</TableCell>
            ) : null}
          </tr>
        ))}
        {allergies?.length === 0 && !loading && (
          <tr>
            <td colSpan={tableHeaders.length}>
              <h6 className="text-muted text-center p-5">No allergies</h6>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default AllergiesTable
