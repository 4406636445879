(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("react-router-dom"), require("react-query"), require("react-hook-form"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define("AngleComponents", ["react", "react-dom", "react-router-dom", "react-query", "react-hook-form", "axios"], factory);
	else if(typeof exports === 'object')
		exports["AngleComponents"] = factory(require("react"), require("react-dom"), require("react-router-dom"), require("react-query"), require("react-hook-form"), require("axios"));
	else
		root["AngleComponents"] = factory(root["react"], root["react-dom"], root["react-router-dom"], root["react-query"], root["react-hook-form"], root["axios"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__6128__, __WEBPACK_EXTERNAL_MODULE__3004__, __WEBPACK_EXTERNAL_MODULE__5369__, __WEBPACK_EXTERNAL_MODULE__3300__) => {
return 