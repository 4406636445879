export const states = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
}

export const utahCities = [
  'Alpine',
  'American Fork',
  'Aurora',
  'Ballard',
  'Beaver',
  'Benjamin',
  'Benson',
  'Blanding',
  'Bluffdale',
  'Bountiful',
  'Brigham City',
  'Canyon Rim',
  'Carbonville',
  'Castle Dale',
  'Cedar City',
  'Cedar Hills',
  'Centerfield',
  'Centerville',
  'Clearfield',
  'Clinton',
  'Coalville',
  'Cottonwood Heights',
  'Daniel',
  'Delta',
  'Draper',
  'Duchesne',
  'Eagle Mountain',
  'East Carbon City',
  'East Millcreek',
  'Elk Ridge',
  'Elwood',
  'Enoch',
  'Enterprise',
  'Ephraim',
  'Erda',
  'Fairview',
  'Farmington',
  'Farr West',
  'Ferron',
  'Fillmore',
  'Fountain Green',
  'Francis',
  'Fruit Heights',
  'Garland',
  'Genola',
  'Granite',
  'Grantsville',
  'Gunnison',
  'Harrisville',
  'Heber City',
  'Helper',
  'Herriman',
  'Highland',
  'Hildale',
  'Hill Air Force Base',
  'Holladay',
  'Honeyville',
  'Hooper',
  'Huntington',
  'Hurricane',
  'Hyde Park',
  'Hyrum',
  'Ivins',
  'Junction',
  'Kamas',
  'Kanab',
  'Kaysville',
  'Kearns',
  'LaVerkin',
  'Layton',
  'Lehi',
  'Lewiston',
  'Liberty',
  'Lindon',
  'Little Cottonwood Creek Valley',
  'Loa',
  'Logan',
  'Maeser',
  'Magna',
  'Manila',
  'Manti',
  'Mapleton',
  'Marriott-Slaterville',
  'Mendon',
  'Midvale',
  'Midway',
  'Milford',
  'Millcreek',
  'Millville',
  'Moab',
  'Mona',
  'Monroe',
  'Monticello',
  'Morgan',
  'Moroni',
  'Mount Olympus',
  'Mount Pleasant',
  'Mountain Green',
  'Murray',
  'Naples',
  'Nephi',
  'Nibley',
  'North Logan',
  'North Ogden',
  'North Salt Lake',
  'Oakley',
  'Ogden',
  'Oquirrh',
  'Orangeville',
  'Orem',
  'Panguitch',
  'Park City',
  'Parowan',
  'Payson',
  'Perry',
  'Plain City',
  'Pleasant Grove',
  'Pleasant View',
  'Price',
  'Providence',
  'Provo',
  'Randolph',
  'Richfield',
  'Richmond',
  'River Heights',
  'Riverdale',
  'Riverton',
  'Roosevelt',
  'Roy',
  'Saint George',
  'Salem',
  'Salina',
  'Salt Lake City',
  'Sandy',
  'Sandy Hills',
  'Santa Clara',
  'Santaquin',
  'Saratoga Springs',
  'Silver Summit',
  'Smithfield',
  'Snyderville',
  'South Jordan',
  'South Jordan Heights',
  'South Ogden',
  'South Salt Lake',
  'South Weber',
  'South Willard',
  'Spanish Fork',
  'Spring City',
  'Spring Glen',
  'Springville',
  'Stansbury park',
  'Summit Park',
  'Sunset',
  'Syracuse',
  'Taylorsville',
  'Tooele',
  'Toquerville',
  'Tremonton',
  'Uintah',
  'Vernal',
  'Vineyard',
  'Washington',
  'Washington Terrace',
  'Wellington',
  'Wellsville',
  'Wendover',
  'West Bountiful',
  'West Haven',
  'West Jordan',
  'West Mountain',
  'West Point',
  'West Valley City',
  'White City',
  'Willard',
  'Wolf Creek',
  'Woodland Hills',
  'Woods Cross'
]

export const utahCounties = [
  'Box Elder',
  'Morgan',
  'Weber',
  'Davis',
  'Salt Lake',
  'Summit',
  'Tooele',
  'Wasatch',
  'Utah'
]
