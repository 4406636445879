export const getCoverage = (memberData: any) => {
  if (memberData?.current_medical_coverage) {
    return memberData?.current_medical_coverage
  } else if (
    memberData?.future_medical_coverages &&
    memberData?.future_medical_coverages?.length
  ) {
    return memberData?.future_medical_coverages[0]
  } else if (
    memberData?.past_medical_coverages &&
    memberData?.past_medical_coverages?.length
  ) {
    return memberData?.past_medical_coverages[0]
  }

  return null
}
