import React from 'react'
import {
  CenteredSpinner,
  Col,
  Form,
  PlaceAutocomplete,
  Row,
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { formValidations } from '../../utils/formValidations'
import { FooterActions } from '../BusinessOnboarding/steps/FooterActions'
import {
  FormSectionRow,
  TitleRow,
  ValidationError
} from '../BusinessOnboarding/steps/style'
import { IBrokerForm } from './interfaces'
import { nonNegativeValues } from '@angle/utils'
import { useRuntimeConfig } from "@angle/hooks"

export const BrokerInformation = () => {
  const isLoading = false
  const isError = false

  const { handleSubmit, register, control, formState, setValue } =
    useForm<IBrokerForm>({
      mode: 'onChange'
    })

  const errors = formState.errors

  const config = useRuntimeConfig();

  const googleApiKey = config?.googleApiKey || '****'

  const handleContinue = handleSubmit(async (formData) => {
    try {
      console.log(formData)
    } catch (error) {
      console.log(error)
    }
  })

  const { required, maxPhoneLength, maxZip, onlyNumbers } = formValidations

  return (
    <>
      <Form>
        {isLoading ? (
          <CenteredSpinner style={{ height: '80vh' }}></CenteredSpinner>
        ) : (
          <>
            <TitleRow noGutters>
              <b>Agency Information</b>
            </TitleRow>
            <FormSectionRow>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Agency Name</Form.Label>
                  <Controller
                    name="agency.name"
                    control={control}
                    rules={{ required }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Agency name"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.agency?.name?.message}
                  </ValidationError>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Agency Phone Number</Form.Label>
                  <Controller
                    rules={{
                      validate: {
                        maxPhoneLength
                      },
                      required
                    }}
                    name="agency.phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        as={ReactInputMask}
                        mask="999 999-9999"
                        type="tel"
                        {...field}
                        placeholder="Agency phone number"
                        {...{ maskChar: null }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.agency?.phoneNumber?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Agency Email</Form.Label>
                  <Controller
                    name="agency.email"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Agency email address"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.agency?.email?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs="12"></Col>
            </FormSectionRow>
            <TitleRow noGutters>
              <b>Producer/Broker Information</b>
            </TitleRow>
            <FormSectionRow>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Controller
                    name="broker.firstName"
                    control={control}
                    rules={{ required }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="First name"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.broker?.firstName.message}
                  </ValidationError>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Controller
                    name="broker.email"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Email address"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.broker?.email?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Controller
                    name="broker.lastName"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Last name"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.broker?.lastName?.message}
                  </ValidationError>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <Controller
                    rules={{
                      validate: {
                        maxPhoneLength
                      },
                      required
                    }}
                    name="broker.phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        as={ReactInputMask}
                        mask="999 999-9999"
                        type="tel"
                        {...field}
                        placeholder="Phone number"
                        {...{ maskChar: null }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted &&
                      errors?.broker?.phoneNumber?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs="12"></Col>
            </FormSectionRow>
            <TitleRow noGutters>
              <b>Producer/Broker Address</b>
            </TitleRow>
            <FormSectionRow>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Controller
                    name="broker.address"
                    rules={{ required }}
                    control={control}
                    render={({ field }) => (
                      <PlaceAutocomplete
                        ref={field.ref}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        className="form-control"
                        placeholder="Address"
                        defaultValue={field.value}
                        apiKey={googleApiKey}
                        onPlaceSelection={({ address, city, zip, state }) => {
                          setValue('broker.address', address)
                          setValue('broker.state', state)
                          setValue('broker.city', city)
                          setValue('broker.zip', zip)
                        }}
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.broker?.address?.message}
                  </ValidationError>
                </Form.Group>

                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Controller
                    name="broker.city"
                    control={control}
                    rules={{ required }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        value={field.value || ''}
                        placeholder="Enter your city"
                      ></Form.Control>
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.broker?.city?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Controller
                    name="broker.state"
                    control={control}
                    rules={{ required }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        value={field.value || ''}
                        as="select"
                      >
                        <option value="" disabled hidden>
                          Select your state
                        </option>
                        <option value="Utah">Utah</option>
                      </Form.Control>
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.broker?.state?.message}
                  </ValidationError>
                </Form.Group>

                <Form.Group>
                  <Form.Label>ZIP</Form.Label>
                  <Controller
                    name="broker.zip"
                    rules={{
                      validate: { maxZip, onlyNumbers },
                      required
                    }}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        {...nonNegativeValues}
                        type="number"
                        placeholder="Enter ZIP code"
                      />
                    )}
                  />
                  <ValidationError>
                    {formState.isSubmitted && errors?.broker?.zip?.message}
                  </ValidationError>
                </Form.Group>
              </Col>
            </FormSectionRow>
          </>
        )}

        <TitleRow noGutters>
          <b>Payee Information</b>
        </TitleRow>

        <Row>
          <Col xs="12" className="mb-3">
            <div className="mb-3">Payee</div>

            <Form.Group>
              <Form.Check
                {...register('payee.payeeType')}
                type="radio"
                value="Producer/Broker"
                label="Producer/Broker"
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                {...register('payee.payeeType')}
                type="radio"
                value="Agency"
                label="Agency"
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Payee’s SSN / Tax ID</Form.Label>
              <Controller
                name="payee.ssnOrTaxId"
                control={control}
                rules={{ required }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    value={field.value || ''}
                    placeholder="SSN / Tax ID"
                  ></Form.Control>
                )}
              />
              <ValidationError>
                {formState.isSubmitted && errors?.payee?.ssnOrTaxId?.message}
              </ValidationError>
            </Form.Group>

            <Form.Group>
              <Form.Label>Payee’s Utah License Number</Form.Label>
              <Controller
                name="payee.licenseNumber"
                rules={{
                  validate: { maxZip, onlyNumbers },
                  required
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    {...nonNegativeValues}
                    type="number"
                    placeholder="Utah license number"
                  />
                )}
              />
              <ValidationError>
                {formState.isSubmitted && errors?.payee?.licenseNumber?.message}
              </ValidationError>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Payee’s NPN</Form.Label>
              <Controller
                name="payee.npn"
                rules={{
                  validate: { maxZip, onlyNumbers },
                  required
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    {...nonNegativeValues}
                    type="number"
                    placeholder="Payee NPN"
                  />
                )}
              />
              <ValidationError>
                {formState.isSubmitted && errors?.payee?.npn?.message}
              </ValidationError>
            </Form.Group>
            <Form.Group>
              <Form.Label>License Expiration Date</Form.Label>
              <Controller
                name="payee.licenseExpirationDate"
                control={control}
                rules={{ required }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    value={field.value || ''}
                    as="select"
                  >
                    <option value="" disabled hidden>
                      Select your state
                    </option>
                    <option value="Utah">Utah</option>
                  </Form.Control>
                )}
              />
              <ValidationError>
                {formState.isSubmitted &&
                  errors?.payee?.licenseExpirationDate?.message}
              </ValidationError>
            </Form.Group>
          </Col>
          <Col>
            <div className="mb-3">
              Is the payee currently appointed with Angle Health?
            </div>

            <Form.Group>
              <Form.Check
                {...register('payee.currentlyAppointed')}
                type="radio"
                value="no"
                label="No"
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                {...register('payee.currentlyAppointed')}
                type="radio"
                value="yes"
                label="Yes"
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <FooterActions
        continueHandler={handleContinue}
        continueDisabled={isError}
        showBack={false}
      />
    </>
  )
}
