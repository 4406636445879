/**
 * This function gets the correct string representation of a plan's copay/coinsurance
 *
 * @param {Coverage} planCoverage - A coverage object
 * @param {string} fieldName - The field to get copay/coinsurance information from. E.g., "doctor_visit_inn"
 * @returns {string} - Correctly-formatted copay/coinsurance information
 */
export const getCopayCoinsuranceString = (
  planCoverage: any,
  fieldName: string
): string => {
  const plan = planCoverage?.plan
  if (plan) {
    return plan[`${fieldName}_copay_type`] === 'copay'
      ? '$' + plan[`${fieldName}_copay`]
      : plan[`${fieldName}_coinsurance`] + '%'
  }
  return 'N/A'
}
