import React, { Dispatch, SetStateAction, useState } from 'react'
import { Col, FormControl, Modal, Row } from 'react-bootstrap'
import { GhostBtn, BankAccountWrapper } from './styles'
import { IPlaidAccount } from './interfaces'
import { Avatar } from '../Avatar'

interface SelectAccountsModalProps {
  accounts: IPlaidAccount[]
  setAccounts: Dispatch<SetStateAction<IPlaidAccount[]>>
  show: boolean
  toggleModal: Function
  continueCallback: Function
}

export const SelectAccountsModal: React.FC<SelectAccountsModalProps> = ({
  accounts,
  setAccounts,
  show,
  toggleModal,
  continueCallback
}) => {
  const [localAccounts, setLocalAccounts] = useState(accounts)

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      show={show}
      onHide={toggleModal}
      style={{ zIndex: 99999 }}
    >
      <Modal.Header className="pt-4 pb-2" closeButton>
        <Modal.Title>Add the account holder name to your accounts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {localAccounts.map((acc: IPlaidAccount, i: number) => (
          <BankAccountWrapper key={acc.account_id}>
            <Row className="w-100">
              <Col>
                <div className="d-flex align-items-center">
                  <Avatar
                    circle
                    fallbackContent={
                      <i className="text-white icon icon-bank"></i>
                    }
                  />
                  <div className="d-flex flex-column ml-3">
                    <h6 className="mb-1">**** {acc.mask}</h6>
                    <p className="text-muted m-0">{acc.institutionName}</p>
                  </div>
                </div>
              </Col>
              <Col>
                <FormControl
                  onChange={(ev) =>
                    setLocalAccounts(
                      localAccounts.map((e) => {
                        if (e.account_id === acc.account_id)
                          (e as any).owner = ev.target.value
                        return e
                      })
                    )
                  }
                  placeholder="Account holder name"
                />
              </Col>
            </Row>
          </BankAccountWrapper>
        ))}
      </Modal.Body>
      <Modal.Footer
        className="d-flex justify-content-between"
        style={{ border: 'none' }}
      >
        <GhostBtn ghost variant="primary" onClick={toggleModal}>
          Cancel
        </GhostBtn>
        <GhostBtn
          variant="primary"
          onClick={() => {
            setAccounts(localAccounts)
            continueCallback()
          }}
        >
          Add accounts
        </GhostBtn>
      </Modal.Footer>
    </Modal>
  )
}
