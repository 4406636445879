import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useQuery } from 'react-query'
import { Coordinates, IFacility, IProvider } from '../../interfaces'
import { IFilter } from '../../interfaces/directory-filters'
import { DirectoryLayout } from '../../layouts/DirectoryLayout'
import { CenteredSpinner } from '../CenteredSpinner'
import { DirectoryMap } from '../DirectoryMap'
import { EmptySearch } from '../EmptySearch'
import { IMarkerItem, MapMarker } from '../MapMarker'
import { DirectoryFilters } from './DirectoryFilters'
import { DirectoryList } from './DirectoryList'
import { DirectoryPagination } from './DirectoryPagination'
import { DirectorySearch } from './DirectorySearch'

const defaultCenter = { lat: 40.75887438182697, lng: -111.89457550366247 }

export interface Coords {
  lat: number
  lng: number
  bounds?: number
}

export type Location = Coords | undefined
export interface IQueryConfig {
  key: string
  asyncSearchFn: (config: any) => Promise<any>
}

export interface IDirectoryProps {
  initialFilters: IFilter[]
  googleApiKey?: string
  reactQueryConfig: IQueryConfig
  itemsName: string
  emptySearchTitle: string
  emptySearchText: string
  isProvider: boolean
  markerIcon: string
  cardClickHandler: Function
  initialCenter?: Coordinates
  initialNameFilter?: string
  maxElementsPerPage?: number
  backBtnCallback?: MouseEventHandler<HTMLElement>
  navbarHeight?: number
}

export interface IMarkerState {
  [x: string]: { show: boolean; items: IMarkerItem[] }
}

const hideAllMarkers = (markers: IMarkerState) => {
  const newMarkers = { ...markers }
  const keys = Object.keys(newMarkers)

  keys.forEach((key: string) => {
    newMarkers[key].show = false
  })

  return newMarkers
}

const getSpecialtyFilter = (filters: IFilter[]) => {
  return filters.find((f) => f.filterKey === 'specialty_searchterm')
}

export const Directory: React.FC<IDirectoryProps> = (props) => {
  const {
    initialFilters,
    googleApiKey,
    reactQueryConfig,
    itemsName,
    emptySearchTitle,
    emptySearchText,
    isProvider,
    markerIcon,
    cardClickHandler,
    initialCenter,
    initialNameFilter,
    backBtnCallback,
    maxElementsPerPage = 1000,
    navbarHeight
  } = props
  // State
  const [filters, setFilters] = useState<IFilter[]>(initialFilters)
  const [mapLocation, setMapLocation] = useState<Location>(undefined)
  const [userMarkerLocation, setUserMarkerLocation] =
    useState<Location>(undefined)
  const [currentLocationFilter, setCurrentLocationFilter] =
    useState<Location>(undefined)
  const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false)
  const [searchAsIMove, setSearchAsIMove] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [markers, setMarkers] = useState<IMarkerState>({})
  const [nameFilter, setNameFilter] = useState(initialNameFilter)

  // Query
  const { isLoading, data, isFetched } = useQuery<{
    items: any[]
    totalCount: number
  }>(
    [reactQueryConfig.key, currentLocationFilter, filters, page, nameFilter],
    () => {
      const specialtyFilter = getSpecialtyFilter(filters)
      if (isProvider && specialtyFilter && !specialtyFilter.value)
        return Promise.resolve({ items: [] })

      return reactQueryConfig.asyncSearchFn({
        ...currentLocationFilter,
        filters,
        page,
        nameFilter,
        maxElementsPerPage
      })
    },
    {
      enabled: Boolean(
        currentLocationFilter?.lat && currentLocationFilter?.lng
      ),
      refetchOnWindowFocus: false
    }
  )

  // Handlers
  const handleFilters = (appliedFilter: any, filterIndex: any) => {
    const newFilters = [...filters]

    newFilters[+filterIndex].value = appliedFilter

    setPage(1)
    setFilters(newFilters)
  }

  const handleMapLocationChange = (l: Location) => {
    setMapLocation(l)
    if (searchAsIMove) {
      setCurrentLocationFilter(l)
      setPage(1)
    }
  }

  const handleCardHover = (coordinates: Coordinates, id: string) => {
    const locationID = `${coordinates.lat}${coordinates.lng}`
    const newMarkers = hideAllMarkers(markers)
    newMarkers[locationID].show = true
    const focusedItemIndex = newMarkers[locationID].items.findIndex(
      (item: IMarkerItem) => {
        return item.id.toString() === id.toString()
      }
    )

    if (focusedItemIndex !== -1) {
      const aux = newMarkers[locationID].items[0]

      newMarkers[locationID].items[0] =
        newMarkers[locationID].items[focusedItemIndex]

      newMarkers[locationID].items[focusedItemIndex] = aux
    }

    setMarkers(newMarkers)
  }

  const handleMarkerToggle = (locationID: string, show: boolean) => {
    const newMarkers = hideAllMarkers(markers)

    newMarkers[locationID].show = show
    setMarkers(newMarkers)
  }

  // Callbacks
  const selectedLocationChange = useCallback(
    (l: Location) => {
      setMapLocation(l)
      setCurrentLocationFilter(l)
      setPage(1)
    },
    [setMapLocation, setCurrentLocationFilter, setPage]
  )

  const getUserLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (p) => {
          const coords = {
            lat: p.coords.latitude,
            lng: p.coords.longitude
          }

          selectedLocationChange(coords)
          setUserMarkerLocation(coords)
        },
        () => {
          selectedLocationChange(defaultCenter)
          setUserMarkerLocation(defaultCenter)
        },
        {
          timeout: 7000,
          maximumAge: 7000,
          enableHighAccuracy: true
        }
      )
    } else {
      selectedLocationChange(defaultCenter)
      setUserMarkerLocation(defaultCenter)
    }
  }, [selectedLocationChange, setUserMarkerLocation])

  const mapMarkers = useCallback(() => {
    if (!data) return
    const markers: IMarkerState = data.items.reduce(
      (markersAccumulator, record: IFacility | IProvider) => {
        const mappedRecord: IMarkerItem = {
          text: record.address,
          title: record.name,
          distance: record.distance,
          img: record.coverPicture,
          coordinates: record.coordinates,
          id: record.id.toString()
        }

        const locationId = `${record.coordinates.lat}${record.coordinates.lng}`

        if (markersAccumulator[locationId]) {
          markersAccumulator[locationId].items.push(mappedRecord)
        } else {
          markersAccumulator[locationId] = { show: false, items: [] }
          markersAccumulator[locationId].items.push(mappedRecord)
        }

        return markersAccumulator
      },
      {}
    )

    setMarkers(markers)
  }, [data, setMarkers])

  // Effects
  useEffect(() => {
    if (initialCenter) {
      selectedLocationChange(initialCenter)
    } else {
      getUserLocation()
    }
  }, [getUserLocation, initialCenter, selectedLocationChange])

  useEffect(() => {
    mapMarkers()
  }, [mapMarkers])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !Boolean(event.target.closest('.map-marker')) &&
        !event.target.classList.contains('map-marker')
      ) {
        const newMarkers = hideAllMarkers(markers)
        setMarkers(newMarkers)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [markers, setMarkers])

  // Derived state
  const items = data?.items || []
  const totalItems = data?.totalCount || 0

  // Elements
  const filtersEl = (
    <DirectoryFilters
      filters={filters}
      onFilter={handleFilters}
      searchAsIMove={searchAsIMove}
      nameFilter={nameFilter}
      clearNameFilter={() => setNameFilter(undefined)}
      backBtnCallback={backBtnCallback}
    />
  )

  let searchTitle = items.length.toString() || '0'

  if (totalItems > items.length) {
    searchTitle = `${items.length} of ${totalItems}`
  }

  const searchEl = (
    <DirectorySearch
      title={`${searchTitle} ${itemsName}`}
      selectLocation={selectedLocationChange}
      googlePlacesReady={googleApiLoaded}
    />
  )

  const mapEl = Boolean(mapLocation) ? (
    <DirectoryMap
      apiKey={googleApiKey}
      zoom={10}
      searchAsIMove={searchAsIMove}
      setSearchAsIMove={setSearchAsIMove}
      location={mapLocation}
      setLocation={handleMapLocationChange}
      otherKeys={{ libraries: ['places'] }}
      onGoogleApiLoaded={() => setGoogleApiLoaded(true)}
      refreshUserLocation={getUserLocation}
      userMarkerLocation={userMarkerLocation}
    >
      {Object.keys(markers).map((key: any) => (
        <MapMarker
          key={key}
          lat={markers[key].items[0].coordinates.lat}
          lng={markers[key].items[0].coordinates.lng}
          markerIcon={markerIcon}
          items={markers[key].items}
          show={markers[key].show}
          setShow={(show: boolean) => handleMarkerToggle(key, show)}
          isProvider={isProvider}
        />
      ))}
    </DirectoryMap>
  ) : null

  const paginatorEl =
    !isLoading && totalItems > maxElementsPerPage ? (
      <DirectoryPagination
        page={page}
        setPage={setPage}
        amountOfRecords={totalItems}
        perPage={maxElementsPerPage}
      />
    ) : null

  let listEl

  if (isLoading || !googleApiLoaded) {
    listEl = <CenteredSpinner data-testid="spinner" />
  }

  const specialtyFilter = getSpecialtyFilter(filters)

  const emptyText =
    specialtyFilter && !specialtyFilter.value
      ? 'Select a specialty to see results'
      : emptySearchText

  const emptyTitle =
    specialtyFilter && !specialtyFilter.value
      ? 'No specialty selected'
      : emptySearchTitle

  if (isFetched) {
    listEl =
      items.length > 0 ? (
        <DirectoryList
          items={items as any}
          cardClickHandler={cardClickHandler}
          hoverCardHandler={handleCardHover}
          circledItems={isProvider}
          paginator={paginatorEl}
        />
      ) : (
        <EmptySearch style={{ height: '100%' }} title={emptyTitle}>
          {emptyText}
        </EmptySearch>
      )
  }

  return (
    <DirectoryLayout
      filters={filtersEl}
      search={searchEl}
      map={mapEl}
      list={listEl}
      navbarHeight={navbarHeight}
    />
  )
}

export default Directory
