export const onCheckPayroll =
  (field: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = field.value || []

    if (e.target.checked) {
      const newValue = e.target.value
      field.onChange([...value, newValue])
    } else {
      field.onChange(value.filter((v: any) => v !== e.target.value))
    }
  }

export const typeOfPayrollList = [
  { text: 'Gusto', value: 'gusto' },
  { text: 'Rippling', value: 'rippling' },
  { text: 'Namely', value: 'namely' },
  { text: 'Zenefits', value: 'zenefits' },
  { text: 'ADP', value: 'adp' },
  { text: 'Paychex', value: 'paychex' },
  { text: 'Quickbooks', value: 'quickbooks' }
]

export const typeOfHRList = [
  { text: 'Gusto', value: 'gusto' },
  { text: 'Rippling', value: 'rippling' },
  { text: 'Zenefits', value: 'zenefits' },
  { text: 'ADP', value: 'adp' }
]
