import React from 'react'
import { Card } from 'react-bootstrap'
import { AnglePlanBadge, MetalTierColor } from '../AnglePlanBadge'
import { Button } from '../Button'
import {
  BadgeContainer,
  ClaimButtonContainer,
  Detail,
  DetailLabel,
  Title,
  GhostButton,
  GhostButtonContainer
} from './style'

export interface IPlanDetailData {
  label: string
  value: string
}

export interface IPlanDetailButton {
  label: string
  callback: (params) => void
}

export interface IPlanDetailProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  metalTier: MetalTierColor
  planName: string
  details: IPlanDetailData[]
  memberIDCardButton: IPlanDetailButton
  claimsButton: IPlanDetailButton
  docsButton: IPlanDetailButton
}

export const PlanDetail: React.FC<IPlanDetailProps> = ({
  title,
  metalTier,
  planName,
  details,
  memberIDCardButton,
  claimsButton,
  docsButton,
  ...p
}) => {
  return (
    <Card {...p}>
      <Card.Body>
        <Title>{title}</Title>
        <BadgeContainer>
          <AnglePlanBadge metalTier={metalTier} planName={planName} />
        </BadgeContainer>
        <div>
          {details.map((d: IPlanDetailData) => (
            <Detail key={d.label}>
              <DetailLabel>{d.label}</DetailLabel>
              <span className="text-muted">{d.value}</span>
            </Detail>
          ))}
        </div>
        <ClaimButtonContainer>
          <Button
            variant="primary"
            onClick={memberIDCardButton.callback}
            className="w-100"
          >
            {memberIDCardButton.label}
          </Button>
        </ClaimButtonContainer>
        <GhostButtonContainer>
          <GhostButton variant="primary" ghost onClick={claimsButton.callback}>
            {claimsButton.label}
          </GhostButton>
          <GhostButton variant="primary" ghost onClick={docsButton.callback}>
            {docsButton.label}
          </GhostButton>
        </GhostButtonContainer>
      </Card.Body>
    </Card>
  )
}

export default PlanDetail
