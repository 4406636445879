import React, { useState } from 'react'
import { useCSVReader } from 'react-papaparse'

export interface CSVReaderProps {
  label: string
  onChange: (data: any[]) => void
  validation: {
    rule: (x: any) => boolean
    message: string
  }
  errorCSV: string | null
  setErrorCSV: React.Dispatch<React.SetStateAction<string | null>>
}

export const CSVReader: React.FC<CSVReaderProps> = ({
  label,
  onChange,
  validation,
  errorCSV,
  setErrorCSV
}) => {
  const { CSVReader: Reader } = useCSVReader()
  const [isReset, setIsReset] = useState(false)

  const cleanError = () => {
    errorCSV && setErrorCSV(null)
  }

  const resetReader = () => {
    setIsReset(true)
    // Set it back to false so we can reset the reader more than once
    setIsReset(false)
  }

  const handleOnDrop = (data: any, file: File) => {
    if (!file.name?.match(/.csv$/)) {
      resetReader()
      return
    }

    const formattedData: any[] = []

    let failedValidation = false

    for (const record of data) {
      if (!validation.rule(record?.data)) {
        failedValidation = true
        break
      }

      formattedData.push(record?.data)
    }

    if (failedValidation) {
      setErrorCSV(validation.message)
      return
    }

    cleanError()
    onChange(formattedData)
  }

  const handleOnRemove = () => {
    cleanError()
    onChange([])
  }

  const handleOnError = (error: any) => {
    console.log(error)
    setErrorCSV('Please upload a valid CSV file')
  }

  return (
    <Reader
      config={{
        header: false,
        skipEmptyLines: 'greedy'
      }}
      onDrop={handleOnDrop}
      onRemoveFile={handleOnRemove}
      onError={handleOnError}
      style={{
        dropArea: {
          padding: '10px',
          borderColor: 'var(--primary)',
          borderRadius: '5px'
        },
        dropFile: {
          width: 'fit-content',
          wordBreak: 'break-word',
          borderRadius: '8px'
        },
        fileSizeInfo: {
          color: 'var(--primary)'
        },
        fileNameInfo: {
          color: 'var(--primary)'
        },
        removeButton: {
          color: 'var(--primary)'
        },
        progressBar: {
          backgroundColor: 'var(--primary)'
        }
      }}
      isReset={isReset}
      addRemoveButton
    >
      <span className="text-primary" style={{ fontSize: '1.2rem' }}>
        {label}
      </span>
    </Reader>
  )
}

export default CSVReader
