import { IBusinessOnboardingDTO } from '../../interfaces'
import { getNewCurrentStep } from '../../utils/utils'

export const mapDTOtoProducer = (data: IBusinessOnboardingDTO) => {
  return {
    agencyAppointment: data.agency_appointment === true ? 'true' : 'false',
    email: data.agency?.producer_contact_info?.work_email || '',
    firstName: data.agency?.producer_name?.first_name || '',
    lastName: data.agency?.producer_name?.last_name || '',
    stepNumber: data.in_progress_state,
    isFronting: data?.insurer_type === 'fronting_arrangement',
    isLevelFunded: data?.funding_type === "level_funded"
  }
}

export const mapProducerToDTO = (formData: any, backendStepNumber: number) => {
  const hasAgency = formData.agencyAppointment === 'true'
  const DTO = {
    agency_appointment: hasAgency,
    in_progress_state: getNewCurrentStep(backendStepNumber, 9),
    agency: {
      producer_name: {
        first_name: formData.firstName,
        last_name: formData.lastName
      },
      producer_contact_info: {
        work_email: formData.email
      }
    }
  }
  return DTO
}
