import { IBusinessOnboardingDTO } from '../../interfaces'
import { getNewCurrentStep } from '../../utils/utils'



export const mapEmployerPreferencesToDTO = (
  formData: any,
  backendStepNumber: number,
) => {
  return {
    coverage_effective_date: formData.effectiveDate,
    eligibility_num_hours: formData.eligibilityHours,
    eligibility_num_employees: formData.numOfEmployees,
    new_hire_waiting_period_update: formData.newHireWaitingPeriod,
    in_progress_state: getNewCurrentStep(backendStepNumber, 4),
    other_compensation_employees_num: formData.medicareNum || null
  }
}

export const mapDTOtoEmployerPreferences = (data: IBusinessOnboardingDTO) => {
  return {
    effectiveDate: data?.coverage_effective_date,
    eligibilityHours: data?.eligibility_num_hours,
    numOfCoveredEmployees: data?.covered_employees,
    numOfCoveredLives: data?.covered_lives,
    newHireWaitingPeriod:
      data?.new_hire_waiting_period_update,
    medicareNum: data.other_compensation_employees_num,
    numOfEmployees: data?.eligibility_num_employees,
    stepNumber: data?.in_progress_state,
    isLargeGroup: data?.group_size === 'large',
    fundingType: data?.funding_type,
    isFronting: data?.insurer_type === "fronting_arrangement"
  }
}
