import React from 'react'
import { LocationSearchField } from '../../LocationSearchField'
import { Location } from '../Directory'

import { SearchContainer, InputPlaceholder } from './style'

interface IDirectorySearchProps {
  selectLocation: (l: Location) => void
  title: string
  googlePlacesReady: boolean
}

export const DirectorySearch: React.FC<IDirectorySearchProps> = ({
  selectLocation,
  title,
  googlePlacesReady,
  ...p
}) => {
  return (
    <SearchContainer {...p}>
      <h2>{title}</h2>
      {googlePlacesReady ? (
        <LocationSearchField
          style={{ width: '100%', marginTop: '15px', marginBottom: '20px' }}
          onLocationSelected={selectLocation}
        />
      ) : (
        <InputPlaceholder />
      )}
    </SearchContainer>
  )
}

export default DirectorySearch
