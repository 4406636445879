import React from 'react'
import { Reporting } from 'react-angle-dashboard-components'
import { useLocation } from 'react-router-dom'
import { Paths } from '../../routes/paths'
import { PageLayout } from '../layouts/PageLayout'
import { reportingService, summaryService } from '../../services'
import { useQuery } from 'react-query'

const tablePath = `${Paths.reporting}/table`

const ReportingPage = () => {
  const location = useLocation()

  const { data: summaryData } = useQuery('summaryData', () =>
    summaryService.getSummary()
  )

  const breadcrumbs = [
    { title: 'Home', link: '/' },
    { title: 'Reporting', link: '/reporting' }
  ]

  if (location.pathname === tablePath) {
    breadcrumbs.push({
      title: 'Table',
      link: tablePath
    })
  }
  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <Reporting
        basePagePath={Paths.reporting}
        reportingService={reportingService}
        companyName={summaryData?.companyName ?? ''}
      />
    </PageLayout>
  )
}

export default ReportingPage
