import moment from 'moment'
import React, { useState } from 'react'
import { Modal } from '../../../../index'
import { useLocation } from 'react-router-dom'
import { DateHandler } from '@angle/utils'

export type DisenrollLocationState = {
  reason: string
  date: string
  name: string
}

export const DisenrollModal: React.FC<{ refetch: any }> = ({ refetch }) => {
  const locationState = useLocation<DisenrollLocationState>().state
  const initialShow = locationState && locationState?.reason === 'disenroll'
  const [show, setShow] = useState(initialShow)

  const date = DateHandler.formatFullDate(
    moment(locationState?.date).endOf('month')
  )

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      show={show}
      onHide={() => {
        setShow(false)
        refetch()
      }}
    >
      <Modal.Header className="pt-3 pb-3 border-bottom mb-4" closeButton>
        <Modal.Title>Employee Disenrolled</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-2 text-center">
        <h5>
          The health coverage for {locationState?.name} will end on {date}.
        </h5>
        <p className="text-muted">
          {' '}
          This change will be reflected in your next invoice.
        </p>
      </Modal.Body>
    </Modal>
  )
}
