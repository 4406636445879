import styled from 'styled-components'

export interface ITableHeaderProps {
  wide?: boolean
  smallText?: boolean
  upperCase?: boolean
}

export const TableHeader = styled.th<ITableHeaderProps>`
  font-size: ${(props) => (props.smallText ? '0.8rem' : '1rem')} !important;
  text-transform: ${(props) =>
    props.upperCase ? 'uppercase' : ' none'} !important;
  width: ${(props) => (props.wide ? '80%' : 'auto')} !important;
`
