import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { getCopayCoinsuranceString } from '../utils/getCopayCoinsuranceString'
import { Subheader } from '../style'

export const PharmacyInformation: React.FC<{
  planCoverage: any
  isHdhp: boolean
}> = ({ planCoverage, isHdhp }) => {
  return (
    <>
      <Subheader className="text-primary mt-2">Pharmacy Information</Subheader>
      <Row>
        <Col>
          <Row>
            <Col xs="6" className="text-left">
              <p className="mb-0 fs-12 font-weight-bold">Rx BIN:</p>
              <p className="mb-0 fs-12 font-weight-bold">Rx PCN:</p>
              <p className="mb-0 fs-12 font-weight-bold">Rx Group:</p>
              <p className="mb-0 fs-12 font-weight-bold">
                Rx Copays:{isHdhp ? '*' : ''}
              </p>
            </Col>
            <Col xs="6" className="pl-0 text-right">
              <p className="mb-0 fs-12">{planCoverage?.plan.rx_bin}</p>
              <p className="mb-0 fs-12">{planCoverage?.plan.rx_pcn}</p>
              <p className="mb-0 fs-12">{planCoverage?.plan.rx_group}</p>
              <p className="mb-0 fs-12">
                {getCopayCoinsuranceString(planCoverage, 'rx_1_inn')} /{' '}
                {getCopayCoinsuranceString(planCoverage, 'rx_2_inn')} /{' '}
                {getCopayCoinsuranceString(planCoverage, 'rx_3_inn')}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
