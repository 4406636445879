import moment from 'moment'

export const sentToNomi = (checkBatch: number) : boolean => {
    return (checkBatch && checkBatch != 9999)
}

export const isNomi = (paymentSource: string) : boolean => {
    return (paymentSource === "nomi_ach" || paymentSource === "nomi_virtual_card" || paymentSource === "nomi_zero_paid")
}

export const nomiFinished = (nomiPaymentStatus: string) : boolean => {
    return (nomiPaymentStatus === "PAID" || nomiPaymentStatus === "PROCESSED")
}

export const getNotes = (checkBatch: number, paymentSource: string, nomiPaymentMethod: string, nomiPaymentStatus: string) : string => {
    if (!sentToNomi(checkBatch)) {
        return claimNotesObject.NOT_SENT_TO_VENDOR
    }

    if (nomiPaymentMethod === "PV_OPT_OUT" || nomiPaymentStatus === "REJECTED") {
        return claimNotesObject.REJECTED_BY_VENDOR
    }

    if (paymentSource === "lob_check") {
        return claimNotesObject.WAITING_FOR_VENDOR_RESPONSE
    }

    if (isNomi(paymentSource) && (nomiPaymentStatus === "PROCESSING")) {
        return claimNotesObject.VENDOR_PAYMENT_PROCESSING
    }

    if (isNomi(paymentSource) && (nomiPaymentStatus === "VIRTUAL_CARD_SENT")) {
        return claimNotesObject.VENDOR_PAYMENT_SENT
    }

    if (isNomi(paymentSource) && (nomiPaymentStatus === "PROCESSED" || nomiPaymentStatus === "PAID")) {
        return claimNotesObject.VENDOR_PAYMENT_PROCESSED
    }
    return "-"
}

export const getClaimPaymentIssuedDate = (claim: any) : string => {
    if (claim.nomiPaymentIssuedAt) {
        return claim.nomiPaymentIssuedAt
    } else {
        if (claim.lastEob) {
            const formattedDate = moment(claim.lastEob.created_at).format('MM/DD/YYYY')

            if (formattedDate === 'Invalid date') return ''
            return formattedDate
        }
    }

    return "-"
}

interface paymentSourceMap {
    lob_check: string;
    nomi_ach: string;
    nomi_virtual_card: string;
    nomi_zero_paid: string;
}

export const paymentSourceObject: paymentSourceMap = {
    lob_check: 'Paper Check',
    nomi_ach: "NOMI ACH",
    nomi_virtual_card: 'NOMI Virtual Card',
    nomi_zero_paid: 'NOMI Zero Paid'
}

export type PaymentSource = keyof typeof paymentSourceObject

interface nomiPaymentMethodMap {
    PV_VIRTUAL_CARD: string;
    PV_BANK: string;
    PV_NULL: string;
    PV_OPT_OUT: string
}

export const nomiPaymentMethodObject: nomiPaymentMethodMap = {
    PV_BANK: "NOMI ACH",
    PV_VIRTUAL_CARD: 'NOMI Virtual Card',
    PV_NULL: "NOMI Zero Paid",
    PV_OPT_OUT: "NOMI Opted Out"
}

export type NomiPaymentMethod = keyof typeof nomiPaymentMethodObject

interface nomiPaymentStatusMap {
    PROCESSING: string;
    PROCESSED: string;
    VIRTUAL_CARD_SENT: string;
    PAID: string;
    REJECTED: string;
}

export const nomiPaymentStatusObject: nomiPaymentStatusMap = {
    PROCESSING: "PROCESSING",
    PROCESSED: "PROCESSED",
    VIRTUAL_CARD_SENT: "VIRTUAL_CARD_SENT",
    PAID: "PAID",
    REJECTED: "REJECTED"
}

export type NomiPaymentSource = keyof typeof nomiPaymentStatusObject

interface claimNotesMap {
    NOT_SENT_TO_VENDOR: string;
    WAITING_FOR_VENDOR_RESPONSE: string;
    VENDOR_PAYMENT_PROCESSING: string;
    VENDOR_PAYMENT_SENT: string;
    VENDOR_PAYMENT_PROCESSED: string;
    REJECTED_BY_VENDOR: string;
}

export const claimNotesObject: claimNotesMap = {
    NOT_SENT_TO_VENDOR: "Claim has not been sent to vendor, please reach out to strategy team if claim's processed date is approaching 30 days",
    WAITING_FOR_VENDOR_RESPONSE: "Claim has been sent to vendor, please wait for response from vendor",
    VENDOR_PAYMENT_PROCESSING: "Claim has been received by vendor and is waiting on confirmation, please wait for further response from vendor",
    VENDOR_PAYMENT_SENT: "Claim funds have been sent to provider, please reach out to strategy team if you have questions about this claim until further notice",
    VENDOR_PAYMENT_PROCESSED: "Claim has been processed and payment (if any) has been sent by vendor, please refer to date provided from vendor",
    REJECTED_BY_VENDOR: "Claim has been returned by vendor due to invalid data or provider opt-out. If not paid out by check, please reach out to strategy team if claim's processed date is approaching 30 days"
}

export type ClaimNotes = keyof typeof claimNotesObject