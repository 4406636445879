const origin = window.location.origin

const isTest =
  origin.includes('test') ||
  origin.includes('dev') ||
  origin.includes('localhost')

export const reports = [
  {
    label: 'Total Claims by Member',
    dashboardToken: isTest ? 'Kjv1OdbAzp' : '5zk1NMBxgX'
  },
  {
    label: 'Total Monthly Claims by Member',
    dashboardToken: isTest ? 'n08YWowYW4' : 'qP8xKbnYoZ'
  },
  {
    label: 'Diagnosis Summary Report',
    dashboardToken: isTest ? 'Dp917jXxa3' : 'QjDxeZE1LG'
  },
  {
    label: 'Stop Loss Eligible Members ',
    dashboardToken: isTest ? '2QRA3eaxEd' : '2bo1qNbAzw'
  }
]

export const dashboardToken = isTest ? 'aovAR3VYlV' : '7BKxgd81LR'
