import { useState } from 'react'

export const typeOfCoverageList = [
  { text: 'Employer Group', value: 'employer_group' },
  { text: 'Individual', value: 'individual' },
  { text: 'Medicare', value: 'medicare' },
  { text: 'Governmental', value: 'governmental' }
]

export const useCustomCoverage = (coverages: any[]) => {
  const coverageList = typeOfCoverageList.map((e) => e.value)
  const coveragesLeft = coverages.filter((e) => !coverageList.includes(e))

  const currentCoverage = coveragesLeft.find((e) => Boolean(e))

  return useState(currentCoverage)
}
