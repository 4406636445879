import styled from 'styled-components'

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 357px;
  height: auto;
  max-height: 100%;
  padding: 1rem;
`

export const LogoWrapper = styled.div`
  max-width: 307px;
  margin: 0 auto;
`
