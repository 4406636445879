import { useQueryParams } from '@angle/hooks'
import classNames from 'classnames'
import React from 'react'
import { Button, Form, useToaster } from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Redirect, useHistory } from 'react-router-dom'
import { Logo } from '../../components/Logo'
import { authService as auth } from '../../services'
import { FormWrapper } from './style'


export const ResetPassword = () => {
  const history = useHistory()
  const toast = useToaster()
  const code = useQueryParams().get('code') || ''

  const onSuccess = async (response: any) => {
    toast.show({
      message: response.detail,
      icon: 'icon-check',
      type: 'success'
    })

    history.push('/login')
  }

  const onFailure = (error: any) => {
    toast.show({
      message: `Something went wrong: ${error.message}`,
      icon: 'icon-cancel',
      type: 'danger'
    })
  }

  const updatePassword = useMutation(
    (data: { new_password: string; code: string }) => auth.resetPassword(data),
    {
      onSuccess: onSuccess,
      onError: onFailure
    }
  )

  const { control, formState, watch, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      password: '',
      reTypePassword: ''
    }
  })

  const passwordValue = watch('password')

  const onSubmit = handleSubmit(async (data: any) => {
    updatePassword.mutate({
      new_password: data.password,
      code
    })
  })

  if (!code) {
    return <Redirect to="/login" />
  }

  return (
    <div className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex align-items-top mb-5">
        <Logo />
      </div>
      <>
        <div>
          <h2 className="fs-32">Reset Your Password</h2>
        </div>

        <p className="fs-20 text-muted m-0 p-3">
          Please choose a new password for your account
        </p>

        <FormWrapper className="p-2">
          <Form
            onSubmit={(e: any) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': formState.errors?.password
                })}
              >
                Password
              </Form.Label>
              <Controller
                name="password"
                rules={{
                  required: true,
                  validate: {
                    moreThan8Char: (value) => {
                      if (value.length < 8) return false
                      return true
                    }
                  }
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="password"
                    autoComplete="new-password"
                    autoFocus
                    placeholder="Create password"
                    className="bg-white"
                    isInvalid={!!formState.errors?.password}
                  />
                )}
              />

              {formState.errors?.password &&
                formState.errors?.password?.type === 'moreThan8Char' && (
                  <small className="text-danger mt-2">
                    {' '}
                    Passwords must have at least 8 characters
                  </small>
                )}
            </Form.Group>

            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger': formState.errors?.reTypePassword
                })}
              >
                Re-type Password
              </Form.Label>
              <Controller
                name="reTypePassword"
                rules={{
                  required: true,
                  validate: {
                    passwordAreNotTheSame: (value) => {
                      if (value !== passwordValue) return false
                      return true
                    }
                  }
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="password"
                    placeholder="Re-type your password"
                    className="bg-white"
                    isInvalid={!!formState.errors?.reTypePassword}
                  />
                )}
              />
              {formState.errors?.reTypePassword &&
                formState.errors?.reTypePassword?.type ===
                  'passwordAreNotTheSame' && (
                  <small className="text-danger mt-2">
                    {' '}
                    Passwords do not match{' '}
                  </small>
                )}
            </Form.Group>
            <Button
              disabled={formState.isSubmitting || updatePassword.isLoading}
              variant="primary"
              className="w-100"
              onClick={onSubmit}
            >
              Update
            </Button>
          </Form>
        </FormWrapper>
      </>
    </div>
  )
}

export default ResetPassword
