import React from 'react'
import { Avatar } from '../Avatar'
import ItemPaginator from './ItemPaginator'
import { IMarkerItem } from './MapMarker'
import {
  TooltipContainer,
  ProviderTooltipContent,
  ProviderTitle,
  ProviderText,
  TooltipFooter
} from './style'

export interface IProviderTooltipProps {
  currentIndex: number
  items: IMarkerItem[]
  setCurrentIndex: Function
}
export const ProviderTooltip: React.FC<IProviderTooltipProps> = ({
  currentIndex,
  items,
  setCurrentIndex
}) => {
  const currentItem = items[currentIndex]
  return (
    <TooltipContainer
      style={{ padding: '1rem', height: '8rem', width: '450px' }}
      className="d-flex flex-column justify-content-center"
    >
      <ItemPaginator
        currentIndex={currentIndex}
        maxIndex={items.length - 1}
        setCurrentIndex={setCurrentIndex}
        showPageControls={items.length > 1}
      />

      <ProviderTooltipContent>
        <Avatar
          avatarImagePath={currentItem.img}
          fallbackContent={<i className="icon icon-user text-primary" />}
          circle
          size="lg"
          variant="primary-light"
        />
        <div className="ml-3">
          <ProviderTitle>{currentItem.title}</ProviderTitle>
          <ProviderText className="fs-16">{currentItem.text}</ProviderText>
          <small className="text-muted d-block mt-1">
            {currentItem.distance}
          </small>
        </div>
      </ProviderTooltipContent>
      <TooltipFooter></TooltipFooter>
    </TooltipContainer>
  )
}

export default ProviderTooltip
