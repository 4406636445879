import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { FilterContainer } from '../FilterContainer/FilterContainer'
import './styles.scss'

const DistanceFilterContainer = styled.div<{ width: number }>`
  width: ${(props) => props.width + 'px'};
`
export interface IRangeWithBarsFilterProps {
  name: string
  componentWidth: number
  maxValue: number
  unitSuffix: string
  value: number
  onChange: Function
  dropdownDisabled?: boolean
  [x: string]: any
}

export const RangeWithBarsFilter: React.FC<IRangeWithBarsFilterProps> = (
  props
) => {
  const {
    name,
    componentWidth,
    maxValue,
    unitSuffix,
    value = 0,
    onChange,
    dropdownDisabled = false,
    ...p
  } = props

  const [sliderValue, setSliderValue] = useState(value)
  const [showDropdown, setShowDropdown] = useState(false)
  const sliderRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (sliderRef.current) {
      setSliderValue(value)
      sliderRef.current.value = value.toString()
    }
  }, [showDropdown, value])

  const onSliderValueChange = (units) => {
    setSliderValue(units)
  }

  const onToggle = () => {
    setShowDropdown(!showDropdown)
  }

  const apply = () => {
    onChange(+sliderValue)
    setShowDropdown(false)
  }

  const clear = () => {
    if (sliderRef.current) {
      setSliderValue(0)
      sliderRef.current.value = ''
    }
  }

  return (
    <FilterContainer
      name={name}
      showDropdown={showDropdown}
      onToggle={onToggle}
      apply={apply}
      clear={clear}
      appliedFilters={value > 0}
      dropdownDisabled={dropdownDisabled}
      className="pt-0"
      {...p}
    >
      <DistanceFilterContainer width={componentWidth}>
        <input
          type="range"
          ref={sliderRef}
          onChange={(e) => onSliderValueChange(e.target.value)}
          min={0}
          max={maxValue}
          step={10}
          className="d-block w-100"
          value={sliderValue}
        />
        <span className="d-inline-block" style={{ marginTop: '16px' }}>
          Distance:{' '}
          <span className="text-primary">
            {sliderValue}
            {unitSuffix}
          </span>
        </span>
      </DistanceFilterContainer>
    </FilterContainer>
  )
}

export default RangeWithBarsFilter
