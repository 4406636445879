import React, { useState } from 'react'
import {
  Col,
  Row,
  CenteredSpinner,
  Button,
  EmployeeService,
  EightThreeFourService,
  EmployerPortalEditEmployeeModalV2,
  PlanService,
  SummaryService
} from '../../../index'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { maskSsn } from '@angle/utils'
import { PersonalInformation, SectionContainer } from './style'
import EmployeeProfileSidebar from './EmployeeProfileSidebar'

export const EmployerPortalDependentProfile: React.FC<{
  employeeService: EmployeeService
  isBrokerPortal?: boolean
  eightThreeFourService: EightThreeFourService
  plansService: PlanService,
  summaryService: SummaryService
}> = ({ employeeService, isBrokerPortal, eightThreeFourService, plansService, summaryService }) => {
  const { id, dependentId, groupId } = useParams<{
    id: string
    dependentId: string
    groupId: string
  }>()

  const basePath = `/employees/${id}/dependents/${dependentId}/edit`
  const [isEditEmployeeModalVisible, setEditEmployeeModalVisible] =
    useState(false)
  const { data: dependent, isLoading, refetch: refetchDependent } = useQuery(
    ['employee', dependentId],
    () =>
      employeeService.getEmployeeProfile(
        dependentId,
        isBrokerPortal ? groupId : ''
      )
  )

  const { data: parent, isLoading: isLoadingParent } = useQuery(
    ['employee', id],
    () => employeeService.getEmployeeProfile(id, isBrokerPortal ? groupId : '')
  )

  if (isLoading) {
    return <CenteredSpinner />
  }
  return (
    <div className="d-flex flex-column h-100">
      <SectionContainer className="d-flex h-100">
        <EmployeeProfileSidebar
          employee={dependent}
          complete={!dependent?.empty}
          empty={dependent?.empty}
          dependent
          isBrokerPortal={isBrokerPortal}
          eightThreeFourService={eightThreeFourService}
          refetchEmployee={refetchDependent}
          summaryService={summaryService}
          plansService={plansService}
        />
        <div className="flex-grow-1" style={{ borderTop: '1px solid #E7EAF3' }}>
          <div className="pt-5 pl-5 pr-5">
            <div>
              <h5 className="mb-5 d-flex justify-content-between">
                <span>Personal Information</span>

                <Button
                  variant="primary"
                  ghost
                  size="sm"
                  onClick={() => setEditEmployeeModalVisible(true)}
                >
                  Edit Information
                </Button>
              </h5>
              <Row>
                <Col md={6}>
                  <PersonalInformation>
                    <p>Full Name</p>
                    <p>{dependent?.name}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6}>
                  <PersonalInformation>
                    <p>Member ID</p>
                    <p>{dependent?.id}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6}>
                  <PersonalInformation>
                    <p>Date of Birth</p>
                    <p>{dependent?.dob}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6}>
                  <PersonalInformation>
                    <p>SSN</p>
                    <p>{maskSsn(dependent?.ssn || '')}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6}>
                  <PersonalInformation>
                    <p>Email Address</p>
                    <p>{dependent?.email}</p>
                  </PersonalInformation>
                </Col>
                {dependent?.medicareEligible && (
                  <Col md={6}>
                    <PersonalInformation>
                      <p>Medicare Eligible</p>
                      <p>{dependent?.medicareEligible}</p>
                    </PersonalInformation>
                  </Col>
                )}
                <Col md={6} sm={12}>
                  <PersonalInformation>
                    <p>Mailing Address</p>
                    <p className="text-right">{dependent?.address}</p>
                  </PersonalInformation>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </SectionContainer>
      {dependent && parent && dependent.address_object && (
        <EmployerPortalEditEmployeeModalV2
          isEditModalVisible={isEditEmployeeModalVisible}
          setEditModalVisible={setEditEmployeeModalVisible}
          employee={dependent}
          eightThreeFourService={eightThreeFourService}
          parentMemberId={parent.id}
          refetchEmployee={refetchDependent}
        />
      )}
    </div>
  )
}
