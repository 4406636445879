import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MedicalCoveragePlanCard } from '../../../../components/MedicalCoveragePlanCard'
import { BusinessOnboardingContext } from '../../context/BusinessOnboardingContext'
import { useStep } from '../../hooks/use-step'
import { businessOnboardingPaths } from '../../router/paths'
import { FooterActions } from '../FooterActions'
import { TitleRow } from '../style'
import { getNewCurrentStep } from '../../utils/utils'
import { CardsContainer } from './styles'
import { useQuery } from 'react-query'
import { plansService } from '../../../../services'
import { groupService } from '../../../../services'
import {
  CenteredSpinner,
  IPlanDTO,
  Modal,
  SummaryOfBenefitsCoverage,
  useToaster
} from 'react-angle-dashboard-components'
import { capitalize } from '@angle/utils'

import { IBusinessOnboardingDTO } from '../../interfaces'

const mapDTOtoPlans = (data: IBusinessOnboardingDTO) => {
  return {
    plans: data.plans,
    stepNumber: data.in_progress_state,
    groupSize: data.group_size,
    isFronting: data.insurer_type === "fronting_arrangement",
  }
}
const isTesting =
  window.location.origin.includes('testing') ||
  window.location.origin.includes('localhost')

export const PlanSelection: React.FC<{ renewal: boolean }> = ({ renewal }) => {
  const history = useHistory()
  const toaster = useToaster()
  const { state, setState, modifyGroup } = useContext(BusinessOnboardingContext)
  const [show, setShow] = useState(false)
  const [currentSBCData, setSBCData] = useState<any>(null)
  const [sbcIsLoading, setSbcLoading] = useState(false)

  const {
    data: plans,
    isLoading: isPlanLoading,
    isError,
    isFetching
  } = useQuery(
    'getPlans',
    async () => {
      const data = await plansService.getList({})
      return data.plans
    },
    {
      initialData: [],
      retry: 0
    }
  )

  const { data, isLoading } = useQuery(
    'getSelectedPlans',
    () => groupService.getInfo(mapDTOtoPlans, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      cacheTime: 0,
      onSuccess: (data: any) => {
        setState(data.stepNumber)
      }
    }
  )

  const [selectedPlans, setSelectedPlans] = useState(
    data?.plans ? data.plans.map((plan: any) => plan.plan_id) : []
  )

  useEffect(() => {
    setSelectedPlans(
      data?.plans ? data.plans.map((plan: any) => plan.plan_id) : []
    )
  }, [plans, data])

  const selectedList = Object.keys(selectedPlans).reduce(
    (acc: string[], planKey: string) => {
      const list = acc
      if (selectedPlans[planKey]) {
        list.push(planKey)
      }

      return list
    },
    []
  )

  const openSBCModal = async (plan: IPlanDTO) => {
    setSbcLoading(true)
    setShow(true)
    const { events, faqs } = await plansService.getSBC(plan.plan_id)
    setSBCData({
      benefits: events,
      faqs,
      plan
    })
    setSbcLoading(false)
  }

  const handleContinue = async () => {
    const planArr = selectedPlans.map((plan: any) => ({ plan_id: plan }))

    const availablePlans = plans?.map((plan: any) => plan.plan_id)

    const list = planArr.filter((plan: any) =>
      availablePlans.includes(plan.plan_id)
    )

    try {
      await modifyGroup.mutateAsync({
        plans: list,
        in_progress_state: getNewCurrentStep(data.stepNumber, 6 + (data.isFronting))
      })
      renewal
        ? history.push(businessOnboardingPaths.billing)
        : history.push(businessOnboardingPaths.carrierInformation)
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  }

  const handleBack = () => {
    history.push(businessOnboardingPaths.contributionStrategy)
  }

  useStep(state, 5, 'plan', data?.stepNumber || 1)

  return (
    <>
      <div>
        <TitleRow noGutters>
          <b>Select all the plans that you want to offer</b>
        </TitleRow>
        <p className="text-secondary">
          Prices are based on your employee contribution selection
        </p>

        {isLoading || isPlanLoading ? (
          <CenteredSpinner style={{ height: '40vh' }}></CenteredSpinner>
        ) : (
          <CardsContainer>
            {(isError ||
              (plans?.length === 0 &&
                !isLoading &&
                !isPlanLoading &&
                !isFetching)) && (
              <div className="font-weight-bold text-danger py-4">
                We encountered an error loading the plans. Please reach out to
                us at{' '}
                <a href="mailto:onboarding@anglehealth.com">
                  onboarding@anglehealth.com
                </a>{' '}
                or 855-937-1855 if this issue persists.
                <br />
              </div>
            )}
            {plans &&
              plans.map((plan: any) => (
                <MedicalCoveragePlanCard
                  className="mb-5 mr-3"
                  plan={plan}
                  key={plan.plan_id}
                  selected={selectedPlans.includes(plan.plan_id)}
                  showPrices={data.groupSize === 'large'}
                  onMoreDetailsClick={() => openSBCModal(plan)}
                  onSelect={() => {
                    if (selectedPlans.includes(plan.plan_id)) {
                      const newPlans = selectedPlans.filter(
                        (id: any) => plan.plan_id !== id
                      )
                      setSelectedPlans(newPlans)
                    } else {
                      const newPlans = [...selectedPlans, plan.plan_id]
                      setSelectedPlans(newPlans)
                    }
                  }}
                />
              ))}
          </CardsContainer>
        )}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="w-100 m-0 mw-100"
          className="px-0"
          aria-labelledby="example-custom-modal-styling-title"
          style={{ zIndex: 9999 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body style={{ minHeight: '95vh', minWidth: 1000 }}>
            {sbcIsLoading && <CenteredSpinner />}
            {currentSBCData && !sbcIsLoading && (
              <SummaryOfBenefitsCoverage
                coverageInfo={{
                  plan: {
                    name: currentSBCData.plan?.plan_name,
                    metalTier: currentSBCData.plan?.metal_tier,
                    type: currentSBCData.plan?.plan_type
                  },
                  coveragePeriod: {
                    from: currentSBCData.plan?.start_date,
                    to: currentSBCData.plan?.end_date
                  },
                  coverageFor: currentSBCData.plan?.eligible_member_types
                    ?.map(capitalize)
                    .join('/') //'Single/Family'
                }}
                benefits={currentSBCData.benefits}
                faqs={currentSBCData.faqs}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
      <FooterActions
        continueHandler={handleContinue}
        backHandler={handleBack}
        continueDisabled={selectedList.length <= 0 && !isTesting}
      />
    </>
  )
}
