import { useContext } from 'react'
import { createContext } from 'react'
import { useQuery } from 'react-query'
import { groupService } from '../services'

// add values to group context as we need them

interface ExploConfig {
  allowExploDashboards: boolean | null
  allowExploReports: boolean | null
  exploId: string | null
  exploGroupToken: string | null
}

export interface IGroupContext {
  exploConfig: ExploConfig
  isLoading: boolean
}
export const GroupContext = createContext<IGroupContext>({
  exploConfig: {
    allowExploDashboards: null,
    allowExploReports: null,
    exploId: null,
    exploGroupToken: null
  },
  isLoading: false
})

export const GroupContextProvider: React.FC = ({ children }) => {
  const { isLoading, data } = useQuery('getGroup', () => groupService.getInfo())

  const exploConfig: ExploConfig = {
    allowExploDashboards: data?.allow_explo_dashboards || false,
    allowExploReports: data?.allow_explo_reports || false,
    exploId: data?.explo_id || null,
    exploGroupToken: data?.explo_group_token || null
  }

  return (
    <GroupContext.Provider
      value={{
        exploConfig,
        isLoading
      }}
    >
      {children}
    </GroupContext.Provider>
  )
}

export const useExploConfig = () => {
  const { exploConfig, isLoading } = useContext(GroupContext)
  return { ...exploConfig, isLoading }
}
