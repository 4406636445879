import { Dispatch } from 'react'

import { AuthService } from '../services/api/auth'
import { AuthTypes } from './types'

const login = async (
  dispatch: Dispatch<AuthTypes>,
  username: string,
  password: string
) =>
  new AuthService()
    .login(username, password)
    .then((response) =>
      dispatch({
        type: 'SET_USER_ACCOUNT',
        user: response,
        fetching: false
      })
    )
    .catch((err) => console.error(err))

export const authActions = {
  login
}
