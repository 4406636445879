import { IPaginationProps } from '../Pagination'

interface IPageItem {
  page: number
  isCurrent: boolean
  class: string
  text: string
}

export const getPagination = (props: IPaginationProps): IPageItem[] => {
  let arr: IPageItem[] = []
  const showMax = props.showMax || 5
  let startAt = props.currentPage - Math.floor(showMax / 2)
  const isPositive = () => Math.sign(startAt)
  if (isPositive() !== 1) startAt = 1
  const max = showMax > props.totalPages ? props.totalPages : showMax

  for (let i = 0; i < max; i++) {
    if (startAt + i > props.totalPages) continue
    arr.push({
      page: startAt + i,
      text: String(startAt + i),
      isCurrent: isCurrent(startAt + i, props.currentPage),
      class: isCurrent(startAt + i, props.currentPage)
        ? props.activeClass
        : props.defaultClass
    })
  }

  if (props.prevNext) {
    arr = addNext(arr, props)
    arr = addPrev(arr, props)
  }

  return arr
}

const isCurrent = (page: number, currentPage: number) => currentPage === page

interface IAddPageProps {
  totalPages: number
  disabledClass?: string
  currentPage: number
  nextText?: string
  prevText?: string
}

const addNext = (arr, props: IAddPageProps) => {
  const nextObj = {
    page: props.currentPage + 1,
    text: props.nextText,
    isCurrent: false,
    class: props.currentPage + 1 > props.totalPages && props.disabledClass
  }
  arr.push(nextObj)
  return arr
}

const addPrev = (arr: IPageItem[], props) => {
  const prevObj = {
    page: props.currentPage - 1,
    text: props.prevText,
    isCurrent: false,
    class: props.currentPage - 1 < 1 && props.disabledClass
  }
  arr.unshift(prevObj)
  return arr
}
