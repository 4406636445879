import React from 'react'
import styled from 'styled-components'

const BadgeContainer = styled.div<{variant: string}>`
  align-items: center;
  background-color: ${(props) => `var(--${props.variant})`};
  border: 1.45px solid var(--white);
  border-radius: 50%;
  color: var(--white);
  display: flex;
  font-size: 10px;
  font-weight: bold;
  height: 16px;
  justify-content: center;
  width: 16px;
`

export interface ICircularBadgeProps {
  children: React.ReactNode
  variant?: string
  [x: string]: any
}

export const CircularBadge: React.FC<ICircularBadgeProps> = ({
  children,
  variant = 'primary',
  ...p
}) => {
  return (
    <BadgeContainer variant={variant} {...p}>
      {children}
    </BadgeContainer>
  )
}

export default CircularBadge
