import React, { useContext } from 'react'
import { CenteredSpinner, Col, Steps } from 'react-angle-dashboard-components'
import {
  Switch,
  Route,
  BrowserRouter as Router
} from 'react-router-dom'
import { groupService } from '../../../services'
import { BusinessOnboardingContext } from '../context/BusinessOnboardingContext'
import {
  AdminInformation,
  Billing,
  BusinessInformation,
  CarrierInformation,
  EmployerPreferences,
  DisclosureForm,
  EmployerContributionStrategy,
  PlanSelection,
  Producer,
  Complete
} from '../steps'
import { businessOnboardingPaths } from './paths'
import { useQuery } from 'react-query'
import {
  getSharedSteps,
  getFrontingSteps,
  getNonFrontingSteps
} from '../utils/getSteps'

const mapper = (data: any) => {
  return {
    renewal: !!data?.in_renewal,
    isFronting: data?.insurer_type === 'fronting_arrangement',
    isLevelFunded: data?.funding_type === 'level_funded'
  }
}

export const BusinessOnboardingRouter = () => {
  const { state: currentStep } = useContext(BusinessOnboardingContext)

  const { data, isLoading } = useQuery(
    'getOnboardingInfo',
    () => groupService.getInfo(mapper, true),
    {
      refetchOnWindowFocus: false
    }
  )

  const sharedSteps = getSharedSteps(currentStep)

  // some step numbers differ based on insurer type and renewal status
  const frontingSteps = [
    ...sharedSteps,
    ...getFrontingSteps(currentStep, !!data?.renewal)
  ]

  const nonFrontingSteps = [
    ...sharedSteps,
    ...getNonFrontingSteps(currentStep, !!data?.renewal)
  ]

  const unfilteredSteps = data?.isFronting ? frontingSteps : nonFrontingSteps

  const steps = unfilteredSteps.filter((step) =>
    data?.renewal ? step.title !== 'Carrier Information' : true
  )

  return (
    <Router>
      <Col xs={12} lg={4} className="mb-2">
        {isLoading ? (
          <CenteredSpinner />
        ) : (
          <Steps
            currentStep={currentStep}
            steps={steps}
            title="Summary"
          ></Steps>
        )}
      </Col>
      <Col
        style={{
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Switch>
          <Route
            exact
            path={[businessOnboardingPaths.base, businessOnboardingPaths.businessInformation]}
            component={BusinessInformation}
          />

          <Route
            path={businessOnboardingPaths.adminInformation}
            component={AdminInformation}
          />

          <Route
            path={businessOnboardingPaths.employerPreferences}
            component={EmployerPreferences}
          />
          {data?.isFronting && (
            <Route
              path={businessOnboardingPaths.disclosure}
              component={DisclosureForm}
            />
          )}

          <Route
            path={businessOnboardingPaths.contributionStrategy}
            component={EmployerContributionStrategy}
          />
          <Route
            path={businessOnboardingPaths.plan}
            render={(props) => (
              <PlanSelection {...props} renewal={!!data?.renewal} />
            )}
          />
          {!data?.renewal && (
            <Route
              path={businessOnboardingPaths.carrierInformation}
              component={CarrierInformation}
            />
          )}

          <Route
            path={businessOnboardingPaths.billing}
            render={(props) => <Billing {...props} renewal={!!data?.renewal} />}
          />

          <Route path={businessOnboardingPaths.producer} component={Producer} />

          <Route path={businessOnboardingPaths.complete} component={Complete} />

        </Switch>
      </Col>
    </Router>
  )
}
