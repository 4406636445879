import { Row } from 'react-angle-dashboard-components'
import styled from 'styled-components'

export const TitleRow = styled(Row)`
  margin-bottom: 1.25rem;
`

export const FormSectionRow = styled(Row)`
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--card-border);
`

export const ValidationError = styled.p`
  color: var(--primary);
  margin-top: 5px;
`
