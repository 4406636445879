import * as React from 'react'
import classnames from 'classnames'
import { Button as BSButton } from 'react-bootstrap'

export interface IButtonProps {
  children: React.ReactNode
  ghost?: boolean
  soft?: boolean
  border?: boolean
  [x: string]: any
}

export const Button = (props) => {
  const { ghost = false, soft = false, children, border = false, ...p } = props

  const classNames = classnames(p.className, {
    [`btn-ghost-${p.variant}`]: ghost,
    [`btn-soft-${p.variant}`]: soft,
    'border': border
  })

  return (
    <BSButton {...p} className={classNames}>
      {children}
    </BSButton>
  )
}

export default Button
