import React from 'react'
import {
  CenteredSpinner,
  Container,
  Nav,
  Tab
} from 'react-angle-dashboard-components'
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
  Switch,
  Redirect
} from 'react-router-dom'
import { useExploConfig } from '../../context/Group'
import { PageLayout } from '../layouts/PageLayout'
import Dashboard from './Dashboard'
import Reports from './Reports/Reports'
import { Paths } from '../../routes/paths'

export const Claims: React.FC = () => {
  const history = useHistory()

  const { allowExploDashboards, allowExploReports, isLoading } =
    useExploConfig()

  if (allowExploDashboards === false && isLoading !== true) {
    history.push(Paths.notFound)
  }
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const key = pathname.slice(`${path}/`.length)

  const onSelect = (k: any) => {
    history.push(`${path}/${k}`)
  }
  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Claims', link: '/claims' }
      ]}
    >
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <Tab.Container
          id="document-tabs-container"
          activeKey={key}
          onSelect={onSelect}
        >
          <section className="bg-light">
            <Container className="mt-5 pt-3 px-5 mx-auto">
              <h1>Claims</h1>

              <Nav variant="tabs" className="mt-5">
                <Nav.Item className="mr-3">
                  <Nav.Link eventKey="dashboard">Dashboard</Nav.Link>
                </Nav.Item>
                {allowExploReports === true && (
                  <Nav.Item className="mr-3">
                    <Nav.Link eventKey="reports">Reports</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Container>
          </section>
          <Container className="mt-5 mx-auto px-5 pb-5">
            <Switch>
              <Route exact path={`${path}/dashboard`} component={Dashboard} />
              {allowExploReports === true && (
                <Route exact path={`${path}/reports`} component={Reports} />
              )}
              <Redirect to={`${path}/dashboard`} />
            </Switch>
          </Container>
        </Tab.Container>
      )}
    </PageLayout>
  )
}

export default Claims
