import React from 'react'
import styled from 'styled-components'
import { Badge, Card } from 'react-bootstrap'

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
`

const StatusBadge = styled(Badge)`
  height: 18px;
  padding: 3px 4px;
  font-weight: 500;
  color: var(--white);
`

const LocationText = styled.p`
  margin-bottom: 0;
`

export interface ICareHours {
  hours: string
  label: string
}
export interface IFacilityInformationProps {
  isOpen: boolean
  location: string
  urgentCareHours: ICareHours[]
  [x: string]: any
}

export const FacilityInformation: React.FC<IFacilityInformationProps> = ({
  isOpen,
  urgentCareHours = [],
  location,
  ...props
}) => {
  const badgeVariant = isOpen ? 'primary' : 'warning'
  const badgeText = isOpen ? 'Open Now' : 'Closed'

  return (
    <Card className="p-3" {...props}>
      {urgentCareHours.length ? (
        <InfoRow style={{ marginBottom: '27px' }}>
          <strong>Urgent Care Hours</strong>
          <StatusBadge className="fs-12" variant={badgeVariant}>
            {badgeText}
          </StatusBadge>
        </InfoRow>
      ) : null}
      {urgentCareHours.map((ch) => (
        <div key={ch.label}>
          <InfoRow>
            <h6>{ch.label}</h6>
            <h6 className="text-muted">{ch.hours}</h6>
          </InfoRow>
          <hr />
        </div>
      ))}
      <strong style={{ marginBottom: '20px', display: 'block' }}>
        Location
      </strong>
      <LocationText className="fs-18 text-capitalize">{location}</LocationText>
    </Card>
  )
}

export default FacilityInformation
