import axios from 'axios'

export type IRequestMethod = 'get' | 'post' | 'put' | 'patch' | 'delete'

export interface IRequest {
  method: IRequestMethod
  url: string
  data?: any
  credentials?: boolean
  headers?: { [x: string]: any }
  auth?: {
    username: string
    password: string
  }
  params?: { [x: string]: any }
  responseType?: 'arraybuffer' | 'document' | 'json' | 'text' | 'stream'
}

export class APIError extends Error {
  public data

  public status

  constructor(message: string, data: any, status: number = 0) {
    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError)
    }

    this.data = data
    this.status = status
  }
}

/**
 * Base API Service is for handling all api requests, also
 * responses and errors from that requests
 *
 *
 * All other services in /services/api folder will extend from ApiService
 */
export class ApiService {
  static _token: string | null
  _baseApiUrl: string | null
  static onError: (error: any) => void

  constructor(baseApiUrl: string | null = null, token: string | null = null) {
    this._baseApiUrl = baseApiUrl
    ApiService._token = token
  }

  get token(): string {
    return ApiService._token as string
  }

  set token(token: string) {
    ApiService._token = token
  }

  get baseApiUrl(): string {
    return this._baseApiUrl as string
  }

  set baseApiUrl(baseApiUrl: string) {
    this._baseApiUrl = baseApiUrl
  }

  handleResponse(response: any) {
    return response?.data || {}
  }

  handleError(error: any) {
    if (error.response?.status === 422) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.detail ||
        typeof error.response?.data === 'object'
          ? error.response.data[Object.keys(error.response.data)[0]]?.response
              ?.detail ||
            error.response.data[Object.keys(error.response.data)[0]]?.response
          : 'An unknown error has occurred'
      throw new APIError(
        message,
        error.response?.data?.data,
        error.response?.status || 500
      )
    } else if (error.response?.status !== 401) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.detail ||
        'An unknown error has occurred'
      throw new APIError(
        message,
        error.response?.data?.data,
        error.response?.status || 500
      )
    } else {
      throw new APIError(
        'Your session has expired',
        error.response?.data?.data,
        401
      )
    }
  }

  request = async ({
    method,
    url,
    params = {},
    data = {},
    auth,
    credentials = true,
    headers = {},
    responseType = 'json'
  }: IRequest) => {
    const baseURL = this.getBaseServiceURL()
    const header = credentials ? this.getCredentialHeader() : {}
    try {
      const response = await axios({
        baseURL,
        method,
        url,
        params,
        data,
        headers: {
          ...{ 'Content-Type': 'application/json' },
          ...headers,
          ...header
        },
        auth,
        responseType
      })

      return this.handleResponse(response)
    } catch (e) {
      if (ApiService.onError) {
        ApiService.onError(e)
      }
      this.handleError(e)
    }
  }

  getCredentialHeader(): { Authorization: string } {
    return {
      Authorization: `Bearer ${this.token}`
    }
  }

  getBaseServiceURL(): string {
    return this.baseApiUrl
  }
}
