import React from 'react'
import {
  Modal,
  Row,
  Col,
  Avatar,
  Button
} from 'react-angle-dashboard-components'
import { PlaidResponse } from '../../pages/Settings/BillingDetails/BillingDetails'
import { PlaidButton } from '../PlaidButton'

interface AddBankAccountModalProps {
  show: boolean
  toggleModal: Function
  publicToken: string
  successCallback?: (token: string, metadata: PlaidResponse) => void
  sessionExpiredCallback: Function
  manuallyAddCallback?: Function
}

export const AddBankAccountModal: React.FC<AddBankAccountModalProps> = ({
  show,
  toggleModal,
  publicToken,
  successCallback,
  sessionExpiredCallback,
  manuallyAddCallback = () => {}
}) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      show={show}
      onHide={toggleModal}
    >
      <Modal.Header className="pt-4 pb-2" closeButton>
        <Modal.Title>How do you want to proceed?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-2">
        {publicToken && (
          <Row className="pt-3 border-top pb-3">
            <Col className="pr-1 text-center" xs={2} sm={1}>
              <Avatar
                circle
                fallbackContent={<i className="text-white icon icon-bank"></i>}
              />
            </Col>
            <Col className="d-flex align-items-center">
              Link a bank account instantly via Plaid (Recommended)
            </Col>

            <Col xs={2}>
              <PlaidButton
                token={publicToken}
                onSuccess={successCallback}
                onSessionExpired={() => sessionExpiredCallback(false)}
              >
                Select
              </PlaidButton>
            </Col>
          </Row>
        )}
        <Row className="pt-3 border-top pb-3">
          <Col className="pr-1 text-center" xs={2} sm={1}>
            <Avatar
              circle
              fallbackContent={<i className="text-white icon icon-bank"></i>}
            />
          </Col>
          <Col className="d-flex align-items-center">
            Manually add a bank account
          </Col>

          <Col xs={2}>
            <Button onClick={manuallyAddCallback}>Select</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
