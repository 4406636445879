import React, { ReactNode } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export interface ILoginLayout {
  image?: string | ReactNode
  [x: string]: any
}

export const LoginLayout: React.FC<ILoginLayout> = ({
  children,
  image = null
}) => (
  <Container fluid className="px-0 vh-100">
    <Row className="mx-0 px-0">
      <Col className="bg-primary vh-100 d-none d-lg-block" sm={6}>
        &nbsp;
      </Col>
      <Col
        sm={6}
        className="vh-100 m-auto d-flex flex-column justify-content-center align-items-center"
      >
        {children}
      </Col>
    </Row>
  </Container>
)
