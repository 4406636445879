import React, { useReducer } from 'react'
import { Button } from '../Button/Button'
import { Carousel, ImagesCarousel } from './Carousel'
import { Modal } from 'react-bootstrap'
import {
  Image,
  GalleryContainer,
  GalleryButtonContainer,
  GridFigure
} from './Styles'
import {
  reducer,
  initialState,
  GalleryContext,
  actions,
  IGalleryImage
} from './context'

export interface IGallery {
  images: IGalleryImage[]
  galleryButtonText?: string
  scrollFrom?: number
  [x: string]: any
}

export const Gallery: React.FC<IGallery> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { show } = state
  const { images, scrollFrom, galleryButtonText = 'Show full gallery' } = props

  const toggleModal = () => dispatch({ type: actions.TOGGLE_MODAL })
  const handleSelect = (selectedIndex: number) =>
    dispatch({ type: actions.SET_CAROUSEL_INDEX, payload: selectedIndex })

  if (!images || !images.length) return <small>The gallery is empty</small>

  const [main, secondary, ternanry] = images

  return (
    <GalleryContext.Provider
      value={{
        ...state,
        toggleModal,
        handleSelect,
        images,
        scrollFrom
      }}
    >
      <div className="d-block d-sm-none">
        <ImagesCarousel />
      </div>
      <section className="d-none d-sm-block">
        <Modal
          className="modal-container full-map-modal"
          show={show}
          onHide={toggleModal}
        >
          <Carousel />
        </Modal>
        <GalleryContainer id="gallery-container">
          <GalleryButtonContainer>
            <Button
              className="fs-14"
              size="sm"
              variant="light"
              onClick={toggleModal}
            >
              {galleryButtonText}
            </Button>
          </GalleryButtonContainer>
          <GridFigure type="main">
            <Image src={main.src} alt={main.src} />
          </GridFigure>

          <GridFigure type="secondary">
            <Image src={secondary.src} alt={secondary.src} />
          </GridFigure>

          <GridFigure type="tertiary">
            <Image src={ternanry.src} alt={ternanry.src} />
          </GridFigure>
        </GalleryContainer>
      </section>
    </GalleryContext.Provider>
  )
}

export default Gallery
