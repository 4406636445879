import React from 'react'
import {
  CenteredSpinner,
  InfoCard,
  InfoRow,
  useErrorHandler
} from '../../../index'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

export const BillingSettings: React.FC<{
  groupService: any
  billingService: any
  isBrokerPortal: boolean
}> = ({ groupService, billingService, isBrokerPortal }) => {
  const { groupId } = useParams<{ groupId: string }>()

  const {
    data: settings,
    isLoading,
    error
  } = useQuery(['settings', groupId], () => {
    const queryFn = isBrokerPortal
      ? groupService.getBillingGeneralData(groupId)
      : billingService.getBillingGeneralData()
    return queryFn
  })

  const {
    data: defaultAccount,
    error: accountError,
    isLoading: accountLoading
  } = useQuery(['defaultAccount', groupId], () => {
    return billingService.getDefaultAccountName(isBrokerPortal ? groupId : '')
  })

  useErrorHandler(error)

  return isLoading ? (
    <CenteredSpinner />
  ) : (
    <>
      <InfoCard title="Bank account information" topTranslation={20}>
        <InfoRow borderBottom>
          <h6>Autopay</h6>
          <h6 className="text-muted">{settings?.autopay ? 'ON' : 'OFF'}</h6>
        </InfoRow>
        <InfoRow>
          <h6>Account</h6>
          <h6 className="text-muted">
            {accountError ? (
              <small className="text-danger">
                Error, trying to get your default account
              </small>
            ) : accountLoading ? (
              '...Loading'
            ) : (
              defaultAccount?.mask || 'No default account'
            )}
          </h6>
        </InfoRow>
      </InfoCard>
    </>
  )
}
