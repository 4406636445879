import React, { useEffect, useState } from 'react'
import {
  Button,
  Row,
  Col,
  Container,
  Modal,
  PageHeaderLight,
  PageContainer,
  CenteredSpinner,
  SummaryService
} from '../../../index'
import { useQuery } from 'react-query'
import { Link, useHistory, useParams } from 'react-router-dom'
import InfoCard from './components/InfoCard/InfoCard'
import { SectionTitle, ListItem } from './style'
import { UnableToLoad } from './components/UnableToLoad'
import { SummaryLabelValue } from './components/SummaryLabelValue'


export const EmployerPortalSummary: React.FC<{
  isBrokerPortal?: boolean
  summaryService: SummaryService
}> = ({ isBrokerPortal, summaryService }) => {
  const history = useHistory()
  const { groupId } = useParams<{ groupId: string }>()
  const [showTPATrueUpModal, setShowTPATrueUpModal] = useState<boolean>(
    true
  )

  const employeesUrl = isBrokerPortal
    ? `/groups/${groupId}/employees`
    : '/employees'

  const queryKey = isBrokerPortal ? ['summary'] : ['summary', groupId]

  const {
    data: summaryData,
    isLoading,
    isError
  } = useQuery(
    queryKey,
    () => summaryService.getSummary(isBrokerPortal ? groupId : ''),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  )

  useEffect(() => {
    if (showTPATrueUpModal && summaryData && !summaryData.tpaTrueUp) {
      setShowTPATrueUpModal(false)
    }
  }, [summaryData, showTPATrueUpModal])

  const labels: Record<string, any> = {
    companyName: 'Company Name',
    groupID: 'Group ID',
    coverageStart: 'Coverage start date',
    coverageEnd: 'Coverage end date',
    brokers: 'Brokers on account',
    newHireWaitingPeriod: 'Waiting Period for New Hires'
  }

  const markTPAModalAsSeen = () => {
    if (summaryData?.groupID) {
      summaryService.unsetTpaTrueUp(summaryData?.groupID)
      setShowTPATrueUpModal(false)
    }
  }

  const getTPATrueUpModal = () => {

    const paragraphs = [
      "Plan Administrator,",
      "We are writing to inform you of an administrative error that has been identified regarding the allocation of expenses on certain Angle Health invoices and proposals. Upon review, it has come to our attention that some proposals contained a misallocation of expenses among the claims fund, stop loss premium, and administrative expenses. Although this misallocation had no net impact upon the amount your group was billed, we apologize for any confusion this may have caused.",
      "Future invoices will reflect the corrected distribution. Again, this will not affect the total cost to your group, it will only reallocate the expenses to their appropriate categories.",
      "We appreciate your understanding and cooperation. Should you have any questions or require further clarification, please do not hesitate to contact us.",
      "Sincerely,"
    ]

    return (
      <Modal
        show={showTPATrueUpModal}
        centered
        onHide={() => { markTPAModalAsSeen() }}
        dialogClassName="tpaTrueUpModalDialog"
        className="tpaTrueUpModal"
        style={{
          zIndex: 2000
        }}
      >
        <Modal.Header className="border-bottom" style={{ paddingBottom: 0, paddingTop: 0, paddingRight: '5px' }} closeButton />
        <Row style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '1.5rem',
          marginRight: '1.5rem',
          marginTop: '1.5rem' }}>
        {paragraphs.map((text, index) => (
          <p className="text-muted" style={{ marginBottom: '1.5rem' }} key={`tpa-${index}`}>{text}</p>
          ))}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
          }}>
            <p className="text-muted" style={{ marginBottom: '1.5rem' }} key={'tpa-end'}>Angle Health</p>
          </div>
        </Row>
      </Modal>
    )
  }

  if (isError) {
    return (
      <Container className="d-flex flex-column justify-content-center h-100">
        <UnableToLoad />
      </Container>
    )
  }

  return (
    <>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <PageHeaderLight
            style={{ marginBottom: '2.8rem', alignItems: 'flex-start' }}
          >
            <PageContainer size="lg">
              <h1>Welcome back!</h1>
            </PageContainer>
          </PageHeaderLight>
          <PageContainer size="lg">
            {getTPATrueUpModal()}
            <Row>
              <Col>
                {summaryData?.inRenewal === true &&
                  summaryData?.onboardingStatus !== 'completed' &&
                  summaryData?.onboardingStatus !== 'under_review' &&
                  !isBrokerPortal && (
                    <>
                      <SectionTitle>Updates</SectionTitle>
                      <InfoCard
                        title="Your Business Onboarding Is Open"
                        subtitle={
                          <p>
                            Your renewal business onboarding is ready for you.
                            Select your plans for the upcoming plan year!
                          </p>
                        }
                        footer={
                          <Button
                            onClick={() => history.push('/business/onboarding')}
                          >
                            Start onboarding
                          </Button>
                        }
                        className="mb-5"
                      />
                    </>
                  )}

                <Row style={{ marginBottom: '2.5rem' }}>
                  <Col md={6} xs={12}>
                    <Link to={employeesUrl}>
                      <InfoCard
                        title="Total Premium Members"
                        subtitle={<h2>{summaryData?.employeeCount}</h2>}
                        footer={<small className="text-muted">Members</small>}
                        className="my-3 my-md-0"
                      />
                    </Link>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '1.25rem' }}>
                  <Col>
                    <SectionTitle style={{ marginBottom: '1rem' }}>
                      Account information
                    </SectionTitle>
                    {Object.keys(labels).map((key: string) => {
                      if (
                        ['brokers', 'generalAgency'].includes(key) &&
                        !(summaryData as any)[key]
                      ) {
                        return null
                      }
                      return (
                        <ListItem key={key}>
                          <p className="text-muted">{labels[key]}</p>
                          <p>
                            <SummaryLabelValue
                              summary={summaryData}
                              label={key}
                            />
                          </p>
                        </ListItem>
                      )
                    })}
                  </Col>
                </Row>
              </Col>
            </Row>
          </PageContainer>
        </>
      )}
    </>
  )
}
