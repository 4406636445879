import React, { useContext } from 'react'
import {
  Accordion,
  Card,
  Nav,
  Navbar,
  Row,
  Col,
  AccordionContext
} from 'react-bootstrap'
import { FlexText } from './styles'

export const CaretIcon = () => {
  const currentEventKey = useContext(AccordionContext)
  return (
    <i
      className="icon fs-16 icon-dropdown"
      style={currentEventKey == '0' ? { transform: 'rotate(180deg)' } : {}}
    />
  )
}

export const ExpandibleNavItem: React.FC<any> = ({
  title,
  items,
  ...props
}) => (
  <Accordion key={title}>
    <Accordion.Toggle as="div" eventKey="0" {...props}>
      <Row className="w-100">
        <Col>
          <FlexText>{title}</FlexText>
        </Col>
        <Col className="text-right">
          <CaretIcon />
        </Col>
      </Row>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <div>
        {items.map((dropdown, k) => (
          <Row className="py-3" onClick={dropdown.onClick} key={k}>
            <Col xs="2">
              <i className={`icon fs-28 text-primary icon-${dropdown.icon}`} />
            </Col>
            <Col xs="10">
              <div className="fs-18">{dropdown.title}</div>
              <small className="text-muted">{dropdown.subtitle}</small>
            </Col>
          </Row>
        ))}
      </div>
    </Accordion.Collapse>
  </Accordion>
)
