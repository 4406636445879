import React, { useRef, useState, useEffect } from 'react'
import { Button } from "../Button"
import { IDCard } from '../IDCard/IDCard'
import { CenteredSpinner } from '../CenteredSpinner'
import { Container, Card } from 'react-bootstrap'
import ReactToPrint from 'react-to-print'
import { getCoverage } from '@angle/utils'
import { ClarityCard, type ClarityCardData } from './ClarityCard'
import { CardRequester } from './CardRequester'
import { EmployeeService, MemberService } from '../../services'
import { Col } from '../../'

export const IDCardContainer: React.FC<{
  isLoading: boolean
  data: any
  isAdmin: boolean
  employeeService: EmployeeService | MemberService
  refetchMemberData: () => {}
  isRefetching: boolean
}> = ({
  isLoading,
  data,
  isAdmin,
  employeeService,
  refetchMemberData,
  isRefetching
}) => {
  const cardRef = useRef<any>()
  const [clarityCardData, setClarityCardData] = useState<ClarityCardData>(null)
  const planCoverage = getCoverage(data)

  useEffect(() => {
    if (data?.clarity_card_information) {
      setClarityCardData(data.clarity_card_information)
    }
  }, [isLoading, data])

  return (
    <Container>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          {data?.id_card_requests?.length > 0 && clarityCardData !== null  && (
            <div className="my-2 py-2" style={{ maxWidth: 750, maxHeight: 400, overflow: 'auto', margin: 'auto' }}>
              <CardRequester
                memberId={data.member_id}
                isAdmin={isAdmin}
                cardRequests={data?.id_card_requests}
                employeeService={employeeService}
                refetchMemberData={refetchMemberData}
                isRefetching={isRefetching}
              />
            </div>
          )}
          <div>
            {planCoverage ? (
              <>
                {clarityCardData ? (
                  <ClarityCard clarityCardData={clarityCardData} />
                ) : (
                  <div>
                    <div ref={cardRef}>
                      <IDCard data={data} />
                    </div>
                    <ReactToPrint
                      trigger={() => (
                        <div className="text-center mt-4">
                          <Button>Print card</Button>
                        </div>
                      )}
                      content={() => cardRef.current}
                    />
                  </div>
                )}
              </>
            ) : (
              <Card>
                <Card.Body className="text-center">
                  This member does not currently have coverage.
                </Card.Body>
              </Card>
            )}
          </div>
        </>
      )}
    </Container>
  )
}
