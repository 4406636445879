import React from 'react'
import { dashboardToken } from './exploConfig'
import { useExploConfig } from '../../context/Group'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': unknown
    }
  }
}

export const Dashboard = () => {
  const { exploGroupToken, exploId } = useExploConfig()
  return (
    <div>
      <explo-dashboard
        dash-user-group-token={`${dashboardToken}:${exploGroupToken}`}
        updateUrlParams={true}
        variables={JSON.stringify({
          varExplo: exploId
        })}
      />
    </div>
  )
}

export default Dashboard
