import classNames from 'classnames'
import React from 'react'
import { Dropdown, DropdownProps } from 'react-bootstrap'

import { Button } from '../Button/Button'
import { DropdownToggle } from './style'

export interface IFilterContainerProps {
  name: string
  onToggle: DropdownProps['onToggle']
  children: React.ReactNode
  showDropdown: boolean
  apply: Function
  clear?: Function
  appliedFilters: boolean
  applyDisabled?: boolean
  dropdownDisabled?: boolean
  [x: string]: any
}

export const FilterContainer: React.FC<IFilterContainerProps> = ({
  name,
  apply,
  clear,
  onToggle,
  children,
  showDropdown,
  appliedFilters,
  applyDisabled = false,
  dropdownDisabled = false,
  ...props
}) => {
  const classnames = classNames(
    'fs-16',
    {
      'dropdown-active': appliedFilters
    },
    'border-primary'
  )

  return (
    <Dropdown onToggle={onToggle} show={showDropdown} {...props}>
      <DropdownToggle
        variant="input"
        className={classnames}
        disabled={dropdownDisabled}
      >
        {name}
      </DropdownToggle>

      <Dropdown.Menu
        className="shadow-sm"
        style={{ minWidth: '290px' }}
      >
        <div className="px-2" style={{ maxHeight: '200px', overflow: 'auto' }}>
          {children}
        </div>
        <Dropdown.Divider />
        <div className="d-flex justify-content-end px-2">
          {clear && (
            <Button variant="link" size="sm" onClick={clear}>
              Clear
            </Button>
          )}
          <Button
            disabled={applyDisabled}
            variant="primary"
            onClick={apply}
          >
            { props.applyButtonLabel || 'Apply' }
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default FilterContainer
