import React, { useState, useEffect } from 'react'
import { Col, Row, Form, useToaster } from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { TitleRow } from '../../style'
import { Controller, FormState, Control } from 'react-hook-form'
import { businessOnboardingPaths } from '../../../router/paths'
import { FooterActions } from '../../FooterActions'
import { CarrierData, CarrierFormData } from '../../../interfaces'
import { mapLargeGroupCarrierInformationToDTO } from '../mappers'
import { PriorCarriers, CurrentCarrier, OtherInsurance, LessThanThree } from '.'

interface LevelFundedCarrierInfoProps {
  data: CarrierData
  formState: FormState<CarrierFormData>
  control: Control
  handleSubmit: any
  modifyGroup: any
  watch: any
  unregister: any
  setValue: any
}

export const LevelFundedGroupCarrierInformation: React.FC<
  LevelFundedCarrierInfoProps
> = ({
  formState,
  control,
  data,
  handleSubmit,
  modifyGroup,
  watch,
  unregister,
  setValue
}) => {
  const history = useHistory()
  const toaster = useToaster()

  const [hasPreviousCarrier, setHasPreviousCarrier] = useState('false')
  const [otherCarriers, setOtherCarriers] = useState<string[]>([''])
  const [otherInsurance, setOtherInsurance] = useState(data?.otherInsurance)
  const [hasCurrentCarrier, setHasCurrentCarrier] = useState(
    !!data?.hasCurrentCarrier
  )
  const [lessThanThree, setLessThanThree] = useState<'true' | 'false'>(
    data?.lessThanThree
  )

  useEffect(() => {
    setOtherCarriers(data?.otherCarrierNames)
    setHasCurrentCarrier(!!data?.hasCurrentCarrier)
  }, [data])

  useEffect(() => {
    setHasPreviousCarrier(data?.hasPreviousCarrier)
    setLessThanThree(data?.lessThanThree)
    setOtherInsurance(data?.otherInsurance)
  }, [data, setHasPreviousCarrier])

  useEffect(() => {
    if (lessThanThree === 'false') {
      unregister('previousCarrier')
      unregister('previousCarrierStartDate')
      unregister('previousCarrierEndDate')
      unregister('previousChangingReason')
      unregister('otherPreviousChangingReason')
    } else {
      setValue('hasPreviousCarrier', data?.hasPreviousCarrier || 'false')
      setHasPreviousCarrier(data?.hasPreviousCarrier || 'false')
    }
  }, [lessThanThree, unregister, setValue, data?.hasPreviousCarrier])

  useEffect(() => {
    if (hasCurrentCarrier === false) {
      unregister('currentCarrier')
      unregister('currentCarrierStartDate')
      unregister('currentCarrierEndDate')
      unregister('lessThanThree')
    }
  }, [hasCurrentCarrier, unregister])

  const handleContinue = handleSubmit(async (formData: CarrierFormData) => {
    const dto = mapLargeGroupCarrierInformationToDTO(
      formData,
      otherCarriers,
      data.stepNumber,
      data.isFronting,
    )
    try {
      await modifyGroup.mutateAsync(dto)
      history.push(businessOnboardingPaths.billing)
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  const handleBack = () => {
    history.push(businessOnboardingPaths.plan)
  }

  return (
    <>
      <Form>
        <TitleRow noGutters>
          <b>Carrier Information</b>
        </TitleRow>
        <Row>
          <Form.Group className="w-100">
            <Col>
              <Controller
                name="currentCarrierToggle"
                control={control}
                render={({ field }) => (
                  <Form.Check
                    {...field}
                    type="checkbox"
                    className="custom-checkbox"
                    label="No Current Carrier"
                    checked={!hasCurrentCarrier}
                    onChange={(e) => {
                      field.onChange(e.target.checked)
                      setHasCurrentCarrier(!hasCurrentCarrier)
                    }}
                  />
                )}
              />
            </Col>
          </Form.Group>
        </Row>
        {hasCurrentCarrier && (
          <CurrentCarrier
            control={control}
            formState={formState}
            watch={watch}
          />
        )}
        {hasCurrentCarrier && (
          <LessThanThree
            control={control}
            formState={formState}
            setLessThanThree={setLessThanThree}
          />
        )}
        {lessThanThree === 'true' && hasCurrentCarrier && (
          <PriorCarriers
            control={control}
            formState={formState}
            hasPreviousCarrier={hasPreviousCarrier}
            setHasPreviousCarrier={setHasPreviousCarrier}
            lessThanThree={lessThanThree}
            watch={watch}
          />
        )}
        {hasCurrentCarrier && (
          <OtherInsurance
            control={control}
            otherInsurance={otherInsurance}
            otherCarriers={otherCarriers}
            setOtherInsurance={setOtherInsurance}
            setOtherCarriers={setOtherCarriers}
          />
        )}
      </Form>
      <FooterActions
        continueHandler={handleContinue}
        backHandler={handleBack}
      />
    </>
  )
}
