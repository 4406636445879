import React, { useEffect, useState } from 'react'
import { Badge } from 'react-bootstrap'
import ItemPaginator from './ItemPaginator'
import { IMarkerItem } from './MapMarker'
import {
  TooltipContainer,
  FacilityTooltipImage,
  FacilityTooltipContent,
  TooltipFooter,
  FacilityTooltipImageFallback,
  EllipsisFacilityTitle
} from './style'

export interface IFacilityTooltipProps {
  currentIndex: number
  items: IMarkerItem[]
  setCurrentIndex: Function
}

export const FacilityTooltip = ({ currentIndex, items, setCurrentIndex }) => {
  const [imgError, setImgError] = useState(false)
  const currentItem = items[currentIndex]

  useEffect(() => {
    if (!currentItem.img) {
      setImgError(true)
    }
  }, [currentItem])

  return (
    <TooltipContainer
      className="shadow"
      style={{ height: '17rem', width: '16rem' }}
    >
      <ItemPaginator
        currentIndex={currentIndex}
        maxIndex={items.length - 1}
        setCurrentIndex={setCurrentIndex}
        showPageControls={items.length > 1}
      />
      {!imgError ? (
        <FacilityTooltipImage
          onError={() => setImgError(true)}
          src={currentItem.img}
        />
      ) : (
        <FacilityTooltipImageFallback>
          <i
            className="icon icon-facility text-primary"
            style={{ fontSize: '4rem' }}
          />
        </FacilityTooltipImageFallback>
      )}
      <FacilityTooltipContent>
        <EllipsisFacilityTitle>{currentItem.title}</EllipsisFacilityTitle>
        <p className="fs-12">{currentItem.text}</p>
        <TooltipFooter>
          <Badge
            variant={currentItem.badgeVariant}
            className="fs-12"
            style={{ fontWeight: 500 }}
          >
            {currentItem.badgeText}
          </Badge>
          <span className="text-muted">{currentItem.distance}</span>
        </TooltipFooter>
      </FacilityTooltipContent>
    </TooltipContainer>
  )
}

export default FacilityTooltip
