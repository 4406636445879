import React from 'react'
import { Spinner, SpinnerProps } from 'react-bootstrap'
import { SpinnerContainer } from './style'

interface ICenteredSpinnerProps {
  variant?: string
  animation?: SpinnerProps['animation']
  [x: string]: any
}

export const CenteredSpinner: React.FC<ICenteredSpinnerProps> = ({
  variant = 'primary',
  animation = 'border',
  ...p
}) => {
  return (
    <SpinnerContainer
      className="d-flex justify-content-center align-items-center"
      {...p}
    >
      <Spinner variant={variant} animation={animation} role="status" />
    </SpinnerContainer>
  )
}

export default CenteredSpinner
