import React from 'react'
import getStyles from './utils/getStyles'

interface IPageItemProps {
  page: number
  text: string
  buttonFontSize: number
  className: string
  onClick: Function
  activeBgColor?: string
  activeBorderColor?: string
  disabledBgColor?: string
  disabledBorderColor?: string
  bgColor?: string
  borderColor?: string
  activeColor?: string
  disabledColor?: string
  color?: string
}
export const PageItem: React.FC<IPageItemProps> = (props) => {
  const {
    page,
    text,
    buttonFontSize,
    className,
    onClick,
    activeBgColor,
    activeBorderColor,
    disabledBgColor,
    disabledBorderColor,
    bgColor,
    borderColor,
    activeColor,
    disabledColor,
    color
  } = props

  const styles = getStyles(
    {
      activeBgColor,
      activeBorderColor,
      disabledBgColor,
      disabledBorderColor,
      bgColor,
      borderColor,
      activeColor,
      color,
      disabledColor,
      buttonFontSize
    },
    className
  )
  return (
    <li className={`page-item ${className || ''}`}>
      <button
        style={styles}
        className="page-link"
        onClick={(e) => onClick && onClick(page, e)}
      >
        {text}
      </button>
    </li>
  )
}
