import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

export interface ICheckInput {
  placeholder?: string
  onChange: any
  id: string
  [x: string]: any
}

export const CheckInput = (props: ICheckInput) => {
  const [value, setValue] = useState('')
  const [checked, setChecked] = useState(false)

  const onInputChange = (e: any) => {
    const newValue = e.target.value
    setValue(newValue)
    props.onChange({
      target: {
        checked: checked,
        value: newValue,
        id: props.id
      }
    })
  }

  const onCheckChange = () => {
    setChecked((s) => !s)
    props.onChange({
      target: {
        checked: checked,
        value: value,
        id: props.id
      }
    })
  }

  return (
    <React.Fragment>
      <Form.Check
        {...props}
        className="my-2 d-inline"
        id={`check-input-custom-${props.id}`}
        onClick={onCheckChange}
        value={value}
      />
      <Form.Control
        style={{ minWidth: 150, width: '27%' }}
        size="sm"
        className="ml-2 d-inline"
        placeholder={props.placeholder}
        onChange={onInputChange}
      />
    </React.Fragment>
  )
}

export default CheckInput
