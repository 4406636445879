import React from 'react'
import { Col, DatePicker, Form, Row } from 'react-angle-dashboard-components'
import { Controller, Control, FormState } from 'react-hook-form'
import { getRequiredObject } from '../../../../../utils/formValidations'
import { CarrierFormData } from '../../../interfaces'
import { ValidationError } from '../../style'

const labels = {
  dentalCarrier: 'Current Dental Insurance Carrier',
  dentalStartDate: 'Start Date',
  dentalEndDate: 'Anticipated Termination Date'
}

interface DentalProps {
  control: Control
  formState: FormState<CarrierFormData>
  isRequired: boolean
}

export const Dental: React.FC<DentalProps> = ({
  control,
  formState,
  isRequired
}) => {
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="w-100">
            <Form.Label className="required-field">
              {labels.dentalCarrier}
            </Form.Label>
            <Controller
              name="dentalCarrier"
              rules={{
                required: isRequired
                  ? getRequiredObject(labels.dentalCarrier)
                  : false
              }}
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  placeholder="Dental Carrier"
                />
              )}
            />

            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.dentalCarrier?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.dentalStartDate}
            </Form.Label>
            <Controller
              name="dentalStartDate"
              rules={{
                required: isRequired
                  ? getRequiredObject(labels.dentalStartDate)
                  : false
              }}
              control={control}
              render={({ field }) => <DatePicker {...field} />}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.dentalStartDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.dentalEndDate}
            </Form.Label>
            <Controller
              name="dentalEndDate"
              rules={{
                required: isRequired
                  ? getRequiredObject(labels.dentalEndDate)
                  : false
              }}
              control={control}
              render={({ field }) => <DatePicker {...field} />}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.dentalEndDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
