import React from 'react'
import { InfoWithIcon } from '../InfoWithIcon'

export interface IEmptySearchProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string
}

export const EmptySearch: React.FC<IEmptySearchProps> = ({
  title,
  children,
  ...p
}) => {
  const iconEl = (
    <i
      className="icon icon-search fs-32"
      style={{ color: 'var(--gray-400)' }}
    />
  )
  return (
    <InfoWithIcon iconEl={iconEl} title={title} {...p}>
      {children}
    </InfoWithIcon>
  )
}

export default EmptySearch
