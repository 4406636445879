import React, { Dispatch, SetStateAction } from 'react'
import { Form } from '../../index'

// const medicalOptions = (
//   <>
//     <option value="payable">Payable</option>
//     <option value="denied">Denied</option>
//     <option value="denied_partial">Partially Denied</option>
//   </>
// )

const rxOptions = (
  <>
    <option value="P">Payable</option>
    <option value="A">Reversed</option>
  </>
)

export const ClaimFilters: React.FC<{
  setClaimStatusFilter: Dispatch<SetStateAction<string>>
  isRx: boolean
}> = ({ setClaimStatusFilter, isRx }) => {
  return (
    <div className="d-flex">
      {isRx && (
        <div className="d-flex align-items-center">
          <Form.Label className="text-nowrap mr-2 mt-1">
            Claim Status:
          </Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => setClaimStatusFilter(e.target.value)}
          >
            <option value="">All</option>
            {rxOptions}
          </Form.Control>
        </div>
      )}
    </div>
  )
}
