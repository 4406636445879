import React, { ReactNode } from 'react'
import { InputGroup } from '../..'

export const FormControlPrefixWrapper: React.FC<{ prefix: ReactNode }> = ({
  children,
  prefix
}) => {
  return (
    <InputGroup>
      <InputGroup.Text
        style={{
          borderRadius: '6px 0px 0px 6px',
          borderRight: 'none'
        }}
      >
        {prefix}
      </InputGroup.Text>
      {children}
    </InputGroup>
  )
}
