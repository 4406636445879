import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Button } from '../Button/Button'
import { DatePicker } from '../DatePicker'
import { Card, TimeButton, TimeButtonGroup } from './style'

export interface IOffice {
  id: string | number
  name: string
  times: ITime[]
}

export interface ITime {
  value: string
}

export interface ILabels {
  title: string
  office: string
  datePicker: string
  time: string
  submitButton: string
  linkButton: string
}

export interface IBookAppointmentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  selectedOfficeIndex: number
  setSelectedOfficeIndex: Function
  onSubmit?: any
  offices: IOffice[]
  labels?: Partial<ILabels>
}

export const BookAppointment: React.FC<IBookAppointmentProps> = ({
  onSubmit,
  offices = [],
  className = '',
  labels = {},
  selectedOfficeIndex,
  setSelectedOfficeIndex,
  ...p
}) => {
  const [selectedTime, setSelectedTime] = useState(0)
  const [selectedDate, setSelectedDate] = useState(null)
  const displayLabels: ILabels = {
    datePicker: 'Date Picker',
    title: 'Book an appointment',
    office: 'Office',
    linkButton: 'Call Provider',
    submitButton: 'Book an appointment',
    time: 'Time',
    ...labels
  }

  const currentOffice = offices[selectedOfficeIndex]

  const submitAction = () => {
    if (onSubmit) {
      onSubmit({
        office: currentOffice.id,
        time: selectedTime,
        date: selectedDate
      })
    }
  }

  return (
    <Card className={`p-3 ${className}`} {...p}>
      <strong className="mb-4">{displayLabels.title}</strong>
      <Form>
        <Form.Group>
          <Form.Label className="fs-16">{displayLabels.office}</Form.Label>
          <Form.Control
            as="select"
            className="fs-18"
            data-testid="offices-select"
            onChange={(e) => setSelectedOfficeIndex(+e.target.value)}
            value={selectedOfficeIndex}
          >
            {offices.map((o, index) => (
              <option key={index} value={index}>
                {o.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label className="fs-16">{displayLabels.datePicker}</Form.Label>
          <DatePicker onChange={(e: any) => setSelectedDate(e)} />
        </Form.Group>
        <Form.Group>
          <Form.Label className="fs-16">{displayLabels.time}</Form.Label>
          <TimeButtonGroup>
            {currentOffice?.times.map((t, index) => (
              <TimeButton
                data-testid={`button-time-${index}`}
                className="fs-14"
                selected={selectedTime === index}
                key={t.value}
                onClick={() => setSelectedTime(index)}
              >
                {t.value}
              </TimeButton>
            ))}
          </TimeButtonGroup>
        </Form.Group>
        <Button
          data-testid="submit-button"
          onClick={submitAction}
          block
          variant="primary"
          className="fs-18"
        >
          {displayLabels.submitButton}
        </Button>
        <hr />
        <Button block variant="link" border className="fs-18">
          {displayLabels.linkButton}
        </Button>
      </Form>
    </Card>
  )
}

export default BookAppointment
