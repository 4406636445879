import React from 'react'

export const WarningIcon = () => (
  <svg
    width="26"
    height="23"
    viewBox="0 0 26 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0002 9.15527e-05C12.2083 9.15527e-05 11.5299 0.506815 11.2425 1.22861L0.906555 19.9474V19.9493C0.720017 20.2649 0.620833 20.6287 0.620117 21.0001C0.620117 21.5305 0.820782 22.0392 1.17797 22.4143C1.53515 22.7894 2.0196 23.0001 2.52474 23.0001C2.56943 22.9998 2.6141 22.9978 2.65866 22.9942L2.66238 23.0001H13.0002H23.338L23.3417 22.9923C23.3862 22.9965 23.4309 22.9991 23.4756 23.0001C23.9807 23.0001 24.4652 22.7894 24.8224 22.4143C25.1796 22.0392 25.3802 21.5305 25.3802 21.0001C25.3799 20.628 25.2807 20.2635 25.0938 19.9474L25.0789 19.92C25.0783 19.9194 25.0777 19.9187 25.0771 19.9181L14.7579 1.22861C14.4705 0.506815 13.7921 9.15527e-05 13.0002 9.15527e-05ZM11.8451 8.35947H14.1552L13.9636 14.8321H12.0367L11.8451 8.35947ZM13.0039 16.8106C13.7867 16.8106 14.2557 17.2529 14.2557 18.0079C14.2557 18.7489 13.7867 19.1895 13.0039 19.1895C12.2154 19.1895 11.7428 18.7489 11.7428 18.0079C11.7428 17.2529 12.2144 16.8106 13.0039 16.8106Z"
      fill="#DC3545"
    />
  </svg>
)
