import React, { useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { PlaidResponse } from '../../interfaces'
import { AuthService } from '../../services'
import { Button } from '../Button'
import { useToaster } from '../Toast'

interface PlaidButtonProps {
  token: string | undefined
  onSuccess?: (token: string, metadata: PlaidResponse) => void
  onSessionExpired?: Function
  authService: AuthService
}

export const PlaidButton: React.FC<PlaidButtonProps> = ({
  token,
  children,
  onSuccess = (token: string, metadata: PlaidResponse) => {},
  onSessionExpired = () => {},
  authService: AuthService
}) => {
  const { show: setToastr } = useToaster()
  const { open } = usePlaidLink({
    token: token || '',
    onSuccess
  } as any)

  const onButtonClick = useCallback(() => {
    // We get the user information
    // before triggering plaid
    AuthService.getUserInfo()
      .then(() => {
        open()
      })
      .catch((e) => {
        // If the session expired, close add bank account the modal
        // The session expired modal will be triggered automatically
        if (e.status === 401) {
          onSessionExpired()
        } else {
          setToastr({
            message:
              'An unknown error has occurred trying to connect your bank account information',
            icon: 'icon-danger',
            type: 'danger'
          })
        }
      })
  }, [onSessionExpired, open, setToastr, AuthService])
  if (!token) {
    return null
  }
  return <Button onClick={() => onButtonClick()}>{children}</Button>
}
