import { StyleSheet } from '@react-pdf/renderer'

const pageHorizontalPadding = 30

const styles = StyleSheet.create({
  pageHeaderContainer: {
    height: 50,
    paddingHorizontal: pageHorizontalPadding,
    paddingVertical: 10,
    backgroundColor: '#F8F9FA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  pageHeaderImage: {
    width: 30,
    height: 30,
    objectFit: 'scale-down',
    marginLeft: 'auto'
  },
  pageHeaderText: {
    color: '#6d39d9',
    fontSize: 11,
    fontStyle: 'normal',
    fontWeight: 800,
    letterSpacing: 2,
    textTransform: 'uppercase'
  }
})

export default styles
