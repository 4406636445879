import { unformatPhoneNumber } from '@angle/utils'
import { IBusinessOnboardingDTO } from '../../interfaces'
import { getNewCurrentStep } from '../../utils/utils'

export const mapDTOtoAdminInformation = (data: IBusinessOnboardingDTO) => {
  return {
    adminName: {
      title: data.admin_contact_name?.title || '',
      firstName: data.admin_contact_name?.first_name || '',
      lastName: data.admin_contact_name?.last_name || ''
    },
    billingName: {
      title: data.billing_contact_name?.title || '',
      firstName: data.billing_contact_name?.first_name || '',
      lastName: data.billing_contact_name?.last_name || ''
    },
    adminContact: {
      workEmail: data.admin_contact_info.work_email || '',
      workPhone: data.admin_contact_info.work_phone || ''
    },
    billingContact: {
      workEmail: data.billing_contact_info.work_email || '',
      workPhone: data.billing_contact_info.work_phone || ''
    },
    adminEqualBilling: data.billing_same_as_admin_contact || false,
    stepNumber: data.in_progress_state
  }
}

export const mapAdminInformationToDTO = (
  formData: any,
  adminEqualsBilling: boolean,
  backendStepNumber: number
) => {
  const admin_contact_info = {
    work_phone: unformatPhoneNumber(formData.adminContact.workPhone),
    work_email: formData.adminContact.workEmail
  }

  const billing_contact_info = adminEqualsBilling
    ? { ...admin_contact_info }
    : {
        work_phone: unformatPhoneNumber(formData.billingContact.workPhone),
        work_email: formData.billingContact.workEmail
      }

  const admin_contact_name = {
    first_name: formData.adminName.firstName,
    last_name: formData.adminName.lastName,
    title: formData.adminName.title
  }

  const billing_contact_name = adminEqualsBilling
    ? { ...admin_contact_name }
    : {
        first_name: formData.billingName.firstName,
        last_name: formData.billingName.lastName,
        title: formData.billingName.title
      }

  return {
    admin_contact_info,
    billing_contact_info,
    admin_contact_name,
    billing_contact_name,
    billing_same_as_admin_contact: adminEqualsBilling,
    in_progress_state: getNewCurrentStep(backendStepNumber, 3)
  }
}
