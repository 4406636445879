import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Logo } from '../components/Logo'
import { Breadcrumb, Container, Navbar, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Variant } from 'react-bootstrap/esm/types'

const Main = styled.main<{ marginTop: number }>`
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  overflow-y: auto;
  margin-top: ${(props) => props.marginTop}px;
`

const DefaultNav = () => (
  <Navbar bg="primary" className="p-2 px-3" fixed="top">
    <Navbar.Brand>
      <Logo variant="full" />
    </Navbar.Brand>
  </Navbar>
)

export interface IBreadcrumb {
  link: string
  title: string
}

export interface IAlertMessage {
  type: Variant
  message: ReactNode
  className?: string
  style?: React.CSSProperties
}

export interface IMemberPortalLayoutProps {
  children: React.ReactNode
  breadcrumbs: IBreadcrumb[]
  nav?: React.ReactNode
  alert?: IAlertMessage
  [x: string]: any
}

export const MemberPortalLayout: React.FC<IMemberPortalLayoutProps> = ({
  children,
  breadcrumbs = [],
  noBreadcrumbs = false,
  nav,
  alert
}) => (
  <Container fluid className="px-0 vh-100 d-flex flex-column">
    {nav || <DefaultNav />}
    {alert && (
      <div style={{ marginTop: 75, flex: 1 }}>
        <Alert
          className={`my-0 rounded-0 text-center ${alert.className || ''}`}
          style={alert.style}
          variant={alert.type}
        >
          {alert.message}
        </Alert>
      </div>
    )}
    <Main marginTop={!alert ? 75 : 0} className="bg-light">
      {!noBreadcrumbs && (
        <Breadcrumb>
          {breadcrumbs.length ? (
            breadcrumbs.map((crumb: IBreadcrumb, key) => (
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{ to: crumb.link }}
                key={key}
                active={breadcrumbs.length === key + 1}
                className="fs-16"
              >
                <span className="text-capitalize">
                  {crumb.title?.toString().toLowerCase()}
                </span>
              </Breadcrumb.Item>
            ))
          ) : (
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          )}
        </Breadcrumb>
      )}
      {children}
    </Main>
  </Container>
)
