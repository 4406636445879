import React from 'react'
import {
  PortalLayout,
  Avatar,
  AngleNavbar,
  Logo,
  useAuth,
  UserDropdown
} from 'react-angle-dashboard-components'
import { useHistory, useLocation } from 'react-router'
import { OnboardingStatus } from '../../routes/OnboardingStatus'
import { Paths } from '../../routes/paths'
import { useQuery } from 'react-query'
import { authService, groupService } from '../../services'

export const PageLayout: React.FC<{ breadcrumbs?: any; noMenu?: boolean }> = ({
  children,
  breadcrumbs,
  noMenu = false
}) => {
  const { data } = useQuery('getGroup', () => groupService.getInfo())

  const renwal = data?.in_renewal
  const history = useHistory()
  const location = useLocation()
  const { user } = useAuth().state

  const isOnboardingCompleted =
    user?.onboarding_status === OnboardingStatus.completed ||
    user?.onboarding_status === OnboardingStatus.underReview

  // if onboarding is completed and no menu is false OR renewal is false and no menu is false
  const items =
    (isOnboardingCompleted && !noMenu) || (renwal && !noMenu)
      ? [
          {
            title: 'Summary',
            onClick: () => history.push(Paths.summary),
            active: location.pathname.startsWith(Paths.summary)
          },
          {
            title: 'Employees',
            onClick: () => history.push(Paths.employees),
            active: location.pathname.startsWith(Paths.employees)
          },
          {
            title: 'Billing',
            onClick: () => history.push(Paths.billing),
            active: location.pathname.startsWith(Paths.billing)
          },
          {
            title: 'Documents',
            onClick: () => history.push(Paths.documentsAndForms),
            active: location.pathname.startsWith(Paths.documentsAndForms)
          }
          // {
          //   title: 'Reporting',
          //   onClick: () => history.push(Paths.reporting),
          //   active: location.pathname.startsWith(Paths.reporting)
          // }
        ]
      : []

  let initials
  if (user) {
    initials =
      user?.first_name[0].toUpperCase() + user?.last_name[0].toUpperCase()
  }

  const avatarImg = (
    <Avatar
      style={{ top: '2px' }}
      circle
      border="lg"
      size="sm"
      fallbackContent={initials}
      customBg="#28347E"
    />
  )

  const userDropdownItems = [
    {
      title: 'Settings',
      icon: 'settings',
      callback: () => history.push(Paths.settings)
    }
  ]

  const generalDropdownItems = [
    {
      title: 'Sign Out',
      icon: 'sign-out',
      callback: async () => {
        await authService.logout()
        localStorage.removeItem('token')
        history.push(Paths.login)
      },
      iconClass: 'text-danger'
    }
  ]

  let username = user ? user?.first_name + ' ' + user?.last_name : ''
  const avatar = (
    <div>
      <UserDropdown
        avatar={avatarImg}
        items={
          isOnboardingCompleted
            ? userDropdownItems.concat(generalDropdownItems)
            : generalDropdownItems
        }
        userName={username}
        email={user?.username || ''}
      />
    </div>
  )

  return (
    <PortalLayout
      nav={
        <AngleNavbar
          items={items}
          logo={<Logo variant="simple" text="Business" />}
          avatar={noMenu ? null : avatar}
        />
      }
      breadcrumbs={breadcrumbs}
      noBreadcrumbs={!breadcrumbs}
    >
      {children}
    </PortalLayout>
  )
}
