import styled, { keyframes } from 'styled-components'

const popIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
export const MarkerContainer = styled.div<{
  lat: number | string
  lng: number | string
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MarkerIcon = styled.i`
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  font-size: 1.1rem;
  cursor: pointer;
`

export const TooltipContainer = styled.div`
  background-color: #fff;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  border-radius: 6px;
  animation: ${popIn} 0.2s linear;
  cursor: auto;
`

export const FacilityTooltipImage = styled.img`
  width: 100%;
  height: 53%;
  border-radius: 6px 6px 0 0;
`

export const FacilityTooltipImageFallback = styled.div`
  width: 100%;
  height: 53%;
  background-color: var(--dropdown-active);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
`

export const FacilityTooltipContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 47%;
`

export const TooltipFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProviderTooltipContent = styled.div`
  display: flex;
  align-items: center;
`

export const ProviderText = styled.div`
  display: block;
`

export const ProviderTitle = styled.h6`
  width: 14rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const TooltipPaginatorContainer = styled.div<{ style: any }>`
  height: 30px;
  position: absolute;
  top: -40px;
  right: 0;
  display: flex;
  width: 8rem;
  justify-content: space-between;
`

export const PaginatorText = styled.div`
  height: 30px;
  min-width: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0.5rem;
`

export const PaginatorButtonContainer = styled.div`
  width: 60px;
  border-radius: 6px;
  background-color: white;
`

export const PaginatorButton = styled.i<{ style: any }>`
  height: 30px;
  width: 30px;
  cursor: pointer;
`

export const EllipsisFacilityTitle = styled.h6`
  whitespace: nowrap;
  textoverflow: ellipsis;
  width: 100%;
  overflow: hidden;
`

export const CounterBadge = styled.div`
  height: 1.3rem;
  width: 1.3rem;
  position: absolute;
  top: -5px;
  left: 2px;
  background-color: var(--primary);
  border-radius: 50%;
  color: white;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 10px;
`
