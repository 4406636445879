export const getStackBaseUrl = (prefixBase: string) => {
  let prefix = prefixBase
  const origin = window.location.origin
  if (origin.includes('dev') || origin.includes('localhost')) {
    prefix += '-dev'
  } else if (origin.includes('testing')) {
    prefix += '-testing'
  }

  return `https://${prefix}.anglehealth.com`
}
