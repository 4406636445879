import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  SelectableImage,
  ScrollableContainer,
  ScrollableImageContainer
} from './Styles'
import { GalleryContext } from './context'

const SCROLL_BREAKPOINT_ITEMS = 8

const ScrollableThumbnail = ({ images, activeIndex, handleSelect }) => (
  <ScrollableContainer>
    {images.map((img, i) => (
      <ScrollableImageContainer key={i}>
        <SelectableImage
          onClick={() => handleSelect(i)}
          className={`d-block ${
            i == activeIndex ? 'border border-primary' : ''
          }`}
          src={img.src}
          alt={img.alt}
          rounded
        />
      </ScrollableImageContainer>
    ))}
  </ScrollableContainer>
)

const StaticThumbnail = ({ images, activeIndex, handleSelect }) => (
  <Row className='justify-content-center'>
    {images.map((img, i) => (
      <Col key={i} style={{ maxWidth: 150 }}>
        <SelectableImage
          onClick={() => handleSelect(i)}
          className={`d-block ${
            i == activeIndex ? 'border border-primary' : ''
          }`}
          src={img.src}
          alt='First slide'
          rounded
        />
      </Col>
    ))}
  </Row>
)

export const Thumbnail = () => {
  const state = useContext(GalleryContext)

  const breakpointNumber = state.scrollFrom || SCROLL_BREAKPOINT_ITEMS

  return state.images.length > breakpointNumber ? (
    <ScrollableThumbnail {...state} />
  ) : (
    <StaticThumbnail {...state} />
  )
}

export default Thumbnail
