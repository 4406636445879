export const headers = [
    {
      title: 'RISK IDENTIFIER', className: "text-wrap"
    },
    {
      title: 'DOB',
    },
    {
      title: 'SEX'
    },
    {
      title: 'EE, SP, or CH'
    },
    {
      title: '(A)CTIVE, (C)OBRA, (R)ETIREE, or (T)ERMED', className: "text-wrap"
    },
    {
      title: 'TERM DATE'
    },
    {
      title: 'DIAGNOSIS'
    },
    {
      title: 'MOST RECENT DATE OF SERVICE', className: "text-wrap"
    },
    {
      title: 'EXPENSES INCURRED THIS PLAN YEAR', className: "text-wrap"
    },
    {
      title: ''
    },
    {
      title: ''
    }
  ]