import { PageLayout } from '../layouts/PageLayout'
import { RouteComponentProps } from 'react-router-dom'
import {
  EmployeeProfile,
  EmployerPortalAddReinstateEmployee
} from 'react-angle-dashboard-components'
import {
  eightThreeFourService,
  summaryService,
  plansService
} from '../../services'

interface EmployeeRegistrationLocationState {
  employeeData: EmployeeProfile | undefined
}

export const EmployeeRegistration = (
  props: RouteComponentProps<{}, any, EmployeeRegistrationLocationState | any>
) => {
  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Employees', link: '/employees' },
        { title: 'Add Employee', link: '/employees/registration' }
      ]}
    >
      <EmployerPortalAddReinstateEmployee
        eightThreeFourService={eightThreeFourService}
        summaryService={summaryService}
        planService={plansService}
        employeeData={props.history?.location?.state?.employeeData}
        isBrokerPortal={false}
      />
    </PageLayout>
  )
}

export default EmployeeRegistration
