import React, { useState, useEffect, ChangeEvent } from 'react'
import {
  Button,
  Card,
  CenteredSpinner,
  Col,
  Row,
  useToaster,
  useQueryParams,
  EmployeeService,
  EightThreeFourService,
  EmployerPortalAddDependent,
  EmployerPortalAddQLE,
  EmployerPortalEditEmployeeModalV2,
  IPlanListDTO,
  Table,
  Form,
  SummaryService,
  PlanService
} from '../../../index'
import { Link, useParams } from 'react-router-dom'
import EmployeeProfileSidebar from './EmployeeProfileSidebar'
import { PersonalInformation, SectionContainer, DependentsRow } from './style'
import { useQuery } from 'react-query'
import { maskSsn } from '@angle/utils'
import moment from 'moment'

export const EmployerPortalEmployeeProfile: React.FC<{
  isBrokerPortal?: boolean
  employeeService: EmployeeService
  eightThreeFourService: EightThreeFourService
  summaryService: SummaryService
  plansService: PlanService
  planData?: IPlanListDTO
}> = ({ isBrokerPortal, employeeService, eightThreeFourService, planData, summaryService, plansService }) => {
  const { show } = useToaster()
  const query = useQueryParams()

  const { id, groupId } = useParams<{ id: string; groupId: string }>()

  const queryKey = ['employee', id]
  if (isBrokerPortal) {
    queryKey.push(groupId)
  }
  const {
    data: employee,
    isLoading,
    refetch: refetchEmployee
  } = useQuery(queryKey, () =>
    employeeService.getEmployeeProfile(id, isBrokerPortal ? groupId : '')
  )

  const {
    data: qleData,
    isLoading: isQleLoading,
    refetch: refetchQLE
  } = useQuery('employeeQle', () => employeeService.getQlesForEmployee(id))

  const transformQleValues = (s: string): string => {
    if (s === 'U') {
      return 'Other'
    }
    return s.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase()
    })
  }

  const condition = query.get('condition')
  const name = query.get('name')

  const [isAddDependentModalVisible, setAddDependentModalVisible] =
    useState(false)
  const [isAddQLEModalVisible, setAddQLEModalVisible] = useState(false)
  const [isEditEmployeeModalVisible, setEditEmployeeModalVisible] =
    useState(false)

  const [coverageIndex, setCoverageIndex] = useState(0);
  const [coverageEffectiveDate, setCoverageEffectiveDate] = useState(employee?.coverageStart);
  const [coverageTerminationDate, setCoverageTerminationDate] = useState(employee?.coverageEnd);
  const [planName, setPlanName] = useState(employee?.plan);
  const [dependents, setDependents] = useState(employee?.dependents || []);
  const [qles, setQles] = useState(qleData?.data || []);

  useEffect(() => {
    const coverageObject = employee?.allCoverages[coverageIndex];
    if (!coverageObject || !coverageObject.effective_date || !coverageObject.term_date) {
      return;
    }
    setCoverageEffectiveDate(coverageObject.effective_date);
    setCoverageTerminationDate(coverageObject.term_date);
    setPlanName(coverageObject.plan.plan_name);
    setQles(qleData?.data.filter((qle) => Date.parse(qle.date) <= Date.parse(coverageObject.term_date)));
    setDependents(employee?.dependents.filter((dependent) => Date.parse(dependent.created_on_date) <= Date.parse(coverageObject.term_date)))
  }, [coverageIndex, employee, isLoading, isQleLoading]);
  
  useEffect(() => {
    if (condition === 'new') {
      setTimeout(() => {
        show({
          message:
            'Robert Smith has been successfully added to the employee list',
          icon: 'icon-correct',
          type: 'success'
        })
      }, 200)
    }
    if (condition === 'new-dependant') {
      setTimeout(() => {
        show({
          message: `Dependent ${name} added succesfully`,
          icon: 'icon-correct',
          type: 'success'
        })
      }, 200)
    }
  }, [condition, name, show])

  if (isLoading || isQleLoading) {
    return <CenteredSpinner />
  }

  return (
    <div className="d-flex flex-column" style={{ height: '100%' }}>
      <SectionContainer className="d-flex" style={{ height: '100%' }}>
        <EmployeeProfileSidebar
          employee={employee}
          complete={false}
          empty={false}
          isBrokerPortal={isBrokerPortal}
          eightThreeFourService={eightThreeFourService}
          refetchEmployee={refetchEmployee}
          planName={planName}
          coverageEffectiveDate={coverageEffectiveDate}
          coverageTerminationDate={coverageTerminationDate}
          numCovered={1 + dependents.length}
          summaryService={summaryService}
          plansService={plansService}
        />
        <div className="flex-grow-1" style={{ borderTop: '1px solid #E7EAF3'}}>
          <div className="d-flex just-content-center align-items-center p-3" style={{ border: '1px solid #E7EAF3'}}>
            <span className="mr-2">Coverage Period</span>
            <Form.Control
              as="select"
              className={
                employee?.allCoverages.length == 1 ? 'bg-gray' : 'bg-white'
              }
              disabled={employee?.allCoverages?.length == 1}
              value={coverageIndex}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setCoverageIndex(parseInt(e.target.value))}
              style={{ width: '300px'}}
            >
              {employee?.allCoverages &&
                employee?.allCoverages.map((coverage, i) => {
                  return (
                    <option value={i}>
                      {coverage.effective_date} to{' '}
                      {coverage.term_date ? coverage.term_date : 'Present'}
                    </option>
                  )
                })}
            </Form.Control>
          </div>
          <div style={{ padding: '30px 30px 30px' }}>
            <div>
              <h5 className="mb-4 d-flex justify-content-between">
                <span>Dependents</span>
                <Button
                  variant="primary"
                  ghost
                  size="sm"
                  onClick={() => setAddDependentModalVisible(true)}
                >
                  Add a Dependent
                </Button>
              </h5>
              {dependents?.length ? (
                <DependentsRow className="mb-5">
                  {dependents?.map((d: any) => {
                    let path = `/employees/${employee?.id}/dependents/${d.member_id}`

                    return (
                      <Col md={3} key={d.member_id}>
                        <Link
                          to={
                            isBrokerPortal ? `/groups/${groupId}${path}` : path
                          }
                        >
                          <Card style={{ minWidth: 200, marginBottom: 30 }}>
                            <Card.Body className="d-flex justify-content-between">
                              <div>
                                <p className="mb-0">
                                  {d.name?.first_name} {d.name?.last_name}
                                </p>
                                <p className="mb-0 text-muted text-capitalize">
                                  {d.relationship}
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    )
                  })}
                </DependentsRow>
              ) : (
                <div className="text-center">
                  <h5 className="text-black-50">No dependents added</h5>
                  <p className="text-black-50" style={{ opacity: 0.5 }}>
                    Dependents that you add will be listed here
                  </p>
                </div>
              )}
            </div>
            <div>
              <h5 className="mb-4 d-flex justify-content-between">
                <span>Qualifying Life Events</span>
                <Button
                  variant="primary"
                  ghost
                  size="sm"
                  disabled={employee?.coverageStart && new Date(employee.coverageStart) >= new Date()}
                  onClick={() => {
                    setAddQLEModalVisible(true)
                  }}
                >
                  Add a QLE
                </Button>
              </h5>
              <Table
                headerClassName="thead-light"
                headers={[
                  { title: 'QLE Reason' },
                  { title: 'Date of QLE' },
                  { title: 'Submitted' }
                ]}
                loading={isQleLoading}
              >
                {(isQleLoading || !qleData || qles.length === 0) && (
                  <tr>
                    <td className="align-middle" colSpan={3}>
                      No Qualifying Life Events
                    </td>
                  </tr>
                )}
                {qles &&
                  qles.toReversed().map((qleDatapoint) => {
                    return (
                      <tr>
                        <td>{transformQleValues(qleDatapoint.reason)}</td>
                        <td>{qleDatapoint.date}</td>
                        <td>
                          {moment(qleDatapoint.created_at).format('LLLL')}
                        </td>
                      </tr>
                    )
                  })}
              </Table>
            </div>
            <div className="mt-5">
              <h5 className="mb-5 d-flex justify-content-between ">
                <span>Personal Information</span>
                <Button
                  variant="primary"
                  ghost
                  size="sm"
                  onClick={() => setEditEmployeeModalVisible(true)}
                >
                  Edit Information
                </Button>
              </h5>
              <Row>
                <Col md={6} sm={12}>
                  <PersonalInformation>
                    <p>Full Name</p>
                    <p>{employee?.name}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6} sm={12}>
                  <PersonalInformation>
                    <p>Member ID</p>
                    <p>{employee?.id}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6} sm={12}>
                  <PersonalInformation>
                    <p>Date of Birth</p>
                    <p>{employee?.dob}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6} sm={12}>
                  <PersonalInformation>
                    <p>SSN</p>
                    <p>{maskSsn(employee?.ssn || '')}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6} sm={12}>
                  <PersonalInformation>
                    <p>Email Address</p>
                    <p>{employee?.email}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6} sm={12}>
                  <PersonalInformation>
                    <p>Medicare Eligible</p>
                    <p>{employee?.medicareEligible}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6} sm={12}>
                  <PersonalInformation>
                    <p>Mailing Address</p>
                    <p className="text-right">{employee?.address}</p>
                  </PersonalInformation>
                </Col>
                <Col md={6} sm={12}>
                  <PersonalInformation>
                    <p>Other Insurance</p>
                    <p>{employee?.otherInsurance}</p>
                  </PersonalInformation>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </SectionContainer>
      {employee && employee.address_object && (
        <EmployerPortalAddDependent
          isAddDependentModalVisible={isAddDependentModalVisible}
          setAddDependentModalVisible={setAddDependentModalVisible}
          employee={employee}
          eightThreeFourService={eightThreeFourService}
          employeeAddress={{
            street_1: employee.address_object.street_1,
            street_2: employee?.address_object.street_2,
            city: employee.address_object.city,
            state: employee.address_object.state,
            zip: employee.address_object.zip
          }}
          refetchEmployee={refetchEmployee}
        />
      )}
      {employee && employee.address_object && (
        <EmployerPortalAddQLE
          isAddQLEModalVisible={isAddQLEModalVisible}
          setAddQleModalVisible={setAddQLEModalVisible}
          eightThreeFourService={eightThreeFourService}
          employee={employee}
          planData={planData}
          refetchQLE={refetchQLE}
          refetchEmployee={refetchEmployee}
        />
      )}
      {employee && employee.address_object && (
        <EmployerPortalEditEmployeeModalV2
          isEditModalVisible={isEditEmployeeModalVisible}
          setEditModalVisible={setEditEmployeeModalVisible}
          employee={employee}
          eightThreeFourService={eightThreeFourService}
          refetchEmployee={refetchEmployee}
        />
      )}
    </div>
  )
}
