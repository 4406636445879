export function onlyNumberKey(evt) {
  // Only ASCII character in that range allowed
  var ASCIICode = evt.which || evt.keyCode

  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) evt.preventDefault()
  return true
}

export const preventNegativeInputs = (e) => {
  if (e.code === 'Minus') {
    e.preventDefault()
  }

  onlyNumberKey(e)
}

export const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window['clipboardData']
  const pastedData = parseFloat(clipboardData.getData('text'))

  // eslint-disable-next-line no-self-compare
  if (pastedData < 0 || pastedData !== pastedData) {
    e.preventDefault()
  }
}

export const preventWheelNumericInput = (e) => e.target.blur()

export const limitLength = (e, maxLength: number, onChange) => {
  if (e.target.value.length > maxLength) return e.preventDefault()

  onChange(e.target.value)
}

export const nonNegativeValues = {
  onKeyPress: preventNegativeInputs,
  onPaste: preventPasteNegative,
  onWheel: preventWheelNumericInput
}

export const maxZip = (val: any) => val.toString().length === 5 || 'Zip code must be 5 digits'
