import React from 'react'
import { EmployerPortalEmployeeProfile } from 'react-angle-dashboard-components'
import { useParams } from 'react-router-dom'
import { employeeService, eightThreeFourService, plansService, summaryService } from '../../services'
import { PageLayout } from '../layouts/PageLayout'
import { useQuery } from 'react-query'

export const EmployeeProfile = () => {
  const { id } = useParams<{ id: string }>()

  const { data: employee } = useQuery(['employee', id], () =>
    employeeService.getEmployeeProfile(id)
  )

  const { data: planData } = useQuery(['getPlans'], () =>
    plansService.getList({})
  )

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Employees', link: '/employees' },
        { title: employee?.name || 'Loading...', link: `/employees/${id}` }
      ]}
    >
      <EmployerPortalEmployeeProfile employeeService={employeeService} eightThreeFourService={eightThreeFourService} planData={planData} summaryService={summaryService} plansService={plansService} />
    </PageLayout>
  )
}

export default EmployeeProfile
