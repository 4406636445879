import { ApiService } from '../base'
import { GroupDTO, NewHireWaitingPeriodChoices } from '../../../interfaces'
import { SummaryData } from '../../../interfaces/employer-broker-shared/summary'
import { GroupData } from '../../../interfaces/employer-broker-shared/group'

const getBrokerFullName = (r: GroupDTO) => {
  const producerName = r.agency?.producer_name
  if (!producerName || producerName.first_name) {
    return ''
  }
  return `${producerName.first_name} ${producerName.last_name}`
}

const mapSummaryData = (r: GroupDTO): SummaryData => ({
  brokers: getBrokerFullName(r),
  companyName: r.group_name,
  coverageEnd: r.group_termination_date,
  coverageStart: r.coverage_effective_date || r.group_effective_date,
  currentBalanceDue: r.current_balance_due,
  currentBalanceDueDate: r.current_balance_due_date,
  employeeCount: r.employee_count || 0,
  generalAgency: r.agency?.agency_name,
  groupID: r.group_id,
  inRenewal: r.in_renewal,
  onboardingStatus: r.onboarding_status,
  totalMembers: r.total_members,
  tpaTrueUp: r?.tpa_true_up || false,
  newHireWaitingPeriod:
    NewHireWaitingPeriodChoices[r.new_hire_waiting_period_update]
})

const mapGroupData = (r: GroupDTO): GroupData => {
  // We refer to "Class" data as subgroups in the FE/PM world
  return {
    coverageEnd: r.coverage_termination_date || r.group_termination_date,
    coverageStart: r.coverage_effective_date || r.group_effective_date,
    defaultSubgroup: r.default_class,
    groupID: r.group_id,
    classes: r.classlist?.map(c => {
      return {
        entryIdPK: c.entry_id,
        description: c.description,
        classId: c.class_id
      }
    }) || [],
    subgroups: r.subgroups,
  }
}

export class SummaryService extends ApiService {
  async getSummary(groupId?: string): Promise<SummaryData> {
    const url = groupId ? `/groups/${groupId}` : '/group'
    return this.request({
      method: 'get',
      url,
      credentials: true
    }).then((r: GroupDTO) => mapSummaryData(r))
  }

  async getGroup(groupId?: string): Promise<GroupData> {
    const url = groupId ? `/groups/${groupId}` : '/group'
    return this.request({
      method: 'get',
      url,
      credentials: true
    }).then((r: GroupDTO) => mapGroupData(r))
  }

  // This function will be deprecated soon
  async unsetTpaTrueUp(
    groupId: string
  ): Promise<void> {
    const data = {
      group_id: groupId
    }

    await this.request({
      method: 'patch',
      url: `/group/unset-tpa-true-up`,
      credentials: true,
      data
    })
  }
}
