import { useCallback, useEffect, useState } from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
import { SessionExpired } from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { ApiService, authService } from '../../services'

interface IUserData {
  authuser_id: number
  first_name: string
  last_name: string
  username: string
  temporary: boolean
  onboarding_status: string
  in_progress_state: number
}

export interface IAuthContext {
  login: Function
  logout: Function
  refreshUserData: Function
  userData: IUserData | null
  loginWithToken: Function
  authLoading: boolean
}
export const AuthContext = createContext<IAuthContext>({
  login: (username: string, password: string) => Promise.reject(),
  logout: () => {},
  userData: null,
  loginWithToken: () => {},
  refreshUserData: () => {},
  authLoading: false
})

export const AuthContextProvider: React.FC = ({ children }) => {
  const FS = (window as any).FS

  const [userData, setUserData] = useState<IUserData | null>(null)

  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)
  const [authLoading, setAuthLoading] = useState(true)
  const history = useHistory()

  const refreshUserData = useCallback(() => {
    setAuthLoading(true)
    authService
      .getUserInfo()
      .then((response) => {
        setUserData(response)
      })
      .catch(() => setUserData(null))
      .finally(() => setAuthLoading(false))
  }, [])

  const onSessionExpiredButtonClicHandler = useCallback(() => {
    setShowSessionExpiredModal(false)

    history.push('/login')
  }, [history])

  ApiService.onError = (error: any) => {
    if (
      error.response?.status === 401 &&
      error.config.url !== '/auth' &&
      error.config.url !== '/group' &&
      localStorage.getItem('token')
    ) {
      setShowSessionExpiredModal(true)
      localStorage.removeItem('token')
      history.push('/login')
    }
    setAuthLoading(false)
    return {}
  }

  const login = async (username: string, password: string) => {
    setAuthLoading(true)
    const response = await authService.login(username, password)

    if (response.access_token) {
      ApiService._token = response.access_token

      localStorage.setItem('token', response.access_token)

      FS.identify(response.authuser_id, {
        displayName: response.first_name + ' ' + response.last_name,
        username: response.username
      })

      setUserData(response)
    }

    setAuthLoading(false)

    return response
  }

  const loginWithToken = async (response: any) => {
    setAuthLoading(true)

    if (response.access_token) {
      ApiService._token = response.access_token
      localStorage.setItem('token', response.access_token)

      FS.identify(response.authuser_id, {
        displayName: response.first_name + ' ' + response.last_name,
        username: response.username
      })

      setUserData(response)
    }

    setAuthLoading(false)

    return response
  }

  const logout = async () => {
    await authService.logout()
    localStorage.removeItem('token')
    setUserData(null)
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      ApiService._token = token
      refreshUserData()
    } else {
      setAuthLoading(false)
    }
  }, [refreshUserData])

  return (
    <AuthContext.Provider
      value={{
        userData,
        authLoading,
        login,
        logout,
        loginWithToken,

        refreshUserData
      }}
    >
      {showSessionExpiredModal && (
        <SessionExpired
          verticallyCentered
          onClick={onSessionExpiredButtonClicHandler}
        />
      )}
      {children}
    </AuthContext.Provider>
  )
}

export const useUserData = () => useContext(AuthContext).userData
