import styled from 'styled-components'
import { Row } from 'react-angle-dashboard-components'

export const TableContainerRow = styled(Row)`
  @media (min-width: 1290px) {
    width: 830px;
  }

  width: 100%;
`

export const TableDataLight = styled.td`
  color: var(--secondary);
`
