import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid var(--card-border);
  padding: 1rem;
  border-radius: 6px;
  width: 100%;
`

export const StepBadge = styled.div<{ active: boolean }>`
  border-radius: 50%;
  background-color: ${(props) => (props.active ? 'var(--success)' : '#e0e0e0')};
  color: ${(props) => (props.active ? 'var(--white)' : 'var(--secondary)')};
  height: 1rem;
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`

export const StepWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid var(--card-border);
  }

  padding: 0.625rem 0em;
`
