import React from 'react'

interface IGalleryContextState {
  show: boolean
  activeIndex: number
}

export interface IGalleryImage {
  id: string | number
  src: string
}

export const initialState: IGalleryContextState = {
  show: false,
  activeIndex: 0
}

export const actions = {
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  SET_CAROUSEL_INDEX: 'SET_CAROUSEL_INDEX'
}

export const reducer = (
  state: IGalleryContextState,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return {
        ...state,
        show: !state.show
      }
    case 'SET_CAROUSEL_INDEX':
      return {
        ...state,
        activeIndex: action.payload
      }
    default:
      throw new Error()
  }
}

interface IGalleryContext extends IGalleryContextState {
  toggleModal: Function
  handleSelect: (index: number) => void
  images: IGalleryImage[]
  scrollFrom?: number
}

export const GalleryContext = React.createContext<IGalleryContext>({
  activeIndex: 0,
  handleSelect: (index) => {},
  images: [],
  show: false,
  toggleModal: () => {}
})
