import React, { ReactNode } from 'react'
import { Container } from 'react-bootstrap'

// pass in navbar custom to each portal
export interface IErrorPageLayoutProps
  extends React.HTMLAttributes<HTMLDivElement> {
  nav?: ReactNode
  button?: ReactNode
  errorTitle: string
  errorSubtitle: string
}

export const ErrorPageLayout: React.FC<IErrorPageLayoutProps> = ({
  nav,
  button,
  errorTitle,
  errorSubtitle,
  ...others
}) => (
  <Container
    fluid
    className="px-0 d-flex justify-content-center"
    style={{ paddingBottom: 100 }}
    {...others}
  >
    {nav}
    <div
      className="d-flex flex-column align-items-center"
      style={{ marginTop: 177 }}
    >
      <h3 style={{ marginBottom: 5 }}>{errorTitle}</h3>
      <p className="text-secondary" style={{ marginBottom: 43 }}>
        {errorSubtitle}
      </p>
      {button}
    </div>
  </Container>
)
