import React from 'react'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'

const ColumnContentCenter = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RowBaselineAlign = styled(Row)`
  display: flex;
  align-items: baseline;
`

const HelpIcon = styled.i`
  color: var(--gray-400);
  height: 10px;
  width: 10px;
  position: relative;
  top: 2px;
  left: 4px;
`

export interface IAboutProps {
  icon: string
  smallText: string
  helpButtonTooltip?: string
  iconStyle?: Record<string, string>
  [x: string]: any
}

export const About: React.FC<IAboutProps> = ({
  icon,
  smallText,
  helpButtonTooltip = null,
  children,
  iconStyle = {},
  ...p
}) => {
  return (
    <Row style={{ minWidth: '335px' }} {...p}>
      <ColumnContentCenter xs={1}>
        <i
          data-testid="about-icon"
          className={`icon text-secondary ${icon}`}
          style={iconStyle}
        />
      </ColumnContentCenter>
      <Col>
        <RowBaselineAlign>
          <small data-testid="about-smallText" className="text-muted">
            {smallText}
          </small>
          {helpButtonTooltip && (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip
                  data-testid="about-tooltip"
                  id={`${smallText} + ' help'`}
                >
                  {helpButtonTooltip}
                </Tooltip>
              }
            >
              <HelpIcon
                data-testid="about-tooltip-trigger"
                className="icon icon-question-mark fs-12"
              />
            </OverlayTrigger>
          )}
        </RowBaselineAlign>
        <Row>
          <h6 data-testid="about-children">{children}</h6>
        </Row>
      </Col>
    </Row>
  )
}

export default About
