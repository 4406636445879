import sanitizeHtml from 'sanitize-html'

export const sanitizerWithLinks = (html: string) =>
  sanitizeHtml(html, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a'],
    allowedAttributes: {
      a: ['href']
    },
    allowedIframeHostnames: ['www.healthcare.gov']
  })

export const removeLinks = (html: string) =>
  sanitizeHtml(html, {
    allowedAttributes: {
      a: ['name']
    }
  })
