import React, { useState } from 'react'
import { Alert, Button, Checkbox, Form } from 'react-angle-dashboard-components'
import { ILoginFormData } from 'react-angle-dashboard-components/src/hooks/use-login-form-handler'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import styled from 'styled-components'
import { EyeClosedIcon, EyeIcon } from './icons'

const FloatingInputIcon = styled.div`
  float: right;
  top: -35px;
  position: relative;
  left: -15px;
  opacity: 0.65;

  &:hover {
    opacity: 1;
  }
`
interface LoginFormProps {
  onSubmit: () => void
  onPasswordForgetHandler: () => void
  register: UseFormRegister<ILoginFormData>
  isSubmitting: boolean
  isValid: boolean
  errors: FieldErrors
  watch: any
}

export const LoginForm = (props: LoginFormProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const {
    onSubmit,
    register,
    isSubmitting,
    errors,
    onPasswordForgetHandler,
    watch
  } = props

  const rememberValue = watch('remember')

  return (
    <Form onSubmit={onSubmit}>
      {errors.email?.message && (
        <Alert variant="danger">{errors.email?.message}</Alert>
      )}
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          {...register('email', { required: true })}
          type="email"
          placeholder="john@anglehealth.com"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control
          {...register('password', { required: true })}
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
        />
        <FloatingInputIcon
          role="button"
          onClick={() => setShowPassword((s) => !s)}
        >
          {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
        </FloatingInputIcon>
      </Form.Group>
      <Form.Group>
        <Checkbox
          {...register('remember')}
          checked={rememberValue}
          label="Remember me"
        />
      </Form.Group>
      <Button type="submit" block disabled={isSubmitting}>
        {!isSubmitting ? 'Sign In' : 'Signing In'}
      </Button>
      <Button variant="link" block onClick={onPasswordForgetHandler}>
        Forgot Password?
      </Button>
    </Form>
  )
}
