import React from 'react'
import { Card, ProgressBar } from 'react-bootstrap'

export interface IOutOfPocketCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  currentValue: number
  maxValue: number
  remainingText: string
}

export const OutOfPocketCard: React.FC<IOutOfPocketCardProps> = ({
  title,
  currentValue,
  maxValue,
  remainingText,
  ...p
}) => {
  const percentage = Math.trunc((currentValue * 100) / maxValue)
  return (
    <Card {...p}>
      <Card.Body>
        <h6 className="mb-0">{title}</h6>
        <span className="text-secondary fs-12">{`Once you've paid $${maxValue.toLocaleString()}, we will cover the full cost of care.`}</span>
        <div className="fs-28 mt-2">
          <span>${currentValue.toLocaleString()} / </span>
          <span className="text-secondary">${maxValue.toLocaleString()}</span>
        </div>
        <ProgressBar now={percentage} className="mb-1 mt-3" />
        <small className="text-secondary">{remainingText}</small>
      </Card.Body>
    </Card>
  )
}

export default OutOfPocketCard
