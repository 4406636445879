import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { getCopayCoinsuranceString } from '../utils/getCopayCoinsuranceString'
import { Subheader } from '../style'

export const Copays: React.FC<{
  planCoverage: any
  idCardType: string
}> = ({ planCoverage, idCardType }) => {
  const isAngle = idCardType === 'angle'
  const isHdhp = !!planCoverage?.plan?.is_hdhp
  let headerText = isAngle ? 'Plan Copays' : 'In-Network Plan Copays'
  if (isHdhp) {
    headerText += '*'
  }

  return (
    <>
      <Subheader className="text-primary">{headerText}</Subheader>
      <Row>
        <Col>
          <Row className="flex-nowrap">
            <Col xs="9" style={{ whiteSpace: 'nowrap' }} className="text-left">
              <p className="mb-0 fs-12 font-weight-bold">Primary Care:</p>
              <p className="mb-0 fs-12 font-weight-bold">Specialist:</p>
            </Col>
            <Col className="pl-0 text-right" style={{ whiteSpace: 'nowrap' }}>
              <p className="mb-0 fs-12">
                {getCopayCoinsuranceString(planCoverage, 'doctor_visit_inn')}
              </p>
              <p className="mb-0 fs-12">
                {getCopayCoinsuranceString(planCoverage, 'specialist_inn')}
              </p>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="flex-nowrap">
            <Col style={{ whiteSpace: 'nowrap' }} xs="8" className="text-left">
              <p className="mb-0 fs-12 font-weight-bold">Urgent Care:</p>
              <p className="mb-0 fs-12 font-weight-bold">TeleHealth:</p>
            </Col>
            <Col style={{ whiteSpace: 'nowrap' }} className="text-right pl-0">
              <p className="mb-0 fs-12">
                {getCopayCoinsuranceString(planCoverage, 'urgent_care_inn')}
              </p>
              <p className="mb-0 fs-12">
                {getCopayCoinsuranceString(planCoverage, 'telehealth_inn')}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
