import React from 'react'
import {
  PageContainer,
  PageHeaderLight
} from 'react-angle-dashboard-components'
import { Route, Switch } from 'react-router-dom'
import { Paths } from '../../../routes/paths'
import { PageLayout } from '../../layouts/PageLayout'
import { DependatFormProvider } from './context'
import DependentConfirmation from './DependentConfirmation'
import RegistrationForm from './RegistrationForm'

export const DependentRegistration = () => {
  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Employee', link: '/employees' },
        { title: 'Robert Smith', link: '/employees/1' },
        { title: 'Add dependent', link: '/employees/1/dependents/registration' }
      ]}
    >
      <PageHeaderLight className="align-items-start">
        <PageContainer size="md">
          <h1>Add dependent</h1>
        </PageContainer>
      </PageHeaderLight>
      <DependatFormProvider>
        <PageContainer size="md">
          <Switch>
            <Route
              exact
              path={Paths.newDependentRegistration}
              component={RegistrationForm}
            />
            <Route
              exact
              path={Paths.newDependentRegistration + '/confirmation'}
              component={DependentConfirmation}
            />
          </Switch>
        </PageContainer>
      </DependatFormProvider>
    </PageLayout>
  )
}

export default DependentRegistration
