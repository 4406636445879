import { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap';
import { ArrowDown, ArrowRight } from './icons';


const faq = [
    {id: 0, title: "When Are We Billed?", description: "Invoices are typically delivered before the first of the month.  New groups may be delayed pending open enrollment finalization."},
    {id: 1, title: "How To Pay", description: "We can automatically debit your account on the first of each month using Plaid or ACH. Updates after the 23rd of the month are reflected the following month. Alternatively, you can initiate the payment each month."},
    {id: 2, title: "Cutoff Dates", description: "Membership updates after the 20th will NOT appear on the invoice ~10 days later; these changes WILL appear the following month with retroactive adjustments."},
    {id: 3, title: "Your Bank Statement", description: "Adrem Administrators is an entity of Angle Health and may appear on your bank statement."},
]


export const Faq = () => {
    const [selected, setSelected] = useState<number|null>(null)

    const handleSelected = (id: number) => {
        selected == id ? setSelected(null) : setSelected(id);
    }
    return (
        <div>
            <Accordion style={{ position: "relative", top: 300, zIndex: 1 }}>
            <h3 className="text-primary">Questions?</h3>
                <Card style={{border: 'none'}}>
                    {faq.map((question) =>
                    <>
                        <Accordion.Toggle key={question.id} as={Card.Header} eventKey={String(question.id)} style={{justifyContent: 'start'}} className="text-primary" onClick={() => handleSelected(question.id)}>
                            {selected == question.id ? <ArrowDown/> : <ArrowRight/>} <span className="ml-4">{question.title}</span>
                        </Accordion.Toggle>
                        <Accordion.Collapse  eventKey={String(question.id)}>
                            <Card.Body>{question.description}</Card.Body>
                        </Accordion.Collapse>
                    </>
                    )}
                </Card>
            </Accordion>
        </div>
    )
}