import React, { useEffect, useState, useContext } from 'react'
import {
  Button,
  Form,
  Row,
  Table,
  useToaster
} from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { BusinessOnboardingContext } from '../../context/BusinessOnboardingContext'
import { businessOnboardingPaths } from '../../router/paths'
import { FooterActions } from '../FooterActions'
import { useQuery } from 'react-query'
import { disclosuresService, groupService } from '../../../../services'
import { TitleRow } from '../style'
import { AddOrEditModal } from './components/AddOrEditModal'
import { useDisclosureAddOrEdit } from './useDisclosureAddOrEdit'
import { headers } from './tableHeaders'
import { DisclosureTableBody } from './components/DisclosureTableBody'
import { useStep } from '../../hooks/use-step'
import { IBusinessOnboardingDTO } from '../../interfaces'
import { getNewCurrentStep } from '../../utils/utils'
import { TableContainerRow } from './style'
import { NewTabLink } from './components/NewTabLink'

export const icdCodeUrl =
  'https://uploads-ssl.webflow.com/612852a932348b6bdd179775/63640db731959e1221147d96_DisclosureCodes_2.pdf'

export const instructionsUrl =
  'https://angle-public-documents.s3.amazonaws.com/stop-loss-disclosure/Stop+Loss+Disclosure+Instructions.pdf'

export const DisclosureForm: React.FC = () => {
  const stepName = 'disclosure'

  const { showError } = useToaster()
  const history = useHistory()

  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false)
  const [customErrorMessage, setCustomErrorMessage] = useState('')

  const {
    data: disclosures,
    refetch,
    isLoading: isLoadingDisclosures
  } = useQuery('getDisclosures', () => disclosuresService.getDisclosures(), {
    refetchOnWindowFocus: false,
    refetchOnMount: 'always'
  })

  const {
    showModal,
    setShowModal,
    currentRow,
    onClickEditButton,
    onEdit,
    onDelete,
    control,
    formState,
    watch,
    register,
    onCloseModal,
    onCreate
  } = useDisclosureAddOrEdit(refetch)

  const { data: groupData } = useQuery(
    'getDisclosureGroupInfo',
    () =>
      groupService.getInfo(
        (data: IBusinessOnboardingDTO) => ({
          stepNumber: data.in_progress_state
        }),
        true
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (data: any) => {
        setState(data.stepNumber)
      }
    }
  )

  const { state, setState, modifyGroup } = useContext(BusinessOnboardingContext)

  useStep(state, 4, stepName, groupData?.stepNumber || 1)

  useEffect(() => {
    // if they have already completed this page and no disclosures exist set the checkbox to true
    if (groupData?.stepNumber > 4 && !disclosures?.length) {
      setIsCheckboxSelected(true)
    }
  }, [groupData?.stepNumber, disclosures])

  const handleContinue = async () => {
    if (isCheckboxSelected === true && !!disclosures?.length) {
      setCustomErrorMessage('Please deselect box or delete participants')
      return
    } else if (isCheckboxSelected === false && !disclosures?.length) {
      setCustomErrorMessage('Please select box or add at least 1 participant')
      return
    } else setCustomErrorMessage('')

    try {
      await modifyGroup.mutateAsync({
        in_progress_state: getNewCurrentStep(groupData.stepNumber, 5)
      })
      history.push(businessOnboardingPaths.contributionStrategy)
    } catch (error: any) {
      showError(error?.message)
    }
  }

  return (
    <>
      <AddOrEditModal
        showModal={showModal}
        currentRow={currentRow}
        register={register}
        formState={formState}
        onCloseModal={onCloseModal}
        watch={watch}
        control={control}
        onSave={currentRow ? onEdit : onCreate}
      />
      <div className="pt-2 pb-5">
        <div>
          <TitleRow
            noGutters
            className="d-flex flex-row flex-nowrap justify-content-between"
          >
            <div>
              <b>Disclosure Form</b>
              <p className="pt-2 text-muted text-wrap">
                Please review the{' '}
                <NewTabLink
                  href={instructionsUrl}
                  text="instructions for completion"
                />{' '}
                and list all plan participants who have been diagnosed with or
                treated during the current benefit period under a risk
                identifier category. To view the full list of codes,{' '}
                <NewTabLink href={icdCodeUrl} text="click here" />.
              </p>
            </div>
            <div className="pl-5">
              <Button
                variant="outline-primary text-nowrap"
                onClick={() => setShowModal(true)}
              >
                Add participants
              </Button>
            </div>
          </TitleRow>
          <Row noGutters className="pb-4">
            <div>
              <Form.Check
                type="checkbox"
                className="custom-checkbox"
                label="If there are no risks to report which meet the disclosure criteria
              above, please select this box."
                checked={isCheckboxSelected}
                onChange={(e) => setIsCheckboxSelected(e.target.checked)}
              />
              <div className="pt-4 text-danger">{customErrorMessage}</div>
            </div>
          </Row>
          <TableContainerRow noGutters>
            <Table
              headerClassName="thead-light"
              headers={headers}
              className="force-show-scrollbars"
              style={{
                overflowX: 'scroll',
                tableLayout: 'fixed',
                position: 'relative'
              }}
              loading={isLoadingDisclosures}
            >
              <DisclosureTableBody
                disclosures={disclosures || []}
                isLoading={isLoadingDisclosures}
                onEdit={onClickEditButton}
                onDelete={onDelete}
              />
            </Table>
          </TableContainerRow>
        </div>
      </div>
      <FooterActions
        continueHandler={handleContinue}
        backHandler={() =>
          history.push(businessOnboardingPaths.employerPreferences)
        }
      />
    </>
  )
}
