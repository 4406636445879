import React, { useContext } from 'react'
import {
  AccordionContext,
  useAccordionToggle,
  Button,
  Row,
  Col,
  Accordion,
  Card
} from 'react-bootstrap'
import { IFaq } from '../../interfaces'
import { sanitizerWithLinks } from '@angle/utils'
import { Avatar } from '../Avatar'

export interface IFaqAccordionHeader {
  eventKey: string
  faq: Pick<IFaq, 'title'>
  callback?: Function
}

export const FaqAccordionHeader: React.FC<IFaqAccordionHeader> = ({
  eventKey,
  faq,
  callback
}) => {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Button block variant="link" className="p-0" onClick={decoratedOnClick}>
      <Row>
        <Col className="text-left">
          <Avatar
            circle
            fallbackContent={
              <span style={{ color: isCurrentEventKey ? 'white' : 'black' }}>
                {Number(eventKey) + 1}
              </span>
            }
            color={isCurrentEventKey ? 'white' : 'black'}
            customBg={isCurrentEventKey ? 'var(--dark)' : 'var(--light)'}
            size="sm"
          />{' '}
          <b
            className="ml-1"
            dangerouslySetInnerHTML={{ __html: sanitizerWithLinks(faq.title) }}
          ></b>
        </Col>
        <Col xs="1">
          <i
            className={`icon icon-down-arrow text-dark ${
              isCurrentEventKey ? 'rotate-180' : ''
            }`}
          ></i>
        </Col>
      </Row>
    </Button>
  )
}

export const Faq: React.FC<{ faqs: IFaq[] }> = ({ faqs }) => {
  return (
    <Accordion className="mt-5" defaultActiveKey="0">
      {faqs.map((f, i) => (
        <Card
          key={i}
          className="border-left-0 border-right-0 border-top-0 border"
        >
          <Card.Header className="pl-2">
            <FaqAccordionHeader faq={f} eventKey={String(i)} />
          </Card.Header>
          <Accordion.Collapse eventKey={String(i)}>
            <Card.Body style={{ paddingLeft: '3.2em' }}>
              <p dangerouslySetInnerHTML={{ __html: f.description }}></p>

              <div key={i}>
                <p
                  className="fs-14 text-uppercase text-gray"
                  dangerouslySetInnerHTML={{
                    __html: sanitizerWithLinks(f.section?.title)
                  }}
                ></p>

                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitizerWithLinks(f.section?.text)
                  }}
                ></p>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}

export default Faq
