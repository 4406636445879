import { IBusinessOnboardingDTO, CarrierFormData } from '../../interfaces'
import { DateHandler } from '@angle/utils'
import { changingReasons } from './changingReasons'
import { getNewCurrentStep } from '../../utils/utils'

const getDate = (date: string | null) => {
  if (!date) return ''
  return new Date(date)
}

export const mapDTOtoCarrierInformation = (data: IBusinessOnboardingDTO) => {
  const isCustomReason = !Object.keys(changingReasons).includes(
    data.current_changing_reason
  )

  return {
    hasCurrentCarrier: data.has_current_carrier,
    currentCarrier: data.current_carrier?.medical_carrier,
    currentCarrierStartDate: getDate(data.current_carrier.medical_start_date),
    currentCarrierEndDate: getDate(data.current_carrier?.medical_term_date),
    hasPreviousCarrier: data.has_previous_carrier === true ? 'true' : 'false',
    lessThanThree: data.current_carrier_3_years === false ? 'true' : 'false',
    previousCarrier: data.previous_carrier?.medical_carrier,
    previousCarrierStartDate: getDate(data.previous_carrier.medical_start_date),
    previousCarrierEndDate: getDate(data.previous_carrier?.medical_term_date),
    hasDental: data.current_carrier?.dental_carrier ? 'true' : 'false',
    dentalCarrier: data.current_carrier?.dental_carrier,
    dentalStartDate: getDate(data.current_carrier.dental_start_date),
    dentalEndDate: getDate(data.current_carrier?.dental_term_date),
    hasVision: data.current_carrier?.vision_carrier ? 'true' : 'false',
    visionCarrier: data.current_carrier?.vision_carrier,
    visionStartDate: getDate(data.current_carrier.vision_start_date),
    visionEndDate: getDate(data.current_carrier?.vision_term_date),
    changingReason: isCustomReason ? 'Other' : data.current_changing_reason,
    otherChangingReason: isCustomReason ? data.current_changing_reason : '',
    previousChangingReason: data.previous_changing_reason,
    otherCarrierNames: data.other_carrier_names?.length
      ? data.other_carrier_names
      : [''],
    otherInsurance: !!data.other_carrier_names?.length ? 'true' : 'false',
    stepNumber: data.in_progress_state,
    isLargeGroup: data.group_size === 'large',
    isLevelFundedGroup: data.funding_type === 'level_funded',
    isFronting: data.insurer_type === 'fronting_arrangement',
  }
}

export const mapLargeGroupCarrierInformationToDTO = (
  formData: CarrierFormData,
  otherCarriers: string[],
  backendStepNumber: number,
  isFronting: boolean,
) => {
  let DTO: Record<string, any>
  if (!formData.currentCarrierToggle) {
    DTO = {
      has_current_carrier: true,
      current_carrier_3_years: formData.lessThanThree === 'true' ? false : true,
      current_carrier: {
        medical_carrier: formData.currentCarrier,
        medical_start_date: DateHandler.formatForServer(
          formData.currentCarrierStartDate
        ),
        medical_term_date: DateHandler.formatForServer(
          formData.currentCarrierEndDate
        )
      },
      current_changing_reason:
        formData.changingReason === 'Other' && formData.otherChangingReason
          ? formData.otherChangingReason
          : formData.changingReason,
      in_progress_state: getNewCurrentStep(backendStepNumber, 7 + (+isFronting))
    }
  } else {
    DTO = {
      has_current_carrier: false,
      current_carrier_3_years: false,
      current_carrier: {},
      current_changing_reason: '',
      in_progress_state: getNewCurrentStep(backendStepNumber, 7 + (+isFronting))
    }
  }

  // previous carrier info
  if (formData.hasPreviousCarrier === 'true') {
    DTO.previous_carrier = {
      medical_carrier: formData.previousCarrier,
      medical_start_date: DateHandler.formatForServer(
        formData.previousCarrierStartDate
      ),
      medical_term_date: DateHandler.formatForServer(
        formData.previousCarrierEndDate
      )
    }
    DTO.previous_changing_reason =
      formData.previousChangingReason === 'Other' &&
      formData.otherPreviousChangingReason
        ? formData.otherPreviousChangingReason
        : formData.previousChangingReason
  }

  // add list of other carriers
  if (otherCarriers.length) {
    if (otherCarriers[0] === '' && otherCarriers.length === 1) {
    } else {
      DTO.other_carrier_names = otherCarriers
    }
  }
  return DTO
}

export const mapSmallGroupCarrierInformationToDTO = (
  formData: CarrierFormData,
  backendStepNumber: number,
  hasCurrentCarrier: boolean,
  isFronting: boolean,
) => {
  const hasDental = formData.hasDental === 'true' && hasCurrentCarrier
  const hasVision = formData.hasVision === 'true' && hasCurrentCarrier

  // sending empty values if they select no on previous/other carriers
  const DTO: any = {
    has_current_carrier: hasCurrentCarrier,
    current_carrier_3_years: formData.lessThanThree !== 'true',
    current_carrier: {
      medical_carrier: hasCurrentCarrier ? formData.currentCarrier : '',
      medical_start_date: DateHandler.formatForServer(
        hasCurrentCarrier ? formData.currentCarrierStartDate : null
      ),
      medical_term_date: DateHandler.formatForServer(
        hasCurrentCarrier ? formData.currentCarrierEndDate : null
      ),
      dental_carrier: hasDental ? formData.dentalCarrier : '',
      dental_start_date: DateHandler.formatForServer(
        hasDental ? formData.dentalStartDate : ''
      ),
      dental_term_date: DateHandler.formatForServer(
        hasDental ? formData.dentalEndDate : null
      ),
      vision_carrier: hasVision ? formData.visionCarrier : '',
      vision_start_date: DateHandler.formatForServer(
        hasVision ? formData.visionStartDate : null
      ),
      vision_term_date: DateHandler.formatForServer(
        hasVision ? formData.visionEndDate : null
      )
    },
    current_changing_reason:
      formData.changingReason === 'Other' && formData.otherChangingReason
        ? formData.otherChangingReason
        : formData.changingReason,
    in_progress_state: getNewCurrentStep(backendStepNumber, 7 + (+isFronting))
  }

  return DTO
}
