import React from 'react'

export const ErrorFallback: React.FC<any> = ({
  children,
  text,
  variant = 'dark',
  display = 'block',
  error,
  resetErrorBoundary,
  ...props
}) => {
  return (
    <div className={`d-${display}`} {...props}>
      <p className="text-danger mb-0">
        {text ? text : children ? children : 'Something went wrong'}
      </p>
      <small className={`text-${variant}`}>
        If the issue continues, please reach out to your care team by phone{' '}
        <a
          href="tel:855
        937 1855"
        >
          (855 937 1855)
        </a>{' '}
        or sessions on your mobile app.
      </small>
    </div>
  )
}
