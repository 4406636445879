import { useQuery } from 'react-query'
import { useDebounce } from './use-debounce'

type SearchQueryFunction<T> = (search: string) => Promise<T>

type SearchQuery<T> = [string, SearchQueryFunction<T>]

type SearchOptions = {
  delay: number
  minCharacters: number
  defaultSearch?: boolean
}

const defaultOptions: SearchOptions = {
  delay: 200,
  minCharacters: 2
}

export function useSearch<T = any>(
  searchTerm: string,
  query: SearchQuery<T>,
  options: SearchOptions = defaultOptions
) {
  const { delay, minCharacters, defaultSearch } = options
  const [name, func] = query
  const debouncedSearchTerm = useDebounce(searchTerm, delay)
  const shouldSearch =
    (debouncedSearchTerm && debouncedSearchTerm.length > minCharacters) ||
    (defaultSearch && debouncedSearchTerm.length < minCharacters)

  const { data, ...others } = useQuery(
    [name, debouncedSearchTerm],
    () => func(debouncedSearchTerm),
    {
      // The query will not execute until shouldSearch be true
      enabled: !!shouldSearch
    }
  )

  return { results: data, ...others }
}
