import styled from 'styled-components'

export const ActionReminderContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e7eaf3;
  padding-bottom: 0.2rem;
`

export const Icon = styled.i<{ completed?: boolean }>`
  background-color: ${(props) =>
    props.completed ? 'var(--success)' : 'var(--primary-light)'};
  color: ${(props) => (props.completed ? 'var(--white)' : 'var(--primary)')};
  border-radius: 50%;
  height: 2.625rem;
  width: 2.625rem;
  margin-right: 1rem;
`
