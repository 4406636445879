import React, { useContext } from 'react'
import {
  CenteredSpinner,
  Form,
  useToaster
} from 'react-angle-dashboard-components'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { groupService } from '../../../../services'
import { BusinessOnboardingContext } from '../../context/BusinessOnboardingContext'
import { useStep } from '../../hooks/use-step'
import { businessOnboardingPaths } from '../../router/paths'
import { FooterActions } from '../FooterActions'
import {
  mapDTOtoEmployerPreferences,
  mapEmployerPreferencesToDTO
} from './mappers'
import { LevelFundedEmployerPreferences } from './LevelFunded/LevelFundedEmployerPreferences'
import { NonLevelFundedEmployerPreferences } from './NonLevelFunded/NonLevelFundedEmployerPreferences'

export const labels = {
  effectiveDate: 'Proposed effective date',
  eligibilityHours:
    'How many hours per week must an employee work to be eligible for coverage?',
  numOfCoveredEmployees: 'Total number of covered employees',
  numOfCoveredLives: 'Total number of covered lives',
  medicareNum:
    'Total number of current employees who receive wages, tips, or other compensation',
  numOfEmployees: 'Total number of employees eligible for coverage',
  newHireWaitingPeriod: 'First of the month following:'
}

export const EmployerPreferences: React.FC = () => {
  const history = useHistory()
  const toaster = useToaster()

  const { state, setState, modifyGroup } = useContext(BusinessOnboardingContext)

  const { handleSubmit, control, formState, reset, register } = useForm({
    mode: 'onChange'
  })

  const { data, isLoading } = useQuery(
    'getEmployerPreferences',
    () => groupService.getInfo(mapDTOtoEmployerPreferences, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      retry: 1,
      cacheTime: 0,
      onSuccess: (data: any) => {
        reset({ ...data })
        setState(data.stepNumber)
      }
    }
  )

  const handleContinue = handleSubmit(async (formData) => {
    const DTO = mapEmployerPreferencesToDTO(formData, data.stepNumber)

    try {
      await modifyGroup.mutateAsync(DTO)

      const path = data?.isFronting
        ? businessOnboardingPaths.disclosure
        : businessOnboardingPaths.contributionStrategy

      history.push(path)
    } catch (error: any) {
      toaster.show({
        message: error.message || 'An unknown error occurred',
        icon: 'icon-cancel',
        type: 'danger'
      })
    }
  })

  const handleBack = () => {
    history.push(businessOnboardingPaths.adminInformation)
  }

  useStep(state, 3, 'employerPreferences', data?.stepNumber || 1)

  return (
    <>
      <Form>
        {isLoading ? (
          <CenteredSpinner style={{ height: '44vh' }} />
        ) : data?.fundingType === 'level_funded' ? (
          <LevelFundedEmployerPreferences
            formState={formState}
            control={control}
            register={register}
          />
        ) : (
          <NonLevelFundedEmployerPreferences
            formState={formState}
            control={control}
            register={register}
            data={data}
          />
        )}
      </Form>
      <FooterActions
        continueHandler={handleContinue}
        backHandler={handleBack}
      />
    </>
  )
}
