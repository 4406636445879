import React from 'react'
import {
  Button,
  CenteredSpinner,
  Col,
  DatePicker,
  Form,
  PageContainer,
  Row,
  SectionTitle,
  useToaster,
  EmployeeService
} from '../../../index'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { Footer } from './style'
import { DateHandler } from '@angle/utils'
import { ErrorMessage } from '../ErrorMessage'

const terminationEvents = [
  'Voluntary',
  'Involuntary',
  'Job Eliminated',
  'Leave of Absence',
  'Member Deceased',
  'Military Leave',
  'Loss of Eligibility'
]

export type DisenrollLocationState = {
  reason: string
  date: string
  name: string
}

export const DisenrollEmployeeContent: React.FC<{
  isBrokerPortal?: boolean
  employeeService: EmployeeService
}> = ({ isBrokerPortal, employeeService }) => {
  const toaster = useToaster()
  const { groupId } = useParams<{ groupId: string }>()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const { control, formState, reset, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      event: '',
      date: ''
    }
  })

  const { data, isLoading, error } = useQuery(['employeeId', id], () =>
    employeeService.getEmployeeById(id, isBrokerPortal ? groupId : '')
  )

  const onTerminate = handleSubmit(async (d: any) => {
    const response = await employeeService.disenroll(
      id,
      {
        date: DateHandler.formatForServer(d.date),
        reason: d.event
      },
      isBrokerPortal ? groupId : ''
    )

    if (response.detail !== 'Success.')
      return toaster.show({
        message: 'Something went wrong',
        icon: 'icon-cancel',
        type: 'danger'
      })

    const state: DisenrollLocationState = {
      reason: 'disenroll',
      name: data?.name?.first_name + ' ' + data?.name?.last_name,
      date: d.date
    }

    const path = isBrokerPortal ? `/groups/${groupId}/employees` : '/employees'
    history.push(path, state)
    reset()
  })

  const invalid = !formState.isValid && formState.isSubmitted
  const errors = formState.errors

  if (isLoading) {
    return <CenteredSpinner />
  } else if (error) {
    return <ErrorMessage />
  }

  return (
    <PageContainer size="md">
      <SectionTitle>Personal information</SectionTitle>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label className="text-muted">First Name</Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="First Name"
                value={data?.name?.first_name}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label className="text-muted">Last Name</Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Last Name"
                value={data?.name?.last_name}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Termination Event</Form.Label>
              <Controller
                name="event"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control className="bg-white" as="select" {...field}>
                    <option value="" disabled hidden>
                      Select termination event
                    </option>
                    {terminationEvents.map((v) => (
                      <option id={v} value={v}>
                        {v}
                      </option>
                    ))}
                  </Form.Control>
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Termination Date</Form.Label>
              <Controller
                key="date"
                name="date"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field }) => <DatePicker {...field} />}
              />
              {errors?.date && (
                <small className="text-danger">{errors?.date?.message}</small>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {invalid && (
        <Row noGutters>
          <p className="text-danger mb-3">All inputs must be filled</p>
        </Row>
      )}
      <div className="text-muted mt-3 mb-3">
        Coverage for your employee will continue untill the end of the month.
      </div>
      <Footer>
        <Button variant="primary" ghost>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={onTerminate}
          disabled={!formState.isValid || formState.isSubmitting}
        >
          Terminate Employee
        </Button>
      </Footer>
    </PageContainer>
  )
}
