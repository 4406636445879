import { DateHandler } from '@angle/utils'
import { ColumnDataType, TableColumn, TableRow } from './types'

export const getFormattedDataBasedOnType = (
  data: string | null,
  dataType: ColumnDataType
) => {
  if (data === null || data === undefined) {
    return '-'
  }
  switch (dataType) {
    case ColumnDataType.string:
    case ColumnDataType.float:
    case ColumnDataType.integer:
      return data
    case ColumnDataType.boolean:
      return data === 'true' ? 'Active' : 'Inactive'
    case ColumnDataType.hundredBasedPercentage:
      return `${data}%`
    case ColumnDataType.isoDate:
      return DateHandler.formatForClient(data)
    case ColumnDataType.isoDateTime:
      return DateHandler.formatForClient(data)
    case ColumnDataType.money:
      return `$${Number(data).toLocaleString(undefined, {
        minimumFractionDigits: 2
      })}`
    case ColumnDataType.oneBasedPercentage:
      return `${(Number(data) * 100).toFixed(1)}%`
    default:
      return data
  }
}

export const generalCellStyle: React.CSSProperties = {
  fontSize: 14
}

export const getCellStyleBasedOnDataType = (
  dataType: ColumnDataType
): React.CSSProperties => {
  switch (dataType) {
    case ColumnDataType.float:
    case ColumnDataType.integer:
    case ColumnDataType.hundredBasedPercentage:
    case ColumnDataType.money:
    case ColumnDataType.oneBasedPercentage:
      return {
        ...generalCellStyle,
        textAlign: 'right'
      }
    default:
      return generalCellStyle
  }
}

export const getColumnFlexBasedOnContent = (
  columns: TableColumn[],
  rowData: TableRow[]
) =>
  columns.reduce((acc, col) => {
    let longestValue = col.columnHeader.length
    rowData.forEach((rd) => {
      const current = rd[col.columnKey]?.length ?? 0
      if (current > longestValue) {
        longestValue = current
      }
    })
    const flexVal = Math.min(Math.ceil(longestValue / 5), 10)
    acc[col.columnKey] = { flex: flexVal }
    return acc
  }, {})
