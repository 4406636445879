import React, { MouseEventHandler } from 'react'
import { Row, Col, Avatar, Badge } from '../../'

interface IDrugItem {
  name: string
  covered: boolean
  onClick: MouseEventHandler<HTMLLIElement>
}

export const DrugItem: React.FC<IDrugItem> = ({ name, covered, onClick }) => {
  return (
    <Row
      as="li"
      role="button"
      className="w-100 border-bottom py-3 m-0"
      onClick={onClick}
    >
      <Col
        className="d-flex p-0 align-items-center justify-content-start"
        xs={1}
      >
        <Avatar
          fallbackContent={<i className="icon icon-drugs text-primary" />}
          avatarClass="bg-primary-light"
          circle
        />
      </Col>
      <Col
        className="d-flex p-0 align-items-center justify-content-start pl-1"
        xs={10}
      >
        <b className="text-capitalize">{name.toLowerCase()}</b>
      </Col>
      <Col className="d-flex p-0 align-items-center justify-content-end" xs={1}>
        <Badge
          variant={covered ? 'success' : 'danger'}
          className="text-white text-capitalize py-2"
        >
          {covered ? 'Covered' : 'Not Covered'}
        </Badge>
      </Col>
    </Row>
  )
}
