import React from 'react'
import {
  Button,
  Table,
  useErrorHandler,
  useServerPagination,
  useToaster,
  DocumentService
} from '../../../index'
import { Statement } from '../../../interfaces/employer-broker-shared/billing'
import { useParams } from 'react-router-dom'

const Statements: React.FC<{
  isBrokerPortal: boolean
  documentService: DocumentService
  billingService: any
}> = ({ isBrokerPortal, documentService, billingService }) => {
  const { groupId } = useParams<{ groupId: string }>()
  const toaster = useToaster()

  const openStatementPdf = async (statementId: string, period: string) => {
    const path = isBrokerPortal
      ? `/groups/${groupId}/payments/statements/${statementId}`
      : `/payments/statements/${statementId}`

    try {
      await documentService.downloadDocument(path, {
        contentType: 'application/pdf',
        filename: `Angle Health Statement ${period}`
      })
    } catch (e: any) {
      toaster.showError(e.message || '')
    }
  }

  const { queryResult, paginationConfig } = useServerPagination(
    ['getStatementsList', groupId],
    ({ pageLimit, pageNumber }) => {
      return billingService.getStatementsList(
        pageNumber,
        pageLimit,
        isBrokerPortal ? groupId : ''
      )
    }
  )

  useErrorHandler(queryResult.error)

  const { data, isLoading: isStatementsLoading } = queryResult

  const statements: Statement[] = data?.statements || []

  return (
    <section
      className="mt-4"
      data-testid="table-section-statements"
      style={{ minHeight: '50vh' }}
    >
      <Table
        headers={[{ title: 'Billing Period' }, { title: '' }]}
        headerClassName="thead-light"
        loading={isStatementsLoading}
        paginationConfig={paginationConfig}
      >
        <tbody>
          {statements?.length === 0 && !isStatementsLoading && (
            <tr>
              <td className="py-5">
                <h6>No statements</h6>
              </td>
            </tr>
          )}
          {statements?.map((statement) => (
            <tr key={statement.id}>
              <td>
                <b>{statement.period}</b>
              </td>
              <td>
                <Button
                  className="p-0"
                  variant="link"
                  as={'a'}
                  target="blank"
                  onClick={() =>
                    openStatementPdf(statement.id, statement.period)
                  }
                >
                  <i className="icon icon-download d-inline mr-1" />
                  Download PDF
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </section>
  )
}

export default Statements
