import React, { useEffect, useState } from 'react'
import {
  DependentFormType,
  Button,
  Card,
  Col,
  Form,
  Row,
  EmployeeProfile,
  computeFamilyIndicator,
  Spinner,
  PlanService,
  SummaryService
} from '../../../index'
import { GroupData } from '../../../interfaces/employer-broker-shared/group'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import './AddPlanSelectionForm.css'

const AddPlanSelectionSchema = z.object({
  coverageStartDate: z.date(),
  planSelected: z.string().nonempty({ message: 'You must select a plan' }),
  subgroupSelected: z.string()
})

export type AddPlanSelectionFormFields = {
  planSelected: string
  coverageStartDate: Date
  subgroupSelected: string
}

export type AddPlanSelectionReturnData = {
  coverageStartDate: Date
  coverageTerminationDate: string
  groupId: string
  planName: string
  subgroup?: string
}

const DependentsRow = styled(Row)`
{
 padding: 0.5em;
 max-width: 700px;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
`

export const AddPlanSelectionForm: React.FC<{
  existingPlan?: string | null
  dependentArr: DependentFormType[]
  employeeFullName: string
  handleCancelCallback: () => void
  employee: EmployeeProfile | undefined
  returnDataToParent: (data: AddPlanSelectionReturnData) => void
  isMutationLoading: boolean
  planService: PlanService
  summaryService: SummaryService
  isBrokerPortal: boolean
  groupId?: string
}> = ({
  existingPlan,
  dependentArr,
  employeeFullName,
  handleCancelCallback,
  employee,
  returnDataToParent,
  isMutationLoading,
  planService,
  summaryService,
  isBrokerPortal,
  groupId
}) => {
  const [groupDataState, setGroupDataState] = useState<GroupData>()
  const [chosenSubgroup, setChosenSubgroup] = useState<string>('')

  const { data: groupData, isLoading: groupLoading } = useQuery(
    'getGroupForAddOrRenewal',
    () =>
      isBrokerPortal
        ? summaryService.getGroup(groupId)
        : summaryService.getGroup()
  )

  const { control, register, handleSubmit, formState, trigger, setValue } =
    useForm<AddPlanSelectionFormFields>({
      defaultValues: {
        planSelected: existingPlan ? existingPlan : '',
        coverageStartDate: new Date(),
        subgroupSelected: ''
      },
      resolver: zodResolver(AddPlanSelectionSchema)
    })

  const errors = formState.errors

  useEffect(() => {
    setGroupDataState(groupData)
  }, [groupLoading, groupData])

  const {
    data: planData,
    isLoading: planDataLoading,
    refetch,
    isRefetching
  } = useQuery(
    ['getPlansForGivenGroupInAddEmployee'],
    () => {
      const getListParams: { groupId?: string; subgroupId?: number } = {}

      if (isBrokerPortal) {
        getListParams.groupId = groupId
      }
      // Class Selection only matters if we can find subgroup associated with that class
      if (
        groupDataState?.classes &&
        groupDataState?.classes.length > 1 &&
        chosenSubgroup !== ''
      ) {
        const matchingClassesForSubgroup = groupDataState.classes.filter(
          (klass) => klass.description === chosenSubgroup
        )
        if (matchingClassesForSubgroup.length >= 1) {
          getListParams.subgroupId = matchingClassesForSubgroup[0].entryIdPK
        }
      }
      return planService.getList(getListParams)
    },
  )

  useEffect(() => {
    setValue('subgroupSelected', chosenSubgroup)
    if (
      groupDataState?.classes &&
      groupDataState?.classes?.length > 1 &&
      chosenSubgroup !== ''
    ) {
      refetch()
    }
  }, [chosenSubgroup, setValue, refetch])

  const handleSave: SubmitHandler<AddPlanSelectionFormFields> = async (
    data
  ) => {
    await trigger()
    const hasErrors = Object.keys(errors).length > 0

    if (
      !hasErrors &&
      groupDataState &&
      groupDataState.groupID &&
      groupDataState.coverageStart &&
      groupDataState.coverageEnd
    ) {
      const transformedData: AddPlanSelectionReturnData = {
        groupId: groupDataState.groupID,
        coverageStartDate: data.coverageStartDate,
        coverageTerminationDate: groupDataState.coverageEnd,
        planName: data.planSelected
      }

      if (data.subgroupSelected !== '') {
        transformedData.subgroup = data.subgroupSelected
      }

      returnDataToParent(transformedData)
    }
  }

  const getFamilyIndicatorForAllFlows = (
    employee: EmployeeProfile | undefined
  ): string => {
    if (!employee) {
      const hasSpouse = dependentArr.some(
        (dependent) => dependent.dependentType === 'spouse'
      )
      const hasChildren = dependentArr.some(
        (dependent) => dependent.dependentType === 'child'
      )
      if (hasSpouse && hasChildren) {
        return 'Employee + Family'
      } else if (hasSpouse) {
        return 'Employee + Spouse'
      } else if (hasChildren) {
        return 'Employee + Child'
      }
      return 'Employee'
    } else {
      const returnVal = computeFamilyIndicator(employee)
      switch (returnVal) {
        case 'employee':
          return 'Employee'
        case 'family':
          return 'Employee + Family'
        case 'employee_children':
          return 'Employee + Children'
        default:
          return 'Employee + Spouse'
      }
    }
  }

  if (planDataLoading || groupLoading || isRefetching || !groupDataState) {
    return <Spinner size="sm" className="ml-2" animation="border" />
  }

  // TODO 2473 - CSS the card borders at the bottom.
  return (
    <>
      <b className="pb-3">Plan Selection</b>
      <Row>
        <Col sm={12} md={6}>
          <Form.Group>
            <Form.Label>Coverage Start Date</Form.Label>
            <Controller
              control={control}
              name="coverageStartDate"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Select a date"
                  onChange={onChange}
                  selected={value}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <h6
        className="mb-3 d-flex justify-content-between"
        style={{ fontWeight: 700 }}
      >
        Employee Family
      </h6>
      <DependentsRow>
        <Card className="mx-1" style={{ minWidth: 60, marginBottom: 15 }}>
          <Card.Body className="d-flex justify-content-between">
            <div>
              <p className="mb-0">{employeeFullName}</p>
            </div>
          </Card.Body>
        </Card>
        {dependentArr?.map((d: any) => {
          return (
            <Card className="mx-1" style={{ minWidth: 60, marginBottom: 15 }}>
              <Card.Body className="d-flex justify-content-between">
                <div>
                  <p className="mb-0">
                    {d.firstName} {d.lastName}
                  </p>
                </div>
              </Card.Body>
            </Card>
          )
        })}
      </DependentsRow>
      <Row className="my-1 pb-1">
        <Col>
          <h6>
            <span style={{ fontWeight: 700 }}>Coverage Type:</span>{' '}
            {getFamilyIndicatorForAllFlows(employee)}
          </h6>
        </Col>
      </Row>
      {groupDataState.subgroups && groupDataState.subgroups?.length >= 1 && (
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label style={{ fontWeight: 700 }}>
                Available Billing Subgroups
              </Form.Label>
              <Form.Control
                as="select"
                className="bg-white"
                isInvalid={!!errors?.subgroupSelected}
                disabled={
                  groupDataState?.subgroups &&
                  groupDataState.subgroups.length === 1
                }
                value={chosenSubgroup}
                onChange={(e) => setChosenSubgroup(e.target.value)}
              >
                <option value="" disabled={true}>
                  {'Please select a subgroup'}
                </option>
                {groupDataState?.subgroups &&
                  groupDataState.subgroups.length > 0 &&
                  groupDataState.subgroups.map((subgroup) => (
                    <option value={subgroup}>{subgroup}</option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors?.subgroupSelected?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12} md={6}>
          <Form.Group>
            <Form.Label style={{ fontWeight: 700 }}>Available Plans</Form.Label>
            <Form.Control
              as="select"
              className="bg-white"
              isInvalid={!!errors?.planSelected}
              {...register('planSelected')}
            >
              <option value={''}>{'Please select a plan'}</option>
              {planData &&
                planData.plans.map((plan) => (
                  <option value={plan.plan_name}>{plan.plan_name}</option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors?.planSelected?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={12} md={6} className="pb-5">
          <Button
            className="float-left text-primary"
            variant="outline-primary"
            onClick={handleCancelCallback}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12} md={6}>
          <Button className="float-right" disabled={isMutationLoading} onClick={handleSubmit(handleSave)}>
            Save & Exit
          </Button>
        </Col>
      </Row>
    </>
  )
}
