import { ApiService } from './base'

import {
  IAllergy,
  IAllergyDTO,
  ILabDTO,
  ILabResult,
  IMedication,
  IMedicationDTO,
  IPatientAuthorization,
  IPatientAuthorizationDTO,
  IPatientClaim,
  IPatientClaimDTO,
  IPatientPlanDTO
} from '../../interfaces'

export class HealthProfileService extends ApiService {
  async getAllergies(): Promise<IAllergy[]> {
    return this.request({
      method: 'post',
      url: '/health-profile/allergies',
      credentials: true
    }).then((r) => mapAllergies(r.allergies))
  }

  async getLabs(): Promise<ILabResult[]> {
    return this.request({
      method: 'post',
      url: '/health-profile/labs',
      credentials: true
    }).then((r) => mapLabs(r.labs))
  }

  async getMedications(): Promise<IMedication[]> {
    return this.request({
      method: 'post',
      url: '/health-profile/medications',
      credentials: true
    }).then((r) => mapMedications(r.medications))
  }

  async getAuthorizations(): Promise<IPatientAuthorization[]> {
    return this.request({
      method: 'post',
      url: '/health-profile/authorizations',
      credentials: true
    }).then((r: { authorizations: IPatientAuthorizationDTO[] }) =>
      mapAuthorization(r.authorizations)
    )
  }

  async getMedicalClaims(): Promise<IPatientClaim[]> {
    return this.request({
      method: 'post',
      url: '/claims/medical',
      credentials: true
    }).then((r) => mapClaims(r.claims))
  }

  async getPatientPlanInfo(): Promise<IPatientPlanDTO> {
    return this.request({
      method: 'post',
      url: '/patient/plan',
      credentials: true
    })
  }
}

const mapMedications = (medications: IMedicationDTO[]): IMedication[] => {
  return medications.map((m: IMedicationDTO) => {
    return {
      ...m,
      provider: {
        name: m.provider.provider_name,
        profileLink: `/providers/${m.provider.provider_id}`
      },
      id: m.medication_id
    }
  })
}

const mapLabs = (labsDTO: ILabDTO[]): ILabResult[] => {
  return labsDTO.map((l: ILabDTO) => {
    return {
      ...l,
      id: l.labResultId,
      provider: {
        name: l.provider.provider_name,
        profileLink: `/providers/${l.provider.provider_id}`
      }
    }
  })
}

const mapAllergies = (allergiesDTO: IAllergyDTO[]): IAllergy[] => {
  return allergiesDTO.map((a: IAllergyDTO) => ({
    id: a.allergy_id,
    name: a.allergy_name,
    updateDate: a.updateDate || undefined
  }))
}

const mapAuthorization = (
  authorizationsDTO: IPatientAuthorizationDTO[]
): IPatientAuthorization[] => {
  return authorizationsDTO.map((a: IPatientAuthorizationDTO) => {
    return {
      date: a.date,
      provider: {
        name: a.provider.provider_name,
        profileLink: `/providers/${a.provider.provider_id}`
      },
      facility: {
        name: a.facility.facility_name,
        profileLink: `/facilities/${a.facility.facility_id}`
      },
      requestType: a.requestType,
      status: a.status,
      decisionLetterLink: a.decisionLetterURL,
      id: a.authorization_id
    }
  })
}

const mapClaims = (claimsDTO: IPatientClaimDTO[]): IPatientClaim[] => {
  return claimsDTO.map((c: IPatientClaimDTO) => {
    return {
      provider: c.provider_name,
      facility: c.facility_name,
      id: c.claim_id,
      date: c.date,
      status: c.status,
      cost: c.cost
    }
  })
}
