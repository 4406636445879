import React from 'react'
import { Container, IDCardContainer } from 'react-angle-dashboard-components'
import { useParams } from 'react-router-dom'
import { employeeService } from '../../services'
import { PageLayout } from '../layouts/PageLayout'
import { useQuery } from 'react-query'

export const EmployeeProfileIdCard: React.FC<{ match: any }> = () => {
  const { id, dependentId } = useParams<{ id: string; dependentId: string }>()
  const { data: dependent } = useQuery(
    ['dependent', dependentId],
    () => employeeService.getEmployeeProfile(dependentId),
    { enabled: !!dependentId }
  )

  const { data: employee } = useQuery(['employee', id], () =>
    employeeService.getEmployeeProfile(id)
  )

  const { data, isLoading, refetch, isRefetching} = useQuery(
    ['getEmployeeIdCard', dependentId || id],
    () => employeeService.getEmployeeIdCard(dependentId || id)
  )

  const breadcrumbs = dependentId
    ? [
        { title: 'Home', link: '/' },
        { title: 'Employees', link: '/employees' },
        {
          title: employee?.name || 'Loading...',
          link: `/employees/${id}`
        },
        {
          title: dependent?.name || 'Loading...',
          link: `/employees/${id}/dependents/${dependentId}`
        },
        {
          title: 'Member ID Card',
          link: `/employees/${id}/dependents/${dependentId}/id-card`
        }
      ]
    : [
        { title: 'Home', link: '/' },
        { title: 'Employees', link: '/employees' },
        {
          title: employee?.name || 'Loading...',
          link: `/employees/${id}`
        },
        {
          title: 'Member ID Card',
          link: `/employees/${id}/id-card`
        }
      ]

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <div style={{ textAlign: 'center' }}>
        <Container>
          <h1>Member ID Card</h1>
        </Container>
      </div>
      <IDCardContainer data={data} isLoading={isLoading} employeeService={employeeService} refetchMemberData={refetch} isRefetching={isRefetching} isAdmin={true}/>
    </PageLayout>
  )
}
