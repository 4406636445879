import React, { ReactElement, ReactNode } from 'react'
import { NavDropdown, Nav } from 'react-bootstrap'

import {
  DropdownItem,
  DropdownItemIcon,
  DropdownContent,
  DropdownTitle,
  DropdownSubtitle
} from './styles'

export interface INavDropdownItem {
  title: string
  onClick?: () => void
  subtitle?: string
  iconElement?: ReactElement
  icon?: string
}

export interface INavItemProp {
  onClick?: () => void
  title: string
  titleBadge?: ReactNode
  items?: INavDropdownItem[]
  active?: boolean
  disabled?: boolean
  showSubtitle?: boolean
}

export const NavItem: React.FC<INavItemProp> = ({
  title,
  items,
  onClick,
  active,
  disabled,
  titleBadge,
  showSubtitle = true
}) => {
  if (items) {
    return (
      <NavDropdown
        title={
          <span className={`dd-link ${active ? 'active' : ''}`}>{title}</span>
        }
        key={title}
        id={title}
        disabled={disabled}
      >
        {showSubtitle && <h5 className="pl-3">{title}</h5>}
        {items.map((dropdown, k) => (
          <NavDropdown.Item key={k} onClick={dropdown.onClick}>
            <DropdownItem>
              <DropdownItemIcon>
                {dropdown.iconElement ? (
                  dropdown.iconElement
                ) : (
                  <i className={`icon icon-${dropdown.icon}`} />
                )}
              </DropdownItemIcon>
              <DropdownContent>
                <DropdownTitle className="fs-18">
                  {dropdown.title}
                </DropdownTitle>
                <DropdownSubtitle className="text-muted">
                  {dropdown.subtitle}
                </DropdownSubtitle>
              </DropdownContent>
            </DropdownItem>
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    )
  }

  return (
    <Nav.Link onClick={onClick} key={title} active={active} disabled={disabled}>
      {title} {!!titleBadge && titleBadge}
    </Nav.Link>
  )
}

export default NavItem
