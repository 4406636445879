import {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useRef,
  useState
} from 'react'

export interface ICheckOption {
  text: string
  value: string
}

export const useMultiCustomCheck = (
  options: ICheckOption[],
  initialValues: string[]
) => {
  const values = options.map((e) => e.value)

  const left = initialValues.filter((e) => !values.includes(e))

  const current = left.find((e) => Boolean(e))

  const inputRef = useRef<any>()

  const [isCustomChecked, setCustomChecked] = useState(!!current)

  useEffect(() => {
    setCustomChecked(!!current)
    if (inputRef.current && current) {
      inputRef.current.value = current
    }
  }, [current])

  const init = (value: string[], onChange: Function) => {
    const removeCustomValue = () => value.filter((v: any) => values.includes(v))

    const onCheckChanges: ChangeEventHandler<HTMLInputElement> = (ev) => {
      setCustomChecked(ev.target.checked)
      if (ev.target.checked) {
        const customValue = inputRef?.current?.value
        if (customValue) onChange([...value, customValue])
        return
      }

      const valuesWithNoCustom = removeCustomValue()
      onChange([...valuesWithNoCustom])
    }

    const onInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      const valuesWithNoCustom = removeCustomValue()
      const customValue = e.target.value
      if (customValue) onChange([...valuesWithNoCustom, customValue])
    }

    return {
      onCheckChanges,
      removeCustomValue,
      onInputBlur
    }
  }

  return {
    init,
    defaultValue: current,
    defaultChecked: !!current,
    inputRef,
    isCustomChecked,
    setCustomChecked
  }
}
