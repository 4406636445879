import React from 'react'
import {
  Container,
  Row,
  useLoginFormHandler,
  useRememberAccount,
  useQueryParams
} from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { LoginForm } from '../../components/LoginForm'
import { Logo } from '../../components/Logo'
import { AuthContext } from '../../context/Auth/Auth'
import { Paths } from '../../routes/paths'
import { FormWrapper, LogoWrapper } from './style'
import { ApiService } from '../../services'

export interface LoginFormData {
  email: string
  password: string
  remember: boolean
}

export const Login = () => {
  const history = useHistory()

  const renewal = useQueryParams().get('renewal')

  const isRenewalLink = renewal === 'true'

  const token = localStorage.getItem('token')
  if (token) {
    ApiService._token = token

    if (isRenewalLink) {
      history.push(Paths.newBusinessOnboarding)
    } else {
      history.push('/summary')
    }
  }

  const {
    onSubmit,
    register,
    formState: { isSubmitting, isValid, errors },
    setValue,
    watch,
    setError
  } = useLoginFormHandler(
    {
      onSuccess: ({ result: response }) => {
        if (response.access_token) {
          if (isRenewalLink) {
            history.push(Paths.newBusinessOnboarding)
          } else {
            history.push('/summary')
          }
        }
      },
      onError: (e) => {
        // @ts-ignore
        setError('email', {
          type: 'manual',
          message: 'Invalid username/password'
        })
        console.error(e)
      }
    },
    AuthContext
  )

  useRememberAccount(setValue, watch)

  return (
    <Container
      fluid
      className="px-0 vh-100 m-auto d-flex flex-column justify-content-center align-items-center"
    >
      <FormWrapper>
        <Row className="mr-auto ml-auto mb-6">
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Row>
        <LoginForm
          register={register as any}
          onSubmit={onSubmit}
          watch={watch}
          errors={errors}
          isSubmitting={isSubmitting}
          isValid={isValid}
          onPasswordForgetHandler={() => history.push(Paths.forgotPassword)}
        />
      </FormWrapper>
    </Container>
  )
}

export default Login
