import React from 'react'
import { InfoWithIcon } from '../../../../index'

export const UnableToLoad: React.FC = () => (
  <InfoWithIcon
    iconEl={
      <i
        className="icon icon-error text-danger"
        style={{ fontSize: '35px', marginBottom: '0.625rem' }}
      />
    }
  >
    We were unable to load your information. If your issue continues please
    <a
      style={{ textDecoration: 'underline', paddingLeft: 3 }}
      href="tel://+18559371855"
    >
      contact member services.
    </a>
  </InfoWithIcon>
)
