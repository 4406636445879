import React from 'react'
import styled from 'styled-components'
import { Color } from 'react-bootstrap/types'

const PortalName = styled.h3<{ size: string }>`
  display: inline;
  margin-left: 0.2em;
  vertical-align: middle;
  font-size: ${(props) =>
    props.size === 'sm' ? '0.8em' : props.size === 'lg' ? '2.8em' : 'auto'};
`

export interface ILogoProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'white' | 'small' | 'simple' | 'default'
  withContainer?: boolean
  text?: string
  color?: string
}

export type LogoPropVariant = 'simple' | 'default' | 'full'
export type LogoPropSize = 'xs' | 'sm' | 'md' | 'lg'

export interface LogoProps {
  variant: LogoPropVariant
  size: LogoPropSize
  color: Color | 'angle-primary'
  text: string
  textColor?: Color
  subTitleColor: string
  width?: number
  height?: number
  [x: string]: any
}

export const Logo: React.FC<Partial<LogoProps>> = ({
  variant = 'full',
  size = 'md',
  subTitleColor = '#212529',
  color = 'white',
  textColor,
  text,
  width: customWidth,
  height: customHeight,
  ...p
}) => {
  const sizeVariation: Record<LogoPropSize, number> = {
    lg: +15,
    md: 0,
    sm: -15,
    xs: -30
  }

  const svgContainerVariants: Record<
    LogoPropVariant,
    { width: number; height: number }
  > = {
    simple: {
      width: 26,
      height: 35
    },
    full: {
      width: 92,
      height: 50
    },
    default: {
      width: 250,
      height: 100
    }
  }

  const svgContainerViewbox: Record<
    LogoPropVariant,
    { width: number; height: number }
  > = {
    simple: {
      width: 550,
      height: 650
    },
    full: {
      height: 500,
      width: 2200
    },
    default: {
      height: 1000,
      width: 2700
    }
  }

  const { width, height } = svgContainerVariants[variant]
  const sizeAdjustment = sizeVariation[size]
  const { width: viewboxWidth, height: viewboxHeight } =
    svgContainerViewbox[variant]

  return (
    <div className="d-inline-block" {...p}>
      <svg
        width={customWidth || width + sizeAdjustment}
        height={customHeight || height + sizeAdjustment}
        viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M190.209 461.213H385.629L430.618 573.621H554.339L469.18 353.693H230.586L190.209 461.213Z"
          fillRule="evenodd"
          clipRule="evenodd"
          className={`fill-${color}`}
        />
        <path
          d="M288.881 198.459L339.033 332.515H461.146L346.828 44.1536L288.881 198.459Z"
          fillRule="evenodd"
          clipRule="evenodd"
          className={`fill-${color}`}
        />
        <path
          d="M334.791 13.7914L334.212 12.3323H220.132L0.00634766 573.621H123.727L277.172 167.161L277.184 167.192L334.791 13.7914Z"
          fillRule="evenodd"
          clipRule="evenodd"
          className={`fill-${color}`}
        />
        {(variant === 'full' || variant === 'default') && (
          <React.Fragment>
            <path
              d="M709.533 349.765C709.533 304.622 736.461 268.982 782.396 268.982C833.083 268.982 854.467 303.038 854.467 346.597V573.897H959.801V328.381C959.801 242.847 915.45 173.944 818.828 173.944C776.852 173.944 730.125 192.16 706.365 232.551V184.24H604.199V573.897H709.533V349.765Z"
              fillRule="evenodd"
              clipRule="evenodd"
              className={`fill-${color}`}
            />
            <path
              d="M1027.67 595.281C1038.76 669.728 1110.04 736.255 1216.96 736.255C1363.48 736.255 1421.29 638.84 1421.29 531.922V184.24H1319.92V230.175C1306.45 204.039 1270.81 177.112 1209.04 177.112C1102.12 177.112 1027.67 264.23 1027.67 367.189C1027.67 476.483 1105.29 556.474 1209.04 556.474C1265.27 556.474 1301.7 531.922 1317.54 506.578V535.09C1317.54 610.329 1280.32 643.592 1213.79 643.592C1163.9 643.592 1129.84 612.705 1122.71 569.938L1027.67 595.281ZM1227.25 466.187C1172.61 466.187 1133.8 428.172 1133.8 367.189C1133.8 306.998 1174.98 268.982 1227.25 268.982C1277.94 268.982 1319.12 306.998 1319.12 367.189C1319.12 427.38 1281.11 466.187 1227.25 466.187Z"
              fillRule="evenodd"
              clipRule="evenodd"
              className={`fill-${color}`}
            />
            <path
              d="M1621.98 573.897V0.498657H1516.64V573.897H1621.98Z"
              fillRule="evenodd"
              clipRule="evenodd"
              className={`fill-${color}`}
            />
            <path
              d="M1800.26 334.717C1802.63 299.078 1832.73 257.895 1887.37 257.895C1947.57 257.895 1972.91 295.91 1974.49 334.717H1800.26ZM1984.79 436.092C1972.12 470.939 1945.19 495.491 1896.09 495.491C1843.82 495.491 1800.26 458.267 1797.88 406.788H2076.66C2076.66 405.204 2078.24 389.364 2078.24 374.317C2078.24 249.183 2006.17 172.36 1885.79 172.36C1786 172.36 1694.13 253.143 1694.13 377.485C1694.13 508.954 1788.38 585.777 1895.29 585.777C1991.13 585.777 2052.9 529.546 2072.7 462.227L1984.79 436.092Z"
              fillRule="evenodd"
              clipRule="evenodd"
              className={`fill-${color}`}
            />
          </React.Fragment>
        )}
        {variant === 'default' && (
          <React.Fragment>
            <path
              d="M1738.48 966.417V661.592H1696.35V791.863H1540.71V661.592H1498.58V966.417H1540.71V830.987H1696.35V966.417H1738.48Z"
              fill={subTitleColor}
            />
            <path
              d="M1826.61 841.735C1828.33 815.079 1848.96 788.853 1882.93 788.853C1919.47 788.853 1938.82 812.07 1939.68 841.735H1826.61ZM1945.7 895.477C1937.96 918.694 1921.19 937.181 1887.66 937.181C1852.83 937.181 1826.18 911.385 1825.32 874.41H1980.95C1981.38 871.831 1981.81 866.672 1981.81 861.512C1981.81 797.022 1946.13 753.168 1882.5 753.168C1830.47 753.168 1783.61 797.452 1783.61 862.372C1783.61 932.452 1832.19 972.866 1887.66 972.866C1935.81 972.866 1968.91 944.06 1980.52 907.515L1945.7 895.477Z"
              fill={subTitleColor}
            />
            <path
              d="M2009.19 910.955C2009.19 943.63 2036.27 972.866 2078.41 972.866C2115.38 972.866 2136.02 953.089 2145.05 938.471C2145.05 953.519 2146.34 961.257 2147.2 966.417H2186.75C2185.89 961.257 2184.17 950.509 2184.17 932.452V828.837C2184.17 788.423 2160.1 753.168 2098.19 753.168C2053.47 753.168 2017.79 780.684 2013.49 820.238L2052.18 829.267C2054.76 805.191 2070.67 787.563 2099.05 787.563C2130.43 787.563 2143.76 804.331 2143.76 824.538C2143.76 831.847 2140.32 838.296 2127.85 840.016L2071.96 848.184C2036.27 853.344 2009.19 873.98 2009.19 910.955ZM2084 938.901C2063.36 938.901 2050.46 924.283 2050.46 908.805C2050.46 890.318 2063.36 880.429 2080.56 877.85L2143.76 868.391V877.85C2143.76 922.993 2117.1 938.901 2084 938.901Z"
              fill={subTitleColor}
            />
            <path
              d="M2276.23 966.417V655.143H2235.82V966.417H2276.23Z"
              fill={subTitleColor}
            />
            <path
              d="M2387.84 694.267H2350.86V727.802C2350.86 745.86 2341.41 759.617 2319.05 759.617H2307.87V796.162H2347.42V909.665C2347.42 947.07 2369.78 968.566 2405.04 968.566C2418.79 968.566 2429.11 965.987 2432.55 964.697V930.302C2429.11 931.162 2421.8 932.022 2416.64 932.022C2395.58 932.022 2387.84 922.563 2387.84 903.646V796.162H2432.55V759.617H2387.84V694.267Z"
              fill={subTitleColor}
            />
            <path
              d="M2515.6 844.745C2516.46 814.219 2532.79 790.143 2563.75 790.143C2599.86 790.143 2611.9 813.359 2611.9 842.165V966.417H2652.32V835.286C2652.32 789.283 2627.81 753.598 2578.37 753.598C2554.72 753.598 2530.64 761.767 2515.6 783.694V655.143H2475.18V966.417H2515.6V844.745Z"
              fill={subTitleColor}
            />
          </React.Fragment>
        )}
      </svg>

      <PortalName size={size} className={`text-${textColor || color}`}>
        {text}
      </PortalName>
    </div>
  )
}

export default Logo
