import React, { useState } from 'react'
import { Pagination } from '../../Pagination'

export interface IDirectoryPaginationProps {
  page: number
  amountOfRecords: number
  setPage: Function
  perPage: number
}

export const DirectoryPagination: React.FC<IDirectoryPaginationProps> = ({
  page,
  setPage,
  amountOfRecords,
  perPage
}) => {
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setPageLimit] = useState(10)

  const totalItems = 25
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const paginationConfig = {
    totalPages: totalPages,
    currentPage: activePage,
    setPage: setActivePage,
    totalItems: totalItems,
    pageLimit: itemsPerPage,
    setPageLimit
  }

  return (
    <div>
      <Pagination {...paginationConfig} />
    </div>
  )
}

export default DirectoryPagination
