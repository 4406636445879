import React, { useContext } from 'react'
import { Carousel as RBCarousel } from 'react-bootstrap'
import { CornerOption, Image } from './Styles'
import { Thumbnail } from './Thumbnail'
import { GalleryContext } from './context'

export const ImagesCarousel = () => {
  const { activeIndex, handleSelect, images } = useContext(GalleryContext)

  const counterTitle = `${activeIndex + 1}/${images.length}`

  return (
    <React.Fragment>
      <h6 className="text-center">{counterTitle}</h6>
      <div>
        <RBCarousel
          activeIndex={activeIndex}
          onSelect={handleSelect}
          slide={false}
          interval={null}
          indicators={false}
        >
          {images.map((img, i) => (
            <RBCarousel.Item
              key={i}
              className="mb-3"
              style={{
                height: '50vh'
              }}
            >
              <Image className="d-block" src={img.src} alt="First slide" />
            </RBCarousel.Item>
          ))}
        </RBCarousel>
      </div>
      <div className="mt-4 d-none d-sm-block">
        <Thumbnail />
      </div>
    </React.Fragment>
  )
}

export const Carousel = () => {
  const { toggleModal } = useContext(GalleryContext)

  return (
    <section id="carousel-container" className="p-5">
      <CornerOption onClick={toggleModal}>
        <i className="btn icon icon-xs text-muted icon-cancel"></i>
      </CornerOption>
      <ImagesCarousel />
    </section>
  )
}

export default Carousel
