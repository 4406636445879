import React from 'react'
import { InfoWithIcon } from '../../index'

export const ErrorMessage: React.FC = () => (
  <InfoWithIcon
    iconEl={
      <i
        className="icon icon-error text-danger"
        style={{ fontSize: '35px', marginBottom: '0.625rem' }}
      />
    }
  >
    An unknown error ocurred. Please contact Angle Health for support.
  </InfoWithIcon>
)
