import React from 'react'
import { Toast as RBToast, ToastProps as RBToastProps } from 'react-bootstrap'
import classnames from 'classnames'

type ToastContainerProps = RBToastProps & React.HTMLAttributes<HTMLDivElement>

export interface IToastProps extends ToastContainerProps {
  icon?: string
  size?: 'lg' | 'sm'
  variant?: 'success' | 'danger' | 'warning' | 'info' | 'primary' | 'secondary'
}

export const Toast: React.FC<IToastProps> = ({
  icon,
  variant,
  children,
  size = 'sm',
  className,
  ...others
}) => {
  const containerClass = classnames('border', `border-${variant}`)
  const iconClass = classnames(
    'icon',
    icon,
    `text-${variant || 'muted'}`,
    'mr-2'
  )

  const headerContainer = classnames(`py-${size === 'sm' ? '2' : '3'}`)

  return (
    <RBToast className={`${containerClass} ${className || ''}`} {...others}>
      <RBToast.Header
        className={headerContainer}
        style={{ borderRadius: '5px' }}
        closeButton={false}
      >
        {icon && (
          <i
            id="toast-icon"
            className={iconClass}
            style={{ width: 'auto', height: 'auto' }}
          />
        )}
        <span className="text-dark mr-auto">{children}</span>
        <i
          onClick={others.onClose}
          role="button"
          className="icon icon-cancel text-muted"
          style={{ width: 'auto', height: 'auto' }}
        />
      </RBToast.Header>
    </RBToast>
  )
}

export default Toast
