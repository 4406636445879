import React, {
  CSSProperties,
  useReducer,
  useContext,
  useCallback
} from 'react'
import styled from 'styled-components'
import { Toast } from './Toast'

export interface IToasterContextState {
  message: string
  icon?: string
  type?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info'
  isShowing: boolean
  autoDismissible?: boolean
  dimissibleIn?: number
}

type IToasterShowProps = Omit<IToasterContextState, 'isShowing'>

export interface IToasterProviderFunctions {
  show: (props: IToasterShowProps) => void
  hide: () => void
  showError: (errorMessage?: string, autoDismissable?: boolean) => void
  showSuccess: (successMessage: string) => void
}

const initialState: IToasterContextState = {
  message: '',
  isShowing: false,
  autoDismissible: true
}

const ToastContainer = styled.div`
  flex-basis: 400px;
  max-width: 400px;
  z-index: 9999;
  top: '100px',
  left: '50%',
  position: fixed;

  @media (max-width: 576px) {
    right: 0px;
    margin: 0.5em;
  }
`

const defaultStyles: CSSProperties = {
  flexBasis: '400px',
  maxWidth: '400px',
  zIndex: 10000,
  top: '100px',
  left: '50%',
  position: 'fixed'
}

export const ToasterContext = React.createContext<
  IToasterContextState & IToasterProviderFunctions
>({
  message: '',
  isShowing: false,
  autoDismissible: true,
  hide: () => {},
  show: (props: IToasterShowProps) => {},
  showError: () => {},
  showSuccess: () => {}
})

export const ToasterProvider: React.FC<{ styles?: CSSProperties }> = ({
  children,
  styles = {}
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { isShowing, message, type, icon, autoDismissible, dimissibleIn } =
    state as IToasterContextState

  const show = useCallback(
    (payload: IToasterShowProps) => dispatch({ type: 'SHOW_TOASTER', payload }),
    [dispatch]
  )

  const hide = useCallback(() => dispatch({ type: 'HIDE_TOASTER' }), [dispatch])

  const showError = (errorMessage?: string, autoDismissible?: boolean) => {
    show({
      message: errorMessage || 'An unknown error occurred',
      icon: 'icon-cancel',
      type: 'danger',
      autoDismissible: autoDismissible === false ? false : true
    })
  }

  const showSuccess = (successMessage: string) => {
    show({
      message: successMessage,
      icon: 'icon-check',
      type: 'success'
    })
  }

  return (
    <ToasterContext.Provider
      value={{ ...state, show, hide, showError, showSuccess }}
    >
      <ToastContainer style={styles}>
        <Toast
          style={{ ...defaultStyles, ...styles }}
          autohide={autoDismissible}
          delay={dimissibleIn}
          show={isShowing}
          variant={type}
          icon={icon}
          onClose={hide}
        >
          {message}
        </Toast>
      </ToastContainer>

      {children}
    </ToasterContext.Provider>
  )
}

export const useToaster = () => useContext(ToasterContext)

function reducer(
  state: IToasterContextState,
  action: any
): IToasterContextState {
  switch (action.type) {
    case 'SHOW_TOASTER':
      return {
        ...state,
        ...action.payload,
        isShowing: true
      }

    case 'HIDE_TOASTER':
      return {
        ...state,
        isShowing: false
      }

    default:
      return action.state
  }
}
