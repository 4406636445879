import React, { useContext } from 'react'
import { CenteredSpinner } from 'react-angle-dashboard-components'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { groupService } from '../../../../services'
import { BusinessOnboardingContext } from '../../context/BusinessOnboardingContext'
import { useStep } from '../../hooks/use-step'
import { SmallGroupCarrierInformation } from './SmallGroup/SmallGroupCarrierInformation'
import { LargeGroupCarrierInformation } from './LargeGroup/LargeGroupCarrierInformation'
import { LevelFundedGroupCarrierInformation } from './LevelFunded/LevelFundedGroupCarrierInformation'
import { mapDTOtoCarrierInformation } from './mappers'
import { CarrierData } from '../../interfaces'

export const CarrierInformation: React.FC = () => {
  const { state, setState, modifyGroup } = useContext(BusinessOnboardingContext)

  const {
    handleSubmit,
    control,
    formState,
    reset,
    setValue,
    watch,
    unregister
  } = useForm({
    mode: 'onChange'
  })

  const { data, isLoading, isFetching } = useQuery(
    'getCarrierInformation',
    () => groupService.getInfo(mapDTOtoCarrierInformation, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (data: CarrierData) => {
        reset({ ...data })
        setState(data.stepNumber)
      }
    }
  )

  useStep(state, 6, 'carrierInformation', data?.stepNumber || 1)

  if (isLoading || isFetching) {
    return <CenteredSpinner style={{ height: '40vh' }} />
  }

  if (data?.isLevelFundedGroup) {
    return (
      <LevelFundedGroupCarrierInformation
        data={data}
        formState={formState}
        control={control}
        handleSubmit={handleSubmit}
        modifyGroup={modifyGroup}
        watch={watch}
        unregister={unregister}
        setValue={setValue}
      />
    )
  }

  if (data?.isLargeGroup) {
    return (
      <LargeGroupCarrierInformation
        data={data}
        formState={formState}
        control={control}
        handleSubmit={handleSubmit}
        modifyGroup={modifyGroup}
        watch={watch}
        unregister={unregister}
        setValue={setValue}
      />
    )
  }

  return (
    <SmallGroupCarrierInformation
      data={data}
      formState={formState}
      control={control}
      handleSubmit={handleSubmit}
      modifyGroup={modifyGroup}
      watch={watch}
      setValue={setValue}
      unregister={unregister}
    />
  )
}
