import React from 'react'
import { DisclaimerText, TableDescription } from './style'
import { PageHeaderLight } from '../PageHeaderLight'
import { PageContainer } from '../PageContainer'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Table } from '../Table'
import { useHistory } from 'react-router-dom'
import { Document, PDFDownloadLink } from '@react-pdf/renderer'
import { ExtraInfo, TableColumn, TableRow, TableSummary } from './types'
import {
  generalCellStyle,
  getCellStyleBasedOnDataType,
  getFormattedDataBasedOnType
} from './funcs'
import ReportPage from './ReportPage'

export interface ReportTableInfo {
  title: string
  groupName: string
  description: string
  table: {
    columns: TableColumn[]
    rowData: TableRow[]
    summaryData: TableSummary
  }
  extra: ExtraInfo
  footer: string
}

export interface ReportTableProps extends ReportTableInfo {
  hideHeader: boolean
}

const ReportTable = ({
  title,
  groupName,
  description,
  table,
  extra,
  footer,
  hideHeader
}: ReportTableProps) => {
  const history = useHistory()

  const { columns, rowData, summaryData } = table

  return (
    <>
      {!hideHeader && (
        <PageHeaderLight>
          <PageContainer size="lg">
            <Row>
              <Col md={8}>
                <h1>{title}</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableDescription>{description}</TableDescription>
              </Col>
            </Row>
            <Row>
              <Col>{groupName}</Col>
            </Row>
          </PageContainer>
        </PageHeaderLight>
      )}
      <Container>
        <div className="w-full d-flex justify-content-between mb-4">
          <Button variant="link" onClick={() => history.goBack()}>
            Back
          </Button>
          <PDFDownloadLink
            document={
              <Document>
                <ReportPage
                  title={title}
                  groupName={groupName}
                  description={description}
                  table={table}
                  footer={footer}
                  extra={extra}
                />
              </Document>
            }
            fileName={`${groupName} ${title} Report.pdf`}
          >
            <Button variant="primary">Download</Button>
          </PDFDownloadLink>
        </div>
        <div id="report-table">
          <Table
            tableContainerProps={{
              style: {
                fontSize: 14
              }
            }}
          >
            <thead>
              <tr
                style={{
                  color: '#6C757D',
                  backgroundColor: '#F8F9FA',
                  fontWeight: 600
                }}
              >
                {columns.map((col) => (
                  <td style={getCellStyleBasedOnDataType(col.dataType)}>
                    {col.columnHeader.toUpperCase()}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowData.map((rd, rIndex) => {
                return (
                  <tr
                    style={{
                      color: '#6C757D'
                    }}
                  >
                    {columns.map(({ columnKey, dataType }) => (
                      <td
                        style={{
                          ...getCellStyleBasedOnDataType(dataType),
                          whiteSpace: 'normal'
                        }}
                      >
                        {getFormattedDataBasedOnType(rd[columnKey], dataType)}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
            <tfoot>
              <tr
                style={{
                  color: '#212529',
                  backgroundColor: '#F8F9FA'
                }}
              >
                {columns.map(({ columnKey, dataType }, index) => {
                  if (index === 0) {
                    return <td>Totals</td>
                  }
                  return (
                    <td style={getCellStyleBasedOnDataType(dataType)}>
                      {getFormattedDataBasedOnType(
                        summaryData[columnKey],
                        dataType
                      )}
                    </td>
                  )
                })}
              </tr>
            </tfoot>
          </Table>
          <div className="py-3">
            <Table style={{ width: '50%' }}>
              {extra.fields.map((field) => (
                <tr>
                  <td style={generalCellStyle}>{field.fieldDescription}:</td>
                  <td style={generalCellStyle}>
                    {getFormattedDataBasedOnType(
                      extra.data[field.fieldKey],
                      field.dataType
                    )}
                  </td>
                </tr>
              ))}
            </Table>
          </div>
          <div className="py-3">
            <DisclaimerText>{footer}</DisclaimerText>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ReportTable
