import React from 'react'
import { EmployerPortalEmployees } from 'react-angle-dashboard-components'
import { employeeService } from '../../services'
import { PageLayout } from '../layouts/PageLayout'

export const Employees: React.FC = () => {
  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Employees', link: '/employees' }
      ]}
    >
      <EmployerPortalEmployees
        employeeService={employeeService}
        isBrokerPortal={false}
      />
    </PageLayout>
  )
}
