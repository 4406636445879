import styled from 'styled-components'
import { Image as BSImage } from 'react-bootstrap'

// Default grids values

export const gridTypes = {
  main: {
    col: {
      start: 1,
      end: 7
    },
    row: {
      start: 1,
      end: 7
    }
  },
  secondary: {
    col: {
      start: 7,
      end: 10
    },
    row: {
      start: 1,
      end: 4
    }
  },
  tertiary: {
    col: {
      start: 7,
      end: 10
    },
    row: {
      start: 4,
      end: 7
    }
  }
}

// Layout

export const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-gap: 10px;
  position: relative;
`

export const GridFigure = styled.figure<{type: string}>`
  grid-column-start: ${(props) => gridTypes[props.type].col.start};
  grid-column-end: ${(props) => gridTypes[props.type].col.end};
  grid-row-start: ${(props) => gridTypes[props.type].row.start};
  grid-row-end: ${(props) => gridTypes[props.type].row.end};
`

export const ButtonGridFigure = styled.figure`
  grid-column-start: 8;
  grid-column-end: 10;
  grid-row-start: 5;
  grid-row-end: 6;
`

export const GalleryButtonContainer = styled.figure`
  bottom: 1rem;
  right: 1rem;
  position: absolute;
`

export const CornerOption = styled.div<{onClick: any;}>`
  position: absolute;
  right: 25px;
  top: 25px;
`

// Image

export const Image = styled(BSImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const SelectableImage = styled(Image)`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-width: 3px !important;
`

//Thumbnail

export const ScrollableContainer = styled.div`
  white-space: nowrap;
  overflow: auto;
`

export const ScrollableImageContainer = styled.div`
  width: 150px;
  height: 120px;
  padding: 0.5em;
  display: inline-block;
`
