import { usePapaParse } from 'react-papaparse'

export const useCsvDownloader = <F = any, U = any>(
  unformattedData?: U,
  formatter: (unformatted: U) => F = (data: U) => data as any as F,
  options?: { fileName: string }
) => {
  const { jsonToCSV } = usePapaParse()

  const getFileName = () => `${options?.fileName || `report_${Date.now()}`}.csv`

  const onDownload = (formattedData?: F) => {
    if (!formattedData && !unformattedData)
      throw new Error('useCsvDownloader cannot be used without data')

    const formatted = formattedData || formatter(unformattedData as U)

    // @ts-ignore
    const csv = jsonToCSV(JSON.stringify(formatted))

    const csvContent = !csv.match(/^data:text\/csv/i)
      ? 'data:text/csv;charset=utf-8,\ufeff' + csv
      : csv

    const content = encodeURI(csvContent)

    const link = document.createElement('a')
    link.href = content
    link.download = getFileName()

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    onDownload
  }
}
