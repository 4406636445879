import { GroupDTO } from '../../interfaces/group'
import { ApiService } from './base'

// pass optional mapper function if only some values need to be mapped
export class GroupService extends ApiService {
  async getInfo(mapper?: Function, latest?: boolean): Promise<GroupDTO> {
    let params = {}
    if (latest) {
      params = { latest: 1 }
    }
    return this.request({
      method: 'get',
      url: '/group',
      credentials: true,
      params
    }).then((r) => (mapper ? mapper(r) : r))
  }

  async modifyGroup(data: any): Promise<any> {
    return this.request({
      method: 'patch',
      url: '/group',
      credentials: true,
      data
    }).then((r) => r)
  }

  async getBeneficiary(groupId: string): Promise<{ accounts: any[] }> {
    return this.request({
      method: 'get',
      url: `/billing/${groupId}/beneficiary-account`,
      credentials: true
    })
  }
}
