import React, { RefObject } from 'react'
import AutoComplete, {
  ReactGoogleAutocompleteInputProps
} from 'react-google-autocomplete'
import './style.css'

interface IPlaceInfo {
  administrative_area_level_1: string
  administrative_area_level_2: string
  country: string
  locality: string
  postal_code: string
  postal_code_suffix: string
  route: string
  street_number: string
  neighborhood: string
  administrative_area_level_3: string
  stateShort: string
}

export interface IPlaceResult {
  address: string
  city: string
  zip: string
  county: string
  state: string
  stateShort: string
}

const onAddressChange =
  (callback: (prop: Partial<IPlaceResult>) => any) =>
  (
    place: google.maps.places.PlaceResult,
    input: RefObject<HTMLInputElement>
  ) => {
    const places: Partial<IPlaceInfo> = {}

    place.address_components?.forEach((a) => {
      places[a.types[0]] = a?.long_name
      if (a.types[0] === 'administrative_area_level_1') {
        places['stateShort'] = a?.short_name
      }
    })

    const { locality, route, street_number } = places

    const address = street_number ? street_number + ' ' + route : route

    const city = locality

    const county = places.administrative_area_level_2

    ;(input as any).value = address

    const result = {
      address,
      city,
      zip: places.postal_code,
      county,
      state: places.administrative_area_level_1,
      stateShort: places.stateShort
    }
    callback(result)
  }

export const PlaceAutocomplete = (
  props: ReactGoogleAutocompleteInputProps & {
    onPlaceSelection: (prop: Partial<IPlaceResult>) => any
  }
) => {
  const { onPlaceSelection, ...otherProps } = props

  return (
    <AutoComplete
      {...otherProps}
      onPlaceSelected={onAddressChange(onPlaceSelection)}
      options={
        otherProps.options || {
          types: ['address'],
          componentRestrictions: {
            country: ['us']
          }
        }
      }
    />
  )
}
