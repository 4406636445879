import { Col } from 'react-bootstrap'
import styled from 'styled-components'

export const BOX_HEIGHT = 115

export const CustomCol = styled(Col)<{
  container: boolean
  heightAuto?: boolean
}>`
  border: ${(props) =>
    props.container ? 'none' : '0.025rem solid var(--card-border)'};
  padding-left: ${(props) => (props.container ? '0em' : '0.5em')};
  padding-right: ${(props) => (props.container ? '0em' : '0.5em')};
  height: ${(props) =>
    props.container || props.heightAuto ? 'auto' : `${BOX_HEIGHT}px`};
  display: flex;

  & > p,
  & > b {
    align-self: center;
    max-height: 105px;
  }
`
