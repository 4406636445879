import React, { ChangeEvent, useEffect, useState } from 'react'
import { UseQueryResult, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import {
  AnglePlanBadge,
  Avatar,
  Col,
  Container,
  IconInput,
  PageHeaderLight,
  Row,
  Table,
  useDebounce,
  useServerPagination,
  ErrorFallback,
  ErrorPageLayout,
  Button,
  EmployeeService,
  SearchViewLayout,
} from '../../../index'
import { DisenrollModal, QleModal } from './Modals'
import { SearchTitle, SearchInputContainer, EmployeeRow, Status } from './style'
import { Employee } from '../../../interfaces/employer-broker-shared/employees'

export const EmployerPortalEmployees: React.FC<{
  employeeService: EmployeeService
  groupId?: string | undefined
  isBrokerPortal?: boolean
}> = ({ employeeService, groupId, isBrokerPortal }) => {
  const history = useHistory()

  const [searchTerm, setSearchTerm] = useState('')
  const [modal, setModal] = useState({ id: '', show: false })
  const [showAddEmployeeModal, setShowAddEmployeeModal] =
    useState<boolean>(false)

  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const { queryResult, setPageNumber, paginationConfig } = useServerPagination(
    ['employees', debouncedSearchTerm],
    ({ pageLimit, pageNumber }) => {
      return employeeService.getEmployees(
        pageNumber,
        pageLimit,
        debouncedSearchTerm,
        groupId || ''
      )
    }
  )

  const { data, isLoading, error, refetch } = queryResult as UseQueryResult<
    {
      employees: Employee[]
      count: number
    },
    unknown
  >

  const { data: allEmployees } = useQuery(['allEmployees', groupId], () =>
    employeeService.getAllEmployees(isBrokerPortal ? groupId : '')
  )

  useEffect(() => {
    setPageNumber(1)
  }, [searchTerm, setPageNumber])

  paginationConfig.totalItems = data?.count || 0
  paginationConfig.totalPages = paginationConfig.totalPages = Math.ceil(
    paginationConfig.totalItems / paginationConfig.pageLimit
  )

  useEffect(() => {
    setPageNumber(1)
  }, [searchTerm, setPageNumber])

  // fallback if we don't have a group id on broker portal view
  if (isBrokerPortal && !groupId) {
    return (
      <ErrorPageLayout
        errorTitle="404 - Not Found"
        errorSubtitle="Something went wrong locating this group. Please reach out to Angle Health if the issue continues."
        button={
          <Button onClick={() => history.push('/')}>Back to Group List</Button>
        }
      />
    )
  }

  return (
    <>
      <DisenrollModal refetch={refetch} />
      {showAddEmployeeModal && (
        // TODO - new modal goes here
        <></>
      )}
      <QleModal
        show={modal.show}
        refetch={refetch}
        memberId={modal.id}
        onHide={() => setModal({ id: '', show: false })}
        employeesService={employeeService}
        groupId={groupId || ''}
      />
      <SearchViewLayout>
        <PageHeaderLight>
          <SearchTitle>Employees</SearchTitle>
          <SearchInputContainer>
            <IconInput
              label="employee"
              id="employee"
              placeholder="Find employee by name"
              icon="icon-search"
              value={searchTerm}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(e.target.value)
              }
            />
          </SearchInputContainer>
        </PageHeaderLight>
        <Container className="mt-5 mb-5 pb-8">
          {error ? (
            <ErrorFallback className="text-center"></ErrorFallback>
          ) : (
            <Row>
              <Col>
                <div className="d-flex flex-row justify-content-between mb-3 align-items-center">
                  <strong>Employees list</strong>
                  <div className="d-flex align-items-center">
                    <Button
                      variant="primary"
                      onClick={() =>
                        history.push(
                          isBrokerPortal
                            ? `/groups/${groupId}/employees/registration`
                            : '/employees/registration'
                        )
                      }
                      style={{ marginLeft: '15px' }}
                    >
                      + Add Employee
                    </Button>
                  </div>
                </div>
                <Table
                  headerClassName="bg-light"
                  headers={[
                    { title: 'Employee' },
                    { title: 'Plan' },
                    { title: 'Start Date' },
                    { title: 'End Date' },
                    { title: 'Status' },
                    { title: '' }
                  ]}
                  loading={isLoading}
                  paginationConfig={paginationConfig}
                  tableContainerProps={{
                    style: { overflow: 'visible' }
                  }}
                >
                  <tbody>
                    {data?.employees?.length === 0 && !isLoading && (
                      <tr>
                        <td className="py-5">
                          <h6>No employees</h6>
                        </td>
                      </tr>
                    )}
                    {data?.employees?.map((employee) => {
                      let status = employee.status
                      if (employee.disenrolled) {
                        status = { text: 'Disenrolled', variant: 'danger' }
                      }
                      else if (employee.waivedCoverage) {
                        status = { text: 'Waived Coverage', variant: 'danger' }
                      }
                      else if (
                        employee.endDate &&
                        new Date() > new Date(employee.endDate)
                      ) {
                          status = { text: 'Terminated', variant: 'danger' }
                      }
                      else if (
                        employee.endDate &&
                        new Date().getMonth() === new Date(employee.endDate).getMonth() &&
                        new Date().getFullYear() === new Date(employee.endDate).getFullYear()
                      ) {
                        status = { text: 'Terminating at the end of the month', variant: 'warning' }
                      }
                      else if (!status) {
                        status = { text: 'Unknown', variant: 'warning' }
                      }
                      return (
                        <EmployeeRow key={employee.id}>
                          <td>
                            <div
                              onClick={() =>
                                history.push(
                                  isBrokerPortal
                                    ? `/groups/${groupId}/employees/${employee.id}`
                                    : `/employees/${employee.id}`
                                )
                              }
                              className="d-flex flex-row"
                            >
                              <Avatar
                                variant="primary"
                                fallbackContent={employee.name[0]}
                                border="lg"
                                style={{ height: 42, width: 42 }}
                                circle
                                avatarImagePath={employee.profilePicUrl}
                              />
                              <div className="d-flex flex-column ml-2">
                                <p className="mb-0">{employee.name}</p>
                                <span className="text-muted">
                                  {employee.id}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            {employee.planName && employee.planName ? (
                              <AnglePlanBadge
                                metalTier={employee.metalTier}
                                planName={employee.planName}
                                textColor="black"
                              />
                            ) : (
                              '-'
                            )}
                          </td>

                          <td className="text-muted">{employee.startDate}</td>
                          <td className="text-muted">{employee.endDate}</td>
                          <td className="text-muted">
                            <Status className={`bg-${status.variant}`} />
                            {status.text}
                          </td>
                        </EmployeeRow>
                      )
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </Container>
      </SearchViewLayout>
    </>
  )
}
