import React from 'react'
import {
  Col,
  Form,
  Row,
  useMonthlyDates,
  NewHireWaitingPeriodChoices
} from 'react-angle-dashboard-components'
import { DateHandler, nonNegativeValues } from '@angle/utils'
import { Controller } from 'react-hook-form'
import { TitleRow, ValidationError } from '../../style'
import {
  formValidations,
  getRequiredObject
} from '../../../../../utils/formValidations'
import { labels } from '../EmployerPreferences'

interface LevelFundedProps {
  formState: any
  control: any
  register: any
}

export const LevelFundedEmployerPreferences: React.FC<LevelFundedProps> = ({
  formState,
  control,
  register
}) => {
  const options = useMonthlyDates(new Date(), 6)

  // add previous month
  const date = DateHandler.use(new Date()).startOf('month')
  options.unshift({
    label: date.format('MMMM D, YYYY'),
    value: date.format('YYYY-MM-DD')
  })

  const { onlyNumbers, minMedicare, hoursWorkedRange } = formValidations

  return (
    <>
      <TitleRow noGutters>
        <b>Employer Preferences</b>
      </TitleRow>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label
              className="required-field"
              style={{ marginBottom: 34, whiteSpace: 'nowrap' }}
            >
              {labels.effectiveDate}
            </Form.Label>
            <Controller
              name="effectiveDate"
              control={control}
              rules={{
                required: getRequiredObject(labels.effectiveDate)
              }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  isInvalid={Boolean(formState.errors?.effectiveDate)}
                  as="select"
                >
                  <option value="" disabled hidden>
                    Select a Date
                  </option>
                  <option></option>
                  {options.map((e) => (
                    <option key={e.value} value={e.value}>
                      {e.label}
                    </option>
                  ))}
                </Form.Control>
              )}
            />

            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.effectiveDate?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.eligibilityHours}
            </Form.Label>
            <Controller
              name="eligibilityHours"
              rules={{
                required: {
                  value: true,
                  message: 'Number of hours (30 or more) is required'
                },
                validate: hoursWorkedRange
              }}
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  {...nonNegativeValues}
                  type="number"
                  placeholder="Number of hours (30 or more)"
                  isInvalid={Boolean(formState.errors?.eligibilityHours)}
                />
              )}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.eligibilityHours?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group>
        <Row>
          <Col>
            <Form.Label className="text-secondary">
              {labels.numOfCoveredEmployees}
            </Form.Label>
          </Col>
          <Col>
            <Form.Label className="text-secondary">
              {labels.numOfCoveredLives}
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <small className="text-secondary">
              These values are chosen prior to onboarding, contact your sales
              representative or customer service rep to make any changes.
            </small>
          </Col>
        </Row>
        <Row className="mb-6">
          <Col>
            <Controller
              name="numOfCoveredEmployees"
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  {...nonNegativeValues}
                  isInvalid={Boolean(formState.errors?.numOfCoveredEmployees)}
                  type="number"
                  // placeholder="Enter number of covered employees"
                  disabled
                />
              )}
            />
          </Col>
          <Col xs={12} md={6}>
            <Controller
              name="numOfCoveredLives"
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  {...nonNegativeValues}
                  type="number"
                  // placeholder="Enter number of covered lives"
                  disabled
                />
              )}
            />
          </Col>
        </Row>
      </Form.Group>

      {/* <Row className="mb-3">
        <Col xs={12}>
          <small className="text-secondary">
            These values are chosen prior to onboarding, contact your sales
            representative or customer service rep to make any changes
          </small>
        </Col>
      </Row> */}

      <Row>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-0">
            <Form.Label className="required-field">
              {labels.medicareNum}
            </Form.Label>
            <Form.Control
              placeholder="Enter number of employees"
              type="number"
              isInvalid={Boolean(formState.errors?.medicareNum)}
              {...register('medicareNum', {
                required: getRequiredObject(labels.medicareNum),
                validate: { minMedicare, onlyNumbers }
              })}
            />
            <small className="text-secondary">
              refer to line 1 of your most recent federal tax return form 941 or
              944
            </small>
            <ValidationError className="mt-0">
              {formState.isSubmitted && formState.errors?.medicareNum?.message}
            </ValidationError>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-0">
            <Form.Label
              className="required-field"
              style={{ marginBottom: 34, whiteSpace: 'nowrap' }}
            >
              {labels.numOfEmployees}
            </Form.Label>
            <Controller
              name="numOfEmployees"
              control={control}
              rules={{
                required: getRequiredObject(labels.numOfEmployees),
              }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  {...nonNegativeValues}
                  isInvalid={Boolean(formState.errors?.numOfEmployees)}
                  type="number"
                  placeholder="Eligible employees"
                />
              )}
            />
            <ValidationError className="mt-0">
              {formState.isSubmitted &&
                formState.errors?.numOfEmployees?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>

      <TitleRow noGutters>
        <b>New Hire Waiting Period</b>
      </TitleRow>
      <Row noGutters>
        <Col>
          <Form.Group>
            <Form.Label className="required-field">
              {labels.newHireWaitingPeriod}
            </Form.Label>
            <Controller
              control={control}
              rules={{
                required: getRequiredObject('New Hire Waiting Period')
              }}
              name="newHireWaitingPeriod"
              render={({ field }) => (
                <Form.Group className="mb-0">
                  <Row>
                    {Object.keys(NewHireWaitingPeriodChoices).map((choice) => (
                      <Col xs={12} md={7} key={choice}>
                        <Form.Check
                          {...field}
                          className="custom-radio"
                          type="radio"
                          value={choice}
                          label={NewHireWaitingPeriodChoices[choice]}
                          onChange={(e) => {
                            field.onChange(e.target.value)
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              )}
            />
            <ValidationError>
              {formState.isSubmitted &&
                formState.errors?.newHireWaitingPeriod?.message}
            </ValidationError>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
