import { ApiService } from './base'
import type { BulkReportData } from '../../components/Reporting/types'

export type ReportTypes =
  | 'funding_summary_fully_insured'
  | 'funding_summary_level_funded'
  | 'large_claimants'
  | 'top_pharmacy_paid'
  | 'top_pharmacy_scripts'

interface IChoice {
  planYearId: number
  planYearStartDate: string
  planYearEndDate: string
  reportTypes: {
    reportTypeId: ReportTypes
    title: string
    description: string
    enabled: boolean
    reasonForDisable?: string
  }[]
}

interface IGetChoicesRes {
  data?: IChoice[],// Deprecated
  planYears: IChoice[],
  subgroups: string[],
}

interface BulkReportRequest {
  planYearId: number
  subgroup: string | undefined
}

interface ReportRequest extends BulkReportRequest {
  reportTypeId: ReportTypes
}

export class ReportingService extends ApiService {
  async getChoices(groupId?: string) {
    return this.request({
      method: 'get',
      url: `${groupId ? `/groups/${groupId}` : ''}/reports/choices`,
      credentials: true
    }).then((r: IGetChoicesRes) => r)
  }

  async getReports(data: ReportRequest, groupId?: string) {
    return this.request({
      method: 'post',
      url: `${groupId ? `/groups/${groupId}` : ''}/reports`,
      credentials: true,
      data: data
    }).then((r) => r)
  }

  async generateBulkReports(data: BulkReportRequest, groupId?: string): Promise<BulkReportData> {
    if (!data.subgroup) {
      data.subgroup = undefined
    }
    return this.request({
      method: 'post',
      url: `${groupId ? `/groups/${groupId}` : ''}/reports/bulk`,
      credentials: true,
      data: data
    }).then((r) => r.data)
  }
}
